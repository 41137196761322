import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const FilterEnvironment = () => {
  const [searchParams, setQueryParams] = useSearchParams();
  const [filterEnv, setFilterEnv] = useState('');

  useEffect(() => {
    setFilterEnv(searchParams.get('environment') ?? '');
  }, [searchParams]);

  const sortEnv = (event: SelectChangeEvent) => {
    const queryParams = Object.fromEntries(searchParams);
    if (event.target.value === 'all') {
      delete queryParams.environment;
    } else {
      queryParams.environment = event.target.value as string;
    }
    setQueryParams(queryParams);
  };

  return (
    <>
      <FormControl size='small' variant='standard'>
        <InputLabel sx={{ fontWeight: 'bold', color: '#000000DE', fontSize: '12px' }}>
          ENV
        </InputLabel>
        <Select
          disableUnderline
          size='small'
          label='ENV'
          aria-label='env-select'
          value={filterEnv}
          onChange={sortEnv}
          sx={{
            fontSize: '12px',
            width: '75px',
            mb: '7px',
            '.MuiSelect-icon': {
              color: '#000000DE',
              mt: '-2px',
            },
          }}
        >
          <MenuItem aria-label='all' value='all'>All</MenuItem>
          <MenuItem aria-label='sandbox' value='sandbox'>SANDBOX</MenuItem>
          <MenuItem aria-label='uat' value='uat'>UAT</MenuItem>
          <MenuItem aria-label='prod' value='prod'>PRODUCTION</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default FilterEnvironment;
