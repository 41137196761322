import { Box, Typography } from '@mui/material';
import { TabPanelProps } from './Interface';
import { ContentState, convertFromHTML } from 'draft-js';

export const formattedTime = (time: any) => {
  const hours = time?.slice(0, 2);
  const minutes = time?.slice(3, 5);
  const period = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 || 12;
  return `${formattedHours}:${minutes} ${period}`;
};

export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getCurrentTime = () => {
  const today = new Date();
  const hours = String(today.getHours()).padStart(2, '0');
  const minutes = String(today.getMinutes()).padStart(2, '0');
  const seconds = String(today.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

export const formattedDate = (inputDate: string) => {
  const date = new Date(inputDate);
  const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const isToday = (dateStr: string): boolean => {
  const inputDate = new Date(dateStr);
  const today = new Date();

  const inputDateStr = inputDate.toISOString().split('T')[0];
  const todayDateStr = today.toISOString().split('T')[0];

  return inputDateStr === todayDateStr;
};

export const isYesterday = (dateStr: string): boolean => {
  const inputDate = new Date(dateStr);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const inputDateStr = inputDate.toISOString().split('T')[0];
  const yesterdayDateStr = yesterday.toISOString().split('T')[0];

  return inputDateStr === yesterdayDateStr;
};

export const isTomorrow = (dateStr: string): boolean => {
  const inputDate = new Date(dateStr);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const inputDateStr = inputDate.toISOString().split('T')[0];
  const tomorrowDateStr = tomorrow.toISOString().split('T')[0];

  return inputDateStr === tomorrowDateStr;
};

export const getTodaysDate = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const removeLeadingZerosRegex = (str: string) => {
  return str.replace(/^0+(?=\d)/, '');
};

export const handleDay = (inputDate: string) => {
  if (!inputDate) return 'Invalid date';
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
  let date: Date;
  if (isoDateRegex.test(inputDate)) {
    date = new Date(inputDate);
  } else {
    const [month, day, year] = inputDate.split(/[-/]/).map((part) => parseInt(part, 10));
    date = new Date(year, month - 1, day);
  }
  if (isNaN(date.getTime())) {
    return 'Invalid date';
  }
  const options: Intl.DateTimeFormatOptions = { weekday: 'long' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, customPadding, customOverflow, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{ overflowY: customOverflow ? customOverflow : 'hidden' }}
    >
      {value === index && (
        <Box sx={{ p: customPadding ? customPadding : 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

export const attendanceUsersSort = (attendanceUsers: any) => {
  return attendanceUsers
    ?.slice()
    ?.sort((a: { attributes: { name: string } }, b: { attributes: { name: string } }) =>
      a.attributes.name.localeCompare(b.attributes.name),
    );
};

export const convertRichText = (text: string) => {
  const blocksFromHTML = convertFromHTML(text);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
  return contentState;
};

export const hasDueDatePassed = (dueDate: string): boolean => {
  const currentDate = new Date();
  const dueDateObj = new Date(dueDate);
  currentDate.setDate(currentDate.getDate() - 1);

  return currentDate > dueDateObj;
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};
