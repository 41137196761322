import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../store/store';
import { SocketContext } from '../../utils/SocketProvider';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  List,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Checkbox,
  IconButton,
  Box,
  Typography,
  ListItemButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { addParticipants } from '../../store/room/roomAction';
import { sendChatLogAction } from '../../store/message/messageActions';
import { stringToColor } from '../../utils/exports/Function';
interface AddParticipantsProps {
  open: boolean;
  onClose: () => void;
}

const AddParticipants: React.FC<AddParticipantsProps> = ({ open, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

  const dispatch = useDispatch<AppDispatch>();
  const socket = useContext(SocketContext);
  const users = useSelector((state: RootState) => state.user.users);
  const currentRoom = useSelector((state: RootState) => state.room.currentRoom);

  const currentRoomParticipants =
    currentRoom?.roomParticipants?.map((participant) => participant.user.userId) || [];

  // Handle adding participants
  const handleAddParticipants = () => {
    // Get the admin user
    const admin = currentRoom?.roomParticipants?.find(
      (participant) => participant.userRole === 'admin',
    );

    // Get the names of selected users
    const selectedUsers = users
      .filter((user) => selectedUserIds.includes(user.userId))
      .map((user) => user.userName);

    // Dispatch the addParticipants action
    if (socket && currentRoom && selectedUserIds.length > 0) {
      dispatch(
        addParticipants({
          socket: socket,
          payload: {
            roomId: currentRoom.id,
            participantIds: selectedUserIds,
          },
        }),
      );
      dispatch(
        sendChatLogAction({
          socket: socket,
          logData: {
            roomId: currentRoom.id,
            content: `${admin?.user.userName} added ${selectedUsers.join(', ')}`,
          },
        }),
      );
    }
    setSelectedUserIds([]);
    // Close the dialog after adding participants
    onClose();
  };

  // Initials for avatar
  const getInitials = (name: string) => {
    const parts = name.trim().split(/\s+/);

    if (parts.length === 1) {
      return parts[0].slice(0, 2).toUpperCase();
    }
    return `${parts[0][0]}${parts[parts.length - 1][0]}`.toUpperCase();
  };

  const handleUserToggle = (userId: number) => {
    setSelectedUserIds((prev) =>
      prev.includes(userId) ? prev.filter((id) => id !== userId) : [...prev, userId],
    );
  };

  const handleRemoveUser = (userId: number) => {
    setSelectedUserIds((prev) => prev.filter((id) => id !== userId));
  };

  const filteredUsers = users.filter(
    (user) =>
      user.userName.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !currentRoomParticipants.includes(user.userId),
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Typography variant='h6' component='span' sx={{ fontWeight: 'bold' }}>
          Add Participants
        </Typography>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* Search input */}
        <TextField
          fullWidth
          placeholder='Search'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          sx={{ mb: 1 }}
        />

        {/* Selected users */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', mb: 2 }}>
          {selectedUserIds.map((userId) => {
            const user = users.find((u) => u.userId === userId);
            return (
              <Box
                key={userId}
                sx={{
                  position: 'relative',
                  margin: 0.5,
                }}
              >
                <Avatar
                  sx={{
                    height: 45,
                    width: 45,
                    bgcolor: stringToColor(user?.userName || 'User'),
                  }}
                >
                  {getInitials(user?.userName || 'User')}
                </Avatar>
                <IconButton
                  size='small'
                  sx={{
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    padding: '2px',
                  }}
                  onClick={() => handleRemoveUser(userId)}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
              </Box>
            );
          })}
        </Box>

        <Typography variant='subtitle1' sx={{ mb: 1 }}>
          Suggested
        </Typography>

        {/* User list */}
        <List sx={{ maxHeight: 200, overflow: 'auto' }}>
          {filteredUsers.map((user) => (
            <ListItemButton
              key={user.userId}
              dense
              onClick={() => handleUserToggle(user.userId)}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{ height: 45, width: 45, bgcolor: stringToColor(user?.userName || 'User') }}
                >
                  {getInitials(user?.userName || 'User')}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={user.userName} />
              <Checkbox
                edge='end'
                checked={selectedUserIds.includes(user.userId)}
                onChange={(e) => {
                  e.stopPropagation();
                  handleUserToggle(user.userId);
                }}
                onClick={(e) => e.stopPropagation()}
              />
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant='contained'
          onClick={handleAddParticipants}
          disabled={selectedUserIds.length === 0}
        >
          Add Participants
          <IconButton>
            <GroupAddIcon sx={{ color: selectedUserIds.length > 0 ? 'white' : 'grey.500' }} />
          </IconButton>
        </Button>
      </Box>
    </Dialog>
  );
};

export default AddParticipants;
