import { useContext, useEffect, useState } from 'react';
import isMobile from '../hooks/isMobile';
import MobileAttendanceLayout from '../layout/Mobile/MobileAttendanceLayout';
import DesktopAttendanceLayout from '../layout/Desktop/DesktopAttendanceLayout';
import {
  AllAttendanceUsersContext,
  AttendanceUsersContext,
  headers,
  LeaveTypesContext,
  PolicyInfoContext,
  UserListContext,
} from '../routes/Main';
import axios from 'axios';
import { failed } from '../hooks/useToast';
import { CurrentUserContext, TenantContext } from '../App';
import { Navigate, useSearchParams } from 'react-router-dom';
import isAttendanceUser from '../hooks/isAttendanceUser';

const AttendancePage = (props: { user: any }) => {
  const mobile = isMobile();

  const { userList } = useContext(UserListContext);

  const { tenant } = useContext(TenantContext);

  const { setCurrentUser } = useContext(CurrentUserContext);

  const { allAttendanceUsers, getAllAttendanceUsers } = useContext(AllAttendanceUsersContext);

  const { getAttendanceUsers } = useContext(AttendanceUsersContext);

  const { policyInfo, getPolicyInfoData } = useContext(PolicyInfoContext);

  const { leaveTypes, getLeaveTypes } = useContext(LeaveTypesContext);

  useEffect(() => {
    setCurrentUser(props?.user);
  }, [props?.user]);

  useEffect(() => {
    getAttendanceUsers();
  }, [tenant]);

  const [sisUsers, setSisUsers] = useState<any>();

  useEffect(() => {
    {
      !allAttendanceUsers && getAllAttendanceUsers();
    }
  }, [allAttendanceUsers]);

  useEffect(() => {
    !policyInfo && getPolicyInfoData();
    !leaveTypes && getLeaveTypes();
  }, []);

  useEffect(() => {
    setSisUsers(
      userList?.data?.users?.users?.filter(
        (user: {
          organizations: [{ alias: string }];
          main_tenant: string;
          main_tenant_role: [{ services: [{ service_name: string }] }];
        }) =>
          user?.main_tenant === null
            ? user?.organizations?.filter(
                (organization: { alias: string }) => organization?.alias === 'sciever',
              )?.length > 0
            : user?.main_tenant_role[0]?.services?.filter(
                (service) => service?.service_name === 'HR Management',
              )?.length > 0,
      ),
    );
  }, [userList]);

  useEffect(() => {
    sisUsers?.map((sisUser: any) => {
      if (
        allAttendanceUsers?.filter((user: any) => user?.attributes?.sis_id == sisUser?.id)
          ?.length === 0
      ) {
        const data = {
          data: {
            sis_id: JSON.stringify(sisUser?.id),
            name: sisUser?.name,
            contact: sisUser?.phone_no,
            official_email: sisUser?.email,
            status: true,
            tenant: sisUser?.main_tenant === null ? 'sciever' : sisUser?.main_tenant,
          },
        };

        axios
          .post(`${process.env.REACT_APP_strapiUrl}/attendance-users`, data, {
            headers: headers,
          })
          .catch(() => failed());
      }
    });
  }, [sisUsers, allAttendanceUsers]);

  const attendanceUser = isAttendanceUser();

  const [searchParams, setQueryParams] = useSearchParams();

  useEffect(() => {
    const queryParams = Object.fromEntries(searchParams);
    if (tenant === 'all') {
      delete queryParams.tenant;
    } else {
      queryParams.tenant = tenant as string;
    }
    delete queryParams.assignees;
    setQueryParams(queryParams);
  }, [tenant]);

  return (
    <>
      {!attendanceUser ? (
        <Navigate to='/tickets' />
      ) : mobile ? (
        <MobileAttendanceLayout />
      ) : (
        <DesktopAttendanceLayout />
      )}
    </>
  );
};

export default AttendancePage;
