import React from "react";
import { Accordion, AccordionSummary, styled } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
// import { Chapter } from '../../types';

// import { Chapter } from "../CourseObj";
import { ChapterHeader } from "./ChapterHeader";
import { ChapterContent } from "./ChapterContent";
import { IEditChapter } from "../../exports/interfaces"; 
// import { IChapter, IEditChapter } from "../../exports/interfaces";


export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  "&.MuiAccordion-root": {
    margin: 0,
    "&:before": {
      display: "none",
    },
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    boxShadow: "none",
    borderRadius: 0,
  },
  "& .MuiAccordionSummary-root": {
    minHeight: 64,
    padding: theme.spacing(0, 2),
    "&.Mui-expanded": {
      minHeight: 64,
    },
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0, 
    },
  },
  "& .MuiAccordionDetails-root": {
    padding: theme.spacing(1, 2, 2),
  },
}));

interface ChapterAccordionProps {
  chapter: IEditChapter;
  isExpanded: boolean;
  onChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  onEdit: () => void;
  onBookmark: () => void;
}

export const ChapterAccordion: React.FC<ChapterAccordionProps> = ({
  chapter,
  isExpanded,
  onChange,
  onEdit,
  onBookmark,
}) => (
  <StyledAccordion expanded={isExpanded} onChange={onChange}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      sx={{
        position: "relative",
        "& .MuiAccordionSummary-expandIconWrapper": {
          transform: "none",
          "&.Mui-expanded": {
            transform: "rotate(180deg)",
          },
        },
      }}
    >
      <ChapterHeader
        name={chapter?.title}  
        duration={chapter?.hours}
        onEdit={onEdit}
        onBookmark={onBookmark}
      />
    </AccordionSummary>
    <ChapterContent content={chapter?.topics?.data} />
  </StyledAccordion>
);
