import { Box, Typography } from '@mui/material';

type colorProps = {
  pill: string;
};

const Pills = (props: colorProps) => {
  const pills = [
    {
      name: 'HIGHEST',
      hexCode: '#E20000',
    },
    {
      name: 'HIGH',
      hexCode: '#FB7800',
    },
    {
      name: 'MEDIUM',
      hexCode: '#FEC400',
    },
    {
      name: 'LOW',
      hexCode: '#93A795',
    },
    {
      name: 'FEATURE',
      hexCode: '#A142FF',
    },
    {
      name: 'BUG',
      hexCode: '#000000',
    },
    {
      name: 'TASK',
      hexCode: '#4A00E8',
    },
    {
      name: 'BACKLOG',
      hexCode: '#515B52',
    },
    {
      name: 'ON HOLD',
      hexCode: '#747c75',
    },
    {
      name: 'TODO',
      hexCode: '#93A795',
    },
    {
      name: 'IN PROGRESS',
      hexCode: '#FF7A00',
    },
    {
      name: 'PR',
      hexCode: '#1468B6',
    },
    {
      name: 'PR DONE',
      hexCode: '#0085FF',
    },
    {
      name: 'DONE',
      hexCode: '#0ED9CD',
    },
    {
      name: 'REDO',
      hexCode: '#E30000',
    },
    {
      name: 'UAT READY',
      hexCode: '#00E309',
    },
    {
      name: 'UAT',
      hexCode: '#00E309',
    },
    {
      name: 'PROD READY',
      hexCode: '#00E309',
    },
    {
      name: 'PRODUCTION',
      hexCode: '#03C03C',
    },
    {
      name: 'CLOSED',
      hexCode: '#00800D',
    },
    {
      name: 'CANCELED',
      hexCode: '#000000',
    },
  ];

  const selectedPill = pills?.filter(
    (pill) => pill?.name?.toUpperCase() == props?.pill?.toUpperCase(),
  );

  if (selectedPill?.length > 0) {
    return (
      <Box
        sx={{
          bgcolor: `${selectedPill[0].hexCode}`,
          border: 'none',
          color: 'white',
          px: '10px',
          width: '78px',
          height: '20px',
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        key={selectedPill[0].name}
      >
        <Typography fontWeight='bold' fontSize='10px'>
          {selectedPill[0].name === 'IN PROGRESS'
            ? 'WIP'
            : selectedPill[0].name === 'PRODUCTION'
            ? 'PROD'
            : selectedPill[0].name}
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          bgcolor: '#FFFFFF',
          border: '1px solid gray',
          color: 'black',
          px: '10px',
          width: '80px',
          height: '20px',
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        key={props?.pill}
      >
        <Typography fontWeight='bold' fontSize='10px'>
          {props?.pill?.slice(-3) === 'env'
            ? props?.pill?.slice(0, -3)?.toUpperCase()
            : props?.pill?.toUpperCase()}
        </Typography>
      </Box>
    );
  }
};

export default Pills;
