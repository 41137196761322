/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { UserDataContext, UserListContext } from '../routes/Main';
import {
  Avatar,
  AvatarGroup,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { LoadingContext } from '../App';
// import EditIcon from '@mui/icons-material/Edit';
import { DataContext } from '../pages/TicketsPage';
// import { TicketListContext } from '../pages/HomePage';
import CloseIcon from '@mui/icons-material/Close';
import isCustomer from '../hooks/isCustomer';
import EditIcon from '@mui/icons-material/Edit';
import { stringToColor } from '../utils/exports/Function';

const AssignDeveloper = (props: {
  idToChange: number | string;
  assignees: any;
  main_tenant: string;
  newTicket: boolean;
  setAssignees: Dispatch<SetStateAction<string[] | null>> | undefined;
  attendees?: boolean;
  table?: boolean;
  static?: boolean;
  hideAssigneeBtn?: boolean;
  fetchTicket?: () => void;
}) => {
  const customer = isCustomer();

  const { setLoading } = useContext(LoadingContext);

  const { userList } = useContext(UserListContext);

  const { userData } = useContext(UserDataContext);

  const { data, setData } = useContext(DataContext);

  // const { fetchTicketsHomePage } = useContext(TicketListContext);

  const [open, setOpen] = useState(false);

  const [selectedAssignee, setSelectedAssignee] = useState<string[]>(
    props.assignees !== null ? props.assignees : [],
  );

  const handleAssign = async () => {
    if (props?.newTicket) {
      if (props.setAssignees !== undefined) {
        props.setAssignees(selectedAssignee);
        setOpen(false);
      }
    } else {
      setLoading(true);

      const editData = {
        assignees: selectedAssignee,
      };

      await fetch(`${process.env.REACT_APP_strapiUrl}/tickets/${props.idToChange}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: editData }),
      })
        .then(async (response) => {
          const responseData = await response.json();
          const updatedTickets = data.map((ticket: any) => {
            if (ticket.id === props.idToChange) {
              return {
                ...ticket,
                attributes: {
                  ...ticket.attributes,
                  assignees: responseData.data.attributes.assignees,
                },
              };
            }
            return ticket;
          });
          setData(updatedTickets);
          if (props.fetchTicket) {
            props.fetchTicket();
          }
          setLoading(false);
          setOpen(false);
          // fetchTicketsHomePage();
          // toggleRefresh();
        })
        .catch(() => {
          // failed();
          setOpen(false);
          // toggleRefresh();
        });

      // fetchTickets();
    }
  };

  const handleChange = (e: any) => {
    selectedAssignee?.includes(e.target.value)
      ? setSelectedAssignee(selectedAssignee?.filter((item: string) => item !== e.target.value))
      : setSelectedAssignee([...selectedAssignee, e.target.value]);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
        color: 'white',
        height: props.static ? '20px' : '24px',
        width: props.static ? '20px' : '24px',
        fontSize: props.static ? '10px' : '12px',
        '&.MuiAvatar-root': {
          border: 'none',
        },
      },
      children:
        name?.split(' ')?.length > 1
          ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
          : `${name.split(' ')[0][0]}`,
    };
  };

  return (
    <>
      {customer ? (
        props?.assignees === null || props?.assignees?.length === 0 ? (
          //   <Typography
          //     onClick={() => setOpen(true)}
          //     color='#3751FF'
          //     sx={{ cursor: 'pointer', fontSize: props.table ? '12px' : '14px' }}
          //   >
          //     {props?.attendees ? 'Select' : 'Assign'}
          //   </Typography>
          <AvatarGroup
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
            spacing={5}
            max={4}
            sx={{
              '& .MuiAvatar-root': {
                border: 'none',
                height: props.static ? '20px' : '24px',
                width: props.static ? '20px' : '24px',
                fontSize: props.static ? '10px' : '12px',
              },
            }}
          >
            {!props?.hideAssigneeBtn && (
              <Avatar sx={{ bgcolor: '#93A795', cursor: props?.static ? 'inherit' : 'pointer' }}>
                <EditIcon sx={{ fontSize: '0.8rem', color: 'white' }} />
              </Avatar>
            )}
          </AvatarGroup>
        ) : props?.assignees?.filter((assignee: string) => assignee?.substring(0, 8) === 'customer')
            ?.length === 0 ? (
          //   <Typography
          //     onClick={() => setOpen(true)}
          //     color='#3751FF'
          //     sx={{ cursor: 'pointer', fontSize: props.table ? '12px' : '14px' }}
          //   >
          //     {props?.attendees ? 'Select' : 'Assign'}
          //   </Typography>
          <AvatarGroup
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
            spacing={5}
            max={4}
            sx={{
              '& .MuiAvatar-root': {
                border: 'none',
                height: props.static ? '20px' : '24px',
                width: props.static ? '20px' : '24px',
                fontSize: props.static ? '10px' : '12px',
              },
            }}
          >
            {!props?.hideAssigneeBtn && (
              <Avatar sx={{ bgcolor: '#93A795', cursor: props?.static ? 'inherit' : 'pointer' }}>
                <EditIcon sx={{ fontSize: '0.8rem', color: 'white' }} />
              </Avatar>
            )}
          </AvatarGroup>
        ) : (
          <Box display='flex' gap='5px'>
            {/* {/ < Typography display='flex' flexDirection={props?.attendees ? 'row' : 'column'} gap='5px' alignItems='center' > /} */}
            <AvatarGroup
              //   onClick={() => !props?.static && setOpen(true)}
              spacing={5}
              max={4}
              sx={{
                // cursor: props?.static ? 'inherit' : 'pointer',
                '& .MuiAvatar-root': {
                  border: 'none',
                  height: props.static ? '20px' : '24px',
                  width: props.static ? '20px' : '24px',
                  fontSize: props.static ? '10px' : '12px',
                },
              }}
            >
              {props?.assignees
                .filter((assignee: string) => assignee?.substring(0, 8) === 'customer')
                ?.map((assignee: string) => {
                  return (
                    // <Box key={assignee} onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
                    //     <Pills pill={assignee.split(' ')[0].slice(8)} />
                    // </Box>
                    <Avatar key={assignee} {...stringAvatar(assignee?.slice(8))} />
                  );
                })}
              {/* </Typography > */}

              {!props?.hideAssigneeBtn && (
                <Avatar
                  onClick={(e) => {
                    e.stopPropagation();
                    !props?.static && setOpen(true);
                  }}
                  sx={{ bgcolor: '#93A795', cursor: props?.static ? 'inherit' : 'pointer' }}
                >
                  <EditIcon sx={{ fontSize: '0.8rem', color: 'white' }} />
                </Avatar>
              )}
            </AvatarGroup>
            {/* {/ <EditIcon onClick={() => setOpen(true)} sx={{ height: '16px', color: 'gray', mb: '4px', cursor: 'pointer' }} /> /} */}
          </Box>
        )
      ) : props.assignees === null || props?.assignees?.length === 0 ? (
        // <Typography onClick={() => setOpen(true)} color='#3751FF' sx={{ cursor: 'pointer', fontSize: props.table ? '12px' : '14px' }}>{props?.attendees ? 'Select' : 'Assign'}</Typography>
        <AvatarGroup
          spacing={5}
          max={4}
          sx={{
            // cursor: props?.static ? 'inherit' : 'pointer',
            '& .MuiAvatar-root': {
              border: 'none',
              height: props.static ? '20px' : '24px',
              width: props.static ? '20px' : '24px',
              fontSize: props.static ? '10px' : '12px',
            },
          }}
        >
          {!props?.hideAssigneeBtn && (
            <Avatar
              onClick={(e) => {
                e.stopPropagation();
                !props?.static && setOpen(true);
              }}
              sx={{ bgcolor: '#93A795', cursor: props?.static ? 'inherit' : 'pointer' }}
            >
              <EditIcon sx={{ fontSize: '0.8rem', color: 'white' }} />
            </Avatar>
          )}
        </AvatarGroup>
      ) : (
        <Box display='flex' gap='5px'>
          {/* {/ <Typography display='flex' flexDirection={props?.attendees ? 'row' : 'column'} gap='5px' alignItems='center'> /} */}
          <AvatarGroup
            // onClick={() => !props?.static && setOpen(true)}
            spacing={5}
            max={4}
            sx={{
              '& .MuiAvatar-root': {
                border: 'none',
                height: props.static ? '20px' : '24px',
                width: props.static ? '20px' : '24px',
                fontSize: props.static ? '10px' : '12px',
              },
            }}
          >
            {props?.assignees?.map((assignee: any) => {
              if (assignee?.substring(0, 8) === 'customer') {
                return (
                  // <Box key={assignee} onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
                  //     <Pills pill={assignee.split(' ')[0].slice(8)} />
                  // </Box>
                  <Avatar key={assignee} {...stringAvatar(assignee?.slice(8))} />
                );
              } else {
                return (
                  // <Box key={assignee} onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
                  //     <Pills pill={assignee.split(' ')[0]} />
                  // </Box>
                  <Avatar key={assignee} {...stringAvatar(assignee)} />
                );
              }
            })}
            {!props?.hideAssigneeBtn && (
              <Avatar
                onClick={(e) => {
                  e.stopPropagation();
                  !props?.static && setOpen(true);
                }}
                sx={{ bgcolor: '#93A795', cursor: props?.static ? 'inherit' : 'pointer' }}
              >
                <EditIcon sx={{ fontSize: '0.8rem', color: 'white' }} />
              </Avatar>
            )}
          </AvatarGroup>
          {/* {/ </Typography> /} */}

          {/* {/ <EditIcon onClick={() => setOpen(true)} sx={{ height: '16px', color: 'gray', mb: '4px', cursor: 'pointer' }} /> /} */}
        </Box>
      )}

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setSelectedAssignee(props.assignees !== null ? props.assignees : []);
        }}
        maxWidth='xl'
      >
        <DialogContent sx={{ padding: '0px 20px' }}>
          <Box display='flex' flexDirection='column'>
            <Box
              display='flex'
              position='sticky'
              top='0px'
              justifyContent='space-between'
              bgcolor='white'
              p='20px 10px'
              m='-10px -10px -15px -10px'
              zIndex='1'
            >
              <Typography fontWeight='bold' fontSize='14px'>
                {props?.attendees ? 'Attendees:' : 'Assign:'}
              </Typography>
              <Box display='flex' gap='10px'>
                <DoneIcon sx={{ cursor: 'pointer' }} onClick={handleAssign} />
                <CloseIcon
                  onClick={() => {
                    setOpen(false);
                    setSelectedAssignee(props.assignees !== null ? props.assignees : []);
                  }}
                  cursor='pointer'
                />
              </Box>
            </Box>

            {!customer ? (
              <Box sx={{ width: '100%', mt: '20px' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tabValue} onChange={handleTabChange} aria-label='basic tabs example'>
                    <Tab label='Developers' {...a11yProps(0)} />
                    <Tab label='Customers' {...a11yProps(1)} />
                    <Tab label='Admins' {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                  <FormControl component='fieldset' variant='standard'>
                    <FormGroup>
                      {userList?.data?.users?.users
                        ?.filter(
                          (user: any) =>
                            user?.status === '1' &&
                            (user?.roles[0]?.name === 'dev' || user?.roles[0]?.name === 'int') &&
                            user?.organizations?.filter(
                              (data: { alias: string }) => data?.alias === props.main_tenant,
                            ).length > 0,
                        )
                        .map((user: any) => {
                          return (
                            <FormControlLabel
                              key={user?.name}
                              control={
                                <Checkbox
                                  aria-label='user-name'
                                  checked={selectedAssignee?.includes(user?.name)}
                                  onChange={handleChange}
                                  value={user?.name}
                                />
                              }
                              label={user?.name}
                            />
                          );
                        })}
                    </FormGroup>
                  </FormControl>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                  <FormControl component='fieldset' variant='standard'>
                    <FormGroup>
                      {userList?.data?.users?.users
                        ?.filter(
                          (user: any) =>
                            user?.status === '1' &&
                            user?.roles[0]?.name === 'cus' &&
                            user?.organizations[0]?.alias === props.main_tenant,
                        )
                        .map((user: any) => {
                          return (
                            <FormControlLabel
                              key={user?.name}
                              control={
                                <Checkbox
                                  aria-label='user-name'
                                  checked={selectedAssignee?.includes(`customer${user?.name}`)}
                                  onChange={handleChange}
                                  value={`customer${user?.name}`}
                                />
                              }
                              label={user?.name}
                            />
                          );
                        })}
                    </FormGroup>
                  </FormControl>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                  <FormControl component='fieldset' variant='standard'>
                    <FormGroup>
                      {userList?.data?.users?.users
                        ?.filter(
                          (user: any) => user?.status === '1' && user?.roles[0]?.name === 'sa',
                        )
                        .map((user: any) => {
                          return (
                            <FormControlLabel
                              key={user?.name}
                              control={
                                <Checkbox
                                  aria-label='user-name'
                                  checked={selectedAssignee?.includes(user?.name)}
                                  onChange={handleChange}
                                  value={user?.name}
                                />
                              }
                              label={user?.name}
                            />
                          );
                        })}
                    </FormGroup>
                  </FormControl>
                </CustomTabPanel>
              </Box>
            ) : (
              <FormControl component='fieldset' variant='standard' sx={{ mt: '20px', mb: '20px' }}>
                <FormGroup>
                  {userList?.data?.users?.users
                    ?.filter(
                      (user: any) =>
                        user?.status === '1' &&
                        user?.roles[0]?.name === 'cus' &&
                        user?.organizations[0]?.alias === userData?.data?.organizations[0]?.alias,
                    )
                    .map((user: any) => {
                      return (
                        <FormControlLabel
                          key={user?.name}
                          control={
                            <Checkbox
                              aria-label='user-name'
                              checked={selectedAssignee?.includes(`customer${user?.name}`)}
                              onChange={handleChange}
                              value={`customer${user?.name}`}
                            />
                          }
                          label={user?.name}
                        />
                      );
                    })}
                </FormGroup>
              </FormControl>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssignDeveloper;
