import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { dynamicFailed, failed, success } from '../hooks/useToast';
import { AllTenantsContext, UserDataContext } from '../routes/Main';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { DataContext } from '../pages/TicketsPage';
// import { TicketListContext } from '../pages/HomePage';
import isDeveloper from '../hooks/isDeveloper';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { IBoardData } from './EditBoard';
import { LoadingContext } from '../App';

const EditTenant = (props: {
  tenant: string;
  currentStatus?: string;
  ticketId: number | string;
  fetchTicket?: () => void;
  getBoardTickets?: (status: string) => void;
}) => {
  const { data, setData } = useContext(DataContext);
  // const { fetchTicketsHomePage } = useContext(TicketListContext);
  const { allTenants } = useContext(AllTenantsContext);
  const { userData } = useContext(UserDataContext);
  const { setLoading } = useContext(LoadingContext);
  const developer = isDeveloper();
  const superAdmin = isSuperAdmin();

  const getDefaultTenantId = () => {
    const matchingTenant = allTenants?.data?.tenants
      ?.map((tenant: { organization: { id: number; alias: string } }) => tenant?.organization)
      ?.find((org: { alias: string }) => org.alias === props.tenant);
    return matchingTenant ? matchingTenant.id : null;
  };
  const [selectedTenantId, setSelectedTenantId] = useState<number | null>(getDefaultTenantId);
  const [selectedTenant, setSelectedTenant] = useState<string | null>(props?.tenant);
  const [newTicketId, setNewTicketId] = useState<string | null>(null);
  const [boardIdArray, setBoardIdArray] = useState<number[]>([0]);

  useEffect(() => {
    setSelectedTenantId(getDefaultTenantId());
  }, [props.tenant, userData]);

  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

  const [open, setOpen] = useState(false);
  const [boardOpen, setBoardOpen] = useState(false);

  const handleTenantChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTenantId(parseInt(e.target.value));
    const matchingTenant = allTenants?.data?.tenants?.find(
      (org: { organization: { id: number } }) => org.organization.id === parseInt(e.target.value),
    );
    setSelectedTenant(matchingTenant.organization.alias);
    setNewTicketId(matchingTenant.organization.ticket_id);
  };

  const handleBoardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);

    const flattenedArray = Array.isArray(boardIdArray) ? boardIdArray.flat() : [];

    if (flattenedArray.includes(value)) {
      setBoardIdArray(flattenedArray.filter((item: number) => item !== value));
    } else {
      setBoardIdArray([...flattenedArray, value]);
    }
  };

  const [boards, setBoards] = useState<IBoardData[]>();

  const editTenant = () => {
    setLoading(true);
    setOpen(false);
    setBoardOpen(true);
    axios
      .get(
        `${process.env.REACT_APP_strapiUrl}/boards?filters[main_tenant][$eq]=${selectedTenant}&populate=*`,
        {
          headers: headers,
        },
      )
      .then((res) => {
        setLoading(false);
        setBoards(res.data.data);
      })
      .catch(() => failed());
  };

  const editBoard = () => {
    if (boardIdArray?.length === 0) {
      dynamicFailed('Atleast 1 board is requred.');
    } else {
      axios
        .get(
          `${process.env.REACT_APP_strapiUrl}/tickets?pagination[pageSize]=1&filters[main_tenant][$eq]=${selectedTenant}&sort=createdAt:desc`,
          {
            headers: headers,
          },
        )
        .then((res) => {
          const tenantwiseId = `${newTicketId?.toUpperCase()}-${
            res?.data?.data[0]?.attributes?.tenantwise_id
              ? parseInt(res?.data?.data[0]?.attributes?.tenantwise_id?.split('-')[1]) + 1
              : 1
          }`;

          const editData = {
            data: {
              boards: boardIdArray?.filter((board) => board !== 0),
              main_tenant: selectedTenant,
              default_board: boardIdArray?.includes(0),
              ...(newTicketId != null && {
                tenantwise_id: tenantwiseId,
              }),
            },
          };

          axios
            .put(`${process.env.REACT_APP_strapiUrl}/tickets/${props.ticketId}`, editData, {
              headers: headers,
            })
            .then(async (response) => {
              const responseData = await response;
              // console.log(responseData.data.data.attributes.board);
              const updatedTickets = data.map((ticket: any) => {
                if (ticket.attributes.ticket_id === props.ticketId) {
                  return {
                    ...ticket,
                    attributes: {
                      ...ticket.attributes,
                      boards: responseData.data.data.attributes.boards,
                      default_board: responseData.data.data.attributes.default_board,
                      main_tenant: response.data.data.attributes.main_tenant,
                      tenantwise_id: response.data.data.attributes.tenantwise_id,
                    },
                  };
                }
                return ticket;
              });
              setData(updatedTickets);
              props.getBoardTickets &&
                props.currentStatus &&
                props.getBoardTickets(props.currentStatus);
              success();
              setBoardOpen(false);

              if (props.fetchTicket) {
                props.fetchTicket();
              }
              // location?.pathname === '/overview' ? fetchTicketsHomePage() : fetchTickets();
              // fetchTickets();
            })
            .catch(() => failed());
        })
        .catch(() => failed());
    }
  };

  const [currentTenant, setCurrentTenant] = useState<{ organization: { name: string } }>();

  const getCurrentTenant = () => {
    setCurrentTenant(
      allTenants?.data?.tenants?.filter(
        (org: { organization: { alias: string } }) => org.organization.alias === selectedTenant,
      )[0],
    );
  };

  useEffect(() => {
    getCurrentTenant();
  }, [selectedTenant]);

  return (
    <>
      <Stack direction='row' sx={{ flexWrap: 'wrap' }}>
        {/* <Typography fontSize='12px'>Tenant:</Typography> */}
        {props?.tenant && (
          <Chip label={props?.tenant.toUpperCase()} sx={{ mr: 1, height: '20px' }} />
        )}
        {(superAdmin || developer) && (
          <EditIcon
            onClick={() => setOpen(true)}
            sx={{ width: '16px', height: '16px', mt: '3px', color: 'gray', cursor: 'pointer' }}
          />
        )}
      </Stack>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setSelectedTenantId(getDefaultTenantId());
        }}
        maxWidth='xs'
        fullWidth
      >
        <DialogContent sx={{ padding: '10px 20px' }}>
          <Box display='flex' flexDirection='column'>
            <Box
              display='flex'
              position='sticky'
              top='-10px'
              justifyContent='space-between'
              bgcolor='white'
              py='10px'
              zIndex='1'
            >
              <Typography fontWeight='bold'>Tenant(s):</Typography>
              <Box display='flex' gap='10px'>
                <DoneIcon onClick={() => editTenant()} sx={{ cursor: 'pointer' }} />
                <CloseIcon
                  onClick={() => {
                    setOpen(false);
                    setSelectedTenantId(getDefaultTenantId());
                  }}
                  cursor='pointer'
                />
              </Box>
            </Box>

            <FormControl component='fieldset' variant='standard'>
              <RadioGroup
                aria-label='tenant-group'
                value={selectedTenantId}
                onChange={handleTenantChange}
              >
                {superAdmin
                  ? allTenants?.data?.tenants?.map(
                      (tenant: { organization: { id: number; name: string } }) => (
                        <FormControlLabel
                          key={tenant.organization.id}
                          value={tenant.organization.id}
                          control={<Radio />}
                          aria-label='organization-id'
                          label={tenant.organization.name}
                        />
                      ),
                    )
                  : developer
                  ? userData?.data?.organizations?.map((tenant: { id: number; name: string }) => (
                      <FormControlLabel
                        key={tenant.id}
                        value={tenant.id}
                        control={<Radio />}
                        aria-label='tenant-name'
                        label={tenant.name}
                      />
                    ))
                  : ''}
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={boardOpen}
        onClose={() => {
          setBoardOpen(false);
          setBoardIdArray([0]);
        }}
        maxWidth='xs'
        fullWidth
      >
        <DialogContent sx={{ padding: '10px 20px' }}>
          <Box display='flex' flexDirection='column'>
            <Box
              display='flex'
              position='sticky'
              top='0px'
              justifyContent='space-between'
              bgcolor='white'
              py='10px'
              zIndex='1'
            >
              <Typography fontWeight='bold'>Board(s):</Typography>
              <Box display='flex' gap='10px'>
                <DoneIcon onClick={() => editBoard()} sx={{ cursor: 'pointer' }} />
                <CloseIcon
                  onClick={() => {
                    setBoardOpen(false);
                    setBoardIdArray([0]);
                  }}
                  cursor='pointer'
                />
              </Box>
            </Box>

            <FormControl component='fieldset' variant='standard'>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleBoardChange}
                      aria-label='organization-name'
                      checked={boardIdArray?.flat().includes(0)}
                      value={0}
                    />
                  }
                  label={currentTenant?.organization?.name}
                />
                {boards?.map((board) => {
                  return (
                    <FormControlLabel
                      key={board?.id}
                      control={
                        <Checkbox
                          onChange={handleBoardChange}
                          aria-label='attributes-name'
                          checked={boardIdArray?.flat().includes(board?.id)}
                          value={board?.id}
                        />
                      }
                      label={board?.attributes?.name}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditTenant;
