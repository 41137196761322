import { Box } from '@mui/material';
import { useDrop } from 'react-dnd';
import OverviewTicket, { ItemTypes } from '../Home Page/OverviewTicket';
import { IItemsProps } from './NewMeeting';
import EditMeetingBoard from './EditMeetingBoard';
import { useState } from 'react';

const DiscussionBoard = (props: { items: IItemsProps }) => { 
  const [commentOpen, setCommentOpen] = useState(false);   
  const [ticketId, setTicketId] = useState<number>(0); 
  const [idToDrop, setIdToDrop] = useState<number>(0);   

  const [, drop] = useDrop(() => ({
    accept: ItemTypes.ITEM,
    drop: (item: { idToDrop: number; data: { id: number } }) => {
      if (!props?.items?.decisions.includes(item?.idToDrop)) {
        setIdToDrop(item?.idToDrop);
        setCommentOpen(true);
        setTicketId(item?.data?.id);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const handleDrop = (idToDrop: number) => {
    props?.items?.setDiscussions((oldData) => [...oldData, idToDrop]);
    props?.items?.setDecisions((oldData) => oldData?.filter((data) => data !== idToDrop));
    props?.items?.setActionItems((oldData) => oldData?.filter((data) => data !== idToDrop));
    props?.items?.setRollingItems((oldData) => oldData?.filter((data) => data !== idToDrop));
  };

  return (
    <>
      <Box display='flex' flexDirection='column' gap='5px' p='5px' alignItems='center'>
        <Box fontWeight='bold' fontSize='14px'>DISCUSSION</Box>
        <Box
          ref={drop}
          height='calc(100vh - 230px)'
          border='1px dashed gray'
          borderRadius='10px'
          width='300px'
          display='flex'
          flexDirection='column'
          gap='10px'
          p='10px'
          sx={{
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '3px',
            },
          }}
        >
          {props?.items?.discussionsArray?.map((item) => {
            return <OverviewTicket key={item?.id} data={item} />;
          })}
        </Box>
      </Box>
      <EditMeetingBoard
        commentOpen={commentOpen}
        setCommentOpen={setCommentOpen}
        type={'DISCUSSION'}
        ticketId={ticketId}
        idToDrop={idToDrop}
        handleDrop={handleDrop}
      />
    </>
  );
};

export default DiscussionBoard;
