import { useEffect } from 'react';

const PreconnectLinks = () => {
  useEffect(() => {
    const urls = [
      process.env.REACT_APP_strapiUrl,
      process.env.REACT_APP_strapiImage,
      process.env.REACT_APP_calendarApiUrl,
      process.env.REACT_APP_sisApi,
      'https://fonts.googleapis.com'
    ];

    urls.forEach((url) => {
      if (url) {
        const link = document.createElement('link');
        link.rel = 'preconnect';
        link.href = url;
        document.head.appendChild(link);
      }
    });
  }, []);

  return null; 
};

export default PreconnectLinks;