import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  AvatarGroup,
  Tooltip,
  useMediaQuery,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { deepOrange, deepPurple, blue, green } from '@mui/material/colors';
import { Edit as EditIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
// import { courses, Course, Intern } from './CourseObj';
import AssignInternDialog from './popups/AssignIntern';
import { StyledAccordion } from './coursetab/ChapterAccordion';
import { useCourseContext } from '../context/CourseContext';
import { fetchCourse, updateCourse } from '../api/CourseApi';
import { failed, success } from '../../hooks/useToast';
import { UserListContext } from '../../routes/Main';
import { LoadingContext } from '../../App';
import { ICourse } from '../exports/interfaces';

const AssignCourse: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { setLoading } = useContext(LoadingContext);
  const [isEditInternOpen, setIsEditInternOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState<number[]>([]);
  const { courseData, setCourseData } = useCourseContext();
  const [coursesData, setCoursesData] = useState<ICourse[]>(courseData);
  const [courseId, setCourseId] = useState<number>(0);
  const { userList } = useContext(UserListContext);

  useEffect(() => {
    if (courseData?.length === 0) {
      setLoading(true);
      fetchCourse()
        .then((data) => {
          setCoursesData(data);
          setLoading(false);
        })
        .catch(() => {
          failed();
          setLoading(false);
        });
    }
  }, [setCourseData]);

  const formatDate = (date: string) => {
    const dt = new Date(date);

    const year = dt.toLocaleString('default', { year: 'numeric' });
    const month = dt.toLocaleString('default', { month: '2-digit' });
    const day = dt.toLocaleString('default', { day: '2-digit' });

    return year + '-' + month + '-' + day;
  };

  const getAvatarSx = (internId: string) => {
    const colors = [
      { bgcolor: deepOrange[500] },
      { bgcolor: deepPurple[500] },
      { bgcolor: blue[500] },
      { bgcolor: green[500] },
    ];
    const index = parseInt(internId) - 1;
    return colors[index % colors.length];
  };

  const getInitials = (name: string) => {
    return name
      .split(' ')
      .map((part) => part[0])
      .join('')
      .toUpperCase();
  };

  const handleEditInterns = (course: ICourse) => {
    setSelectedCourse(course?.attributes?.assigned_to || []);
    setCourseId(course?.id);
    setIsEditInternOpen(true);
  };

  const handleAssignInterns = async (selectedInterns: number[]) => {
    setLoading(true);
    try {
      const data = {
        data: {
          assigned_to: selectedInterns,
        },
      };

      const response = await updateCourse(courseId, data);

      if (response?.status === 200 || response?.status === 201) {
        success();
        fetchCourse()
          .then((data) => {
            setCoursesData(data);
            setLoading(false);
          })
          .catch(() => {
            failed();
            setLoading(false);
          });
      } else {
        failed();
        setLoading(false);
      }
    } catch (error) {
      failed();
      setLoading(false);
    }
    // if (!selectedCourse) return;

    // setCoursesData((prevCourses) =>
    //   prevCourses.map((course) =>
    //     course.name === selectedCourse.name ? { ...course, assignees: selectedInterns } : course,
    //   ),
    // );
    setSelectedCourse([]);
  };

  const renderAssignees = (course: ICourse) => {
    const filteredUsers = userList?.data?.users?.users.filter((user: any) =>
      course?.attributes?.assigned_to?.includes(user.id),
    );
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: isMobile ? 'flex-start' : 'flex-end',
        }}
      >
        <AvatarGroup
          sx={{
            '& .MuiAvatar-root': {
              width: 25,
              height: 25,
              fontSize: '0.875rem',
              fontWeight: 500,
              border: 0,
            },
            gap: 0.5,
          }}
        >
          <AvatarGroup
            max={3}
            sx={{
              '& .MuiAvatar-root': {
                width: 25,
                height: 25,
                fontSize: '0.875rem',
                fontWeight: 500,
                border: 0,
              },
              gap: 0.5,
            }}
          >
            {filteredUsers?.map((intern: any) => (
              <Tooltip
                key={intern.id}
                title={`${intern.name} (${intern.id})`}
                arrow
                placement='top'
              >
                <Avatar
                  {...getAvatarSx(intern.id)}
                  sx={{
                    cursor: 'pointer',
                    ...getAvatarSx(intern.id),
                  }}
                >
                  {getInitials(intern.name)}
                </Avatar>
              </Tooltip>
            ))}
          </AvatarGroup>

          <Tooltip title='Edit assigned interns'>
            <Avatar sx={{ backgroundColor: 'blue' }} onClick={() => handleEditInterns(course)}>
              <EditIcon sx={{ fontSize: '0.875rem' }} />
            </Avatar>
          </Tooltip>
        </AvatarGroup>
      </Box>
    );
  };

  return (
    <Box sx={{ px: 2 }}>
      {isMobile ? (
        <div>
          {coursesData.map((course) => (
            <StyledAccordion key={course.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    transform: 'none',
                    '&.Mui-expanded': {
                      transform: 'rotate(180deg)',
                    },
                  },
                }}
              >
                <Typography sx={{ fontWeight: 600 }}>{course?.attributes?.Title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Box>
                    <Typography variant='body2' color='text.secondary'>
                      Date Created
                    </Typography>
                    <Typography>{formatDate(course?.attributes?.createdAt)}</Typography>
                  </Box>
                  <Box>
                    <Typography variant='body2' color='text.secondary'>
                      Number of Chapters
                    </Typography>
                    <Typography>{course?.attributes?.chapters?.data.length || 0}</Typography>
                  </Box>
                  <Box>
                    <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
                      Assigned Interns
                    </Typography>
                    {renderAssignees(course)}
                  </Box>
                </Box>
              </AccordionDetails>
            </StyledAccordion>
          ))}
        </div>
      ) : (
        <TableContainer component={Paper} sx={{height: "calc(100dvh - 131px)"}}>
          <Table sx={{ minWidth: 650 }} aria-label='course table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600 }}>Course Name</TableCell>
                <TableCell align='right' sx={{ fontWeight: 600 }}> 
                  Date Created
                </TableCell>
                <TableCell align='right' sx={{ fontWeight: 600 }}>
                  No. of chapters
                </TableCell>
                <TableCell align='right' sx={{ fontWeight: 600 }}>
                  Assigned Interns
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coursesData.map((course) => (
                <TableRow key={course?.id}>
                  <TableCell component='th' scope='row'>
                    {course?.attributes?.Title}
                  </TableCell>
                  <TableCell align='right'>{formatDate(course?.attributes?.createdAt)}</TableCell>
                  <TableCell align='right'>
                    {course?.attributes?.chapters?.data.length || 0}
                  </TableCell>
                  <TableCell align='right'>
                    {renderAssignees(course)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

        <AssignInternDialog
          open={isEditInternOpen}
          onClose={() => {
            setIsEditInternOpen(false);
            setSelectedCourse([]);
          }}
          currentAssignees={selectedCourse}
          onSubmit={handleAssignInterns}
        />
    </Box>
  );
};

export default AssignCourse;
