import ListItemButton from '@mui/material/ListItemButton';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { NavLink, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { Box } from '@mui/material';
import { CurrentUserContext } from '../../App';
import axios from 'axios';
import { UserDataContext } from '../../routes/Main';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import isDeveloper from '../../hooks/isDeveloper';
import isAttendanceUser from '../../hooks/isAttendanceUser';
import GroupsIcon from '@mui/icons-material/Groups';
import ForumIcon from '@mui/icons-material/Forum';
import SummarizeIcon from '@mui/icons-material/Summarize';
import isUserOfService from '../../hooks/isServiceUser';
import SchoolIcon from '@mui/icons-material/School';


const Sidebar = () => {
  const location = useLocation();

  const superAdmin = isSuperAdmin();

  const developer = isDeveloper();

  const attendanceUser = isAttendanceUser();

  const projectManagementUser = isUserOfService('Project Management');

  const { userData } = useContext(UserDataContext);

  const { setCurrentUser } = useContext(CurrentUserContext);

  const getCurrentAttendanceUser = () => {
    axios
      .get(
        `${process.env.REACT_APP_strapiUrl}/attendance-users?filters[sis_id][$eq]=${userData?.data?.id}&populate[user_files][populate][0]=file`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_token}`,
          },
        },
      )
      .then((res) => {
        setCurrentUser(res?.data?.data[0]);
        return null;
      });
  };

  const attendanceUserOnly =
    userData?.data?.roles[0]?.name !== 'sa' &&
    userData?.data?.organizations?.length === 1 &&
    userData?.data?.organizations[0]?.alias === 'sciever'
      ? true
      : false;

  return (
    <>
      <Box display='flex' flexDirection='column' alignItems='center' width='65px' mt='7px'>
        {attendanceUserOnly ? (
          <>
            {attendanceUser && (
              <NavLink
                id='attendance'
                onClick={() => getCurrentAttendanceUser()}
                to={'/attendance'}
              >
                {/* <ListItem disablePadding sx={{ width: '65px' }}> */}
                <ListItemButton
                  aria-label='attendance-calendar'
                  sx={{ width: '65px' }}
                  selected={
                    location.pathname === '/attendance' || location.pathname === '/attendance-users'
                      ? true
                      : false
                  }
                >
                  <GroupsIcon
                    sx={{ width: '30px', height: '30px', mx: 'auto', my: '5px', color: 'black' }}
                  />
                </ListItemButton>
              </NavLink>
            )}
          </>
        ) : (
          <>
            {attendanceUser && (
              <NavLink
                id='attendance'
                onClick={() => getCurrentAttendanceUser()}
                to={'/attendance'}
              >
                {/* <ListItem disablePadding sx={{ width: '65px' }}> */}
                <ListItemButton
                  aria-label='groups-icon'
                  sx={{ width: '65px' }}
                  selected={
                    location.pathname === '/attendance' || location.pathname === '/attendance-users'
                      ? true
                      : false
                  }
                >
                  <GroupsIcon
                    sx={{ width: '30px', height: '30px', mx: 'auto', my: '5px', color: 'black' }}
                  />
                </ListItemButton>
                {/* </ListItem> */}
              </NavLink>
            )}


            {projectManagementUser && (
              <NavLink
                id='home_page'
                to='/tickets'
                onClick={() => {
                  sessionStorage.setItem('filter', location.search);
                  getCurrentAttendanceUser();
                }}
              >
                <ListItemButton
                  sx={{
                    margin: 0,
                    width: '65px',
                  }}
                  aria-label='confirmation-number'
                  selected={
                    location.pathname === '/overview' || location.pathname === '/tickets'
                      ? true
                      : false
                  }
                >
                  <ConfirmationNumberIcon
                    sx={{ width: '30px', height: '30px', mx: 'auto', my: '5px', color: 'black' }}
                  />
                </ListItemButton>
              </NavLink>
            )}


            {/* <NavLink id='tickets_page' onClick={() => getCurrentAttendanceUser()} to={`/tickets${sessionStorage.getItem('filter') !== null ? sessionStorage.getItem('filter') : ''}`}>
              <ListItemButton sx={{ width: '65px' }} selected={location.pathname === '/tickets' ? true : false}>
                <ConfirmationNumberIcon sx={{ width: '30px', height: '30px', mx: 'auto', my: '5px', color: 'black' }} />
              </ListItemButton>
            </NavLink> */}

            {/* <NavLink id='create_ticket' onClick={() => getCurrentAttendanceUser()} to={location.pathname + location.search}>
              <ListItemButton sx={{ width: '65px' }} onClick={handleClickOpen}>
                <AddCircleIcon sx={{ width: '30px', height: '30px', mx: 'auto', my: '5px', color: 'black' }} />
              </ListItemButton>
            </NavLink> */}

            {(superAdmin || developer) && (
              <NavLink id='meeting' onClick={() => getCurrentAttendanceUser()} to={'/meeting'}>
                <ListItemButton
                  aria-label='month-icon'
                  sx={{ width: '65px' }}
                  selected={location.pathname === '/meeting' ? true : false}
                >
                  <SummarizeIcon
                    sx={{ width: '30px', height: '30px', mx: 'auto', my: '5px', color: 'black' }}
                  />
                </ListItemButton>
              </NavLink>
            )}

            {(superAdmin || developer) && (
              <>
              <NavLink id='chatroom' onClick={() => getCurrentAttendanceUser()} to={'/chatroom'}>
                <ListItemButton
                  aria-label='forum-icon'
                  sx={{ width: '65px' }}
                  selected={location.pathname === '/chatroom' ? true : false}
                >
                  <ForumIcon
                    sx={{ width: '30px', height: '30px', mx: 'auto', my: '5px', color: 'black' }}
                  />
                </ListItemButton>
              </NavLink>
              <NavLink id='course' onClick={() => getCurrentAttendanceUser()} to={'/course'}>
              <ListItemButton
                aria-label='School-icon'
                sx={{ width: '65px' }}
                selected={location.pathname.split('/')[1] === 'course' ? true : false}
              >
                <SchoolIcon
                  sx={{ width: '30px', height: '30px', mx: 'auto', my: '5px', color: 'black' }}
                />
              </ListItemButton>
            </NavLink>
            </>
            )}

            {/* <DesktopNotifications />

            <Button onClick={handleAccountClick} sx={accountOpen ? { width: '65px', height: '60px', borderRadius: '0px', backgroundColor: 'rgba(31, 28, 46, 0.08)' } : { width: '65px', height: '60px', borderRadius: '0px' }}>
              <AccountBoxIcon sx={{ width: '30px', height: '30px', mx: 'auto', color: 'black' }} />
            </Button> */}
          </>
        )}
      </Box>
    </>
  );
};

export default Sidebar;
