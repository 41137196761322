import { Box } from '@mui/material';
import isMobile from '../../../hooks/isMobile';
import DesktopTicketSection from './DesktopTicketSection';
import MobileTicketSection from './MobileTicketSection';
import TicketSection from './TicketSection';
import { ITicketsContainer } from '../../../utils/exports/Interface';
// import { Socket } from 'socket.io-client';

const TicketsContainer = ({
  type,
  ticketsData,
  setTicketsData,
  ticketsCount,
  getBoardTickets,
}: ITicketsContainer) => {
  const mobile = isMobile();

  return (
    <>
      {!mobile ? (
        <DesktopTicketSection type={type} getBoardTickets={getBoardTickets}>
          <TicketSection
            type={type}
            ticketsData={ticketsData}
            setTicketsData={setTicketsData}
            ticketsCount={ticketsCount}
            getBoardTickets={getBoardTickets}
          />
        </DesktopTicketSection>
      ) : (
        <Box minWidth='calc(100vw - 70px)'>
          <MobileTicketSection>
            <TicketSection
              type={type}
              ticketsData={ticketsData}
              setTicketsData={setTicketsData}
              ticketsCount={ticketsCount}
              getBoardTickets={getBoardTickets}
            />
          </MobileTicketSection>
        </Box>
      )}
    </>
  );
};

export default TicketsContainer;
