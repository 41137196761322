import { Button } from "@mui/material"
import { useSearchParams } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';

const ClearFilter = () => {

    const [searchParams, setQueryParams] = useSearchParams();

    const clearFilter = () => {
        setQueryParams({});
    };

    if (searchParams) {
        null
    }

    return (
        <>
            <Button
                variant='contained'
                color='error'
                aria-label='clear-filter'
                onClick={clearFilter}
                sx={{ minWidth: '18px', height: '18px', p: '0px' }}
            >
                <ClearIcon sx={{ width: '16px', height: '16px' }} />
            </Button>
        </>
    )
}

export default ClearFilter
