import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import {
  Close as CloseIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Check as CheckIcon,
} from '@mui/icons-material';
import {
  addTopic,
  deleteChapter,
  deleteTopic,
  fetchCourse,
  updateTopic,
} from '../../api/CourseApi';
import { useCourseContext } from '../../context/CourseContext';
import { failed, success } from '../../../hooks/useToast';
import DeletePopUp from './DeletePopUp';

interface Topic {
  content: string;
  id?: number;
}

interface EditChapterDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: { title: string; duration: string; topics: Topic[] }) => void;
  initialData: {
    title: string;
    duration: string;
    topics: Topic[];
  };
  chapterId: number;
}

const EditChapterDialog: React.FC<EditChapterDialogProps> = ({
  open,
  onClose,
  onSubmit,
  initialData,
  chapterId,
}) => {
  const [title, setTitle] = useState('');
  const [duration, setDuration] = useState('');
  const [topics, setTopics] = useState<Topic[]>([]);
  const [newTopic, setNewTopic] = useState('');
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editingContent, setEditingContent] = useState('');
  const [editingId, setEditingId] = useState<number>(0);

  const { setCourseData } = useCourseContext();

  useEffect(() => {
    setTitle(initialData.title);
    setDuration(initialData.duration);
    setTopics(initialData.topics);
  }, [initialData]);

  const handleDeleteChapter = async () => {
    try {
      const response = await deleteChapter(chapterId);

      if (response?.status === 200 || response?.status === 201) {
        success();
        onClose();
        fetchCourse()
          .then((data) => {
            setCourseData(data);
          })
          .catch(() => {
            failed();
          });
      } else {
        failed();
      }
    } catch (error) {
      failed();
    }
  };

  const handleAddTopic = async () => {
    if (newTopic.trim()) {
      setTopics([...topics, { content: newTopic.trim() }]);
      setNewTopic('');
      try {
        const topicInfo = {
          data: {
            title: newTopic.trim(),
            chapter: chapterId,
          },
        };

        const topicResponse = await addTopic(topicInfo);

        if (topicResponse?.status === 200 || topicResponse?.status === 201) {
          success();
          fetchCourse()
            .then((courseData) => {
              setCourseData(courseData);
            })
            .catch(() => {
              failed();
            });
        }
      } catch (error) {
        failed();
      }
    }
  }; 

  const handleDeleteTopic = async (index: number) => {
    const newTopics = [...topics];
    newTopics.splice(index, 1);
    setTopics(newTopics);
    try {
      const response = await deleteTopic(topics[index].id || 0);

      if (response?.status === 200 || response?.status === 201) {
        success();
        fetchCourse()
          .then((data) => {
            setCourseData(data);
          })
          .catch(() => {
            failed();
          });
      } else {
        failed();
      }
    } catch (error) {
      failed();
    }
  };

  const handleStartEdit = (index: number) => {
    setEditingIndex(index);
    setEditingContent(topics[index].content);
    setEditingId(topics[index].id || 0);
  };

  const handleSaveEdit = async () => {
    if (editingIndex !== null && editingContent.trim()) {
      const newTopics = [...topics];
      newTopics[editingIndex] = { id: editingId, content: editingContent.trim() };
      setTopics(newTopics);
      setEditingIndex(null);
      setEditingContent('');
      try {
        const data = {
          data: {
            title: newTopics[editingIndex].content,
          },
        };

        const response = await updateTopic(editingId, data);

        if (response?.status === 200 || response?.status === 201) {
          success();
          fetchCourse()
            .then((data) => {
              setCourseData(data);
            })
            .catch(() => {
              failed();
            });
        } else {
          failed();
        }
      } catch (error) {
        failed();
      }
    }
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
    setEditingContent('');
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      title,
      duration,
      topics,
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          fontWeight: 600,
        }}
      >
        Edit Chapter
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 2 }}>
        <Box component='form' onSubmit={handleSubmit}>
          <Typography variant='subtitle1' sx={{ mb: 1 }}>
            Chapter Title
          </Typography>

          <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <TextField
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder='Chapter1'
              size='small'
            />
            <TextField
              sx={{ width: '150px' }}
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              placeholder='60h'
              size='small'
            />
            <Box sx={{ display: 'flex' }}>
              <IconButton sx={{ mr: 1 }} onClick={handleSubmit}>
                <CheckIcon sx={{width: '16px', height: '16px'}}/>
              </IconButton>
              <DeletePopUp onDelete={handleDeleteChapter} />
            </Box>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1,
              }}
            >
              <Typography variant='subtitle1'>Topics</Typography>
              <IconButton size='small' onClick={handleAddTopic}>
                <AddIcon />
              </IconButton>
            </Box>

            <List
              sx={{
                // border: '1px solid',
                // borderColor: 'divider',
                // borderRadius: 1,
                maxHeight: '200px',
                overflow: 'auto',
              }}
            >
              {topics.map((topic, index) => (
                <ListItem key={index} dense>
                  {editingIndex === index ? (
                    <>
                      <TextField
                        fullWidth
                        size='small'
                        value={editingContent}
                        onChange={(e) => setEditingContent(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSaveEdit()}
                        autoFocus
                      />
                      <IconButton edge='end' size='small' onClick={handleSaveEdit}>
                        <CheckIcon sx={{width: '16px', height: '16px'}}/>
                      </IconButton>
                      <IconButton edge='end' size='small' onClick={handleCancelEdit}>
                        <CloseIcon sx={{width: '16px', height: '16px'}}/>
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <ListItemText primary={topic.content} />
                      <ListItemSecondaryAction>
                        <IconButton edge='end' size='small' onClick={() => handleStartEdit(index)}>
                          <EditIcon sx={{width: '16px', height: '16px'}}/>
                        </IconButton>
                        {/* <IconButton
                          edge='end'
                          size='small'
                          onClick={() => handleDeleteTopic(index)}
                        >
                          <DeleteIcon />
                        </IconButton> */}
                        <DeletePopUp onDelete={() => handleDeleteTopic(index)} />
                      </ListItemSecondaryAction>
                    </>
                  )}
                </ListItem>
              ))}

              <ListItem dense>
                <TextField
                  fullWidth
                  size='small'
                  value={newTopic}
                  onChange={(e) => setNewTopic(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleAddTopic()}
                  placeholder='Add new topic'
                />
              </ListItem>
            </List>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditChapterDialog;
