import SearchIcon from '@mui/icons-material/Search';
import isMobile from '../../hooks/isMobile';
import { SquareIconButton } from '../../utils/exports/Styles';

interface SearchProps {
  onClick: () => void;
}

/* eslint-disable react/prop-types */
const Search: React.FC<SearchProps> = ({ onClick }) => {
  const mobileView = isMobile();
  return (
    <>
      <SquareIconButton
        onClick={onClick}
        sx={{
          color: 'black',
          width: mobileView ? '20px' : '30px',
          height: mobileView ? '20px' : '30px',
        }}
      >
        <SearchIcon />
      </SquareIconButton>
    </>
  );
};

export default Search;
