import { getWeekDates } from '../components/Attendance/Hours';
import httpClient from './httpClient';

const weekDates = getWeekDates();
const nextWeekDates = getWeekDates(1);
const prevWeekDates = getWeekDates(-1);

export const getTicketsService = async (
  userId: number | string, // id if developer, 'sa' if superadmin
  mainTenant: string,
  board: number[] | string[],
  page: number,
  pageSize: number,
  roadmap: boolean,
  status?: string,
  assignee?: string,
  type?: string,
  priority?: string,
  environment?: string,
  dueDate?: string,
) => {
  let url = `/tickets?pagination[page]=${
    page + 1
  }&pagination[pageSize]=${pageSize}&sort=updatedAt%3Adesc&populate=boards&filters[main_tenant][$eq]=${mainTenant}${board?.map(
    (item) =>
      item === 'all'
        ? userId === 'sa'
          ? ''
          : `&filters[$or][0][boards][users][$contains]=${userId}&filters[$or][1][default_board][$eq]=true`
        : item === 0
        ? `&filters[default_board][$eq]=true`
        : `&filters[boards][id][$in]=${item}`,
  )}&populate[chats][fields][0]=sender&populate[attachment][fields][0]=name&populate[attachment][fields][1]=url`;

  if (assignee) {
    url = `${url}&filters[assignees][$contains]=${assignee}`;
  }

  if (type) {
    url = `${url}&filters[type][$eq]=${type}`;
  }

  if (priority) {
    url = `${url}&filters[priority][$eq]=${priority}`;
  }

  if (environment) {
    url = `${url}&filters[environment][$eq]=${
      environment?.toLowerCase() === 'sandbox'
        ? 'Sandbox'
        : environment?.toLowerCase() === 'uat'
        ? 'UAT'
        : environment?.toLowerCase() === 'production'
        ? 'Production'
        : ''
    }`;
  }

  if (status) {
    url = `${url}&filters[status][$eq]=${status}`;
  }

  if (dueDate) {
    if (dueDate === 'asc' || dueDate === 'desc') {
      url = url.replace(/&sort=updatedAt%3Adesc/, '') + `&sort=due_date:${dueDate}`;
    } else if (dueDate === 'current' || dueDate === 'next' || dueDate === 'prev') {
      url = `${url}&filters[due_date][$gte]=${
        dueDate === 'current'
          ? weekDates[0]
          : dueDate === 'next'
          ? nextWeekDates[0]
          : prevWeekDates[0]
      }&filters[due_date][$lte]=${
        dueDate === 'current'
          ? weekDates[6]
          : dueDate === 'next'
          ? nextWeekDates[6]
          : prevWeekDates[6]
      }`;
    } else {
      url = `${url}&filters[due_date][$gte]=${dueDate?.split('/')[0]}`;
    }
  }

  if (roadmap) {
    url =
      url.replace(/&sort=updatedAt%3Adesc/, '') +
      `&filters[due_date][$notNull]=true&sort=due_date%3Adesc`;
  }

  return await httpClient.get(url);
};

export const getTicketsTenantSpecificService = async (
  userId: number | string, // id if developer, 'sa' if superadmin
  tenant: string | [{ alias: string }],
  board: number[] | string[],
  page: number,
  pageSize: number,
  roadmap: boolean,
  status?: string,
  assignee?: string,
  type?: string,
  priority?: string,
  environment?: string,
  dueDate?: string,
) => {
  let url = `/tickets?pagination[page]=${
    page + 1
  }&pagination[pageSize]=${pageSize}&sort=updatedAt%3Adesc&populate[chats][fields][0]=sender&populate[attachment][fields][0]=name&populate[attachment][fields][1]=url&populate=boards`;
  if (tenant) {
    if (typeof tenant === 'object') {
      url = `${url}${tenant.map(
        (value) => `&filters[main_tenant][$eq]=${value?.alias}`,
      )}${board?.map((item) =>
        item === 'all'
          ? userId === 'sa'
            ? ''
            : `&filters[$or][0][boards][users][$contains]=${userId}&filters[$or][1][default_board][$eq]=true`
          : item === 0
          ? `&filters[default_board][$eq]=true`
          : `&filters[boards][id][$in]=${item}`,
      )}`.replace(/,/g, '');
    } else {
      url = `${url}&filters[main_tenant][$eq]=${tenant}${board?.map((item) =>
        item === 'all'
          ? userId === 'sa'
            ? ''
            : `&filters[$or][0][boards][users][$contains]=${userId}&filters[$or][1][default_board][$eq]=true`
          : item === 0
          ? `&filters[default_board][$eq]=true`
          : `&filters[boards][id][$in]=${item}`,
      )}`.replace(/,/g, '');
    }
  }

  if (tenant === null) {
    url = `${url}${userId === 'sa' ? '' : `&filters[boards][users][$contains]=${userId}`}`.replace(
      /,/g,
      '',
    );
  }

  if (assignee) {
    url = `${url}&filters[assignees][$contains]=${assignee}`;
  }

  if (type) {
    url = `${url}&filters[type][$eq]=${type}`;
  }

  if (priority) {
    url = `${url}&filters[priority][$eq]=${priority}`;
  }

  if (environment) {
    url = `${url}&filters[environment][$eq]=${
      environment?.toLowerCase() === 'sandbox'
        ? 'Sandbox'
        : environment?.toLowerCase() === 'uat'
        ? 'UAT'
        : environment?.toLowerCase() === 'prod'
        ? 'Production'
        : ''
    }`;
  }

  if (status) {
    url = `${url}&filters[status][$eq]=${status}`;
  }

  if (dueDate) {
    if (dueDate === 'asc' || dueDate === 'desc') {
      url = url.replace(/&sort=updatedAt%3Adesc/, '') + `&sort=due_date:${dueDate}`;
    } else if (dueDate === 'current' || dueDate === 'next' || dueDate === 'prev') {
      url = `${url}&filters[due_date][$gte]=${
        dueDate === 'current'
          ? weekDates[0]
          : dueDate === 'next'
          ? nextWeekDates[0]
          : prevWeekDates[0]
      }&filters[due_date][$lte]=${
        dueDate === 'current'
          ? weekDates[6]
          : dueDate === 'next'
          ? nextWeekDates[6]
          : prevWeekDates[6]
      }`;
    } else {
      url = `${url}&filters[due_date][$gte]=${dueDate?.split('/')[0]}`;
    }
  }

  if (roadmap) {
    url =
      url.replace(/&sort=updatedAt%3Adesc/, '') +
      `&filters[due_date][$notNull]=true&sort=due_date%3Adesc`;
  }

  return await httpClient.get(url);
};

export const addMessageService = async (payload: object) => {
  return await httpClient.post('/chats', payload);
};
