import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  RoomParticipant,
  RoomData,
  RemovedParticipant,
  FavoritesData,
} from '../constants/constants';

interface RoomState {
  rooms: RoomData[];
  searchedRooms: RoomData[][];
  currentRoom: RoomData | null;
  selectedChat: RoomData | null;
  total: number;
  page: number;
  limit: number;
  loading: boolean;
  error: string | null;
  searchOpen: boolean;
  favorites: FavoritesData[];
}

const initialState: RoomState = {
  rooms: [],
  searchedRooms: [],
  currentRoom: null,
  selectedChat: null,
  total: 0,
  page: 1,
  limit: 10,
  loading: false,
  error: null,
  searchOpen: false,
  favorites: [],
};

const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setRooms(
      state,
      action: PayloadAction<{ rooms: RoomData[]; total: number; page: number; limit: number }>,
    ) {
      state.rooms = action.payload.rooms;
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
    },
    setSearchedRooms(state, action: PayloadAction<{ rooms: RoomData[][] }>) {
      state.searchedRooms = action.payload.rooms;
    },
    addRoom(state, action: PayloadAction<any>) {
      state.rooms.push(action.payload);
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setRoomDetails(state, action: PayloadAction<RoomData>) {
      state.currentRoom = action.payload;
    },
    setSearchOpen(state, action: PayloadAction<boolean>) {
      state.searchOpen = action.payload;
    },
    addRoomParticipants(
      state,
      action: PayloadAction<{ roomId: number; participants: RoomParticipant[] }>,
    ) {
      const room = state.rooms.find((room) => room.id === action.payload.roomId);
      if (room) {
        room.roomParticipants.push(...action.payload.participants);
      }
    },
    removeRoomParticipants(
      state,
      action: PayloadAction<{ roomId: number; removedParticipants: RemovedParticipant[] }>,
    ) {
      const room = state.rooms.find((room) => room.id === action.payload.roomId);
      if (room && room.roomParticipants) {
        room.roomParticipants = room.roomParticipants.filter(
          (participant) =>
            !action.payload.removedParticipants.some(
              (removedParticipant) => removedParticipant.id === participant.id,
            ),
        );
      }
    },
    updateRoomDetails(state, action: PayloadAction<RoomData>) {
      // Update room in rooms array
      const index = state.rooms.findIndex((room) => room.id === action.payload.id);
      if (index !== -1) {
        state.rooms[index] = {
          ...state.rooms[index],
          roomName: action.payload.roomName,
          avatar: action.payload.avatar,
          updatedAt: action.payload.updatedAt,
        };
      }

      // Update currentRoom if it matches the updated room
      if (state.currentRoom && state.currentRoom.id === action.payload.id) {
        state.currentRoom = {
          ...state.currentRoom,
          roomName: action.payload.roomName,
          avatar: action.payload.avatar,
          updatedAt: action.payload.updatedAt,
        };
      }
    },
    removeRoom(state, action: PayloadAction<number>) {
      // Remove room from rooms array
      state.rooms = state.rooms.filter((room) => room.id !== action.payload);

      // If the current room is the one being removed, set it to null
      if (state.currentRoom && state.currentRoom.id === action.payload) {
        state.currentRoom = null;
      }

      // Remove room from searchedRooms if present
      state.searchedRooms = state.searchedRooms.map((roomGroup) =>
        roomGroup.filter((room) => room.id !== action.payload),
      );
    },
    setSelectedChat(state, action: PayloadAction<RoomData | null>) {
      state.selectedChat = action.payload;
    },
    setFavorites(state, action: PayloadAction<FavoritesData[]>) {
      state.favorites = action.payload;
    },
    addFavorite(state, action: PayloadAction<FavoritesData>) {
      state.favorites.push(action.payload);
    },
    removeFavorite(state, action: PayloadAction<number>) {
      state.favorites = state.favorites.filter((fav) => fav.id !== action.payload);
    },
  },
});

export const {
  setRooms,
  setSearchedRooms,
  setLoading,
  setError,
  setRoomDetails,
  addRoom,
  addRoomParticipants,
  removeRoomParticipants,
  updateRoomDetails,
  removeRoom,
  setSelectedChat,
  setSearchOpen,
  setFavorites,
  addFavorite,
  removeFavorite,
} = roomSlice.actions;

export default roomSlice.reducer;
