import { Box, Typography } from '@mui/material';
import moment from 'moment';
import isSuperAdmin from '../hooks/isSuperAdmin';
import Pills from './Pills';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import ViewAttachments from './Desktop/ViewAttachments';
import isDeveloper from '../hooks/isDeveloper';
import { IChatData } from '../utils/exports/Interface';
import { UserListContext } from '../routes/Main';

const MessageBox = (props: { message: IChatData }) => {
  const { userList } = useContext(UserListContext);

  const superAdmin = isSuperAdmin();
  const developer = isDeveloper();

  const formatDate = (value: string, locale = 'en-GB') => {
    return new Date(value).toLocaleDateString(locale);
  };

  const adminMessage = props.message.attributes.admin;
  const message = props.message.attributes.message;
  const sender = props.message.attributes.sender;
  const createdAt = props.message.attributes.createdAt;
  const time = moment(createdAt).format('h:mm a');
  const date = formatDate(createdAt);

  const [attachments, setAttachments] = useState<any>();

  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_strapiUrl}/chats/${props?.message?.id}?populate=*`, {
        headers: headers,
      })
      .then((res) => setAttachments(res?.data?.data?.attributes?.attachments?.data));
  }, [props.message]);

  const getUrl = (data: {
    attributes: {
      url: string;
    };
  }) => {
    return data?.attributes?.url;
  };

  const getImages = (data: {
    attributes: {
      url: string;
    };
  }) => {
    return `${process.env.REACT_APP_strapiImage}${data?.attributes?.url}`;
  };

  const url =
    attachments !== null && attachments?.length > 0 ? attachments?.map(getUrl) : 'undefined';
  const images =
    attachments !== null && attachments?.length > 0 ? attachments?.map(getImages) : 'undefined';

  const isLegitMention = (mention: string) => {
    return userList?.data?.users?.users
      ?.filter((user: { name: string }) => user?.name === mention?.split('@')[1])
      ?.map((user: { id: number }) => user?.id)
      ?.some((value: number) => props?.message?.attributes?.tagged_users?.includes(value));
  };

  const processMessage = (message: string) => {
    let processedMessage = message.replace(/<p>/g, '<span>').replace(/<\/p>/g, '</span>');

    processedMessage = processedMessage.replace(/~(.*?)~/g, (match, content) => {
      if (isLegitMention(content)) {
        return `<span style="background-color: #e0f7fa; color: #00796b; padding: 5px; border-radius: 5px;">${content}</span>`;
      }
      return match;
    });

    return processedMessage;
  };

  processMessage(message);

  return (
    <>
      <Box
        display='flex'
        p='10px'
        borderBottom='solid'
        borderColor='lightgray'
        position='relative'
        sx={{ borderWidth: '1px' }}
      >
        <Box display='flex' width='85%' flexDirection='column' gap='12px'>
          <Box display='flex' gap='5px'>
            <Typography
              whiteSpace='pre-wrap'
              fontSize='12px'
              sx={{
                wordBreak: 'break-word',
              }}
              dangerouslySetInnerHTML={{
                __html: processMessage(message),
              }}
            />
            {attachments !== null && attachments !== undefined ? (
              <ViewAttachments url={url} images={images} name='SOS' />
            ) : (
              ''
            )}
          </Box>
          <Box display='flex' gap='10px'>
            <Typography fontWeight='bold' fontSize='12px'>
              {(superAdmin || developer) && adminMessage
                ? `${sender} (Admin)`
                : !superAdmin && !developer && adminMessage
                ? 'Admin'
                : sender}
            </Typography>
            {props?.message?.attributes?.changed_to && (
              <Pills pill={props?.message?.attributes?.changed_to} />
            )}
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          gap='3px'
          textAlign='end'
          position='absolute'
          bottom='10px'
          right='0px'
        >
          <Typography fontSize='12px'>{date}</Typography>
          <Typography fontSize='12px'>{time}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default MessageBox;
