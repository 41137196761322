/* eslint-disable react/prop-types */
import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import Setting from './Setting';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import AudioCall from './AudioCall';
import VideoCall from './VideoCall';
import ScreenShare from './ScreenShare';
import isMobile from '../../hooks/isMobile';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { RoomData } from '../../store/constants/constants';
import { useContext, useEffect, useState } from 'react';
import { UserDataContext } from '../../routes/Main';
import Search from './Search';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRoomDetails } from '../../store/room/roomAction';
import { debounce } from 'lodash';
import { SocketContext } from '../../utils/SocketProvider';
import { stringToColor } from '../../utils/exports/Function';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { SquareIconButton } from '../../utils/exports/Styles';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 3px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""',
    },
  },
}));

interface ChatBoxHeadingProps {
  chat: RoomData | null;
  onBackClick: () => void;
  onSearchClick: () => void;
  onCloseSearch: () => void;
  setShowPinnedContext: (show: boolean) => void;
}

const ChatBoxHeading: React.FC<ChatBoxHeadingProps> = ({
  chat,
  onBackClick,
  onSearchClick,
  onCloseSearch,
  setShowPinnedContext,
}) => {
  const mobileView = isMobile();
  const { userData } = useContext(UserDataContext);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const currentRoom = useSelector((state: RootState) => state.room.currentRoom);
  const searchStatus = useSelector((state: RootState) => state.message.searchStatus);
  const dispatch: AppDispatch = useDispatch();
  const socket = useContext(SocketContext);

  const fetchRoomDetailsDebounced = debounce(() => {
    if (chat?.id && socket) {
      dispatch(fetchRoomDetails({ socket, payload: { id: chat.id } }));
    }
  }, 1000);
  useEffect(() => {
    fetchRoomDetailsDebounced();

    // Cleanup the debounce on unmount
    return () => {
      fetchRoomDetailsDebounced.cancel();
    };
  }, [chat?.id, dispatch, socket, currentRoom]);

  const getInitials = (name: string) => {
    const parts = name.trim().split(/\s+/);

    if (parts.length === 1) {
      return parts[0].slice(0, 2).toUpperCase();
    }
    return `${parts[0][0]}${parts[parts.length - 1][0]}`.toUpperCase();
  };

  const handleNameClick = () => {
    setIsSettingsOpen(true);
  };

  return (
    <>
      <Box
        width='100%'
        position={mobileView ? 'fixed' : 'inherit'}
        top='0'
        bgcolor='white'
        borderRadius={mobileView ? '0' : '15px'}
        height={mobileView ? '50px' : '48px'}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        zIndex={3}
        borderBottom={mobileView ? '1px solid lightgray' : 'none'}
      >
        <Box display='flex' alignItems='center' gap='6px' sx={{ paddingLeft: '5px' }}>
          {mobileView && <ArrowBackIosIcon onClick={onBackClick} />}
          <Box
            sx={{
              // position: 'absolute',
              // left: '50%',
              // top: '70%',
              // transform: 'translate(-50%, -50%)',
              zIndex: 2,
            }}
          >
            {chat?.roomType === 'group' ? (
              <Avatar
                src={`https://chatroom.enitiation.com/${chat?.avatar}`}
                alt='dtvu'
                sx={{
                  alignSelf: 'center',
                  width: '36px',
                  height: '36px',
                  fontSize: '1rem',
                  // marginTop: '30px',
                  // boxShadow: '0 0 4px 4px #fff, 0 0 4px 4px #fff',
                  bgcolor: stringToColor(chat?.roomName || 'Group Chat'),
                }}
              >
                {getInitials(currentRoom?.roomName || 'Group Chat')}
              </Avatar>
            ) : (
              <StyledBadge
                sx={{
                  '& .MuiBadge-badge': {
                    color: currentRoom?.roomParticipants?.find(
                      (participant) => participant.user.userId !== userData.data.id,
                    )?.user?.isActive
                      ? '#28a745'
                      : '#808080',
                    backgroundColor: currentRoom?.roomParticipants?.find(
                      (participant) => participant.user.userId !== userData.data.id,
                    )?.user?.isActive
                      ? '#28a745'
                      : '#808080',

                    // borderRadius: '5px',
                  },
                }}
                overlap='circular'
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant='dot'
              >
                <Avatar
                  src={
                    currentRoom?.roomParticipants?.find(
                      (participant) => participant.user.userId !== userData.data.id,
                    )?.user?.profilePictureURL
                  }
                  alt='dtvu'
                  sx={{
                    alignSelf: 'center',
                    width: '38px',
                    height: '38px',
                    fontSize: '1rem',
                    boxShadow: '0 0 4px 4px #fff, 0 0 4px 4px #fff',
                    bgcolor: stringToColor(
                      chat?.roomParticipants?.find(
                        (participant) => participant.user.userId !== userData.data.id,
                      )?.user?.userName || 'User',
                    ),
                  }}
                >
                  {getInitials(
                    currentRoom?.roomParticipants?.find(
                      (participant) => participant.user.userId !== userData.data.id,
                    )?.user?.userName || 'User',
                  )}
                </Avatar>
              </StyledBadge>
            )}
          </Box>
          <Box
            display='flex'
            flexDirection={mobileView ? 'column' : 'row'}
            alignItems='center'
            sx={{ cursor: 'default' }}
            // onClick={handleNameClick}
          >
            {chat?.roomType === 'group' ? (
              <>
                <Typography
                  variant='h6'
                  sx={{
                    fontSize: '16px',
                    fontWeight: '600',
                    maxWidth: mobileView ? '113px' : '180px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {currentRoom?.roomName}
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ ml: mobileView ? 0 : '6px' }}
                >
                  ({currentRoom?.roomParticipants.length} participants)
                </Typography>
              </>
            ) : (
              <Typography variant='h6' sx={{ fontSize: '16px', fontWeight: '600' }}>
                {currentRoom?.roomParticipants?.find(
                  (participant) => participant.user.userId !== userData.data.id,
                )?.user?.userName || 'Unknown User'}
              </Typography>
            )}
          </Box>
        </Box>

        {/* <Box
          sx={{
            // position: 'absolute',
            // left: '50%',
            // top: '70%',
            // transform: 'translate(-50%, -50%)',
            zIndex: 2,
          }}
        >
          {chat?.roomType === 'group' ? (
            <Avatar
              src={`https://chatroom.enitiation.com/${chat?.avatar}`}
              alt='dtvu'
              sx={{
                alignSelf: 'center',
                width: '44px',
                height: '44px',
                // marginTop: '30px',
                // boxShadow: '0 0 4px 4px #fff, 0 0 4px 4px #fff',
                bgcolor: stringToColor(chat?.roomName || 'Group Chat'),
              }}
            >
              {getInitials(currentRoom?.roomName || 'Group Chat')}
            </Avatar>
          ) : (
            <StyledBadge
              sx={{
                '& .MuiBadge-badge': {
                  color: currentRoom?.roomParticipants?.find(
                    (participant) => participant.user.userId !== userData.data.id,
                  )?.user?.isActive
                    ? '#28a745'
                    : '#808080',
                  backgroundColor: currentRoom?.roomParticipants?.find(
                    (participant) => participant.user.userId !== userData.data.id,
                  )?.user?.isActive
                    ? '#28a745'
                    : '#808080',
                  width: '9px',
                  height: '9px',
                  // borderRadius: '5px',
                },
              }}
              overlap='circular'
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant='dot'
            >
              <Avatar
                src={
                  currentRoom?.roomParticipants?.find(
                    (participant) => participant.user.userId !== userData.data.id,
                  )?.user?.profilePictureURL
                }
                alt='dtvu'
                sx={{
                  alignSelf: 'center',
                  width: '52px',
                  height: '52px',
                  marginTop: '30px',
                  boxShadow: '0 0 4px 4px #fff, 0 0 4px 4px #fff',
                  bgcolor: stringToColor(
                    chat?.roomParticipants?.find(
                      (participant) => participant.user.userId !== userData.data.id,
                    )?.user?.userName || 'User',
                  ),
                }}
              >
                {getInitials(
                  currentRoom?.roomParticipants?.find(
                    (participant) => participant.user.userId !== userData.data.id,
                  )?.user?.userName || 'User',
                )}
              </Avatar>
            </StyledBadge>
          )}
        </Box> */}

        <Box display='flex' gap='15px' mr='5px'>
          {searchStatus ? <Search onClick={onCloseSearch} /> : <Search onClick={onSearchClick} />}
          <ScreenShare />

          <AudioCall />
          <VideoCall />
          <Box
            sx={{
              width: '1px',
              height: mobileView ? '20px' : '30px',
              backgroundColor: 'lightgray',
            }}
          />
          <Tooltip title='Media'>
            <SquareIconButton
              onClick={handleNameClick}
              sx={{
                color: 'black',
                width: mobileView ? '20px' : '30px',
                height: mobileView ? '20px' : '30px',
              }}
            >
              <MenuOpenIcon />
            </SquareIconButton>
          </Tooltip>
          <Setting
            isOpen={isSettingsOpen}
            onClose={() => setIsSettingsOpen(false)}
            chats={chat}
            setShowPinnedContext={setShowPinnedContext}
          />
        </Box>
      </Box>
    </>
  );
};

export default ChatBoxHeading;
