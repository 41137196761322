/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import * as d3 from 'd3';
import ReactDOM from 'react-dom/client';
import { Box, Button, Slide, Typography } from '@mui/material';
import AssignDeveloper from './AssignDeveloper';
import {
  // addWeeks,
  // subWeeks,
  startOfWeek,
  endOfWeek,
  // addMonths,
  // subMonths,
  // addQuarters,
  // subQuarters,
} from 'date-fns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getDatesInRange } from './Attendance/Hours';
import { UserDataContext } from '../routes/Main';
import { BoardContext, LoadingContext, TenantContext } from '../App';
import { getTicketsService, getTicketsTenantSpecificService } from '../services/ticketService';
import isSuperAdmin from '../hooks/isSuperAdmin';
import isDeveloper from '../hooks/isDeveloper';
import { ITicketData } from '../utils/exports/Interface';
import striptags from 'striptags';
import TicketDetails from './TicketDetails';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterStatus from './FilterStatus';
import FilterAssignees from './FilterAssignees';
import { useSearchParams } from 'react-router-dom';

const RoadmapChart = () => {
  const { userData } = useContext(UserDataContext);
  const { setLoading } = useContext(LoadingContext);
  const { tenant } = useContext(TenantContext);
  const { board } = useContext(BoardContext);

  const superAdmin = isSuperAdmin();
  const developer = isDeveloper();

  const defaultStartDate = startOfWeek(new Date());
  const defaultEndDate = endOfWeek(new Date());
  const svgRef = useRef<SVGSVGElement | null>(null);
  // const tooltipRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<SVGSVGElement | null>(null);
  const [sprintStartDate, setSprintStartDate] = useState<Date>(defaultStartDate);
  const [sprintEndDate, setSprintEndDate] = useState<Date>(defaultEndDate);

  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState(searchParams?.get('status'));
  const [assignees, setAssignees] = useState(searchParams.get('assignees'));

  useEffect(() => {
    setStatus(searchParams?.get('status'));
    setAssignees(searchParams.get('assignees'));
  }, [searchParams]);

  const [roadmapTickets, setRoadmapTickets] = useState<ITicketData[]>([]);

  const getRoadmapTickets = async () => {
    if (!userData) {
      return;
    }
    setLoading(true);
    const res = await getTicketsService(
      superAdmin ? 'sa' : userData?.data?.id,
      userData.data.organizations[0]?.alias ?? false,
      board,
      0,
      999,
      true,
    );
    if (res?.data) {
      setRoadmapTickets(res?.data?.data);
      setLoading(false);
    }
  };

  const getTenantRoadmapTickets = async () => {
    if (!userData) {
      return;
    }
    setLoading(true);
    const res = await getTicketsTenantSpecificService(
      superAdmin ? 'sa' : userData?.data?.id,
      tenant === 'all' ? null : tenant,
      board,
      0,
      999,
      true,
      status || undefined,
      assignees || undefined,
      undefined,
      undefined,
      undefined,
      `${new Date(sprintStartDate).toISOString().split('T')[0]}/${
        new Date(sprintEndDate).toISOString().split('T')[0]
      }`,
    );
    if (res?.data) {
      setRoadmapTickets(res?.data?.data);
      setLoading(false);
    }
  };

  const fetchTicketsHomePage = () => {
    if (!superAdmin && !developer) {
      getRoadmapTickets();
      return;
    }
    getTenantRoadmapTickets();
  };

  useEffect(() => {
    fetchTicketsHomePage();
  }, [userData, superAdmin, tenant, board, sprintStartDate, sprintEndDate, status, assignees]);

  const colorMap = [
    { name: 'backlog', rgb: 'rgb(81, 91, 82)' },
    { name: 'on hold', rgb: 'rgb(116, 124, 117)' },
    { name: 'todo', rgb: 'rgb(147, 167, 149)' },
    { name: 'in progress', rgb: 'rgb(255, 122, 0)' },
    { name: 'pr', rgb: 'rgb(20, 104, 182)' },
    { name: 'pr done', rgb: 'rgb(0, 133, 255)' },
    { name: 'done', rgb: 'rgb(14, 217, 205)' },
    { name: 'redo', rgb: 'rgb(227, 0, 0)' },
    { name: 'uat', rgb: 'rgb(0, 227, 9)' },
    { name: 'production', rgb: 'rgb(3, 192, 60)' },
    { name: 'closed', rgb: 'rgb(0, 128, 13)' },
    { name: 'canceled', rgb: 'rgb(0, 0, 0)' },
  ];

  const getColorForStatus = (status: string): string => {
    const statusColor = colorMap.find((color) => color.name === status);
    return statusColor ? statusColor.rgb : 'rgb(81, 91, 82)';
  };

  const tickets = roadmapTickets.map((ticket) => ({
    ticketId: ticket.attributes.ticket_id,
    taskId: ticket.attributes.tenantwise_id,
    name: striptags(`${ticket.attributes.description}`),
    startDate: new Date(ticket.attributes.createdAt?.split('T')[0]),
    endDate: new Date(
      new Date(ticket.attributes.due_date).setDate(
        new Date(ticket.attributes.due_date).getDate() + 1,
      ),
    ),
    dueDate: ticket.attributes.due_date,
    createdAt: ticket.attributes.createdAt?.split('T')[0],
    // status: ticket.attributes.status,
    status: ticket.attributes.status === 'in progress' ? 'wip' : ticket.attributes.status,
    color: getColorForStatus(ticket.attributes.status),
    assignees: ticket.attributes.assignees,
    // color: colors[index % colors.length],
  }));

  const datesInRange = getDatesInRange(
    new Date(sprintStartDate).toISOString().split('T')[0],
    new Date(sprintEndDate).toISOString().split('T')[0],
  );

  const [ticketOpen, setTicketOpen] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState<string>();

  const renderChart = useCallback(() => {
    if (!tickets || !svgRef.current || !tableRef.current) return;

    const svgTable = d3.select(tableRef.current);
    const svgChart = d3.select(svgRef.current);

    svgTable.selectAll('*').remove();
    svgChart.selectAll('*').remove();

    const filteredTickets = tickets.filter(
      (task) =>
        (task.startDate >= sprintStartDate && task.startDate <= sprintEndDate) ||
        (task.endDate >= sprintStartDate && task.endDate <= sprintEndDate) ||
        (task.startDate < sprintStartDate && task.endDate > sprintEndDate),
    );

    const margin = { top: 45, right: 0, bottom: 5, left: 0 };
    // const width = 1000 - margin.left - margin.right;
    const width = window.innerWidth - 350;
    const barHeight = 35;

    const totalBars = filteredTickets.length;
    const dynamicHeight = totalBars * barHeight;
    const height = dynamicHeight + margin.top + margin.bottom;

    const taskIds = filteredTickets.map((task) => task.taskId);

    const clipDate = (date: Date, start: Date, end: Date) => {
      if (date < start) return start;
      if (date > end) return end;
      return date;
    };

    // Y scale for both chart and table
    const y = d3.scaleBand().domain(taskIds).range([0, dynamicHeight]).padding(0.1);

    // Table View (left side)
    const tableGroup = svgTable
      .attr('width', 255)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(0, 49)`);

    // Add column headers
    tableGroup
      .append('g')
      .attr('class', 'table-headers')
      .attr('transform', `translate(0, -10)`)
      .selectAll('text')
      .data(['Ticket ID', 'Status', 'Assignee'])
      .enter()
      .append('text')
      .attr('x', (d, i) => i * 85)
      .attr('y', 0)
      .style('font-size', '13px')
      .style('text-anchor', 'start');

    // Add rows for each ticket
    tableGroup
      .selectAll('g.row')
      .data(filteredTickets)
      .enter()
      .append('g')
      .attr('class', 'row')
      .attr('transform', (d) => `translate(0, ${y(d.taskId)!})`)
      .each(function (d) {
        const row = d3.select(this);

        // Ticket ID
        row
          .append('text')
          .attr('x', 0)
          .attr('y', y.bandwidth() / 2)
          .attr('dy', '.35em')
          .text(d.taskId)
          .style('font-size', '14px')
          .style('text-anchor', 'start');

        // Status (foreignObject with flexbox for vertical alignment)
        row
          .append('foreignObject')
          .attr('x', 75)
          .attr('y', 0)
          .attr('width', '78px')
          .attr('height', '40px')
          .append('xhtml:div')
          .style('background-color', d.color)
          .style('color', '#fff')
          .style('border-radius', '5px')
          .style('display', 'flex') // Center content vertically
          .style('align-items', 'center') // Vertical alignment
          .style('justify-content', 'center') // Horizontal alignment
          .style('font-size', '11px')
          .style('font-weight', 'bold')
          .text(d.status.toUpperCase());

        // Assignee Avatar Container
        row
          .append('foreignObject')
          .attr('x', 160)
          .attr('y', 0)
          .attr('width', '85px')
          .attr('height', '40px')
          .append('xhtml:div')
          .attr('class', `avatar-container avatar-${d.taskId}`)
          .style('display', 'flex') // Center avatar within the container
          .style('align-items', 'center')
          .style('justify-content', 'center');

        // Row Separator Line
        row
          .append('line')
          .attr('x1', 0)
          .attr('y1', y.bandwidth())
          .attr('x2', 265)
          .attr('y2', y.bandwidth())
          .attr('stroke', '#ccc')
          .attr('stroke-width', 1);
      });

    // Create Gantt Chart (right side)
    const chartGroup = svgChart
      .attr('width', 'calc(100dvw - 350px)')
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x = d3.scaleTime().domain([sprintStartDate, sprintEndDate]).range([0, width]);

    const xAxis = d3
      .axisTop(x)
      .ticks(d3.timeDay.every(1))
      .tickFormat(d3.timeFormat('%a %d %b') as any);

    // Add alternating background colors for each date
    const days = d3.timeDays(sprintStartDate, sprintEndDate);

    chartGroup
      .selectAll('rect.background-column')
      .data(days)
      .enter()
      .append('rect')
      .attr('class', 'background-column')
      .attr('x', (d) => x(d))
      .attr('y', 0)
      .attr('width', (d) => x(d3.timeDay.offset(d, 1)) - x(d))
      .attr('height', height)
      .attr('fill', (d, i) => (i % 2 === 0 ? '#e0e0e0' : '#ffff'));

    // Draw bars for the tasks
    const bars = chartGroup
      .selectAll('g.bar-group')
      .data(filteredTickets)
      .enter()
      .append('g')
      .attr('class', 'bar-group');

    // Append underline
    bars
      .append('line')
      .attr('x1', x(sprintStartDate))
      .attr('x2', x(sprintEndDate))
      .attr('y1', (d) => y(d.taskId)! + barHeight)
      .attr('y2', (d) => y(d.taskId)! + barHeight)
      .attr('stroke', '#ccc')
      .attr('stroke-width', 1);

    // Append the main bar as a rectangle or path
    bars
      .append('path')
      .attr('class', 'bar')
      .attr('d', (d) => {
        const initialStartDate = new Date(clipDate(d.startDate, sprintStartDate, sprintEndDate));
        initialStartDate.setHours(5);
        initialStartDate.setMinutes(45);
        const clippedStartDate = initialStartDate;
        const initialEndDate = new Date(clipDate(d.endDate, sprintStartDate, sprintEndDate));
        initialEndDate.setHours(5);
        initialEndDate.setMinutes(45);

        const clippedEndDate = initialEndDate;
        const xStart = x(d3.timeDay.floor(clippedStartDate));
        const yPos = y(d.taskId)! + 3;
        const barWidth = x(d3.timeDay.ceil(clippedEndDate)) - x(d3.timeDay.ceil(clippedStartDate));
        const radius = 5;

        // Define path for rounded right-side rectangle
        return `
      M ${xStart},${yPos}
      h ${barWidth - radius}
      a ${radius},${radius} 0 0 1 ${radius},${radius}
      v ${barHeight - 5 - 2 * radius}
      a ${radius},${radius} 0 0 1 -${radius},${radius}
      h -${barWidth - radius}
      z
    `;
      })
      .attr('fill', (d) => d.color)
      .style('cursor', 'pointer')
      .on('click', function (event, d) {
        // d3.select(tooltipRef.current).style('opacity', 0);

        // setTimeout(() => {
        setTicketOpen(true);
        setSelectedTicketId(d.ticketId);
        // }, 100);
      });

    // Append arrows inside the bar with padding
    bars.each(function (d) {
      const barGroup = d3.select(this);
      const initialStartDate = new Date(clipDate(d.startDate, sprintStartDate, sprintEndDate));
      initialStartDate.setHours(5);
      initialStartDate.setMinutes(45);
      const clippedStartDate = initialStartDate;
      const initialEndDate = new Date(clipDate(d.endDate, sprintStartDate, sprintEndDate));
      initialEndDate.setHours(5);
      initialEndDate.setMinutes(45);
      const clippedEndDate = initialEndDate;
      const xStart = x(d3.timeDay.floor(clippedStartDate));
      const yPos = y(d.taskId)!;
      const barWidth = x(d3.timeDay.ceil(clippedEndDate)) - x(d3.timeDay.ceil(clippedStartDate));
      const arrowSize = 6; // Size of the arrows
      const padding = 10; // Padding between arrow and the bar edge

      // Adjust arrows to be inside the bar with padding
      const leftArrowX = xStart + padding; // Arrow shifted inside with padding
      const rightArrowX = xStart + barWidth - arrowSize - padding; // Arrow shifted inside with padding

      // Append left arrow
      if (new Date(d.startDate) < new Date(sprintStartDate)) {
        barGroup
          .append('polygon')
          .attr(
            'points',
            `${leftArrowX},${yPos + barHeight / 2}
                      ${leftArrowX + arrowSize},${yPos + barHeight / 2 - arrowSize}
                      ${leftArrowX + arrowSize},${yPos + barHeight / 2 + arrowSize}`,
          )
          .attr('fill', 'white');
      }

      const newSprintEndDate = new Date(sprintEndDate);
      newSprintEndDate.setHours(5);
      newSprintEndDate.setMinutes(45);

      // Append right arrow
      if (new Date(d.endDate) > newSprintEndDate) {
        barGroup
          .append('polygon')
          .attr(
            'points',
            `${rightArrowX},${yPos + barHeight / 2}
                      ${rightArrowX - arrowSize},${yPos + barHeight / 2 - arrowSize}
                      ${rightArrowX - arrowSize},${yPos + barHeight / 2 + arrowSize}`,
          )
          .attr('fill', 'white');
      }
    });

    // Function to calculate bar width
    function getBarWidth(d: { startDate: Date; endDate: Date }): number {
      const initialStartDate = new Date(clipDate(d.startDate, sprintStartDate, sprintEndDate));
      initialStartDate.setHours(5);
      initialStartDate.setMinutes(45);
      const clippedStartDate = initialStartDate;
      const initialEndDate = new Date(clipDate(d.endDate, sprintStartDate, sprintEndDate));
      initialEndDate.setHours(5);
      initialEndDate.setMinutes(45);
      const clippedEndDate = initialEndDate;
      return x(d3.timeDay.floor(clippedEndDate)) - x(d3.timeDay.floor(clippedStartDate)) - 10;
    }

    chartGroup
      .selectAll('text.task-label')
      .data(filteredTickets)
      .enter()
      .append('text')
      .attr('class', 'task-label')
      .attr('x', (d) => {
        const initialStartDate = new Date(clipDate(d.startDate, sprintStartDate, sprintEndDate));
        initialStartDate.setHours(5);
        initialStartDate.setMinutes(45);
        const clippedStartDate = initialStartDate;
        const padding = 25; // Padding from the left edge of the bar
        return x(d3.timeDay.floor(clippedStartDate)) + padding; // Add padding to the x position
      })
      .attr('y', (d) => y(d.taskId)! + y.bandwidth() / 2)
      .attr('dy', '.35em')
      .each(function (d) {
        const labelText = d3.select(this);
        const barWidth = getBarWidth(d);
        let truncatedText = d.name;

        // Set the initial full text
        labelText.text(truncatedText);

        // Truncate if the initial text exceeds the bar width, considering padding
        const padding = 25; // Padding from the left edge of the bar
        while (
          labelText.node()!.getComputedTextLength() > barWidth - padding &&
          truncatedText.length > 0
        ) {
          truncatedText = truncatedText.slice(0, -1).trim(); // Remove last character
          labelText.text(truncatedText + '...');
        }

        // Final consistency check to ensure the text fits
        if (labelText.node()!.getComputedTextLength() > barWidth - padding) {
          truncatedText = truncatedText.slice(0, -1).trim(); // Trim one more time if needed
          labelText.text(truncatedText + '...');
        }
      })
      .style('fill', '#ffff')
      .style('font-size', '15px')
      .style('text-anchor', 'start')
      .style('cursor', 'pointer')
      .on('click', function (event, d) {
        // d3.select(tooltipRef.current).style('opacity', 0);
        // setTimeout(() => {
        setTicketOpen(true);
        setSelectedTicketId(d.ticketId);
        // }, 100);
      });

    // bars
    //   .on('mouseover', function (event, d) {
    //     d3.select(tooltipRef.current)
    //       .style('opacity', 1)
    //       .html(` ${d.name}`)
    //       .style('left', `${event.pageX + 10}px`)
    //       .style('top', `${event.pageY - 28}px`);
    //   })
    //   .on('mousemove', function (event) {
    //     d3.select(tooltipRef.current)
    //       .style('left', `${event.pageX + 10}px`)
    //       .style('top', `${event.pageY - 28}px`);
    //   })
    //   .on('mouseout', function () {
    //     d3.select(tooltipRef.current).style('opacity', 0);
    //   });

    // label
    //   .on('mouseover', function (event, d) {
    //     d3.select(tooltipRef.current)
    //       .style('opacity', 1)
    //       .html(`${d.name}`)
    //       .style('left', `${event.pageX + 10}px`)
    //       .style('top', `${event.pageY - 28}px`);
    //   })
    //   .on('mousemove', function (event) {
    //     d3.select(tooltipRef.current)
    //       .style('left', `${event.pageX + 10}px`)
    //       .style('top', `${event.pageY - 28}px`);
    //   })
    //   .on('mouseout', function () {
    //     d3.select(tooltipRef.current).style('opacity', 0);
    //   });

    // const tooltip = d3
    //   .select('body')
    //   .append('div')
    //   .attr('class', 'tooltip')
    //   .style('position', 'absolute')
    //   .style('text-align', 'left')
    //   .style('padding-left', '5px')
    //   .style('padding-right', '5px')
    //   .style('background', '#333')
    //   .style('color', '#fff')
    //   .style('border-radius', '3px')
    //   .style('opacity', 0);
    // tooltipRef.current = tooltip.node() as HTMLDivElement;

    const xAxisGroup = chartGroup.append('g').call(xAxis);
    const tickWidth = x(days[1]) - x(days[0]);

    if (datesInRange?.length <= 15) {
      xAxisGroup
        .selectAll('.tick text')
        .style('font-size', '12px')
        .style('text-anchor', 'middle')
        .attr('transform', `translate(${tickWidth / 2}, 0)`)
        .each(function (d: any) {
          // Split the text into two lines if necessary
          const text = d.toString().split('2024')[0];
          const firstLine = text.slice(0, Math.floor(3));
          const secondLine = text.slice(Math.floor(4));

          d3.select(this).text('');

          // Create the two lines with tspan
          d3.select(this).append('tspan').attr('x', 0).attr('dy', -15).text(firstLine);

          d3.select(this)
            .append('tspan')
            .attr('x', 0)
            .attr('dy', '1.2em') // Adjust line spacing
            .text(secondLine);
        });
    } else {
      xAxisGroup
        .selectAll('.tick text')
        .style('font-size', '10px')
        .style('text-anchor', 'middle')
        .attr('transform', `rotate(-60)`)
        .attr('dx', '0px')
        .attr('dy', '0px')
        .each(function (d: any) {
          // Split the text into two lines if necessary
          const text = d.toString().split('2024')[0];
          const firstLine = text.slice(0, Math.floor(3));
          const secondLine = text.slice(Math.floor(4));

          d3.select(this).text('');

          // Create the two lines with tspan
          d3.select(this).append('tspan').attr('x', 30).attr('dy', 12).text(firstLine);

          d3.select(this)
            .append('tspan')
            .attr('x', 30)
            .attr('dy', '1.2em') // Adjust line spacing
            .text(secondLine);
        });
    }

    xAxisGroup.select('.domain').attr('stroke', '#000').attr('stroke-width', 0);

    setTimeout(() => {
      filteredTickets.forEach((ticket) => {
        const avatarContainer = document.querySelector(`.avatar-${ticket.taskId}`);
        if (avatarContainer) {
          const root = ReactDOM.createRoot(avatarContainer as Element);
          root.render(
            <AssignDeveloper
              assignees={ticket.assignees}
              idToChange={1}
              main_tenant='sos'
              newTicket={false}
              setAssignees={undefined}
              table={false}
              hideAssigneeBtn={true}
            />,
          );
        }
      });
    }, 0);
  }, [tickets, sprintStartDate, sprintEndDate]);

  useEffect(() => {
    renderChart();
  }, [renderChart]);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = () => {
    if (!startDate || !endDate) {
      setErrorMessage('Please select both Start Date and End Date');
    } else {
      const newEndDate = new Date(endDate);
      newEndDate.setDate(newEndDate.getDate() + 1);
      setErrorMessage(null);
      setSprintStartDate(startDate);
      setSprintEndDate(newEndDate);
    }
  };

  const [filterOpen, setfilterOpen] = useState(false);

  const handleToggle = () => {
    setfilterOpen(!filterOpen);
  };

  const containerRef = useRef<HTMLElement>(null);

  return (
    <div>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          height: 'calc(100dvh - 120px)',
          overflowY: 'auto',
          justifyContent: 'space-between',
          overflowX: 'hidden',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            left: '245px',
            top: '15px',
            display: 'flex',
            justifyContent: 'end',
          }}
          ref={containerRef}
        >
          <FilterAltIcon
            sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
            onClick={() => handleToggle()}
          />
          <Slide direction='right' in={filterOpen} container={containerRef.current}>
            <Box bgcolor='white' p='10px' border='1px solid lightgray' borderRadius='5px'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box display='flex' alignItems='center' gap={2}>
                  <Box display='flex' alignItems='center' gap={1}>
                    <Typography>Start Date:</Typography>
                    <DatePicker
                      value={startDate}
                      onChange={(newValue: any) => setStartDate(newValue?.$d)}
                      slotProps={{
                        textField: {
                          placeholder: 'Select a date',
                          size: 'small',
                          sx: { width: 160 },
                        },
                      }}
                    />
                  </Box>

                  <Box display='flex' alignItems='center' gap={1}>
                    <Typography>End Date:</Typography>
                    <DatePicker
                      value={endDate}
                      onChange={(newValue: any) => {
                        setEndDate(newValue?.$d);
                      }}
                      slotProps={{
                        textField: {
                          placeholder: 'Select a date',
                          size: 'small',
                          sx: { width: 160 },
                        },
                      }}
                    />
                  </Box>

                  <Button variant='contained' color='primary' onClick={handleSubmit}>
                    GO
                  </Button>
                </Box>

                {errorMessage && (
                  <Typography color='error' variant='body2' mt={1}>
                    {errorMessage}
                  </Typography>
                )}
              </LocalizationProvider>
            </Box>
          </Slide>
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          position='absolute'
          top='0px'
          left='0px'
        >
          <Typography fontSize='12px' fontWeight='bold' mt='4px'>
            Ticket ID
          </Typography>
          <Box ml='30px'>
            <FilterStatus />
          </Box>
          <Box ml='15px'>
            <FilterAssignees />
          </Box>
        </Box>
        <svg ref={tableRef} />
        <svg ref={svgRef} />
      </div>

      {ticketOpen && selectedTicketId && (
        <TicketDetails open={ticketOpen} ticketId={selectedTicketId} setOpen={setTicketOpen} />
      )}
    </div>
  );
};

export default RoadmapChart;
