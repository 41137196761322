import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import {
  Close as CloseIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Check as CheckIcon,
} from '@mui/icons-material';

interface Topic {
  content: string;
}

interface AddChapterDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: { title: string; duration: string; topics: Topic[] }) => void;
}

const AddChapterDialog: React.FC<AddChapterDialogProps> = ({ open, onClose, onSubmit }) => {
  const [title, setTitle] = useState('');
  const [duration, setDuration] = useState('');
  const [topics, setTopics] = useState<Topic[]>([]);
  const [newTopic, setNewTopic] = useState('');
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editingContent, setEditingContent] = useState('');

  const handleAddTopic = () => {
    if (newTopic.trim()) {
      setTopics([...topics, { content: newTopic.trim() }]);
      setNewTopic('');
    }
  };

  const handleDeleteTopic = (index: number) => {
    const newTopics = [...topics];
    newTopics.splice(index, 1);
    setTopics(newTopics);
  };

  const handleStartEdit = (index: number) => {
    setEditingIndex(index);
    setEditingContent(topics[index].content);
  };

  const handleSaveEdit = () => {
    if (editingIndex !== null && editingContent.trim()) {
      const newTopics = [...topics];
      newTopics[editingIndex] = { content: editingContent.trim() };
      setTopics(newTopics);
      setEditingIndex(null);
      setEditingContent('');
    }
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
    setEditingContent('');
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      title,
      duration,
      topics,
    });
    setTitle('');
    setDuration('');
    setTopics([]);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setTitle('');
        setDuration('');
        setTopics([]);
      }}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          fontWeight: 600,
        }}
      >
        Add Chapter
        <IconButton
          onClick={() => {
            onClose();
            setTitle('');
            setDuration('');
            setTopics([]);
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 2 }}>
        <Box component='form' onSubmit={handleSubmit}>
          <Typography variant='subtitle1' sx={{ mb: 1 }}>
            Chapter Title
          </Typography>

          <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <TextField
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder='Chapter1'
              size='small'
            />
            <TextField
              sx={{ width: '150px' }}
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              placeholder='days'
              size='small'
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1,
              }}
            >
              <Typography variant='subtitle1'>Topics</Typography>
              <IconButton size='small' onClick={handleAddTopic}>
                <AddIcon />
              </IconButton>
            </Box>

            <List
              sx={{
                // border: '1px solid',
                // borderColor: 'divider',
                // borderRadius: 1,
                maxHeight: '200px',
                overflow: 'auto',
              }}
            >
              {topics.map((topic, index) => (
                <ListItem key={index} dense>
                  {editingIndex === index ? (
                    <>
                      <TextField
                        fullWidth
                        size='small'
                        value={editingContent}
                        onChange={(e) => setEditingContent(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSaveEdit()}
                        autoFocus
                      />
                      <IconButton edge='end' size='small' onClick={handleSaveEdit}>
                        <CheckIcon sx={{width: '16px', height: '16px'}}/>
                      </IconButton>
                      <IconButton edge='end' size='small' onClick={handleCancelEdit}>
                        <CloseIcon sx={{width: '16px', height: '16px'}}/>
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <ListItemText primary={topic.content} />
                      <ListItemSecondaryAction>
                        <IconButton edge='end' size='small' onClick={() => handleStartEdit(index)}>
                          <EditIcon sx={{width: '16px', height: '16px'}}/>
                        </IconButton>
                        <IconButton
                          edge='end'
                          size='small'
                          onClick={() => handleDeleteTopic(index)}
                        >
                          <DeleteIcon sx={{width: '16px', height: '16px'}}/>
                        </IconButton>
                      </ListItemSecondaryAction>
                    </>
                  )}
                </ListItem>
              ))}

              <ListItem dense>
                <TextField
                  fullWidth
                  size='small'
                  value={newTopic}
                  onChange={(e) => setNewTopic(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleAddTopic()}
                  placeholder='Add new topic'
                />
              </ListItem>
            </List>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button variant='contained' type='submit' color='info'>
              Add
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddChapterDialog;
