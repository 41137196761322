import { useContext } from 'react';
import { UserDataContext } from '../routes/Main';

export default function isUserOfService(serviceName : string) {
  const { userData } = useContext(UserDataContext);

  if (userData?.data?.main_tenant === null) {
    return (
      userData?.data?.organizations?.filter((org: { alias: string }) => org?.alias === 'sciever')?.length > 0
    );
  } else {
    return (
      userData?.data?.main_tenant_role[0]?.services?.filter(
        (service: { service_name: string }) => service?.service_name === serviceName,
      )?.length > 0
    );
  }
}
