import { Box, Popover, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import axios from 'axios';
import { failed, success } from '../hooks/useToast';
import { DataContext } from '../pages/TicketsPage';
// import { TicketListContext } from '../pages/HomePage';

const Estimate = (props: { estimate: string; id: number | string; fetchTicket?: () => void }) => {
  const { data, setData } = useContext(DataContext);
  // const { fetchTicketsHomePage } = useContext(TicketListContext);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleEstimateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEstimateClose = () => {
    setAnchorEl(null);
  };

  const estimateOpen = Boolean(anchorEl);

  const [days, setDays] = useState('0');
  const [hours, setHours] = useState('0');

  useEffect(() => {
    setDays(props?.estimate ? props?.estimate?.split('d')[0] : '0');
    setHours(props?.estimate ? props?.estimate?.split(' ')[1]?.split('h')[0] : '0');
  }, [props?.estimate]);

  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

  const editEstimate = () => {
    const editData = {
      data: {
        estimate: `${days}d ${hours}h`,
      },
    };

    axios
      .put(`${process.env.REACT_APP_strapiUrl}/tickets/${props?.id}`, editData, {
        headers: headers,
      })
      .then(async (response) => {
        success();
        handleEstimateClose();
        if (props.fetchTicket) {
          props.fetchTicket();
        }
        const responseData = await response;
        const updatedTickets = data.map((ticket: any) => {
          if (ticket.id === props?.id) {
            return {
              ...ticket,
              attributes: {
                ...ticket.attributes,
                estimate: responseData.data.attributes.changed_to,
              },
            };
          }
          return ticket;
        });
        setData(updatedTickets);
        // location.pathname === '/overview' ? fetchTicketsHomePage() : fetchTickets();
        // fetchTickets();
      })
      .catch(() => failed());
  };

  return (
    <>
      {props?.estimate ? (
        <Box onClick={(e: any) => handleEstimateClick(e)} sx={{ cursor: 'pointer' }}>
          {props?.estimate}
        </Box>
      ) : (
        <Box
          onClick={(e: any) => handleEstimateClick(e)}
          color='#3751FF'
          sx={{ cursor: 'pointer' }}
        >
          Set
        </Box>
      )}

      <Popover
        open={estimateOpen}
        anchorEl={anchorEl}
        onClose={() => {
          handleEstimateClose();
          setDays(props?.estimate ? props?.estimate?.split('d')[0] : '0');
          setHours(props?.estimate ? props?.estimate?.split(' ')[1]?.split('h')[0] : '0');
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p='10px' display='flex' gap='5px' alignItems='center'>
          <TextField
            size='small'
            defaultValue={days}
            aria-label='days'
            onChange={(e) => setDays(e.target.value)}
            sx={{ width: '30px' }}
            inputProps={{ style: { height: '20px', padding: '5px' } }}
          />
          d
          <TextField
            size='small'
            defaultValue={hours}
            aria-label='hours'
            onChange={(e) => setHours(e.target.value)}
            sx={{ width: '30px' }}
            inputProps={{ style: { height: '20px', padding: '5px' } }}
          />
          h
          <DoneIcon sx={{ cursor: 'pointer', height: '20px' }} onClick={() => editEstimate()} />
          <CloseIcon
            onClick={() => {
              handleEstimateClose();
              setDays(props?.estimate ? props?.estimate?.split('d')[0] : '0');
              setHours(props?.estimate ? props?.estimate?.split(' ')[1]?.split('h')[0] : '0');
            }}
            sx={{ cursor: 'pointer', height: '20px', ml: '-5px' }}
          />
        </Box>
      </Popover>
    </>
  );
};

export default Estimate;
