import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { CurrentUserContext, LoadingContext } from '../App';
import isMobile from '../hooks/isMobile';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { UserDataContext, UserListContext } from '../routes/Main';
import { addMessageService } from '../services/ticketService';
import MessageBox from './MessageBox';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import axios from 'axios';
import isDeveloper from '../hooks/isDeveloper';
import { recordLog } from '../services/recordLog';
import { IChatData, ITicketForum } from '../utils/exports/Interface';
import Fuse from 'fuse.js';

const TicketForum = (props: ITicketForum) => {
  const superAdmin = isSuperAdmin();
  const developer = isDeveloper();

  const [messages, setMessages] = useState<IChatData[]>([]);
  const [message, setMessage] = useState('');

  const { data } = props;
  const { userData } = useContext(UserDataContext);
  const { userList } = useContext(UserListContext);
  const { setLoading } = useContext(LoadingContext);
  const { currentUser } = useContext(CurrentUserContext);

  const [files, setFiles] = useState<any>([]);

  const [taggedUserIds, setTaggedUserIds] = useState<number[]>([]);

  const taggedUserNames = Array.from(message?.matchAll(/~(.*?)~/g))?.map((match) => match[1]);

  const userIdsFromNames = taggedUserNames
    ?.map((name) =>
      userList?.data?.users?.users?.filter(
        (user: { name: string }) => user?.name === name?.split('@')[1],
      ),
    )
    ?.flat()
    ?.map((user2) => user2?.id);

  const verifiedTaggedUserIds = userIdsFromNames?.filter((id) => taggedUserIds?.includes(id));

  const createMessage = async () => {
    setLoading(true);

    const formData = new FormData();

    Array.from(files).forEach((file: any) => {
      formData.append('files', file);
      formData.append('ref', 'chat');
      formData.append('field', 'attachments');
    });

    const ticket = data?.id;

    const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

    axios
      .post(`${process.env.REACT_APP_strapiUrl}/upload`, formData, {
        headers: headers,
      })
      .then(async (response) => {
        const attachments: any = [];
        response.data.map((imageId: { id: number }) => {
          attachments.push(imageId.id);
        });
        const messageInfo = {
          message: message,
          sender: userData !== undefined && userData.data.name,
          ticket: ticket,
          admin: superAdmin || developer,
          attachments: attachments,
          changed_to: 'Comment',
          tagged_users: verifiedTaggedUserIds,
        };
        const response2 = await addMessageService({ data: messageInfo }).catch(setLoading(false));
        if (response2.data.data) {
          setMessages([...messages, response2.data.data]);
          setMessage('');
          setLoading(false);
          setFiles([]);
          recordLog(`Comment`, message, 'ticket', props.selectedRowId, currentUser?.id);
        }
      })
      .catch(async () => {
        const messageInfo = {
          message: message,
          sender: userData !== undefined && userData.data.name,
          ticket: ticket,
          admin: superAdmin || developer,
          changed_to: 'Comment',
          tagged_users: verifiedTaggedUserIds,
        };
        const response2 = await addMessageService({ data: messageInfo }).catch(setLoading(false));
        if (response2.data.data) {
          setMessages([...messages, response2.data.data]);
          setMessage('');
          setLoading(false);
          setFiles([]);
          recordLog(`Comment`, message, 'ticket', props.selectedRowId, currentUser?.id);
        }
      });
    setShowSuggestions(false);

    // const response = await addMessageService({ data: messageInfo }).catch(setLoading(false))

    // if (response.data.data) {
    //   setMessages([...messages, response.data.data]);
    //   setMessage('');
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    setMessages(data?.attributes?.chats?.data ?? []);
  }, [data]);

  const mobile = isMobile();

  const uploadInputRef = useRef<HTMLInputElement>(null);

  const textFieldRef = useRef<HTMLTextAreaElement>(null); // Ref for TextField
  const [caretPosition, setCaretPosition] = useState({ top: 0, left: 0 });
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<any>([]);
  const [timer, setTimer] = useState<any>(null);

  useEffect(() => {
    const fuse = new Fuse(userList?.data?.users?.users, {
      keys: ['name'],
      shouldSort: true,
      includeScore: true,
    });

    if (query.trim() !== '') {
      const newTimer = setTimeout(() => {
        const searchResults = fuse.search(query);
        const sortedResults = searchResults.sort((a, b) => (a.score ?? 0) - (b.score ?? 0));
        setResults(sortedResults);
      }, 1000);
      setTimer(newTimer);

      // Cleanup previous timer
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    } else {
      setResults([]);
    }
  }, [query, userList]);

  const calculateCaretPosition = () => {
    const textarea = textFieldRef.current;
    if (textarea) {
      const textBeforeCaret = textarea.value.substring(0, textarea.selectionStart);
      const atIndex = textBeforeCaret.lastIndexOf('@');

      // Proceed only if there is an '@' before the caret
      if (atIndex !== -1) {
        const textUntilAt = textBeforeCaret.substring(0, atIndex + 1);
        const textareaRect = textarea.getBoundingClientRect();

        const span = document.createElement('span');
        const computedStyle = window.getComputedStyle(textarea);

        Object.assign(span.style, {
          position: 'absolute',
          whiteSpace: 'pre-wrap',
          visibility: 'hidden',
          fontFamily: computedStyle.fontFamily,
          fontSize: computedStyle.fontSize,
          lineHeight: computedStyle.lineHeight,
        });

        // Set span content to the text up to the '@' character
        span.textContent = textUntilAt.replace(/\n$/, '\n ');

        // Append span to body to measure
        document.body.appendChild(span);

        const spanRect = span.getBoundingClientRect();

        // Calculate position relative to the textarea
        const top = textareaRect.top + spanRect.height;
        const left = textareaRect.left + spanRect.width;

        setCaretPosition({ top, left });

        // Clean up
        document.body.removeChild(span);
      }
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;

    const parts = value.split('@');
    if (parts.length > 1 && parts[parts.length - 1].length > 0) {
      setQuery(parts[parts.length - 1].toLowerCase());
      setShowSuggestions(true);
      calculateCaretPosition();
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (id: number) => {
    const selectedSuggestion = userList?.data?.users?.users?.find((s: any) => s.id === id);

    if (selectedSuggestion) {
      const textarea = textFieldRef.current;

      if (textarea) {
        const cursorPosition = textarea.selectionStart; // Current cursor position
        const textBeforeCursor = textarea.value.substring(0, cursorPosition); // Text before the cursor
        const textAfterCursor = textarea.value.substring(cursorPosition); // Text after the cursor

        const atIndex = textBeforeCursor.lastIndexOf('@'); // Find the last '@' index
        if (atIndex !== -1) {
          // Construct new value with the tagged suggestion
          const newMessage =
            textBeforeCursor.substring(0, atIndex) +
            `~@${selectedSuggestion.name}~ ` + // Add selected suggestion's name
            textAfterCursor;

          setTaggedUserIds((prev) => [...prev, id]);
          setMessage(newMessage); // Update the message
          setShowSuggestions(false); // Hide suggestions
          setQuery(''); // Clear query
          textarea.focus(); // Refocus the textarea
          textarea.setSelectionRange(
            atIndex + selectedSuggestion.name.length + 2,
            atIndex + selectedSuggestion.name.length + 2,
          ); // Move cursor to the correct position
        }
      }
    }
  };

  return (
    <>
      {!mobile ? (
        <Box
          height='150px'
          // width='400px'
          // border='solid'
          // borderColor='lightgray'
          // borderRadius='5px'
          position='relative'
          sx={{ overflowY: 'scroll' }}
        >
          <Box position='sticky' top='0px' bgcolor='white' zIndex='1' pr='15px'>
            <TextField
              inputRef={textFieldRef}
              id='outlined-basic'
              placeholder='Comment...'
              variant='outlined'
              fullWidth
              multiline
              aria-label='comment'
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: '12px',
                },
                '& .MuiInputBase-root.MuiOutlinedInput-root': {
                  padding: '6px 8px', // Adjust padding here
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={{ display: 'flex', gap: '5px' }}>
                    <input
                      ref={uploadInputRef}
                      type='file'
                      style={{ display: 'none' }}
                      onChange={(e) => setFiles(e.target.files)}
                      aria-label='select-file'
                      multiple
                      // accept='image/*'
                    />
                    <Box display='flex'>
                      <AttachFileIcon
                        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                        sx={{ cursor: 'pointer', height: '16px', width: '16px' }}
                      />
                      {files?.length !== undefined && files?.length > 0 && (
                        <Box>{files?.length}</Box>
                      )}
                    </Box>
                    <SendIcon
                      onClick={createMessage}
                      sx={{ cursor: 'pointer', height: '16px', width: '16px' }}
                    />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setMessage(e.target.value);
                handleInputChange(e);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  createMessage();
                  e.preventDefault();
                }
              }}
              value={message}
            />
          </Box>

          <Box pr='15px' pb='15px'>
            {messages?.length > 0 &&
              messages
                ?.slice(0)
                ?.reverse()
                .map((message) => {
                  return <MessageBox key={message.id} message={message} />;
                })}
          </Box>
        </Box>
      ) : (
        <Box
          height='500px'
          border='solid'
          borderColor='lightgray'
          borderRadius='5px'
          position='relative'
          sx={{ borderWidth: '1px', overflowY: 'scroll' }}
        >
          <Box position='sticky' top='0px' mb='15px' zIndex='1' bgcolor='white' p='15px'>
            <TextField
              id='outlined-basic'
              placeholder='Comment...'
              variant='outlined'
              fullWidth
              multiline
              aria-label='comment'
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: '12px',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={{ display: 'flex', gap: '10px' }}>
                    <input
                      ref={uploadInputRef}
                      type='file'
                      aria-label='select-file'
                      style={{ display: 'none' }}
                      onChange={(e) => setFiles(e.target.files)}
                      multiple
                      // accept='image/*'
                    />
                    <Box display='flex'>
                      <AttachFileIcon
                        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                        sx={{ cursor: 'pointer' }}
                      />
                      {files?.length !== undefined && files?.length > 0 && (
                        <Box>{files?.length}</Box>
                      )}
                    </Box>
                    <SendIcon onClick={createMessage} sx={{ cursor: 'pointer' }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setMessage(e.target.value);
                handleInputChange(e);
              }}
              // onKeyDown={(e) => {
              //   if (e.key === 'Enter' && !e.shiftKey) {
              //     e.preventDefault();
              //     createMessage();
              //   }
              // }}
              value={message}
            />
          </Box>

          <Box p='15px' mt='-40px'>
            {messages?.length > 0 &&
              messages
                ?.slice(0)
                ?.reverse()
                .map((message) => <MessageBox key={message.id} message={message} />)}
          </Box>
        </Box>
      )}

      {showSuggestions && results?.length > 0 && (
        <Paper
          sx={{
            position: 'absolute',
            top: caretPosition.top - 40,
            left: caretPosition.left - 105,
            maxWidth: 350,
            maxHeight: 175,
            overflowY: 'auto',
            mt: -1,
            ml: 1,
            zIndex: 1000,
          }}
        >
          <List>
            {results.map((suggestion: any, index: any) => {
              return (
                <ListItem
                  button
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion?.item?.id)}
                  sx={{ height: '25px' }}
                >
                  <ListItemText
                    primary={<Typography fontSize='12px'>{suggestion?.item?.name}</Typography>}
                  />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      )}
    </>
  );
};

export default TicketForum;
