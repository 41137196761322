import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { dynamicFailed, failed, success } from '../hooks/useToast';
import { AllTenantsContext, UserDataContext } from '../routes/Main';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { DataContext } from '../pages/TicketsPage';
import { getBoards } from '../utils/api/API';
import isSuperAdmin from '../hooks/isSuperAdmin';
import isAdmin from '../hooks/isAdmin';
// import { TicketListContext } from '../pages/HomePage';

export interface IBoardData {
  id: number;
  attributes: {
    name: string;
  };
}

const EditBoard = (props: {
  ticketBoard: number[];
  tenant: string;
  currentStatus?: string;
  ticketId: number | string;
  fetchTicket?: () => void;
  getBoardTickets?: (status: string) => void;
  defaultBoard: boolean;
}) => {
  const { data, setData } = useContext(DataContext);

  const { allTenants } = useContext(AllTenantsContext);

  const { userData } = useContext(UserDataContext);

  const superAdmin = isSuperAdmin();

  const admin = isAdmin();

  const [currentTenant, setCurrentTenant] = useState<{ organization: { name: string } }>();

  const getCurrentTenant = () => {
    setCurrentTenant(
      allTenants?.data?.tenants?.filter(
        (org: { organization: { alias: string } }) => org.organization.alias === props?.tenant,
      )[0],
    );
  };

  useEffect(() => {
    getCurrentTenant();
  }, [props?.tenant]);

  const [boards, setBoards] = useState<IBoardData[]>();

  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

  // const getBoards = () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_strapiUrl}/boards?filters[main_tenant][$eq]=${props?.tenant}&populate=*`,
  //       {
  //         headers: headers,
  //       },
  //     )
  //     .then((res) => setBoards(res.data.data))
  //     .catch(() => failed());
  // };
  // const getBoards = () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_strapiUrl}/boards?filters[main_tenant][$eq]=${props?.tenant}&populate=*`,
  //       {
  //         headers: headers,
  //       },
  //     )
  //     .then((res) => setBoards(res.data.data))
  //     .catch(() => failed());
  // };

  useEffect(() => {
    getBoards(props?.tenant, setBoards, userData?.data?.id, superAdmin || admin);
  }, [props.tenant]);

  const [boardsArray, setBoardsArray] = useState<IBoardData[]>();
  const [boardIdArray, setBoardIdArray] = useState<number[]>(
    props.defaultBoard ? [...props.ticketBoard, 0] : props.ticketBoard,
  );

  useEffect(() => {
    setBoardIdArray(props.defaultBoard ? [...props.ticketBoard, 0] : props.ticketBoard);
  }, [props.ticketBoard, props.defaultBoard]);

  useEffect(() => {
    setBoardsArray(boards?.filter((board) => props?.ticketBoard?.includes(board?.id)));
  }, [boards, props?.ticketBoard]);

  const [open, setOpen] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);

    const flattenedArray = Array.isArray(boardIdArray) ? boardIdArray.flat() : [];

    if (flattenedArray.includes(value)) {
      setBoardIdArray(flattenedArray.filter((item: number) => item !== value));
    } else {
      setBoardIdArray([...flattenedArray, value]);
    }
  };

  const editBoard = () => {
    if (boardIdArray?.length === 0) {
      dynamicFailed('Atleast 1 board is requred.');
    } else {
      const editData = {
        data: {
          boards: boardIdArray?.filter((board) => board !== 0),
          default_board: boardIdArray?.includes(0),
        },
      };

      axios
        .put(`${process.env.REACT_APP_strapiUrl}/tickets/${props.ticketId}`, editData, {
          headers: headers,
        })
        .then(async (response) => {
          const responseData = await response;
          // console.log(responseData.data.data.attributes.board);
          const updatedTickets = data.map((ticket: any) => {
            if (ticket.attributes.ticket_id === props.ticketId) {
              return {
                ...ticket,
                attributes: {
                  ...ticket.attributes,
                  boards: responseData.data.data.attributes.boards,
                  default_board: responseData.data.data.attributes.default_board,
                },
              };
            }
            return ticket;
          });
          setData(updatedTickets);
          props.getBoardTickets &&
            props.currentStatus &&
            props.getBoardTickets(props.currentStatus);
          success();
          setOpen(false);

          if (props.fetchTicket) {
            props.fetchTicket();
          }
          // location?.pathname === '/overview' ? fetchTicketsHomePage() : fetchTickets();
          // fetchTickets();
        })
        .catch(() => failed());
    }
  };

  return (
    <>
      <Stack direction='row' sx={{ flexWrap: 'wrap' }}>
        {/* <Typography fontSize='12px'>Board:</Typography> */}
        {boardIdArray?.flat().includes(0) && (
          <Chip label={currentTenant?.organization?.name} sx={{ mr: 1, height: '20px' }} />
        )}
        {boardsArray?.flat().map((board) => {
          return (
            <Chip key={board?.id} label={board?.attributes?.name} sx={{ mr: 1, height: '20px' }} />
          );
        })}
        <EditIcon
          onClick={() => setOpen(true)}
          sx={{ width: '16px', height: '16px', mt: '3px', color: 'gray', cursor: 'pointer' }}
        />
      </Stack>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setBoardIdArray(props.defaultBoard ? [...props.ticketBoard, 0] : props.ticketBoard);
        }}
        maxWidth='xs'
        fullWidth
      >
        <DialogContent sx={{ padding: '10px 20px' }}>
          <Box display='flex' flexDirection='column'>
            <Box
              display='flex'
              position='sticky'
              top='0px'
              justifyContent='space-between'
              bgcolor='white'
              py='10px'
              zIndex='1'
            >
              <Typography fontWeight='bold'>Board(s):</Typography>
              <Box display='flex' gap='10px'>
                <DoneIcon onClick={() => editBoard()} sx={{ cursor: 'pointer' }} />
                <CloseIcon
                  onClick={() => {
                    setOpen(false);
                    setBoardIdArray(
                      props.defaultBoard ? [...props.ticketBoard, 0] : props.ticketBoard,
                    );
                  }}
                  cursor='pointer'
                />
              </Box>
            </Box>

            <FormControl component='fieldset' variant='standard'>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      aria-label='organization-name'
                      onChange={handleChange}
                      checked={boardIdArray?.flat().includes(0)}
                      value={0}
                    />
                  }
                  label={currentTenant?.organization?.name}
                />
                {boards?.map((board) => {
                  return (
                    <FormControlLabel
                      key={board?.id}
                      control={
                        <Checkbox
                          onChange={handleChange}
                          aria-label='attributes-name'
                          checked={boardIdArray?.flat().includes(board?.id)}
                          value={board?.id}
                        />
                      }
                      label={board?.attributes?.name}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditBoard;
