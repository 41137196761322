import { Button, lighten, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

interface FileDragAndDropProps {
  setFiles: React.Dispatch<React.SetStateAction<any[]>>;
  fileNames: string[] ;
  setFileNames: React.Dispatch<React.SetStateAction<string[]>>;
  details?: boolean;
}

const FileDragAndDrop = ({ setFiles, fileNames, setFileNames, details}: FileDragAndDropProps) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;

    if (selectedFiles) {
      const newFiles = Array.from(selectedFiles);
      setFiles((prevFiles: File[]) => [...prevFiles, ...newFiles]);

      setFileNames((prevNames) => [...prevNames, ...newFiles.map((file) => file.name)]);
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles: File[]) => prevFiles.filter((_: File, i: number) => i !== index));
    setFileNames((prevNames) => prevNames.filter((_, i) => i !== index));
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;

    if (droppedFiles) {
      const newFiles = Array.from(droppedFiles);
      setFiles((prevFiles: File[]) => [...prevFiles, ...newFiles]);
      setFileNames((prevNames) => [...prevNames, ...newFiles.map((file) => file.name)]);
    }
    setIsDragOver(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };
  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        border: isDragOver ? '1px dashed rgba(0, 0, 0, 0.87)' : '1px dashed gray',
        borderRadius: '8px',
        padding: '5px',
        width: details ? '100%' : 'unset'
      }}
    >
      <input
        type='file'
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id='file-upload-input'
        aria-label='upload-file'
        multiple
      />
      <label htmlFor='file-upload-input'>
        <Button
          variant='contained'
          component='span'
          size='small'
          aria-label='upload-file'
          sx={{
            width: '90px',
            mx: 'auto',
            textTransform: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
            backgroundColor: '#C3C3C3',
            boxShadow: 'unset',
            '&:hover': {
              backgroundColor: lighten('#C3C3C3', 0.3),
              boxShadow: 'unset'
            },
          }}
        >
          Upload File
        </Button>
      </label>
      <div>
        {fileNames.length > 0 ? (
          fileNames.map((name, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant='body1'
                style={{
                  width: details ? '100%' : '410px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {name}
              </Typography>
              <CloseIcon onClick={() => handleRemoveFile(index)} cursor='pointer' />
            </div>
          ))
        ) : (
          <Typography variant='body2' color='textSecondary'>
            / Drag and Drop
          </Typography>
        )}
      </div>
    </div>
  );
};

export default FileDragAndDrop;
