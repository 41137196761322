import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tabs,
  Tab,
  Box,
  Divider, 
  Select,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import {
  Notifications as NotificationsIcon,
} from '@mui/icons-material';
import Sidebar from '../../components/Desktop/Sidebar';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import logo from '../../assets/images/sciever_logo_png.webp';
import Account from '../../components/Account';

const Layout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const superAdmin = isSuperAdmin();

  const paths = ['/course/courses', '/course/assign', '/course/interns'];
  const currentTab = paths.indexOf(location.pathname);

  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(paths[newValue]);
  };

  const handleSelectChange = (event: any) => {
    const selectedPath = event.target.value;
    const pathIndex = paths.indexOf(selectedPath);
    if (pathIndex !== -1) {
      navigate(selectedPath);
    }
  };

  return (
    <Box display='flex' sx={{ fontFamily: '"Exo 2"',  }}>
      {/* Vertical Tabs */}
      {/* <Box 
        sx={{ 
          display: 'flex', 
          position: isMobile ? 'absolute' : 'initial',
          bottom: 0,
          zIndex: 3,
          flexDirection: isMobile ? 'row' : 'column', 
          bgcolor: '#eaf0ff', 
          minWidth: isMobile ? '100vw' : 65,
          minHeight: isMobile ? 10 : '100vh' 
        }}
      >
        <Tabs
          orientation="vertical"
          aria-label="Vertical tabs"
          value={0}
          sx={{
            '& .MuiTab-root': {
              minWidth: 0,
              padding: isMobile ? 'auto' : '16px 0',
              minHeight: 56,
            },
            '& .Mui-selected': {
              color: '#000',
              backgroundColor: 'rgba(31, 28, 46, 0.08)',
              border: "none",
            },
            '& .MuiTabs-indicator': {
              display: 'none'
            },
            marginTop: isMobile ? 0 : 20,
          }}
        >
          <Tab icon={<SchoolIcon fontSize='large'/>} aria-label="Users" />
        </Tabs>
      </Box> */}
      <Sidebar />
      <Box sx={{ flexGrow: 1, backgroundColor: 'white', height: '100dvh' }}>
        <AppBar position='static' sx={{ all: 'unset' }}>
          <Toolbar>
            <img 
              src={logo} 
              alt='logo'
              style={{
                width: 35,
                paddingRight: 10,
              }}
            />
            <Typography variant='h6' component='div' sx={{ flexGrow: 1, fontWeight: '600' }}>
              Course Management
            </Typography>
            {!isMobile && (
              <>
                <IconButton color='inherit'>
                  <NotificationsIcon />
                </IconButton>
                <Account/>
              </>
            )}
            {isMobile && superAdmin && (
              <Select
                value={location.pathname}
                onChange={handleSelectChange}
                sx={{
                  '& .MuiSelect-select': {
                    py: 1,
                    fontFamily: '"Exo 2"',
                  },
                }}
              >
                <MenuItem value='/course/courses'>Course</MenuItem>
                <MenuItem value='/course/assign'>Assign Course</MenuItem>
                <MenuItem value='/course/interns'>Intern</MenuItem>
              </Select>
            )}
          </Toolbar>
        </AppBar>
        {!isMobile && superAdmin && (
          <>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label='course management tabs'
              sx={{
                pl: 2,
                '& .MuiTab-root': {
                  fontFamily: '"Exo 2"',
                },
                '& .Mui-selected': {
                  color: '#000',
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: '#000000',
                  height: 2.5,
                },
              }}
            >
              <Tab label='Course' />
              <Tab label='Assign Course' />
              <Tab label='Intern' />
            </Tabs>
            <Divider />
          </>
        )}

        <Box sx={{ mt: 2 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
