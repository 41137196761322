import React from "react";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";

interface CourseListItemProps {
  name: string; 
  isSelected: boolean;
  onSelect: () => void;
  onEdit: () => void;
}

export const CourseListItem: React.FC<CourseListItemProps> = ({
  name,
  isSelected,
  onSelect,
  onEdit,
}) => (
  <ListItem
    onClick={onSelect}
    sx={{
      cursor: "pointer",
      bgcolor: isSelected ? "action.selected" : "inherit",
      "&:hover": { bgcolor: "action.hover" },
    }}
  >
    <ListItemText primary={name} />
    <ListItemSecondaryAction>
      <IconButton
        edge="end"
        aria-label="edit"
        onClick={(e) => {
          e.stopPropagation();
          onEdit();
        }}
      >
        <EditIcon sx= {{height: '16px', width: '16px'}}/>
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
);
