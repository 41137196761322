import { Box, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import { failed, success } from '../../hooks/useToast';
import { extractTime, getCurrentTime } from './Hours';
import { formattedTime, getTodaysDate } from '../../utils/exports/Function';
import DoneButton from '../UI/DoneButton';
import CancelButton from '../UI/CancelButton';
import { CombinedReportContext } from '../../routes/Main';
import { TenantContext } from '../../App';

const DailyAttendanceReport = (props: { user: number | undefined }) => {
  const { combinedReport } = useContext(CombinedReportContext);

  const [allCheckIns, setAllCheckIns] = useState<any>();

  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

  const { tenant } = useContext(TenantContext);

  const [date, setDate] = useState(getTodaysDate());

  const getAllCheckIns = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_strapiUrl
        }/check-ins?sort=createdAt%3Adesc&pagination[pageSize]=99&filters[date][$eq]=${date}${
          tenant !== 'all' ? `&filters[attendance_user][tenant][$eq]=${tenant}` : ''
        }&populate[attendance_user][fields][0]=name`,
        {
          headers: headers,
        },
      )
      .then((res) => setAllCheckIns(res?.data?.data))
      .catch(() => failed());
  };

  useEffect(() => {
    getAllCheckIns();
  }, [props?.user, combinedReport, date]);

  const [entryToEdit, setEntryToEdit] = useState<{
    attributes: {
      in_time: string;
      out_time: string;
      lunch_out: string;
      lunch_in: string;
    };
    id: number;
  }>();

  const [editOpen, setEditOpen] = useState(false);

  const handleEditClick = (entry: {
    attributes: {
      in_time: string;
      out_time: string;
      lunch_out: string;
      lunch_in: string;
    };
    id: number;
  }) => {
    setEditOpen(true);
    setEntryToEdit(entry);
  };

  const [checkIn, setCheckIn] = useState(entryToEdit?.attributes?.in_time);
  const [checkOut, setCheckOut] = useState(entryToEdit?.attributes?.out_time);
  const [lunchOut, setLunchOut] = useState(entryToEdit?.attributes?.lunch_out);
  const [lunchIn, setLunchIn] = useState(entryToEdit?.attributes?.lunch_in);

  useEffect(() => {
    setCheckIn(entryToEdit?.attributes?.in_time);
    setCheckOut(entryToEdit?.attributes?.out_time);
    setLunchOut(entryToEdit?.attributes?.lunch_out);
    setLunchIn(entryToEdit?.attributes?.lunch_in);
  }, [entryToEdit]);

  const handleEdit = () => {
    const data = {
      data: {
        in_time: checkIn,
        out_time: checkOut !== '' ? checkOut : null,
        lunch_out: lunchOut !== '' ? lunchOut : null,
        lunch_in: lunchIn !== '' ? lunchIn : null,
      },
    };

    axios
      .put(`${process.env.REACT_APP_strapiUrl}/check-ins/${entryToEdit?.id}`, data, {
        headers: headers,
      })
      .then(() => {
        getAllCheckIns();
        success();
        setEditOpen(false);
        setCheckIn(undefined);
        setCheckOut(undefined);
        setLunchOut(undefined);
        setLunchIn(undefined);
      })
      .catch(() => failed());
  };

  // const [page, setPage] = useState(0);
  // const [pageSize, setPageSize] = useState(25);

  const columns: GridColDef[] = [
    {
      field: 'sn',
      renderHeader: () => {
        return <Typography fontWeight='bold'>SN</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      sortable: false,
      editable: false,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.row.id) + 1,
    },
    {
      field: 'name',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Name</Typography>;
      },
      headerAlign: 'left',
      flex: 5,
      align: 'left',
      editable: false,
      renderCell: (rowData) => {
        return (
          <Box textOverflow='ellipsis' overflow='hidden'>
            {rowData?.row?.attributes?.attendance_user?.data?.attributes?.name}
          </Box>
        );
      },
    },
    {
      field: 'Check In',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Check In</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 4.5,
      editable: false,
      sortable: false,
      renderCell: (rowData) => {
        if (rowData?.row?.attributes?.in_by) {
          return `${formattedTime(rowData?.row?.attributes?.in_time)} (${
            rowData?.row?.attributes?.in_by?.split(' ')[0]
          })`;
        } else {
          return formattedTime(rowData?.row?.attributes?.in_time);
        }
      },
    },
    {
      field: 'Check Out',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Check Out</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 4.5,
      editable: false,
      renderCell: (rowData) => {
        if (rowData?.row?.attributes?.out_time) {
          if (rowData?.row?.attributes?.out_by) {
            return `${formattedTime(rowData?.row?.attributes?.out_time)} (${
              rowData?.row?.attributes?.out_by?.split(' ')[0]
            })`;
          } else {
            return formattedTime(rowData?.row?.attributes?.out_time);
          }
        } else {
          return '-';
        }
      },
    },
    {
      field: 'Lunch Out',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Lunch Out</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 4.5,
      editable: false,
      renderCell: (rowData) => {
        if (rowData?.row?.attributes?.lunch_out) {
          if (rowData?.row?.attributes?.lunch_out_by) {
            return `${formattedTime(rowData?.row?.attributes?.lunch_out)} (${
              rowData?.row?.attributes?.lunch_out_by?.split(' ')[0]
            })`;
          } else {
            return formattedTime(rowData?.row?.attributes?.lunch_out);
          }
        } else {
          return '-';
        }
      },
    },
    {
      field: 'Lunch In',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Lunch In</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 4.5,
      editable: false,
      renderCell: (rowData) => {
        if (rowData?.row?.attributes?.lunch_in) {
          if (rowData?.row?.attributes?.lunch_in_by) {
            return `${formattedTime(rowData?.row?.attributes?.lunch_in)} (${
              rowData?.row?.attributes?.lunch_in_by?.split(' ')[0]
            })`;
          } else {
            return formattedTime(rowData?.row?.attributes?.lunch_in);
          }
        } else {
          return '-';
        }
      },
    },
    {
      field: 'Hours',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Hours</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 3,
      editable: false,
      renderCell: (rowData) =>
        rowData?.row?.attributes?.in_time
          ? extractTime(
              rowData?.row?.attributes?.in_time,
              rowData?.row?.attributes?.out_time !== null
                ? rowData?.row?.attributes?.out_time
                : getCurrentTime(),
            )
          : '-',
    },
    {
      field: 'actions',
      renderHeader: () => {
        return null;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      editable: false,
      renderCell: (row) => {
        return (
          <EditIcon
            onClick={() => handleEditClick(row?.row)}
            style={{ height: '16px', width: '16px', cursor: 'pointer' }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Box position='absolute' right='50px' top='50px' width='166px'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={getTodaysDate() !== undefined && dayjs(getTodaysDate())}
            onChange={(e: any) =>
              setDate(
                `${e?.$y}-${JSON.stringify(e?.$M + 1)?.padStart(2, '0')}-${JSON.stringify(
                  e?.$D,
                ).padStart(2, '0')}`,
              )
            }
            label='Date'
            format='DD/MM/YYYY'
            slotProps={{ textField: { size: 'small' } }}
          />
        </LocalizationProvider>
      </Box>

      <Box sx={{ bgcolor: 'white', height: '450px', mt: '-20px', mb: '-30px' }}>
        <DataGrid
          rows={
            allCheckIns !== undefined &&
            allCheckIns?.slice()?.sort(
              (
                a: {
                  attributes: { attendance_user: { data: { attributes: { name: string } } } };
                },
                b: {
                  attributes: { attendance_user: { data: { attributes: { name: string } } } };
                },
              ) =>
                a?.attributes?.attendance_user?.data?.attributes?.name?.localeCompare(
                  b?.attributes?.attendance_user?.data?.attributes?.name,
                ),
            )
          }
          columns={columns}
          rowHeight={40}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[25, 50, 75, 100]}
          disableColumnMenu
          sx={{
            border: 'none',
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              width: '0.4em',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders':
              {
                borderColor: 'darkgray',
                borderWidth: '1px',
              },
          }}
          getRowId={(row) => {
            return row?.id;
          }}
          disableRowSelectionOnClick
          // onPaginationModelChange={(row) => {
          //     setPage(row.page);
          //     setPageSize(row.pageSize);
          // }}
        />
      </Box>

      <Dialog
        open={editOpen}
        onClose={() => {
          setEntryToEdit(undefined);
          setEditOpen(false);
          setCheckIn(undefined);
          setCheckOut(undefined);
          setLunchOut(undefined);
          setLunchIn(undefined);
        }}
      >
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}
        >
          <Box display='flex' flexDirection='column' gap='10px'>
            <TextField
              size='small'
              value={checkIn}
              onChange={(e) => setCheckIn(e.target.value)}
              label='Check In'
              aria-label='checkin'
            ></TextField>
            <TextField
              size='small'
              value={checkOut}
              onChange={(e) => setCheckOut(e.target.value)}
              label='Check Out'
              aria-label='checkout'
            ></TextField>
            <TextField
              size='small'
              value={lunchOut}
              onChange={(e) => setLunchOut(e.target.value)}
              label='Lunch Out'
              aria-label='lunchout'
            ></TextField>
            <TextField
              size='small'
              value={lunchIn}
              onChange={(e) => setLunchIn(e.target.value)}
              label='Lunch In'
              aria-label='lunchin'
            ></TextField>
          </Box>

          <Box display='flex' gap='10px'>
            <DoneButton text='Save' onClick={handleEdit} mt='5px' />
            <CancelButton
              text='Cancel'
              onClick={() => {
                setEntryToEdit(undefined);
                setEditOpen(false);
                setCheckIn(undefined);
                setCheckOut(undefined);
                setLunchOut(undefined);
                setLunchIn(undefined);
              }}
              mt='5px'
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DailyAttendanceReport;
