import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  Box,
  Button,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import DeletePopUp from './DeletePopUp';

interface EditCourseDialogProps {
  open: boolean;
  onClose: () => void;
  onUpdate: (courseName: string) => void;
  onDelete: () => void;
  initialCourseName?: string;
}
 
const EditCourseDialog: React.FC<EditCourseDialogProps> = ({
  open,
  onClose,
  onUpdate,
  onDelete,
  initialCourseName = '',
}) => {
  const [courseName, setCourseName] = useState(initialCourseName);

  // Update courseName when initialCourseName changes or dialog opens
  useEffect(() => {
    if (open) {
      setCourseName(initialCourseName);
    }
  }, [open, initialCourseName]);

  const handleClose = () => {
    setCourseName(''); // Reset the state
    onClose();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (courseName.trim()) {
      onUpdate(courseName.trim());
      handleClose();
    }
  };

  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          // borderBottom: '1px solid',
          borderColor: 'divider',
          p: 2,
          fontWeight: 600,
        }}
      >
        Edit Course
        <IconButton aria-label='close' onClick={handleClose} size='small'>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 2 }}>
        <Box
          component='form'
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            pt: 2,
          }}
        >
          <TextField
            autoFocus
            fullWidth
            value={courseName}
            onChange={(e) => setCourseName(e.target.value)}
            placeholder='Course name'
            size='small'
          />

          <Button variant='contained' type='submit' sx={{ minWidth: 'fit-content' }} color='info'> 
            Update
          </Button>
          <DeletePopUp onDelete={handleDelete} iconSize={'24px'}/>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditCourseDialog;
