import { EditorState, convertToRaw } from 'draft-js';
import striptags from 'striptags';
import draftToHtml from 'draftjs-to-html';

// Function to tokenize a sentence
const tokenize = (sentence: string | EditorState): string[] => {
  return striptags(sentence as string)
    .toLowerCase()
    .split(/\W+/)
    .filter(Boolean);
};

// Function to calculate similarity between two strings
const calculateSimilarity = (str1: string | EditorState, str2: string): number => {
  const rawContentState = convertToRaw((str1 as EditorState).getCurrentContent());
  const newstr1 = draftToHtml(rawContentState);

  const tokens1 = tokenize(newstr1);
  const tokens2 = tokenize(str2);

  const union: string[] = [];
  const intersection: string[] = [];

  for (const token of tokens1) {
    union.push(token);
    if (tokens2.includes(token) && !intersection.includes(token)) {
      intersection.push(token);
    }
  }

  for (const token of tokens2) {
    union.push(token);
  }

  return intersection.length / union.length;
};

// Function to bold related tokens in a description
const boldRelatedWords = (str: string, tokens: string[]): string => {
  let result = str;
  tokens.forEach((token) => {
    const regex = new RegExp(`\\b(${token})\\b`, 'gi'); // Match the whole word, case insensitive
    result = result.replace(regex, '<b>$1</b>'); // Wrap in <b> tags
  });
  return result;
};

export const getRelatedTickets = (
  inputDescription: string | EditorState,
  descriptions: any,
): string[] => {
  const inputTokens = tokenize(
    draftToHtml(convertToRaw((inputDescription as EditorState).getCurrentContent())),
  );

  const similarDescs = descriptions
    ?.filter((desc: any) => {
      const descriptionText = desc?.attributes?.description || '';
      const similarity = calculateSimilarity(inputDescription, descriptionText);
      return similarity > 0.1;
    })
    .map((desc: any) => {
      const descriptionText = desc?.attributes?.description || '';
      const descriptionTokens = tokenize(descriptionText);
      const commonTokens = inputTokens.filter((token) => descriptionTokens.includes(token));
      const boldedDescription = boldRelatedWords(descriptionText, commonTokens);
      return { ...desc, boldedDescription };
    });

  return similarDescs;
};
