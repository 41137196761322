import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import { Box, Typography, Button, IconButton } from '@mui/material';

interface DeleteProps {
  onDelete: () => void;
  iconSize?: string; 
}

const DeletePopUp: React.FC<DeleteProps> = ({ onDelete, iconSize }) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteTicket = () => {
    onDelete();
  };
  return (
    <>
      <IconButton edge='end' size='small' onClick={handleClickOpen}>
        <DeleteIcon sx={{width: iconSize || '16px', height: iconSize || '16px'}}/>
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth='sm'>
        <Box display='flex' flexDirection='column' gap='10px' p='15px' alignItems='center'>
          <Box display='flex' gap='3px' pr='2px'>
            <DeleteIcon />
            <Typography>Are you sure?</Typography>
          </Box>
          <Box display='flex' gap='10px'>
            <Button
              size='small'
              variant='contained'
              aria-label='delete-ticket'
              color='error'
              onClick={deleteTicket} 
            >
              Yes
            </Button>
            <Button
              size='small'
              variant='outlined'
              aria-label='no-delete-ticket'
              onClick={handleClose}
            >
              No
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DeletePopUp;
