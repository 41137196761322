import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  IconButton,
  Box
} from '@mui/material'; 
import { Close as CloseIcon } from '@mui/icons-material';

interface AddCourseDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (courseName: string) => void;
}

const AddCourseDialog: React.FC<AddCourseDialogProps> = ({ open, onClose, onAdd }) => {
  const [courseName, setCourseName] = useState('');

  const handleAdd = () => {
    if (courseName.trim()) {
      onAdd(courseName.trim());
      setCourseName('');
      onClose();
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          // borderBottom: '1px solid',
          borderColor: 'divider',
          pl: 2,
          fontWeight: 600,
        }}
      >
        Add course
        <IconButton
          aria-label='close'
          onClick={onClose}
          size='small'
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{p: 3}}>
        <Box sx={{ display: 'flex', gap: 2, mt: 0, pt: 2 }}>
          <TextField
            autoFocus
            fullWidth
            value={courseName}
            onChange={(e) => setCourseName(e.target.value)}
            variant="outlined"
            size='small'
            label='Course Name'
          />
          <Button 
            variant="contained"
            onClick={handleAdd}
            sx={{ 
              // textTransform: 'none',
              fontWeight: 'normal'
            }}
            color='info'
          >
            Add
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddCourseDialog;