import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

interface GaugeProps {
  value: number;
  maxValue: number;
  label: string;
  color: string;
}

interface GaugeChartProps {
  gaugeData: GaugeProps; // Single gauge data
}

const GaugeChart: React.FC<GaugeChartProps> = ({ gaugeData }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const gaugeWidth = 115; // Width of the gauge
    const arcThickness = 15; // Thickness of the arcs

    // Calculate the height and width based on the gauge size
    const width = gaugeWidth; // Width based on the gauge
    const height = gaugeWidth * 0.75; // Height based on gauge's aspect ratio

    // Set up the SVG dimensions
    svg.attr('width', width).attr('height', height);
    svg.selectAll('*').remove(); // Clear previous render

    // Arc generator for the filled portion of the gauge
    const arc = d3
      .arc<GaugeProps>()
      .innerRadius(gaugeWidth / 2 - arcThickness)
      .outerRadius(gaugeWidth / 2)
      .startAngle(-Math.PI / 2)
      .endAngle((d: GaugeProps) => (d.value / d.maxValue) * Math.PI - Math.PI / 2);

    // Background arc generator
    const backgroundArc = d3
      .arc<any>()
      .innerRadius(gaugeWidth / 2 - arcThickness)
      .outerRadius(gaugeWidth / 2)
      .startAngle(-Math.PI / 2)
      .endAngle(Math.PI / 2);

    // Create a group for the gauge, centered within the SVG
    const group = svg.append('g').attr('transform', `translate(${width / 2}, ${height * 0.75})`); // Center vertically

    // Add the background arc
    group.append('path').attr('d', backgroundArc).style('fill', '#ccc');

    // Add the foreground arc
    group
      .append('path')
      .datum(gaugeData)
      .attr('d', (d: GaugeProps) => arc(d) || '')
      .style('fill', (d: GaugeProps) => d.color);

    // Add text label (e.g., "7/8h")
    group
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('text-anchor', 'middle')
      .style('font-size', '15px') // Slightly smaller font size
      .text(`${gaugeData.value}/${gaugeData.maxValue}h`);

    // Add sub-label (e.g., "Today")
    group
      .append('text')
      .attr('x', 0)
      .attr('y', 15)
      .attr('text-anchor', 'middle')
      .style('font-size', '12px')
      .text(gaugeData.label);
  }, [gaugeData]);

  return <svg ref={svgRef}></svg>;
};

export default GaugeChart;
