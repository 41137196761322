import { useEffect, useState } from 'react';
import { AttendanceBlock } from '../../layout/Desktop/DesktopAttendanceLayout';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogTitle, Typography } from '@mui/material';
import ScieverCalendar from '../../utils/ScieverCalendar';
import axios from 'axios';
import {
  formattedDate,
  getCurrentDate,
  isToday,
  isTomorrow,
  isYesterday,
} from '../../utils/exports/Function';
import { failed } from '../../hooks/useToast';
// import Info from './Info';
import DragHandleIcon from '@mui/icons-material/DragHandle';

const Holidays = () => {
  const [open, setOpen] = useState(false);

  const [upcomingHolidays, setUpcomingHolidays] =
    useState<[{ summary: string; start: { date: string } }]>();

  const getUpcomingHolidays = () => {
    axios
      .get(
        `${process.env.REACT_APP_calendarApiUrl}/calendar/events/e81b5c1c828bbd9f57ff94b5082cfa57220252d7b25d4e2db07b442f012efda0@group.calendar.google.com`,
      )
      .then((res) =>
        setUpcomingHolidays(
          res.data
            .filter((holiday: { start: { date: string } }) => holiday.start.date > getCurrentDate())
            .sort((a: { start: { date: string } }, b: { start: { date: string } }) =>
              a.start.date.localeCompare(b.start.date),
            ),
        ),
      )
      .catch(() => failed());
  };

  useEffect(() => {
    getUpcomingHolidays();
  }, []);

  return (
    <>
      <AttendanceBlock sx={{ height: '50%', bgcolor: '#ebf8f2', border: '1px solid #d2f0e3' }}>
        {/* <Info section='Holidays' /> */}
        <Box display='flex' alignItems='center' width='100%' justifyContent='space-between'>
          <Box
            display='flex'
            gap='5px'
            ml='-3px'
            alignItems='center'
            color='#3f8d6c'
            onClick={() => setOpen(true)}
            sx={{ cursor: 'pointer' }}
          >
            <Box p='5px' bgcolor='#3f8d6c' display='flex' alignItems='center' borderRadius='5px'>
              <CalendarMonthIcon sx={{ width: '22px', height: '22px', color: 'white' }} />
            </Box>
            <b>Holidays</b>
          </Box>

          <DragHandleIcon onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }} />
        </Box>
        <Box width='calc(100% + 30px)' display='flex' flexDirection='column' mt='10px' mx='-15px'>
          {upcomingHolidays?.slice(0, 5)?.map((holiday, index) => {
            const isEven = index % 2 === 0;
            return (
              <Box
                key={holiday.summary}
                display='flex'
                p='5px 15px'
                width='100%'
                justifyContent='space-between'
                bgcolor={isEven ? 'white' : 'inherit'}
              >
                <Typography display='flex' justifyContent='end' fontSize='15px'>
                  {isToday(holiday?.start?.date)
                    ? 'Tday'
                    : isYesterday(holiday?.start?.date)
                    ? 'Yday'
                    : isTomorrow(holiday?.start?.date)
                    ? 'Tmrw'
                    : formattedDate(holiday?.start?.date)}
                </Typography>
                <Typography
                  maxWidth='80%'
                  overflow='hidden'
                  textOverflow='ellipsis'
                  whiteSpace='nowrap'
                  fontSize='15px'
                >
                  {holiday.summary}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </AttendanceBlock>

      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='md'>
        <DialogTitle
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          sx={{ pb: 0 }}
        >
          <Box display='flex' gap='10px' alignItems='center'>
            <CalendarMonthIcon sx={{ width: '25px', height: '25px' }} />
            <b>Holidays</b>
          </Box>
          <CloseIcon onClick={() => setOpen(false)} cursor='pointer' />
        </DialogTitle>
        {/* <iframe
                    src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Asia%2FKathmandu&showNav=1&showPrint=0&showCalendars=1&showTabs=1&src=ZTgxYjVjMWM4MjhiYmQ5ZjU3ZmY5NGI1MDgyY2ZhNTcyMjAyNTJkN2IyNWQ0ZTJkYjA3YjQ0MmYwMTJlZmRhMEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23B39DDB"
                    style={{ border: 'solid 1px #777' }}
                    width="100%"
                    height="450px" /> */}
        <ScieverCalendar
          height='570px'
          calendarId='e81b5c1c828bbd9f57ff94b5082cfa57220252d7b25d4e2db07b442f012efda0@group.calendar.google.com'
        />
      </Dialog>
    </>
  );
};

export default Holidays;
