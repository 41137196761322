import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Box, Typography } from '@mui/material';
import {
  Close as CloseIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';
import { keyframes } from '@mui/system';
import { SquareIconButton } from '../../utils/exports/Styles';

export interface CarouselImage {
  id: number;
  path: string;
  updatedAt: string;
  sender: {
    userName: string;
  };
}

interface ImageCarouselProps {
  open: boolean;
  onClose: () => void;
  images: (Omit<CarouselImage, 'path'> & { path?: string })[];
  initialIndex: number;
  initialPosition?: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
}

const createExpandAnimation = (initialPosition: ImageCarouselProps['initialPosition']) => keyframes`
  0% {
    position: fixed;
    top: ${initialPosition?.top}px;
    left: ${initialPosition?.left}px;
    width: ${initialPosition?.width}px;
    height: ${initialPosition?.height}px;
    transform: scale(1);
    opacity: 1;
  }
  100% {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1);
    opacity: 1;
  }
`;

const createCollapseAnimation = (
  initialPosition: ImageCarouselProps['initialPosition'],
) => keyframes`
  0% {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1);
    opacity: 1;
  }
  100% {
    position: fixed;
    top: ${initialPosition?.top}px;
    left: ${initialPosition?.left}px;
    width: ${initialPosition?.width}px;
    height: ${initialPosition?.height}px;
    transform: scale(0.5);
    opacity: 0;
  }
`;

const ImageCarousel: React.FC<ImageCarouselProps> = ({
  open,
  onClose,
  images: rawImages,
  initialIndex = 0,
  initialPosition,
}) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [isClosing, setIsClosing] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const [animation, setAnimation] = useState<any>(null);

  useEffect(() => {
    if (open) {
      setShouldRender(true);
      setIsClosing(false);
      if (initialPosition) {
        // Only create animation if position exists
        setAnimation(createExpandAnimation(initialPosition));
      }
    }
  }, [open, initialPosition]);

  const handleClose = () => {
    if (initialPosition) {
      setIsClosing(true);
      setAnimation(createCollapseAnimation(initialPosition));
      setTimeout(() => {
        setShouldRender(false);
        setIsClosing(false);
        onClose();
      }, 500);
    } else {
      setIsClosing(true);
      setTimeout(() => {
        setShouldRender(false);
        setIsClosing(false);
        onClose();
      }, 300);
    }
  };

  // Reset current index when initialIndex changes
  useEffect(() => {
    setCurrentIndex(initialIndex);
  }, [initialIndex]);

  // Filter out images with undefined paths
  const images: CarouselImage[] = rawImages?.filter(
    (img): img is CarouselImage => typeof img.path === 'string',
  );

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images?.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images?.length) % images?.length);
  };

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (!open) return;

      switch (event.key) {
        case 'ArrowRight':
          handleNext();
          break;
        case 'ArrowLeft':
          handlePrevious();
          break;
        case 'Escape':
          handleClose();
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [open]);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const getFileName = (path: string) => {
    return path.split('/').pop() || '';
  };

  if (!images?.length) return null;

  return (
    <Dialog
      open={shouldRender}
      onClose={handleClose}
      maxWidth={false}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: isClosing ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.95)',
          margin: 0,
          transition: 'background-color 500ms ease',
        },
      }}
      TransitionProps={{
        timeout: 0, // Disable Material-UI's default transition
      }}
    >
      <DialogContent
        sx={{
          p: 0,
          position: 'relative',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        {/* Top bar */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '64px',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 2,
            zIndex: 2,
            opacity: isClosing ? 0 : 1,
          }}
        >
          <Typography variant='body1' color='white'>
            {currentIndex + 1} / {images?.length}
          </Typography>

          <Typography variant='body1' color='white'>
            {getFileName(images[currentIndex]?.path)}
          </Typography>

          <SquareIconButton
            onClick={handleClose}
            sx={{
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            <CloseIcon />
          </SquareIconButton>
        </Box>

        {/* Main image container */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              animation: animation ? `${animation} 1000ms ease forwards` : 'none',
            }}
          >
            <img
              src={`https://chatroom.enitiation.com/${images[currentIndex].path}`}
              alt={`Image ${currentIndex + 1}`}
              style={{
                maxHeight: 'calc(100vh - 144px)', // Account for top and bottom bars
                maxWidth: '100%',
                objectFit: 'contain',
                animation: animation ? `${animation} 500ms ease forwards` : 'none',
              }}
            />
          </Box>

          {/* Navigation buttons */}
          <SquareIconButton
            onClick={handlePrevious}
            sx={{
              position: 'absolute',
              left: 16,
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
            }}
          >
            <ChevronLeftIcon sx={{ fontSize: 40 }} />
          </SquareIconButton>

          <SquareIconButton
            onClick={handleNext}
            sx={{
              position: 'absolute',
              right: 16,
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
            }}
          >
            <ChevronRightIcon sx={{ fontSize: 40 }} />
          </SquareIconButton>
        </Box>

        {/* Bottom info bar */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            p: 2,
            borderRadius: 0,
            opacity: isClosing ? 0 : 1,
            transition: 'opacity 300ms ease',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Box>
              <Typography variant='body2' color='white'>
                Shared by {images[currentIndex].sender.userName}
              </Typography>
              <Typography variant='caption' sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                {formatDate(images[currentIndex].updatedAt)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImageCarousel;
