import { useContext } from 'react';
import { UserDataContext } from '../routes/Main';

export default function isAttendanceUser() {
  const { userData } = useContext(UserDataContext);

  return userData?.data?.main_tenant === null
    ? userData?.data?.organizations?.filter((org: { alias: string }) => org?.alias === 'sciever')
        ?.length === 0
      ? false
      : true
    : userData?.data?.main_tenant_role[0]?.services?.filter(
        (service: { service_name: string }) => service?.service_name === 'HR Management',
      )?.length > 0
    ? true
    : false;
}
