import { createContext, useContext, useEffect, useState } from 'react';
import { BoardContext, LoadingContext, TenantContext } from '../App';
import isMobile from '../hooks/isMobile';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { UserDataContext } from '../routes/Main';
import { getTicketsService, getTicketsTenantSpecificService } from '../services/ticketService';
import DesktopHomePage from './Desktop/DesktopHomePage';
import MobileHomePage from './Mobile/MobileHomePage';
import isDeveloper from '../hooks/isDeveloper';
import { useNavigate } from 'react-router-dom';

// export const TicketListContext = createContext<{
//   ticketList: ITicketData[];
// }>({
//   ticketList: [],
// });

export const TicketListContext = createContext<any>({});

const HomePage = () => {
  const mobile = isMobile();
  const superAdmin = isSuperAdmin();
  const developer = isDeveloper();

  const [tickets, setTickets] = useState([]);

  const { userData } = useContext(UserDataContext);
  const { tenant } = useContext(TenantContext);
  const { setLoading } = useContext(LoadingContext);
  const { board } = useContext(BoardContext);

  const getTickets = async () => {
    if (!userData) {
      return;
    }
    setLoading(true);
    const res = await getTicketsService(
      superAdmin ? 'sa' : userData?.data?.id,
      userData.data.organizations[0]?.alias ?? false,
      board,
      0,
      25,
      false,
    );
    if (res?.data) {
      setTickets(res?.data?.data);
      setLoading(false);
    }
  };

  const getTenantTickets = async () => {
    if (!userData) {
      return;
    }
    setLoading(true);
    const res = developer
      ? await getTicketsTenantSpecificService(
          superAdmin ? 'sa' : userData?.data?.id,
          tenant === 'all' ? userData?.data?.organizations : tenant,
          board,
          0,
          25,
          false,
          status,
        )
      : await getTicketsTenantSpecificService(
          superAdmin ? 'sa' : userData?.data?.id,
          tenant === 'all' ? null : tenant,
          board,
          0,
          25,
          false,
        );
    if (res?.data) {
      setTickets(res?.data?.data);
      setLoading(false);
    }
  };

  const fetchTicketsHomePage = () => {
    if (!superAdmin && !developer) {
      getTickets();
      return;
    }
    getTenantTickets();
  };

  useEffect(() => {
    fetchTicketsHomePage();
  }, [userData, superAdmin, tenant, board]);

  const attendanceUserOnly =
    userData?.data?.roles[0]?.name !== 'sa' &&
    userData?.data?.organizations?.length === 1 &&
    userData?.data?.organizations[0]?.alias === 'sciever'
      ? true
      : false;

  const navigate = useNavigate();

  useEffect(() => {
    if (attendanceUserOnly) {
      navigate('/attendance');
    }
  }, [attendanceUserOnly]);

  return (
    <TicketListContext.Provider
      value={{
        ticketList: tickets,
        fetchTicketsHomePage,
      }}
    >
      {mobile ? <MobileHomePage /> : <DesktopHomePage />}
    </TicketListContext.Provider>
  );
};

export default HomePage;
