interface Chapter {
  name: string;
  duration: string;
  content?: string[];
  bookmark?: Bookmark[];
  suggestion?: Bookmark[];
}

interface Intern {
  id: string;
  name: string;
}

interface Bookmark {
  name: string;
  link: string;
}

interface Course {
  name: string;
  chapters?: Chapter[];
  dateCreated: string;
  assignees: Intern[];
}

interface Mentor {
  id: string;
  mentor: string;
}

interface Score {
  id: number;
  score: string;
}

export type { Chapter, Intern, Bookmark, Course };

export const interns: Intern[] = [
  { id: "Intern01", name: "John Doe" },
  { id: "Intern02", name: "Jane Smith" },
  { id: "Intern03", name: "Basd asda" },
  { id: "Intern04", name: "VSas Twas" },
  { id: "Intern05", name: "Bteqw GGGA" },
];

export const mentors: Mentor[] = [
    { id: "Mentor01", mentor: "Ashik" },
    { id: "Mentor02", mentor: "Ankit" },
    { id: "Mentor03", mentor: "Madan" },
  ];
  
  export const scores: Score[] = [
    { id: 1, score: "Needs Improvement" },
    { id: 2, score: "Average" },
    { id: 3, score: "Good" },
    { id: 4, score: "Excellent" }, 
  ];

export const courses: Course[] = [
  {
    name: "Basic Common",
    dateCreated: "16/02/2024",
    assignees: [
      { id: "Intern01", name: "John Doe" },
      { id: "Intern02", name: "Jane Smith" },
      { id: "Intern03", name: "Basd asda" },
      { id: "Intern04", name: "VSas Twas" },
      { id: "Intern05", name: "Bteqw GGGA" },
    ],
    chapters: [
      {
        name: "Project Requirement Documentation",
        duration: "60h",
        content: [
          "What is PRD?",
          "What problems does PRD solve?",
          "What problems does PRD solve?",
        ],
        bookmark: [
          { name: "task1", link: "tasklink1.com" },
          { name: "task2", link: "tasklink2.com" },
          { name: "task3", link: "tasklink3.com" },
          { name: "task4", link: "tasklink4.com" },
        ],
        suggestion: [
          { name: "sugg1", link: "suggestedlink1.com" },
          { name: "sugg2", link: "suggestedlink2.com" },
          { name: "sugg3", link: "suggestedlink3.com" },
          { name: "sugg4", link: "suggestedlink4.com" },
        ],
      },
      { name: "Agile", duration: "100h" },
      { name: "Scrum", duration: "25h" },
      { name: "Project Management", duration: "36h" },
    ],
  },
  { 
    name: "UIX",
    dateCreated: "12/01/2024",
    assignees: [
      { id: "Intern01", name: "John Doe" }
    ],
    chapters: []
  },
  { 
    name: "Frontend",
    dateCreated: "10/01/2024",
    assignees: [
      { id: "Intern02", name: "Jane Smith" }
    ],
    chapters: []
  },
];