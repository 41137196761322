import { useContext } from 'react';
import { UserDataContext } from '../routes/Main';

export default function isAdmin() {
  const { userData } = useContext(UserDataContext);
  return userData?.data?.main_tenant === null
    ? userData?.data?.roles[0]?.name === 'ad'
      ? true
      : false
    : userData?.data?.main_tenant_role[0]?.name === 'admin'
    ? true
    : false;
}
