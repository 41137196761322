import { Box } from '@mui/material';
import OverviewHeader from '../../components/Home Page/OverviewHeader';
import StatusBlock from '../../components/Home Page/StatusBlock';

const MobileHomePageLayout = () => {

  return (
    <>
      <Box display='flex' flexDirection='column' mx='15px' gap='20px' mb='65px'>
        <OverviewHeader />
        <StatusBlock />
        <Box display='flex' overflow='scroll' mx='2px' gap='10px' sx={{
          '&::-webkit-scrollbar': {
            display: 'none', // Hide scrollbar for Chrome, Safari, and Opera
          },
        }}>
          {/* <TicketsContainer type='Backlog' />
          <TicketsContainer type='On Hold' />
          <TicketsContainer type='To Do' />
          <TicketsContainer type='Redo' />
          <TicketsContainer type='WIP' />
          <TicketsContainer type='PR' />
          <TicketsContainer type='PR Done' />
          <TicketsContainer type='Done' />
          <TicketsContainer type='UAT' />
          <TicketsContainer type='Prod' />
          <TicketsContainer type='Closed' />
          <TicketsContainer type='Canceled' /> */}
        </Box>
      </Box>
    </>
  );
};

export default MobileHomePageLayout;
