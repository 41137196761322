import React, { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  Button,
  Typography,
  Box,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import {
  Close as CloseIcon,
  Edit as EditIcon,
  Check as CheckIcon,
} from '@mui/icons-material';
import { LoadingContext } from '../../../App';
import {
  addAssignment,
  deleteAssignment,
  fetchAssignment,
  updateAssignment,
} from '../../api/CourseApi';
import { failed, success } from '../../../hooks/useToast';
import DeletePopUp from './DeletePopUp';
import isSuperAdmin from '../../../hooks/isSuperAdmin';

interface Assignment {
  id: number;
  attributes: {
    title: string;
    link?: string | null;
    intern_id?: number;
    chapter?: number;
  };
}

interface AssignmentPopupProps {
  open: boolean;
  onClose: () => void;
  intern_id: number;
  chapter: number;
}

const AssignmentPopup: React.FC<AssignmentPopupProps> = ({ open, onClose, intern_id, chapter }) => {
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [newTask, setNewTask] = useState('');
  const [editNewTask, setEditNewTask] = useState('');
  const [newTaskname, setNewTaskname] = useState('');
  const [editNewTaskname, setEditNewTaskname] = useState('');
  const [editLink, setEditLink] = useState('');
  const [newLink, setNewLink] = useState('');
  const [editId, setEditId] = useState<number>(0);
  const { setLoading } = useContext(LoadingContext);
  const superAdmin = isSuperAdmin();

  useEffect(() => {
    if (intern_id !== 0 && chapter !== 0) {
      setLoading(true);
      fetchAssignment(chapter, intern_id)
        .then((data) => {
          setAssignments(data);
          setLoading(false);
        })
        .catch(() => {
          failed();
          setLoading(false);
        });
    }
  }, [intern_id, chapter]);

  const handleAddAssignment = async () => {
    setLoading(true);
    try {
      const data = {
        data: {
          title: newTask,
          link: null,
          intern_id: intern_id,
          chapter: chapter,
        },
      };

      const response = await addAssignment(data);

      if (response?.status === 200 || response?.status === 201) {
        fetchAssignment(chapter, intern_id)
          .then((data) => {
            setAssignments(data);
            success();
            setLoading(false);
          })
          .catch(() => {
            failed();
            setLoading(false);
          });
      } else {
        failed();
        setLoading(false);
      }
    } catch (error) {
      failed();
      setLoading(true);
    }
    setNewTask('');
  };

  const handleAddLink = async () => {
    setLoading(true);
    try {
      const data = {
        data: {
          title: newTaskname,
          link: newLink,
          intern_id: intern_id,
          chapter: chapter,
        },
      };

      const response = await addAssignment(data);

      if (response?.status === 200 || response?.status === 201) {
        fetchAssignment(chapter, intern_id)
          .then((data) => {
            setAssignments(data);
            success();
            setLoading(false);
          })
          .catch(() => {
            failed();
            setLoading(false);
          });
      } else {
        failed();
        setLoading(false);
      }
    } catch (error) {
      failed();
      setLoading(true);
    }
    setNewTaskname('');
    setNewLink('');
  };

  const startEdit = (index: number) => {
    setEditIndex(index);
    setEditId(assignments[index]?.id);
    setEditNewTask(assignments[index]?.attributes?.title);
  };

  const startInternEdit = (index: number) => {
    setEditIndex(index);
    setEditId(assignments[index]?.id);
    setEditNewTaskname(assignments[index]?.attributes?.title);
    setEditLink(assignments[index]?.attributes?.link || '');
  };

  const handleCancelEdit = () => {
    setEditIndex(null);
    setEditNewTask('');
  };

  const saveEdit = async () => {
    if (editIndex !== null) {
      setLoading(true);
      try {
        const data = {
          data: {
            title: editNewTask,
          },
        };

        const response = await updateAssignment(editId, data);

        if (response?.status === 200 || response?.status === 201) {
          fetchAssignment(chapter, intern_id)
            .then((data) => {
              setAssignments(data);
              success();
              setLoading(false);
            })
            .catch(() => {
              failed();
              setLoading(false);
            });
        } else {
          failed();
          setLoading(false);
        }
      } catch (error) {
        failed();
        setLoading(false);
      }
      setEditIndex(null);
      setEditNewTask('');
    }
  };

  const saveInternEdit = async () => {
    if (editIndex !== null) {
      setLoading(true);
      try {
        const data = {
          data: {
            title: editNewTaskname,
            link: editLink,
          },
        };

        const response = await updateAssignment(editId, data);

        if (response?.status === 200 || response?.status === 201) {
          fetchAssignment(chapter, intern_id)
            .then((data) => {
              setAssignments(data);
              success();
              setLoading(false);
            })
            .catch(() => {
              failed();
              setLoading(false);
            });
        } else {
          failed();
          setLoading(false);
        }
      } catch (error) {
        failed();
        setLoading(false);
      }
      setEditIndex(null);
      setEditNewTaskname('');
      setEditLink('');
    }
  };

  const handleDelete = async (index: number) => {
    setLoading(true);
    const id = assignments[index]?.id;
    try {
      const response = await deleteAssignment(id);

      if (response?.status === 200 || response?.status === 201) {
        fetchAssignment(chapter, intern_id)
          .then((data) => {
            setAssignments(data);
            success();
            setLoading(false);
          })
          .catch(() => {
            failed();
            setLoading(false);
          });
      } else {
        failed();
        setLoading(false);
      }
    } catch (error) {
      failed();
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h6'>Assignment</Typography>
          <IconButton onClick={onClose} size='small'>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          {/* Assignment Creation Section */}
          {superAdmin && (
            <Box>
              <Stack direction='row' spacing={2} alignItems='center'>
                <TextField
                  fullWidth
                  placeholder='Create a e-commerce web app'
                  value={newTask}
                  onChange={(e) => setNewTask(e.target.value)}
                  size='small'
                />
                <Button
                  variant='contained'
                  onClick={handleAddAssignment}
                  sx={{
                    minWidth: '80px',
                    fontWeight: 600,
                  }}
                  color='info'
                >
                  Add
                </Button>
              </Stack>
            </Box>
          )}

          {assignments.length > 0 && (
            <List>
              {assignments.map((assignment, index) =>
                assignment?.attributes?.link === null ? (
                  <ListItem key={assignment?.id}>
                    {editIndex === index ? (
                      <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                        <TextField
                          size='small'
                          value={editNewTask}
                          onChange={(e) => setEditNewTask(e.target.value)}
                          placeholder='Name'
                          sx={{ flex: 1 }}
                        />
                        <IconButton size='small' onClick={saveEdit}>
                          <CheckIcon sx={{width: '16px', height: '16px'}}/>
                        </IconButton>
                        <IconButton size='small' onClick={handleCancelEdit}>
                          <CloseIcon sx={{width: '16px', height: '16px'}}/>
                        </IconButton>
                      </Box>
                    ) : (
                      <>
                        <ListItemText primary={assignment?.attributes?.title} />
                        {superAdmin && (
                        <ListItemSecondaryAction>
                          <IconButton
                            edge='end'
                            size='small'
                            sx={{ mr: 1 }}
                            onClick={() => startEdit(index)}
                          >
                            <EditIcon fontSize='small' sx={{width: '16px', height: '16px'}}/>
                          </IconButton>
                          <DeletePopUp onDelete={() => handleDelete(index)} />
                        </ListItemSecondaryAction>
                        )}
                      </>
                    )}
                  </ListItem>
                ) : null,
              )}
            </List>
          )}

          {/* Assignment Link Section */}
          <Box>
            <Typography variant='h6' gutterBottom>
              Assignment Link
            </Typography>
            <Stack direction='row' spacing={2} alignItems='center'>
              <TextField
                placeholder='taskname'
                value={newTaskname}
                onChange={(e) => setNewTaskname(e.target.value)}
                size='small'
              />
              <TextField
                placeholder='example.com'
                value={newLink}
                onChange={(e) => setNewLink(e.target.value)}
                size='small'
              />
              <Button
                variant='contained'
                onClick={handleAddLink}
                sx={{
                  minWidth: '80px',
                  fontWeight: 600,
                }}
                color='info'
              >
                Add
              </Button>
            </Stack>
          </Box>

          {/* Assignments List */}
          {assignments.length > 0 && (
            <List>
              {assignments.map((assignment, index) =>
                assignment?.attributes?.link !== null ? (
                  <ListItem key={assignment.id}>
                    {editIndex === index ? (
                      <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                        <TextField
                          size='small'
                          value={editNewTaskname}
                          onChange={(e) => setEditNewTaskname(e.target.value)}
                          placeholder='Name'
                          sx={{ flex: 1 }}
                        />
                        <TextField
                          size='small'
                          value={editLink}
                          onChange={(e) => setEditLink(e.target.value)}
                          placeholder='Name'
                          sx={{ flex: 1 }}
                        />
                        <IconButton size='small' onClick={saveInternEdit}>
                          <CheckIcon sx={{width: '16px', height: '16px'}}/>
                        </IconButton>
                        <IconButton size='small' onClick={handleCancelEdit}>
                          <CloseIcon sx={{width: '16px', height: '16px'}}/>
                        </IconButton>
                      </Box>
                    ) : (
                      <>
                        <ListItemText
                          secondary={
                            <>
                              {assignment?.attributes?.title} -{' '}
                              <a
                                href={
                                  assignment?.attributes?.link?.startsWith('http://') || assignment?.attributes?.link?.startsWith('https://')
                                    ? assignment?.attributes?.link
                                    : `https://${assignment?.attributes?.link}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                // style={{ textDecoration: 'none', color: 'inherit' }}
                              >
                                {assignment?.attributes?.link}
                              </a>
                            </>
                          }
                        />
                        {
                          superAdmin &&
                        <ListItemSecondaryAction>
                          <IconButton
                            edge='end'
                            size='small'
                            sx={{ mr: 1 }}
                            onClick={() => startInternEdit(index)}
                          >
                            <EditIcon fontSize='small' sx={{width: '16px', height: '16px'}}/>
                          </IconButton>
                          <DeletePopUp onDelete={() => handleDelete(index)} />
                        </ListItemSecondaryAction>
                        }
                      </>
                    )}
                  </ListItem>
                ) : null,
              )}
            </List>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AssignmentPopup;
