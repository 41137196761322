import { useContext, useState } from 'react';
import {
    NovuProvider,
    useNotifications
} from '@novu/notification-center';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, Popover, Box, Typography } from '@mui/material';
import NotificationList from './NotificationList';
import { UserDataContext } from '../../routes/Main';

const DesktopNotifications = () => {

    const { userData } = useContext(UserDataContext)

    const CustomNotificationCenter = () => {
        const { notifications, unseenCount } = useNotifications();

        const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

        const handleClick = (event: any) => {
            setAnchorEl(event.currentTarget);
        };

        const handlePopoverClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <>
                <Badge badgeContent={unseenCount} max={999999} color='error' >
                    <NotificationsIcon onClick={handleClick} sx={{ width: '28px', height: '28px', color: 'black', cursor: 'pointer' }} />
                </Badge>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 35,
                        horizontal: 0,
                    }}
                >
                    <Box height='450px' width='350px' position='relative'>
                        <Box position='sticky' top='0px' bgcolor='white' borderBottom='1px solid lightgray' display='flex' gap='5px' alignItems='center' p='15px' pb='13px'>
                            <NotificationsIcon />
                            <Typography variant='h6' fontWeight='bold'>Notifications</Typography>
                        </Box>

                        <Box px='15px' pb='15px' mt='-1px'>
                            {notifications?.length > 0 ? notifications?.map((notification) => {
                                return (
                                    <NotificationList notification={notification} key={notification} />
                                )
                            }) : ''}
                        </Box>
                    </Box>
                </Popover>

            </>
        );
    }

    // development:
    // const novuAppId = process.env.REACT_APP_novuAppId?.slice(1, -1);

    // production:
    // const novuAppId = process.env.REACT_APP_novuAppId;

    return (
        <NovuProvider
            backendUrl={process.env.REACT_APP_novuBackendUrl}
            subscriberId={JSON.stringify(userData?.data?.id)}
            applicationIdentifier={process.env.REACT_APP_novuAppId !== undefined ? process.env.REACT_APP_novuAppId : ''}
            // applicationIdentifier={novuAppId !== undefined ? novuAppId : ''}
            initialFetchingStrategy={{ fetchNotifications: true }}
        >
            <CustomNotificationCenter />
        </NovuProvider>
    )
}

export default DesktopNotifications
