import React, { useState, useRef, useContext, useEffect, useCallback } from 'react';
import { Box, Button, Typography, Dialog, DialogContent, Tooltip } from '@mui/material';
import { SocketContext } from '../../utils/SocketProvider';
import isMobile from '../../hooks/isMobile';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { MessageData, RoomData, TypingResponse } from '../../store/constants/constants';
import { UserDataContext } from '../../routes/Main';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { setSelectedMessage, updateTypingStatus } from '../../store/message/messageSlice';
import { startTypingIndicator, stopTypingIndicator } from '../../store/message/messageActions';
import TypingIndicator from './TypingIndicator';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
  Modifier,
  SelectionState,
} from 'draft-js';
import { Editor, SyntheticKeyboardEvent } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import '../css/chatcss.css';
import HtmltoText from './HtmltoText';
import UserMention from './UserMention';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'; // Add this import
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react'; // Emoji picker library
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import { SquareIconButton } from '../../utils/exports/Styles';

interface ChatInputAreaProps {
  droppedFiles: FileList | null;
  chat: RoomData | null;
  onSendMessage: (message: MessageData) => void;
  onSendAttachment: (attachment: {
    base64String: string;
    fileName: string;
    extension: string;
    roomId: number;
  }) => void;
}

const MAX_FILE_SIZE = 10 * 1024 * 1024;

const ChatInputArea: React.FC<ChatInputAreaProps> = ({
  chat,
  onSendMessage,
  onSendAttachment,
  droppedFiles,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const editorWrapperRef = useRef<HTMLDivElement>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [PopoverOpen, setPopoverOpen] = useState(false);

  const { userData } = useContext(UserDataContext);
  const selectedMessage = useSelector((state: RootState) => state.message.selectedMessage);
  const action = useSelector((state: RootState) => state.message.action);

  const dispatch: AppDispatch = useDispatch();
  const socket = useContext(SocketContext);
  const typingUsers = useSelector((state: RootState) => state.message.typingUsers);

  const typingTimerRef = useRef<NodeJS.Timeout | null>(null);
  const typingStartedRef = useRef(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const mobileView = isMobile();
  const [mentionAnchorEl, setMentionAnchorEl] = useState<null | HTMLElement>(null);
  const [mentionSearch, setMentionSearch] = useState('');
  const [isMentionMode, setIsMentionMode] = useState(false);
  const [lastMentionPosition, setLastMentionPosition] = useState<{
    blockKey: string;
    offset: number;
  } | null>(null);

  const handleMentionClose = () => {
    setMentionSearch('');
    setMentionAnchorEl(null);
    setIsMentionMode(false);
    setLastMentionPosition(null);
  };

  const handleMentionSelect = (userName?: string) => {
    if (!userName) return;

    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const currentBlock = currentContent.getBlockForKey(selection.getStartKey());
    const currentBlockText = currentBlock.getText();

    // Find the last @ index
    const lastAtIndex = currentBlockText.lastIndexOf('@');

    if (lastAtIndex !== -1) {
      // Create a selection state that covers the text from @ to current selection
      const mentionSelectionState = selection.merge({
        anchorOffset: lastAtIndex,
        focusOffset: selection.getFocusOffset(),
      }) as SelectionState;

      // Create a new content state with the mention
      const contentWithMention = Modifier.replaceText(
        currentContent,
        mentionSelectionState,
        `@${userName} `,
      );

      // Create a selection for just the mention name (including @)
      const mentionNameSelection = mentionSelectionState.merge({
        anchorOffset: lastAtIndex,
        focusOffset: lastAtIndex + userName.length + 1, // @username
      }) as SelectionState;

      // Apply bold style only to the mention name
      const contentWithStyledMention = Modifier.applyInlineStyle(
        contentWithMention,
        mentionNameSelection,
        'BOLD',
      );

      // Create a new EditorState
      const newEditorState = EditorState.push(
        editorState,
        contentWithStyledMention,
        'insert-characters',
      );

      // Ensure the cursor is placed after the mention with no active style
      const finalEditorState = EditorState.forceSelection(
        newEditorState,
        newEditorState.getSelection().merge({
          anchorOffset: lastAtIndex + userName.length + 2, // @username + space
          focusOffset: lastAtIndex + userName.length + 2,
        }) as SelectionState,
      );

      // Store the last mention position
      setLastMentionPosition({
        blockKey: selection.getStartKey(),
        offset: lastAtIndex,
      });

      setEditorState(finalEditorState);
    }

    setMentionAnchorEl(null);
    setMentionSearch('');
    setIsMentionMode(false);
  };

  const handleTypingStop = useCallback(() => {
    if (chat?.id && socket && typingStartedRef.current) {
      typingStartedRef.current = false;

      dispatch(
        stopTypingIndicator({
          socket: socket,
          payload: { roomId: chat.id },
        }),
      );
    }
  }, [chat?.id, socket, dispatch]);

  const handleTypingStart = useCallback(() => {
    if (chat?.id && socket && !typingStartedRef.current) {
      // Set typing started flag to prevent multiple typing indicators
      typingStartedRef.current = true;

      // Dispatch the start typing action with socket and room ID
      dispatch(
        startTypingIndicator({
          socket: socket,
          payload: { roomId: chat.id },
        }),
      );

      // Clear any existing typing timer
      if (typingTimerRef.current) {
        clearTimeout(typingTimerRef.current);
      }

      // Set a timer to stop typing after a short period of inactivity
      typingTimerRef.current = setTimeout(handleTypingStop, 10000);
    }
  }, [chat?.id, socket, dispatch, handleTypingStop]);

  useEffect(() => {
    if (socket) {
      socket.on('startTyping', (response: TypingResponse) => {
        dispatch(updateTypingStatus(response));
      });
      socket.on('stopTyping', (response: TypingResponse) => {
        dispatch(updateTypingStatus(response));
      });
    }

    return () => {
      if (socket) {
        socket.off('startTyping');
        socket.off('stopTyping');
      }
    };
  }, [socket, dispatch]);

  useEffect(() => {
    if (action === 'edit' && selectedMessage) {
      const blocksFromHTML = convertFromHTML(selectedMessage.content);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [action, selectedMessage]);
  useEffect(() => {
    if (droppedFiles) {
      const newFiles = Array.from(droppedFiles).filter(checkFileSize);
      setSelectedFiles((prev) => [...prev, ...newFiles]);
    }
  }, [droppedFiles]);

  const checkFileSize = (file: File): boolean => {
    if (file.size > MAX_FILE_SIZE) {
      setErrorMessage(`${file.name}`);
      setPopoverOpen(true);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      return false;
    }
    return true;
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);

      const validFiles = filesArray.filter(checkFileSize);
      setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }
  };

  const handleSend = async () => {
    const contentState = editorState.getCurrentContent();
    const htmlContent = draftToHtml(convertToRaw(contentState));
    const plainTextContent = contentState.getPlainText('').trim();

    if (plainTextContent || selectedFiles.length > 0) {
      const roomId = chat?.id ? chat.id : 1;

      // Forcefully reset all mention-related states
      setMentionSearch('');
      setMentionAnchorEl(null);
      setIsMentionMode(false);

      // Send message if available
      if (plainTextContent) {
        onSendMessage({
          senderId: userData?.data?.id,
          roomId,
          content: htmlContent,
        });
      }

      // Process and send selected files as attachments
      for (const file of selectedFiles) {
        const base64String = await convertFileToBase64(file);
        const extension = file.name.split('.').pop() || '';
        const fileName = file.name.substring(0, file.name.lastIndexOf('.'));
        onSendAttachment({
          base64String,
          extension,
          fileName,
          roomId,
        });
      }

      // Clear input fields after sending
      const newEmptyEditorState = EditorState.createEmpty();
      const finalEditorState = EditorState.moveFocusToEnd(newEmptyEditorState);
      setEditorState(finalEditorState);

      setSelectedFiles([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      handleTypingStop();

      // Additional measure to prevent mention popover
      setTimeout(() => {
        setMentionSearch('');
        setMentionAnchorEl(null);
        setIsMentionMode(false);
      }, 0);

      const scrollableDiv = document.getElementById('scrollableDiv');
      if (scrollableDiv) {
        // Add a small delay to ensure the message is rendered
        setTimeout(() => {
          scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
        }, 1500);
      }
    }
  };
  // Function to handle editor state changes
  const onEditorStateChange = (newEditorState: EditorState) => {
    // Trigger typing start when content changes

    const contentText = newEditorState.getCurrentContent().getPlainText('').trim();
    if (contentText.length > 0) {
      handleTypingStart();
    }

    const contentState = newEditorState.getCurrentContent();
    const selection = newEditorState.getSelection();
    const currentBlock = contentState.getBlockForKey(selection.getStartKey());
    const currentBlockText = currentBlock.getText();

    // Handle mention functionality
    const isAfterLastMention = lastMentionPosition
      ? selection.getStartKey() === lastMentionPosition.blockKey
      : false;

    // Find the last @ in the current text
    const lastAtIndex = currentBlockText
      .split('')
      .findIndex(
        (char, index) => char === '@' && (index === 0 || /\s/.test(currentBlockText[index - 1])),
      );

    if (lastAtIndex !== -1 && !isAfterLastMention) {
      const textAfterAt = currentBlockText.slice(lastAtIndex + 1);

      // Mention logic for '@all' or other mentions
      if (textAfterAt === 'all') {
        setMentionSearch('all');
        setMentionAnchorEl(document.getElementById('editor-wrapper'));
        setIsMentionMode(true);
      } else if (
        lastAtIndex === 0 || // @ is the first character
        /\s/.test(currentBlockText[lastAtIndex - 1]) || // @ is preceded by whitespace
        textAfterAt.trim().length > 0 // There are characters after @
      ) {
        setMentionSearch(textAfterAt.trim());
        setMentionAnchorEl(document.getElementById('editor-wrapper'));
        setIsMentionMode(true);
      } else {
        handleMentionClose();
      }
    } else {
      handleMentionClose();
    }

    setEditorState(newEditorState);
  };

  const handleReturn = (e: SyntheticKeyboardEvent): boolean => {
    // Check if Shift key is not pressed (to allow multiline messages with Shift+Enter)
    if (!e.shiftKey) {
      handleSend(); // Call send message function
      return true; // Indicate that the return key press is handled
    }
    return false; // Allow default new line behavior if Shift is pressed
  };

  // Clean up timers on component unmount
  useEffect(() => {
    return () => {
      if (typingTimerRef.current) {
        clearTimeout(typingTimerRef.current);
      }
      handleTypingStop();
    };
  }, [handleTypingStop]);

  const handleRemoveFile = (index: number) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePopoverClose = (event: React.MouseEvent<HTMLElement>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setPopoverOpen(false);
    }
  };

  const [showToolbar, setShowToolbar] = useState(false);

  const toggleToolbar = () => {
    setShowToolbar(!showToolbar);
  };

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef<HTMLDivElement>(null);

  // Function to add emoji to editor
  const addEmoji = (emoji: { native: string }) => {
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    // Create a new content state with the emoji
    const newContent = Modifier.insertText(
      currentContent,
      selection,
      emoji.native,
      editorState.getCurrentInlineStyle(),
    );

    // Create a new editor state with the updated content
    const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');

    // Move cursor to the end
    const finalEditorState = EditorState.moveFocusToEnd(newEditorState);
    setEditorState(finalEditorState);
  };

  // Close emoji picker when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target as Node) &&
        // Check if the click was not on the emoji button
        !(event.target as HTMLElement).closest('button[aria-label="emoji-button"]')
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  const editorRef = useRef<any>(null);

  useEffect(() => {
    if (action === 'reply' && selectedMessage && editorRef.current) {
      // Small delay to ensure the editor is mounted and ready
      setTimeout(() => {
        editorRef.current.focusEditor();
      }, 0);
    }
  }, [action, selectedMessage]);

  return (
    <Box
      width='100%'
      position={mobileView ? 'fixed' : 'inherit'}
      bottom='0'
      display='flex'
      flexDirection='column'
      borderTop='1px solid lightgray'
      borderRadius='5px'
      alignItems='center'
      bgcolor='white'
      padding='4px 0px'
    >
      <TypingIndicator
        typingUsers={typingUsers.filter(
          (user) => user.roomId === chat?.id && user.userId !== userData?.data?.id && user.typing,
        )}
        calledFrom='InputArea'
      />
      <Dialog
        open={PopoverOpen}
        onClose={handlePopoverClose}
        PaperProps={{
          style: {
            padding: 10,
            borderRadius: '8px',
            width: '100%',
            backgroundColor: 'white',
          },
        }}
      >
        <SquareIconButton
          onClick={() => handlePopoverClose({} as React.MouseEvent<HTMLElement>)}
          aria-label='close'
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: 'grey.500',
            '&:hover': {
              color: 'grey.700',
            },
          }}
        >
          <CloseIcon />
        </SquareIconButton>

        <DialogContent>
          <Typography sx={{ color: 'black', textAlign: 'center' }}>
            File <strong>{errorMessage}</strong> exceeds 10MB limit. Upload files less than 10MB.
          </Typography>
        </DialogContent>
      </Dialog>
      {selectedFiles.length > 0 && (
        <Box
          display='flex'
          gap={2}
          p={1}
          border='2px solid lightgray'
          borderRadius={1}
          bgcolor='background.paper'
          zIndex={1}
          maxWidth='800px'
          maxHeight='200px'
          sx={{ overflowX: 'auto', marginBottom: '10px' }}
        >
          <Box display='flex' gap={2}>
            {selectedFiles.map((file, index) => (
              <Box key={index} display='flex' alignItems='center' gap={2} position='relative'>
                {file.type.startsWith('image/') ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    width='auto'
                    height='160'
                    style={{ objectFit: 'cover', borderRadius: '3px' }}
                  />
                ) : (
                  // <Tooltip title='Attach File'>
                  <Box display='flex' alignItems='center'>
                    <AttachFileIcon />
                    <Box ml={1}>{file.name}</Box>
                  </Box>
                  // </Tooltip>
                )}
                <SquareIconButton
                  sx={{
                    position: 'absolute',
                    top: -6,
                    right: -6,
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    },
                    boxShadow: 2,
                    borderRadius: '50%',
                    padding: '2px',
                  }}
                  onClick={() => handleRemoveFile(index)}
                >
                  <CloseIcon />
                </SquareIconButton>
              </Box>
            ))}
            <Button
              variant='contained'
              sx={{
                height: '160px',
                color: 'white',
                bgcolor: '#D3D3D3',
                '&:hover': {
                  bgcolor: '#C0C0C0',
                },
              }}
              onClick={() => fileInputRef.current?.click()}
            >
              <AddIcon />
            </Button>
          </Box>
        </Box>
      )}
      <Box
        width='100%'
        display='flex'
        flexDirection='column'
        // gap='6'
        justifyContent='space-evenly'
        alignItems='center'
      >
        {selectedMessage && action === 'reply' && (
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignContent='center'
            width='100%'
            bgcolor='#f0f0f0'
            p={2}
            mb={1}
            borderRadius='8px'
            border='1px solid #ccc'
            position='relative'
            sx={{ wordBreak: 'break-word' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='caption' color='textSecondary'>
                Replying to: {selectedMessage.senderId}
              </Typography>
              <Typography variant='body2' color='textPrimary'>
                {selectedMessage.type === 'forwardMessage' ? (
                  <HtmltoText HtmlContent={selectedMessage.forwardMessage?.content} />
                ) : (
                  <HtmltoText HtmlContent={selectedMessage.content} />
                )}
              </Typography>
            </div>

            <SquareIconButton
              size='small'
              sx={{ height: '20px', width: '20px' }}
              onClick={() => dispatch(setSelectedMessage(null))}
            >
              <CloseIcon fontSize='small' />
            </SquareIconButton>
          </Box>
        )}

        {/* Emoji Picker */}
        {showEmojiPicker && (
          <div
            ref={emojiPickerRef}
            style={{
              position: 'absolute',
              bottom: '50px',
              right: '20px',
              zIndex: 1000,
            }}
          >
            <Picker data={data} onEmojiSelect={addEmoji} theme='light' previewPosition='none' />
          </div>
        )}
        <Box
          width={mobileView ? 'calc(100dvw)' : 'calc(75dvw - 65px)'}
          maxWidth={mobileView ? 'calc(100dvw)' : 'calc(75dvw - 65px)'}
          display='flex'
          // gap={mobileView ? '0' : '10px'}
          justifyContent='space-evenly'
          alignItems='end'
        >
          <Box sx={{ height: '41.78px', display: 'flex', alignItems: 'center' }}>
            <Tooltip title='Record Message'>
              <SquareIconButton
                sx={{
                  color: 'black',
                  width: mobileView ? '20px' : '30px',
                  height: mobileView ? '20px' : '30px',
                }}
              >
                <KeyboardVoiceIcon />
              </SquareIconButton>
            </Tooltip>
          </Box>

          {/* <SquareIconButton sx={{ color: 'black' }} >
            <AlternateEmailIcon />
          </SquareIconButton> */}

          {/* Mention Dropdown Menu */}
          {/* <Menu
            anchorEl={mentionAnchorEl}
            open={Boolean(mentionAnchorEl) && filteredParticipants.length > 0}
            onClose={() => {
              setMentionAnchorEl(null);
              setMentionSearch('');
            }}
            PaperProps={{
              style: {
                maxHeight: 300,
                width: '250px',
              },
            }}
          >
            {filteredParticipants.map((participant) => (
              <MenuItem key={participant.id} onClick={() => handleMentionSelect(participant)}>
                <Box display='flex' alignItems='center'>
                  <Typography variant='body2' sx={{ ml: 1 }}>
                    {participant.fullName} (@{participant.userName})
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Menu> */}

          <Box
            id='editor-wrapper'
            ref={editorWrapperRef}
            display='flex'
            flexDirection='column'
            position='relative'
            width={mobileView ? 'calc(100% - 190px)' : 'calc(100% - 250px)'}
            alignSelf={'end'}
          >
            <Editor
              ref={editorRef}
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              wrapperClassName='demo-wrapper'
              handleReturn={handleReturn}
              editorClassName='demo-editor'
              wrapperStyle={{
                margin: '0px',
                padding: 0,
              }}
              editorStyle={{
                margin: '0px',

                padding: '0px 5px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                maxHeight: '100px',
                overflowY: 'auto',
              }}
              toolbarStyle={{ margin: '0px', Padding: '0px' }}
              placeholder='Type Your Message'
              toolbarHidden={!showToolbar}
              toolbar={{
                options: ['inline'],
                inline: {
                  options: ['bold', 'italic', 'underline'],
                },
              }}
            />
          </Box>
          <UserMention
            anchorEl={mentionAnchorEl}
            onClose={handleMentionClose}
            onMentionSelect={handleMentionSelect}
            searchTerm={mentionSearch}
            isMentionMode={isMentionMode}
          />
          <Box sx={{ height: '41.78px', display: 'flex', alignItems: 'center', gap: '15px' }}>
            {/* // Emoji */}
            <Tooltip title='Emoji'>
              <SquareIconButton
                sx={{
                  color: 'black',
                  width: mobileView ? '20px' : '30px',
                  height: mobileView ? '20px' : '30px',
                }}
                onClick={toggleEmojiPicker}
                aria-label='emoji-button'
              >
                <EmojiEmotionsIcon />
              </SquareIconButton>
            </Tooltip>

            {/* Text Format */}
            <Tooltip title='Text Format'>
              <SquareIconButton
                sx={{
                  color: 'black',
                  width: mobileView ? '20px' : '30px',
                  height: mobileView ? '20px' : '30px',
                }}
                onClick={toggleToolbar}
              >
                <FormatColorTextIcon />
              </SquareIconButton>
            </Tooltip>

            {/* Attachfile */}
            <Tooltip title='Attach File'>
              <SquareIconButton
                sx={{
                  color: 'black',
                  width: mobileView ? '20px' : '30px',
                  height: mobileView ? '20px' : '30px',
                }}
                onClick={() => fileInputRef.current?.click()}
              >
                <AttachFileIcon />
              </SquareIconButton>
            </Tooltip>
            <input
              ref={fileInputRef}
              type='file'
              multiple
              style={{ display: 'none' }}
              onChange={handleFileSelect}
            />

            {/* Send */}
            <Tooltip title='Send'>
              <SquareIconButton
                sx={{
                  color: 'black',
                  width: mobileView ? '20px' : '30px',
                  height: mobileView ? '20px' : '30px',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSend();
                }}
              >
                <SendIcon />
              </SquareIconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatInputArea;
