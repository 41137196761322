import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const FilterPriority = () => {

    const [searchParams, setQueryParams] = useSearchParams();
    const [filterPriority, setFilterPriority] = useState('');

    useEffect(() => {
        setFilterPriority(searchParams.get('priority') ?? '');
    }, [searchParams]);

    const sortPriority = (event: SelectChangeEvent) => {
        const queryParams = Object.fromEntries(searchParams);
        if (event.target.value === 'all') {
            delete queryParams.priority;
          } else {
            queryParams.priority = event.target.value as string;
          }
          setQueryParams(queryParams);
    };

    return (
        <>
            <FormControl size='small' variant='standard'>
                <InputLabel sx={{ fontWeight: 'bold', color: '#000000DE',fontSize: '12px' }}>Priority</InputLabel>
                <Select
                    disableUnderline
                    size='small'
                    label='Priority'
                    aria-label='priority-select'
                    value={filterPriority}
                    onChange={sortPriority}
                    sx={{
                        fontSize: '12px',
                        width: '75px',
                        mb: '8px',
                        '.MuiSelect-icon': {
                            color: '#000000DE',
                            mt: '-2px'
                        },
                    }}
                >
                    <MenuItem aria-label='all' value='all'>All</MenuItem>
                    <MenuItem aria-label='highest' value='highest'>HIGHEST</MenuItem>
                    <MenuItem aria-label='high' value='high'>HIGH</MenuItem>
                    <MenuItem aria-label='medium' value='medium'>MEDIUM</MenuItem>
                    <MenuItem aria-label='low' value='low'>LOW</MenuItem>
                </Select>
            </FormControl>
        </>
    )
}

export default FilterPriority
