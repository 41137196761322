import React from "react";
import { List, ListItem, ListItemText, AccordionDetails } from "@mui/material";
import { ITopic } from "../../exports/interfaces";

interface ChapterContentProps {
  content?: ITopic[];
}

export const ChapterContent: React.FC<ChapterContentProps> = ({
  content = [],
}) => ( 
  <AccordionDetails>
    <List sx={{ pt: 0 }}>
      {content.map((item, index) => (
        <ListItem key={index} sx={{ pb: 0, height: 30 }}>
          <ListItemText primary={item?.attributes?.title} />
        </ListItem>
      ))}
    </List>
  </AccordionDetails>
);
