import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { Box, Dialog, DialogContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Dispatch, SetStateAction, useState } from 'react';
import isMobile from '../../hooks/isMobile';
import OverviewPills from '../OverviewPills';
import TicketForum from '../TicketForum';
import AssignDeveloper from '../AssignDeveloper';
// import Pills from '../Pills';
import { useDrag } from 'react-dnd';
import striptags from 'striptags';
import TicketDetails from '../TicketDetails';
import { hasDueDatePassed, isToday, isTomorrow, isYesterday } from '../../utils/exports/Function';
import { ITicketData } from '../../utils/exports/Interface';

export const ItemTypes = {
  ITEM: 'item',
};

const OverviewTicket = ({
  data,
  ticketsData,
  setTicketsData,
  getBoardTickets,
}: {
  data: ITicketData;
  getBoardTickets?: (status: string) => void;
  ticketsData?: ITicketData[];
  setTicketsData?: Dispatch<SetStateAction<ITicketData[]>>;
}) => {
  const iconSize = {
    width: '20px',
    height: '20px',
  };

  const theme = useTheme();

  const idToDrop = data?.id;

  const [, drag] = useDrag(() => ({
    type: ItemTypes.ITEM,
    item: { idToDrop, data },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleMobileOpen = () => {
    setMobileOpen(true);
  };

  const handleMobileClose = () => {
    setMobileOpen(false);
  };

  const [imageOpen, setImageOpen] = useState(false);
  const [imageToOpen] = useState('');
  const handleImageClose = () => {
    setImageOpen(false);
  };

  const mobile = isMobile();

  // console.log(data?.attributes?.attachment?.data);
  // const [download, setDownload] = useState(false);

  // useEffect(() => {
  //   data?.attributes?.attachment?.data?.map((file: any) => {
  //     if (file.attributes.ext !== '.png' && file.attributes.ext !== '.jpg') {
  //       setDownload(true)
  //     }
  //   })
  // }, [data])

  return (
    <>
      <Box
        ref={drag}
        onClick={() => {
          !mobile ? handleClickOpen() : handleMobileOpen();
        }}
        display='flex'
        flexDirection='column'
        width='100%'
        p='10px'
        gap='5px'
        bgcolor={
          (data?.attributes?.status === 'backlog' ||
            data?.attributes?.status === 'on hold' ||
            data?.attributes?.status === 'todo' ||
            data?.attributes?.status === 'redo' ||
            data?.attributes?.status === 'in progress') &&
          data?.attributes?.due_date !== null &&
          hasDueDatePassed(data?.attributes?.due_date)
            ? '#f8d7da'
            : theme.palette.background.default
        }
        sx={{
          borderRadius: '5px',
          cursor: 'move',
        }}
      >
        <Box display='flex' alignItems='center'>
          <Typography fontSize='14px' fontStyle='italic' minWidth='80px'>
            {data?.attributes?.tenantwise_id}
          </Typography>
          <Typography
            fontSize='14px'
            noWrap
            className='content-wrapper'
            // dangerouslySetInnerHTML={{ __html: striptags(data?.attributes?.description, ['a']) }}
            dangerouslySetInnerHTML={{ __html: striptags(data?.attributes?.description) }}
          />
        </Box>
        <Box display='flex' gap='5px' alignItems='center'>
          <OverviewPills pill={data?.attributes?.type} />
          <OverviewPills pill={data?.attributes?.priority} />
          <Typography ml='auto' fontSize='12px' noWrap>
            {data?.attributes?.due_date != null
              ? isToday(data?.attributes?.due_date)
                ? 'Tday'
                : isTomorrow(data?.attributes?.due_date)
                ? 'Tmrw'
                : isYesterday(data?.attributes?.due_date)
                ? 'Yday'
                : new Date(data?.attributes?.due_date).toLocaleDateString('en-GB')
              : ''}
          </Typography>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box display='flex' flexDirection='row' gap='2px'>
            <SmsOutlinedIcon sx={iconSize} />
            <Typography fontSize='12px'>
              {data?.attributes?.chats?.data != null ? data?.attributes?.chats?.data?.length : '0'}
            </Typography>
            <AttachFileIcon sx={iconSize} />
            <Typography fontSize='12px'>
              {data?.attributes?.attachment?.data != null
                ? data?.attributes?.attachment?.data?.length
                : '0'}
            </Typography>
          </Box>
          {
            data?.attributes?.assignees !== null && data?.attributes?.assignees?.length !== 0 && (
              <AssignDeveloper
                assignees={data?.attributes?.assignees}
                idToChange={data?.id}
                main_tenant={data?.attributes?.main_tenant}
                newTicket={false}
                setAssignees={undefined}
                static={true}
                hideAssigneeBtn={true}
              />
            )
            // <Typography fontSize='14px'>{data?.attributes?.assignees[0]?.substring(0, 8) === 'customer' ? data?.attributes?.assignees[0].split(' ')[0].slice(8) : data?.attributes?.assignees[0].split(' ')[0]}</Typography>
          }
        </Box>
      </Box>
      {/*  */}
      <TicketDetails
        open={open}
        setOpen={setOpen}
        ticketsData={ticketsData}
        setTicketsData={setTicketsData}
        ticketId={data?.attributes?.ticket_id}
        getBoardTickets={getBoardTickets}
      />
      {/*  */}
      <Dialog open={imageOpen} onClose={handleImageClose} maxWidth='md'>
        <DialogContent sx={{ padding: '0px' }}>
          <img
            src={`${process.env.REACT_APP_strapiImage}${imageToOpen}`}
            alt='ticket-image'
            width='100%'
            height='100%'
          />
        </DialogContent>
      </Dialog>

      <Dialog open={mobileOpen} maxWidth='sm' fullWidth onClose={handleMobileClose}>
        <Box m='15px' display='flex'>
          <ArrowBackIcon onClick={handleMobileClose} />
        </Box>
        <Box
          m='15px'
          mt='0px'
          border='solid'
          borderColor='lightgray'
          borderRadius='10px'
          sx={{ borderWidth: '1px' }}
        >
          <TicketForum data={data} selectedRowId={data?.attributes?.ticket_id} />
        </Box>
      </Dialog>
    </>
  );
};

export default OverviewTicket;
