import { Box } from '@mui/material';
import { useContext } from 'react';
import { TenantContext } from '../../../App';

export default function MobileTicketSection({ children }: { children: React.ReactNode }) {

  const { tenant } = useContext(TenantContext);

  return (
    <Box
      width='100%'
      bgcolor='white'
      p='10px'
      pt='0px'
      pb='15px'
      border='1px dashed gray'
      borderRadius='10px'
      borderColor='gray'
      display='flex'
      flexDirection='column'
      gap='10px'
      height={tenant === 'all' ? 'calc(100vh - 215px)' : 'calc(100vh - 250px)'}
      sx={{ overflowY: 'scroll' }}
    >
      {children}
    </Box>
  );
}
