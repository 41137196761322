import React from "react";
import { Box, Typography, IconButton, Paper } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
// import { Course, Chapter } from '../../types';

// import { Course, Chapter } from "../CourseObj";
import { ChapterAccordion } from "./ChapterAccordion";
import { IChapter, ICourse } from "../../exports/interfaces";

interface ChapterListProps {
  course: ICourse | undefined;
  expandedChapter: string;
  onChapterChange: (
    chapterName: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  onAddChapter: (id: number) => void;
  onEditChapter: (chapter: IChapter) => void;
  onBookmarkChapter: (chapter: IChapter) => void;
  isMobile: boolean;
}

export const ChapterList: React.FC<ChapterListProps> = ({
  course,
  expandedChapter,
  onChapterChange,
  onAddChapter,
  onEditChapter,
  onBookmarkChapter,
  isMobile,
}) => (
  <Box sx={{ width: isMobile ? "100%" : "80%" }}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 1,
        height: 30,
      }}
    >
      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
        <>
        {course?.attributes?.Title} Chapters
        </>
      </Typography>
      <IconButton size="small"  onClick={() => onAddChapter(course?.id || 0)}>
        <AddIcon />
      </IconButton>
    </Box>
    <Paper sx={{ maxHeight: isMobile ? "42dvh" : "calc(100dvh - 168px)", overflow: "auto" }}>
      {course?.attributes?.chapters?.data.map((chapter) => (
        <ChapterAccordion
          key={chapter?.id}
          chapter={chapter?.attributes}
          isExpanded={expandedChapter === chapter?.attributes?.title}
          onChange={onChapterChange(chapter?.attributes?.title)}
          onEdit={() => onEditChapter(chapter)}
          onBookmark={() => onBookmarkChapter(chapter)}
        />
      ))}
    </Paper>
  </Box>
);
