import {
  Box,
  // Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import DeleteTicket from './DeleteTicket';
import EditType from './EditType';
import EditPriority from './EditPriority';
import EditStatus from './EditStatus';
import TicketForum from './TicketForum';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import isSuperAdmin from '../hooks/isSuperAdmin';
import EditDescription from './EditDescription';
import EditDueDate from './EditDueDate';
import DueDatePicker from './Desktop/DueDatePicker';
import AssignDeveloper from './AssignDeveloper';
import ViewAttachments from './Desktop/ViewAttachments';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EditEnvironment from './EditEnvironment';
import EditMerged from './EditMerged';
import EditBranch from './EditBranch';
import Estimate from './Estimate';
import EditBoard from './EditBoard';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import MergeIcon from '@mui/icons-material/Merge';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EditTenant from './EditTenant';
import { LoadingContext } from '../App';
import StartDatePicker from './Desktop/StartDatePicker';
import EditStartDate from './EditStartDate';
import { ITicketData } from '../utils/exports/Interface';

const TicketDetails = (props: {
  open: boolean;
  ticketId: string;
  tenantwiseId?: string;
  ticketsData?: ITicketData[];
  setTicketsData?: Dispatch<SetStateAction<ITicketData[]>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  getBoardTickets?: (status: string) => void;
}) => {
  // const [loading, setLoading] = useState(false);
  const { setLoading } = useContext(LoadingContext);

  const [data, setData] = useState<any>('');

  const superAdmin = isSuperAdmin();

  const id = props?.ticketId?.slice(4);

  const headers = {
    Authorization: `Bearer ${process.env.REACT_APP_token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  useEffect(() => {
    if (id && props.open) {
      setLoading(true);
      fetchTicket();
    } else {
      setData('');
    }
  }, [props.open, id]);

  const fetchTicket = () => {
    axios
      .get(`${process.env.REACT_APP_strapiUrl}/tickets/${id}?populate=*`, { headers })
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setData(undefined);
      });
  };

  const getUrl = (selectedTicket: any) => {
    return selectedTicket.attributes.url;
  };

  const getImages = (selectedTicket: any) => {
    return `${process.env.REACT_APP_strapiImage}${selectedTicket.attributes.url}`;
  };

  const images =
    data !== undefined && data?.attributes?.attachment?.data?.length > 0
      ? data?.attributes?.attachment?.data?.map(getImages)
      : '';
  const url =
    data !== undefined && data?.attributes?.attachment?.data?.length > 0
      ? data?.attributes?.attachment?.data?.map(getUrl)
      : '';

  const handleClose = () => {
    setData('');
    props.setOpen(false);
  };

  return (
    <>
      {data === undefined ? (
        <Dialog open={props.open} onClose={() => props.setOpen(false)}>
          <Box padding='15px' display='flex' gap='5px' alignItems='center'>
            <ErrorOutlineIcon color='error' />
            Ticket is not available!
          </Box>
        </Dialog>
      ) : data === '' ? (
        ''
      ) : (
        <Dialog
          open={props.open}
          onClose={handleClose}
          maxWidth='lg'
          fullWidth
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: '15px',
          }}
        >
          <DialogTitle
            display='flex'
            // flexDirection='column'
            justifyContent='space-between'
            // gap='15px'
            // m='20px 5px 20px 5px'
            alignItems='center'
            // padding='15px 24px 10px 24px !important'
            padding='15px !important'
          >
            <Box display='flex' gap='8px'>
              <EditTenant
                ticketId={data !== undefined ? data?.id : ''}
                tenant={data !== undefined ? data?.attributes?.main_tenant : ''}
                currentStatus={data !== undefined ? data?.attributes?.status : ''}
                fetchTicket={fetchTicket}
                getBoardTickets={props.getBoardTickets}
              />
              <EditBoard
                ticketId={data !== undefined ? data?.id : ''}
                ticketBoard={
                  data !== undefined
                    ? data?.attributes?.boards?.data?.map((board: { id: number }) => board?.id)
                    : []
                }
                tenant={data !== undefined ? data?.attributes?.main_tenant : ''}
                fetchTicket={fetchTicket}
                currentStatus={data !== undefined ? data?.attributes?.status : ''}
                getBoardTickets={props.getBoardTickets}
                defaultBoard={data !== undefined ? data?.attributes?.default_board : false}
              />
            </Box>
            <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
          </DialogTitle>
          <DialogContent
            sx={{
              p: '0px',
              overflowY: 'hidden',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                // width: '100%',
                // p: '10px 24px 10px 24px',
                p: '15px',
                borderTop: 'solid',
                borderColor: 'lightgray',
                // borderRadius: '5px',
                gap: '15px',
                borderWidth: '1px',
              }}
            >
              <Box
                display='flex'
                // flexDirection='column'
                justifyContent='space-between'
                gap='15px'
                // m='20px 5px 20px 5px'
              >
                <Box display='flex' gap='15px'>
                  <Typography fontSize='12px'>Created:</Typography>
                  <Typography fontSize='12px'>
                    {new Date(
                      data !== undefined ? data?.attributes?.createdAt : '',
                    ).toLocaleDateString('en-GB')}
                  </Typography>
                </Box>
              </Box>
              <Typography
                fontWeight='bold'
                whiteSpace='pre-wrap'
                fontSize='14px'
                height='100px'
                sx={{
                  overflowY: 'scroll',
                }}
              >
                <i style={{ marginRight: '10px' }}>{data?.attributes?.tenantwise_id}</i>{' '}
                <span
                  className='content-wrapper'
                  dangerouslySetInnerHTML={{
                    __html: data?.attributes?.description
                      .replace(/<p>/g, '<span>')
                      .replace(/<\/p>/g, '</span>'),
                  }}
                />{' '}
                {superAdmin && (
                  <EditDescription
                    ticket_id={props?.ticketId}
                    id={data !== undefined ? data?.id : ''}
                    current={data !== undefined ? data?.attributes?.description : ''}
                    fetchTicket={fetchTicket}
                    ticketsData={props?.ticketsData}
                    setTicketsData={props?.setTicketsData}
                  />
                )}
                {data?.attributes?.merged_ticket && (
                  <Typography
                    // fontWeight='bold'
                    whiteSpace='pre-wrap'
                    display='flex'
                    alignItems='center'
                    fontSize='12px'
                  >
                    {/* <Box display='inline-block' alignItems="center"> */}
                    {/* <Pills pill='merged' /> */}
                    <MergeIcon sx={{ transform: 'rotate(90deg)', ml: '40px', mr: '10px' }} />
                    {/* </Box>{' '} */}
                    {data?.attributes?.merged_ticket}{' '}
                    {superAdmin && (
                      <EditMerged
                        ticket_id={props?.ticketId}
                        id={data !== undefined ? data?.id : ''}
                        current={data !== undefined ? data?.attributes?.merged_ticket : ''}
                        fetchTicket={fetchTicket}
                      />
                    )}
                  </Typography>
                )}
              </Typography>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                m='5px 0px 0px 0px'
              >
                <Box display='flex' justifyContent='space-between' alignItems='center' gap='10px'>
                  <Box flex='1' display='flex' justifyContent='start'>
                    <EditType
                      tenant={data !== undefined ? data?.attributes?.main_tenant : ''}
                      fullId={data !== undefined ? data?.attributes?.ticket_id : ''}
                      idToChange={data !== undefined ? data?.id : ''}
                      type={data !== undefined ? data?.attributes?.type : ''}
                      ticketsData={props?.ticketsData}
                      setTicketsData={props?.setTicketsData}
                      fetchTicket={fetchTicket}
                    />
                  </Box>
                  <Box flex='1' display='flex' justifyContent='center'>
                    <EditPriority
                      tenant={data !== undefined ? data?.attributes?.main_tenant : ''}
                      fullId={data !== undefined ? data?.attributes?.ticket_id : ''}
                      idToChange={data !== undefined ? data?.id : ''}
                      priority={data !== undefined ? data?.attributes?.priority : ''}
                      fetchTicket={fetchTicket}
                      ticketsData={props?.ticketsData}
                      setTicketsData={props?.setTicketsData}
                    />
                  </Box>
                  <Box flex='1' display='flex' justifyContent='end'>
                    <EditStatus
                      dragDrop={false}
                      tenant={data !== undefined ? data?.attributes?.main_tenant : ''}
                      type={data !== undefined ? data?.attributes?.type : ''}
                      branch={data !== undefined ? data?.attributes?.branch : ''}
                      fullId={data !== undefined ? data?.attributes?.ticket_id : ''}
                      assignees={data !== undefined ? data?.attributes?.assignees : ''}
                      dueDate={data !== undefined ? data?.attributes?.due_date : ''}
                      idToChange={data !== undefined ? data?.id : ''}
                      status={data !== undefined ? data?.attributes?.status : ''}
                      fetchTicket={fetchTicket}
                      getBoardTickets={props.getBoardTickets}
                    />
                  </Box>
                  <Box flex='1' display='flex' justifyContent='center'>
                    <EditEnvironment
                      environment={
                        data?.attributes?.environment
                          ? data?.attributes?.environment.toUpperCase()
                          : '-'
                      }
                      tenant={data !== undefined ? data?.attributes?.main_tenant : ''}
                      fullId={data !== undefined ? data?.attributes?.ticket_id : ''}
                      idToChange={data !== undefined ? data?.id : ''}
                      fetchTicket={fetchTicket}
                    />
                  </Box>
                  <Box flex='1' display='flex' justifyContent='end'>
                    {url != '' ? (
                      <ViewAttachments
                        url={url}
                        images={images}
                        name={data?.attributes?.ticket_id}
                      />
                    ) : (
                      // ''
                      <Box
                        display='flex'
                        alignItems='center'
                        sx={{
                          cursor: 'not-allowed',
                        }}
                      >
                        <AttachFileIcon sx={{ width: '17px', height: '17px', color: '#3751FF' }} />
                        <Box color='#3751FF'>0</Box>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box display='flex' justifyContent='space-between' alignItems='center' gap='15px'>
                  <Chip
                    sx={{
                      height: '20px',
                    }}
                    label={
                      <Box display='flex' gap='15px'>
                        <Typography fontSize='12px'>Start:</Typography>
                        {data?.attributes?.start_date !== null ? (
                          <EditStartDate
                            defaultDate={data !== undefined ? data?.attributes?.start_date : ''}
                            selectedRowId={data !== undefined ? data?.attributes?.ticket_id : ''}
                            fetchTicket={fetchTicket}
                            ticketsData={props.ticketsData}
                            setTicketsData={props.setTicketsData}
                          />
                        ) : (
                          <StartDatePicker
                            selectedRowId={data?.attributes?.ticket_id}
                            fetchTicket={fetchTicket}
                            ticketsData={props.ticketsData}
                            setTicketsData={props.setTicketsData}
                          />
                        )}
                      </Box>
                    }
                  />

                  <Chip
                    sx={{
                      height: '20px',
                    }}
                    label={
                      <Box display='flex' gap='15px'>
                        <Typography fontSize='12px'>Due:</Typography>
                        {data?.attributes?.due_date !== null ? (
                          <EditDueDate
                            defaultDate={data !== undefined ? data?.attributes?.due_date : ''}
                            selectedRowId={data !== undefined ? data?.attributes?.ticket_id : ''}
                            fetchTicket={fetchTicket}
                            ticketsData={props.ticketsData}
                            setTicketsData={props.setTicketsData}
                          />
                        ) : (
                          <DueDatePicker
                            selectedRowId={data?.attributes?.ticket_id}
                            tenantwiseId={data?.attributes?.tenantwise_id}
                            fetchTicket={fetchTicket}
                            ticketsData={props.ticketsData}
                            setTicketsData={props.setTicketsData}
                          />
                        )}
                      </Box>
                    }
                  />

                  <Chip
                    sx={{
                      height: '20px',
                    }}
                    label={
                      <Box display='flex' gap='15px'>
                        <Typography fontSize='12px'>Estimate:</Typography>
                        <Estimate
                          estimate={data !== undefined ? data?.attributes?.estimate : ''}
                          id={data !== undefined ? data?.id : ''}
                          fetchTicket={fetchTicket}
                        />
                      </Box>
                    }
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                // flexDirection='column'
                justifyContent='space-between'
                gap='15px'
              >
                <AssignDeveloper
                  newTicket={false}
                  setAssignees={undefined}
                  idToChange={data !== undefined ? data?.id : ''}
                  assignees={data?.attributes?.assignees}
                  main_tenant={data?.attributes?.main_tenant}
                  fetchTicket={fetchTicket}
                />
                <EditBranch
                  fullId={data !== undefined ? data?.attributes?.ticket_id : ''}
                  type={data !== undefined ? data?.attributes?.type : ''}
                  id={data !== undefined ? data?.id : ''}
                  branch={data !== undefined ? data?.attributes?.branch : ''}
                  tenant={data !== undefined ? data?.attributes?.main_tenant : ''}
                  fetchTicket={fetchTicket}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                // p: '10px 24px 10px 24px',
                p: '15px',
                borderTop: 'solid',
                borderBottom: 'solid',
                borderColor: 'lightgray',
                gap: '15px',
                borderWidth: '1px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px',
                  flex: 1,
                }}
              >
                <Typography fontWeight='bold' whiteSpace='pre-wrap' fontSize='14px'>
                  Relates
                </Typography>
                <Box display='flex' alignItems='center'>
                  <WarningIcon style={{ marginRight: '8px' }} />
                  <Typography fontSize='12px'>No Related Tickets</Typography>
                </Box>
              </Box>
              <Box sx={{ flex: 1 }}>
                <TicketForum
                  selectedRowId={data !== undefined ? data?.attributes?.ticket_id : ''}
                  data={data}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              // flexDirection='column'
              justifyContent: 'start',
              // gap='15px'
              // m='20px 5px 20px 5px'
              // p: '10px 24px 15px 24px'
              p: '15px',
            }}
          >
            <DeleteTicket
              tenant={data !== undefined ? data?.attributes?.main_tenant : ''}
              data={data !== undefined ? data : undefined}
              selectedRowId={data !== undefined ? data?.attributes?.ticket_id : ''}
              tenantwise_id={data !== undefined ? data?.attributes?.tenantwise_id : ''}
              status={data !== undefined ? data?.attributes?.status : ''}
              getBoardTickets={props.getBoardTickets}
            />
            {/* <Button
              onClick={handleClose}
              // sx={{
              //   border: 'solid',
              //   borderColor: 'lightgray',
              //   padding: '0px',
              // }}
            >
              CANCEL
            </Button> */}
          </DialogActions>
        </Dialog>
      )}

      {/* <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
        }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop> */}
    </>
  );
};

export default TicketDetails;
