import { useState, useContext, useEffect } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  FormControl,
  Typography,
} from '@mui/material';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { Box } from '@mui/system';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AllTenantsContext, UserDataContext, UserListContext } from '../../routes/Main';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import { BoardContext, CurrentUserContext, RefreshContext, TenantContext } from '../../App';
import { failed } from '../../hooks/useToast';
import { catchedTrigger } from '../../utils/novu';
import isDeveloper from '../../hooks/isDeveloper';
import AssignDeveloper from '../AssignDeveloper';
import { recordLog } from '../../services/recordLog';
import { IUserData } from '../../utils/exports/Interface';
import DoneButton from '../UI/DoneButton';

const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

const MobileCreateTicketForm = () => {
  const { userData } = useContext(UserDataContext);

  const { allTenants } = useContext(AllTenantsContext);

  const { currentUser } = useContext(CurrentUserContext);

  const { tenant, setTenant } = useContext(TenantContext);

  const { board } = useContext(BoardContext);

  const { refresh, setRefresh } = useContext(RefreshContext);

  const toggleRefresh = () => {
    refresh ? setRefresh(false) : setRefresh(true);
  };

  const superAdmin = isSuperAdmin();

  const developer = isDeveloper();

  const [type, setType] = useState('task');
  const [priority, setPriority] = useState('high');
  const [environment, setEnvironment] = useState('Sandbox');
  const [description, setDescription] = useState('');
  const [assignees, setAssignees] = useState<string[] | null>(null);
  const [files, setFiles] = useState<any>([]);

  const [createTicketTenant, setCreateTicketTenant] = useState<string>();

  useEffect(() => {
    setCreateTicketTenant(tenant === 'all' ? 'enitiation' : tenant);
  }, [refresh]);

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  const handlePriorityChange = (event: SelectChangeEvent) => {
    setPriority(event.target.value as string);
  };

  const handleEnvironmentChange = (event: SelectChangeEvent) => {
    setEnvironment(event.target.value as string);
  };

  const [ticketId, setTicketId] = useState('');

  useEffect(() => {
    if (superAdmin || developer) {
      setTicketId(
        allTenants?.data?.tenants?.filter(
          (tenant1: { id: string }) => tenant1?.id === createTicketTenant,
        )[0]?.organization?.ticket_id,
      );
    } else {
      setTicketId(
        allTenants?.data?.tenants?.filter(
          (tenant1: { id: string }) => tenant1?.id === userData?.data?.organizations[0]?.alias,
        )[0]?.organization?.ticket_id,
      );
    }
  }, [allTenants, tenant, createTicketTenant]);

  const navigate = useNavigate();

  const { userList } = useContext(UserListContext);

  const [relatedUsers, setRelatedUsers] = useState<any>();

  useEffect(() => {
    const users = userList?.data?.users?.users?.filter(
      (user: IUserData) =>
        user?.organizations?.some(
          (obj: { alias: string }) =>
            obj.alias ===
            (superAdmin || developer
              ? createTicketTenant
              : userData !== undefined && userData.data.main_tenant),
        ) || user.roles[0].name === 'sa',
    );
    const notifUsers = users?.map((user: IUserData) => {
      return {
        subscriberId: JSON.stringify(user.id),
      };
    });
    setRelatedUsers(notifUsers);
  }, [userList, superAdmin, developer, createTicketTenant, userData]);

  const createTicket = async () => {
    const formData = new FormData();

    Array.from(files).forEach((file: any) => {
      formData.append('files', file);
      formData.append('ref', 'ticket');
      formData.append('field', 'attachment');
    });

    axios
      .post(`${process.env.REACT_APP_strapiUrl}/upload`, formData, {
        headers: headers,
      })
      .then((response) => {
        const attachments: number[] = [];
        response.data.map((imageId: { id: number }) => {
          attachments.push(imageId.id);
        });
        const ticketInfo = {
          data: {
            description: description,
            type: type,
            priority: priority,
            status: 'backlog',
            environment: environment,
            main_tenant:
              superAdmin || developer
                ? createTicketTenant
                : userData !== undefined && userData.data.organizations[0]?.alias,
            attachment: attachments,
            assignees: assignees,
            board: board,
          },
        };
        createTicketService(ticketInfo);
      })
      .catch(() => {
        const ticketInfo = {
          data: {
            description: description,
            type: type,
            priority: priority,
            status: 'backlog',
            environment: environment,
            main_tenant:
              superAdmin || developer
                ? createTicketTenant
                : userData !== undefined && userData.data.organizations[0]?.alias,
            assignees: assignees,
            board: board,
          },
        };
        createTicketService(ticketInfo);
      });

    setDescription('');
    setPriority('high');
    setType('task');
    setEnvironment('Sandbox');
    setFiles('');
    setAssignees(null);
  };

  const createTicketService = async (ticketInfo: {
    data: {
      description: string;
      type: string;
      priority: string;
      status: string;
      main_tenant: string;
      assignees: string[] | null;
    };
  }) => {
    axios
      .post(`${process.env.REACT_APP_strapiUrl}/tickets`, ticketInfo, {
        headers: headers,
      })
      .then((response) => {
        // if (response.status === 200) {
        //     await catchedTrigger('create-ticket', {
        //         // to: {
        //         //   subscriberId: '63d7751916e379fe65f29506'
        //         // },
        //         to: relatedUsers,
        //         payload: {
        //             desc: response?.data?.data?.attributes?.description
        //         }
        //     })
        // }

        // const idInfo = {
        //     ticket_id: `${createTicketTenant?.slice(0, 3).toUpperCase()}-` + response.data.data.id
        // }

        const idInfo = {
          ticket_id: `${ticketId?.slice(0, 3).toUpperCase()}-` + response.data.data.id,
        };

        const message = {
          message: response.data.data.attributes.description,
          sender: userData !== undefined && userData.data.name,
          ticket: response.data.data.id,
          admin: superAdmin || developer,
          changed_to: 'Description',
        };
        putId(response.data.data.id, response.data.data.attributes.description, idInfo);
        firstMessage(message);
      })
      .catch(() => {
        // console.log(error);
        failed();
      });
  };

  const firstMessage = async (message: {
    message: string;
    sender: string;
    ticket: number;
    admin: boolean;
  }) => {
    await fetch(`${process.env.REACT_APP_strapiUrl}/chats`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: message }),
    });
    // const addMessage = await add.json()
    // console.log(addMessage);
  };

  const putId = async (id: number, description: string, idInfo: object) => {
    axios
      .put(
        `${process.env.REACT_APP_strapiUrl}/tickets/${id}`,
        { data: idInfo },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then(async (response) => {
        if (response.status === 200) {
          await catchedTrigger('create-ticket', {
            // to: {
            //   subscriberId: '63d7751916e379fe65f29506'
            // },
            to: relatedUsers,
            payload: {
              ticket: response?.data?.data?.attributes?.ticket_id,
              user: userData?.data?.name,
              description: description,
            },
          });
        }
        recordLog(
          'Create',
          description,
          'ticket',
          response?.data?.data?.attributes?.ticket_id,
          currentUser?.id,
        );
      });
    // console.log(addResponse);
    toggleRefresh();
    setTenant(createTicketTenant);
    navigate('/tickets');
  };

  return (
    <>
      <Box display='flex' alignItems='center' gap='10px'>
        <ConfirmationNumberIcon />
        <Typography variant='h6' fontWeight='bold'>
          Create Ticket
        </Typography>

        {(superAdmin || developer) && (
          <Box>
            <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
              <InputLabel>Select</InputLabel>
              <Select
                label='Select'
                value={createTicketTenant}
                aria-label='alias-select'
                onChange={(event) => {
                  setCreateTicketTenant(event.target.value);
                }}
              >
                {developer
                  ? userData?.data?.organizations
                      ?.filter((tenant: any) => tenant?.sts_link === 1)
                      ?.map((tenant: any) => {
                        return (
                          <MenuItem
                            aria-label='tenant-alias'
                            value={tenant?.alias}
                            key={tenant?.alias}
                          >
                            {tenant?.ticket_id?.toUpperCase()}
                          </MenuItem>
                        );
                      })
                  : allTenants.data.tenants
                      .filter((tenant: any) => tenant.organization.sts_link === 1)
                      .map(
                        (tenant: {
                          organization: { alias: string; ticket_id: string };
                          id: string;
                        }) => {
                          return (
                            <MenuItem
                              aria-label='organization-alias'
                              value={tenant.organization.alias}
                              key={tenant.id}
                            >
                              {tenant.organization.ticket_id.toUpperCase()}
                            </MenuItem>
                          );
                        },
                      )}
              </Select>
            </FormControl>
          </Box>
        )}
      </Box>

      <Box display='flex' flexDirection='column' gap='10px'>
        <TextField
          id='outlined-multiline-static'
          label='Description*'
          aria-label='description'
          multiline
          fullWidth
          rows={7}
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        />

        <FormControl fullWidth size='small'>
          <InputLabel id='demo-simple-select-label'>Type*</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            label='Type'
            aria-label='type-select'
            value={type}
            onChange={handleTypeChange}
          >
            <MenuItem aria-label='bug' value='bug'>
              Bug
            </MenuItem>
            <MenuItem aria-label='task' value='task'>
              Task
            </MenuItem>
            <MenuItem aria-label='feature' value='feature'>
              Feature
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth size='small'>
          <InputLabel id='demo-simple-select-label'>Priority*</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            label='Priority'
            aria-label='priority-select'
            value={priority}
            onChange={handlePriorityChange}
          >
            <MenuItem aria-label='highest' value='highest'>
              Highest
            </MenuItem>
            <MenuItem aria-label='high' value='high'>
              High
            </MenuItem>
            <MenuItem aria-label='medium' value='medium'>
              Medium
            </MenuItem>
            <MenuItem aria-label='low' value='low'>
              Low
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth size='small'>
          <InputLabel id='demo-simple-select-label'>Environment*</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            label='Environment'
            aria-label='environment-select'
            value={environment}
            onChange={handleEnvironmentChange}
          >
            <MenuItem aria-label='sandbox' value='Sandbox'>
              Sandbox
            </MenuItem>
            <MenuItem aria-label='uat' value='UAT'>
              UAT
            </MenuItem>
            <MenuItem aria-label='production' value='Production'>
              Production
            </MenuItem>
          </Select>
        </FormControl>

        <AssignDeveloper
          idToChange=''
          assignees={assignees}
          setAssignees={setAssignees}
          main_tenant={createTicketTenant !== undefined ? createTicketTenant : ''}
          newTicket={true}
        />

        <input
          aria-label='select-file'
          type='file'
          onChange={(e) => setFiles(e.target.files)}
          multiple
          // accept='image/*'
        />

        {/* <AttachButton
                    onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                    sx={{ display: 'flex', justifyContent: 'space-between', pl: '13px', pr: '7px', width: '50%' }}
                >
                    <Typography>Attach images</Typography>
                    <AttachmentIcon />
                </AttachButton> */}

        <DoneButton text='Create' onClick={createTicket} mx='auto' mt='5px' />
      </Box>
    </>
  );
};

export default MobileCreateTicketForm;
