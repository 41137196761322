import React, { useState } from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuOption } from '../../../store/constants/constants';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ForwardIcon from '@mui/icons-material/Forward';
import PushPinIcon from '@mui/icons-material/PushPin';
import ReplyIcon from '@mui/icons-material/Reply';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { SquareIconButton } from '../../../utils/exports/Styles';

interface OptionsMenuProps {
  options: MenuOption[];
  onOptionSelect: (option: MenuOption) => void;
}

const getIconForAction = (action: string) => {
  switch (action) {
    case 'copy':
      return <ContentCopyIcon fontSize='small' />;
    case 'delete':
      return <DeleteIcon fontSize='small' />;
    case 'edit':
      return <EditIcon fontSize='small' />;
    case 'forward':
      return <ForwardIcon fontSize='small' />;
    case 'pin':
    case 'unpin':
      return <PushPinIcon fontSize='small' />;
    case 'bookmark':
    case 'removeBookmark':
      return <BookmarkIcon fontSize='small' />;
    case 'reply':
      return <ReplyIcon fontSize='small' />;
    default:
      return null;
  }
};

const OptionsMenu: React.FC<OptionsMenuProps> = ({ options, onOptionSelect }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option: MenuOption) => {
    onOptionSelect(option);
    handleClose();
  };

  return (
    <>
      <SquareIconButton
        size='small'
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
        sx={{ p: '1px' }}
      >
        <MoreVertIcon fontSize='small' />
      </SquareIconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '21ch',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.action} onClick={() => handleOptionClick(option)}>
            <ListItemIcon>{getIconForAction(option.action)}</ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default OptionsMenu;
