import { Button } from '@mui/material';

const DoneButton = (props: {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  width?: string;
  mx?: string;
  mt?: string;
  mb?: string;
  height?: string;
}) => {
  return (
    <>
      <Button
        onClick={() => props.onClick()}
        disabled={props.disabled || false}
        variant='contained'
        aria-label='done'
        color='info'
        size='small'
        sx={{
          width: props.width || '90px',
          mx: props.mx || '0px',
          mt: props.mt || '0px',
          mb: props.mb || '0px',
          textTransform: 'none',
          height: props.height || 'inherit',
        }}
      >
        {props.text}
      </Button>
    </>
  );
};

export default DoneButton;
