import axios from 'axios';
import { failed } from '../../hooks/useToast';
import { Dispatch, SetStateAction } from 'react';
import { CicdDataType } from '../exports/Interface';

const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

export const getLog = (date: string, selectedUser: number | string, setLog: any) => {
  axios
    .get(
      `${process.env.REACT_APP_strapiUrl}/logs?filters[date][$eq]=${date}&filters[type][$eq]=ticket&filters[attendance_user][id][$eq]=${selectedUser}&pagination[pageSize]=999&populate[attendance_user][fields][0]=name`,
      {
        headers: headers,
      },
    )
    .then((res) => setLog(res?.data?.data))
    .catch(() => failed());
};

export const getCicdData = (
  tenant: string,
  setCicdData: Dispatch<SetStateAction<CicdDataType[]>>,
) => {
  axios
    .get(`${process.env.REACT_APP_strapiUrl}/cicds?filters[tenant][$eq]=${tenant}&populate=*`, {
      headers: headers,
    })
    .then((res) => setCicdData(res?.data?.data))
    .catch(() => failed());
};

export const getBoards = (
  tenant: string | undefined,
  setBoards: any,
  userId: number,
  superAdmin: boolean,
) => {
  axios
    .get(
      `${process.env.REACT_APP_strapiUrl}/boards?filters[main_tenant][$eq]=${tenant}${
        !superAdmin ? `&filters[users][$contains]=${userId}` : ''
      }&populate[tenant_files]=*&populate[project]=*`,
      {
        headers: headers,
      },
    )
    .then((res) => setBoards(res.data.data))
    .catch(() => failed());
};

export const getProject = (
  tenant: string | undefined,
  setProjects: any,
) => {
  axios
    .get(
      `${process.env.REACT_APP_strapiUrl}/projects?filters[tenant][$eq]=${tenant}&populate=*`,
      {
        headers: headers,
      },
    )
    .then((res) => setProjects(res.data.data))
    .catch(() => failed());
};
