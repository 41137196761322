import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';
import { IAttachmentData } from '../utils/exports/Interface';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { failed } from '../hooks/useToast';
import { LoadingContext } from '../App';
import { Dispatch, SetStateAction, useContext, useState } from 'react';

const AttachmentTable = (props: {
  attachmentData: IAttachmentData[];
  setAttachmentData: Dispatch<SetStateAction<IAttachmentData[]>>;
}) => {
  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };
  const { setLoading } = useContext(LoadingContext);

  const [open, setOpen] = useState(false);
  const [id, setID] = useState<number>(0);
  const [userFileID, setUserFileID] = useState<number>(0);

  const handleClickOpen = (id: number, userFileID: number) => {
    setOpen(true);
    setID(id);
    setUserFileID(userFileID);
  };

  const handleClose = () => {
    setOpen(false);
    setID(0);
    setUserFileID(0);
  };

  const handleView = (url: string) => {
    window.open(`${process.env.REACT_APP_strapiImage}${url}`, '_blank', 'noreferrer');
  };

  const handleDeleteFile = (id: number, userFileID: number) => {
    setLoading(true);
    axios
      .delete(`${process.env.REACT_APP_strapiUrl}/upload/files/${id}`, {
        headers: headers,
      })
      .then(() => {
        const updatedAttachments = props.attachmentData
          .filter(
            (attachment: IAttachmentData) =>
              attachment.userFileID === userFileID && attachment.id !== id,
          )
          .map((attachment: IAttachmentData) => attachment.id);

        if (updatedAttachments.length === 0) {
          axios
            .delete(`${process.env.REACT_APP_strapiUrl}/user-files/${userFileID}`, {
              headers: headers,
            })
            .then(() => {
              const updatedAttachments = props.attachmentData.filter(
                (attachment: IAttachmentData) => attachment.id !== id,
              );
              props.setAttachmentData(updatedAttachments);
              setLoading(false);
              setOpen(false);
            })
            .catch(() => {
              failed();
              setLoading(false);
              setOpen(false);
            });
        } else {
          const fileInfo = {
            data: {
              file: updatedAttachments,
            },
          };
          axios
            .put(
              `${process.env.REACT_APP_strapiUrl}/user-files/${userFileID}

`,
              fileInfo,
              {
                params: { populate: '*' },
                headers: headers,
              },
            )
            .then(() => {
              const updatedAttachments = props.attachmentData.filter(
                (attachment: IAttachmentData) => attachment.id !== id,
              );
              props.setAttachmentData(updatedAttachments);
              setLoading(false);
              setOpen(false);
            })
            .catch(() => {
              failed();
              setLoading(false);
              setOpen(false);
            });
        }
      })
      .catch(() => {
        failed();
        setLoading(false);
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'sn',
      renderHeader: () => {
        return <Typography fontWeight='bold'>SN</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: 'fileName',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Files</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 4.5,
      sortable: false,
      editable: false,
      renderCell: (params) => params.value,
    },
    {
      field: 'uploadedBy',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Created By</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      sortable: false,
      editable: false,
      renderCell: (params) => params.value,
    },
    {
      field: 'createdAt',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Created At</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      sortable: false,
      editable: false,
      renderCell: (params) => params.value,
    },
    {
      field: 'fileUrl',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      editable: false,
      valueGetter: (params) => ({
        fileUrl: params.row.fileUrl,
        userFileID: params.row.userFileID,
        id: params.row.id,
      }),
      renderCell: (params) => {
        const { id, fileUrl, userFileID } = params.value;
        return (
          <Box display='flex' gap='8px'>
            <IconButton
              aria-label='attachment-view-button'
              onClick={() => handleView(fileUrl)}
              sx={{ padding: '0px' }}
            >
              <VisibilityIcon sx={{ width: '0.8em' }} />
            </IconButton>
            <IconButton
              aria-label='attachment-delete-button'
              onClick={() => handleClickOpen(id, userFileID)}
              sx={{ padding: '0px' }}
            >
              <DeleteIcon sx={{ width: '0.8em' }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box sx={{ bgcolor: 'white' }}>
        <DataGrid
          rows={props.attachmentData}
          columns={columns}
          rowHeight={40}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[25, 50, 75, 100]}
          disableColumnMenu
          sx={{
            border: 'none',
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              width: '0.4em',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders':
              {
                borderColor: 'darkgray',
                borderWidth: '1px',
              },
          }}
          getRowId={(row) => row.id}
          disableRowSelectionOnClick
          // onPaginationModelChange={(row) => {
          //   setPage(row.page);
          //   setPageSize(row.pageSize);
          // }}
        />
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth='sm'>
        <Box display='flex' flexDirection='column' gap='10px' p='15px' alignItems='center'>
          <Box display='flex' gap='3px' pr='2px'>
            <DeleteIcon />
            <Typography>Are you sure?</Typography>
          </Box>
          <Box display='flex' gap='10px'>
            <Button
              size='small'
              variant='contained'
              aria-label='delete-ticket'
              color='error'
              onClick={() => handleDeleteFile(id, userFileID)}
            >
              Yes
            </Button>
            <Button
              size='small'
              variant='outlined'
              aria-label='no-delete-ticket'
              onClick={handleClose}
            >
              No
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default AttachmentTable;
