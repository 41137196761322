import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import {
  Edit as EditIcon,
  Bookmark as BookmarkIcon,
} from "@mui/icons-material";

interface ChapterHeaderProps {
  name: string;
  duration: number; 
  onEdit: () => void;
  onBookmark: () => void;
}

export const ChapterHeader: React.FC<ChapterHeaderProps> = ({
  name,
  duration,
  onEdit,
  onBookmark,
}) => (
  <Box sx={{ display: "flex", alignItems: "center", width: "100%", pr: 16 }}>
    <Box sx={{ flexGrow: 1 }}>
      <Typography>{name}</Typography>
      <Typography variant="body2" color="text.secondary">
        {duration}
      </Typography>
    </Box>
    <Box
      sx={{
        position: "absolute",
        right: 48,
        top: "50%",
        transform: "translateY(-50%)",
        display: "flex",
        gap: 1,
        zIndex: 1,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <IconButton size="small" onClick={onEdit}>
      <EditIcon sx= {{height: '16px', width: '16px'}}/>
      </IconButton>
      <IconButton size="small" onClick={onBookmark}>
        <BookmarkIcon sx= {{height: '18px', width: '18px'}}/>
      </IconButton>
    </Box>
  </Box>
);
