import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Box, Dialog, DialogContent, InputAdornment, TextField, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { LoadingContext } from '../../App';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import { failed, success } from '../../hooks/useToast';
import { UserDataContext } from '../../routes/Main';
import isMobile from '../../hooks/isMobile';
import isDeveloper from '../../hooks/isDeveloper';
import Pills from '../Pills';

const EditMeetingBoard = (props: {
  commentOpen: boolean;
  setCommentOpen: Dispatch<SetStateAction<boolean>>;
  type: string;
  ticketId: number;
  idToDrop: number;
  handleDrop: (idToDrop: number) => void;
}) => {
  const mobile = isMobile(); 
  const superAdmin = isSuperAdmin();
  const developer = isDeveloper();
  const { userData } = useContext(UserDataContext);
  const { setLoading } = useContext(LoadingContext);
  const [comment, setComment] = useState('');
  const handleCommentClose = () => {
    props.setCommentOpen(false);
    setComment('');
  };

  const handleChange = async () => {
    setLoading(true);
    const messageInfo = {
      message: comment,
      sender: userData !== undefined && userData.data.name,
      ticket: props.ticketId,
      admin: (superAdmin || developer) && true,
      changed_to: props.type,
    };

    await fetch(`${process.env.REACT_APP_strapiUrl}/chats`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: messageInfo }),
    })
      .then(async (response) => {
        if (response?.status === 200 || response?.status === 201) {
          success();
          props.handleDrop(props.idToDrop);
          props.setCommentOpen(false);
        }else {
            failed();
        }
      })
      .catch(() => {
        failed();
      });
    setLoading(false);
    setComment('');
  };

  return (
    <>
      <Dialog open={props?.commentOpen} onClose={handleCommentClose} fullWidth>
        <DialogContent>
          <Box display='flex' flexDirection='column' gap='15px'>
            <Box display='flex' justifyContent='space-between'>
              <Box display='flex' gap='10px'>
                <Typography fontWeight='bold'>Comment:</Typography>
                <Pills pill={props.type} />
              </Box>
              <CloseIcon onClick={handleCommentClose} cursor='pointer' />
            </Box>
            <TextField
              multiline
              fullWidth
              rows={4}
              aria-label='comment'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={{ mt: '-68px' }}>
                    <SendIcon onClick={handleChange} sx={{ cursor: 'pointer' }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setComment(e.target.value)}
              onKeyDown={(e) => {
                if (!mobile) {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    handleChange();
                    e.preventDefault();
                  }
                }
              }}
              value={comment}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditMeetingBoard;
