import { useContext, useEffect, useState } from 'react';
import { AttendanceBlock } from '../../layout/Desktop/DesktopAttendanceLayout';
import Switch from '@mui/material/Switch';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import axios from 'axios';
// import Info from './Info';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import CloseIcon from '@mui/icons-material/Close';
import { failed, success } from '../../hooks/useToast';
import AttendanceReport from './AttendanceReport';
import { CurrentUserContext, TenantContext } from '../../App';
import { recordLog } from '../../services/recordLog';
import { CombinedReportContext, UserDataContext, UserListContext } from '../../routes/Main';
import Pills from '../Pills';
import ErrorIcon from '@mui/icons-material/Error';
import isMobile from '../../hooks/isMobile';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { catchedTrigger } from '../../utils/novu';
import {
  formattedDate,
  formattedTime,
  getCurrentDate,
  getCurrentTime,
  isToday,
  isTomorrow,
  isYesterday,
  removeLeadingZerosRegex,
} from '../../utils/exports/Function';
import { IAttendanceDataType, ITicketData, IUserData } from '../../utils/exports/Interface';

const CheckInOut = () => {
  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

  const mobile = isMobile();

  const { userData } = useContext(UserDataContext);

  const { userList } = useContext(UserListContext);

  const { currentUser } = useContext(CurrentUserContext);

  const { combinedReport } = useContext(CombinedReportContext);

  const [checkInsToday, setCheckInsToday] = useState<any>();

  const [checkInId, setCheckInId] = useState(
    checkInsToday?.data?.filter(
      (data: any) =>
        data?.attributes?.attendance_user?.data?.attributes?.sis_id ===
        currentUser?.attributes?.sis_id,
    )[0]?.id,
  );

  const [checkIn, setCheckIn] = useState(
    checkInsToday?.data?.filter(
      (data: any) =>
        data?.attributes?.attendance_user?.data?.attributes?.sis_id ===
        currentUser?.attributes?.sis_id,
    )[0]?.attributes?.in_time,
  );
  const [checkOut, setCheckOut] = useState(
    checkInsToday?.data?.filter(
      (data: any) =>
        data?.attributes?.attendance_user?.data?.attributes?.sis_id ===
        currentUser?.attributes?.sis_id,
    )[0]?.attributes?.out_time,
  );
  const [checkedIn, setCheckedIn] = useState(checkIn ? true : false);

  const [lunchOut, setLunchOut] = useState(
    checkInsToday?.data?.filter(
      (data: any) =>
        data?.attributes?.attendance_user?.data?.attributes?.sis_id ===
        currentUser?.attributes?.sis_id,
    )[0]?.attributes?.lunch_out,
  );
  const [lunchIn, setLunchIn] = useState(
    checkInsToday?.data?.filter(
      (data: any) =>
        data?.attributes?.attendance_user?.data?.attributes?.sis_id ===
        currentUser?.attributes?.sis_id,
    )[0]?.attributes?.lunch_in,
  );
  const [lunch, setLunch] = useState(lunchOut ? true : false);

  const [alert, setAlert] = useState(false);

  const { tenant } = useContext(TenantContext);

  const getCheckInsToday = () => {
    axios
      .get(
        `${process.env.REACT_APP_strapiUrl}/check-ins?pagination[pageSize]=99${
          tenant !== 'all' ? `&filters[attendance_user][tenant][$eq]=${tenant}` : ''
        }&sort[0]=createdAt:desc&filters[date][$eq]=${getCurrentDate()}&populate=*`,
        {
          headers: headers,
        },
      )
      .then((res) => setCheckInsToday(res?.data))
      .catch(() => failed());
  };

  const [last5Days, setLast5Days] = useState<IAttendanceDataType[]>();

  const getLast5Days = () => {
    axios
      .get(
        `${process.env.REACT_APP_strapiUrl}/check-ins?pagination[pageSize]=5&filters[attendance_user][id][$eq]=${currentUser?.id}&populate=*&sort[0]=date%3Adesc&sort[1]=createdAt%3Adesc`,
        {
          headers: headers,
        },
      )
      .then((res) => setLast5Days(res?.data?.data))
      .catch(() => failed());
  };

  const [multiCheckIns, setMultiCheckIns] = useState<boolean>(false);

  useEffect(() => {
    getLast5Days();
    getCheckInsToday();
    setMultiCheckIns(currentUser?.attributes?.multi_check_ins);
  }, [currentUser, tenant]);

  useEffect(() => {
    setCheckInId(
      checkInsToday?.data?.filter(
        (data: any) =>
          data?.attributes?.attendance_user?.data?.attributes?.sis_id ===
          currentUser?.attributes?.sis_id,
      )[0]?.id,
    );

    setCheckIn(
      checkInsToday?.data?.filter(
        (data: any) =>
          data?.attributes?.attendance_user?.data?.attributes?.sis_id ===
          currentUser?.attributes?.sis_id,
      )[0]?.attributes?.in_time,
    );

    setCheckOut(
      checkInsToday?.data?.filter(
        (data: any) =>
          data?.attributes?.attendance_user?.data?.attributes?.sis_id ===
          currentUser?.attributes?.sis_id,
      )[0]?.attributes?.out_time,
    );
  }, [checkInsToday, currentUser, checkedIn]);

  useEffect(() => {
    setCheckedIn(checkIn && !checkOut ? true : false);
  }, [checkIn, checkOut]);

  useEffect(() => {
    setLunchOut(
      checkInsToday?.data?.filter(
        (data: any) =>
          data?.attributes?.attendance_user?.data?.attributes?.sis_id ===
          currentUser?.attributes?.sis_id,
      )[0]?.attributes?.lunch_out,
    );

    setLunchIn(
      checkInsToday?.data?.filter(
        (data: any) =>
          data?.attributes?.attendance_user?.data?.attributes?.sis_id ===
          currentUser?.attributes?.sis_id,
      )[0]?.attributes?.lunch_in,
    );
  }, [checkInsToday, currentUser, lunch]);

  useEffect(() => {
    setLunch(lunchOut && !lunchIn ? true : false);
  }, [lunchOut, lunchIn]);

  const [wipAlert, setWipAlert] = useState(false);

  const wipCheck = () => {
    axios
      .get(
        `${process.env.REACT_APP_strapiUrl}/tickets?pagination[pageSize]=99&filters[status][$eq]=in progress`,
        {
          headers: headers,
        },
      )
      .then((res) => {
        if (
          res?.data?.data?.filter((ticket: ITicketData) =>
            ticket?.attributes?.assignees?.includes(userData?.data?.name),
          )?.length > 0
        ) {
          setCheckInConfirmatrion(true);
        } else {
          setWipAlert(true);
        }
      })
      .catch(() => failed());
  };

  const [dueDateAlert, setDueDateAlert] = useState(false);

  const dueDateCheck = () => {
    axios
      .get(
        `${process.env.REACT_APP_strapiUrl}/tickets?pagination[pageSize]=99&filters[status][$eq]=in progress`,
        {
          headers: headers,
        },
      )
      .then((res) => {
        if (
          res?.data?.data?.filter(
            (ticket: ITicketData) =>
              ticket?.attributes?.assignees?.includes(userData?.data?.name) &&
              ticket?.attributes?.due_date === getCurrentDate(),
          )?.length === 0
        ) {
          setCheckInConfirmatrion(true);
        } else {
          setDueDateAlert(true);
        }
      })
      .catch(() => failed());
  };

  const [currentBsDate, setCurrentBsDate] = useState<string>();

  const getBsDate = (adDate: string) => {
    const year = adDate.split('-')[0];
    const month = adDate.split('-')[1];

    axios
      .get(
        `${process.env.REACT_APP_calendarApiUrl}/calendar/ad/${year}/${removeLeadingZerosRegex(
          month,
        )}/`,
      )
      .then((res) => {
        setCurrentBsDate(
          res.data.filter(
            (date: { adDate: string }) =>
              date.adDate === `${year}-${removeLeadingZerosRegex(month)}-${new Date().getDate()}`,
          )[0]?.bsDate,
        );
      })
      .catch(() => failed());
  };

  useEffect(() => {
    getBsDate(getCurrentDate());
  }, []);

  const [relatedUsers, setRelatedUsers] = useState<any>();

  useEffect(() => {
    const users = userList?.data?.users?.users?.filter(
      (user: IUserData) => user.roles[0].name === 'sa',
    );
    const notifUsers = users?.map((user: IUserData) => {
      return {
        subscriberId: JSON.stringify(user.id),
      };
    });
    setRelatedUsers(notifUsers);
  }, [userList]);

  const handleCheckIn = () => {
    const data = {
      data: {
        attendance_user: JSON.parse(currentUser?.id),
        date: getCurrentDate(),
        bs_date: currentBsDate,
        in_time: getCurrentTime(),
        in_by:
          currentUser?.attributes?.sis_id !== JSON.stringify(userData?.data?.id)
            ? userData?.data?.name
            : null,
      },
    };

    axios
      .post(`${process.env.REACT_APP_strapiUrl}/check-ins`, data, {
        headers: headers,
      })
      .then(async (response) => {
        if (response.status === 200) {
          await catchedTrigger('check-in', {
            to: relatedUsers,
            payload: {
              user: currentUser?.attributes?.name,
              time: formattedTime(response?.data?.data?.attributes?.in_time),
            },
          });
        }
        recordLog(`Attendance`, 'Checked In', 'attendance', '-', currentUser?.id, '-');
        success();
        getCheckInsToday();
        getLast5Days();
      })
      .catch(() => failed());
  };

  const extractTime = (start: string | undefined | null, end: string | undefined | null) => {
    const startTime = start;
    const endTime = end;

    const startTimeObj: any = new Date(`2000-01-01T${startTime}Z`);
    const endTimeObj: any = new Date(`2000-01-01T${endTime}Z`);

    const timeDiff = endTimeObj - startTimeObj;
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));

    return hours;
  };

  const handleCheckOut = () => {
    const data = {
      data: {
        out_time: getCurrentTime(),
        out_by:
          currentUser?.attributes?.sis_id !== JSON.stringify(userData?.data?.id)
            ? userData?.data?.name
            : null,
      },
    };

    axios
      .put(`${process.env.REACT_APP_strapiUrl}/check-ins/${checkInId}`, data, {
        headers: headers,
      })
      .then(async (res) => {
        if (res.status === 200) {
          await catchedTrigger('check-out', {
            to: relatedUsers,
            payload: {
              user: currentUser?.attributes?.name,
              time: formattedTime(res?.data?.data?.attributes?.out_time),
            },
          });
        }
        const data = {
          data: {
            achieved_hours:
              currentUser?.attributes?.achieved_hours +
              extractTime(res.data.data.attributes.in_time, res.data.data.attributes.out_time),
          },
        };

        axios
          .put(`${process.env.REACT_APP_strapiUrl}/attendance-users/${currentUser?.id}`, data, {
            headers: headers,
          })
          .then(() => {
            recordLog(`Attendance`, 'Checked Out', 'attendance', '-', currentUser?.id, '-');
            success();
            getCheckInsToday();
            getLast5Days();
          })
          .catch(() => {
            failed;
          });
      })
      .catch(() => failed());
  };

  const handleLunchOut = () => {
    const data = {
      data: {
        lunch_out: getCurrentTime(),
        lunch_out_by:
          currentUser?.attributes?.sis_id !== JSON.stringify(userData?.data?.id)
            ? userData?.data?.name
            : null,
      },
    };

    axios
      .put(`${process.env.REACT_APP_strapiUrl}/check-ins/${checkInId}`, data, {
        headers: headers,
      })
      .then(() => {
        recordLog(`Attendance`, 'Out For Lunch', 'attendance', '-', currentUser?.id, '-');
        success();
        getCheckInsToday();
        getLast5Days();
      })
      .catch(() => failed());
  };

  const handleLunchIn = () => {
    const data = {
      data: {
        lunch_in: getCurrentTime(),
        lunch_in_by:
          currentUser?.attributes?.sis_id !== JSON.stringify(userData?.data?.id)
            ? userData?.data?.name
            : null,
      },
    };

    axios
      .put(`${process.env.REACT_APP_strapiUrl}/check-ins/${checkInId}`, data, {
        headers: headers,
      })
      .then(() => {
        recordLog(`Attendance`, 'Back From Lunch', 'attendance', '-', currentUser?.id, '-');
        success();
        getCheckInsToday();
        getLast5Days();
      })
      .catch(() => failed());
  };

  const [reportOpen, setReportOpen] = useState(false);

  const [checkInConfirmation, setCheckInConfirmatrion] = useState(false);
  const [lunchConfirmation, setLunchConfirmatrion] = useState(false);

  return (
    <>
      <AttendanceBlock sx={{ border: 'none', p: '0px', cursor: 'default' }}>
        <Box display='flex' flexDirection='column' gap='10px' height='100%' width='100%'>
          <AttendanceBlock sx={{ height: '50%', bgcolor: '#fff8f5', border: '1px solid #fbe9e1' }}>
            {/* <Info section='Attendance' /> */}
            {combinedReport ? (
              <Box display='flex' alignItems='center' width='100%' justifyContent='space-between'>
                <Box
                  display='flex'
                  gap='5px'
                  ml='-3px'
                  alignItems='center'
                  color='#d8551c'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setReportOpen(true)}
                >
                  <Box
                    p='3px'
                    bgcolor='#d8551c'
                    display='flex'
                    alignItems='center'
                    borderRadius='5px'
                  >
                    <DomainVerificationIcon
                      sx={{ width: '26px', height: '26px', color: 'white' }}
                    />
                  </Box>
                  <b>Attendance</b>
                </Box>

                <DragHandleIcon sx={{ cursor: 'pointer' }} onClick={() => setReportOpen(true)} />
              </Box>
            ) : (
              <Box display='flex' alignItems='center' width='100%' justifyContent='space-between'>
                <Box
                  display='flex'
                  gap='5px'
                  ml='-3px'
                  alignItems='center'
                  color='#d8551c'
                  onClick={() => {
                    if (checkedIn) {
                      if (userData?.data?.roles[0]?.name === 'dev') {
                        dueDateCheck();
                      } else {
                        setCheckInConfirmatrion(true);
                      }
                    } else {
                      if (userData?.data?.roles[0]?.name === 'dev') {
                        wipCheck();
                      } else {
                        setCheckInConfirmatrion(true);
                      }
                    }
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <Box
                    p='3px'
                    bgcolor='#d8551c'
                    display='flex'
                    alignItems='center'
                    borderRadius='5px'
                  >
                    <DomainVerificationIcon
                      sx={{ width: '26px', height: '26px', color: 'white' }}
                    />
                  </Box>
                  <b>Attendance</b>
                </Box>

                <Switch
                  checked={checkedIn}
                  color='success'
                  size='small'
                  aria-label='checkedin-switch'
                  onClick={() => {
                    if (checkedIn) {
                      if (userData?.data?.roles[0]?.name === 'dev') {
                        dueDateCheck();
                      } else {
                        setCheckInConfirmatrion(true);
                      }
                    } else {
                      if (userData?.data?.roles[0]?.name === 'dev') {
                        wipCheck();
                      } else {
                        setCheckInConfirmatrion(true);
                      }
                    }
                  }}
                />
              </Box>
            )}
            {combinedReport ? (
              <Box
                display='flex'
                maxHeight={mobile ? '250px' : 'none'}
                flexDirection='column'
                mt='10px'
                width='calc(100% + 30px)'
                ml='-15px'
                sx={{ overflowY: 'scroll', overflowX: 'hidden' }}
              >
                {checkInsToday?.data?.map(
                  (
                    checkIn: {
                      attributes: { attendance_user: any; in_time: string; out_time: string };
                    },
                    index: number,
                  ) => {
                    const isEven = index % 2 === 0;
                    return (
                      <Box
                        key={index}
                        display='flex'
                        justifyContent='space-between'
                        p='5px 15px'
                        bgcolor={isEven ? 'white' : 'inherit'}
                      >
                        <Typography fontSize='15px'>
                          {
                            checkIn?.attributes?.attendance_user?.data?.attributes?.name?.split(
                              ' ',
                            )[0]
                          }
                        </Typography>
                        <Typography alignSelf='end' fontSize='15px'>
                          {formattedTime(checkIn?.attributes?.in_time)} -{' '}
                          {checkIn?.attributes?.out_time
                            ? formattedTime(checkIn?.attributes?.out_time)
                            : 'N/A'}
                        </Typography>
                      </Box>
                    );
                  },
                )}
              </Box>
            ) : (
              <Box display='flex' width='100%' mt='10px' flexDirection='column'>
                {/* <Box display='flex' justifyContent='space-between'>
                                    <Box display='flex' flexDirection='column' gap='5px'>
                                        <Typography fontSize='15px'>Check-in</Typography>
                                        <Typography fontSize='15px'>Check-out</Typography>
                                    </Box>

                                    <Box display='flex' flexDirection='column' gap='5px'>
                                        <Typography fontSize='15px' alignSelf='end'>{checkIn ? formattedTime(checkIn) : 'N/A'}</Typography>
                                        <Typography fontSize='15px' alignSelf='end'>{checkOut ? formattedTime(checkOut) : 'N/A'}</Typography>
                                    </Box>
                                </Box> */}

                {last5Days?.map((day, index) => {
                  const isEven = index % 2 === 0;
                  return (
                    <Box
                      key={index}
                      display='flex'
                      justifyContent='space-between'
                      p='5px 15px'
                      mx='-15px'
                      bgcolor={isEven ? 'white' : 'inherit'}
                    >
                      <Typography fontSize='15px'>
                        {isToday(day?.attributes?.date)
                          ? 'Tday'
                          : isYesterday(day?.attributes?.date)
                          ? 'Yday'
                          : isTomorrow(day?.attributes?.date)
                          ? 'Tmrw'
                          : formattedDate(day?.attributes?.date)}
                      </Typography>

                      <Typography fontSize='15px' alignSelf='end'>
                        {day?.attributes?.in_time ? formattedTime(day?.attributes?.in_time) : 'N/A'}{' '}
                        -{' '}
                        {day?.attributes?.out_time
                          ? formattedTime(day?.attributes?.out_time)
                          : 'N/A'}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            )}
          </AttendanceBlock>

          <AttendanceBlock sx={{ height: '50%', bgcolor: '#fff8f5', border: '1px solid #fbe9e1' }}>
            {/* <Info section='Lunch' /> */}
            {combinedReport ? (
              <Box display='flex' alignItems='center' width='100%' justifyContent='space-between'>
                <Box
                  display='flex'
                  gap='5px'
                  ml='-3px'
                  alignItems='center'
                  color='#d8551c'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setReportOpen(true)}
                >
                  <Box
                    p='5px'
                    bgcolor='#d8551c'
                    display='flex'
                    alignItems='center'
                    borderRadius='5px'
                  >
                    <RestaurantIcon sx={{ width: '22px', height: '22px', color: 'white' }} />
                  </Box>
                  <b>Lunch</b>
                </Box>

                <DragHandleIcon sx={{ cursor: 'pointer' }} onClick={() => setReportOpen(true)} />
              </Box>
            ) : (
              <Box display='flex' alignItems='center' width='100%' justifyContent='space-between'>
                <Box
                  display='flex'
                  gap='5px'
                  ml='-3px'
                  alignItems='center'
                  color='#d8551c'
                  onClick={() => {
                    setLunchConfirmatrion(true);
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <Box
                    p='5px'
                    bgcolor='#d8551c'
                    display='flex'
                    alignItems='center'
                    borderRadius='5px'
                  >
                    <RestaurantIcon sx={{ width: '22px', height: '22px', color: 'white' }} />
                  </Box>
                  <b>Lunch</b>
                </Box>

                <Switch
                  checked={lunch}
                  color='success'
                  size='small'
                  aria-label='lunch-switch'
                  onClick={() => {
                    setLunchConfirmatrion(true);
                  }}
                />
              </Box>
            )}
            {combinedReport ? (
              <Box
                display='flex'
                maxHeight={mobile ? '250px' : 'none'}
                flexDirection='column'
                mt='10px'
                width='calc(100% + 30px)'
                ml='-15px'
                sx={{ overflowY: 'scroll', overflowX: 'hidden' }}
              >
                {checkInsToday?.data
                  ?.filter(
                    (checkIn: {
                      attributes: { attendance_user: any; lunch_out: string; lunch_in: string };
                    }) => checkIn?.attributes?.lunch_out !== null,
                  )
                  ?.map(
                    (
                      checkIn: {
                        attributes: { attendance_user: any; lunch_out: string; lunch_in: string };
                      },
                      index: number,
                    ) => {
                      const isEven = index % 2 === 0;
                      return (
                        <Box
                          key={index}
                          display='flex'
                          justifyContent='space-between'
                          py='5px'
                          px='15px'
                          bgcolor={isEven ? 'white' : 'inherit'}
                        >
                          <Typography fontSize='15px'>
                            {
                              checkIn?.attributes?.attendance_user?.data?.attributes?.name?.split(
                                ' ',
                              )[0]
                            }
                          </Typography>
                          <Typography alignSelf='end' fontSize='15px'>
                            {formattedTime(checkIn?.attributes?.lunch_out)} -{' '}
                            {checkIn?.attributes?.lunch_in
                              ? formattedTime(checkIn?.attributes?.lunch_in)
                              : 'N/A'}
                          </Typography>
                        </Box>
                      );
                    },
                  )}
              </Box>
            ) : (
              <Box display='flex' width='100%' mt='10px' flexDirection='column'>
                {/* <Box display='flex' justifyContent='space-between'>
                                    <Box display='flex' flexDirection='column' gap='5px'>
                                        <Typography fontSize='15px'>Check-out</Typography>
                                        <Typography fontSize='15px'>Check-in</Typography>
                                    </Box>

                                    <Box display='flex' flexDirection='column' gap='5px'>
                                        <Typography fontSize='15px' alignSelf='end'>{lunchOut ? formattedTime(lunchOut) : 'N/A'}</Typography>
                                        <Typography fontSize='15px' alignSelf='end'>{lunchIn ? formattedTime(lunchIn) : 'N/A'}</Typography>
                                    </Box>
                                </Box> */}

                {last5Days?.map((day, index) => {
                  const isEven = index % 2 === 0;
                  return (
                    <Box
                      key={index}
                      display='flex'
                      justifyContent='space-between'
                      py='5px'
                      mx='-15px'
                      px='15px'
                      bgcolor={isEven ? 'white' : 'inherit'}
                    >
                      <Typography fontSize='15px'>
                        {isToday(day?.attributes?.date)
                          ? 'Tday'
                          : isYesterday(day?.attributes?.date)
                          ? 'Yday'
                          : isTomorrow(day?.attributes?.date)
                          ? 'Tmrw'
                          : formattedDate(day?.attributes?.date)}
                      </Typography>

                      <Typography fontSize='15px' alignSelf='end'>
                        {day?.attributes?.lunch_out
                          ? formattedTime(day?.attributes?.lunch_out)
                          : 'N/A'}{' '}
                        -{' '}
                        {day?.attributes?.lunch_in
                          ? formattedTime(day?.attributes?.lunch_in)
                          : 'N/A'}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            )}
          </AttendanceBlock>
        </Box>
      </AttendanceBlock>

      <Dialog open={alert} onClose={() => setAlert(false)}>
        <DialogContent>
          <Typography display='flex' gap='5px'>
            <ErrorIcon /> Already checked in today!
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog open={wipAlert} onClose={() => setWipAlert(false)}>
        <DialogContent>
          <Typography display='flex' gap='5px'>
            <ErrorIcon /> Move a ticket to <Pills pill='in progress' /> before checking in.
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog open={dueDateAlert} onClose={() => setDueDateAlert(false)} maxWidth='lg'>
        <DialogContent>
          <Typography display='flex' gap='5px'>
            <ErrorIcon /> Ticket(s) in <Pills pill='in progress' /> is due today. Change the due
            date or the status before checking out.
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog open={reportOpen} onClose={() => setReportOpen(false)} fullWidth maxWidth='lg'>
        <DialogTitle
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          sx={{ pb: 0 }}
        >
          <Box display='flex' gap='10px' alignItems='center'>
            <DomainVerificationIcon sx={{ width: '25px', height: '25px' }} />
            <b>Attendance</b>
          </Box>
          <CloseIcon onClick={() => setReportOpen(false)} cursor='pointer' />
        </DialogTitle>
        <DialogContent>
          <AttendanceReport />
        </DialogContent>
      </Dialog>

      <Dialog open={checkInConfirmation} onClose={() => setCheckInConfirmatrion(false)}>
        <DialogContent>
          <Box display='flex' flexDirection='column' alignItems='center' gap='15px'>
            {checkedIn ? (
              <Typography>Are you sure you want to check out?</Typography>
            ) : (
              <Typography>Are you sure you want to check in?</Typography>
            )}
            <Box display='flex' gap='10px'>
              <Button
                size='small'
                variant='contained'
                aria-label='checkedin-button'
                color='info'
                onClick={() => {
                  setCheckInConfirmatrion(false);
                  checkedIn ? setCheckedIn(false) : setCheckedIn(true);
                  multiCheckIns
                    ? !checkedIn
                      ? handleCheckIn()
                      : handleCheckOut()
                    : !checkIn
                    ? handleCheckIn()
                    : checkIn && !checkOut
                    ? handleCheckOut()
                    : (setAlert(true), setCheckedIn(false));
                }}
              >
                Yes
              </Button>
              <Button
                size='small'
                aria-label='no-checkedin-button'
                variant='outlined'
                onClick={() => setCheckInConfirmatrion(false)}
              >
                No
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={lunchConfirmation} onClose={() => setLunchConfirmatrion(false)}>
        <DialogContent>
          <Box display='flex' flexDirection='column' alignItems='center' gap='15px'>
            {lunch ? (
              <Typography>Back from lunch?</Typography>
            ) : (
              <Typography>Out for lunch?</Typography>
            )}
            <Box display='flex' gap='10px'>
              <Button
                size='small'
                variant='contained'
                aria-label='lunch-confirmatrion'
                color='info'
                onClick={() => {
                  setLunchConfirmatrion(false);
                  lunch ? setLunch(false) : setLunch(true);
                  !lunchOut
                    ? handleLunchOut()
                    : lunchOut && !lunchIn
                    ? handleLunchIn()
                    : (setAlert(true), setLunch(false));
                }}
              >
                Yes
              </Button>
              <Button
                size='small'
                variant='outlined'
                aria-label='no-lunch-confirmatrion'
                onClick={() => setLunchConfirmatrion(false)}
              >
                No
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CheckInOut;
