import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const FilterStatus = () => {
  const [searchParams, setQueryParams] = useSearchParams();
  const [filterStatus, setFilterStatus] = useState('');

  useEffect(() => {
    setFilterStatus(searchParams.get('status') ?? '');
  }, [searchParams]);

  const sortStatus = (event: SelectChangeEvent) => {
    const queryParams = Object.fromEntries(searchParams);
    if (event.target.value === 'all') {
      delete queryParams.status;
    } else {
      queryParams.status = event.target.value as string;
    }
    setQueryParams(queryParams);
  };

  return (
    <>
      <FormControl size='small' variant='standard'>
        <InputLabel sx={{ fontWeight: 'bold', color: '#000000DE', fontSize: '12px' }}>
          Status
        </InputLabel>
        <Select
          disableUnderline
          size='small'
          label='Status'
          aria-label='status-select'
          value={filterStatus}
          onChange={sortStatus}
          sx={{
            fontSize: '12px',
            width: '75px',
            mb: '8px',
            '.MuiSelect-icon': {
              color: '#000000DE',
              mt: '-2px',
            },
          }}
        >
          <MenuItem aria-label='all' value='all'>All</MenuItem>
          <MenuItem aria-label='backlog' value='backlog'>BACKLOG</MenuItem>
          <MenuItem aria-label='onhold' value='on hold'>ON HOLD</MenuItem>
          <MenuItem aria-label='todo' value='todo'>TODO</MenuItem>
          <MenuItem aria-label='redo' value='redo'>REDO</MenuItem>
          <MenuItem aria-label='inprogress' value='in progress'>WIP</MenuItem>
          <MenuItem aria-label='pr' value='pr'>PR</MenuItem>
          <MenuItem aria-label='prdone' value='pr done' disabled>
            PR DONE
          </MenuItem>
          <MenuItem aria-label='canceled' value='canceled'>CANCELED</MenuItem>
          <MenuItem aria-label='done' value='done'>DONE</MenuItem>
          <MenuItem aria-label='uat ready' value='uat ready'>UAT READY</MenuItem>
          <MenuItem aria-label='uat' value='uat'>UAT</MenuItem>
          <MenuItem aria-label='prod ready' value='prod ready'>PROD READY</MenuItem>
          <MenuItem aria-label='production' value='production'>PROD</MenuItem>
          <MenuItem aria-label='closed' value='closed'>CLOSED</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default FilterStatus;
