import VideocamIcon from '@mui/icons-material/Videocam';
import { Tooltip } from '@mui/material';
import isMobile from '../../hooks/isMobile';
import { SquareIconButton } from '../../utils/exports/Styles';

const VideoCall = () => {
  const mobileView = isMobile();
  return (
    <Tooltip title='Video Call'>
      <SquareIconButton
        sx={{
          color: 'black',
          width: mobileView ? '20px' : '30px',
          height: mobileView ? '20px' : '30px',
        }}
      >
        <VideocamIcon />
      </SquareIconButton>
    </Tooltip>
  );
};

export default VideoCall;
