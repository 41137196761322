// import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import { Box, Slide, TextField } from '@mui/material';
import { useRef, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { useSearchParams } from 'react-router-dom';

const Search = () => {

    const [searchParams, setQueryParams] = useSearchParams();

    // const [searchAnchorEl, setSearchAnchorEl] = useState<HTMLButtonElement | null>(null);

    // const handleSearchClick = (event: any) => {
    //     setSearchAnchorEl(event.currentTarget);
    // };

    // const handleSearchPopoverClose = () => {
    //     setSearchAnchorEl(null);
    // };

    // const searchOpen = Boolean(searchAnchorEl);

    const [inputValue, setInputValue] = useState<string>('');

    const [query, setQuery] = useState('');
    // const [results, setResults] = useState<any>([]);
    // const [timer, setTimer] = useState<any>(null);

    const runSearch = () => {

        const queryParams = Object.fromEntries(searchParams);
        setQueryParams({
            ...queryParams,
            search: query as string,
        });

        // const fuse = new Fuse(data, {
        //     keys: ['attributes.description', 'attributes.ticket_id'],
        //     shouldSort: true,
        //     includeScore: true,
        // });

        // if (query.trim() !== '') {
        //     const newTimer = setTimeout(() => {
        //         const searchResults = fuse.search(query);
        //         const sortedResults = searchResults.sort((a, b) => (a.score ?? 0) - (b.score ?? 0));
        //         setResults(sortedResults);
        //     }, 1000);
        //     setTimer(newTimer);

        //     return () => {
        //         if (timer) {
        //             clearTimeout(timer);
        //         }
        //     };
        // } else {
        //     setResults([]);
        // }
        // console.log(results)
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);
        setQuery(value.toLowerCase());
    }

    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    const containerRef = useRef<HTMLElement>(null);

    return (
        // <>
        //     <ManageSearchOutlinedIcon onClick={e => handleSearchClick(e)} sx={{ width: '30px', height: '30px', cursor: 'pointer' }} />

        //     <Popover
        //         open={searchOpen}
        //         anchorEl={searchAnchorEl}
        //         onClose={handleSearchPopoverClose}
        //         anchorOrigin={{
        //             vertical: 40,
        //             horizontal: 40
        //         }}
        //         transformOrigin={{
        //             vertical: 'top',
        //             horizontal: 'right',
        //         }}
        //     >
        //         <Box display='flex' flexDirection='column' p='5px'>
        //             <TextField
        //                 size='small'
        //                 placeholder='Search...'
        //                 sx={{ width: '500px' }}
        //                 value={inputValue}
        //                 onChange={(e: any) => handleInputChange(e)}
        //                 onKeyDown={(e) => {
        //                     if (e.key === 'Enter') {
        //                         runSearch();
        //                     }
        //                 }}
        //                 InputProps={{
        //                     startAdornment: (
        //                         <InputAdornment position='start'>
        //                             <SearchIcon sx={{ color: 'gray' }} />
        //                         </InputAdornment>
        //                     )
        //                 }
        //                 }
        //             />
        //         </Box>
        //     </Popover >
        // </>

        <>
            <Box width='100%' overflow='hidden' ref={containerRef}>
                <Slide direction='left' style={{ flex: '1' }} in={open} container={containerRef.current}>
                    <Box mx='10px' mb='2px'>
                        <TextField
                            fullWidth
                            size="small"
                            placeholder="Search..."
                            variant="standard"
                            sx={{ mt: '2px' }}
                            value={inputValue}
                            aria-label='search'
                            onChange={(e: any) => handleInputChange(e)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    runSearch();
                                }
                            }}
                        />
                    </Box>
                </Slide>
            </Box>

            <SearchIcon onClick={() => handleToggle()} sx={{ width: '28px', height: '28px', mt: '3px', cursor: 'pointer' }} />
        </>
    )
}

export default Search
