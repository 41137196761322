import { Box } from '@mui/material';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '../OverviewTicket';
import EditStatus from '../../EditStatus';
import { useState } from 'react';
import { ITicketData } from '../../../utils/exports/Interface';

export default function DesktopTicketSection({
  children,
  type,
  getBoardTickets,
}: {
  children: React.ReactNode;
  type: string;
  getBoardTickets: (status: string) => void;
}) {
  const [data, setData] = useState<ITicketData>();
  const [idToDrop, setIdToDrop] = useState<number>();
  const [trigger, setTrigger] = useState(false);

  const [, drop] = useDrop(() => ({
    accept: ItemTypes.ITEM,
    drop: (item: { idToDrop: number; data: ITicketData }) => handleDrop(item?.idToDrop, item?.data),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const handleDrop = (idToDrop: number, data: ITicketData) => {
    setData(data);
    setIdToDrop(idToDrop);
    setTrigger((trigger) => !trigger);
  };

  // useEffect(() => {
  //   getBoardTickets(type);
  // }, [page, tenant, board]);

  return (
    <>
      <Box
        ref={drop}
        position='relative'
        maxWidth='300px'
        minWidth='300px'
        bgcolor='white'
        display='flex'
        flexDirection='column'
        gap='10px'
        height='calc(100vh - 142px)'
        sx={{
          overflowY: 'scroll',
          overflowX: 'hidden',
          '::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
          },
          '::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
          '&:hover': {
            '::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
            },
          },
        }}
      >
        {children}
      </Box>

      {data && idToDrop && (
        <EditStatus
          trigger={trigger}
          dragDropStatus={type}
          assignees={data?.attributes?.assignees}
          branch={data?.attributes?.branch}
          dragDrop={true}
          dueDate={data?.attributes?.due_date}
          fullId={data?.attributes?.ticket_id}
          idToChange={idToDrop}
          status={data?.attributes?.status}
          tenant={data?.attributes?.main_tenant}
          type={data?.attributes?.type}
          getBoardTickets={getBoardTickets}
        />
      )}
    </>
  );
}
