import {
  Box,
  ImageList,
  ImageListItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import isMobile from '../../hooks/isMobile';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ViewCompactRoundedIcon from '@mui/icons-material/ViewCompactRounded';
import { useNavigate } from 'react-router-dom';
import CreateTicket from '../CreateTicket';
import SelectTenant from '../SelectTenant';
// import Bookmarks from '../Bookmarks';
import logo from '../../assets/images/sciever_logo_png.webp';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { TenantContext } from '../../App';
import SelectBoard from '../SelectBoard';
import { UserDataContext } from '../../routes/Main';
import DesktopNotifications from '../Desktop/DesktopNotifications';
import isCustomer from '../../hooks/isCustomer';
import Account from '../Account';
import Search from '../Search';
import BoardAccess from '../BoardAccess';

const Bookmarks = React.lazy(() => import('../Bookmarks'));

const OverviewHeader = (props: { getBoardTickets?: (status: string) => void }) => {
  const theme = useTheme();
  const mobile = isMobile();
  const customer = isCustomer();
  const navigate = useNavigate();

  const { tenant, setTenant } = useContext(TenantContext);
  const { userData } = useContext(UserDataContext);

  useEffect(() => {
    if (userData?.data?.roles[0]?.name === 'cus') {
      setTenant(userData?.data?.main_tenant);
    }
  }, [userData]);

  const [dynamicLogo, setDynamicLogo] = useState();

  useEffect(() => {
    if (customer) {
      setDynamicLogo(userData?.data?.organizations[0]?.logo);
    }
  }, [customer]);

  const [isNewBoard, setIsNewBoard] = useState(false);

  return (
    <>
      {mobile ? (
        <Box
          display='flex'
          flexDirection='column'
          gap='5px'
          sx={{
            position: 'fixed',
            backgroundColor: theme.palette.background.default,
            left: '15px',
            right: '15px',
            pb: '10px',
            pt: '15px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box display='flex' gap='10px' alignItems='center'>
              <ImageList
                sx={{
                  // width: '35px',
                  height: '35px',
                  overflow: 'hidden',
                  m: '0px',
                }}
                cols={1}
              >
                <ImageListItem>
                  <img
                    src={
                      customer
                        ? `https://sciever.sis.scieverinc.com/api/image/organizations/${dynamicLogo}`
                        : logo
                    }
                    alt='Logo'
                    style={{ height: '35px' }}
                  />
                </ImageListItem>
              </ImageList>

              {userData?.data?.roles[0]?.name === 'cus' && (
                <Typography fontWeight='bold' noWrap>
                  Project Management
                </Typography>
              )}

              <SelectTenant />

              <Box ml='-5px' display='flex'>
                <Suspense fallback={null}>
                  <Bookmarks />
                </Suspense>
              </Box>
            </Box>

            <Box display='flex' alignItems='center' gap='5px'>
              <CreateTicket />

              <ToggleButtonGroup
                orientation='horizontal'
                aria-label='button-group'
                value={location.pathname === '/overview' ? 'module' : 'list'}
                size='small'
                sx={{ height: '30px' }}
              >
                <ToggleButton
                  sx={{ width: '30px' }}
                  aria-label='rounded-icon-button'
                  value='module'
                  onClick={() => location.pathname === '/tickets' && navigate('/overview')}
                >
                  <ViewCompactRoundedIcon />
                </ToggleButton>
                <ToggleButton
                  sx={{ width: '30px' }}
                  aria-label='handle-icon-button'
                  value='list'
                  onClick={() => location.pathname === '/overview' && navigate('/tickets')}
                >
                  <DragHandleIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>

          {tenant !== 'all' && (
            <SelectBoard isNewBoard={isNewBoard} setIsNewBoard={setIsNewBoard} />
          )}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box display='flex' gap='10px' alignItems='center'>
            <ImageList
              sx={{
                height: '35px',
                overflow: 'hidden',
                m: '0px',
              }}
              cols={1}
            >
              <ImageListItem>
                <img
                  src={
                    customer
                      ? `https://sciever.sis.scieverinc.com/api/image/organizations/${dynamicLogo}`
                      : logo
                  }
                  alt='Logo'
                  style={{ height: '35px', width: 'auto', objectFit: 'contain' }}
                />
              </ImageListItem>
            </ImageList>

            <Typography variant='h6' fontWeight='bold' noWrap>
              Project Management
            </Typography>

            <SelectTenant />

            {tenant !== 'all' && (
              <SelectBoard isNewBoard={isNewBoard} setIsNewBoard={setIsNewBoard} />
            )}

            {!isNewBoard && (
              <Suspense fallback={null}>
                <Box display='flex' alignItems='center' gap='5px'>
                  <BoardAccess />
                  <Bookmarks />
                </Box>
              </Suspense>
            )}
          </Box>

          <Box display='flex' flex='1' ml='15px' alignItems='center' gap='10px'>
            <Search />
            <CreateTicket getBoardTickets={props.getBoardTickets} />
            <DesktopNotifications />
            <Account />
          </Box>
        </Box>
      )}
    </>
  );
};

export default OverviewHeader;
