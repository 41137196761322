import isMobile from "../hooks/isMobile"
import DesktopMeetingLayout from "../layout/Desktop/DesktopMeetingLayout"
import MobileMeetingLayout from "../layout/Mobile/MobileMeetingLayout";

const MeetingPage = (props: {
    fromTickets?: boolean;
}) => {

    const mobile = isMobile();

    return (
        <>
            {mobile
                ?
                <MobileMeetingLayout />
                :
                <DesktopMeetingLayout fromTickets={props.fromTickets}/>
            }
        </>
    )
}

export default MeetingPage
