import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import {
  Close as CloseIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Check as CheckIcon,
} from '@mui/icons-material';
import DeletePopUp from './DeletePopUp';
import isSuperAdmin from '../../../hooks/isSuperAdmin';
import { BookmarkItem, IBookmark } from '../../exports/interfaces';

interface BookmarkDialogProps {
  open: boolean;
  onClose: () => void;
  chapterName: string;
  bookmarks?: IBookmark[];
  suggestions?: IBookmark[];
  onAddBookmark?: (item: BookmarkItem) => void;
  onEditBookmark?: (index: number, item: BookmarkItem) => void;
  onDeleteBookmark?: (index: number) => void;
  onAddSuggestion?: (item: BookmarkItem) => void;
  onEditSuggestion?: (index: number, item: BookmarkItem) => void;
  onDeleteSuggestion?: (index: number) => void;
}

const BookmarkSection: React.FC<{
  title: string;
  items: IBookmark[];
  onAdd?: (item: BookmarkItem) => void;
  onEdit?: (index: number, item: BookmarkItem) => void;
  onDelete?: (index: number) => void;
}> = ({ title, items, onAdd, onEdit, onDelete }) => {
  const superAdmin = isSuperAdmin();
  const [newName, setNewName] = useState('');
  const [newLink, setNewLink] = useState('');
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editName, setEditName] = useState('');
  const [editLink, setEditLink] = useState('');
  const [editId, setEditId] = useState<number>(0);

  const handleAdd = () => {
    if (newName.trim() && newLink.trim() && onAdd) {
      onAdd({ name: newName, link: newLink, suggestion: title === 'Bookmarks' ? false : true });
      setNewName('');
      setNewLink('');
    }
  };

  const startEdit = (index: number) => {
    setEditIndex(index);
    setEditId(items[index]?.id);
    setEditName(items[index]?.attributes?.title);
    setEditLink(items[index]?.attributes?.link);
  };

  const saveEdit = () => {
    if (editIndex !== null && onEdit) {
      onEdit(editId, { name: editName, link: editLink });
      setEditIndex(null);
      setEditName('');
      setEditLink('');
    }
  };

  const handleCancelEdit = () => {
    setEditIndex(null);
    setEditName('');
    setEditLink('');
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant='h6' sx={{ mb: 1 }}>
        {title}
      </Typography>
      <List
        sx={{
          // border: '1px solid',
          // borderColor: 'divider',
          // borderRadius: 1,
          maxHeight: '200px',
          overflow: 'auto',
        }}
      >
        {items.map((item, index) => (
          <ListItem key={index} dense>
            {editIndex === index ? (
              <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                <TextField
                  size='small'
                  value={editName}
                  onChange={(e) => setEditName(e.target.value)}
                  placeholder='Name'
                  sx={{ flex: 1 }}
                />
                <TextField
                  size='small'
                  value={editLink}
                  onChange={(e) => setEditLink(e.target.value)}
                  placeholder='URL'
                  sx={{ flex: 1 }}
                />
                <IconButton size='small' onClick={saveEdit}>
                  <CheckIcon sx={{ width: '16px', height: '16px' }} />
                </IconButton>
                <IconButton size='small' onClick={handleCancelEdit}>
                  <CloseIcon sx={{ width: '16px', height: '16px' }} />
                </IconButton>
              </Box>
            ) : (
              <>
                <ListItemText
                  primary={item?.attributes?.title}
                  secondary={
                    <a
                      href={
                        item?.attributes?.link?.startsWith('http://') ||
                        item?.attributes?.link?.startsWith('https://')
                          ? item?.attributes?.link
                          : `https://${item?.attributes?.link}`
                      }
                      target='_blank'
                      rel='noopener noreferrer'
                      // style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {item?.attributes?.link}
                    </a>
                  }
                />
                {superAdmin && (
                  <ListItemSecondaryAction>
                    <IconButton edge='end' size='small' onClick={() => startEdit(index)}>
                      <EditIcon sx={{ width: '16px', height: '16px' }} />
                    </IconButton>
                    {onDelete && (
                      <DeletePopUp onDelete={() => onDelete(item?.id)} />
                      // <IconButton edge='end' size='small' onClick={() => onDelete(item?.id)}>
                      //   <DeleteIcon />
                      // </IconButton>
                    )}
                  </ListItemSecondaryAction>
                )}
              </>
            )}
          </ListItem>
        ))}
        {((title !== 'Bookmarks' && !superAdmin) || superAdmin) && (
          <ListItem dense>
            <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
              <TextField
                size='small'
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                placeholder='Add new name'
                sx={{ flex: 1 }}
              />
              <TextField
                size='small'
                value={newLink}
                onChange={(e) => setNewLink(e.target.value)}
                placeholder='Add new URL'
                sx={{ flex: 1 }}
              />
              <IconButton size='small' onClick={handleAdd}>
                <AddIcon />
              </IconButton>
            </Box>
          </ListItem>
        )}
      </List>
    </Box>
  );
};

const BookmarkDialog: React.FC<BookmarkDialogProps> = ({
  open,
  onClose,
  chapterName,
  bookmarks = [],
  onAddBookmark,
  onEditBookmark,
  onDeleteBookmark,
}) => {
  const bookmaked: IBookmark[] = [];
  const suggested: IBookmark[] = [];

  bookmarks?.map((item) => {
    if (item?.attributes?.suggestion === false) {
      bookmaked.push(item);
    } else {
      suggested.push(item);
    }
  });
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='bookmark-dialog-title'
      sx={{ '& .MuiDialog-paper': { width: '800px', maxWidth: '100%' } }}
    >
      <DialogTitle
        id='bookmark-dialog-title'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          // borderBottom: 1,
          // borderColor: 'divider',
          padding: 2,
        }}
      >
        <Typography variant='h6' sx={{ fontWeight: 600 }}>
          {chapterName} - Bookmarks
        </Typography>
        <IconButton aria-label='close' onClick={onClose} size='small'>
          <CloseIcon fontSize='small' />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: 3 }}>
        <BookmarkSection
          title='Bookmarks'
          items={bookmaked}
          onAdd={onAddBookmark}
          onEdit={onEditBookmark}
          onDelete={onDeleteBookmark}
        />
        <BookmarkSection
          title='Suggestions'
          items={suggested}
          onAdd={onAddBookmark}
          onEdit={onEditBookmark}
          onDelete={onDeleteBookmark}
        />
      </DialogContent>
    </Dialog>
  );
};

export default BookmarkDialog;
