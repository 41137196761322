import { Box, CircularProgress } from '@mui/material';
import DesktopMainLayout from '../layout/Desktop/DesktopMainLayout';
import ChatList from './components/ChatList';
import ChatHead from './components/ChatHead';
import ChatBox from './components/ChatBox';
import isMobile from '../hooks/isMobile';
import BottomNavbar from '../components/Mobile/BottomNavbar';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserDataContext } from '../routes/Main';
import { RoomData } from '../store/constants/constants';
import { AppDispatch, RootState } from '../store/store';
import { mapUser } from '../store/user/userActions';
import { SocketContext } from '../utils/SocketProvider';
import { setSelectedChat } from '../store/room/roomSlice';
import { setBookmarkStatus, setSearchStatus } from '../store/message/messageSlice';
import { dynamicFailed } from '../hooks/useToast';
import DesktopNotifications from '../components/Desktop/DesktopNotifications';
import Account from '../components/Account';
import Bookmarks from './components/Bookmarks';
import './css/chatcss.css';

const ChatRoom = () => {
  const mobileView = isMobile();

  const { userData } = useContext(UserDataContext);

  const chatUser = userData
    ? {
        userId: userData?.data?.id,
        userName: userData?.data?.name,
        role:
          userData?.data?.main_tenant_role[0]?.display_name ||
          userData?.data?.roles[0]?.display_name,
        organization: userData?.data?.main_tenant || 'Sciever Operation System',
      }
    : null;

  const dispatch: AppDispatch = useDispatch();
  const showBookmark = useSelector((state: RootState) => state.message.bookmarkStatus);
  const isConnected = useSelector((state: RootState) => state.websocket.isConnected);
  const error = useSelector((state: RootState) => state.websocket.error);
  const selectedChat = useSelector((state: RootState) => state.room.selectedChat);

  const socket = useContext(SocketContext);

  const handleBackclick = () => {
    dispatch(setSelectedChat(null));
    dispatch(setBookmarkStatus(false));
  };

  const handleChatItemClick = (chat: RoomData) => {
    dispatch(setBookmarkStatus(false));
    dispatch(setSelectedChat(chat));
    dispatch(setSearchStatus(false));
  };

  useEffect(() => {
    if (isConnected && socket && chatUser && chatUser.userId && chatUser.userName) {
      dispatch(mapUser({ socket, chatUser }));
    }
  }, [isConnected, socket, chatUser, dispatch]);

  if (!isConnected) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' padding={2}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    dynamicFailed(`${error}`);
  }

  return (
    <>
      {mobileView ? (
        <Box
          display='flex'
          flexDirection='column'
          height='100vh'
          position='relative'
          overflow='hidden'
          alignItems='center'
        >
          {!selectedChat && !showBookmark && <ChatHead />}
          {!selectedChat && !showBookmark ? (
            <ChatList onChatItemClick={handleChatItemClick} />
          ) : showBookmark ? (
            <Bookmarks onBackClick={handleBackclick} />
          ) : (
            <ChatBox chat={selectedChat} />
          )}
          {!selectedChat && !showBookmark && <BottomNavbar />}
        </Box>
      ) : (
        <DesktopMainLayout>
          <Box
            bgcolor='white'
            position='fixed'
            left='65px'
            right='0px'
            top='0px'
            // bottom='-25px'
            p='15px'
            overflow='hidden'
            display='flex'
            flexDirection='row'
            gap='15px'
          >
            {/* left side== navbar and chatuser item */}
            <Box display='flex' flexDirection='column' gap='8px' width='23dvw' minWidth='23dvw'>
              <ChatHead />
              <ChatList onChatItemClick={handleChatItemClick} />
            </Box>

            {/* right side chatting */}
            <Box
              width='calc(77dvw - 110px)'
              maxWidth='calc(77dvw - 110px)'
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <Box
                width='100%'
                maxWidth='100%'
                minHeight='35px'
                height='35px'
                display='flex'
                flexDirection='row'
                alignItems='center'
                justifyContent='flex-end'
                gap='10px'
                // sx={{ pb: 1, pr: 1 }}
              >
                <DesktopNotifications />
                <Account />
              </Box>
              {showBookmark ? (
                <Bookmarks onBackClick={handleBackclick} />
              ) : (
                <ChatBox chat={selectedChat} />
              )}
            </Box>
          </Box>
        </DesktopMainLayout>
      )}
    </>
  );
};

export default ChatRoom;
