import { Box } from '@mui/material';
import FuseSuggestions from './FuseSuggestions';
import OverviewTicket, { ItemTypes } from '../Home Page/OverviewTicket';
import { IItemsProps } from './NewMeeting';
import { useDrop } from 'react-dnd';
import { ITicketData } from '../../utils/exports/Interface';
import { useState } from 'react';
import EditMeetingBoard from './EditMeetingBoard';

const AgendasBoard = (props: {   
  items: IItemsProps;
  suggestions: ITicketData[];
  fetchSuggestions?: () => void;
}) => {
  const [commentOpen, setCommentOpen] = useState(false);
  const [ticketId, setTicketId] = useState<number>(0);
  const [idToDrop, setIdToDrop] = useState<number>(0);
  const currentItems=props.items

  const [, drop] = useDrop(() => ({
    accept: ItemTypes.ITEM,
    drop: (item: { idToDrop: number; data: { id: number } }) => {
      if (!currentItems?.actionItems.includes(item?.idToDrop)) {
        setIdToDrop(item?.idToDrop);
        setCommentOpen(true);
        setTicketId(item?.data?.id);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const handleDrop = (idToDrop: number) => {
    props?.items?.setActionItems((oldData) => [...oldData, idToDrop]);
    props?.items?.setDiscussions((oldData) => oldData?.filter((data) => data !== idToDrop));
    props?.items?.setRollingItems((oldData) => oldData?.filter((data) => data !== idToDrop));
    props?.items?.setDecisions((oldData) => oldData?.filter((data) => data !== idToDrop));
  };

  return (
    <> 
      <Box display='flex' flexDirection='column' gap='5px' p='5px' alignItems='center'>
        <Box fontWeight='bold' fontSize='14px'>AGENDA</Box>
        <Box
          ref={drop}
          height='calc(100vh - 230px)'
          border='1px dashed gray'
          borderRadius='10px'
          width='300px'
          display='flex'
          flexDirection='column'
          gap='10px'
          p='10px'
          sx={{
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '3px',
            },
          }}
        >
          <FuseSuggestions
            suggestions={props?.suggestions}
            items={props?.items?.actionItems}
            setItems={props?.items?.setActionItems}
            fetchSuggestions={props.fetchSuggestions}
          />
          {props?.items?.actionItemsArray?.map((item) => {
            return <OverviewTicket key={item?.id} data={item} />;
          })}
        </Box>
      </Box>
      <EditMeetingBoard
        commentOpen={commentOpen}
        setCommentOpen={setCommentOpen}
        type={'AGENDA'}
        ticketId={ticketId}
        idToDrop={idToDrop}
        handleDrop={handleDrop}
      />
    </>
  );
};

export default AgendasBoard;
