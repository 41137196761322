import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const FilterType = () => {
  const [searchParams, setQueryParams] = useSearchParams();
  const [filterType, setFilterType] = useState('');

  useEffect(() => {
    setFilterType(searchParams.get('type') ?? '');
  }, [searchParams]);

  const sortType = (event: SelectChangeEvent) => {
    const queryParams = Object.fromEntries(searchParams);
    if (event.target.value === 'all') {
      delete queryParams.type;
    } else {
      queryParams.type = event.target.value as string;
    }
    setQueryParams(queryParams);
  };

  return (
    <>
      <FormControl size='small' variant='standard'>
        <InputLabel sx={{ fontWeight: 'bold', color: '#000000DE', fontSize: '12px' }}>
          Type
        </InputLabel>
        <Select
          disableUnderline
          size='small'
          label='Type'
          aria-label='type-select'
          value={filterType}
          onChange={sortType}
          sx={{
            fontSize: '12px',
            width: '75px',
            mb: '8px',
            '.MuiSelect-icon': {
              color: '#000000DE',
              mt: '-2px',
            },
          }}
        >
          <MenuItem aria-label='all' value='all'>All</MenuItem>
          <MenuItem aria-label='bug' value='bug'>BUG</MenuItem>
          <MenuItem aria-label='task' value='task'>TASK</MenuItem>
          <MenuItem aria-label='feature' value='feature'>FEATURE</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default FilterType;
