import React from "react";
import { Box, Typography, IconButton, List, Paper } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
// import { Course } from '../../types';
import { CourseListItem } from "./CourseListItem";
import { ICourse } from "../../exports/interfaces";

interface CourseListProps {
  courses: ICourse[];
  selectedCourse: string;
  onSelectCourse: (courseName: string) => void;
  onAddCourse: () => void;
  onEditCourse: (courseName: string, id: number) => void;
  isMobile: boolean;
}

export const CourseList: React.FC<CourseListProps> = ({
  courses,
  selectedCourse,
  onSelectCourse,
  onAddCourse,
  onEditCourse,
  isMobile,
}) => (
  <Box sx={{ width: isMobile ? "100%" : "20%", pr: 2, minWidth: 130 }}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 1,
        height: 30,
      }}
    >
      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}> 
        Courses
      </Typography>
      <IconButton size="small" onClick={onAddCourse}>
        <AddIcon />
      </IconButton>
    </Box>
    <List
      component={Paper}
      sx={{ p: 0, maxHeight: isMobile ? "22dvh" : "calc(100dvh - 168px)", overflow: "auto" }}
    >
      {courses.map((course) => (
        <CourseListItem
          key={course.id}
          name={course.attributes.Title}
          isSelected={selectedCourse === course.attributes.Title}
          onSelect={() => onSelectCourse(course.attributes.Title)}
          onEdit={() => onEditCourse(course.attributes.Title,course.id)}
        />
      ))}
    </List>
  </Box>
);
