import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChatLogData,
  ForwardMessageResponse,
  MessageData,
  PinnedMessageData,
  SearchResponse,
  MediaCollectionData,
  AttachmentData,
  TypingUser,
  TypingResponse,
  BookmarkData,
} from '../constants/constants';

interface MessageState {
  messages: MessageData[];
  chatLogs: ChatLogData[];
  total: number;
  page: number;
  limit: number;
  selectedMessage: MessageData | null;
  action: string | null;
  pinMessage: PinnedMessageData | null;
  pinnedMessages: MessageData[];
  searchedMessages: MessageData[];
  mediaCollection: MediaCollectionData | null;
  SearchResults: SearchResponse | null;
  searchStatus: boolean;
  pinnedStatus: boolean;
  bookmarkStatus: boolean;
  forwardedMessages: ForwardMessageResponse | null;
  typingUsers: TypingUser[];
  bookmarks: BookmarkData[];
  isSearchContextActive: boolean;
  isSearching: boolean;
  currentMatchIndex: number;
  debouncedSearchQuery: string;
}

const initialState: MessageState = {
  messages: [],
  chatLogs: [],
  total: 0,
  page: 1,
  limit: 10,
  selectedMessage: null,
  action: null,
  pinMessage: null,
  pinnedMessages: [],
  searchedMessages: [],
  mediaCollection: null,
  SearchResults: null,
  pinnedStatus: false,
  searchStatus: false,
  bookmarkStatus: false,
  forwardedMessages: null,
  typingUsers: [],
  bookmarks: [],
  isSearching: false,
  isSearchContextActive: false,
  currentMatchIndex: 0,
  debouncedSearchQuery: '',
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    sendMessage(state, action: PayloadAction<MessageData>) {
      state.messages.push(action.payload);
    },
    sendChatLog(state, action: PayloadAction<ChatLogData>) {
      state.chatLogs.push(action.payload);
    },

    getMessage(
      state,
      action: PayloadAction<{
        data: MessageData[];
        total: number;
        page: number;
        limit: number;
        pinMessage: PinnedMessageData | null;
      }>,
    ) {
      state.messages = action.payload.data;
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
      state.pinMessage = action.payload.pinMessage;
    },
    replyMessage(state, action: PayloadAction<MessageData>) {
      state.messages.push(action.payload);
    },
    deleteMessage(state, action: PayloadAction<number>) {
      state.messages.find((msg) => msg.id === action.payload);
    },

    setSelectedMessage(state, action: PayloadAction<MessageData | null>) {
      // New action
      state.selectedMessage = action.payload;
    },
    sendAttachment(state, action: PayloadAction<AttachmentData>) {
      console.log('Attachment sent:', action.payload);
    },
    pinMessage(state, action: PayloadAction<PinnedMessageData>) {
      state.pinMessage = action.payload;
    },
    removePinMessage(state) {
      state.pinMessage = null;
    },
    getMediaCollection(state, action: PayloadAction<MediaCollectionData>) {
      state.mediaCollection = action.payload;
    },
    setAction(state, action: PayloadAction<string | null>) {
      state.action = action.payload;
    },
    setSearchResults(state, action: PayloadAction<SearchResponse>) {
      state.SearchResults = action.payload;
    },

    setPinnedStatus(state, action: PayloadAction<boolean>) {
      state.pinnedStatus = action.payload;
    },
    setSearchStatus(state, action: PayloadAction<boolean>) {
      state.searchStatus = action.payload;
    },
    setDebouncedSearchQuery(state, action: PayloadAction<string>) {
      state.debouncedSearchQuery = action.payload;
    },
    setCurrentMatchIndex(state, action: PayloadAction<number>) {
      state.currentMatchIndex = action.payload;
    },

    setIsSearchContextActive(state, action: PayloadAction<boolean>) {
      state.isSearchContextActive = action.payload;
    },

    setIsSearching(state, action: PayloadAction<boolean>) {
      state.isSearching = action.payload;
    },
    setBookmarkStatus(state, action: PayloadAction<boolean>) {
      state.bookmarkStatus = action.payload;
    },
    clearSearchResults(state) {
      state.SearchResults = null;
    },

    forwardMessage(state, action: PayloadAction<ForwardMessageResponse>) {
      // Add forwarded message to messages array
      if (action.payload.data) {
        const newMessages = action.payload.data.map((forwardMsg) => ({
          id: forwardMsg.id,
          senderId: forwardMsg.sender.userId,
          content: forwardMsg.forwardMessage.content,
          roomId: forwardMsg.room.id,
          type: 'forwardMessage',
          createdAt: forwardMsg.createdAt,
          isDeleted: forwardMsg.isDeleted,
          seenBy: forwardMsg.seenBy,
          sender: forwardMsg.sender,
          forwardMessage: {
            content: forwardMsg.forwardMessage.content,
            createdAt: forwardMsg.forwardMessage.createdAt,
            id: forwardMsg.forwardMessage.id,
            isDeleted: forwardMsg.forwardMessage.isDeleted,
            type: forwardMsg.forwardMessage.type,
            path: forwardMsg.forwardMessage.path,
          },
        }));

        state.messages.push(...newMessages);
      }
      state.forwardedMessages = action.payload;
    },

    updateTypingStatus(state, action: PayloadAction<TypingResponse>) {
      const { userId, roomId, userName, typing } = action.payload;

      // Remove existing typing status for this user if exists
      state.typingUsers = state.typingUsers.filter((user) => user.userId !== userId);

      // Add new typing status if user is typing
      if (typing) {
        state.typingUsers.push({
          userId,
          roomId,
          userName,
          typing,
        });
      }
    },

    setPinnedMessages(state, action: PayloadAction<MessageData[]>) {
      state.pinnedMessages = action.payload;
    },
    getSearchedMessages(state, action: PayloadAction<MessageData[]>) {
      state.searchedMessages = action.payload;
    },

    addBookmark(state, action: PayloadAction<BookmarkData>) {
      state.bookmarks.push(action.payload);
    },
    removeBookmark(state, action: PayloadAction<number>) {
      state.bookmarks = state.bookmarks.filter((bookmark) => bookmark.id !== action.payload);
    },
    setBookmarks(state, action: PayloadAction<BookmarkData[]>) {
      state.bookmarks = action.payload;
    },
  },
});

export const {
  sendMessage,
  getMessage,
  replyMessage,
  deleteMessage,
  setSelectedMessage,
  sendAttachment,
  removePinMessage,
  pinMessage,
  getMediaCollection,
  setAction,
  setSearchResults,
  clearSearchResults,
  setSearchStatus,
  setBookmarkStatus,
  forwardMessage,
  sendChatLog,
  updateTypingStatus,
  getSearchedMessages,
  setPinnedMessages,
  addBookmark,
  removeBookmark,
  setBookmarks,
  setIsSearchContextActive,
  setCurrentMatchIndex,
  setDebouncedSearchQuery,
  setIsSearching,
} = messageSlice.actions;

export default messageSlice.reducer;
