import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  styled,
  Divider,
  useMediaQuery,
  SelectChangeEvent,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, Bookmark as BookmarkIcon } from '@mui/icons-material';

// import { mentors, scores } from './CourseObj';
import { scores } from './CourseObj';
import AssignmentPopup from './popups/AssignmentPopup';
import BookmarkDialog from './popups/Bookmark';
import { useCourseContext } from '../context/CourseContext';
import {
  addBookmark,
  addMentor,
  deleteBookmark,
  fetchCourse,
  fetchMentor,
  updateBookmark,
  updateMentor,
  updateTopic,
} from '../api/CourseApi';
import { failed, success } from '../../hooks/useToast';
import { UserDataContext, UserListContext } from '../../routes/Main';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import isIntern from '../../hooks/isIntern';
import { LoadingContext } from '../../App';
import { BookmarkItem, IMentor } from '../exports/interfaces';

// Styled Accordion component to remove default spacing and borders
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    margin: 0,
    '&:before': {
      display: 'none',
    },
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    boxShadow: 'none',
    borderRadius: 0,
  },
  '& .MuiAccordionSummary-root': {
    minHeight: 64,
    padding: theme.spacing(0, 2),
    '&.Mui-expanded': {
      minHeight: 64,
    },
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  '& .MuiAccordionDetails-root': {
    padding: theme.spacing(1, 2, 2),
  },
}));

const Intern: React.FC = () => {
  const [expandedChapter, setExpandedChapter] = useState<string | false>('');
  // const [expandedChapterId, setExpandedChapterId] = useState<number | false>();
  const [selectedCourse, setSelectedCourse] = useState<string>('');
  // const [selectedIntern, setSelectedIntern] = useState<string>('');
  const [internId, setInternId] = useState<number>(0);
  // const [selectedMentor, setSelectedMentor] = useState<string>('Mentor');
  const [mentorId, setMentorId] = useState<number>(0);
  const [mentorData, setMentorData] = useState<IMentor[]>([]);
  const [selectedScore, setSelectedScore] = useState<string>('Score');
  const [isAssignmentPopupOpen, setIsAssignmentPopupOpen] = useState(false);
  const [chapterId, setChapterId] = useState<number>(0);
  // const [completeTopic, setCompleteTopic] = useState<any[]>([]);

  // Add new state for bookmark functionality
  const [isBookmarkOpen, setIsBookmarkOpen] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState<any>(null);

  const { courseData, setCourseData } = useCourseContext();
  const { setLoading } = useContext(LoadingContext);
  const { userList } = useContext(UserListContext);
  const { userData } = useContext(UserDataContext);

  const filterInterns = userList?.data?.users?.users.filter((intern: any) => {
    return intern?.roles[0]?.name === 'int' && intern?.status === '1';
  });
  const filterMentor = userList?.data?.users?.users.filter((intern: any) => {
    return intern?.roles[0]?.name === 'sa';
  });

  useEffect(() => {
    if (filterInterns && filterInterns.length > 0 && !internId) {
      // setSelectedIntern(filterInterns[0]?.name || '');
      setInternId(filterInterns[0]?.id || 0);
    }
  }, [filterInterns]);

  const intern = isIntern();
  const superAdmin = isSuperAdmin();

  useEffect(() => {
    if (courseData?.length === 0) {
      setLoading(true);
      fetchCourse()
        .then((data) => {
          setCourseData(data);
          setSelectedCourse(data[0]?.attributes?.Title);
          setExpandedChapter(data[0]?.attributes?.chapters?.data[0]?.attributes?.title);
          // setExpandedChapterId(data[0]?.attributes?.chapters?.data[0]?.attributes?.id);
          setLoading(false);
        })
        .catch(() => {
          failed();
          setLoading(false);
        });
    } else {
      setSelectedCourse(courseData[0]?.attributes?.Title);
      setExpandedChapter(courseData[0]?.attributes?.chapters?.data[0]?.attributes?.title);
      // setExpandedChapterId(courseData[0]?.attributes?.chapters?.data[0]?.id);
    }
  }, [setCourseData]);
  const currentCourse = courseData.find(
    (c) =>
      c?.attributes?.Title === selectedCourse &&
      c?.attributes?.assigned_to?.includes(superAdmin ? internId : userData?.data?.id),
  );
  useEffect(() => {
    if (currentCourse?.attributes?.chapters?.data[0]?.id && (intern || internId)) {
      fetchMentor(
        currentCourse?.attributes?.chapters?.data[0]?.id,
        intern ? userData?.data?.id : internId,
      )
        .then((data) => {
          if (data?.length === 0) {
            setMentorId(0);
            setSelectedScore('Score');
          } else {
            setMentorData(data);
            setMentorId(
              data[0]?.attributes?.mentor_id === null ? 0 : data[0]?.attributes?.mentor_id,
            );
            setSelectedScore(
              data[0]?.attributes?.score === null ? 'Score' : data[0]?.attributes?.score,
            );
          }
        })
        .catch(() => {
          failed();
          setLoading(false);
        });
    }
  }, [currentCourse]);

  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleChapterExpand =
    (id: number, chapterName: string | false) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedChapter(isExpanded ? chapterName : false);
      fetchMentor(id, intern ? userData?.data?.id : internId)
        .then((data) => {
          if (data?.length === 0) {
            setMentorId(0);
            setSelectedScore('Score');
          } else {
            setMentorData(data);
            setMentorId(
              data[0]?.attributes?.mentor_id === null ? 0 : data[0]?.attributes?.mentor_id,
            );
            setSelectedScore(
              data[0]?.attributes?.score === null ? 'Score' : data[0]?.attributes?.score,
            );
          }
        })
        .catch(() => {
          failed();
          setLoading(false);
        });
    };

  const handleAddBookmark = async (item: BookmarkItem) => {
    setLoading(true);
    try {
      const BookmarkInfo = {
        data: {
          title: item?.name,
          link: item?.link,
          suggestion: item?.suggestion,
          chapter: chapterId,
        },
      };

      const bookmarkResponse = await addBookmark(BookmarkInfo);

      if (bookmarkResponse?.status === 200 || bookmarkResponse?.status === 201) {
        success();
        setSelectedChapter((prevChapter: any) => ({
          ...prevChapter,
          bookmarks: {
            ...prevChapter.bookmarks,
            data: [...prevChapter.bookmarks.data, bookmarkResponse.data.data],
          },
        }));
        fetchCourse()
          .then((data) => {
            setCourseData(data);
            setLoading(false);
          })
          .catch(() => {
            failed();
            setLoading(false);
          });
      } else {
        failed();
        setLoading(false);
      }
    } catch (error) {
      failed();
      setLoading(false);
    }
  };

  const handleEditBookmark = async (id: number, item: BookmarkItem) => {
    setLoading(true);
    try {
      const bookmarkInfo = {
        data: {
          title: item?.name,
          link: item?.link,
        },
      };

      const bookmarkResponse = await updateBookmark(id, bookmarkInfo);

      if (bookmarkResponse?.status === 200 || bookmarkResponse?.status === 201) {
        success();
        setSelectedChapter((prevChapter: any) => ({
          ...prevChapter,
          bookmarks: {
            ...prevChapter.bookmarks,
            data: prevChapter.bookmarks.data.map((bookmark: any) => {
              if (bookmark.id === id) {
                return {
                  ...bookmark,
                  attributes: {
                    ...bookmark.attributes,
                    title: item.name,
                    link: item.link,
                  },
                };
              }
              return bookmark;
            }),
          },
        }));
        fetchCourse()
          .then((data) => {
            setCourseData(data);
            setLoading(false);
          })
          .catch(() => {
            failed();
            setLoading(false);
          });
      } else {
        failed();
        setLoading(false);
      }
    } catch (error) {
      failed();
      setLoading(false);
    }
  };

  const handleDeleteBookmark = async (id: number) => {
    setLoading(true);
    try {
      const response = await deleteBookmark(id);

      if (response?.status === 200 || response?.status === 201) {
        success();
        setSelectedChapter((prevChapter: any) => ({
          ...prevChapter,
          bookmarks: {
            ...prevChapter.bookmarks,
            data: prevChapter.bookmarks.data.filter((bookmark: any) => bookmark.id !== id),
          },
        }));
        fetchCourse()
          .then((data) => {
            setCourseData(data);
            setLoading(false);
          })
          .catch(() => {
            failed();
            setLoading(false);
          });
      } else {
        failed();
        setLoading(false);
      }
    } catch (error) {
      failed();
      setLoading(false);
    }
  };

  const handleTopicChange = async (
    user_id: number,
    topic_id: number,
    ids: number[] | null | undefined,
  ) => {
    setLoading(true);
    try {
      const idsArray = ids || [];
      const isUserInIds = idsArray.includes(user_id);
      const updatedIds = isUserInIds
        ? idsArray.filter((id: number) => id !== user_id)
        : [...idsArray, user_id];
      const data = {
        data: {
          completed_by: updatedIds,
        },
      };
      const response = await updateTopic(topic_id, data);
      if (response?.status === 200 || response?.status === 201) {
        success();
        fetchCourse()
          .then((data) => {
            setCourseData(data);
            setLoading(false);
          })
          .catch(() => {
            failed();
            setLoading(false);
          });
      } else {
        failed();
        setLoading(false);
      }
    } catch (error) {
      failed();
      setLoading(false);
    }
  };

  const handleMentorChange = async (e: SelectChangeEvent<number | 'Mentor'>, chapId: number) => {
    setLoading(true);
    setChapterId(chapId);
    const id = e.target.value === 'Mentor' ? 0 : parseInt(e.target.value as string, 10);
    if (mentorId !== 0 || selectedScore !== 'Score') {
      try {
        const mentorInfo = {
          data: {
            mentor_id: id,
          },
        };

        const mentorResponse = await updateMentor(mentorData[0]?.id, mentorInfo);

        if (mentorResponse?.status === 200 || mentorResponse?.status === 201) {
          success();
          fetchCourse()
            .then((data) => {
              setCourseData(data);
              setLoading(false);
            })
            .catch(() => {
              failed();
              setLoading(false);
            });
        } else {
          failed();
          setLoading(false);
        }
      } catch (error) {
        failed();
        setLoading(false);
      }
    } else {
      try {
        const data = {
          data: {
            mentor_id: id,
            intern_id: internId,
            chapter: chapId,
          },
        };

        const response = await addMentor(data);

        if (response?.status === 200 || response?.status === 201) {
          success();
          fetchCourse()
            .then((data) => {
              setCourseData(data);
              setLoading(false);
            })
            .catch(() => {
              failed();
              setLoading(false);
            });
        } else {
          failed();
          setLoading(false);
        }
      } catch (error) {
        failed();
        setLoading(true);
      }
    }
    setMentorId(id);
  };

  const handleScoreChange = async (e: SelectChangeEvent<string>, chapId: number) => {
    setLoading(true);
    setChapterId(chapId);
    const score = e.target.value as string;
    if (mentorId !== 0 || selectedScore !== 'Score') {
      try {
        const mentorInfo = {
          data: {
            score: score,
          },
        };

        const mentorResponse = await updateMentor(mentorData[0]?.id, mentorInfo);

        if (mentorResponse?.status === 200 || mentorResponse?.status === 201) {
          success();
          fetchCourse()
            .then((data) => {
              setCourseData(data);
              setLoading(false);
            })
            .catch(() => {
              failed();
              setLoading(false);
            });
        } else {
          failed();
          setLoading(false);
        }
      } catch (error) {
        failed();
        setLoading(false);
      }
    } else {
      try {
        const data = {
          data: {
            score: score,
            intern_id: internId,
            chapter: chapId,
          },
        };

        const response = await addMentor(data);

        if (response?.status === 200 || response?.status === 201) {
          success();
          fetchCourse()
            .then((data) => {
              setCourseData(data);
              setLoading(false);
            })
            .catch(() => {
              failed();
              setLoading(false);
            });
        } else {
          failed();
          setLoading(false);
        }
      } catch (error) {
        failed();
        setLoading(true);
      }
    }
    setSelectedScore(score);
  };

  const handleInternChange = async (e: SelectChangeEvent<number>) => { 
    setLoading(true);
    const intern = e.target.value as number;
    fetchMentor(chapterId, intern ? userData?.data?.id : internId)
      .then((data) => {
        if (data?.length === 0) {
          setMentorId(0);
          setSelectedScore('Score');
          setLoading(false);
        } else {
          setLoading(false);
          setMentorData(data);
          setMentorId(data[0]?.attributes?.mentor_id === null ? 0 : data[0]?.attributes?.mentor_id);
          setSelectedScore(
            data[0]?.attributes?.score === null ? 'Score' : data[0]?.attributes?.score,
          );
        }
      })
      .catch(() => {
        failed();
        setLoading(false);
      });
    setInternId(intern);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        px: 2,
        flexDirection: isMobile ? 'column' : 'row',
      }}
    >
      <Box sx={{ width: isMobile ? '100%' : '20%', pr: 2, minWidth: 130 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1,
            height: 30,
          }}
        >
          <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>
            Courses
          </Typography>
        </Box>
        <List
          component={Paper}
          sx={{
            p: 0,
            maxHeight: isMobile ? '22dvh' : 'calc(100dvh - 168px)',
            overflow: 'auto',
          }}
        >
          {courseData
            .filter((course) =>
              course?.attributes?.assigned_to?.includes(superAdmin ? internId : userData?.data?.id),
            )
            .map((course) => (
              <ListItem
                key={course?.id}
                onClick={() => setSelectedCourse(course?.attributes?.Title)}
                sx={{
                  cursor: 'pointer',
                  bgcolor:
                    selectedCourse === course?.attributes?.Title ? 'action.selected' : 'inherit',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }}
              >
                <ListItemText primary={course?.attributes?.Title} />
              </ListItem>
            ))}
        </List>
      </Box>

      <Box sx={{ width: isMobile ? '100%' : '80%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1,
            height: 30,
          }}
        >
          <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>
            {currentCourse && selectedCourse} Chapters
          </Typography>
          {superAdmin && (
            <Select
              value={internId}
              onChange={(e) => {
                {
                  handleInternChange(e);
                }
              }}
              size='small'
            >
              {filterInterns?.map((intern: any) => (
                <MenuItem key={intern.id} value={intern.id} aria-label='Intern'>
                  {intern.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>

        <Paper sx={{ maxHeight: isMobile ? '42dvh' : 'calc(100dvh - 168px)', overflow: 'auto' }}>
          {currentCourse?.attributes?.chapters?.data?.map((chapter) => (
            <StyledAccordion
              key={chapter?.id}
              expanded={expandedChapter === chapter?.attributes?.title}
              onChange={handleChapterExpand(chapter?.id, chapter?.attributes?.title)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  position: 'relative',
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    transform: 'none',
                    '&.Mui-expanded': {
                      transform: 'rotate(180deg)',
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    pr: 16,
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography>{chapter?.attributes?.title}</Typography>
                    <Typography variant='body2' color='text.secondary'>
                      {chapter?.attributes?.hours}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 48,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    display: 'flex',
                    gap: 1,
                    zIndex: 1,
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <IconButton
                    size='small'
                    aria-label='bookmark'
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedChapter(chapter?.attributes);
                      setChapterId(chapter?.id);
                      setIsBookmarkOpen(true);
                    }}
                  >
                    <BookmarkIcon sx={{ width: '18px', height: '18px' }} />
                  </IconButton>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <List sx={{ pt: 0, pb: 3 }}>
                  {chapter?.attributes?.topics?.data?.map((item, index) => (
                    <ListItem key={index} sx={{ pb: 0, height: 30 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item?.attributes?.completed_by?.includes(
                              intern ? userData?.data?.id : internId,
                            )}
                            onChange={() =>
                              handleTopicChange(
                                intern ? userData?.data?.id : internId,
                                item?.id,
                                item?.attributes?.completed_by,
                              )
                            }
                          />
                        }
                        label={item?.attributes?.title}
                      />
                    </ListItem>
                  ))}
                </List>
                <Divider />
                <List>
                  <ListItem
                    sx={{
                      pl: 2,
                      display: 'flex',
                      justifyContent: 'space-around',
                      flexDirection: isMobile ? 'column' : 'row',
                      alignItems: 'start',
                    }}
                  >
                    <Typography sx={{ fontWeight: 500 }}>Mentor Section:</Typography>
                    {superAdmin ? (
                      <>
                        <Select
                          value={mentorId === 0 ? 'Mentor' : mentorId}
                          onChange={(e) => handleMentorChange(e, chapter?.id)}
                          size='small'
                          sx={{ mr: 1, minWidth: 120, mb: 2 }}
                        >
                          <MenuItem value='Mentor' disabled>
                            Mentor
                          </MenuItem>
                          {filterMentor?.map((mentor: any) => (
                            <MenuItem key={mentor.id} value={mentor.id}>
                              {mentor.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    ) : (
                      <Typography sx={{ fontWeight: 300 }}>
                        Mentor:{' '}
                        {filterMentor?.find((mentor: any) => mentor.id === mentorId)?.name ||
                          'Not Assigned'}
                      </Typography>
                    )}
                    <Button
                      variant='outlined'
                      size='small'
                      sx={{ mx: isMobile ? 0 : 1, mb: 2, height: '40px' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsAssignmentPopupOpen(true);
                        setChapterId(chapter?.id);
                      }}
                    >
                      Assignment
                    </Button>
                    {superAdmin ? (
                      <>
                        <Select
                          value={selectedScore}
                          onChange={(e) => handleScoreChange(e, chapter?.id)}
                          size='small'
                          sx={{ minWidth: 120 }}
                        >
                          <MenuItem value='Score' disabled>
                            Score
                          </MenuItem>
                          {scores.map((score) => (
                            <MenuItem key={score.id} value={score.score}>
                              {score.score}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    ) : (
                      <Typography sx={{ fontWeight: 300 }}>
                        Score: {selectedScore === 'Score' ? 'Not Assigned' : selectedScore}
                      </Typography>
                    )}
                  </ListItem>
                </List>
              </AccordionDetails>
            </StyledAccordion>
          ))}
        </Paper>
      </Box>

      <AssignmentPopup
        open={isAssignmentPopupOpen}
        onClose={() => setIsAssignmentPopupOpen(false)}
        intern_id={intern ? userData?.data?.id : internId}
        chapter={chapterId}
      />

      <BookmarkDialog
        open={isBookmarkOpen}
        onClose={() => {
          setIsBookmarkOpen(false);
          setSelectedChapter(null);
        }}
        chapterName={selectedChapter?.title || ''}
        bookmarks={selectedChapter?.bookmarks?.data}
        onAddBookmark={(item) => {
          handleAddBookmark(item);
        }}
        onEditBookmark={(index, item) => {
          handleEditBookmark(index, item);
        }}
        onDeleteBookmark={(index) => {
          handleDeleteBookmark(index);
        }}
      />
    </Box>
  );
};

export default Intern;
