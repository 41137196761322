import Badge from '@mui/material/Badge';
import { Box, ListItemButton, Typography, Menu, MenuItem } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import StarIcon from '@mui/icons-material/Star';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState, MouseEvent, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { SocketContext } from '../../utils/SocketProvider';
import { FavoriteRoom, removeFavoriteAction } from '../../store/room/roomAction';
import HtmltoText from './HtmltoText';
import { stringToColor } from '../../utils/exports/Function';
import { UserDataContext } from '../../routes/Main';
import TypingIndicator from './TypingIndicator';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { SquareIconButton } from '../../utils/exports/Styles';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      zIndex: '1',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      content: '""',
    },
  },
}));

// Initials for avatar
const getInitials = (name: string) => {
  const parts = name.trim().split(/\s+/);

  if (parts.length === 1) {
    return parts[0].slice(0, 2).toUpperCase();
  }
  return `${parts[0][0]}${parts[parts.length - 1][0]}`.toUpperCase();
};

const ChatItem = (props: {
  name: string;
  lastmessage?: string;
  time?: string;
  count?: number;
  unseen?: boolean;
  online?: boolean;
  avatar: string;
  roomType: string;
  roomId: number;
  isFavorite?: boolean;
  onClick: () => void;
  // onFavoriteToggle: () => void;
}) => {
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const socket = useContext(SocketContext);
  const selectedRoom = useSelector((state: RootState) => state.room.selectedChat);
  const typingUsers = useSelector((state: RootState) => state.message.typingUsers);
  const bookmarkStatus = useSelector((state: RootState) => state.message.bookmarkStatus);
  const { userData } = useContext(UserDataContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMuted, setIsMuted] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget as unknown as HTMLElement); // Explicit double cast
  };

  const typingUser = typingUsers.filter(
    (user) => user.roomId === props.roomId && user.userId !== userData?.data?.id && user.typing,
  );

  const isTyping = (): boolean => {
    return typingUser.length > 0;
  };

  const isSelected = (): boolean => {
    if (bookmarkStatus) {
      return false;
    }
    return selectedRoom?.id === props.roomId;
  };

  const handleContextMenu = (event: MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };

  const handleFavoriteClick = (roomId: number) => {
    if (socket) {
      dispatch(FavoriteRoom({ socket, roomId }));
    }
    setAnchorEl(null);
  };

  const handleRemoveFavorite = (roomId: number) => {
    if (socket) {
      dispatch(removeFavoriteAction({ socket, roomId }));
    }
    setAnchorEl(null);
  };

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
  };

  return (
    <>
      <ListItemButton
        onClick={props.onClick}
        onContextMenu={handleContextMenu}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontFamily: "'Exo 2', sans-serif",
          borderBottom: '2px solid lightgray',
          bgcolor: isSelected() ? 'grey.300' : '',
          '&:hover': {
            backgroundColor: 'grey.300', // Darker shade from theme palette
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {props.roomType == 'group' ? (
            <Avatar
              alt={props.avatar}
              src={`https://chatroom.enitiation.com/${props.avatar}`}
              sx={{
                width: 45,
                height: 45,
                bgcolor: stringToColor(props.name || 'User'),
              }}
            >
              {getInitials(props.name || 'User')}
            </Avatar>
          ) : (
            <StyledBadge
              sx={{
                '& .MuiBadge-badge': {
                  color: props.online ? '#28a745' : '#808080',
                  backgroundColor: props.online ? '#28a745' : '#808080',
                },
              }}
              overlap='circular'
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant='dot'
            >
              <Avatar
                alt={props.avatar}
                src={`https://chatroom.enitiation.com/${props.avatar}`}
                sx={{ width: 45, height: 45, bgcolor: stringToColor(props.name || 'User') }}
              >
                {getInitials(props.name || 'User')}
              </Avatar>
            </StyledBadge>
          )}
          <Box sx={{ marginLeft: '8px' }}>
            <Typography
              variant='body1'
              sx={{
                fontWeight: props.unseen ? 'bold' : 'normal',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: '80%',
                // height: '20px',
              }}
            >
              {props.name}
            </Typography>
            {isTyping() ? (
              <TypingIndicator typingUsers={typingUser} calledFrom='ChatItem' />
            ) : (
              <Typography
                variant='body2'
                sx={{
                  fontWeight: props.unseen ? 'bold' : 'normal',
                  width: '160px',
                  height: '20px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                <HtmltoText HtmlContent={props.lastmessage} />
              </Typography>
            )}
          </Box>
        </Box>
        <Box display='flex' alignItems='center' gap={0.5}>
          <Box
            alignItems='end'
            gap='4px'
            width='65.45px'
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            {props.unseen ? (
              <Box display='flex' alignItems='center'>
                <Box
                  width='16px'
                  height='16px'
                  borderRadius='8px'
                  bgcolor='lightgray'
                  display='flex'
                  alignItems='center'
                  sx={{ fontSize: '10px', fontFamily: "'Exo 2', sans-serif", fontWeight: 'bold' }}
                  justifyContent='center'
                >
                  {props.count}
                </Box>
                {props.isFavorite && <StarIcon sx={{ ml: 0.5, height: '16px', width: '16px' }} />}
              </Box>
            ) : (
              <Box height='16px'>
                {props.isFavorite && <StarIcon sx={{ height: '16px', width: '16px' }} />}
              </Box>
            )}
            <Typography
              variant='body1'
              sx={{ fontSize: '12px', fontWeight: props.unseen ? 'bold' : 'normal' }}
            >
              {props.time}
            </Typography>
          </Box>
          <SquareIconButton sx={{ p: '1px' }}>
            <MoreVertIcon
              component='svg' // Ensure the component type is specified if required
              onClick={(event) => {
                event.stopPropagation();
                handleMenuOpen(event);
              }}
              sx={{ height: '16px', width: '16px' }}
            />
          </SquareIconButton>
        </Box>
      </ListItemButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem>
          {props.isFavorite ? (
            <Box
              display='flex'
              alignItems='center'
              onClick={() => handleRemoveFavorite(props.roomId)}
            >
              <StarIcon sx={{ mr: 1 }} />
              Remove from Favorites
            </Box>
          ) : (
            <Box
              display='flex'
              alignItems='center'
              onClick={() => handleFavoriteClick(props.roomId)}
            >
              <StarIcon sx={{ mr: 1 }} />
              Add to Favorites
            </Box>
          )}
        </MenuItem>
        <MenuItem>
          {isMuted ? (
            <Box display='flex' alignItems='center' onClick={() => handleMuteToggle}>
              <NotificationsOffIcon sx={{ mr: 1 }} />
              Unmute Notification
            </Box>
          ) : (
            <Box
              display='flex'
              alignItems='center'
              onClick={() => handleFavoriteClick(props.roomId)}
            >
              <NotificationsIcon sx={{ mr: 1 }} />
              Mute Notification
            </Box>
          )}
        </MenuItem>
      </Menu>
    </>
  );
};

ChatItem.propTypes = {
  name: PropTypes.string.isRequired,
  lastmessage: PropTypes.number.isRequired,
  time: PropTypes.string.isRequired,
  count: PropTypes.number,
  unseen: PropTypes.bool.isRequired,
  online: PropTypes.bool.isRequired,
  avatar: PropTypes.string.isRequired,
  // isFavorite: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  // onFavoriteToggle: PropTypes.func.isRequired,
};

export default ChatItem;
