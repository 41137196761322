import axios from 'axios';
import { headers } from '../exports/exports';
import {
  IAssignment,
  IBookmakrFormData,
  IChapterFormData,
  ICourseFormData,
  IMentorFormData,
  ITopicFormData,
} from '../exports/interfaces';
import { failed } from '../../hooks/useToast';
// import { IMessageData } from "../exports/interfaces";
// import { failed } from "../hooks/useToast";

export const fetchCourse = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_strapiUrl}/courses?populate[chapters][populate]=topics,bookmarks`,
      { headers },
    );
    return response.data.data;
  } catch (error) {
    failed();
    console.error('Server Error', error);
  }
};

export const addCourse = async (data: ICourseFormData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_strapiUrl}/courses`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};

export const updateCourse = async (id: number, data: ICourseFormData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_strapiUrl}/courses/${id}`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};

export const deleteCourse = async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_strapiUrl}/courses/${id}`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};

export const addChapter = async (data: IChapterFormData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_strapiUrl}/chapters`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};

export const updateChapter = async (id: number, data: IChapterFormData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_strapiUrl}/chapters/${id}`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};

export const deleteChapter = async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_strapiUrl}/chapters/${id}`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};

export const addTopic = async (data: ITopicFormData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_strapiUrl}/titles`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};

export const updateTopic = async (id: number, data: ITopicFormData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_strapiUrl}/titles/${id}`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};

export const deleteTopic = async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_strapiUrl}/titles/${id}`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};

export const addBookmark = async (data: IBookmakrFormData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_strapiUrl}/bookmarks`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};

export const updateBookmark = async (id: number, data: IBookmakrFormData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_strapiUrl}/bookmarks/${id}`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};

export const deleteBookmark = async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_strapiUrl}/bookmarks/${id}`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};

export const addMentor = async (data: IMentorFormData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_strapiUrl}/mentors`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};

export const fetchMentor = async (chapterId: any, intern_id: number) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_strapiUrl}/mentors?filters[chapter][id][$eq]=${chapterId}&filters[intern_id][$eq]=${intern_id}&populate=*`,
      { headers },
    );
    return response.data.data;
  } catch (error) {
    failed();
    console.error('Server Error', error);
  }
};

export const updateMentor = async (id: number, data: IMentorFormData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_strapiUrl}/mentors/${id}`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};

export const addAssignment = async (data: IAssignment) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_strapiUrl}/assignments`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};

export const fetchAssignment = async (chapterId: any, intern_id: number) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_strapiUrl}/assignments?filters[chapter][id][$eq]=${chapterId}&filters[intern_id][$eq]=${intern_id}&populate=*`,
      { headers },
    );
    return response.data.data;
  } catch (error) {
    failed();
    console.error('Server Error', error);
  }
};

export const updateAssignment = async (id: number, data: IAssignment) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_strapiUrl}/assignments/${id}`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};

export const deleteAssignment = async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_strapiUrl}/assignments/${id}`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error('Server Error', error);
    failed();
  }
};
