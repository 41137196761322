import { Box, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BoardContext, TenantContext, TicketsContext } from '../../../App';
import OverviewTicket from '../OverviewTicket';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DataContext } from '../../../pages/TicketsPage';
import { PageContext } from '../../../routes/Main';
import { ITicketSection } from '../../../utils/exports/Interface';
// import axios from 'axios';
// import { failed } from '../../../hooks/useToast';
// import InfiniteScroll from "react-infinite-scroll-component";

export default function TicketSection({
  type,
  ticketsData,
  setTicketsData,
  ticketsCount,
  getBoardTickets,
}: ITicketSection) {
  const { setTickets } = useContext(TicketsContext);
  // const { ticketList } = useContext(TicketListContext);
  const { data } = useContext(DataContext);
  const { tenant } = useContext(TenantContext);
  const { board } = useContext(BoardContext);
  const { page } = useContext(PageContext);

  const navigate = useNavigate();

  // const [index, setIndex] = useState(1);
  // const [hasMore, setHasMore] = useState(true);

  const handleViewAll = () => {
    setTickets(type);
    navigate(
      `/tickets?status=${
        type === 'WIP' ? 'in progress' : type === 'Prod' ? 'production' : type?.toLowerCase()
      }`,
    );
  };

  useEffect(() => {
    getBoardTickets(type);
  }, [page, tenant, board]);

  // useEffect(() => {
  //   console.log('data', data);
  //   console.log('tickets',tickets);
  //   socket?.emit('newMessage', tickets);
  //   socket?.on('onMessage', (tickets) => {
  //     setTicketsData(tickets);
  //   });
  //   return () => {
  //     console.log('unregistering Events...');
  //     socket?.off('connect');
  //     socket?.off('onMessage');
  //   };
  // }, [socket,tickets]);

  // const fetchMoreData = () => {
  //   console.log('running')
  //   axios.get(`${process.env.REACT_APP_strapiUrl}/tickets?pagination[page]=${index}&pagination[pageSize]=10&filters[status][$eq]=${type.toLowerCase()}&populate=*&sort=updatedAt%3Adesc`, {
  //     headers: headers
  //   })
  //     .then(res => {
  //       setData((prevItems) => [...prevItems, ...res.data.data]);
  //       res.data.data.length > 0 ? setHasMore(true) : setHasMore(false);
  //     })
  //     .catch(() => failed())
  //   setIndex((prevIndex) => prevIndex + 1)
  // }

  return (
    <>
      <Box
        position='sticky'
        top='0px'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        width='100%'
        px='5px'
        pb='5px'
        bgcolor='white'
        sx={{
          zIndex: 1,
        }}
      >
        <Typography fontSize='14px' fontWeight='bold'>
          {type === 'In Progress' ? 'WIP' : type === 'Production' ? 'PROD' : type.toUpperCase()} (
          {ticketsCount})
        </Typography>
        {data?.length > 0 && <DragHandleIcon onClick={handleViewAll} sx={{ cursor: 'pointer' }} />}
      </Box>

      {/* <InfiniteScroll
        dataLength={data?.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      > */}
      <Box mt='-10px' display='flex' flexDirection='column' gap='10px'>
        {ticketsData?.length > 0 &&
          ticketsData.map((individualData) => (
            <OverviewTicket
              data={individualData}
              ticketsData={ticketsData}
              setTicketsData={setTicketsData}
              key={individualData?.attributes?.ticket_id}
              getBoardTickets={getBoardTickets}
            />
          ))}
      </Box>

      {/* </InfiniteScroll> */}
    </>
  );
}
