import { MeiliSearch } from 'meilisearch';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BoardContext, LoadingContext, TenantContext, TicketsContext } from '../App';
import isMobile from '../hooks/isMobile';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { PageContext, PageSizeContext, UserDataContext } from '../routes/Main';
import { getTicketsService, getTicketsTenantSpecificService } from '../services/ticketService';
import DesktopTicketsPage from './Desktop/DesktopTicketsPage';
import MobileTicketsPage from './Mobile/MobileTicketsPage';
import isDeveloper from '../hooks/isDeveloper';
import Fuse, { FuseResult } from 'fuse.js';
import { ITicketData } from '../utils/exports/Interface';

export const DataContext = createContext<any>({});

export const CountContext = createContext<any>({});

const TicketsPage = () => {
  const mobile = isMobile();
  const superAdmin = isSuperAdmin();
  const developer = isDeveloper();

  const { tickets } = useContext(TicketsContext);
  const { userData } = useContext(UserDataContext);
  const { tenant } = useContext(TenantContext);
  const { setLoading } = useContext(LoadingContext);
  const { page } = useContext(PageContext);
  const { pageSize } = useContext(PageSizeContext);
  const { board } = useContext(BoardContext);

  const [count, setCount] = useState<number>();

  const [filters, setFilters] = useState<ITicketData[]>([]);
  const [searchparams] = useSearchParams();

  const [data, setData] = useState<ITicketData[]>([]);

  const [searchParams, setQueryParams] = useSearchParams();

  useEffect(() => {
    const queryParams = Object.fromEntries(searchParams);
    if (tenant === 'all') {
      delete queryParams.tenant;
    } else {
      queryParams.tenant = tenant as string;
    }
    queryParams.board = board.join(',');
    delete queryParams.assignees;
    setQueryParams(queryParams);
  }, [tenant, board]);

  const client = new MeiliSearch({
    host: 'http://localhost:7700/',
  });
  const getTickets = async (status: string) => {
    const type = searchparams.get('type');
    const priority = searchparams.get('priority');
    const assignees = searchparams.get('assignees');
    const environment = searchparams.get('environment');
    const dueDate = searchparams.get('dueDate');

    if (!userData) {
      return;
    }
    setLoading(true);
    const res = await getTicketsService(
      superAdmin ? 'sa' : userData?.data?.id,
      userData.data.organizations[0]?.alias ?? false,
      board,
      page,
      pageSize,
      false,
      status,
      assignees || undefined,
      type || undefined,
      priority || undefined,
      environment || undefined,
      dueDate || undefined,
    );
    if (res?.data) {
      setCount(res?.data?.meta?.pagination?.total);
      setFilters(res?.data?.data);
      setLoading(false);
    }
  };

  const getTenantTickets = async (status: string) => {
    const type = searchparams.get('type');
    const priority = searchparams.get('priority');
    const assignees = searchparams.get('assignees');
    const environment = searchparams.get('environment');
    const dueDate = searchparams.get('dueDate');

    if (!userData) {
      return;
    }
    setLoading(true);
    const res = developer
      ? await getTicketsTenantSpecificService(
          superAdmin ? 'sa' : userData?.data?.id,
          tenant === 'all' ? userData?.data?.organizations : tenant,
          board,
          page,
          pageSize,
          false,
          status,
          assignees || undefined,
          type || undefined,
          priority || undefined,
          environment || undefined,
          dueDate || undefined,
        )
      : await getTicketsTenantSpecificService(
          superAdmin ? 'sa' : userData?.data?.id,
          tenant === 'all' ? null : tenant,
          board,
          page,
          pageSize,
          false,
          status,
          assignees || undefined,
          type || undefined,
          priority || undefined,
          environment || undefined,
          dueDate || undefined,
        );
    if (res?.data) {
      setCount(res?.data?.meta?.pagination?.total);
      setFilters(res?.data?.data);
      setLoading(false);
    }
  };

  const fetchTickets = () => {
    const status = searchparams.get('status') ?? '';

    if (!superAdmin && !developer) {
      getTickets(status);
      return;
    }
    getTenantTickets(status);
  };

  useEffect(() => {
    fetchTickets();
  }, [superAdmin, developer, searchparams, tenant, board, page, pageSize]);

  useEffect(() => {
    const query = searchparams.get('data');

    if (query && query !== null) {
      client.index('ticket').updateFilterableAttributes(['main_tenant']);
      client
        .index('ticket')
        .search(query)
        .then((results) => {
          const finalResult: any = [];
          results.hits.map((hit) => {
            hit.attachment != null
              ? (hit.attachment = {
                  data: hit.attachment.map((item: any) => {
                    return { attributes: item };
                  }),
                })
              : '';
            hit.chats != null
              ? (hit.chats = {
                  data: hit.chats.map((item: any) => {
                    return { attributes: item };
                  }),
                })
              : '';
            const finalResults = {
              attributes: hit,
            };
            finalResult.push(finalResults);
          });
          setFilters(finalResult);
          setLoading(false);
        });
    }
  }, [searchparams.get('data')]);

  const searchTickets = (query: string) => {
    let results: FuseResult<ITicketData>[] = [];

    const fuse = new Fuse(filters, {
      keys: ['attributes.description', 'attributes.ticket_id'],
      shouldSort: true,
      includeScore: true,
    });

    if (query.trim() !== '') {
      const searchResults = fuse.search(query);
      const sortedResults = searchResults.sort((a, b) => (a.score ?? 0) - (b.score ?? 0));
      results = sortedResults;
    } else {
      results = [];
    }
    setFilters(results?.map((ticket: { item: ITicketData }) => ticket?.item));
  };

  const search = searchparams.get('search');

  useEffect(() => {
    if (search && search !== '') {
      searchTickets(decodeURI(search));
    } else {
      fetchTickets();
    }
  }, [search]);

  useEffect(() => {
    const type = searchparams.get('type');
    const priority = searchparams.get('priority');
    const assignees = searchparams.get('assignees');
    const environment = searchparams.get('environment');

    if (
      type &&
      type !== '' &&
      priority &&
      priority !== '' &&
      assignees &&
      assignees !== '' &&
      environment &&
      environment !== ''
    ) {
      setData(
        filters?.filter(
          (el) =>
            el?.attributes?.type === decodeURI(type) &&
            el?.attributes?.priority === decodeURI(priority) &&
            el?.attributes?.environment?.toLowerCase() === decodeURI(environment) &&
            el?.attributes?.assignees?.includes(decodeURI(assignees)),
        ),
      );
    }

    if (
      type &&
      type !== '' &&
      priority === null &&
      assignees &&
      assignees !== '' &&
      environment &&
      environment !== ''
    ) {
      setData(
        filters?.filter(
          (el) =>
            el?.attributes?.type === decodeURI(type) &&
            el?.attributes?.environment?.toLowerCase() === decodeURI(environment) &&
            el?.attributes?.assignees?.includes(decodeURI(assignees)),
        ),
      );
    }

    if (
      type &&
      type !== '' &&
      priority &&
      priority !== '' &&
      assignees &&
      assignees !== '' &&
      environment === null
    ) {
      setData(
        filters?.filter(
          (el) =>
            el?.attributes?.type === decodeURI(type) &&
            el?.attributes?.priority === decodeURI(priority) &&
            el?.attributes?.assignees?.includes(decodeURI(assignees)),
        ),
      );
    }

    if (
      type &&
      type !== '' &&
      priority &&
      priority !== '' &&
      assignees === null &&
      environment &&
      environment !== ''
    ) {
      setData(
        filters?.filter(
          (el) =>
            el?.attributes?.type === decodeURI(type) &&
            el?.attributes?.priority === decodeURI(priority) &&
            el?.attributes?.environment?.toLowerCase() === decodeURI(environment),
        ),
      );
    }

    if (
      type === null &&
      priority &&
      priority !== '' &&
      assignees &&
      assignees !== '' &&
      environment &&
      environment !== ''
    ) {
      setData(
        filters?.filter(
          (el) =>
            el?.attributes?.priority === decodeURI(priority) &&
            el?.attributes?.environment?.toLowerCase() === decodeURI(environment) &&
            el?.attributes?.assignees?.includes(decodeURI(assignees)),
        ),
      );
    }

    if (
      type === null &&
      priority === null &&
      assignees &&
      assignees !== '' &&
      environment &&
      environment !== ''
    ) {
      setData(
        filters?.filter(
          (el) =>
            el?.attributes?.environment?.toLowerCase() === decodeURI(environment) &&
            el?.attributes?.assignees?.includes(decodeURI(assignees)),
        ),
      );
    }

    if (
      type === null &&
      priority &&
      priority !== '' &&
      assignees === null &&
      environment &&
      environment !== ''
    ) {
      setData(
        filters?.filter(
          (el) =>
            el?.attributes?.priority === decodeURI(priority) &&
            el?.attributes?.environment?.toLowerCase() === decodeURI(environment),
        ),
      );
    }

    if (
      type &&
      type !== '' &&
      priority === null &&
      assignees === null &&
      environment &&
      environment !== ''
    ) {
      setData(
        filters?.filter(
          (el) =>
            el?.attributes?.type === decodeURI(type) &&
            el?.attributes?.environment?.toLowerCase() === decodeURI(environment),
        ),
      );
    }

    if (
      type === null &&
      priority &&
      priority !== '' &&
      assignees &&
      assignees !== '' &&
      environment === null
    ) {
      setData(
        filters?.filter(
          (el) =>
            el?.attributes?.priority === decodeURI(priority) &&
            el?.attributes?.assignees?.includes(decodeURI(assignees)),
        ),
      );
    }

    if (
      type &&
      type !== '' &&
      priority === null &&
      assignees &&
      assignees !== '' &&
      environment === null
    ) {
      setData(
        filters?.filter(
          (el) =>
            el?.attributes?.type === decodeURI(type) &&
            el?.attributes?.assignees?.includes(decodeURI(assignees)),
        ),
      );
    }

    if (
      type &&
      type !== '' &&
      priority &&
      priority !== '' &&
      assignees === null &&
      environment === null
    ) {
      setData(
        filters?.filter(
          (el) =>
            el?.attributes?.type === decodeURI(type) &&
            el?.attributes?.priority === decodeURI(priority),
        ),
      );
    }

    if (type && type !== '') {
      setData(filters?.filter((el) => el?.attributes?.type === decodeURI(type)));
    }

    if (priority && priority !== '') {
      // console.log(filters?.filter((el) => el?.attributes?.priority === decodeURI(priority)));
      setData(filters?.filter((el) => el?.attributes?.priority === decodeURI(priority)));
    }

    if (environment && environment !== '') {
      setData(
        filters?.filter(
          (el) => el?.attributes?.environment?.toLowerCase() === decodeURI(environment),
        ),
      );
    }

    if (assignees && assignees !== '') {
      // console.log(filters?.map((el) => el?.attributes?.assignees?.filter(item => item?.name === decodeURI(assignees))).filter(element => element !== undefined));
      // setData(filters?.map((el) => el?.attributes?.assignees?.filter(item => item?.name === decodeURI(assignees)).filter(element => element !== undefined));
      setData(
        filters?.filter((el) => {
          return (
            el?.attributes?.assignees !== null &&
            el?.attributes?.assignees?.includes(decodeURI(assignees))
          );
        }),
      );
    }

    setData(filters);
  }, [tickets, searchparams, filters, tenant]);

  const attendanceUserOnly =
    userData?.data?.roles[0]?.name !== 'sa' &&
    userData?.data?.organizations?.length === 1 &&
    userData?.data?.organizations[0]?.alias === 'sciever'
      ? true
      : false;

  const navigate = useNavigate();

  useEffect(() => {
    if (attendanceUserOnly) {
      navigate('/attendance');
    }
  }, [attendanceUserOnly]);

  return (
    <>
      <DataContext.Provider value={{ data, setData, fetchTickets }}>
        <CountContext.Provider value={{ count, setCount }}>
          {mobile ? <MobileTicketsPage /> : <DesktopTicketsPage />}
        </CountContext.Provider>
      </DataContext.Provider>
    </>
  );
};

export default TicketsPage;
