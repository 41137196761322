import React, { Suspense, useContext, useEffect, useState } from 'react';
import Sidebar from '../../components/Desktop/Sidebar';
import {
  Box,
  FormControl,
  ImageList,
  ImageListItem,
  MenuItem,
  Select,
  Typography,
  styled,
} from '@mui/material';
import CheckInOut from '../../components/Attendance/CheckInOut';
import Holidays from '../../components/Attendance/Holidays';
// import Leave from '../../components/Attendance/Leave';
// import Report from '../../components/Attendance/Report';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import isAdmin from '../../hooks/isAdmin';
import Details from '../../components/Attendance/Details';
import { CurrentUserContext, LoadingContext } from '../../App';
// import Policy from '../../components/Attendance/Policy'
import logo from '../../assets/images/sciever_logo_png.webp';
import DesktopNotifications from '../../components/Desktop/DesktopNotifications';
import Account from '../../components/Account';
import { AttendanceUsersContext, CombinedReportContext, UserDataContext } from '../../routes/Main';
import { attendanceUsersSort } from '../../utils/exports/Function';
import SelectTenant from '../../components/SelectTenant';
import isCustomer from '../../hooks/isCustomer';
import isUserOfService from '../../hooks/isServiceUser';

const Policy = React.lazy(() => import('../../components/Attendance/Policy'));
const Leave = React.lazy(() => import('../../components/Attendance/Leave'));
const Report = React.lazy(() => import('../../components/Attendance/Report'));

export const AttendanceBlock = styled('div')({
  height: 'calc(100dvh - 80px)',
  padding: '15px',
  flex: '1',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'flex-start',
  gap: '5px',
  position: 'relative',
});

const DesktopAttendanceLayout = () => {
  const superAdmin = isSuperAdmin();
  const admin = isAdmin();
  const customer = isCustomer();

  const projectManagementUser = isUserOfService('Project Management');

  const { setCombinedReport } = useContext(CombinedReportContext);

  const { setLoading } = useContext(LoadingContext);

  const { combinedReport } = useContext(CombinedReportContext);

  const { attendanceUsers } = useContext(AttendanceUsersContext);

  const { userData } = useContext(UserDataContext);

  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);

  const [currentUserId, setCurrentUserId] = useState<number>(currentUser?.id);

  const [dynamicLogo, setDynamicLogo] = useState();

  useEffect(() => {
    if (customer) {
      setDynamicLogo(userData?.data?.organizations[0]?.logo);
    }
  }, [customer]);

  useEffect(() => {
    currentUser ? setLoading(false) : setLoading(true);
  }, [currentUser]);

  // useEffect(() => {
  //   // currentUserId === 0 ? setCombinedReport(true) : setCombinedReport(false);
  //   currentUserId !== 0 &&
  //   setCurrentUser(attendanceUsers?.filter((user: any) => user?.id === currentUserId)[0]);
  // }, [currentUserId]);

  const sortedUsers = attendanceUsersSort(attendanceUsers);

  useEffect(() => {
    setCurrentUserId(currentUser?.id);
  }, [currentUser]);

  return (
    <>
      {/* <DesktopHeader /> */}
      <Sidebar />
      <Box
        bgcolor='white'
        position='fixed'
        left={!projectManagementUser ? '0px' : '65px'}
        right='0px'
        top='0px'
        bottom='-25px'
        p='15px'
        overflow='hidden'
        // sx={{ borderTopLeftRadius: '10px' }}f
      >
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex' alignItems='center' gap='10px'>
            <ImageList
              sx={{
                height: '35px',
                overflow: 'hidden',
                m: '0px',
              }}
              cols={1}
            >
              <ImageListItem>
                <img
                  src={
                    customer
                      ? `https://sciever.sis.scieverinc.com/api/image/organizations/${dynamicLogo}`
                      : logo
                  }
                  alt='Logo'
                  style={{ height: '35px', width: 'auto', objectFit: 'contain' }}
                />
              </ImageListItem>
            </ImageList>

            <Typography variant='h6' fontWeight='bold'>
              HR Management
            </Typography>

            {superAdmin && <SelectTenant />}

            {(superAdmin || admin) && (
              // <PersonIcon onClick={() => setUsersOpen(true)} sx={{ cursor: 'pointer' }} />
              <FormControl size='small'>
                <Select
                  value={combinedReport ? 0 : currentUserId !== undefined ? currentUserId : ''}
                  aria-label='attributes-name-select'
                  sx={{ height: '28px', pb: '1px' }}
                  onChange={(e: any) => {
                    setCurrentUserId(e.target.value);
                    e.target.value !== 0 &&
                      setCurrentUser(
                        attendanceUsers?.filter((user: any) => user?.id === e.target.value)[0],
                      );
                    e.target.value === 0 ? setCombinedReport(true) : setCombinedReport(false);
                  }}
                >
                  <MenuItem aria-label='all' value={0}>
                    All
                  </MenuItem>
                  {sortedUsers?.map(
                    (user: { attributes: { name: string; status: boolean }; id: number }) => {
                      if (user?.attributes?.status === true) {
                        return (
                          <MenuItem aria-label='attributes-name' value={user?.id} key={user?.id}>
                            {user?.attributes?.name?.split(' ')[0]}
                          </MenuItem>
                        );
                      }
                      return null;
                    },
                  )}
                </Select>
              </FormControl>
            )}

            <Suspense fallback={null}>
              <Policy />
            </Suspense>
          </Box>

          <Box display='flex' alignItems='center' gap='10px'>
            <DesktopNotifications />
            <Account />
          </Box>
        </Box>

        <Box display='flex' width='100%' mt='15px' gap='15px' justifyContent='space-between'>
          <Details setCurrentUserId={setCurrentUserId} />
          <CheckInOut />
          <AttendanceBlock sx={{ border: 'none', p: '0px', cursor: 'default' }}>
            <Box display='flex' flexDirection='column' gap='10px' height='100%' width='100%'>
              <Suspense fallback={null}>
                <Leave />
              </Suspense>
              <Holidays />
            </Box>
          </AttendanceBlock>
          <Suspense fallback={null}>
            <Report />
          </Suspense>
        </Box>
      </Box>
    </>
  );
};

export default DesktopAttendanceLayout;
