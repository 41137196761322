import React, { useContext, useEffect, useRef } from 'react';
import { Box, Typography, MenuItem, Avatar, Popover } from '@mui/material';
import { UserDataContext } from '../../routes/Main';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

interface UserMentionProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onMentionSelect: (username?: string) => void;
  searchTerm: string;
  isMentionMode: boolean;
}

const UserMention: React.FC<UserMentionProps> = ({
  anchorEl,
  onClose,
  onMentionSelect,
  searchTerm,
  isMentionMode,
}) => {
  const { userData } = useContext(UserDataContext);
  const currentRoom = useSelector((state: RootState) => state.room.currentRoom);
  const popoverRef = useRef<HTMLDivElement>(null);
  // Check if the search term is exactly '@' or '@all'
  const isJustAtSymbol = searchTerm.trim() === '';
  const isAtAll = searchTerm.toLowerCase().trim() === 'all';

  // Placeholder for room participants - in a real app, this would come from chat data or API
  const participants = currentRoom?.roomParticipants || [];

  const getInitials = (name: string) => {
    const parts = name.trim().split(' ');
    if (parts.length === 1) {
      return parts[0].slice(0, 2).toUpperCase();
    }
    return `${parts[0][0]}${parts[parts.length - 1][0]}`.toUpperCase();
  };

  // Enhanced filtering to match partial names
  const filteredParticipants = participants.filter(
    (participant) =>
      participant.user.userId !== userData.data.id &&
      (searchTerm === '' ||
        participant.user?.userName.toLowerCase().includes(searchTerm.toLowerCase())),
  );

  const handleMentionAll = () => {
    onMentionSelect('all');
  };

  const highlightMatch = (text: string, searchTerm: string) => {
    if (!searchTerm) return text;

    const lowerText = text.toLowerCase();
    const lowerSearchTerm = searchTerm.toLowerCase();

    const matchIndex = lowerText.indexOf(lowerSearchTerm);

    if (matchIndex === -1) return text;

    return (
      <>
        {text.slice(0, matchIndex)}
        <span style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>
          {text.slice(matchIndex, matchIndex + searchTerm.length)}
        </span>
        {text.slice(matchIndex + searchTerm.length)}
      </>
    );
  };

  // If "@all" is typed exactly, select the "Mention All" option
  useEffect(() => {
    if (isAtAll) {
      handleMentionAll();
    }
  }, [isAtAll]);

  return (
    <Popover
      ref={popoverRef}
      open={isMentionMode && (isJustAtSymbol || filteredParticipants.length > 0 || isAtAll)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        sx: {
          width: 300,
          maxHeight: 300,
          overflowY: 'auto',
          marginTop: '-2rem',
        },
        onMouseDown: (e: any) => e.stopPropagation(),
        onClick: (e: any) => e.stopPropagation(),
      }}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <Box>
        {isJustAtSymbol && filteredParticipants.length > 1 && (
          <MenuItem onClick={handleMentionAll} sx={{ color: 'primary.main' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <GroupAddIcon />
              <Typography variant='body1'>Mention All Participants</Typography>
            </Box>
          </MenuItem>
        )}

        {(isJustAtSymbol || (!isAtAll && filteredParticipants.length > 0)) &&
          filteredParticipants.map((participant) => (
            <MenuItem
              key={participant.id}
              onClick={() => onMentionSelect(participant.user?.userName)}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Avatar
                  src={`https://chatroom.enitiation.com/${participant.user?.profilePictureURL}`}
                  sx={{ width: 35, height: 35, fontSize: 16 }}
                >
                  {getInitials(participant.user?.userName || 'Anonymous')}
                </Avatar>
                <Typography variant='body1'>
                  {!isJustAtSymbol && searchTerm
                    ? highlightMatch(participant.user?.userName, searchTerm)
                    : participant.user?.userName}
                </Typography>
              </Box>
            </MenuItem>
          ))}
      </Box>
    </Popover>
  );
};

export default UserMention;
