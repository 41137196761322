// import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { Avatar, Box, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useContext, useState } from 'react';
import { CountContext, DataContext } from '../../pages/TicketsPage';
import EditDueDate from '../EditDueDate';
import EditPriority from '../EditPriority';
import EditStatus from '../EditStatus';
import EditType from '../EditType';
import DueDatePicker from './DueDatePicker';
// import TableIcons from './TableIcons';
import ViewAttachments from './ViewAttachments';
import '../../index.css';
import { PageContext, PageSizeContext } from '../../routes/Main';
import FilterType from '../FilterType';
import FilterStatus from '../FilterStatus';
import FilterPriority from '../FilterPriority';
import FilterAssignees from '../FilterAssignees';
import ClearFilter from '../ClearFilter';
import EditEnvironment from '../EditEnvironment';
import FilterEnvironment from '../FilterEnvironment';
import AssignDeveloper from '../AssignDeveloper';
import FilterDueDate from '../FilterDueDate';
import { hasDueDatePassed, stringToColor } from '../../utils/exports/Function';
import { ITicketData } from '../../utils/exports/Interface';
import TicketDetails from '../TicketDetails';
import striptags from 'striptags';

const TicketsTable = () => {
  const { data } = useContext(DataContext);

  const { count } = useContext(CountContext);

  const { page, setPage } = useContext(PageContext);

  const { pageSize, setPageSize } = useContext(PageSizeContext);

  const [open, setOpen] = useState(false);

  const [selectedRow, setSelectedRow] = useState<GridRowParams<any>>();

  const getTickets = (ticket: ITicketData) => {
    return ticket?.attributes;
  };

  const getUrl = (ticket: { attributes: { url: string } }) => {
    return ticket.attributes.url;
  };

  const tickets = data?.length > 0 ? data.map(getTickets) : '';

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
        color: 'white',
        height: '24px',
        width: '24px',
        fontSize: '12px',
        '&.MuiAvatar-root': {
          border: 'none',
        },
      },
      children:
        name?.split(' ')?.length > 1
          ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
          : `${name.split(' ')[0][0]}`,
    };
  };

  const columns: GridColDef[] = [
    {
      field: 'sn',
      renderHeader: () => {
        return (
          <Typography fontSize='12px' fontWeight='bold'>
            SN
          </Typography>
        );
      },
      headerAlign: 'center',
      align: 'center',
      // width: 50,
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <Typography fontSize='12px'>
          {page * pageSize + params.api.getAllRowIds().indexOf(params.row.ticket_id) + 1}
        </Typography>
      ),
    },
    {
      field: 'ticket_id',
      renderHeader: () => {
        return (
          <Box display='flex' alignItems='center'>
            <Typography fontSize='12px' fontWeight='bold'>
              Ticket ID
            </Typography>
            <ImportExportIcon sx={{ width: '20px', height: '20px' }} />
          </Box>
        );
      },
      headerAlign: 'center',
      // width: 100,
      flex: 1.25,
      align: 'center',
      editable: false,
      renderCell: (rowData) => {
        return (
          <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {rowData?.row?.tenantwise_id}
          </Box>
        );
      },
    },
    {
      field: 'description',
      renderHeader: () => {
        return (
          <Typography fontSize='12px' fontWeight='bold'>
            Description
          </Typography>
        );
      },
      // width: 330,
      flex: 6.5,
      editable: false,
      sortable: false,
      renderCell: (row) => {
        const url =
          row?.row?.attachment?.data?.length > 0
            ? row.row.attachment.data.map(getUrl)
            : 'undefined';

        const getImages = (ticket: { attributes: { url: string } }) => {
          return `${process.env.REACT_APP_strapiImage}${ticket.attributes.url}`;
        };

        const images =
          row?.row?.attachment?.data?.length > 0 ? row.row.attachment.data.map(getImages) : '';
        return (
          <Box
            display='flex'
            alignItems='center'
            gap='5px'
            justifyContent='space-between'
            width='100%'
          >
            <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              <span
                className='content-wrapper'
                dangerouslySetInnerHTML={{
                  __html: striptags(row?.row?.description),
                }}
              />
            </Box>
            {url != 'undefined' && (
              <ViewAttachments url={url} images={images} name={row?.row?.ticket_id} />
            )}
          </Box>
        );
      },
    },
    // {
    //   field: 'attachment',
    //   renderHeader: () => {
    //     // return <AttachFileIcon sx={{ width: '16px', height: '16px' }} />;
    //     return null;
    //   },
    //   headerAlign: 'center',
    //   // width: 50,
    //   flex: 0.5,
    //   editable: false,
    //   sortable: false,
    //   align: 'center',
    //   renderCell: (row) => {
    //     const url =
    //       row?.row?.attachment?.data?.length > 0
    //         ? row.row.attachment.data.map(getUrl)
    //         : 'undefined';

    //     const getImages = (ticket: { attributes: { url: string } }) => {
    //       return `${process.env.REACT_APP_strapiImage}${ticket.attributes.url}`;
    //     };

    //     const images =
    //       row?.row?.attachment?.data?.length > 0 ? row.row.attachment.data.map(getImages) : '';

    //     return (
    //       <>
    //         {url != 'undefined' ? (
    //           <ViewAttachments url={url} images={images} name={row?.row?.ticket_id} />
    //         ) : (
    //           <Box display='flex' justifyContent='center' alignItems='center'>
    //             <Typography align='center'>-</Typography>
    //           </Box>
    //         )}

    //         {/* <Attachments url={url} images={images} data={row.row} table={true} /> */}
    //       </>
    //     );
    //   },
    // },
    {
      field: 'createdBy',
      renderHeader: () => {
        return (
          <Typography fontSize='12px' fontWeight='bold'>
            By
          </Typography>
        );
      },
      headerAlign: 'center',
      // width: 170,
      flex: 0.5,
      editable: false,
      sortable: false,
      align: 'center',
      renderCell: (row) => {
        return (
          <>
            {row.row.creator ? (
              <Avatar {...stringAvatar(row.row.creator)} />
            ) : (
              <Box display='flex' justifyContent='center' alignItems='center'>
                <Typography align='center'>-</Typography>
              </Box>
            )}
          </>
        );
      },
    },

    {
      field: 'assignees',
      renderHeader: () => {
        // return <Typography fontWeight='bold'>Assignee(s)</Typography>;
        return <FilterAssignees />;
      },
      headerAlign: 'center',
      align: 'center',
      // width: 100,
      flex: 1.25,
      editable: false,
      sortable: false,
      renderCell: (row) => {
        const id = row.row.ticket_id != null ? row.row.ticket_id.slice(4) : 'undefined';
        const idToChange = parseInt(id);
        // if (!customer) {
        // return (
        //   row.row.assignees === null || row?.row?.assignees?.length === 0
        //     ?
        //     <Box display='flex' justifyContent='center' alignItems='center'>
        //       <Typography align='center'>-</Typography>
        //     </Box>
        //     :
        //     <Typography display='flex' flexDirection='column' alignItems='center'>
        //       {row.row.assignees.map((assignee: string) => {
        //         if (assignee?.substring(0, 8) === 'customer') {
        //           return (
        //             <Box key={assignee} fontSize='12px'>
        //               {assignee.split(' ')[0].slice(8)}
        //             </Box>
        //           )
        //         } else {
        //           return (
        //             <Box key={assignee} fontSize='12px'>
        //               {assignee.split(' ')[0]}
        //             </Box>
        //           )
        //         }
        //       })}
        //     </Typography>
        //   );
        // } else {
        //   return (
        //     row.row.assignees === null || row?.row?.assignees?.length === 0
        //       ?
        //       <Box display='flex' justifyContent='center' alignItems='center'>
        //         <Typography align='center'>-</Typography>
        //       </Box>
        //       : row?.row?.assignees?.filter((assignee: string) => assignee?.substring(0, 8) === 'customer')?.length === 0
        //         ?
        //         <Box display='flex' justifyContent='center' alignItems='center'>
        //           <Typography align='center'>-</Typography>
        //         </Box>
        //         :
        //         < Typography display='flex' flexDirection='column' alignItems='center' >
        //           {
        //             row.row.assignees.filter((assignee: string) => assignee?.substring(0, 8) === 'customer')?.map((assignee: string) => {
        //               return (
        //                 <Box key={assignee}>
        //                   {assignee.split(' ')[0].slice(8)}
        //                 </Box>
        //               )
        //             })
        //           }
        //         </Typography >
        // )
        return (
          <>
            <AssignDeveloper
              assignees={row.row.assignees}
              idToChange={idToChange}
              main_tenant={row.row.main_tenant}
              newTicket={false}
              setAssignees={undefined}
              table={true}
            />
          </>
        );
      },
    },
    {
      field: 'type',
      renderHeader: () => {
        // return <Typography fontWeight='bold'>Type</Typography>;
        return <FilterType />;
      },
      headerAlign: 'center',
      align: 'center',
      // width: 100,
      flex: 1.25,
      editable: false,
      sortable: false,
      renderCell: (row) => {
        const type = row?.row?.type?.length > 0 ? row.row.type : 'undefined';
        const id = row.row.ticket_id != null ? row.row.ticket_id.slice(4) : 'undefined';
        const idToChange = parseInt(id);
        return (
          // <Button onClick={() => { handleTypeOpen(); handleTypeToChange(idToChange); }} sx={{ p: '0px', b: '0px' }}>
          //     <Pills pill={type} />
          // </Button>

          <EditType
            tenant={row.row.main_tenant}
            fullId={row.row.ticket_id}
            idToChange={idToChange}
            type={type}
          />
        );
      },
    },
    {
      field: 'priority',
      renderHeader: () => {
        // return <Typography fontWeight='bold'>Priority</Typography>;
        return <FilterPriority />;
      },
      headerAlign: 'center',
      align: 'center',
      // width: 110,
      flex: 1.25,
      editable: false,
      sortable: false,
      renderCell: (row) => {
        const priority = row?.row?.priority?.length > 0 ? row.row.priority : 'undefined';
        const id = row.row.ticket_id != null ? row.row.ticket_id.slice(4) : 'undefined';
        const idToChange = parseInt(id);
        return (
          // <Button onClick={() => { handlePriorityOpen(); handlePriorityToChange(idToChange); }} sx={{ p: '0px', b: '0px' }}>
          //     <Pills pill={priority} />
          // </Button>

          <EditPriority
            tenant={row.row.main_tenant}
            fullId={row.row.ticket_id}
            idToChange={idToChange}
            priority={priority}
          />
        );
      },
    },
    {
      field: 'status',
      renderHeader: () => {
        // return <Typography fontWeight='bold'>Status</Typography>;
        return <FilterStatus />;
      },
      headerAlign: 'center',
      align: 'center',
      // width: 110,
      flex: 1.25,
      editable: false,
      sortable: false,
      renderCell: (row) => {
        const status = row?.row?.status?.length > 0 ? row.row.status : 'undefined';
        const id = row.row.ticket_id != null ? row.row.ticket_id.slice(4) : 'undefined';
        const idToChange = parseInt(id);
        return (
          <>
            {/* {admin || superAdmin */}
            {/* ?  */}

            <EditStatus
              dragDrop={false}
              tenant={row.row.main_tenant}
              branch={row.row.branch}
              type={row.row.type}
              fullId={row.row.ticket_id}
              assignees={row.row.assignees}
              dueDate={row.row.due_date}
              idToChange={idToChange}
              status={status}
            />

            {/* : <Button disabled sx={{ p: '0px', b: '0px' }}>
                                <Pills pill={status} />
                            </Button>
                        } */}
          </>
        );
      },
    },
    {
      field: 'environment',
      renderHeader: () => {
        // return <Typography fontSize='14px' fontWeight='bold'>Environment</Typography>;
        return <FilterEnvironment />;
      },
      headerAlign: 'center',
      align: 'center',
      // width: 110,
      flex: 1.25,
      editable: false,
      sortable: false,
      renderCell: (row) => {
        const id = row.row.ticket_id != null ? row.row.ticket_id.slice(4) : 'undefined';
        const idToChange = parseInt(id);
        return (
          <>
            <EditEnvironment
              tenant={row.row.main_tenant}
              environment={row.row.environment}
              fullId={row.row.ticket_id}
              idToChange={idToChange}
            />
          </>
        );
      },
    },
    {
      field: 'due_date',
      renderHeader: () => {
        return (
          // <Box display='flex' alignItems='center'>
          //   <Typography fontSize='12px' fontWeight='bold'>
          //     Due Date
          //   </Typography>
          //   <ImportExportIcon sx={{ width: '20px', height: '20px' }} />
          // </Box>

          <Box display='flex' alignItems='center'>
            <FilterDueDate />
            <ClearFilter />
          </Box>
        );
      },
      headerAlign: 'center',
      align: 'center',
      // width: 110,
      flex: 1.5,
      editable: false,
      sortable: false,
      renderCell: (row) => {
        if (row?.row?.due_date !== null && row?.row?.due_date !== undefined) {
          return (
            // <Typography>{new Date(row.row.due_date).toLocaleDateString('en-GB')}</Typography>
            <EditDueDate
              table={true}
              defaultDate={row.row.due_date}
              selectedRowId={row.row.ticket_id}
            />
          );
        } else {
          return (
            <DueDatePicker selectedRowId={row.row.ticket_id} tenantwiseId={row.row.tenantwise_id} />
          );
        }
      },
    },
    // {
    //   field: 'forum',
    //   renderHeader: () => <ClearFilter />,
    //   headerAlign: 'center',
    //   headerName: '',
    //   flex: 0.5,
    //   align: 'center',
    //   editable: false,
    //   sortable: false,
    //   renderCell: (rowData) => {
    //     return <TableIcons selectedRowId={rowData.row.ticket_id} data={data} />;
    //   },
    // },
  ];

  // const columnVisibilityModel = useMemo(() => {
  //   if (admin || superAdmin || developer) {
  //     return {
  //       assignees: true,
  //     };
  //   }
  //   return {
  //     assignees: false
  //   };
  // }, [admin, superAdmin, developer]);

  return (
    <>
      <Box sx={{ bgcolor: 'white', mt: '3px', height: 'calc(100dvh - 100px)', width: '100%' }}>
        <DataGrid
          rows={tickets}
          columns={columns}
          // columnVisibilityModel={columnVisibilityModel}
          rowHeight={40}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[25, 50, 75, 100]}
          paginationMode='server'
          disableColumnMenu
          sx={{
            border: 'none',
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              width: '0.4em',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders':
              {
                borderColor: 'darkgray',
                borderWidth: '1px',
              },
            '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
          getRowId={(row) => row?.ticket_id}
          rowCount={count}
          disableRowSelectionOnClick
          onPaginationModelChange={(row) => {
            setPageSize(row.pageSize);
            setPage(row.page);
          }}
          onRowClick={(row) => {
            setOpen(true);
            setSelectedRow(row);
          }}
          getRowClassName={(row) => {
            if (
              (row?.row?.status === 'backlog' ||
                row?.row?.status === 'on hold' ||
                row?.row?.status === 'todo' ||
                row?.row?.status === 'redo' ||
                row?.row?.status === 'in progress') &&
              row?.row?.due_date !== null &&
              hasDueDatePassed(row?.row?.due_date)
            ) {
              return 'custom-row-class';
            } else {
              return '';
            }
          }}
        />
      </Box>

      <TicketDetails
        open={open}
        setOpen={setOpen}
        ticketId={selectedRow?.row?.ticket_id}
        tenantwiseId={selectedRow?.row?.tenantwise_id}
      />
    </>
  );
};

export default TicketsTable;
