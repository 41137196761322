import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { useState, useContext, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import {
  Box,
  Select,
  MenuItem,
  SelectChangeEvent,
  Dialog,
  DialogActions,
  DialogContent,
  InputLabel,
  FormControl,
  Typography,
  DialogTitle,
  Checkbox,
  Avatar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BoardContext, CurrentUserContext, TenantContext } from '../App';
import axios from 'axios';
import { AllTenantsContext, UserDataContext, UserListContext } from '../routes/Main';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { success, dynamicFailed } from '../hooks/useToast';
import { catchedTrigger } from '../utils/novu';
import { DataContext } from '../pages/TicketsPage';
import { TicketListContext } from '../pages/HomePage';
import isDeveloper from '../hooks/isDeveloper';
import AssignDeveloper from './AssignDeveloper';
import { recordLog } from '../services/recordLog';
import { getRelatedTickets } from '../services/relatedTickets';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import isMobile from '../hooks/isMobile';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertFromHTML, convertToRaw, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import FileDragAndDrop from './FileDragAndDrop';
import { IUserData } from '../utils/exports/Interface';
import { getBoards } from '../utils/api/API';
import DoneButton from './UI/DoneButton';
import isAdmin from '../hooks/isAdmin';

const CreateTicket = (props: { getBoardTickets?: (status: string) => void, fetchSuggestions?: () => void, items?: number[], setItems?: Dispatch<SetStateAction<number[]>>; }) => {
  const mobile = isMobile();

  const superAdmin = isSuperAdmin();

  const admin = isAdmin();

  const developer = isDeveloper();

  const { userData } = useContext(UserDataContext);

  const { currentUser } = useContext(CurrentUserContext);

  const { tenant, setTenant } = useContext(TenantContext);

  const { allTenants } = useContext(AllTenantsContext);

  const { fetchTickets } = useContext(DataContext);

  const { fetchTicketsHomePage } = useContext(TicketListContext);

  const { board, setBoard } = useContext(BoardContext);

  const [open, setOpen] = useState(false);

  const [type, setType] = useState('task');
  const [priority, setPriority] = useState('high');
  const [environment, setEnvironment] = useState('Sandbox');
  // const [description, setDescription] = useState('');
  const [assignees, setAssignees] = useState<string[] | null>(
    developer ? [userData?.data?.name] : null,
  );
  const [files, setFiles] = useState<any>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);

  const [ticketId, setTicketId] = useState('');

  const [createTicketTenant, setCreateTicketTenant] = useState<string>();
  const [createTicketBoard, setCreateTicketBoard] = useState<number[]>([0]);

  const [editedValue, setEditedValue] = useState<EditorState>(EditorState.createEmpty());

  useEffect(() => {
    board[0] !== 'all' ? setCreateTicketBoard(board) : setCreateTicketBoard([0]);
  }, [board]);

  useEffect(() => {
    superAdmin || developer
      ? setCreateTicketTenant(tenant === 'all' ? null : tenant)
      : setCreateTicketTenant(userData?.data?.organizations[0]?.alias);
  }, [tenant, userData, superAdmin, developer]);

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  const handlePriorityChange = (event: SelectChangeEvent) => {
    setPriority(event.target.value as string);
  };

  const handleEnvironmentChange = (event: SelectChangeEvent) => {
    setEnvironment(event.target.value as string);
  };

  useEffect(() => {
    if (superAdmin || developer) {
      setTicketId(
        allTenants?.data?.tenants?.filter(
          (tenant1: { id: string }) => tenant1?.id === createTicketTenant,
        )[0]?.organization?.ticket_id,
      );
    } else {
      setTicketId(
        allTenants?.data?.tenants?.filter(
          (tenant1: { id: string }) => tenant1?.id === userData?.data?.organizations[0]?.alias,
        )[0]?.organization?.ticket_id,
      );
    }
  }, [allTenants, tenant, createTicketTenant]);

  const handleClose = () => {
    setOpen(false);
    setMergeOpen(false);
    // setDescription('');
    setPriority('high');
    setType('task');
    setFiles('');
    setEnvironment('Sandbox');
    setAssignees(null);
    setEditedValue(EditorState.createEmpty());
    setFileNames([]);
  };

  const { userList } = useContext(UserListContext);

  const [relatedUsers, setRelatedUsers] = useState<any>();

  useEffect(() => {
    const users = userList?.data?.users?.users?.filter(
      (user: IUserData) =>
        user?.organizations?.some(
          (obj: { alias: string }) =>
            obj.alias ===
            (superAdmin || developer
              ? createTicketTenant
              : userData !== undefined && userData.data.main_tenant),
        ) || user.roles[0].name === 'sa',
    );
    const notifUsers = users?.map((user: IUserData) => {
      return {
        subscriberId: JSON.stringify(user.id),
      };
    });
    setRelatedUsers(notifUsers);
  }, [userList, superAdmin, developer, createTicketTenant, userData]);

  const [mergeOpen, setMergeOpen] = useState(false);

  const [relatedDescriptions, setRelatedDescriptions] = useState<any>([]);

  const relatedTicketsCheck = () => {
    const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

    axios
      .get(
        `${
          process.env.REACT_APP_strapiUrl
        }/tickets?pagination[pageSize]=999&filters[main_tenant][$eq]=${
          superAdmin || developer
            ? createTicketTenant
            : userData !== undefined && userData.data.organizations[0]?.alias
        }`,
        {
          headers: headers,
        },
      )
      .then((res) => {
        const descriptions = res?.data?.data?.filter(
          (ticket: any) =>
            ticket?.attributes?.status === 'backlog' ||
            ticket?.attributes?.status === 'todo' ||
            ticket?.attributes?.status === 'on hold' ||
            ticket?.attributes?.status === 'redo' ||
            ticket?.attributes?.status === 'in progress',
        );
        const related = getRelatedTickets(editedValue, descriptions);
        if (related?.length > 0) {
          setMergeOpen(true);
          setRelatedDescriptions(related);
        } else {
          createTicket();
        }
        setFileNames([]);
      })
      .catch(() => dynamicFailed('Server Error!'));
  };

  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

  const createTicket = async () => {
    if (editedValue) {
      const contentState = editedValue.getCurrentContent();
      const plainText = contentState.getPlainText().trim();
      if (plainText.length === 0) {
        dynamicFailed('Description is required.');
        return;
      }
      const rawContentState = convertToRaw(editedValue.getCurrentContent());
      const descriptionHtml = draftToHtml(rawContentState);

      const formData = new FormData();

      Array.from(files).forEach((file: any) => {
        formData.append('files', file);
        formData.append('ref', 'ticket');
        formData.append('field', 'attachment');
      });

      axios
        .get(
          `${process.env.REACT_APP_strapiUrl}/tickets?pagination[pageSize]=1&filters[main_tenant][$eq]=${createTicketTenant}&sort=createdAt:desc`,
          {
            headers: headers,
          },
        )
        .then((res) => {
          const tenantwiseId = `${ticketId?.toUpperCase()}-${
            res?.data?.data[0]?.attributes?.tenantwise_id
              ? parseInt(res?.data?.data[0]?.attributes?.tenantwise_id?.split('-')[1]) + 1
              : 1
          }`;
          axios
            .post(`${process.env.REACT_APP_strapiUrl}/upload`, formData, {
              headers: headers,
            })
            .then((response) => {
              const attachments: any = [];
              response.data.map((imageId: { id: number }) => {
                attachments.push(imageId.id);
              });
              const ticketInfo = {
                data: {
                  description: descriptionHtml,
                  creator: userData !== undefined && userData.data.name,
                  type: type,
                  priority: priority,
                  status: 'backlog',
                  environment: environment,
                  tenantwise_id: tenantwiseId,
                  main_tenant:
                    superAdmin || developer
                      ? createTicketTenant
                      : userData !== undefined && userData.data.organizations[0]?.alias,
                  attachment: attachments,
                  assignees: assignees,
                  boards: createTicketBoard?.filter((board) => board !== 0),
                  default_board: createTicketBoard?.includes(0),
                },
              };
              createTicketService(ticketInfo);
            })
            .catch(() => {
              const ticketInfo = {
                data: {
                  description: descriptionHtml,
                  creator: userData !== undefined && userData.data.name,
                  type: type,
                  priority: priority,
                  environment: environment,
                  tenantwise_id: tenantwiseId,
                  main_tenant:
                    superAdmin || developer
                      ? createTicketTenant
                      : userData !== undefined && userData.data.organizations[0]?.alias,
                  status: 'backlog',
                  assignees: assignees,
                  boards: createTicketBoard?.filter((board) => board !== 0),
                  default_board: createTicketBoard?.includes(0),
                },
              };
              createTicketService(ticketInfo);
            });
        })
        .catch(() => dynamicFailed('Server Error!'));

      handleClose();
    }
  };

  const createTicketService = async (ticketInfo: {
    data: {
      description: string;
      creator: string;
      type: string;
      priority: string;
      environment: string;
      tenantwise_id: string;
      main_tenant: string;
      status: string;
      assignees: string[] | null;
    };
  }) => {
    axios
      .post(`${process.env.REACT_APP_strapiUrl}/tickets`, ticketInfo, {
        headers: headers,
      })
      .then((response) => {
        // if (response.status === 200) {
        //   await catchedTrigger('create-ticket', {
        //     // to: {
        //     //   subscriberId: '63d7751916e379fe65f29506'
        //     // },
        //     to: relatedUsers,
        //     payload: {
        //       desc: response?.data?.data?.attributes?.description
        //     }
        //   })
        // }

        // const idInfo = {
        //   ticket_id: `${createTicketTenant?.slice(0, 3).toUpperCase()}-` + response.data.data.id
        // }

        const idInfo = {
          ticket_id: `${ticketId?.slice(0, 3).toUpperCase()}-` + response.data.data.id,
        };

        const message = {
          message: response.data.data.attributes.description,
          sender: userData !== undefined && userData.data.name,
          ticket: response.data.data.id,
          admin: (superAdmin || developer) && true,
          changed_to: 'Description',
        };
        putId(response.data.data.id, response.data.data.attributes.description, idInfo);
        firstMessage(message);
      })
      .catch((err) => {
        dynamicFailed(
          err.response.status === 400
            ? 'Please make sure you have entered all the fields correctly.'
            : 'Server Error!',
        );
      });
  };

  const firstMessage = async (message: {
    message: string;
    sender: string;
    ticket: number;
    admin: boolean;
  }) => {
    await fetch(`${process.env.REACT_APP_strapiUrl}/chats`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: message }),
    });
    // const addMessage = await add.json()
    // console.log(addMessage);
  };

  const putId = async (id: number, description: string, idInfo: object) => {
    axios
      .put(
        `${process.env.REACT_APP_strapiUrl}/tickets/${id}`,
        { data: idInfo },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then(async (response) => {
        recordLog(
          `Create`,
          description,
          'ticket',
          response?.data?.data?.attributes?.ticket_id,
          currentUser?.id,
          response?.data?.data?.attributes?.tenantwise_id,
        );
        if (response.status === 200) {
          await catchedTrigger('create-ticket', {
            // to: {
            //   subscriberId: '63d7751916e379fe65f29506'
            // },
            to: relatedUsers,
            payload: {
              ticket: response?.data?.data?.attributes?.ticket_id,
              user: userData?.data?.name,
              description: description,
            },
          });
        }
        props.fetchSuggestions && props.fetchSuggestions();
        props?.setItems && 
          props?.items &&
          props.setItems([...props.items, response.data.data.id]);
        success();
        location?.pathname === '/overview' ? fetchTicketsHomePage() : location?.pathname ==='/tickets'? fetchTickets() : null;
        (superAdmin || developer) && setTenant(createTicketTenant);
        setBoard(createTicketBoard);
        if (props.getBoardTickets) {
          props.getBoardTickets('Backlog');
        }
      })
      .catch(() => {
        dynamicFailed('Ticket ID could not be updated.');
      });
  };

  const mergeTicket = (idToMerge: number) => {
    if (editedValue) {
      const rawContentState = convertToRaw(editedValue.getCurrentContent());
      const descriptionHtml = draftToHtml(rawContentState);
      const data = {
        data: {
          merged_ticket: descriptionHtml,
        },
      };

      axios
        .put(`${process.env.REACT_APP_strapiUrl}/tickets/${idToMerge}`, data, {
          headers: headers,
        })
        .then(() => {
          success();
          handleClose();
          location?.pathname === '/overview' ? fetchTicketsHomePage() : fetchTickets();
          if (props.getBoardTickets) {
            props.getBoardTickets('Backlog');
          }
        })
        .catch((err) => {
          dynamicFailed(
            err.response.status === 400
              ? 'Please make sure you have entered all the fields correctly.'
              : 'Server Error!',
          );
          handleClose();
        });
    }
  };

  const [currentTenant, setCurrentTenant] = useState<{ organization: { name: string } }>();
  const [boards, setBoards] = useState<[{ attributes: { name: string }; id: number }]>();

  const getCurrentTenant = () => {
    setCurrentTenant(
      allTenants?.data?.tenants?.filter(
        (org: { organization: { alias: string } }) => org.organization.alias === createTicketTenant,
      )[0],
    );
  };

  useEffect(() => {
    getCurrentTenant();
  }, [tenant, createTicketTenant, allTenants]);

  // const getBoards = () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_strapiUrl}/boards?filters[main_tenant][$eq]=${createTicketTenant}&populate=*`,
  //       {
  //         headers: headers,
  //       },
  //     )
  //     .then((res) => setBoards(res.data.data))
  //     .catch(() => failed());
  // };
  // const getBoards = () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_strapiUrl}/boards?filters[main_tenant][$eq]=${createTicketTenant}&populate=*`,
  //       {
  //         headers: headers,
  //       },
  //     )
  //     .then((res) => setBoards(res.data.data))
  //     .catch(() => failed());
  // };

  useEffect(() => {
    getBoards(createTicketTenant, setBoards, userData?.data?.id, superAdmin || admin);
  }, [createTicketTenant]);

  const handlePastedText = useCallback(
    (html: string | null) => {
      if (html) {
        const cleanedHtml = html.replace(/style="[^"]*"/g, '');
        const blocksFromHTML = convertFromHTML(cleanedHtml);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );

        const currentContent = editedValue.getCurrentContent();
        const selection = editedValue.getSelection();

        const newContentState = Modifier.replaceWithFragment(
          currentContent,
          selection,
          contentState.getBlockMap(),
        );

        const newEditorState = EditorState.push(editedValue, newContentState, 'insert-fragment');

        setEditedValue(
          EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter()),
        );
        return true;
      }
      return false;
    },
    [editedValue, setEditedValue],
  );

  const handleBoardToggle = (boardId: number) => {
    const updatedBoard = createTicketBoard?.flat().includes(boardId)
      ? createTicketBoard?.flat().filter((id: number) => id !== boardId)
      : [...createTicketBoard, boardId];
    if (updatedBoard.length === 0) {
      dynamicFailed('Board selection cannot be empty.');
      return;
    }
    setCreateTicketBoard(updatedBoard);
  };

  const getSelectedItems = () => {
    const selectedItems = createTicketBoard?.flat().map((id: number) => {
      if (id === 0) return currentTenant?.organization?.name;
      const selectedBoard = boards?.find((b) => b.id === id);
      return selectedBoard?.attributes?.name || '';
    });
    return selectedItems;
  };

  return (
    <>
      <AddCircleIcon
        onClick={() => {
          getBoards(createTicketTenant, setBoards, userData?.data?.id, superAdmin || admin);
          setOpen(true);
        }}
        sx={{ width: '28px', height: '28px', color: 'black', cursor: 'pointer' }}
      />

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogContent>
          <Box display='flex' justifyContent='space-between' alignItems='center' mb='15px'>
            <Box display='flex' alignItems='center' gap='8px'>
              <ConfirmationNumberIcon />
              <Typography variant='h6' fontWeight='bold'>
                {mobile ? 'Create' : 'Create Ticket'}
              </Typography>
              {(superAdmin || developer) && (
                <Box>
                  <FormControl sx={{ minWidth: 100 }} size='small'>
                    <InputLabel>Tenant*</InputLabel>
                    <Select
                      label='Tenant*'
                      value={createTicketTenant}
                      aria-label='tenant'
                      onChange={(event) => {
                        setCreateTicketTenant(event.target.value);
                      }}
                    >
                      {developer
                        ? userData?.data?.organizations
                            ?.filter((tenant: any) => tenant?.sts_link === 1)
                            ?.map((tenant: any) => {
                              return (
                                <MenuItem
                                  aria-label='ticket_id'
                                  value={tenant?.alias}
                                  key={tenant?.alias}
                                >
                                  {tenant?.ticket_id?.toUpperCase()}
                                </MenuItem>
                              );
                            })
                        : allTenants?.data?.tenants
                            .filter((tenant: any) => tenant.organization.sts_link === 1)
                            .map(
                              (tenant: {
                                organization: { alias: string; ticket_id: string };
                                id: string;
                              }) => {
                                return (
                                  <MenuItem
                                    aria-label='ticket_id'
                                    value={tenant?.organization?.alias}
                                    key={tenant.id}
                                  >
                                    {tenant?.organization?.ticket_id?.toUpperCase()}
                                  </MenuItem>
                                );
                              },
                            )}
                    </Select>
                  </FormControl>
                </Box>
              )}

              <Box>
                <FormControl sx={{ minWidth: 100, maxWidth: 200 }} size='small'>
                  <InputLabel>Board*</InputLabel>
                  <Select
                    multiple
                    label='Board*'
                    aria-label='board'
                    value={createTicketBoard}
                    disabled={createTicketTenant === null ? true : false}
                    // onChange={(event) => {
                    //   setCreateTicketBoard(event.target.value as number[]);
                    // }}
                    renderValue={() => {
                      const selectedItems = getSelectedItems();
                      const selectedCount = selectedItems.length;
                      return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            noWrap
                            variant='body2'
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {selectedItems.join(', ')}
                          </Typography>
                          {selectedCount > 1 && (
                            <Avatar
                              sx={{
                                backgroundColor: 'lightgray',
                                color: 'gray',
                                width: '20px',
                                height: '20px',
                                fontSize: '12px',
                              }}
                            >
                              {selectedCount}
                            </Avatar>
                          )}
                        </Box>
                      );
                    }}
                  >
                    {/* <MenuItem value={0}> */}
                    <Box display='flex' alignItems='center' padding='2px 8px 2px 2px'>
                      <Checkbox
                        aria-label='organization-name'
                        checked={createTicketBoard?.flat().includes(0)}
                        onChange={() => handleBoardToggle(0)}
                        size='small'
                      />
                      {currentTenant?.organization?.name}
                    </Box>
                    {/* </MenuItem> */}
                    {boards?.map((board) => {
                      return (
                        // <MenuItem key={board?.id} value={board?.id}>
                        <Box
                          display='flex'
                          alignItems='center'
                          padding='2px 8px 2px 2px'
                          key={board.id}
                        >
                          <Checkbox
                            aria-label='attributes-name'
                            checked={createTicketBoard?.includes(board.id)}
                            onChange={() => handleBoardToggle(board.id)}
                            size='small'
                          />
                          {board?.attributes?.name}
                        </Box>
                        // </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <DialogActions sx={{ p: 0 }}>
              <CloseIcon onClick={handleClose} cursor='pointer' />
            </DialogActions>
          </Box>
          <Box display='flex' flexDirection='column' gap='10px'>
            {/* <TextField
                            id="outlined-multiline-static"
                            label="Description*"
                            multiline
                            fullWidth
                            rows={8}
                            onChange={e => setDescription(e.target.value)}
                            value={description}
                        /> */}
            <Editor
              editorState={editedValue}
              wrapperClassName='ticket-wrapper'
              editorClassName='ticket-editor'
              onEditorStateChange={setEditedValue}
              handlePastedText={handlePastedText}
              toolbar={{
                options: ['inline', 'link', 'history'],
                inline: {
                  inDropdown: false,
                  options: ['bold', 'italic', 'underline'],
                },
                link: {
                  inDropdown: false,
                  options: ['link'],
                },
                history: {
                  inDropdown: false,
                  options: ['undo', 'redo'],
                },
              }}
            />
            <Box display='flex' gap='10px'>
              <FormControl fullWidth size='small'>
                <InputLabel id='demo-simple-select-label'>Type*</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={type}
                  label='Type*'
                  aria-label='type-select'
                  onChange={handleTypeChange}
                >
                  <MenuItem aria-label='bug' value='bug'>
                    Bug
                  </MenuItem>
                  <MenuItem aria-label='task' value='task'>
                    Task
                  </MenuItem>
                  <MenuItem aria-label='feature' value='feature'>
                    Feature
                  </MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth size='small'>
                <InputLabel id='demo-simple-select-label'>Priority*</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={priority}
                  label='Priority*'
                  aria-label='priority-select'
                  onChange={handlePriorityChange}
                >
                  <MenuItem aria-label='highest' value='highest'>
                    Highest
                  </MenuItem>
                  <MenuItem aria-label='high' value='high'>
                    High
                  </MenuItem>
                  <MenuItem aria-label='medium' value='medium'>
                    Medium
                  </MenuItem>
                  <MenuItem aria-label='low' value='low'>
                    Low
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box display='flex' gap='10px' alignItems='center'>
              <Box width='calc(50% - 5px)'>
                <FormControl fullWidth size='small'>
                  <InputLabel id='demo-simple-select-label'>Environment*</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={environment}
                    label='Environment*'
                    aria-label='environment-select'
                    onChange={handleEnvironmentChange}
                  >
                    <MenuItem aria-label='sandbox' value='Sandbox'>
                      Sandbox
                    </MenuItem>
                    <MenuItem aria-label='uat' value='UAT'>
                      UAT
                    </MenuItem>
                    <MenuItem aria-label='production' value='Production'>
                      Production
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box ml='5px'>
                <AssignDeveloper
                  idToChange=''
                  assignees={assignees}
                  setAssignees={setAssignees}
                  main_tenant={createTicketTenant !== undefined ? createTicketTenant : ''}
                  newTicket={true}
                />
              </Box>
            </Box>

            <FileDragAndDrop
              setFiles={setFiles}
              fileNames={fileNames}
              setFileNames={setFileNames}
            />

            <DoneButton text='Create' onClick={relatedTicketsCheck} mt='5px' mx='auto' />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog maxWidth='md' fullWidth open={mergeOpen} onClose={() => setMergeOpen(false)}>
        <DialogTitle>Related Ticket(s)</DialogTitle>
        <DialogContent>
          <Box display='flex' flexDirection='column' gap='10px'>
            {relatedDescriptions?.map((ticket: any) => {
              return (
                <Box key={ticket?.id} display='flex' justifyContent='space-between'>
                  <Typography width='80px'>{ticket?.attributes?.ticket_id}</Typography>
                  <Typography
                    overflow='hidden'
                    flex='1'
                    mr='10px'
                    dangerouslySetInnerHTML={{
                      __html: ticket?.boldedDescription // Use bolded description here
                        .replace(/<p>/g, '<span>')
                        .replace(/<\/p>/g, '</span>'),
                    }}
                  />
                  <Typography
                    onClick={() => mergeTicket(ticket?.id)}
                    color='blue'
                    sx={{ cursor: 'pointer' }}
                  >
                    Merge
                  </Typography>
                </Box>
              );
            })}

            <DoneButton
              text='Create New Ticket'
              onClick={createTicket}
              width='150px'
              mt='5px'
              mx='auto'
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateTicket;
