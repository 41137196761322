import TicketsAccordion from '../../components/Mobile/TicketsAccordion';
import { Box, FormControl, Input, InputAdornment, Button } from '@mui/material';
import { useContext, useState } from 'react';
import Filter from '../../components/Filter';
import { DataContext } from '../../pages/TicketsPage';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { MeiliSearch } from 'meilisearch';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from '@mui/material/styles';
import { UserDataContext } from '../../routes/Main';
import OverviewHeader from '../../components/Home Page/OverviewHeader';
import { TenantContext } from '../../App';

const MobileTicketsPageLayout = () => {
  const { tenant } = useContext(TenantContext);

  const { userData } = useContext(UserDataContext);

  const theme = useTheme();

  const [searchToggle, setSearchToggle] = useState(false);

  const toggleSearch = () => {
    searchToggle == true ? setSearchToggle(false) : setSearchToggle(true);
  };

  const [searchText, setSearchText] = useState<string>();

  const { data, setData } = useContext(DataContext);

  const client = new MeiliSearch({
    host: 'http://localhost:7700/',
  });

  const searchTickets = async () => {
    client.index('ticket').updateFilterableAttributes(['main_tenant']);

    client
      .index('ticket')
      .search(searchText, {
        filter: `main_tenant = ${userData !== undefined && userData.data.main_tenant}`,
      })
      .then((results) => {
        const finalResult: any = [];
        results.hits.map((hit) => {
          hit.attachment != null
            ? (hit.attachment = {
                data: hit.attachment.map((item: any) => {
                  return { attributes: item };
                }),
              })
            : '';
          hit.chats != null
            ? (hit.chats = {
                data: hit.chats.map((item: any) => {
                  return { attributes: item };
                }),
              })
            : '';
          const finalResults = {
            attributes: hit,
          };
          finalResult.push(finalResults);
        });
        setData(finalResult);
        setSearchText('');
      });
  };

  const clearSearch = () => {
    fetch(`${process.env.REACT_APP_strapiUrl}/tickets?populate=*&sort=createdAt%3Adesc`)
      .then((resp) => resp.json())
      .then((apiData) => {
        setData(apiData.data);
      });
    setSearchText('');
  };

  return (
    <>
      <Box mx='15px' display='flex' flexDirection='column'>
        <Box sx={{ backgroundColor: theme.palette.background.default }} zIndex={1}>
          <OverviewHeader />
        </Box>
        {searchToggle == false ? (
          <Box
            display='flex'
            position='fixed'
            width='93%'
            justifyContent='space-between'
            mt={tenant === 'all' ? '60px' : '95px'}
            ml='-2px'
            gap='5px'
            alignItems='center'
            zIndex='1'
            sx={{ backgroundColor: theme.palette.background.default }}
          >
            <Filter />
            <SearchIcon onClick={toggleSearch} />
          </Box>
        ) : (
          <Box
            display='flex'
            position='fixed'
            width='92%'
            justifyContent='space-between'
            mt={tenant === 'all' ? '60px' : '95px'}
            gap='5px'
            alignItems='center'
            zIndex='1'
            sx={{ backgroundColor: theme.palette.background.default }}
          >
            <FormControl fullWidth sx={{ my: 1 }} variant='standard'>
              <Input
                startAdornment={
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                }
                size='small'
                placeholder='Search...'
                aria-label='select-file'
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    searchTickets();
                    e.preventDefault();
                  }
                }}
                value={searchText}
              />
            </FormControl>
            <Box display='flex' gap='5px' mt='-3px'>
              <Button
                aria-label='search-tickets'
                variant='contained'
                color='info'
                onClick={searchTickets}
                sx={{ minWidth: '22px', height: '22px', p: '0px' }}
              >
                <DoneIcon sx={{ width: '20px', height: '20px' }} />
              </Button>
              <Button
                aria-label='clear-search'
                variant='contained'
                color='error'
                onClick={clearSearch}
                sx={{ minWidth: '22px', height: '22px', p: '0px' }}
              >
                <ClearIcon sx={{ width: '20px', height: '20px' }} />
              </Button>
              <FilterAltIcon onClick={toggleSearch} />
            </Box>
          </Box>
        )}
        <Box mb='65px' mt={tenant === 'all' ? '105px' : '140px'} px='2px'>
          {data?.length > 0
            ? data.map((ticket: { attributes: { ticket_id: string } }) => (
                <TicketsAccordion key={ticket.attributes.ticket_id} data={ticket} apiData={data} />
              ))
            : 'No matches!'}
        </Box>
      </Box>
    </>
  );
};

export default MobileTicketsPageLayout;
