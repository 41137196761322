// import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Avatar, Box, Button, Dialog, Popover, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { UserDataContext } from '../routes/Main';
import { stringToColor } from '../utils/exports/Function';

const Account = () => {

    const { userData } = useContext(UserDataContext);

    const [accountAnchorEl, setAccountAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleAccountClick = (event: any) => {
        setAccountAnchorEl(event.currentTarget);
    };

    const handleAccountPopoverClose = () => {
        setAccountAnchorEl(null);
    };

    const accountOpen = Boolean(accountAnchorEl);

    const [logoutOpen, setLogoutOpen] = useState(false);

    const handleLogoutClose = () => {
        setLogoutOpen(false);
    };

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        window.location.href = process.env.REACT_APP_sisUrl !== undefined ? process.env.REACT_APP_sisUrl : '';
    };

    const stringAvatar = (name: string) => {
        return {
            sx: {
                bgcolor: stringToColor(name),
                height: '26px',
                width: '26px',
                fontSize: '13px',
                color: 'white',
                cursor: 'pointer',
                '&.MuiAvatar-root': {
                    border: 'none',
                },
            },
            children: name?.split(' ')?.length > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : `${name.split(' ')[0][0]}`,
        };
    }

    return (
        <>
            {/* <AccountBoxIcon onClick={handleAccountClick} sx={{ width: '26px', height: '26px', color: 'black', cursor: 'pointer' }} /> */}
            {userData &&
                <Avatar {...stringAvatar(userData?.data?.name)} onClick={handleAccountClick} />
            }

            <Popover
                open={accountOpen}
                anchorEl={accountAnchorEl}
                onClose={handleAccountPopoverClose}
                anchorOrigin={{
                    vertical: 35,
                    horizontal: 0,
                }}
            >
                <Box display='flex' flexDirection='column' p='10px' pb='12px' gap='10px'>
                    <Box borderBottom='1px solid lightgray' pb='10px'>
                        <Box px='10px' fontSize='14px'>
                            Welcome!, <span style={{ fontWeight: 'bold' }}>{userData?.data?.name}</span>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', fontSize: '14px', gap: '10px', px: '5px', cursor: 'not-allowed', justifyContent: 'start', alignItems: 'center' }}><PersonIcon sx={{ height: '22px', width: '22px' }} />Account</Box>
                    <Box sx={{ display: 'flex', fontSize: '14px', gap: '10px', px: '5px', cursor: 'pointer', justifyContent: 'start', alignItems: 'center' }} onClick={() => setLogoutOpen(true)}><LogoutIcon sx={{ height: '22px', width: '20px', ml: '2px' }} />Logout</Box>
                </Box>
            </Popover>

            <Dialog open={logoutOpen} onClose={handleLogoutClose} maxWidth='sm'>
                <Box display='flex' flexDirection='column' gap='10px' p='15px' alignItems='center'>
                    <Box display='flex' gap='3px' pr='2px'>
                        <LogoutIcon />
                        <Typography>Are you sure?</Typography>
                    </Box>
                    <Box display='flex' gap='10px'>
                        <Button size='small' variant='contained' aria-label='logout' color='error' onClick={handleLogout}>
                            Yes
                        </Button>
                        <Button size='small' variant='outlined' aria-label='logout' onClick={handleLogoutClose}>
                            No
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}

export default Account
