import { Box, Typography } from '@mui/material';
import MobileMainLayout from './MobileMainLayout';
import NotificationList from '../../components/Desktop/NotificationList';
import {
    NovuProvider,
    useNotifications
} from '@novu/notification-center';
import { useContext } from 'react';
import { UserDataContext } from '../../routes/Main';

const MobileNotificationsLayout = () => {

    const { userData } = useContext(UserDataContext);

    const CustomNotificationCenter = () => {
        const { notifications } = useNotifications();

        return (
            <>
                {notifications?.length > 0 ? notifications?.map((notification) => {
                    return (
                        <NotificationList notification={notification} key={notification} />
                    )
                }) : ''}
            </>
        );
    }

    // development:
    // const novuAppId = process.env.REACT_APP_novuAppId?.slice(1, -1);

    // production:
    // const novuAppId = process.env.REACT_APP_novuAppId;

    return (
        <>
            <MobileMainLayout>
                <Box position='fixed' bgcolor='white' top='15px' right='15px' left='15px' bottom='65px' borderRadius='15px' sx={{ overflowY: 'scroll' }}>
                    <Box position='fixed' width='90%' px='15px' py='13px' pt='15px' borderBottom='1px solid lightgray' sx={{ backgroundColor: 'white' }}>
                        <Typography variant='h6' fontWeight='bold'>Notifications</Typography>
                    </Box>

                    <Box mt='43px' p='15px'>
                        <NovuProvider
                            backendUrl={process.env.REACT_APP_novuBackendUrl}
                            subscriberId={JSON.stringify(userData?.data?.id)}
                            applicationIdentifier={process.env.REACT_APP_novuAppId !== undefined ? process.env.REACT_APP_novuAppId : ''}
                            initialFetchingStrategy={{ fetchNotifications: true }}
                        >
                            <CustomNotificationCenter />
                        </NovuProvider>
                    </Box>

                </Box>
            </MobileMainLayout>
        </>
    )
}

export default MobileNotificationsLayout
