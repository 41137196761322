import { useState, useContext, Dispatch, SetStateAction } from 'react';
import {
  Dialog,
  Box,
  Typography,
  DialogContent,
  TextField,
  InputAdornment,
  Popover,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import { CurrentUserContext, LoadingContext } from '../App';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { UserDataContext } from '../routes/Main';
import isMobile from '../hooks/isMobile';
import { DataContext } from '../pages/TicketsPage';
// import { TicketListContext } from '../pages/HomePage';
import isDeveloper from '../hooks/isDeveloper';
import { recordLog } from '../services/recordLog';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { failed } from '../hooks/useToast';
import dayjs from 'dayjs';
import { isToday, isTomorrow, isYesterday } from '../utils/exports/Function';
import { ITicketData } from '../utils/exports/Interface';

const EditDueDate = (props: {
  defaultDate: string;
  selectedRowId: string;
  table?: boolean;
  fetchTicket?: () => void;
  ticketsData?: ITicketData[] | undefined;
  setTicketsData?: Dispatch<SetStateAction<ITicketData[]>> | undefined;
}) => {
  const superAdmin = isSuperAdmin();

  const developer = isDeveloper();

  const { userData } = useContext(UserDataContext);

  const { setLoading } = useContext(LoadingContext);

  const { data, setData } = useContext(DataContext);

  const { currentUser } = useContext(CurrentUserContext);

  // const { fetchTicketsHomePage } = useContext(TicketListContext);

  const id = props.selectedRowId != null ? props.selectedRowId.slice(4) : 'undefined';
  const selectedId = parseInt(id);
  // console.log(selectedId);

  const [commentOpen, setCommentOpen] = useState(false);

  const [comment, setComment] = useState('');

  const handleCommentClose = () => {
    setCommentOpen(false);
  };

  const [date, setDate] = useState<any>(dayjs(props.defaultDate));

  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

  const handleDate = () => {
    setLoading(true);

    const dt = new Date(date);

    const year = dt.toLocaleString('default', { year: 'numeric' });
    const month = dt.toLocaleString('default', { month: '2-digit' });
    const day = dt.toLocaleString('default', { day: '2-digit' });

    const formattedDate = year + '-' + month + '-' + day;

    const dueDate = {
      data: {
        due_date: formattedDate,
      },
    };

    const messageInfo = {
      data: {
        message: `${formattedDate} | ${comment}`,
        sender: userData !== undefined && userData.data.name,
        ticket: selectedId,
        admin: (superAdmin || developer) && true,
        changed_to: 'Due Date',
      },
    };

    axios
      .put(`${process.env.REACT_APP_strapiUrl}/tickets/${selectedId}`, dueDate, {
        headers: headers,
      })
      .then(() => {
        axios
          .post(`${process.env.REACT_APP_strapiUrl}/chats`, messageInfo, {
            headers: headers,
          })
          .then(() => {
            handleCommentClose();
            handleDateClose();
            setComment('');
            if (props.fetchTicket) {
              props.fetchTicket();
            }
            const updatedTickets = data.map((ticket: any) => {
              if (ticket.id === selectedId) {
                return {
                  ...ticket,
                  attributes: {
                    ...ticket.attributes,
                    due_date: formattedDate,
                  },
                };
              }
              return ticket;
            });
            const updatedBoardTickets = props.ticketsData
              ? props.ticketsData.map((ticket: any) => {
                  if (ticket.id === selectedId) {
                    return {
                      ...ticket,
                      attributes: {
                        ...ticket.attributes,
                        due_date: formattedDate,
                      },
                    };
                  }
                  return ticket;
                })
              : [];
            setData(updatedTickets);
            props.setTicketsData && props.setTicketsData(updatedBoardTickets);
            // fetchTickets();
            setLoading(false);
          })
          .catch(() => failed());
        recordLog(
          `Due Date`,
          `Due Date Changed: ${formattedDate} | ${comment}`,
          'ticket',
          props.selectedRowId,
          currentUser?.id,
        );
      })
      .catch(() => {
        failed();
        setLoading(false);
      });
  };

  const mobile = isMobile();

  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);

  const handleDateClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateClose = () => {
    setAnchorEl(null);
  };

  const dateOpen = Boolean(anchorEl);

  const handleDateChange = (date: Date | null) => {
    setDate(date);
    setCommentOpen(true);
  };

  return (
    <>
      {/* {(superAdmin || developer) */}
      {/* ?  */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography
          fontSize={props?.table ? '12px' : '12px'}
          onClick={(e) => {
            e.stopPropagation();
            handleDateClick(e);
          }}
          sx={{ cursor: 'pointer' }}
        >
          {props.table
            ? isToday(props.defaultDate)
              ? 'Tday'
              : isTomorrow(props.defaultDate)
              ? 'Tmrw'
              : isYesterday(props.defaultDate)
              ? 'Yday'
              : new Date(props.defaultDate).toLocaleDateString('en-GB')
            : new Date(props.defaultDate).toLocaleDateString('en-GB')}
        </Typography>

        <Popover
          open={dateOpen}
          anchorEl={anchorEl}
          onClose={handleDateClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: -165,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <StaticDatePicker
            value={date}
            onChange={handleDateChange}
            disablePast
            // onAccept={() => setCommentOpen(true)}
            componentsProps={{ actionBar: { actions: [] } }}
          />
        </Popover>
      </LocalizationProvider>
      {/* : <Typography fontSize={props?.table ? '12px' : '16px'}>{new Date(props.defaultDate).toLocaleDateString('en-GB')}</Typography> */}
      {/* } */}

      <Dialog open={commentOpen} onClose={handleCommentClose} fullWidth>
        <DialogContent>
          <Box display='flex' flexDirection='column' gap='15px'>
            <Box display='flex' justifyContent='space-between'>
              <Typography fontWeight='bold'>Comment:</Typography>
              <CloseIcon onClick={handleCommentClose} cursor='pointer' />
            </Box>
            <TextField
              multiline
              fullWidth
              aria-label='comment'
              rows={4}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={{ mt: '-68px' }}>
                    <SendIcon onClick={handleDate} sx={{ cursor: 'pointer' }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setComment(e.target.value)}
              onKeyDown={(e) => {
                if (!mobile) {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    handleDate();
                    e.preventDefault();
                  }
                }
              }}
              value={comment}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditDueDate;
