import { Box, Dialog } from '@mui/material';
import { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import '../../index.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DownloadFiles from '../DownloadFiles';
import pdfIcon from '../../assets/images/pdf-icon.webp';
import docxIcon from '../../assets/images/docx-icon.webp';
import excelIcon from '../../assets/images/excel-icon.webp';
import CloseIcon from '@mui/icons-material/Close';

const ViewAttachments = (props: any) => {
  const [imageToOpen, setImageToOpen] = useState<string[]>();

  const handleImageToOpen = (images: string[]) => {
    setImageToOpen(images);
  };

  const [imageOpen, setImageOpen] = useState(false);

  const handleImageOpen = () => {
    setImageOpen(true);
  };

  const handleImageClose = () => {
    setImageOpen(false);
  };

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        onClick={(event) => {
          event.stopPropagation();
          handleImageOpen();
          handleImageToOpen(props.images);
        }}
        sx={{ cursor: 'pointer' }}
      >
        {/* <Typography color='#3751FF' fontSize='14px'>View {props.url.length} attachment(s)</Typography> */}
        <AttachFileIcon sx={{ width: '17px', height: '17px', color: '#3751FF' }} />
        <Box color='#3751FF'>{props.url.length}</Box>
      </Box>

      <Dialog open={imageOpen} onClose={handleImageClose}>
        {imageToOpen != null ? (
          <Box pt='25px' overflow='hidden'>
            <Carousel dynamicHeight showThumbs={true} thumbWidth={75}>
              {imageToOpen.map((image: string) => {
                if (image.slice(-4) === '.pdf') {
                  return (
                    <Box key={image}>
                      <img
                        src={pdfIcon}
                        alt='Image'
                        width='100%'
                        height='100%'
                        style={{ maxWidth: '1000px', maxHeight: '350px', objectFit: 'contain' }}
                      />
                      <Box
                        p='3px'
                        bgcolor='rgb(0,0,0,0.5)'
                        maxWidth='250px'
                        color='white'
                        position='absolute'
                        fontSize='10px'
                        top='5px'
                        left='5px'
                      >
                        {image?.split(`${process.env.REACT_APP_strapiImage}/uploads/`)[1]}
                      </Box>
                      <Box position='absolute' top='12px' right='35px'>
                        <DownloadFiles file={image} name={props.name} icon='expand' />
                      </Box>
                    </Box>
                  );
                } else if (image.slice(-5) === '.docx') {
                  return (
                    <Box key={image}>
                      <img
                        src={docxIcon}
                        alt='Image'
                        width='100%'
                        height='100%'
                        style={{ maxWidth: '1000px', maxHeight: '350px', objectFit: 'contain' }}
                      />
                      <Box
                        p='3px'
                        bgcolor='rgb(0,0,0,0.5)'
                        maxWidth='250px'
                        color='white'
                        position='absolute'
                        fontSize='10px'
                        top='5px'
                        left='5px'
                      >
                        {image?.split(`${process.env.REACT_APP_strapiImage}/uploads/`)[1]}
                      </Box>
                      <Box position='absolute' top='12px' right='35px'>
                        <DownloadFiles file={image} name={props.name} />
                      </Box>
                    </Box>
                  );
                } else if (image.slice(-5) === '.xlsx') {
                  return (
                    <Box key={image}>
                      <img
                        src={excelIcon}
                        alt='Image'
                        width='100%'
                        height='100%'
                        style={{ maxWidth: '1000px', maxHeight: '350px', objectFit: 'contain' }}
                      />
                      <Box
                        p='3px'
                        bgcolor='rgb(0,0,0,0.5)'
                        maxWidth='250px'
                        color='white'
                        position='absolute'
                        fontSize='10px'
                        top='5px'
                        left='5px'
                      >
                        {image?.split(`${process.env.REACT_APP_strapiImage}/uploads/`)[1]}
                      </Box>
                      <Box position='absolute' top='12px' right='35px'>
                        <DownloadFiles file={image} name={props.name} />
                      </Box>
                    </Box>
                  );
                } else {
                  return (
                    <Box key={image}>
                      <img
                        src={image}
                        alt='Image'
                        width='100%'
                        height='100%'
                        style={{ maxWidth: '1000px', maxHeight: '350px', objectFit: 'contain' }}
                      />
                      <Box
                        p='3px'
                        bgcolor='rgb(0,0,0,0.5)'
                        maxWidth='250px'
                        color='white'
                        position='absolute'
                        fontSize='10px'
                        top='5px'
                        left='5px'
                      >
                        {image?.split(`${process.env.REACT_APP_strapiImage}/uploads/`)[1]}
                      </Box>
                      <Box display='flex' gap='5px' position='absolute' top='12px' right='35px'>
                        <DownloadFiles file={image} name={props.name} icon='expand' />
                        <Box
                          p='3px 3px 0px 3px'
                          bgcolor='rgb(0,0,0,0.7)'
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleImageClose()}
                        >
                          <CloseIcon sx={{ color: 'white' }} cursor='pointer' />
                        </Box>
                      </Box>
                    </Box>
                  );
                }
              })}
            </Carousel>
          </Box>
        ) : (
          ''
        )}
      </Dialog>
    </>
  );
};

export default ViewAttachments;
