import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import isCustomer from '../hooks/isCustomer';
import { TenantContext } from '../App';
import { UserDataContext, UserListContext } from '../routes/Main';
import isDeveloper from '../hooks/isDeveloper';
import { IUserData } from '../utils/exports/Interface';

const FilterAssignees = () => {
  const customer = isCustomer();
  const developer = isDeveloper();

  const { tenant } = useContext(TenantContext);
  const { userList } = useContext(UserListContext);
  const { userData } = useContext(UserDataContext);

  const [searchParams, setQueryParams] = useSearchParams();
  const [filterAssignee, setFilterAssignee] = useState('');

  useEffect(() => {
    setFilterAssignee(searchParams.get('assignees') ?? '');
  }, [searchParams]);

  const sortAssignee = (event: SelectChangeEvent) => {
    const queryParams = Object.fromEntries(searchParams);
    if (event.target.value === 'all') {
      delete queryParams.assignees;
    } else {
      queryParams.assignees = event.target.value as string;
    }
    setQueryParams(queryParams);
  };

  return (
    <>
      <FormControl size='small' variant='standard'>
        <InputLabel sx={{ fontWeight: 'bold', color: '#000000DE', fontSize: '12px' }}>
          Assignee
        </InputLabel>
        <Select
          disableUnderline
          size='small'
          label='Assignee'
          aria-label='assignee-select'
          value={filterAssignee}
          onChange={sortAssignee}
          sx={{
            fontSize: '12px',
            width: '75px',
            mb: '8px',
            '.MuiSelect-icon': {
              color: '#000000DE',
              mt: '-2px',
            },
          }}
        >
          <MenuItem aria-label='all' value='all'>All</MenuItem>
          {!customer
            ? tenant !== 'all'
              ? userList?.data?.users?.users
                  ?.filter(
                    (user: IUserData) =>
                      ((user?.roles[0]?.name === 'dev' || user?.roles[0]?.name === 'int') &&
                        user?.organizations?.filter(
                          (organization: { alias: string }) => organization?.alias === tenant,
                        )?.length > 0) ||
                      (user?.roles[0]?.name === 'cus' && user?.organizations[0]?.alias === tenant),
                  )
                  .map((user: IUserData) => {
                    return (
                      <MenuItem
                        aria-label='user-name'
                        key={user.name}
                        value={
                          user?.roles[0]?.name === 'cus' ? `customer${user?.name}` : user?.name
                        }
                      >
                        {user.name}
                      </MenuItem>
                    );
                  })
              : developer
              ? userList?.data?.users?.users
                  ?.filter(
                    (user: IUserData) =>
                      user?.roles[0]?.name === 'dev' ||
                      user?.roles[0]?.name === 'int' ||
                      (user?.roles[0]?.name === 'cus' &&
                        userData?.data?.organizations?.filter(
                          (organization: { alias: string }) =>
                            organization?.alias === user?.organizations[0]?.alias,
                        )?.length > 0),
                  )
                  .map((user: IUserData) => {
                    return (
                      <MenuItem
                        aria-label='user-name'
                        key={user.name}
                        value={
                          user?.roles[0]?.name === 'cus' ? `customer${user?.name}` : user?.name
                        }
                      >
                        {user.name}
                      </MenuItem>
                    );
                  })
              : userList?.data?.users?.users
                  ?.filter(
                    (user: IUserData) =>
                      user?.roles[0]?.name === 'dev' ||
                      user?.roles[0]?.name === 'int' ||
                      user?.roles[0]?.name === 'cus',
                  )
                  .map((user: IUserData) => {
                    return (
                      <MenuItem
                        aria-label='user-name'
                        key={user.name}
                        value={
                          user?.roles[0]?.name === 'cus' ? `customer${user?.name}` : user?.name
                        }
                      >
                        {user.name}
                      </MenuItem>
                    );
                  })
            : userList?.data?.users?.users
                ?.filter(
                  (user: IUserData) =>
                    user?.roles[0]?.name === 'cus' &&
                    user?.organizations[0]?.alias === userData?.data?.organizations[0]?.alias,
                )
                .map((user: IUserData) => {
                  return (
                    <MenuItem aria-label='user-name' key={user.name} value={`customer${user.name}`}>
                      {user.name}
                    </MenuItem>
                  );
                })}
        </Select>
      </FormControl>
    </>
  );
};

export default FilterAssignees;
