import React, { useContext, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';

// import { Intern } from '../CourseObj';
// import { interns, Intern } from '../CourseObj';

import { UserListContext } from '../../../routes/Main';

interface AssignInternDialogProps {
  open: boolean;
  onClose: () => void;
  currentAssignees: number[];
  onSubmit: (selectedInterns: number[]) => void;
}

const AssignInternDialog: React.FC<AssignInternDialogProps> = ({
  open,
  onClose,
  currentAssignees,
  onSubmit,
}) => {
  // Initialize selected interns with current assignees
  const [selectedInterns, setSelectedInterns] = useState<number[]>([]);
  const { userList } = useContext(UserListContext);

  const filterInterns = userList?.data?.users?.users.filter((intern: any) =>{
    return intern?.roles[0]?.name === 'int' && intern?.status === '1'
  })

  // Reset selected interns when dialog opens
  React.useEffect(() => {
    if (open) {
      setSelectedInterns(currentAssignees);
    }
  }, [open, currentAssignees]);

  const handleToggleIntern = (intern: any) => {
    if(selectedInterns === null || selectedInterns.length === 0){
      return setSelectedInterns([intern.id]);
    }
    setSelectedInterns((prev) => {
      const isSelected = prev?.some((i) => i === intern.id);
      if (isSelected) {
        return prev.filter((i) => i !== intern.id);
      } else {
        return [...prev, intern.id];
      }
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(selectedInterns);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm'>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderColor: 'divider',
          pl: 2,
          fontWeight: 600,
        }}
      >
        Assign Interns
        <Box pl={3}>
          <IconButton aria-label='submit' onClick={handleSubmit} size='small'>
            <CheckIcon />
          </IconButton>
          <IconButton aria-label='close' onClick={onClose} size='small'>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <List> 
          {filterInterns?.map((intern: any) => (
            <ListItem key={intern.id} disablePadding>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedInterns?.some(i => i === intern.id)}
                    onChange={() => handleToggleIntern(intern)}
                  />
                }
                label={intern.name}
                sx={{ width: '100%', py: 0.5 }}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default AssignInternDialog;
