import {
  Avatar,
  Box,
  FormControl,
  MenuItem,
  Select,
  Radio,
  ListItemText,
  TextField,
  Typography,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { BoardContext, LoadingContext, TenantContext } from '../App';
import { AllTenantsContext, UserDataContext } from '../routes/Main';
import axios from 'axios';
import { failed, success } from '../hooks/useToast';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import isMobile from '../hooks/isMobile';
import isSuperAdmin from '../hooks/isSuperAdmin';
import isAdmin from '../hooks/isAdmin';
import { getBoards, getProject } from '../utils/api/API';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IBoard {
  id: number;
  attributes: {
    main_tenant: string;
    name: string;
    project?: {
      data?: {
        id: number;
        attributes: {
          name: string;
          tenant: string;
        };
      };
    };
  };
}

interface IProject {
  id: number;
  attributes: {
    name: string;
    tenant: string;
  };
}

const SelectBoard = (props: {
  isNewBoard: boolean;
  setIsNewBoard: Dispatch<SetStateAction<boolean>>;
}) => {
  const mobile = isMobile();
  const superAdmin = isSuperAdmin();
  const admin = isAdmin();

  const { board, setBoard } = useContext(BoardContext);
  const { tenant } = useContext(TenantContext);
  const { allTenants } = useContext(AllTenantsContext);
  const { userData } = useContext(UserDataContext);
  const { setLoading } = useContext(LoadingContext);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleAccordionChange = (panel: string) => {
    setExpanded(expanded === panel ? false : panel);
  };

  const [currentTenant, setCurrentTenant] = useState<{ organization: { name: string } }>();
  // const [boards, setBoards] = useState<[{ attributes: { name: string }; id: number }]>();
  const [boards, setBoards] = useState<IBoard[]>();
  const [projects, setProjects] = useState<IProject[]>();
  const [newProject, setNewProject] = useState<string>();
  const [editProject, setEditProject] = useState<string>();
  const [projectId, setProjectId] = useState<number>(0);
  const [editprojectId, seteditProjectId] = useState<number>(0);
  const [prevBoard, setPrevBoard] = useState<number[]>();
  const [newBoard, setNewBoard] = useState<string>();
  const [isEditBoard, setIsEditBoard] = useState(false);
  const [editedBoard, setEditedBoard] = useState<string>();
  const [isNewProject, setIsNewProject] = useState<boolean>(false);
  const [filterBoard, setFilterBoard] = useState<any[]>();
  const [isEditProject, setIsEditProject] = useState<boolean>(false);

  const getCurrentTenant = () => {
    setCurrentTenant(
      allTenants?.data?.tenants?.filter(
        (org: { organization: { alias: string } }) => org.organization.alias === tenant,
      )[0],
    );
  };

  useEffect(() => {
    getCurrentTenant();
  }, [tenant, allTenants]);

  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

  // const getBoards = () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_strapiUrl}/boards?filters[main_tenant][$eq]=${tenant}&populate=*`,
  //       {
  //         headers: headers,
  //       },
  //     )
  //     .then((res) => setBoards(res.data.data))
  //     .catch(() => failed());
  // };
  // const getBoards = () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_strapiUrl}/boards?filters[main_tenant][$eq]=${tenant}&populate=*`,
  //       {
  //         headers: headers,
  //       },
  //     )
  //     .then((res) => setBoards(res.data.data))
  //     .catch(() => failed());
  // };

  useEffect(() => {
    tenant !== 'all' && getBoards(tenant, setBoards, userData?.data?.id, superAdmin || admin);
    getProject(tenant, setProjects);
  }, [tenant, userData]);

  useEffect(() => {
    const groupedBoards = boards?.reduce<Record<number, { project: { id: number; name: string; [key: string]: any }; boards: any[] }>>(
      (acc, board) => {
        const projectData = board.attributes.project?.data;
  
        if (projectData) {
          const projectId = projectData.id;
          const projectAttributes = projectData.attributes;
          if (!acc[projectId]) {
            acc[projectId] = {
              project: { id: projectId, ...projectAttributes },
              boards: [],
            };
          }
          acc[projectId].boards.push({
            id: board.id,
            attributes: board.attributes,
          });
        }
  
        return acc;
      },
      {}
    );
  
    const result = Object.values(groupedBoards || {});
    setFilterBoard(result);
  }, [boards]);

  const createNewBoard = () => {
    // if (projectId === 0) {
    //   dynamicFailed('Please Select Project');
    //   return;
    // }
    const data = {
      data: {
        name: newBoard,
        main_tenant: tenant,
        project: projectId === 0 ? null : projectId,
      },
    };
    axios
      .post(`${process.env.REACT_APP_strapiUrl}/boards`, data, {
        headers: headers,
      })
      .then((res) => {
        getBoards(tenant, setBoards, userData?.data?.id, superAdmin || admin);
        props.setIsNewBoard(false);
        setBoard([res.data.data.id]);
        setProjectId(0);
        success();
      })
      .catch(() => failed());
  };

  const createNewProject = () => {
    setLoading(true);
    const data = {
      data: {
        name: newProject,
        tenant: tenant,
      },
    };
    axios
      .post(`${process.env.REACT_APP_strapiUrl}/projects`, data, {
        headers: headers,
      })
      .then(() => {
        getProject(tenant, setProjects);
        setIsNewProject(false);
        setLoading(false);
        success();
      })
      .catch(() => failed());
  };

  const editnewProject = () => {
    setLoading(true);
    const data = {
      data: {
        name: editProject,
      },
    };
    axios
      .put(`${process.env.REACT_APP_strapiUrl}/projects/${editprojectId}`, data, {
        headers: headers,
      })
      .then(() => {
        getBoards(tenant, setBoards, userData?.data?.id, superAdmin || admin);
        getProject(tenant, setProjects);
        setIsEditProject(false);
        setLoading(false);
        success();
      })
      .catch(() => failed());
  };

  const editBoard = () => {
    const data = {
      data: {
        name: editedBoard,
        project: projectId,
      },
    };
    axios
      .put(`${process.env.REACT_APP_strapiUrl}/boards/${board}`, data, {
        headers: headers,
      })
      .then((res) => {
        getBoards(tenant, setBoards, userData?.data?.id, superAdmin || admin);
        setIsEditBoard(false);
        setBoard([res.data.data.id]);
        success();
      })
      .catch(() => failed());
  };

  const handleBoardToggle = (boardId: number | string) => {
    setBoard([boardId]);
  };

  const getSelectedItems = () => {
    const selectedItems = board.map((id: number | string) => {
      if (parseInt(id as string) === 0) return currentTenant?.organization?.name;
      if (id === 'all') return 'All';
      const selectedBoard = boards?.find((b) => b.id === parseInt(id as string));
      return selectedBoard?.attributes?.name || '';
    });
    return selectedItems;
  };

  return (
    <>
      {props.isNewBoard ? (
        <Box display='flex' gap='5px' alignItems='center'>
          <FormControl sx={{ mt: '-16px' }} fullWidth={mobile}>
            <TextField
              onChange={(e) => setNewBoard(e.target.value)}
              variant='standard'
              size='small'
              label='Board'
              aria-label='board'
            />
          </FormControl>
          <Box>
            {projects && projects?.length > 0 && (
              <FormControl size='small' sx={{ mt: '-16px' }}>
                <InputLabel id='project-select-label' size='small' sx={{ mt: '8px', ml: '-13px' }}>
                  Project
                </InputLabel>
                <Select
                  labelId='project-select-label'
                  variant='standard'
                  size='small'
                  label='Project'
                  value={projectId || ''}
                  aria-label='project-select'
                  onChange={(e) => setProjectId(e.target.value as number)}
                  sx={{ width: '100px' }}
                >
                  {projects?.map((project: { attributes: { name: string }; id: number }) => (
                    <MenuItem aria-label='attributes-title' key={project.id} value={project.id}>
                      {project.attributes.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
          <DoneIcon
            onClick={() => {
              newBoard !== '' && createNewBoard();
            }}
            sx={{ width: '18px', height: '18px', color: 'gray', cursor: 'pointer' }}
          />
          <CloseIcon
            onClick={() => {
              props.setIsNewBoard(false);
              setBoard(prevBoard);
              setProjectId(0);
            }}
            sx={{ width: '18px', height: '18px', color: 'gray', cursor: 'pointer' }}
          />
        </Box>
      ) : isEditBoard ? (
        <Box display='flex' gap='5px' alignItems='center'>
          <FormControl sx={{ mt: '-16px' }} fullWidth={mobile}>
            <TextField
              defaultValue={boards?.filter((item) => item?.id === board[0])?.[0]?.attributes?.name}
              onChange={(e) => setEditedBoard(e.target.value)}
              variant='standard'
              size='small'
              label='Board'
              aria-label='board'
            />
          </FormControl>
          <Box>
            {projects && projects?.length > 0 && (
              <FormControl size='small' sx={{ mt: '-16px' }}>
                <InputLabel id='project-select-label' size='small' sx={{ mt: '8px', ml: '-13px' }}>
                  Project
                </InputLabel>
                <Select
                  labelId='project-select-label'
                  variant='standard'
                  size='small'
                  label='Project'
                  value={projectId || ''}
                  aria-label='project-select'
                  onChange={(e) => setProjectId(e.target.value as number)}
                  sx={{ width: '100px' }}
                >
                  {projects?.map((project: { attributes: { name: string }; id: number }) => (
                    <MenuItem aria-label='attributes-title' key={project.id} value={project.id}>
                      {project.attributes.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
          <DoneIcon
            onClick={() => {
              editedBoard !== '' && editBoard();
            }}
            sx={{ width: '18px', height: '18px', color: 'gray', cursor: 'pointer' }}
          />
          <CloseIcon
            onClick={() => {
              setIsEditBoard(false);
            }}
            sx={{ width: '18px', height: '18px', color: 'gray', cursor: 'pointer' }}
          />
        </Box>
      ) : isNewProject ? (
        <Box display='flex' gap='5px' alignItems='center'>
          <FormControl sx={{ mt: '-16px' }} fullWidth={mobile}>
            <TextField
              onChange={(e) => setNewProject(e.target.value)}
              variant='standard'
              size='small'
              label='Project'
              aria-label='project'
            />
          </FormControl>
          <DoneIcon
            onClick={() => {
              createNewProject();
            }}
            sx={{ width: '18px', height: '18px', color: 'gray', cursor: 'pointer' }}
          />
          <CloseIcon
            onClick={() => {
              setNewProject('');
              setIsNewProject(false);
            }}
            sx={{ width: '18px', height: '18px', color: 'gray', cursor: 'pointer' }}
          />
        </Box>
      ) : isEditProject ? (
        <Box display='flex' gap='5px' alignItems='center'>
          <FormControl sx={{ mt: '-16px' }} fullWidth={mobile}>
            <TextField
              defaultValue={projects?.filter((item) => item?.id === editprojectId)?.[0]?.attributes?.name}
              onChange={(e) => setEditProject(e.target.value)}
              variant='standard'
              size='small'
              label='Project'
              aria-label='project'
            />
          </FormControl>
          <DoneIcon
            onClick={() => {
              editnewProject();
            }}
            sx={{ width: '18px', height: '18px', color: 'gray', cursor: 'pointer' }}
          />
          <CloseIcon
            onClick={() => {
              setEditProject('');
              setIsEditProject(false);
              seteditProjectId(0);
            }}
            sx={{ width: '18px', height: '18px', color: 'gray', cursor: 'pointer' }}
          />
        </Box>
      ) : (
        <Box display='flex' alignItems='center' gap='5px'>
          <Select
            value={board}
            size='small'
            variant='standard'
            aria-label='standard-select'
            sx={{ maxWidth: '180px' }}
            renderValue={() => {
              const selectedItems = getSelectedItems();
              const selectedCount = selectedItems.length;
              return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    noWrap
                    variant='body2'
                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                  >
                    {selectedItems.join(', ')}
                  </Typography>
                  {selectedCount > 1 && (
                    <Avatar
                      sx={{
                        backgroundColor: 'lightgray',
                        color: 'gray',
                        width: '20px',
                        height: '20px',
                        fontSize: '12px',
                      }}
                    >
                      {selectedCount}
                    </Avatar>
                  )}
                </Box>
              );
            }}
          >
            <MenuItem
              onClick={() => {handleBoardToggle('all'); setExpanded(false);}}
              selected={board?.flat()?.includes('all')}
              sx={{ padding: '2px 10px 2px 2px' }}
            >
              <Radio checked={board?.flat()?.includes('all')} size='small' />
              <ListItemText primary='All' />
            </MenuItem>

            <MenuItem
              onClick={() => {handleBoardToggle(0); setExpanded(false);}}
              selected={board?.flat()?.includes(0)}
              sx={{ padding: '2px 10px 2px 2px' }}
            >
              <Radio
                checked={board?.flat()?.includes(0) || board?.flat()?.includes('0')}
                size='small'
              />
              <ListItemText primary={currentTenant?.organization?.name} />
            </MenuItem>
            {boards?.map((boardItem) => {
              if (boardItem?.attributes?.project?.data === null) {
                return (
                  <MenuItem
                    key={boardItem.id}
                    onClick={() => {handleBoardToggle(boardItem.id); setExpanded(false);}}
                    selected={board?.flat()?.includes(boardItem.id)}
                    sx={{ padding: '2px 10px 2px 2px' }}
                  >
                    <Radio
                      checked={
                        board?.flat()?.includes(boardItem.id) ||
                        board?.flat()?.includes(JSON.stringify(boardItem.id))
                      }
                      size='small'
                    />
                    <ListItemText primary={boardItem.attributes.name} />
                  </MenuItem>
                );
              }
              return null;
            })}

            {filterBoard?.map((group) => (
              <Accordion
                key={group.project.id}
                expanded={expanded === group.project.id.toString()}
                onChange={() => handleAccordionChange(group.project.id.toString())}
                disableGutters
                sx={{
                  '&:before': {
                    display: 'none', 
                  },
                  border: 'none',
                  boxShadow: 'none',
                  backgroundColor: 'white',
                  '&.Mui-expanded': {
                    backgroundColor: 'rgba(0, 0, 0, 0.06)'
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${group.project.id}-content`}
                  id={`${group.project.id}-header`}
                  sx={{
                    minHeight: '42px !important',
                    maxHeight: '42px !important',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <span style={{ flex: 1 }}>{group.project.name}</span>
                    <EditIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsEditProject(true)
                        seteditProjectId(group.project.id);
                      }}
                      sx={{ width: '16px', height: '16px', color: 'gray', cursor: 'pointer' }}
                    />
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 'unset' }}>
                  {group.boards.map((boardItem: any) => (
                    <MenuItem
                      key={boardItem.id}
                      onClick={() => handleBoardToggle(boardItem.id)}
                      selected={board?.flat()?.includes(boardItem.id)}
                      sx={{ padding: '2px 10px 2px 2px' }}
                    >
                      <Radio
                        checked={
                          board?.flat()?.includes(boardItem.id) ||
                          board?.flat()?.includes(JSON.stringify(boardItem.id))
                        }
                        size='small'
                      />
                      <ListItemText primary={boardItem.attributes.name} />
                    </MenuItem>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}

            {(superAdmin || admin) && (
              <MenuItem
                aria-label='new'
                onClick={() => {
                  props.setIsNewBoard(true);
                  setPrevBoard(board);
                }}
                sx={{
                  height: '42px',
                }}
              >
                + New Board
              </MenuItem>
            )}
            {(superAdmin || admin) && (
              <MenuItem
                aria-label='new'
                onClick={() => {
                  setIsNewProject(true);
                }}
                sx={{
                  height: '42px',
                }}
              >
                + New Project
              </MenuItem>
            )}
          </Select>

          {(superAdmin || admin) &&
            board.length === 1 &&
            !board.includes(0) &&
            !board.includes('0') &&
            !board.includes('all') && (
              <EditIcon
                onClick={() => {
                  setIsEditBoard(true);
                  setProjectId(
                    boards?.filter((item) => item?.id === board[0])?.[0]?.attributes?.project?.data
                      ?.id || 0
                  );
                }}
                sx={{ width: '16px', height: '16px', color: 'gray', cursor: 'pointer' }}
              />
            )}
        </Box>
      )}
    </>
  );
};

export default SelectBoard;
