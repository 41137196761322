import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  // Tab,
  // Tabs,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import axios from 'axios';
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import isSuperAdmin from '../../hooks/isSuperAdmin';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { failed, success } from '../../hooks/useToast';
import dayjs from 'dayjs';
import {
  AttendanceUsersContext,
  LeaveTypesContext,
  PolicyInfoContext,
  UserDataContext,
} from '../../routes/Main';
import {
  IAttachmentData,
  ILeaveTypesType,
  IUserDetails,
  UserFile,
} from '../../utils/exports/Interface';
import FileDragAndDrop from '../FileDragAndDrop';
import { CurrentUserContext, LoadingContext } from '../../App';
import AttachmentTable from '../AttachmentTable';
import DoneButton from '../UI/DoneButton';
// import { a11yProps, CustomTabPanel } from '../../utils/exports/Function';

export const EditField = styled(TextField)({
  width: '300px',
});

const EditUser = (props: {
  table: boolean;
  id: number;
  setCurrentUserId?: Dispatch<SetStateAction<number>>;
}) => {
  const { getAttendanceUsers } = useContext(AttendanceUsersContext);

  const { policyInfo } = useContext(PolicyInfoContext);

  const { leaveTypes } = useContext(LeaveTypesContext);

  const { userData } = useContext(UserDataContext);

  const { setLoading } = useContext(LoadingContext);

  const { currentUser } = useContext(CurrentUserContext);

  const superAdmin = isSuperAdmin();

  const [open, setOpen] = useState(false);

  const [userDetails, setUserDetails] = useState<IUserDetails>();

  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

  // const [tabValue, setTabValue] = useState(0);

  // const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setTabValue(newValue);
  // };

  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [contact, setContact] = useState<string>();
  const [joinDate, setJoinDate] = useState<any>();
  const [terminationDate, setTerminationDate] = useState<any>();
  const [bankAccName, setBankAccName] = useState<string>();
  const [bankAccNo, setBankAccNo] = useState<number>();
  const [pan, setPan] = useState<string>();
  const [dailyHours, setDailyHours] = useState<number>();
  const [monthlyHours, setMonthlyHours] = useState<number>();
  const [paidLeaves, setPaidLeaves] = useState<number>();
  const [address, setAddress] = useState<string>();
  const [emergencyName, setEmergencyName] = useState<string>();
  const [emergencyNum, setEmergencyNum] = useState<string>();
  const [bankName, setBankName] = useState<string>();
  const [bankBranch, setBankBranch] = useState<string>();
  const [role, setRole] = useState<string>();
  const [position, setPosition] = useState<string>();
  const [gender, setGender] = useState<string>();
  const [linkedin, setLinkedin] = useState<string>();
  const [facebook, setFacebook] = useState<string>();
  const [instagram, setInstagram] = useState<string>();
  const [status, setStatus] = useState<boolean>();
  const [editedHours, setEditedHours] = useState<number>();
  const [multiCheckIns, setMultiCheckIns] = useState<boolean>();
  const [anchorElJoin, setAnchorElJoin] = useState<HTMLButtonElement | null>(null);
  const [anchorElTermination, setAnchorElTermination] = useState<HTMLButtonElement | null>(null);

  const [files, setFiles] = useState<any>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [attachmentData, setAttachmentData] = useState<IAttachmentData[]>([]);

  useEffect(() => {
    if (currentUser?.attributes?.user_files?.data) {
      const rows = currentUser?.attributes?.user_files?.data?.flatMap((item: UserFile) =>
        item?.attributes?.file?.data?.map((fileItem: any) => ({
          id: fileItem?.id,
          userFileID: item?.id,
          fileName: fileItem?.attributes?.name || '-',
          uploadedBy: item?.attributes?.uploaded_by?.split(' ')[0] || '-',
          createdAt: fileItem?.attributes?.createdAt
            ? new Date(fileItem.attributes.createdAt).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            : '-',
          fileUrl: fileItem?.attributes?.url || null,
        })),
      );
      setAttachmentData(rows);
    }
  }, [currentUser]);

  const resetFileInput = () => {
    setFiles('');
    setFileNames([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const uploadFile = async () => {
    setLoading(true);
    const formData = new FormData();

    Array.from(files).forEach((file: any) => {
      formData.append('files', file);
      formData.append('ref', 'ticket');
      formData.append('field', 'attachment');
    });

    axios
      .post(`${process.env.REACT_APP_strapiUrl}/upload`, formData, {
        headers: headers,
      })
      .then((response) => {
        const attachments: any = [];
        response.data.map((imageId: { id: number }) => {
          attachments.push(imageId.id);
        });
        const fileInfo = {
          data: {
            attendance_user: currentUser.id,
            file: attachments,
            uploaded_by: userData.data.name,
          },
        };
        uploadFileService(fileInfo);
      })
      .catch(() => {
        failed();
        setLoading(false);
      });

    // handleClose();
  };

  const uploadFileService = async (fileInfo: {
    data: {
      uploaded_by: string;
    };
  }) => {
    axios
      .post(
        `${process.env.REACT_APP_strapiUrl}/user-files

`,
        fileInfo,
        {
          params: { populate: '*' },
          headers: headers,
        },
      )
      .then((res) => {
        const newFileData = res?.data?.data?.attributes?.file?.data?.map((fileItem: any) => ({
          id: fileItem.id,
          userFileID: res?.data?.data?.id,
          fileName: fileItem?.attributes?.name || '-',
          uploadedBy: res?.data?.data?.attributes?.uploaded_by?.split(' ')[0] || '-',
          createdAt: fileItem?.attributes?.createdAt
            ? new Date(fileItem.attributes.createdAt).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            : '-',
          fileUrl: fileItem?.attributes?.url || null,
        }));
        setAttachmentData((prevData) => [...prevData, ...newFileData]);
        success();
        setLoading(false);
        setFiles([]);
      })
      .catch(() => {
        failed();
        setLoading(false);
        setFiles([]);
      });
  };

  const openJoinDate = Boolean(anchorElJoin);
  const openTerminationDate = Boolean(anchorElTermination);

  const handleJoinDateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElJoin(event.currentTarget);
  };

  const handleTerminationDateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElTermination(event.currentTarget);
  };

  const handleJoinDateChange = (date: Date | null) => {
    setJoinDate(date);
    setAnchorElJoin(null);
  };

  const handleTerminationDateChange = (date: Date | null) => {
    setTerminationDate(date);
    setAnchorElTermination(null);
  };

  const handleCloseJoin = () => {
    setAnchorElJoin(null);
  };

  const handleCloseTermination = () => {
    setAnchorElTermination(null);
  };

  const handleClearJoinDate = () => {
    setJoinDate(null);
  };

  const handleClearTerminationDate = () => {
    setTerminationDate(null);
  };

  const getUserDetails = (id: number) => {
    if (id !== undefined) {
      axios
        .get(`${process.env.REACT_APP_strapiUrl}/attendance-users/${id}`, {
          headers: headers,
        })
        .then((res) => {
          setUserDetails(res?.data?.data);
        })
        .catch(() => failed());
    }
  };

  const generalHours = policyInfo?.general_hours;

  // const [expanded, setExpanded] = useState<string | false>(false);

  // const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  const handleClick = (id: number) => {
    setOpen(true);
    getUserDetails(id);
  };

  useEffect(() => {
    getUserDetails(props?.id);
  }, [props?.id]);

  useEffect(() => {
    setName(userDetails?.attributes?.name);
    setEmail(userDetails?.attributes?.official_email);
    setTitle(userDetails?.attributes?.title);
    setContact(userDetails?.attributes?.contact);
    setJoinDate(
      userDetails?.attributes?.join_date ? dayjs(userDetails?.attributes?.join_date) : null,
    );
    setBankAccName(userDetails?.attributes?.bank_acc_name);
    setBankAccNo(userDetails?.attributes?.bank_acc_num);
    setPan(userDetails?.attributes?.pan);
    setDailyHours(userDetails?.attributes?.daily_hours);
    setMonthlyHours(userDetails?.attributes?.monthly_hours);
    setPaidLeaves(userDetails?.attributes?.paid_leaves);
    setAddress(userDetails?.attributes?.address);
    setEmergencyName(userDetails?.attributes?.emergency_name);
    setEmergencyNum(userDetails?.attributes?.emergency_num);
    setBankName(userDetails?.attributes?.bank_name);
    setBankBranch(userDetails?.attributes?.bank_branch);
    setRole(userDetails?.attributes?.role);
    setPosition(userDetails?.attributes?.position);
    setTerminationDate(
      userDetails?.attributes?.termination_date
        ? dayjs(userDetails?.attributes?.termination_date)
        : null,
    );
    setGender(userDetails?.attributes?.gender);
    setEditedHours(userDetails?.attributes?.edited_hours);
    setLinkedin(userDetails?.attributes?.linkedin);
    setFacebook(userDetails?.attributes?.facebook);
    setInstagram(userDetails?.attributes?.instagram);
    setStatus(userDetails?.attributes?.status);
    setMultiCheckIns(userDetails?.attributes?.multi_check_ins);
  }, [userDetails]);

  const [needToEditDays, setNeedToEditDays] = useState(false);

  const handleSave = (id: number) => {
    setOpen(false);

    const data = {
      data: {
        name: name ? name : null,
        official_email: email ? email : null,
        title: title ? title : null,
        contact: contact ? contact : null,
        bank_acc_name: bankAccName ? bankAccName : null,
        bank_acc_num: bankAccNo ? bankAccNo : null,
        pan: pan ? pan : null,
        daily_hours: dailyHours ? dailyHours : null,
        monthly_hours: monthlyHours ? monthlyHours : null,
        paid_leaves: paidLeaves ? paidLeaves : null,
        join_date: joinDate ? joinDate.add(1, 'day') : null,
        address: address ? address : null,
        emergency_name: emergencyName ? emergencyName : null,
        emergency_num: emergencyNum ? emergencyNum : null,
        bank_name: bankName ? bankName : null,
        bank_branch: bankBranch ? bankBranch : null,
        role: role ? role : null,
        position: position ? position : null,
        termination_date: terminationDate ? terminationDate.add(1, 'day') : null,
        gender: gender ? gender : null,
        edited_hours: editedHours ? editedHours : null,
        linkedin: linkedin ? linkedin : null,
        facebook: facebook ? facebook : null,
        instagram: instagram ? instagram : null,
        status: status === true ? true : false,
        multi_check_ins: multiCheckIns === true ? true : false,
      },
    };
    axios
      .put(`${process.env.REACT_APP_strapiUrl}/attendance-users/${id}`, data, {
        headers: headers,
      })
      .then(() => {
        getUserDetails(id);
        !needToEditDays && success();
        getAttendanceUsers();
        needToEditDays && editDays();
        if (status === false && props.setCurrentUserId) {
          props.setCurrentUserId(0);
        }
      })
      .catch(() => failed());
  };

  const editedLeaves = userDetails?.attributes?.edited_leaves;
  const [editedDays, setEditedDays] = useState<string[]>([]);

  useEffect(() => {
    editedLeaves && editedLeaves !== null && setEditedDays(editedLeaves);
  }, [editedLeaves]);

  const removeDuplicates = (arr: string[]) => {
    const uniqueItems = [];
    const firstTwoWordsSet = new Set();

    for (const item of arr) {
      const [firstWord] = item.split(' ', 1);

      if (!firstTwoWordsSet.has(firstWord)) {
        uniqueItems.push(item);
        firstTwoWordsSet.add(firstWord);
      } else {
        // Remove the first occurrence of the matching item
        const index = arr.findIndex((element) => {
          const [firstWordExisting] = element.split(' ', 1);
          return firstWordExisting === firstWord;
        });
        if (index !== -1) {
          arr.splice(index, 1);
        }
      }
    }

    return uniqueItems;
  };

  const dataToPost = removeDuplicates(editedDays);

  const editDays = () => {
    const data = {
      data: {
        edited_leaves: dataToPost,
      },
    };

    axios
      .put(`${process.env.REACT_APP_strapiUrl}/attendance-users/${props?.id}`, data, {
        headers: headers,
      })
      .then(() => {
        success();
        setEditedDays([]);
        getUserDetails(props?.id);
        setNeedToEditDays(false);
      })
      .catch(() => {
        failed();
      });
  };

  return (
    <>
      {props.table ? (
        <EditIcon
          onClick={() => handleClick(props.id)}
          style={{ height: '16px', cursor: 'pointer' }}
        />
      ) : (
        <Box display='flex' alignItems='center' width='100%' justifyContent='space-between'>
          <Box
            display='flex'
            gap='5px'
            ml='-3px'
            alignItems='center'
            onClick={() => handleClick(props.id)}
            color='#5e4f9c'
            sx={{ cursor: 'pointer' }}
          >
            <Box p='5px' bgcolor='#5e4f9c' display='flex' alignItems='center' borderRadius='5px'>
              <PersonIcon sx={{ width: '22px', height: '22px', color: 'white' }} />
            </Box>
            <b>Details</b>
          </Box>

          <DragHandleIcon onClick={() => handleClick(props.id)} sx={{ cursor: 'pointer' }} />
        </Box>
      )}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth='xl'
        fullWidth
        PaperProps={{ sx: { overflowY: 'unset' } }}
      >
        <DialogTitle
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          sx={{ pb: 0 }}
        >
          <Box display='flex' gap='10px' alignItems='center'>
            <PersonIcon sx={{ width: '25px', height: '25px' }} />
            <b>Details</b>
          </Box>
          <CloseIcon onClick={() => setOpen(false)} cursor='pointer' />
        </DialogTitle>

        <DialogContent
          sx={{
            p: '15px',
          }}
        >
          <Box display='flex' flexDirection='column' gap='10px' m='15px'>
            <Typography fontSize='18px'>Personal</Typography>
            <Box display='flex' flexWrap='wrap' gap='10px'>
              <EditField
                label='Name'
                size='small'
                value={name || ''}
                onChange={(e) => superAdmin && setName(e.target.value)}
              />
              <EditField
                label='E-mail'
                size='small'
                value={email || ''}
                onChange={(e) => superAdmin && setEmail(e.target.value)}
              />
              <EditField
                label='Contact No.'
                size='small'
                value={contact || ''}
                onChange={(e) => superAdmin && setContact(e.target.value)}
              />
              <EditField
                label='Address'
                size='small'
                value={address || ''}
                onChange={(e) => superAdmin && setAddress(e.target.value)}
              />
              {/* <EditField label='Gender' size='small' value={gender || ''} onChange={e => superAdmin && setGender(e.target.value)} /> */}
              <FormControl
                size='small'
                sx={{
                  width: '300px',
                }}
              >
                <InputLabel>Gender</InputLabel>
                <Select
                  value={gender || ''}
                  onChange={(e) => superAdmin && setGender(e.target.value)}
                  label='Gender'
                  aria-label='gender-select'
                >
                  <MenuItem aria-label='male' value='male'>
                    Male
                  </MenuItem>
                  <MenuItem aria-label='female' value='female'>
                    Female
                  </MenuItem>
                </Select>
              </FormControl>
              <EditField
                label='Emergency Contact Name'
                size='small'
                value={emergencyName || ''}
                onChange={(e) => superAdmin && setEmergencyName(e.target.value)}
              />
              <EditField
                label='LinkedIn'
                size='small'
                value={linkedin || ''}
                onChange={(e) => superAdmin && setLinkedin(e.target.value)}
              />
              <EditField
                label='Facebook'
                size='small'
                value={facebook || ''}
                onChange={(e) => superAdmin && setFacebook(e.target.value)}
              />
              <EditField
                label='Instagram'
                size='small'
                value={instagram || ''}
                onChange={(e) => superAdmin && setInstagram(e.target.value)}
              />
            </Box>
          </Box>
          <Box display='flex' flexDirection='column' gap='10px' m='15px'>
            <Typography fontSize='18px'>Official</Typography>
            <Box display='flex' flexWrap='wrap' gap='10px'>
              <EditField
                label='PAN No.'
                size='small'
                value={pan || ''}
                onChange={(e) => superAdmin && setPan(e.target.value)}
              />
              <EditField
                label='Bank Name'
                size='small'
                value={bankName || ''}
                onChange={(e) => superAdmin && setBankName(e.target.value)}
              />
              {/* <EditField label='Bank Branch' size='small' value={bankBranch || ''} onChange={e => superAdmin && setBankBranch(e.target.value)} /> */}
              {/* <EditField label='Bank Account Name' size='small' value={bankAccName || ''} onChange={e => superAdmin && setBankAccName(e.target.value)} /> */}
              <EditField
                label='Bank Account No.'
                size='small'
                value={bankAccNo || ''}
                onChange={(e) => superAdmin && setBankAccNo(parseInt(e.target.value))}
              />
              {/* <EditField
              label='Join Date'
              size='small'
              value={joinDate || ''}
              onChange={(e) => superAdmin && setJoinDate(e.target.value)}
            />
            <EditField
              label='Termination Date'
              size='small'
              value={terminationDate || ''}
              onChange={(e) => superAdmin && setTerminationDate(e.target.value)}
            /> */}
              <EditField
                label='Join Date'
                size='small'
                type='text'
                value={joinDate ? joinDate.format('YYYY-MM-DD') : 'YYYY-MM-DD'}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label='calendar-join-date'
                      onClick={handleJoinDateClick}
                      sx={{ padding: '0px' }}
                    >
                      <CalendarMonthIcon />
                    </IconButton>
                  ),
                }}
              />
              <Popover
                open={openJoinDate}
                anchorEl={anchorElJoin}
                onClose={handleCloseJoin}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDatePicker
                    value={joinDate}
                    onChange={handleJoinDateChange}
                    componentsProps={{
                      actionBar: {
                        actions: ['clear'],
                      },
                    }}
                    onAccept={handleClearJoinDate}
                  />
                </LocalizationProvider>
              </Popover>

              <EditField
                label='Termination Date'
                size='small'
                type='text'
                value={terminationDate ? terminationDate.format('YYYY-MM-DD') : 'YYYY-MM-DD'}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label='calendar-termination-date'
                      onClick={handleTerminationDateClick}
                      sx={{ padding: '0px' }}
                    >
                      <CalendarMonthIcon />
                    </IconButton>
                  ),
                }}
              />
              <Popover
                open={openTerminationDate}
                anchorEl={anchorElTermination}
                onClose={handleCloseTermination}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDatePicker
                    value={terminationDate}
                    onChange={handleTerminationDateChange}
                    componentsProps={{
                      actionBar: {
                        actions: ['clear'],
                      },
                    }}
                    onAccept={handleClearTerminationDate}
                  />
                </LocalizationProvider>
              </Popover>
              <FormControl
                size='small'
                sx={{
                  width: '300px',
                }}
              >
                <InputLabel>Status</InputLabel>
                <Select
                  value={status === true ? 1 : 0}
                  onChange={(e) => {
                    const value = e.target.value === 1 ? true : false;
                    superAdmin && setStatus(value);
                  }}
                  label='Status'
                  aria-label='status-select'
                >
                  <MenuItem aria-label='active' value={1}>
                    Active
                  </MenuItem>
                  <MenuItem aria-label='inactive' value={0}>
                    Inactive
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box display='flex' flexDirection='column' gap='10px' m='15px'>
            <Typography fontSize='18px'>Contract</Typography>
            <Box display='flex' flexWrap='wrap' gap='10px'>
              <EditField
                label='Job Title'
                size='small'
                value={title || ''}
                onChange={(e) => superAdmin && setTitle(e.target.value)}
              />
              <EditField
                label='Position'
                size='small'
                value={position || ''}
                onChange={(e) => superAdmin && setPosition(e.target.value)}
              />
              <EditField
                label='Working Hours'
                size='small'
                defaultValue={editedHours !== null ? editedHours : generalHours}
                onChange={(e) => superAdmin && setEditedHours(parseInt(e.target.value))}
              />
              {leaveTypes?.map((type: ILeaveTypesType) => {
                const existingLeave = editedLeaves?.find(
                  (leave) => leave.split(' ')[0] === type?.attributes?.code,
                );
                const totalLeaves = existingLeave
                  ? parseFloat(existingLeave.replace(/[^0-9.]/g, ''))
                  : type?.attributes?.days;

                return (
                  <EditField
                    disabled={!superAdmin}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000000',
                      },
                      '& .MuiInputLabel-root.Mui-disabled': {
                        color: 'rgba(0, 0, 0, 0.6)',
                      },
                    }}
                    key={type?.id}
                    label={type?.attributes?.name}
                    size='small'
                    defaultValue={totalLeaves}
                    onChange={(e) => {
                      const updatedValue = e.target.value;
                      if (superAdmin) {
                        const updatedLeave = `${type?.attributes?.code} [${updatedValue} days]`;

                        setEditedDays((prev) =>
                          prev.some((leave) => leave.split(' ')[0] === type?.attributes?.code)
                            ? prev.map((leave) =>
                                leave.split(' ')[0] === type?.attributes?.code
                                  ? updatedLeave
                                  : leave,
                              )
                            : [...prev, updatedLeave],
                        );
                        setNeedToEditDays(true);
                      }
                    }}
                  />
                );
              })}
              <FormControl
                size='small'
                sx={{
                  width: '300px',
                }}
              >
                <InputLabel>Multiple Check Ins</InputLabel>
                <Select
                  value={multiCheckIns === true ? 1 : 0}
                  onChange={(e) => {
                    const value = e.target.value === 1 ? true : false;
                    superAdmin && setMultiCheckIns(value);
                  }}
                  label='Multiple Check Ins'
                  aria-label='multi-check-ins-select'
                >
                  <MenuItem aria-label='enable' value={1}>
                    Enable
                  </MenuItem>
                  <MenuItem aria-label='disable' value={0}>
                    Disable
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box display='flex' flexDirection='column' gap='10px' m='15px'>
            <Typography fontSize='18px'>Attachments</Typography>
            {attachmentData.length > 0 && (
              <AttachmentTable
                attachmentData={attachmentData}
                setAttachmentData={setAttachmentData}
              />
            )}
            {superAdmin && (
              <Box display='flex' flexDirection='column' alignItems='center' width='100%' gap='5px'>
                <FileDragAndDrop
                  setFiles={setFiles}
                  fileNames={fileNames}
                  setFileNames={setFileNames}
                  details={true}
                />
                {files.length > 0 && (
                  <Box>
                    <DoneIcon
                      onClick={() => {
                        resetFileInput();
                        uploadFile();
                      }}
                      sx={{ height: '20px', color: 'gray', cursor: 'pointer' }}
                    />
                    <CloseIcon
                      onClick={resetFileInput}
                      sx={{ height: '20px', color: 'gray', cursor: 'pointer' }}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </DialogContent>

        {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label='basic tabs example'>
            <Tab label='Personal' {...a11yProps(0)} />
            <Tab label='Official' {...a11yProps(1)} />
            <Tab label='Contract' {...a11yProps(2)} />
          </Tabs>
        </Box> */}

        {/* <CustomTabPanel value={tabValue} index={0}>
          <Box display='flex' flexDirection='column' gap='10px' m='15px'>
            <EditField
              label='Name'
              size='small'
              value={name || ''}
              onChange={(e) => superAdmin && setName(e.target.value)}
            />
            <EditField
              label='E-mail'
              size='small'
              value={email || ''}
              onChange={(e) => superAdmin && setEmail(e.target.value)}
            />
            <EditField
              label='Contact No.'
              size='small'
              value={contact || ''}
              onChange={(e) => superAdmin && setContact(e.target.value)}
            />
            <EditField
              label='Address'
              size='small'
              value={address || ''}
              onChange={(e) => superAdmin && setAddress(e.target.value)}
            /> */}
        {/* <EditField label='Gender' size='small' value={gender || ''} onChange={e => superAdmin && setGender(e.target.value)} /> */}
        {/* <FormControl size='small'>
              <InputLabel>Gender</InputLabel>
              <Select
                value={gender || ''}
                onChange={(e) => superAdmin && setGender(e.target.value)}
                label='Gender'
                aria-label='gender-select'
              >
                <MenuItem aria-label='male' value='male'>
                  Male
                </MenuItem>
                <MenuItem aria-label='female' value='female'>
                  Female
                </MenuItem>
              </Select>
            </FormControl>
            <EditField
              label='Emergency Contact Name'
              size='small'
              value={emergencyName || ''}
              onChange={(e) => superAdmin && setEmergencyName(e.target.value)}
            />
            <EditField
              label='LinkedIn'
              size='small'
              value={linkedin || ''}
              onChange={(e) => superAdmin && setLinkedin(e.target.value)}
            />
            <EditField
              label='Facebook'
              size='small'
              value={facebook || ''}
              onChange={(e) => superAdmin && setFacebook(e.target.value)}
            />
            <EditField
              label='Instagram'
              size='small'
              value={instagram || ''}
              onChange={(e) => superAdmin && setInstagram(e.target.value)}
            />
          </Box>
        </CustomTabPanel>

        <CustomTabPanel value={tabValue} index={1}>
          <Box display='flex' flexDirection='column' gap='10px' m='15px'>
            <EditField
              label='PAN No.'
              size='small'
              value={pan || ''}
              onChange={(e) => superAdmin && setPan(e.target.value)}
            />
            <EditField
              label='Bank Name'
              size='small'
              value={bankName || ''}
              onChange={(e) => superAdmin && setBankName(e.target.value)}
            />
            {/* <EditField label='Bank Branch' size='small' value={bankBranch || ''} onChange={e => superAdmin && setBankBranch(e.target.value)} /> */}
        {/* <EditField label='Bank Account Name' size='small' value={bankAccName || ''} onChange={e => superAdmin && setBankAccName(e.target.value)} /> */}
        {/* <EditField
              label='Bank Account No.'
              size='small'
              value={bankAccNo || ''}
              onChange={(e) => superAdmin && setBankAccNo(parseInt(e.target.value))}
            /> */}
        {/* <EditField
              label='Join Date'
              size='small'
              value={joinDate || ''}
              onChange={(e) => superAdmin && setJoinDate(e.target.value)}
            />
            <EditField
              label='Termination Date'
              size='small'
              value={terminationDate || ''}
              onChange={(e) => superAdmin && setTerminationDate(e.target.value)}
            /> */}
        {/* <EditField
              label='Join Date'
              size='small'
              type='text'
              value={joinDate ? joinDate.format('YYYY-MM-DD') : 'YYYY-MM-DD'}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label='calendar-join-date'
                    onClick={handleJoinDateClick}
                    sx={{ padding: '0px' }}
                  >
                    <CalendarMonthIcon />
                  </IconButton>
                ),
              }}
            />
            <Popover
              open={openJoinDate}
              anchorEl={anchorElJoin}
              onClose={handleCloseJoin}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  value={joinDate}
                  onChange={handleJoinDateChange}
                  componentsProps={{
                    actionBar: {
                      actions: ['clear'],
                    },
                  }}
                  onAccept={handleClearJoinDate}
                />
              </LocalizationProvider>
            </Popover>

            <EditField
              label='Termination Date'
              size='small'
              type='text'
              value={terminationDate ? terminationDate.format('YYYY-MM-DD') : 'YYYY-MM-DD'}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label='calendar-termination-date'
                    onClick={handleTerminationDateClick}
                    sx={{ padding: '0px' }}
                  >
                    <CalendarMonthIcon />
                  </IconButton>
                ),
              }}
            />
            <Popover
              open={openTerminationDate}
              anchorEl={anchorElTermination}
              onClose={handleCloseTermination}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  value={terminationDate}
                  onChange={handleTerminationDateChange}
                  componentsProps={{
                    actionBar: {
                      actions: ['clear'],
                    },
                  }}
                  onAccept={handleClearTerminationDate}
                />
              </LocalizationProvider>
            </Popover>
          </Box>
        </CustomTabPanel> */}

        {/* <CustomTabPanel value={tabValue} index={2}>
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            disableGutters={true}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Title</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display='flex' flexDirection='column' gap='10px'>
                <EditField
                  label='Job Title'
                  size='small'
                  value={title || ''}
                  onChange={(e) => superAdmin && setTitle(e.target.value)}
                />
                <EditField
                  label='Position'
                  size='small'
                  value={position || ''}
                  onChange={(e) => superAdmin && setPosition(e.target.value)}
                />
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
            disableGutters={true}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Hours</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}> */}
        {/* <EditField label='Daily Hours' size='small' value={dailyHours || ''} onChange={e => superAdmin && setDailyHours(parseInt(e.target.value))} /> */}
        {/* <EditField label='Monthly Hours' size='small' value={monthlyHours || ''} onChange={e => superAdmin && setMonthlyHours(parseInt(e.target.value))} /> */}
        {/* <EditField
                label='Working Hours'
                size='small'
                defaultValue={editedHours !== null ? editedHours : generalHours}
                onChange={(e) => superAdmin && setEditedHours(parseInt(e.target.value))}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
            disableGutters={true}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ width: '33%', flexShrink: 0 }}>Leave</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {leaveTypes?.map((type: ILeaveTypesType) => {
                const existingLeave = editedLeaves?.find(
                  (leave) => leave.split(' ')[0] === type?.attributes?.code,
                );
                const totalLeaves = existingLeave
                  ? parseFloat(existingLeave.replace(/[^0-9.]/g, ''))
                  : type?.attributes?.days;

                return (
                  <EditField
                    disabled={!superAdmin}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000000',
                      },
                      '& .MuiInputLabel-root.Mui-disabled': {
                        color: 'rgba(0, 0, 0, 0.6)',
                      },
                    }}
                    key={type?.id}
                    label={type?.attributes?.name}
                    size='small'
                    defaultValue={totalLeaves}
                    onChange={(e) => {
                      const updatedValue = e.target.value;
                      if (superAdmin) {
                        const updatedLeave = `${type?.attributes?.code} [${updatedValue} days]`;

                        setEditedDays((prev) =>
                          prev.some((leave) => leave.split(' ')[0] === type?.attributes?.code)
                            ? prev.map((leave) =>
                                leave.split(' ')[0] === type?.attributes?.code
                                  ? updatedLeave
                                  : leave,
                              )
                            : [...prev, updatedLeave],
                        );
                        setNeedToEditDays(true);
                      }
                    }}
                  />
                );
              })}
            </AccordionDetails>
          </Accordion> */}
        {/* <EditField label='Join Date' size='small' value={joinDate || ''} onChange={e =>superAdmin&& setJoinDate(e.target.value)} /> */}
        {/* </CustomTabPanel> */}

        {/* <Box display='flex' gap='25px'>
                    <Box display='flex' flexDirection='column' gap='10px' m='15px'>
                        <Typography variant='h6'>Personal Details</Typography>
                        <EditField label='Name' size='small' value={name || ''} onChange={e => superAdmin && setName(e.target.value)} />
                        <EditField label='E-mail' size='small' value={email || ''} onChange={e => superAdmin && setEmail(e.target.value)} />
                        <EditField label='Contact No.' size='small' value={contact || ''} onChange={e => superAdmin && setContact(e.target.value)} />
                        <EditField label='Address' size='small' value={address || ''} onChange={e => superAdmin && setAddress(e.target.value)} />
                        <EditField label='Emergency Contact Name' size='small' value={emergencyName || ''} onChange={e => superAdmin && setEmergencyName(e.target.value)} />
                        <EditField label='Emergency Contact No.' size='small' value={emergencyNum || ''} onChange={e => superAdmin && setEmergencyNum(e.target.value)} />
                    </Box>

                    <Box display='flex' flexDirection='column' gap='10px' m='15px'>
                        <Typography variant='h6'>Official Details</Typography>
                        <EditField label='PAN No.' size='small' value={pan || ''} onChange={e => superAdmin && setPan(e.target.value)} />
                        <EditField label='Bank Name' size='small' value={bankName || ''} onChange={e => superAdmin && setBankName(e.target.value)} />
                        <EditField label='Bank Branch' size='small' value={bankBranch || ''} onChange={e => superAdmin && setBankBranch(e.target.value)} />
                        <EditField label='Bank Account Name' size='small' value={bankAccName || ''} onChange={e => superAdmin && setBankAccName(e.target.value)} />
                        <EditField label='Bank Account No.' size='small' value={bankAccNo || ''} onChange={e => superAdmin && setBankAccNo(parseInt(e.target.value))} />
                    </Box>

                    <Box display='flex' flexDirection='column' gap='10px' m='15px'>
                        <Typography variant='h6'>Contract Details</Typography>
                        <EditField label='Role' size='small' value={role || ''} onChange={e => superAdmin && setRole(e.target.value)} />
                        <EditField label='Position' size='small' value={position || ''} onChange={e => superAdmin && setPosition(e.target.value)} />
                        <EditField label='Working Hours (Weekly)' size='small' value={workingHours || ''} onChange={e => superAdmin && setWorkingHours(parseInt(e.target.value))} />
                        <EditField label='Paid Leaves (Yearly)' size='small' value={paidLeaves || ''} onChange={e => superAdmin && setPaidLeaves(parseInt(e.target.value))} />
                        <EditField label='Join Date' size='small' value={joinDate || ''} onChange={e =>superAdmin&& setJoinDate(e.target.value)} />
                    </Box>
                </Box> */}
        {superAdmin && (
          <Box display='flex' justifyContent='space-around'>
            <DoneButton text='Save' onClick={() => handleSave(props?.id)} mt='15px' mb='15px' />
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default EditUser;
