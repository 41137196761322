import React, { useContext } from 'react';
import { Avatar, Box, Divider, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { UserDataContext } from '../../routes/Main';
import { MessageData } from '../../store/constants/constants';
import { stringToColor } from '../../utils/exports/Function';

interface MessageSeenIndicatorProps {
  message: MessageData;
  messages: MessageData[];
}

const SeenIndicator: React.FC<MessageSeenIndicatorProps> = ({ message, messages }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { userData } = useContext(UserDataContext);

  const getInitials = (name: string) => {
    const parts = name.trim().split(' ');
    if (parts.length === 1) {
      return parts[0].slice(0, 2).toUpperCase();
    }
    return `${parts[0][0]}${parts[parts.length - 1][0]}`.toUpperCase();
  };

  const getUserLatestSeenMessage = (
    messages: MessageData[],
    userId: number,
    seenByUserId: number,
  ) => {
    const sortedMessages = [...messages].sort((a, b) => (b.id as any) - (a.id as any));

    return sortedMessages.find((msg) =>
      msg.seenBy?.some((seen) => seen.user?.userId === seenByUserId && seen.isSeen),
    );
  };

  // For avatar display - show only on latest seen message
  const latestSeenUsers =
    message.seenBy?.filter(
      (seen) =>
        seen.user?.userId !== userData.data.id &&
        seen.isSeen &&
        getUserLatestSeenMessage(messages, userData.data.id, seen.user?.userId)?.id === message.id,
    ) || [];

  // For menu display - show all users who have seen this message
  const allSeenUsers =
    message.seenBy?.filter((seen) => seen.user?.userId !== userData.data.id && seen.isSeen) || [];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (latestSeenUsers.length === 0) return null;

  const theme = useTheme();

  return (
    <Box display='flex' justifyContent='end' alignItems='center' gap={1}>
      <Box onClick={handleClick} sx={{ display: 'flex', gap: 1, px: 1, cursor: 'pointer' }}>
        {latestSeenUsers.slice(0, 2).map((seenBy) => (
          <Avatar
            key={seenBy.user?.userId}
            src={`https://chatroom.enitiation.com/${seenBy.user?.profilePictureURL}`}
            sx={{
              width: 20,
              height: 20,
              border: '2px solid white',

              fontSize: 8,
              marginLeft: '-8px',
              '&:first-of-type': {
                marginLeft: 0,
              },
              bgcolor: stringToColor(seenBy.user?.userName || 'Anonymous'),
            }}
          >
            {getInitials(seenBy.user?.userName || 'Anonymous')}
          </Avatar>
        ))}
        {latestSeenUsers.length > 2 && (
          <Typography
            variant='caption'
            sx={{
              fontSize: '12px',
              color: 'text.secondary',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            +{latestSeenUsers.length - 2}
          </Typography>
        )}
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          width: 300, // Set the width of the menu
        }}
      >
        {/* Header MenuItem */}
        <MenuItem
          disabled
          sx={{
            opacity: 1, // Ensures text is fully visible
            '& .MuiTypography-root': {
              color: theme.palette.text.primary, // Explicit color
            },
          }}
        >
          <Typography variant='body1'>
            Seen by {allSeenUsers.length} {allSeenUsers.length === 1 ? 'person' : 'people'}
          </Typography>
        </MenuItem>
        <Divider />

        <Box
          sx={{
            maxHeight: 250,
            overflowY: 'auto',
          }}
        >
          {allSeenUsers
            .slice()
            .sort((a, b) => {
              const nameA = (a.user?.userName || 'Anonymous').toLowerCase();
              const nameB = (b.user?.userName || 'Anonymous').toLowerCase();
              return nameA.localeCompare(nameB);
            })
            .map((seenBy) => (
              <MenuItem key={seenBy.user?.userId}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Avatar
                    src={`https://chatroom.enitiation.com/${seenBy.user?.profilePictureURL}`}
                    sx={{
                      width: 35,
                      height: 35,
                      fontSize: 16,
                      bgcolor: stringToColor(seenBy.user?.userName || 'Anonymous'),
                    }}
                  >
                    {getInitials(seenBy.user?.userName || 'Anonymous')}
                  </Avatar>
                  <Typography variant='body1'>
                    {seenBy.user?.userName.split(' ')[0] || 'Anonymous'}
                  </Typography>
                  <Typography variant='body2'>
                    {/* {seenBy.seenAt
                      ? new Date(seenBy.seenAt).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })
                      : 'N/A'} */}
                    {seenBy.seenAt
                      ? (() => {
                          const date = new Date(seenBy.seenAt);
                          const hours = date.getHours() % 12 || 12; // Converts to 12-hour format and handles midnight (0 -> 12)
                          const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensures two-digit minutes
                          const amPm = date.getHours() >= 12 ? 'PM' : 'AM'; // Adds AM/PM
                          return `${hours}:${minutes} ${amPm}`;
                        })()
                      : 'N/A'}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
        </Box>
      </Menu>
    </Box>
  );
};

export default SeenIndicator;
