import { useContext, useEffect, useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import axios from 'axios';
import { failed } from '../../hooks/useToast';
import { CurrentUserContext } from '../../App';
// import { PieChart } from '@mui/x-charts/PieChart';
import { getCurrentDate, handleDay } from '../../utils/exports/Function';
import { PolicyInfoContext } from '../../routes/Main';
import GaugeChart from './GaugeChart';

export const extractTime = (start: string | undefined | null, end: string | undefined | null) => {
  const startTime = start;
  const endTime = end;

  const startTimeObj: any = new Date(`2000-01-01T${startTime}Z`);
  const endTimeObj: any = new Date(`2000-01-01T${endTime}Z`);

  const timeDiff = endTimeObj - startTimeObj;
  const hours = Math.floor(timeDiff / (1000 * 60 * 60));
  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

  return `${hours}h ${minutes}m`;
};

const extractHours = (start: string | undefined | null, end: string | undefined | null) => {
  const startTime = start;
  const endTime = end;

  const startTimeObj: any = new Date(`2000-01-01T${startTime}Z`);
  const endTimeObj: any = new Date(`2000-01-01T${endTime}Z`);

  const timeDiff = endTimeObj - startTimeObj;
  const hours = Math.floor(timeDiff / (1000 * 60 * 60));

  return hours;
};

export const getDatesInRange = (startDate: string, endDate: string): string[] => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const dates: string[] = [];
  const currentDate = new Date(start);

  while (currentDate <= end) {
    dates.push(currentDate.toISOString().split('T')[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export const getMatchingValues = (array1: string[], array2: string[]): string[] => {
  const set1 = new Set(array1);
  const set2 = new Set(array2);

  return Array.from(set1).filter((value) => set2.has(value));
};

export const getCurrentTime = () => {
  const currentTime = new Date();

  const hours = currentTime.getHours().toString().padStart(2, '0');
  const minutes = currentTime.getMinutes().toString().padStart(2, '0');
  const seconds = currentTime.getSeconds().toString().padStart(2, '0');
  const milliseconds = currentTime.getMilliseconds().toString().padStart(3, '0');

  const formattedTime = `${hours}:${minutes}:${seconds}.${milliseconds}`;

  return formattedTime;
};

export const getSaturdaysInMonth = (year: number, month: number): number => {
  let count = 0;
  const date = new Date(year, month - 1, 1);

  while (date.getMonth() === month - 1) {
    if (date.getDay() === 6) {
      count++;
    }
    date.setDate(date.getDate() + 1);
  }

  return count;
};

export const countSaturdays = (dates: string[]): number => {
  return dates.filter((dateStr) => {
    const date = new Date(dateStr);
    return date.getDay() === 6;
  }).length;
};

export const getDaysInMonth = (year: number, month: number): number => {
  const date = new Date(year, month, 1);
  date.setDate(date.getDate() - 1);
  return date.getDate();
};

export const getWeekDates = (weekOffset = 0) => {
  const today = new Date();
  const dayOfWeek = today.getDay();

  const sunday = new Date(today);
  sunday.setDate(today.getDate() - dayOfWeek + weekOffset * 7);

  const weekDates = [];

  for (let i = 0; i < 7; i++) {
    const date = new Date(sunday);
    date.setDate(sunday.getDate() + i);
    const formattedDate = date.toISOString().split('T')[0];
    weekDates.push(formattedDate);
  }

  return weekDates;
};

const Hours = () => {
  const { currentUser } = useContext(CurrentUserContext);

  const { policyInfo } = useContext(PolicyInfoContext);

  const [open, setOpen] = useState(false);

  const weekDates = getWeekDates();

  const [allLeaves, setAllLeaves] =
    useState<[{ attributes: { to: string; from: string; half_leave: string } }]>();
  const [todayLeave, setTodayLeave] = useState<string>();
  const [weekLeaves, setWeekLeaves] = useState<number>(0);
  const [monthLeaves, setMonthLeaves] = useState<number>(0);
  // const [saturdaysInWeekLeaves, setSaturdaysInWeekLeaves] = useState<number>(0);
  // const [saturdaysInMonthLeaves, setSaturdaysInMonthLeaves] = useState<number>(0);
  const [holidaysInWeekLeaves, setHolidaysInWeekLeaves] = useState<number>(0);
  const [holidaysInMonthLeaves, setHolidaysInMonthLeaves] = useState<number>(0);

  const [checkIns, setCheckIns] = useState<any>();

  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

  const getLeaves = () => {
    axios
      .get(
        `${process.env.REACT_APP_strapiUrl}/leaves?pagination[pageSize]=32&filters[attendance_user][id][$eq]=${currentUser?.id}&filters[status][$eq]=Approved&sort=createdAt%3Adesc`,
        {
          headers: headers,
        },
      )
      .then((res) => setAllLeaves(res?.data?.data))
      .catch(() => failed());
  };

  useEffect(() => {
    getLeaves();
  }, [currentUser]);

  const [holidays, setHolidays] = useState<[{ start: { date: string } }]>();

  const holidaysInWeek =
    holidays !== undefined
      ? getMatchingValues(
          holidays?.map((holiday) => holiday.start.date),
          weekDates,
        )
      : [];
  const holidaysInMonth =
    holidays !== undefined
      ? holidays
          ?.filter(
            (holiday) =>
              holiday?.start?.date?.substring(0, 7) ===
              new Date().toISOString().split('T')[0]?.substring(0, 7),
          )
          ?.map((holiday) => holiday?.start?.date)
      : [];
  // const saturdaysInWeekHolidays = countSaturdays(holidaysInWeek);
  // const saturdaysInMonthHolidays = countSaturdays(holidaysInMonth);

  useEffect(() => {
    if (allLeaves) {
      const singleDayFullLeaves = allLeaves?.filter(
        (leave) => leave?.attributes?.to === null && leave?.attributes?.half_leave === 'Full',
      );
      const singleDayFullLeavesArray = singleDayFullLeaves?.map((leave) => leave?.attributes?.from);
      const multiDaysFullLeaves = allLeaves?.filter(
        (leave) => leave?.attributes?.to !== null && leave?.attributes?.half_leave === 'Full',
      );
      const multiDaysFullLeavesArray = multiDaysFullLeaves
        ?.map((leave) => getDatesInRange(leave?.attributes?.from, leave?.attributes?.to))
        ?.flat();
      const fullLeavesArray = singleDayFullLeavesArray?.concat(multiDaysFullLeavesArray);
      const weeklyFullLeavesArray = getMatchingValues(weekDates, fullLeavesArray);
      const monthlyFullLeavesArray = fullLeavesArray?.filter(
        (leave) =>
          leave?.substring(5, 7) === new Date().toISOString().split('T')[0]?.substring(5, 7),
      );
      const singleDayHalfLeaves = allLeaves?.filter(
        (leave) => leave?.attributes?.to === null && leave?.attributes?.half_leave !== 'Full',
      );
      const singleDayHalfLeavesArray = singleDayHalfLeaves?.map((leave) => leave?.attributes?.from);
      const multiDaysHalfLeaves = allLeaves?.filter(
        (leave) => leave?.attributes?.to !== null && leave?.attributes?.half_leave !== 'Full',
      );
      const multiDaysHalfLeavesArray = multiDaysHalfLeaves
        ?.map((leave) => getDatesInRange(leave?.attributes?.from, leave?.attributes?.to))
        ?.flat();
      const halfLeavesArray = singleDayHalfLeavesArray?.concat(multiDaysHalfLeavesArray);
      const weeklyHalfLeavesArray = getMatchingValues(weekDates, halfLeavesArray);
      const monthlyHalfLeavesArray = halfLeavesArray?.filter(
        (leave) =>
          leave?.substring(5, 7) === new Date().toISOString().split('T')[0]?.substring(5, 7),
      );
      const totalWeeklyLeaves = weeklyFullLeavesArray?.length + weeklyHalfLeavesArray?.length * 0.5;
      const totalMonthlyLeaves =
        monthlyFullLeavesArray?.length + monthlyHalfLeavesArray?.length * 0.5;
      if (fullLeavesArray?.includes(new Date().toISOString().split('T')[0])) {
        setTodayLeave('full');
      } else if (
        halfLeavesArray?.includes(new Date().toISOString().split('T')[0]) &&
        !fullLeavesArray?.includes(new Date().toISOString().split('T')[0])
      ) {
        setTodayLeave('half');
      } else setTodayLeave('none');
      setWeekLeaves(totalWeeklyLeaves);
      setMonthLeaves(totalMonthlyLeaves);
      // const saturdaysInMonthlyLeaves =
      //   countSaturdays(monthlyFullLeavesArray) + countSaturdays(monthlyHalfLeavesArray) * 0.5;
      // const saturdaysInWeeklyLeaves =
      //   countSaturdays(weeklyFullLeavesArray) + countSaturdays(weeklyHalfLeavesArray) * 0.5;
      // setSaturdaysInMonthLeaves(saturdaysInMonthlyLeaves);
      // setSaturdaysInWeekLeaves(saturdaysInWeeklyLeaves);
      const holidaysInWeeklyLeaves =
        getMatchingValues(weeklyFullLeavesArray, holidaysInWeek)?.length +
        getMatchingValues(weeklyHalfLeavesArray, holidaysInWeek)?.length * 0.5;
      const holidaysInMonthlyLeaves =
        getMatchingValues(monthlyFullLeavesArray, holidaysInMonth)?.length +
        getMatchingValues(monthlyHalfLeavesArray, holidaysInMonth)?.length * 0.5;
      setHolidaysInWeekLeaves(holidaysInWeeklyLeaves);
      setHolidaysInMonthLeaves(holidaysInMonthlyLeaves);

      // let weeklyMultiDaysFullLeavesCount = 0;
      // let weeklyMultiDaysHalfLeavesCount = 0;
      // const weeklySingleDayFullLeaves = allLeaves?.filter((leave: { attributes: { to: string, from: string, half_leave: string } }) => weekDates?.includes(leave?.attributes?.from) && leave?.attributes?.to === null && leave?.attributes?.half_leave === 'Full');
      // const weeklySingleDayHalfLeaves = allLeaves?.filter((leave: { attributes: { to: string, from: string, half_leave: string } }) => weekDates?.includes(leave?.attributes?.from) && leave?.attributes?.to === null && leave?.attributes?.half_leave !== 'Full');
      // const weeklySingleDayLeavesCount = weeklySingleDayFullLeaves?.length + weeklySingleDayHalfLeaves?.length * 0.5;
      // const weeklyMultiDaysFullLeaves = allLeaves?.filter((leave: { attributes: { to: string, from: string, half_leave: string } }) => getMatchingValues(weekDates, getDatesInRange(leave?.attributes?.from, leave?.attributes?.to))?.length > 0 && leave?.attributes?.to !== null && leave?.attributes?.half_leave === 'Full');
      // const weeklyMultiDaysHalfLeaves = allLeaves?.filter((leave: { attributes: { to: string, from: string, half_leave: string } }) => getMatchingValues(weekDates, getDatesInRange(leave?.attributes?.from, leave?.attributes?.to))?.length > 0 && leave?.attributes?.to !== null && leave?.attributes?.half_leave !== 'Full');
      // weeklyMultiDaysHalfLeaves?.map((leave: { attributes: { from: string, to: string } }) => {
      //     const dateRange = getDatesInRange(leave?.attributes?.from, leave?.attributes?.to);
      //     weeklyMultiDaysHalfLeavesCount = weeklyMultiDaysHalfLeavesCount + getMatchingValues(weekDates, dateRange)?.length;
      // });
      // weeklyMultiDaysFullLeaves?.map((leave: { attributes: { from: string, to: string } }) => {
      //     const dateRange = getDatesInRange(leave?.attributes?.from, leave?.attributes?.to);
      //     weeklyMultiDaysFullLeavesCount = weeklyMultiDaysFullLeavesCount + getMatchingValues(weekDates, dateRange)?.length;
      // });
      // const weeklyMultiDaysLeavesCount = weeklyMultiDaysFullLeavesCount + weeklyMultiDaysHalfLeavesCount * 0.5;
      // const weeklyLeavesTotalCount = weeklySingleDayLeavesCount + weeklyMultiDaysLeavesCount;
      // setWeekLeaves(weeklyLeavesTotalCount);

      // let monthlyMultiDaysFullLeavesCount = 0;
      // let monthlyMultiDaysHalfLeavesCount = 0;
      // const monthlySingleDayFullLeaves = allLeaves?.filter((leave: { attributes: { to: string, from: string, half_leave: string } }) => monthDates?.includes(leave?.attributes?.from) && leave?.attributes?.to === null && leave?.attributes?.half_leave === 'Full');
      // const monthlySingleDayHalfLeaves = allLeaves?.filter((leave: { attributes: { to: string, from: string, half_leave: string } }) => monthDates?.includes(leave?.attributes?.from) && leave?.attributes?.to === null && leave?.attributes?.half_leave !== 'Full');
      // const monthlySingleDayLeavesCount = monthlySingleDayFullLeaves?.length + monthlySingleDayHalfLeaves?.length * 0.5;
      // const monthlyMultiDaysFullLeaves = allLeaves?.filter((leave: { attributes: { to: string, from: string, half_leave: string } }) => getMatchingValues(monthDates, getDatesInRange(leave?.attributes?.from, leave?.attributes?.to))?.length > 0 && leave?.attributes?.to !== null && leave?.attributes?.half_leave === 'Full');
      // const monthlyMultiDaysHalfLeaves = allLeaves?.filter((leave: { attributes: { to: string, from: string, half_leave: string } }) => getMatchingValues(monthDates, getDatesInRange(leave?.attributes?.from, leave?.attributes?.to))?.length > 0 && leave?.attributes?.to !== null && leave?.attributes?.half_leave !== 'Full');
      // monthlyMultiDaysHalfLeaves?.map((leave: { attributes: { from: string, to: string } }) => {
      //     const dateRange = getDatesInRange(leave?.attributes?.from, leave?.attributes?.to);
      //     monthlyMultiDaysHalfLeavesCount = monthlyMultiDaysHalfLeavesCount + getMatchingValues(monthDates, dateRange)?.length;
      // });
      // monthlyMultiDaysFullLeaves?.map((leave: { attributes: { from: string, to: string } }) => {
      //     const dateRange = getDatesInRange(leave?.attributes?.from, leave?.attributes?.to);
      //     monthlyMultiDaysFullLeavesCount = monthlyMultiDaysFullLeavesCount + getMatchingValues(monthDates, dateRange)?.length;
      // });
      // const monthlyMultiDaysLeavesCount = monthlyMultiDaysFullLeavesCount + monthlyMultiDaysHalfLeavesCount * 0.5;
      // const monthlyLeavesTotalCount = monthlySingleDayLeavesCount + monthlyMultiDaysLeavesCount;
      // setMonthLeaves(monthlyLeavesTotalCount);
    }
  }, [allLeaves]);

  const generalHours = policyInfo?.general_hours;

  const getHolidays = () => {
    axios
      .get(
        `${process.env.REACT_APP_calendarApiUrl}/calendar/events/e81b5c1c828bbd9f57ff94b5082cfa57220252d7b25d4e2db07b442f012efda0@group.calendar.google.com`,
      )
      .then((res) => setHolidays(res.data))
      .catch(() => failed());
  };

  useEffect(() => {
    getHolidays();
  }, []);

  const getCheckIns = () => {
    currentUser !== undefined &&
      axios
        .get(
          `${process.env.REACT_APP_strapiUrl}/check-ins?pagination[pageSize]=250&filters[attendance_user][id][$eq]=${currentUser?.id}`,
          {
            headers: headers,
          },
        )
        .then((res) => setCheckIns(res?.data?.data))
        .catch(() => failed());
  };

  useEffect(() => {
    getCheckIns();
  }, [currentUser]);

  const [todayCheckIn, setTodayCheckIn] =
    useState<[{ attributes: { in_time: string; out_time: string | null } }]>();
  const [weekCheckIns, setWeekCheckIns] =
    useState<[{ attributes: { in_time: string; out_time: string | null } }]>();
  const [monthlyCheckIns, setMonthlyCheckIns] =
    useState<[{ attributes: { in_time: string; out_time: string | null } }]>();

  useEffect(() => {
    setTodayCheckIn(
      checkIns?.filter(
        (checkIn: { attributes: { date: string } }) =>
          checkIn?.attributes?.date === new Date().toISOString().split('T')[0],
      ),
    );
    setWeekCheckIns(
      checkIns?.filter((checkIn: { attributes: { date: string } }) =>
        weekDates?.includes(checkIn?.attributes?.date),
      ),
    );
    setMonthlyCheckIns(
      checkIns?.filter(
        (checkIn: { attributes: { date: string } }) =>
          checkIn?.attributes?.date?.substring(5, 7) ===
          new Date().toISOString().split('T')[0]?.substring(5, 7),
      ),
    );
  }, [checkIns]);

  const [dailyHours, setDailyHours] = useState<string>();
  const [weeklyHours, setWeeklyHours] = useState<string>();
  const [monthlyHours, setMonthlyHours] = useState<string>();

  const totalTimeCalculator = (timeArray: string[]) => {
    const calculateTotalTime = (timeArray: string[]) => {
      let totalHours = 0;
      let totalMinutes = 0;

      timeArray?.forEach((timeStr: any) => {
        const [hoursStr, minutesStr] = timeStr.split('h ');
        const hours = parseInt(hoursStr);
        const minutes = parseInt(minutesStr?.replace('m', ''));

        totalHours += hours;
        totalMinutes += minutes;
      });

      totalHours += Math.floor(totalMinutes / 60);
      totalMinutes %= 60;

      return { totalHours, totalMinutes };
    };

    const { totalHours, totalMinutes } = calculateTotalTime(timeArray);

    return `${totalHours}h ${totalMinutes}m`;
  };

  const [timeRefresh, setTimeRefresh] = useState(false);

  setTimeout(() => {
    timeRefresh ? setTimeRefresh(false) : setTimeRefresh(true);
  }, 3600000);

  // useEffect(() => {
  //   setDailyHours(
  //     extractTime(
  //       todayCheckIn !== undefined && todayCheckIn?.length > 0
  //         ? todayCheckIn[0]?.attributes?.in_time
  //         : '00:00:00.000',
  //       todayCheckIn !== undefined && todayCheckIn?.length > 0
  //         ? todayCheckIn[0]?.attributes?.out_time !== null
  //           ? todayCheckIn[0]?.attributes?.out_time
  //           : getCurrentTime()
  //         : '00:00:00.000',
  //     ),
  //   );
  // }, [todayCheckIn, timeRefresh]);

  useEffect(() => {
    const allCheckIns = todayCheckIn
      ?.filter?.((checkIn) => checkIn?.attributes?.out_time !== null)
      ?.map((checkIn) => [checkIn?.attributes?.in_time, checkIn?.attributes?.out_time]);
    const timeArray = allCheckIns?.map((checkIn) =>
      extractTime(checkIn[0], checkIn[1] !== null ? checkIn[1] : getCurrentTime()),
    );
    setDailyHours(totalTimeCalculator(timeArray !== undefined ? timeArray : ['0h', '0m']));
  }, [weekCheckIns, timeRefresh]);

  useEffect(() => {
    const allCheckIns = weekCheckIns
      ?.filter?.((checkIn) => checkIn?.attributes?.out_time !== null)
      ?.map((checkIn) => [checkIn?.attributes?.in_time, checkIn?.attributes?.out_time]);
    const timeArray = allCheckIns?.map((checkIn) =>
      extractTime(checkIn[0], checkIn[1] !== null ? checkIn[1] : getCurrentTime()),
    );
    setWeeklyHours(totalTimeCalculator(timeArray !== undefined ? timeArray : ['0h', '0m']));
  }, [weekCheckIns, timeRefresh]);

  useEffect(() => {
    const allCheckIns = monthlyCheckIns
      ?.filter?.((checkIn) => checkIn?.attributes?.out_time !== null)
      ?.map((checkIn) => [checkIn?.attributes?.in_time, checkIn?.attributes?.out_time]);
    const timeArray = allCheckIns?.map((checkIn) =>
      extractTime(checkIn[0], checkIn[1] !== null ? checkIn[1] : getCurrentTime()),
    );
    setMonthlyHours(totalTimeCalculator(timeArray !== undefined ? timeArray : ['0h', '0m']));
  }, [monthlyCheckIns, timeRefresh]);

  const weekDay = handleDay(JSON.stringify(new Date().toLocaleDateString('en-US')));

  const totalDailyHours =
    weekDay?.toLowerCase() === 'saturday'
      ? 0
      : holidays !== undefined &&
        holidays?.filter((holiday) => holiday?.start?.date === getCurrentDate())?.length > 0
      ? 0
      : todayLeave === 'none'
      ? currentUser?.attributes?.edited_hours === null
        ? generalHours
        : currentUser?.attributes?.edited_hours
      : todayLeave === 'half'
      ? (currentUser?.attributes?.edited_hours === null
          ? generalHours
          : currentUser?.attributes?.edited_hours) * 0.5
      : 0;

  const totalWeeklyHours =
    (currentUser?.attributes?.edited_hours === null
      ? generalHours
      : currentUser?.attributes?.edited_hours) *
    (7 - weekLeaves - holidaysInWeek?.length + holidaysInWeekLeaves);
  // +saturdaysInWeekLeaves +
  // saturdaysInWeekHolidays);

  const totalMonthlyHours =
    (currentUser?.attributes?.edited_hours === null
      ? generalHours
      : currentUser?.attributes?.edited_hours) *
    (getDaysInMonth(
      parseInt(new Date().toISOString().split('T')[0]?.split('-')[0]),
      parseInt(new Date().toISOString().split('T')[0]?.split('-')[1]),
    ) -
      // getSaturdaysInMonth(
      //   parseInt(new Date().toISOString().split('T')[0]?.split('-')[0]),
      //   parseInt(new Date().toISOString().split('T')[0]?.split('-')[1]),
      // ) -
      monthLeaves -
      holidaysInMonth?.length +
      holidaysInMonthLeaves);
  // +saturdaysInMonthLeaves +
  // saturdaysInMonthHolidays);

  const [achievedInternship, setAchievedInternship] = useState<number>(0);

  const getInternshipPeriod = () => {
    if (currentUser?.attributes?.title?.toLowerCase() === 'intern') {
      const hoursArray = checkIns?.map((checkIn: any) =>
        checkIn?.attributes?.out_time
          ? extractHours(checkIn?.attributes?.in_time, checkIn?.attributes?.out_time)
          : 0,
      );
      const totalHours = hoursArray?.reduce(
        (accumulator: number, currentValue: number) => accumulator + currentValue,
        0,
      );
      setAchievedInternship(totalHours);
    }
  };

  useEffect(() => {
    getInternshipPeriod();
  }, [currentUser, checkIns]);

  const dailyGaugeData = {
    value: !dailyHours ? 0 : parseInt(dailyHours?.split('h')[0]),
    maxValue: !totalDailyHours ? 0 : totalDailyHours,
    label: 'Today',
    color:
      (!dailyHours ? 0 : parseInt(dailyHours?.split('h')[0])) <= totalDailyHours / 4
        ? '#FF7A00'
        : (!dailyHours ? 0 : parseInt(dailyHours?.split('h')[0])) <= totalDailyHours / 2
        ? '#FEC400'
        : (!dailyHours ? 0 : parseInt(dailyHours?.split('h')[0])) <=
          totalDailyHours / 1.333333333333333
        ? '#00E309'
        : '#00800D',
  };

  const weeklyGaugeData = {
    value: !weeklyHours ? 0 : parseInt(weeklyHours?.split('h')[0]),
    maxValue: !totalWeeklyHours ? 0 : totalWeeklyHours,
    label: 'This Week',
    color:
      (!weeklyHours ? 0 : parseInt(weeklyHours?.split('h')[0])) <= totalWeeklyHours / 4
        ? '#FF7A00'
        : (!weeklyHours ? 0 : parseInt(weeklyHours?.split('h')[0])) <= totalWeeklyHours / 2
        ? '#FEC400'
        : (!weeklyHours ? 0 : parseInt(weeklyHours?.split('h')[0])) <=
          totalWeeklyHours / 1.333333333333333
        ? '#00E309'
        : '#00800D',
  };

  const monthlyGaugeData = {
    value: !monthlyHours ? 0 : parseInt(monthlyHours?.split('h')[0]),
    maxValue: !totalMonthlyHours ? 0 : totalMonthlyHours,
    label: 'This Month',
    color:
      (!monthlyHours ? 0 : parseInt(monthlyHours?.split('h')[0])) <= totalMonthlyHours / 4
        ? '#FF7A00'
        : (!monthlyHours ? 0 : parseInt(monthlyHours?.split('h')[0])) <= totalMonthlyHours / 2
        ? '#FEC400'
        : (!monthlyHours ? 0 : parseInt(monthlyHours?.split('h')[0])) <=
          totalMonthlyHours / 1.333333333333333
        ? '#00E309'
        : '#00800D',
  };

  const internGaugeData = {
    value: achievedInternship,
    maxValue: 832,
    label: 'Internship Period',
    color:
      achievedInternship <= 208
        ? '#FF7A00'
        : achievedInternship <= 416
        ? '#FEC400'
        : achievedInternship <= 624
        ? '#00E309'
        : '#00800D',
  };

  return (
    <>
      {/* <AttendanceBlock>
                <Info section='Hours' />
                <Box display='flex' flexDirection='column' gap='5px' alignItems='center' onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
                    <AccessTimeIcon sx={{ width: '35px', height: '35px' }} />
                    <b>Hours</b>
                </Box> */}
      {currentUser?.attributes?.title?.toLowerCase() === 'intern' ? (
        <>
          <Box display='flex' flexDirection='column' mt='10px' width='calc(100% + 30px)' mx='-15px'>
            <Box display='flex' bgcolor='white' justifyContent='space-between' p='5px 15px'>
              <Typography fontSize='15px'>Today</Typography>
              <Typography fontSize='15px'>{dailyHours}</Typography>
            </Box>

            <Box display='flex' justifyContent='space-between' p='5px 15px'>
              <Typography fontSize='15px'>This Week</Typography>
              <Typography fontSize='15px'>{weeklyHours}</Typography>
            </Box>

            <Box display='flex' bgcolor='white' justifyContent='space-between' p='5px 15px'>
              <Typography fontSize='15px'>This Month</Typography>
              <Typography fontSize='15px'>{monthlyHours}</Typography>
            </Box>
          </Box>

          {/* <Box sx={{ width: '100%', mt: '15px', display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ width: '200px', position: 'relative', height: '120px' }}>
              <PieChart
                tooltip={{ trigger: 'none' }}
                series={[
                  {
                    data: [
                      {
                        value: achievedInternship,
                        color:
                          achievedInternship <= 208
                            ? '#FF7A00'
                            : achievedInternship <= 416
                            ? '#FEC400'
                            : achievedInternship <= 624
                            ? '#00E309'
                            : '#00800D',
                      },
                      {
                        value: 832 - achievedInternship,
                        color: 'gray',
                      },
                    ],
                    innerRadius: '200%',
                    outerRadius: '150%',
                    paddingAngle: 1,
                    cornerRadius: 3,
                    startAngle: -90,
                    endAngle: 90,
                    cx: '100%',
                    cy: '50%',
                  },
                ]}
                width={200}
                height={200}
              />
              <Box
                display='flex'
                width='125px'
                flexDirection='column'
                alignItems='center'
                sx={{
                  position: 'absolute',
                  bottom: '-30px',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Typography fontWeight='bold' fontSize='24px'>
                  {achievedInternship}
                  /832h
                </Typography>
                <Typography fontSize='15px' mb='5px' mt='-5px'>
                  Internship Period
                </Typography>
              </Box>
            </Box>
          </Box> */}

          <Box display='flex' justifyContent='center' width='100%' mt='5px'>
            <GaugeChart gaugeData={internGaugeData} />
          </Box>
        </>
      ) : (
        // <Box
        //   sx={{
        //     width: '100%',
        //     mt: '15px',
        //     display: 'flex',
        //     flexDirection: 'column',
        //     gap: '25px',
        //     alignItems: 'center',
        //   }}
        // >
        //   <Box sx={{ width: '200px', position: 'relative', height: '120px' }}>
        //     <PieChart
        //       tooltip={{ trigger: 'none' }}
        //       series={[
        //         {
        //           data: [
        //             {
        //               value: !dailyHours ? 0 : parseInt(dailyHours?.split('h')[0]),
        //               color:
        // (!dailyHours ? 0 : parseInt(dailyHours?.split('h')[0])) <=
        // totalDailyHours / 4
        //   ? '#FF7A00'
        //   : (!dailyHours ? 0 : parseInt(dailyHours?.split('h')[0])) <=
        //     totalDailyHours / 2
        //   ? '#FEC400'
        //   : (!dailyHours ? 0 : parseInt(dailyHours?.split('h')[0])) <=
        //     totalDailyHours / 1.333333333333333
        //   ? '#00E309'
        //   : '#00800D',
        //             },
        //             {
        //               value:
        //                 totalDailyHours > 0
        //                   ? totalDailyHours -
        //                     parseInt(!dailyHours ? '0' : dailyHours?.split('h')[0])
        //                   : dailyHours
        //                   ? parseInt(dailyHours) > 0
        //                     ? 0
        //                     : 1
        //                   : 1,
        //               color: 'gray',
        //             },
        //           ],
        //           innerRadius: '200%',
        //           outerRadius: '150%',
        //           paddingAngle: 1,
        //           cornerRadius: 3,
        //           startAngle: -90,
        //           endAngle: 90,
        //           cx: '100%',
        //           cy: '100%',
        //         },
        //       ]}
        //       width={200}
        //       height={100}
        //     />
        //     <Box
        //       display='flex'
        //       width='125px'
        //       flexDirection='column'
        //       alignItems='center'
        //       sx={{
        //         position: 'absolute',
        //         bottom: '-30px',
        //         left: '50%',
        //         transform: 'translate(-50%, -50%)',
        //       }}
        //     >
        //       <Typography fontWeight='bold' fontSize='24px'>
        //         {!dailyHours ? 0 : parseInt(dailyHours?.split('h')[0])}/{totalDailyHours}h
        //       </Typography>
        //       <Typography fontSize='15px' mb='5px' mt='-5px'>
        //         Today
        //       </Typography>
        //     </Box>
        //   </Box>

        // <Box sx={{ width: '200px', position: 'relative', height: '120px' }}>
        //   <PieChart
        //     tooltip={{ trigger: 'none' }}
        //     series={[
        //       {
        //         data: [
        //           {
        //             value: !weeklyHours ? 0 : parseInt(weeklyHours?.split('h')[0]),
        //             color:
        // (!weeklyHours ? 0 : parseInt(weeklyHours?.split('h')[0])) <=
        // totalWeeklyHours / 4
        //   ? '#FF7A00'
        //   : (!weeklyHours ? 0 : parseInt(weeklyHours?.split('h')[0])) <=
        //     totalWeeklyHours / 2
        //   ? '#FEC400'
        //   : (!weeklyHours ? 0 : parseInt(weeklyHours?.split('h')[0])) <=
        //     totalWeeklyHours / 1.333333333333333
        //   ? '#00E309'
        //   : '#00800D',
        //           },
        //           {
        //             value:
        //               totalWeeklyHours > 0
        //                 ? totalWeeklyHours -
        //                   parseInt(!weeklyHours ? '0' : weeklyHours?.split('h')[0])
        //                 : weeklyHours
        //                 ? parseInt(weeklyHours) > 0
        //                   ? 0
        //                   : 1
        //                 : 1,
        //             color: 'gray',
        //           },
        //         ],
        //         innerRadius: '200%',
        //         outerRadius: '150%',
        //         paddingAngle: 1,
        //         cornerRadius: 3,
        //         startAngle: -90,
        //         endAngle: 90,
        //         cx: '100%',
        //         cy: '100%',
        //       },
        //     ]}
        //     width={200}
        //     height={100}
        //   />
        //   <Box
        //     display='flex'
        //     width='125px'
        //     flexDirection='column'
        //     alignItems='center'
        //     sx={{
        //       position: 'absolute',
        //       bottom: '-30px',
        //       left: '50%',
        //       transform: 'translate(-50%, -50%)',
        //     }}
        //   >
        //     <Typography fontWeight='bold' fontSize='24px'>
        // {!weeklyHours ? 0 : parseInt(weeklyHours?.split('h')[0])}/{totalWeeklyHours}h
        //     </Typography>
        //     <Typography fontSize='15px' mb='5px' mt='-5px'>
        //       This Week
        //     </Typography>
        //   </Box>
        // </Box>

        //   <Box sx={{ width: '200px', position: 'relative', height: '120px' }}>
        //     <PieChart
        //       tooltip={{ trigger: 'none' }}
        //       series={[
        //         {
        //           data: [
        //             {
        //               value: !monthlyHours ? 0 : parseInt(monthlyHours?.split('h')[0]),
        //               color:
        // (!monthlyHours ? 0 : parseInt(monthlyHours?.split('h')[0])) <=
        // totalMonthlyHours / 4
        //   ? '#FF7A00'
        //   : (!monthlyHours ? 0 : parseInt(monthlyHours?.split('h')[0])) <=
        //     totalMonthlyHours / 2
        //   ? '#FEC400'
        //   : (!monthlyHours ? 0 : parseInt(monthlyHours?.split('h')[0])) <=
        //     totalMonthlyHours / 1.333333333333333
        //   ? '#00E309'
        //   : '#00800D',
        //             },
        //             {
        //               value:
        //                 totalMonthlyHours > 0
        //                   ? totalMonthlyHours -
        //                     parseInt(!monthlyHours ? '0' : monthlyHours?.split('h')[0])
        //                   : monthlyHours
        //                   ? parseInt(monthlyHours) > 0
        //                     ? 0
        //                     : 1
        //                   : 1,
        //               color: 'gray',
        //             },
        //           ],
        //           innerRadius: '200%',
        //           outerRadius: '150%',
        //           paddingAngle: 1,
        //           cornerRadius: 3,
        //           startAngle: -90,
        //           endAngle: 90,
        //           cx: '100%',
        //           cy: '100%',
        //         },
        //       ]}
        //       width={200}
        //       height={100}
        //     />
        //     <Box
        //       display='flex'
        //       width='125px'
        //       flexDirection='column'
        //       alignItems='center'
        //       sx={{
        //         position: 'absolute',
        //         bottom: '-30px',
        //         left: '50%',
        //         transform: 'translate(-50%, -50%)',
        //       }}
        //     >
        //       <Typography fontWeight='bold' fontSize='24px'>
        //         {!monthlyHours ? 0 : parseInt(monthlyHours?.split('h')[0])}/{totalMonthlyHours}h
        //       </Typography>
        //       <Typography fontSize='15px' mb='5px' mt='-5px'>
        //         This Month
        //       </Typography>
        //     </Box>
        //   </Box>
        // </Box>

        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          width='100%'
          gap='15px'
          mt='10px'
        >
          <GaugeChart gaugeData={dailyGaugeData} />
          <Box style={{ display: 'flex', gap: '25px', justifyContent: 'center' }}>
            <GaugeChart gaugeData={weeklyGaugeData} />
            <GaugeChart gaugeData={monthlyGaugeData} />
          </Box>
        </Box>
      )}
      {/* </AttendanceBlock> */}

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Box display='flex' justifyContent='space-between' alignItems='center' mb='20px'>
            <Box display='flex' alignItems='center' gap='8px'>
              <AccessTimeIcon />
              <Typography variant='h6' fontWeight='bold'>
                Hours
              </Typography>
            </Box>
            <DialogActions>
              {/* <CloseIcon onClick={() => setOpen(false)} cursor='pointer' /> */}
            </DialogActions>
          </Box>
          <Box display='flex' gap='100px'>
            <Box display='flex' flexDirection='column' gap='15px'>
              <Typography>Today:</Typography>
              <Typography>This week:</Typography>
              <Typography>This month:</Typography>
            </Box>
            <Box alignItems='flex-end' display='flex' flexDirection='column' gap='15px'>
              <Typography>{dailyHours}</Typography>
              <Typography>{weeklyHours}</Typography>
              <Typography>{monthlyHours}</Typography>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Hours;
