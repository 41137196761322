import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const FilterDueDate = () => {
  const [searchParams, setQueryParams] = useSearchParams();
  const [filterDueDate, setFilterDueDate] = useState('');

  useEffect(() => {
    setFilterDueDate(searchParams.get('dueDate') ?? '');
  }, [searchParams]);

  const sortDueDate = (event: SelectChangeEvent) => {
    const queryParams = Object.fromEntries(searchParams);
    if (event.target.value === 'all') {
      delete queryParams.dueDate;
    } else {
      queryParams.dueDate = event.target.value as string;
    }
    setQueryParams(queryParams);
  };

  return (
    <>
      <FormControl size='small' variant='standard'>
        <InputLabel sx={{ fontWeight: 'bold', color: '#000000DE', fontSize: '12px' }}>
          Due Date
        </InputLabel>
        <Select
          disableUnderline
          size='small'
          label='Type'
          value={filterDueDate}
          onChange={sortDueDate}
          sx={{
            fontSize: '12px',
            width: '70px',
            mb: '8px',
            '.MuiSelect-icon': {
              color: '#000000DE',
              mt: '-2px',
            },
          }}
        >
          <MenuItem value='all'>All</MenuItem>
          <MenuItem value='asc'>Ascending</MenuItem>
          <MenuItem value='desc'>Descending</MenuItem>
          <MenuItem value='current'>Current Sprint</MenuItem>
          <MenuItem value='next'>Next Sprint</MenuItem>
          <MenuItem value='prev'>Previous Sprint</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default FilterDueDate;
