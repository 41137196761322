import {
  Box,
  FormControl,
  ImageList,
  ImageListItem,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import BottomNavbar from '../../components/Mobile/BottomNavbar';
import logo from '../../assets/images/sciever_logo_png.webp';
import Details from '../../components/Attendance/Details';
import CheckInOut from '../../components/Attendance/CheckInOut';
import { AttendanceBlock } from '../Desktop/DesktopAttendanceLayout';
// import Leave from '../../components/Attendance/Leave';
import Holidays from '../../components/Attendance/Holidays';
// import Report from '../../components/Attendance/Report';
import isAdmin from '../../hooks/isAdmin';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { CurrentUserContext, LoadingContext } from '../../App';
import { AttendanceUsersContext, CombinedReportContext } from '../../routes/Main';
import { attendanceUsersSort } from '../../utils/exports/Function';

const Leave = React.lazy(() => import('../../components/Attendance/Leave'));
const Report = React.lazy(() => import('../../components/Attendance/Report'));

const MobileAttendanceLayout = () => {
  const theme = useTheme();
  const admin = isAdmin();
  const superAdmin = isSuperAdmin();

  const { setCombinedReport } = useContext(CombinedReportContext);

  const { setLoading } = useContext(LoadingContext);

  const { attendanceUsers } = useContext(AttendanceUsersContext);

  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);

  const [currentUserId, setCurrentUserId] = useState<number>(currentUser?.id);

  useEffect(() => {
    currentUser ? setLoading(false) : setLoading(true);
  }, [currentUser]);

  // useEffect(() => {
  //   currentUserId === 0 ? setCombinedReport(true) : setCombinedReport(false);
  //   currentUserId !== 0 &&
  //     setCurrentUser(attendanceUsers?.filter((user: any) => user?.id === currentUserId)[0]);
  // }, [currentUserId]);

  const sortedUsers = attendanceUsersSort(attendanceUsers);

  useEffect(() => {
    setCurrentUserId(currentUser?.id);
  }, [currentUser]);

  return (
    <>
      <Box
        display='flex'
        px='15px'
        justifyContent='space-between'
        alignItems='center'
        position='fixed'
        left='0px'
        right='0px'
        bgcolor={theme.palette.background.default}
        zIndex='1'
        height='60px'
      >
        <Box display='flex' alignItems='center' gap='10px'>
          <ImageList
            sx={{
              width: '35px',
              height: '35px',
              overflow: 'hidden',
              m: '0px',
            }}
            cols={1}
          >
            <ImageListItem>
              <img src={logo} alt='Sciever Inc. Logo' style={{ width: '35px', height: '35px' }} />
            </ImageListItem>
          </ImageList>

          <Typography variant='h6' fontSize='18px' fontWeight='bold' noWrap>
            HR Management
          </Typography>
        </Box>

        {(superAdmin || admin) && (
          // <PersonIcon onClick={() => setUsersOpen(true)} sx={{ cursor: 'pointer' }} />
          <FormControl size='small'>
            <Select
              value={currentUserId !== undefined ? currentUserId : ''}
              aria-label='attributes-name-select'
              sx={{ height: '30px', pb: '1px' }}
              onChange={(e: any) => {
                setCurrentUserId(e.target.value);
                e.target.value !== 0 &&
                  setCurrentUser(
                    attendanceUsers?.filter((user: any) => user?.id === e.target.value)[0],
                  );
                e.target.value === 0 ? setCombinedReport(true) : setCombinedReport(false);
              }}
            >
              <MenuItem aria-label='all' value={0}>
                All
              </MenuItem>
              {sortedUsers?.map((user: { attributes: { name: string }; id: number }) => {
                return (
                  <MenuItem aria-label='attributes-name' value={user?.id} key={user?.id}>
                    {user?.attributes?.name?.split(' ')[0]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </Box>

      <Box pt='61px'>
        <Box
          display='flex'
          flexDirection='column'
          bgcolor='white'
          borderRadius='5px'
          m='0px 15px 65px 15px'
          p='15px 15px 15px 15px'
          gap='15px'
          justifyContent='space-between'
        >
          <Details />
          <CheckInOut />
          <AttendanceBlock sx={{ border: 'none', p: '0px', cursor: 'default' }}>
            <Box display='flex' flexDirection='column' gap='10px' height='100%' width='100%'>
              <Suspense fallback={null}>
                <Leave />
              </Suspense>
              <Holidays />
            </Box>
          </AttendanceBlock>
          <Suspense fallback={null}>
            <Report />
          </Suspense>
        </Box>
      </Box>
      <BottomNavbar />
    </>
  );
};

export default MobileAttendanceLayout;
