import { Box, ImageList, ImageListItem, Typography } from '@mui/material';
import Sidebar from '../../components/Desktop/Sidebar';
import NewMeeting from '../../components/Meeting/NewMeeting';
import ScieverCalendar from '../../utils/ScieverCalendar';
import { useContext, useEffect, useState } from 'react';
import { AllTenantsContext } from '../../routes/Main';
import { TenantContext } from '../../App';
import logo from '../../assets/images/sciever_logo_png.webp';
import DesktopNotifications from '../../components/Desktop/DesktopNotifications';
import Account from '../../components/Account';
import SelectTenant from '../../components/SelectTenant';
import SelectBoard from '../../components/SelectBoard';

const DesktopMeetingLayout = (props: { fromTickets?: boolean }) => {
  const [englishDate, setEnglishDate] = useState<string>();
  const [nepaliDate, setNepaliDate] = useState<string>();
  const [calendarId, setCalendarId] = useState<string>();
  const [isNewBoard, setIsNewBoard] = useState(false);

  const { allTenants } = useContext(AllTenantsContext);
  const { tenant } = useContext(TenantContext);

  const getCalendarId = () => {
    setCalendarId(
      allTenants?.data?.tenants
        ?.filter(
          (org: { organization: { alias: string } }) => org?.organization?.alias === tenant,
        )[0]
        ?.organization?.google_calendar_id?.split(', ')[1],
    );
  };

  useEffect(() => {
    getCalendarId();
  }, [tenant, allTenants]);

  return (
    <>
      {/* <DesktopHeader /> */}
      {!props?.fromTickets && <Sidebar />}
      <Box
        bgcolor='white'
        position={props?.fromTickets ? 'unset' : 'fixed'}
        height={props?.fromTickets ? '86dvh' : 'unset'}
        left='65px'
        right='0px'
        top='0px'
        bottom='0px'
        p={props?.fromTickets ? 'unset' : '15px'}
        overflow='hidden'
        // sx={{ borderTopLeftRadius: '10px' }}
      >
        {!props?.fromTickets && (
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box display='flex' alignItems='center' gap='10px'>
              <ImageList
                sx={{
                  height: '35px',
                  overflow: 'hidden',
                  m: '0px',
                }}
                cols={1}
              >
                <ImageListItem>
                  <img
                    src={logo}
                    alt='Sciever Inc. Logo'
                    style={{ height: '35px', width: 'auto', objectFit: 'contain' }}
                  />
                </ImageListItem>
              </ImageList>

              <Typography variant='h6' fontWeight='bold'>
                Meeting Management
              </Typography>

              <SelectTenant />

              {tenant !== 'all' && (
                <SelectBoard isNewBoard={isNewBoard} setIsNewBoard={setIsNewBoard} />
              )}
            </Box>

            <Box display='flex' alignItems='center' gap='10px'>
              <DesktopNotifications />
              <Account />
            </Box>
          </Box>
        )}

        <Box display='flex' gap='15px' height='100%' mt='15px'>
          <Box width={englishDate && nepaliDate ? '50%' : '100%'} height='100%'>
            <ScieverCalendar
              padding='0px'
              height='90%'
              setEnglishDate={setEnglishDate}
              setNepaliDate={setNepaliDate}
              calendarId={calendarId !== undefined ? calendarId : ''}
            />
          </Box>

          {englishDate && nepaliDate && (
            <Box width='50%' height='90%'>
              <NewMeeting englishDate={englishDate} nepaliDate={nepaliDate} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DesktopMeetingLayout;
