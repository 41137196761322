import React, { useContext } from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import chat11 from '../../assets/images/chat1.svg';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { UserDataContext } from '../../routes/Main';
import { stringToColor } from '../../utils/exports/Function';

const Welcome = () => {
  const { userData } = useContext(UserDataContext);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 3px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        border: '1px solid currentColor',
        content: '""',
      },
    },
  }));

  const getInitials = (name: string) => {
    const parts = name.trim().split(/\s+/);

    if (parts.length === 1) {
      return parts[0].slice(0, 2).toUpperCase();
    }
    return `${parts[0][0]}${parts[parts.length - 1][0]}`.toUpperCase();
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 73px)',
        border: '1px solid lightgray',
        borderRadius: '5px',
        display: 'flex',
      }}
    >
      <Box
        gap={15}
        mt={15}
        ml={2}
        sx={{
          width: '45%',
          // height: 'calc(100vh - 73px)',
          display: 'flex',
          flexDirection: 'column',
          //   pl: '10px',
          //   alignItems: 'center',
          //   justifyContent: 'center',
        }}
      >
        <Box
          gap={1}
          sx={{
            maxWidth: '98%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Box sx={{ alignSelf: 'flex-start' }}>
            <StyledBadge
              sx={{
                '& .MuiBadge-badge': {
                  color: '#28a745',
                  backgroundColor: '#28a745',
                  width: '12px',
                  height: '12px',
                  borderRadius: '5px',
                },
              }}
              overlap='circular'
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant='dot'
            >
              <Avatar
                src={userData.data.profile_pic}
                alt='dtvu'
                sx={{
                  width: '90px',
                  height: '90px',
                  bgcolor: stringToColor(userData.data.name),
                  fontSize: '2.5rem',
                }}
              >
                {getInitials(userData.data.name)}
              </Avatar>
            </StyledBadge>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
            <Typography variant='h4'>Welcome!</Typography>
            <Typography variant='h3'>{userData.data.name}</Typography>
          </Box>
        </Box>
        <Typography variant='h4' alignSelf='center'>
          Seamlessly collaborate <br /> with your colleagues...
        </Typography>
      </Box>
      <Box
        sx={{
          width: '55%',
          display: 'flex',
          justifyContent: 'center', // Centers horizontally
          alignItems: 'center', // Centers vertically
          height: 'calc(100vh - 121px)', // Full viewport height
          overflow: 'auto',
        }}
      >
        <img
          src={chat11}
          alt='bgimg'
          style={{
            height: '85%',
            maxWidth: '100%',
            objectFit: 'cover',
          }}
        />
      </Box>
    </Box>
    //     <div
    //     style={{
    //       margin: 'auto',
    //       marginLeft: '265px',
    //       height: '80%',
    //       width: '100%',
    //       backgroundImage: `url(${chat1})`,
    //       backgroundSize: 'contain',
    //       backgroundPosition: 'center',
    //       backgroundRepeat: 'no-repeat',
    //     }}
    //   >
    //     <Box
    //       gap={15}
    //       sx={{
    //         mt: '5dvh',
    //         display: 'flex',
    //         flexDirection: 'column',
    //         ml: '-220px',
    //         maxWidth: '27dvw',
    //       }}
    //     >
    //       <Box
    //         gap={2}
    //         sx={{
    //           display: 'flex',
    //           flexDirection: 'row',
    //           alignItems: 'center',
    //           justifyContent: 'flex-start',
    //         }}
    //       >
    //         <StyledBadge
    //           sx={{
    //             '& .MuiBadge-badge': {
    //               color: '#28a745',
    //               backgroundColor: '#28a745',
    //               width: '12px',
    //               height: '12px',
    //               borderRadius: '5px',
    //             },
    //           }}
    //           overlap='circular'
    //           anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    //           variant='dot'
    //         >
    //           <Avatar
    //             src={userData.data.profile_pic}
    //             alt='dtvu'
    //             sx={{
    //               width: '80px',
    //               height: '80px',
    //               bgcolor: stringToColor(userData.data.name),
    //               fontSize: '2rem',
    //             }}
    //           >
    //             {getInitials(userData.data.name)}
    //           </Avatar>
    //         </StyledBadge>
    //         <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    //           <Typography variant='h4'>Welcome!</Typography>
    //           <Typography variant='h3'>{userData.data.name}</Typography>
    //         </Box>
    //       </Box>
    //       <Typography variant='h5'>
    //         Seamlessly collaborate <br /> with your colleagues
    //       </Typography>
    //     </Box>
    //   </div>
  );
};

export default Welcome;
