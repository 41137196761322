import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  styled,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { failed, success } from '../../hooks/useToast';
import { extractTime, getCurrentTime } from './Hours';
import { CurrentUserContext } from '../../App';
import EditIcon from '@mui/icons-material/Edit';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import { AttendanceUsersContext, CombinedReportContext } from '../../routes/Main';
import {
  handleDay,
  removeLeadingZerosRegex,
  formattedTime,
  attendanceUsersSort,
} from '../../utils/exports/Function';
import DoneButton from '../UI/DoneButton';
import CancelButton from '../UI/CancelButton';

const MonthlyAttendanceReport = (props: { user: number | undefined }) => {
  const { combinedReport } = useContext(CombinedReportContext);

  const { currentUser } = useContext(CurrentUserContext);

  const { attendanceUsers } = useContext(AttendanceUsersContext);

  const superAdmin = isSuperAdmin();

  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

  const [allCheckIns, setAllCheckIns] = useState<any>();

  const [selectedUser, setSelectedUser] = useState<number | string>(currentUser?.id);

  const [month, setMonth] = useState(new Date().toISOString().split('T')[0]?.substring(5, 7));

  const [year, setYear] = useState(new Date().toISOString().split('T')[0]?.substring(0, 4));

  const [isBsDate, setIsBsDate] = useState(false);

  const [bsData, setBsData] = useState<[{ bsDate: string; day: string }]>();

  useEffect(() => {
    if (isBsDate) {
      axios
        .get(
          `${process.env.REACT_APP_calendarApiUrl}/calendar/ad/${year}/${removeLeadingZerosRegex(
            month,
          )}/`,
        )
        .then((res) => {
          const currentBsDate = res.data.filter(
            (date: { adDate: string }) =>
              date.adDate === `${year}-${removeLeadingZerosRegex(month)}-${new Date().getDate()}`,
          )[0]?.bsDate;
          setYear(currentBsDate.split('-')[0]);
          setMonth(currentBsDate.split('-')[1]);
        })
        .catch(() => failed());
    } else {
      setMonth(new Date().toISOString().split('T')[0]?.substring(5, 7));
      setYear(new Date().toISOString().split('T')[0]?.substring(0, 4));
    }
  }, [isBsDate]);

  const MaterialUISwitch = styled(Switch)(() => ({
    width: 68,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(28px)',
        '& .MuiSwitch-thumb:before': {
          content: '"AD"',
          textAlign: 'center',
          alignContent: 'center',
        },
        '& + .MuiSwitch-track:before': {
          content: '"BS"',
          fontSize: '12px',
          color: 'black',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '48%',
          height: '100%',
          position: 'absolute',
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: 'lightgray',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: 'black',
      width: 32,
      height: 32,
      position: 'relative',
      '&::before': {
        content: '"BS"',
        textAlign: 'center',
        alignContent: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: 'lightgray',
      borderRadius: 20 / 2,
      position: 'relative',
      '&:before': {
        content: '"AD"',
        fontSize: '12px',
        color: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '153%',
        height: '100%',
        position: 'absolute',
      },
    },
  }));

  const getAllCheckIns = () => {
    if (isBsDate) {
      axios
        .get(
          `${
            process.env.REACT_APP_strapiUrl
          }/check-ins?pagination[pageSize]=32&sort=createdAt%3Adesc&populate=*&filters[bs_date][$contains]=${year}-${month}&filters[attendance_user][id][$eq]=${
            combinedReport ? selectedUser : props?.user
          }`,
          {
            headers: headers,
          },
        )
        .then((res) => setAllCheckIns(res?.data?.data))
        .catch(() => failed());
    } else {
      axios
        .get(
          `${
            process.env.REACT_APP_strapiUrl
          }/check-ins?pagination[pageSize]=32&sort=createdAt%3Adesc&populate=*&filters[date][$contains]=${year}-${month}&filters[attendance_user][id][$eq]=${
            combinedReport ? selectedUser : props?.user
          }`,
          {
            headers: headers,
          },
        )
        .then((res) => setAllCheckIns(res?.data?.data))
        .catch(() => failed());
    }
  };

  const formattedNepaliDate = (date: string): string => {
    const [year, month, day] = date.split('-').map(Number);
    const paddedMonth = month.toString().padStart(2, '0');
    const paddedDay = day.toString().padStart(2, '0');
    return `${year}-${paddedMonth}-${paddedDay}`;
  };

  useEffect(() => {
    getAllCheckIns();
  }, [props?.user, selectedUser, combinedReport, month, year, isBsDate]);

  const extractBsDates = (data: any) => {
    return data?.map((item: any) => item.bsDate);
  };

  // useEffect(() => {
  //     if (selectedUser === 0) {
  //         setMonthlyCheckIns(allCheckIns?.filter((checkIn: { attributes: { date: string } }) => checkIn?.attributes?.date?.substring(0, 7) === `${year}-${month}`))
  //     } else {
  //         setMonthlyCheckIns(allCheckIns?.filter((checkIn: { attributes: { date: string, attendance_user: { data: { id: number | string } } } }) => checkIn?.attributes?.attendance_user?.data?.id === selectedUser))
  //     }
  // }, [allCheckIns, selectedUser, month, year])

  const monthDays = (year: number, month: number) => {
    const [monthArray, setMonthArray] = useState<string[]>([]);

    useEffect(() => {
      if (isBsDate) {
        axios
          .get(
            `${process.env.REACT_APP_calendarApiUrl}/calendar/bs/${year}/${removeLeadingZerosRegex(
              JSON.stringify(month),
            )}/`,
          )
          .then((res) => {
            setMonthArray(extractBsDates(res.data));
            setBsData(res.data);
          })
          .catch(() => failed());
      } else {
        const getDaysInMonth = (year: number, month: number): string[] => {
          const daysInMonth: string[] = [];
          const daysCount: number = new Date(year, month, 0).getDate();
          for (let day = 1; day <= daysCount; day++) {
            const date = new Date(year, month - 1, day + 1);
            const formattedDate: string = date.toISOString().split('T')[0];
            daysInMonth.push(formattedDate);
          }
          return daysInMonth;
        };

        setMonthArray(getDaysInMonth(year, month));
      }
    }, [year, month]);

    return monthArray.map((date) => date);
  };

  const handleBsDay = (inputDate: string) => {
    return bsData?.filter((date) => date?.bsDate === inputDate)[0]?.day;
  };

  const [editOpen, setEditOpen] = useState(false);
  const [entryToEdit, setEntryToEdit] = useState<{
    attributes: {
      in_time: string;
      out_time: string;
      lunch_out: string;
      lunch_in: string;
    };
    id: number;
  }>();

  const [checkIn, setCheckIn] = useState(entryToEdit?.attributes?.in_time);
  const [checkOut, setCheckOut] = useState(entryToEdit?.attributes?.out_time);
  const [lunchOut, setLunchOut] = useState(entryToEdit?.attributes?.lunch_out);
  const [lunchIn, setLunchIn] = useState(entryToEdit?.attributes?.lunch_in);

  useEffect(() => {
    setCheckIn(entryToEdit?.attributes?.in_time);
    setCheckOut(entryToEdit?.attributes?.out_time);
    setLunchOut(entryToEdit?.attributes?.lunch_out);
    setLunchIn(entryToEdit?.attributes?.lunch_in);
  }, [entryToEdit]);

  const [dateToEdit, setDateToEdit] = useState<string>();

  const handleEditClick = (
    entry: {
      attributes: {
        in_time: string;
        out_time: string;
        lunch_out: string;
        lunch_in: string;
      };
      id: number;
    },
    date: string,
  ) => {
    setEditOpen(true);
    setEntryToEdit(entry);
    setDateToEdit(date);
  };

  const [bsDate, setBsDate] = useState();

  const getBsDate = (adDate: string) => {
    const year = adDate.split('-')[0];
    const month = adDate.split('-')[1];
    const day = adDate.split('-')[2];

    axios
      .get(
        `${process.env.REACT_APP_calendarApiUrl}/calendar/ad/${year}/${removeLeadingZerosRegex(
          month,
        )}/`,
      )
      .then((res) => {
        setBsDate(
          res.data.filter(
            (date: { adDate: string }) =>
              date.adDate ===
              `${year}-${removeLeadingZerosRegex(month)}-${removeLeadingZerosRegex(day)}`,
          )[0]?.bsDate,
        );
      })
      .catch(() => failed());
  };

  useEffect(() => {
    if (dateToEdit) {
      getBsDate(dateToEdit);
    }
  }, [dateToEdit]);

  const handleEdit = () => {
    if (entryToEdit) {
      const data = {
        data: {
          in_time: checkIn,
          out_time: checkOut !== '' ? checkOut : null,
          lunch_out: lunchOut !== '' ? lunchOut : null,
          lunch_in: lunchIn !== '' ? lunchIn : null,
        },
      };

      axios
        .put(`${process.env.REACT_APP_strapiUrl}/check-ins/${entryToEdit?.id}`, data, {
          headers: headers,
        })
        .then(() => {
          getAllCheckIns();
          success();
          setEditOpen(false);
          setCheckIn(undefined);
          setCheckOut(undefined);
          setLunchOut(undefined);
          setLunchIn(undefined);
        })
        .catch(() => failed());
    } else {
      const data = {
        data: {
          in_time: checkIn && checkIn !== '' ? checkIn : null,
          out_time: checkOut && checkOut !== '' ? checkOut : null,
          lunch_out: lunchOut && lunchOut !== '' ? lunchOut : null,
          lunch_in: lunchIn && lunchIn !== '' ? lunchIn : null,
          date: dateToEdit,
          attendance_user: selectedUser,
          bs_date: bsDate,
        },
      };

      axios
        .post(`${process.env.REACT_APP_strapiUrl}/check-ins`, data, {
          headers: headers,
        })
        .then(() => {
          getAllCheckIns();
          success();
          setEditOpen(false);
          setCheckIn(undefined);
          setCheckOut(undefined);
          setLunchOut(undefined);
          setLunchIn(undefined);
          setBsDate(undefined);
        })
        .catch(() => failed());
    }
  };

  // const [page, setPage] = useState(0);
  // const [pageSize, setPageSize] = useState(25);

  const columns: GridColDef[] = [
    {
      field: 'sn',
      renderHeader: () => {
        return <Typography fontWeight='bold'>SN</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    // {
    //     field: 'name',
    //     renderHeader: () => {
    //         return <Typography fontWeight='bold'>Name</Typography>;
    //     },
    //     headerAlign: 'center',
    //     flex: 5,
    //     align: 'center',
    //     editable: false,
    //     renderCell: (rowData) => rowData?.row?.attributes?.attendance_user?.data?.attributes?.name
    // },
    {
      field: 'Date',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Date</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 4,
      editable: false,
      sortable: false,
      renderCell: (rowData) => (isBsDate ? formattedNepaliDate(rowData?.row) : rowData?.row),
    },
    {
      field: 'Day',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Day</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 4,
      editable: false,
      sortable: false,
      renderCell: (rowData) => (isBsDate ? handleBsDay(rowData?.row) : handleDay(rowData?.row)),
    },
    {
      field: 'Check In',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Check In</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 4.5,
      editable: false,
      sortable: false,
      renderCell: (rowData) => {
        const filter = isBsDate
          ? allCheckIns
              ?.filter((checkIn: any) => checkIn?.attributes?.bs_date === rowData?.row)
              ?.reverse()
          : allCheckIns
              ?.filter((checkIn: any) => checkIn?.attributes?.date === rowData?.row)
              ?.reverse();
        if (filter?.length === 0) {
          return '-';
        } else {
          if (filter !== undefined) {
            return filter?.map((entry: any, index: number) => {
              if (entry?.attributes?.in_by) {
                return `${formattedTime(entry?.attributes?.in_time)} (${
                  entry?.attributes?.in_by?.split(' ')[0]
                })${index + 1 !== filter?.length ? ', ' : ''}`;
              } else {
                return `${formattedTime(entry?.attributes?.in_time)}${
                  index + 1 !== filter?.length ? ', ' : ''
                }`;
              }
            });
          } else {
            return '-';
          }
        }
      },
    },
    {
      field: 'Check Out',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Check Out</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 4.5,
      editable: false,
      sortable: false,
      renderCell: (rowData) => {
        const filter = isBsDate
          ? allCheckIns
              ?.filter((checkIn: any) => checkIn?.attributes?.bs_date === rowData?.row)
              ?.reverse()
          : allCheckIns
              ?.filter((checkIn: any) => checkIn?.attributes?.date === rowData?.row)
              ?.reverse();
        if (filter?.length === 0) {
          return '-';
        } else {
          if (filter !== undefined) {
            return filter?.map((entry: any, index: number) => {
              if (entry?.attributes?.out_time) {
                if (entry?.attributes?.out_by) {
                  return `${formattedTime(entry?.attributes?.out_time)} (${
                    entry?.attributes?.out_by?.split(' ')[0]
                  }${index + 1 !== filter?.length ? ', ' : ''})`;
                } else {
                  return `${formattedTime(entry?.attributes?.out_time)}${
                    index + 1 !== filter?.length ? ', ' : ''
                  }`;
                }
              } else {
                return `-${index + 1 !== filter?.length ? ', ' : ''}`;
              }
            });
          } else {
            return '-';
          }
        }
      },
    },
    // {
    //     field: 'Lunch Out',
    //     renderHeader: () => {
    //         return <Typography fontWeight='bold'>Lunch Out</Typography>;
    //     },
    //     headerAlign: 'center',
    //     align: 'center',
    //     flex: 4,
    //     editable: false,
    //     sortable: false,
    //     renderCell: (rowData) => {
    //         const filter = monthlyCheckIns?.filter((checkIn: any) => checkIn?.attributes?.date === rowData?.row)
    //         if (filter?.length === 0) {
    //             return '-'
    //         } else {
    //             return (filter !== undefined && filter[0]?.attributes?.lunch_out) ? formattedTime(filter[0]?.attributes?.lunch_out) : '-'
    //         }
    //     }
    // },
    // {
    //     field: 'Lunch In',
    //     renderHeader: () => {
    //         return <Typography fontWeight='bold'>Lunch In</Typography>;
    //     },
    //     headerAlign: 'center',
    //     align: 'center',
    //     flex: 4,
    //     editable: false,
    //     sortable: false,
    //     renderCell: (rowData) => {
    //         const filter = monthlyCheckIns?.filter((checkIn: any) => checkIn?.attributes?.date === rowData?.row)
    //         if (filter?.length === 0) {
    //             return '-'
    //         } else {
    //             return (filter !== undefined && filter[0]?.attributes?.lunch_in) ? formattedTime(filter[0]?.attributes?.lunch_in) : '-'
    //         }
    //     }
    // },
    {
      field: 'Hours',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Hours</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 4,
      editable: false,
      sortable: false,
      renderCell: (rowData) => {
        const filter = isBsDate
          ? allCheckIns?.filter((checkIn: any) => checkIn?.attributes?.bs_date === rowData?.row)
          : allCheckIns?.filter((checkIn: any) => checkIn?.attributes?.date === rowData?.row);
        if (filter?.length === 0) {
          return '-';
        } else {
          return filter !== undefined &&
            filter[0]?.attributes?.in_time &&
            filter[0]?.attributes?.out_time
            ? extractTime(
                filter[0]?.attributes?.in_time,
                filter[0]?.attributes?.out_time !== null
                  ? filter[0]?.attributes?.out_time
                  : getCurrentTime(),
              )
            : '-';
        }
      },
    },
    {
      field: 'Remarks',
      renderHeader: () => {
        return <Typography fontWeight='bold'>Remarks</Typography>;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 4,
      editable: false,
      sortable: false,
      renderCell: () => '-',
    },
    {
      field: 'actions',
      renderHeader: () => {
        return null;
      },
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      editable: false,
      renderCell: (row) => {
        return (
          <EditIcon
            onClick={() =>
              isBsDate
                ? handleEditClick(
                    allCheckIns?.filter(
                      (checkIn: any) => checkIn?.attributes?.bs_date === row?.row,
                    )[0],
                    row?.row,
                  )
                : handleEditClick(
                    allCheckIns?.filter(
                      (checkIn: any) => checkIn?.attributes?.date === row?.row,
                    )[0],
                    row?.row,
                  )
            }
            style={{ height: '16px', width: '16px', cursor: 'pointer' }}
          />
        );
      },
    },
  ];

  const sortedUsers = attendanceUsersSort(attendanceUsers);

  const getDefaultTime = (time: string | undefined) => {
    return `2000-01-01T${time?.split(':')[0]}:${time?.split(':')[1]}`;
  };

  const prependZero = (num: number) => {
    return num < 10 ? '0' + num : num;
  };

  useEffect(() => {
    if (sortedUsers?.filter((user: { id: number }) => user?.id === selectedUser)?.length === 0) {
      setSelectedUser(0);
    }
  }, [sortedUsers]);

  return (
    <>
      <Box display='flex' gap='20px' position='absolute' right='50px' top='40px'>
        <MaterialUISwitch
          sx={{ mt: '12px' }}
          checked={!isBsDate}
          onChange={(e) => setIsBsDate(!e.target.checked)}
        />
        {combinedReport && (
          <FormControl size='small' variant='standard'>
            <InputLabel>Employee</InputLabel>
            <Select
              value={selectedUser}
              aria-label='user-select'
              onChange={(e) => setSelectedUser(e.target.value)}
              label='Employee'
              sx={{
                width: '110px',
              }}
            >
              {/* <MenuItem value={0}>All</MenuItem> */}
              {sortedUsers?.map((user: { attributes: { name: string }; id: number }) => {
                return (
                  <MenuItem aria-label='attributes-name' key={user?.id} value={user?.id}>
                    {user?.attributes?.name?.split(' ')[0]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        <FormControl size='small' variant='standard'>
          <InputLabel>Month</InputLabel>
          {isBsDate ? (
            <Select
              value={month}
              aria-label='month-select'
              onChange={(e) => setMonth(e.target.value)}
              label='Month'
              sx={{
                width: '110px',
              }}
            >
              <MenuItem aria-label='baisakh' value={'1'}>
                Baisakh
              </MenuItem>
              <MenuItem aria-label='jestha' value={'2'}>
                Jestha
              </MenuItem>
              <MenuItem aria-label='asar' value={'3'}>
                Asar
              </MenuItem>
              <MenuItem aria-label='shrawan' value={'4'}>
                Shrawan
              </MenuItem>
              <MenuItem aria-label='bhadra' value={'5'}>
                Bhadra
              </MenuItem>
              <MenuItem aria-label='asoj' value={'6'}>
                Asoj
              </MenuItem>
              <MenuItem aria-label='kartik' value={'7'}>
                Kartik
              </MenuItem>
              <MenuItem aria-label='mangsir' value={'8'}>
                Mangsir
              </MenuItem>
              <MenuItem aria-label='poush' value={'9'}>
                Poush
              </MenuItem>
              <MenuItem aria-label='magh' value={'10'}>
                Magh
              </MenuItem>
              <MenuItem aria-label='falgun' value={'11'}>
                Falgun
              </MenuItem>
              <MenuItem aria-label='chaitra' value={'12'}>
                Chaitra
              </MenuItem>
            </Select>
          ) : (
            <Select
              value={month}
              aria-label='month-select'
              onChange={(e) => setMonth(e.target.value)}
              label='Month'
              sx={{
                width: '110px',
              }}
            >
              <MenuItem aria-label='january' value={'01'}>
                January
              </MenuItem>
              <MenuItem aria-label='february' value={'02'}>
                February
              </MenuItem>
              <MenuItem aria-label='march' value={'03'}>
                March
              </MenuItem>
              <MenuItem aria-label='april' value={'04'}>
                April
              </MenuItem>
              <MenuItem aria-label='may' value={'05'}>
                May
              </MenuItem>
              <MenuItem aria-label='june' value={'06'}>
                June
              </MenuItem>
              <MenuItem aria-label='july' value={'07'}>
                July
              </MenuItem>
              <MenuItem aria-label='august' value={'08'}>
                August
              </MenuItem>
              <MenuItem aria-label='september' value={'09'}>
                September
              </MenuItem>
              <MenuItem aria-label='october' value={'10'}>
                October
              </MenuItem>
              <MenuItem aria-label='november' value={'11'}>
                November
              </MenuItem>
              <MenuItem aria-label='december' value={'12'}>
                December
              </MenuItem>
            </Select>
          )}
        </FormControl>

        <FormControl size='small' variant='standard'>
          <InputLabel>Year</InputLabel>
          {isBsDate ? (
            <Select
              value={year}
              aria-label='year-select'
              onChange={(e) => setYear(e.target.value)}
              label='Year'
              sx={{
                width: '110px',
              }}
            >
              <MenuItem aria-label='2080' value={'2080'}>
                2080
              </MenuItem>
              <MenuItem aria-label='2081' value={'2081'}>
                2081
              </MenuItem>
              <MenuItem aria-label='2082' value={'2082'}>
                2082
              </MenuItem>
              <MenuItem aria-label='2083' value={'2083'}>
                2083
              </MenuItem>
              <MenuItem aria-label='2084' value={'2084'}>
                2084
              </MenuItem>
              <MenuItem aria-label='2085' value={'2085'}>
                2085
              </MenuItem>
              <MenuItem aria-label='2086' value={'2086'}>
                2086
              </MenuItem>
            </Select>
          ) : (
            <Select
              value={year}
              aria-label='year-select'
              onChange={(e) => setYear(e.target.value)}
              label='Year'
              sx={{
                width: '110px',
              }}
            >
              <MenuItem aria-label='2024' value={'2024'}>
                2024
              </MenuItem>
              <MenuItem aria-label='2025' value={'2025'}>
                2025
              </MenuItem>
              <MenuItem aria-label='2026' value={'2026'}>
                2026
              </MenuItem>
              <MenuItem aria-label='2027' value={'2027'}>
                2027
              </MenuItem>
              <MenuItem aria-label='2028' value={'2028'}>
                2028
              </MenuItem>
              <MenuItem aria-label='2029' value={'2029'}>
                2029
              </MenuItem>
              <MenuItem aria-label='2030' value={'2030'}>
                2030
              </MenuItem>
            </Select>
          )}
        </FormControl>
      </Box>

      <Box sx={{ bgcolor: 'white', height: '450px', mt: '-20px', mb: '-30px' }}>
        <DataGrid
          // rows={monthlyCheckIns !== undefined && monthlyCheckIns?.slice()?.sort((a: { attributes: { attendance_user: { data: { attributes: { name: string } } } } }, b: { attributes: { attendance_user: { data: { attributes: { name: string } } } } }) => a?.attributes?.attendance_user?.data?.attributes?.name?.localeCompare(b?.attributes?.attendance_user?.data?.attributes?.name))}
          rows={monthDays(parseInt(year), parseInt(month))}
          columns={columns}
          rowHeight={40}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[25, 50, 75, 100]}
          disableColumnMenu
          sx={{
            border: 'none',
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              width: '0.4em',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders':
              {
                borderColor: 'darkgray',
                borderWidth: '1px',
              },
          }}
          getRowId={(row) => {
            return row;
          }}
          disableRowSelectionOnClick
          // onPaginationModelChange={(row) => {
          //   setPage(row.page);
          //   setPageSize(row.pageSize);
          // }}
        />
      </Box>

      <Dialog
        open={editOpen}
        onClose={() => {
          setEntryToEdit(undefined);
          setEditOpen(false);
          setCheckIn(undefined);
          setCheckOut(undefined);
          setLunchOut(undefined);
          setLunchIn(undefined);
        }}
      >
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}
        >
          <Box display='flex' flexDirection='column' gap='10px'>
            {/* <TextField size='small' value={checkIn} onChange={e => setCheckIn(e.target.value)} label='Check In' ></TextField>
                        <TextField size='small' value={checkOut} onChange={e => setCheckOut(e.target.value)} label='Check Out' ></TextField>
                        <TextField size='small' value={lunchOut} onChange={e => setLunchOut(e.target.value)} label='Lunch Out' ></TextField>
                        <TextField size='small' value={lunchIn} onChange={e => setLunchIn(e.target.value)} label='Lunch In' ></TextField> */}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label='Check In'
                value={checkIn && dayjs(getDefaultTime(checkIn))}
                onChange={(e: any) =>
                  setCheckIn(`${prependZero(e?.$H)}:${prependZero(e?.$m)}:00.000`)
                }
                slotProps={{ textField: { size: 'small' } }}
              />
              <TimePicker
                label='Check Out'
                value={checkOut && dayjs(getDefaultTime(checkOut))}
                onChange={(e: any) =>
                  setCheckOut(`${prependZero(e?.$H)}:${prependZero(e?.$m)}:00.000`)
                }
                slotProps={{ textField: { size: 'small' } }}
              />
              <TimePicker
                label='Lunch Out'
                value={lunchOut && dayjs(getDefaultTime(lunchOut))}
                onChange={(e: any) =>
                  setLunchOut(`${prependZero(e?.$H)}:${prependZero(e?.$m)}:00.000`)
                }
                slotProps={{ textField: { size: 'small' } }}
              />
              <TimePicker
                label='Lunch In'
                value={lunchIn && dayjs(getDefaultTime(lunchIn))}
                onChange={(e: any) =>
                  setLunchIn(`${prependZero(e?.$H)}:${prependZero(e?.$m)}:00.000`)
                }
                slotProps={{ textField: { size: 'small' } }}
              />
            </LocalizationProvider>
          </Box>

          <Box display='flex' gap='10px'>
            <DoneButton text='Save' onClick={handleEdit} disabled={!superAdmin} mt='5px' />
            <CancelButton
              text='Cancel'
              onClick={() => {
                setEntryToEdit(undefined);
                setEditOpen(false);
                setCheckIn(undefined);
                setCheckOut(undefined);
                setLunchOut(undefined);
                setLunchIn(undefined);
              }}
              disabled={!superAdmin}
              mt='5px'
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MonthlyAttendanceReport;
