import React, { useContext, useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
// import { courses, Chapter } from './CourseObj';
import { CourseList } from './coursetab/CourseList';
import { ChapterList } from './coursetab/ChapterList';
// import { Chapter } from './types';
import AddCourseDialog from './popups/AddCourse';
import EditCourseDialog from './popups/EditCourse';
import AddChapterDialog from './popups/AddChapter';
import EditChapterDialog from './popups/EditChapter';
import BookmarkDialog from './popups/Bookmark';
import {
  addBookmark,
  addChapter,
  addCourse,
  addTopic,
  deleteBookmark,
  deleteCourse,
  fetchCourse,
  updateBookmark,
  updateChapter,
  updateCourse,
} from '../api/CourseApi';
import { useCourseContext } from '../context/CourseContext';
import { failed, success } from '../../hooks/useToast';
import { LoadingContext } from '../../App';
import { BookmarkItem } from '../exports/interfaces';

const CourseManagement: React.FC = () => {
  const [expandedCourse, setExpandedCourse] = useState<string>('');
  const [expandedChapter, setExpandedChapter] = useState<string>('');
  const [isBookmarkOpen, setIsBookmarkOpen] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState<any | null>(null);
  const [isAddCourseOpen, setIsAddCourseOpen] = useState(false);
  const [isEditCourseOpen, setIsEditCourseOpen] = useState(false);
  const [courseToEdit, setCourseToEdit] = useState<string | null>(null);
  const [courseId, setCourseId] = useState<number>(0);
  const [isAddChapterOpen, setIsAddChapterOpen] = useState(false);
  const [chapterId, setChapterId] = useState<number>(0);
  const [isEditChapterOpen, setIsEditChapterOpen] = useState(false);
  const [chapterToEdit, setChapterToEdit] = useState<{
    title: string;
    duration: string;
    topics: { id: number; content: string }[];
  } | null>(null);

  const { courseData, setCourseData } = useCourseContext();
  const { setLoading } = useContext(LoadingContext);

  const isMobile = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    if (courseData?.length === 0) {
      setLoading(true);
      fetchCourse()
        .then((data) => {
          setCourseData(data);
          setExpandedCourse(data[0]?.attributes?.Title);
          setExpandedChapter(data[0]?.attributes?.chapters?.data[0]?.attributes?.title);
          setLoading(false);
        })
        .catch(() => {
          failed();
        });
    } else {
      setExpandedCourse(courseData[0]?.attributes?.Title);
      setExpandedChapter(courseData[0]?.attributes?.chapters?.data[0]?.attributes?.title);
    }
  }, [setCourseData]);

  const handleAddCourse = async (courseName: string) => {
    setLoading(true);
    try {
      const data = {
        data: {
          Title: courseName,
        },
      };

      const response = await addCourse(data);

      if (response?.status === 200 || response?.status === 201) {
        success();
        fetchCourse()
          .then((data) => {
            setCourseData(data);
            setLoading(false);
          })
          .catch(() => {
            failed();
            setLoading(false);
          });
      } else {
        failed();
        setLoading(false);
      }
    } catch (error) {
      failed();
      setLoading(true);
    }
    setIsAddCourseOpen(false);
  };

  const handleUpdateCourse = async (newName: string) => {
    setLoading(true);
    try {
      const data = {
        data: {
          Title: newName,
        },
      };

      const response = await updateCourse(courseId, data);

      if (response?.status === 200 || response?.status === 201) {
        success();
        fetchCourse()
          .then((data) => {
            setCourseData(data);
            setLoading(false);
          })
          .catch(() => {
            failed();
            setLoading(false);
          });
      } else {
        failed();
        setLoading(false);
      }
    } catch (error) {
      failed();
      setLoading(false);
    }
    setIsEditCourseOpen(false);
  };

  const handleDeleteCourse = async () => {
    setLoading(true);
    try {
      const response = await deleteCourse(courseId);

      if (response?.status === 200 || response?.status === 201) {
        success();
        fetchCourse()
          .then((data) => {
            setCourseData(data);
            setLoading(false);
          })
          .catch(() => {
            failed();
            setLoading(false);
          });
      } else {
        failed();
        setLoading(false);
      }
    } catch (error) {
      failed();
      setLoading(false);
    }
    setIsEditCourseOpen(false);
  };

  const handleAddChapter = async (data: {
    title: string;
    duration: string;
    topics: { content: string }[];
  }) => {
    setLoading(true);
    try {
      const chapterInfo = {
        data: {
          title: data.title,
          hours: parseInt(data.duration),
          course: courseId,
        },
      };

      const chapterResponse = await addChapter(chapterInfo);

      if (chapterResponse?.status === 200 || chapterResponse?.status === 201) {
        const chapterId = chapterResponse.data.data.id;
        if (data?.topics.length === 0) {
          fetchCourse()
            .then((courseData) => {
              setCourseData(courseData);
            })
            .catch(() => {
              failed();
              setLoading(false);
            });
        } else {
          for (const topic of data.topics) {
            try {
              const topicInfo = {
                data: {
                  title: topic.content,
                  chapter: chapterId,
                },
              };

              const topicResponse = await addTopic(topicInfo);

              if (topicResponse?.status === 200 || topicResponse?.status === 201) {
                fetchCourse()
                  .then((courseData) => {
                    setCourseData(courseData);
                  })
                  .catch(() => {
                    failed();
                    setLoading(false);
                  });
              }
            } catch (error) {
              failed();
              setLoading(false);
            }
          }
        }
        success();
        setLoading(false);
      } else {
        failed();
        setLoading(false);
      }
    } catch (error) {
      failed();
      setLoading(false);
    }

    setIsAddChapterOpen(false);
  };

  const handleUpdateChapter = async (data: {
    title: string;
    duration: string;
    topics: { content: string }[];
  }) => {
    setLoading(true);
    try {
      const chapterInfo = {
        data: {
          title: data.title,
          hours: parseInt(data.duration),
        },
      };

      const chapterResponse = await updateChapter(chapterId, chapterInfo);

      if (chapterResponse?.status === 200 || chapterResponse?.status === 201) {
        success();
        fetchCourse()
          .then((data) => {
            setCourseData(data);
            setLoading(false);
          })
          .catch(() => {
            failed();
            setLoading(false);
          });
      } else {
        failed();
        setLoading(false);
      }
    } catch (error) {
      failed();
      setLoading(false);
    }
    setIsEditChapterOpen(false);
  };

  const handleAddBookmark = async (item: BookmarkItem) => {
    setLoading(true);
    try {
      const BookmarkInfo = {
        data: {
          title: item?.name,
          link: item?.link,
          suggestion: item?.suggestion,
          chapter: chapterId,
        },
      };

      const bookmarkResponse = await addBookmark(BookmarkInfo);

      if (bookmarkResponse?.status === 200 || bookmarkResponse?.status === 201) {
        success();
        setSelectedChapter((prevChapter: any) => ({
          ...prevChapter,
          bookmarks: {
            ...prevChapter.bookmarks,
            data: [...prevChapter.bookmarks.data, bookmarkResponse.data.data],
          },
        }));
        fetchCourse()
          .then((data) => {
            setCourseData(data);
            setLoading(false);
          })
          .catch(() => {
            failed();
            setLoading(false);
          });
      } else {
        failed();
        setLoading(false);
      }
    } catch (error) {
      failed();
      setLoading(false);
    }
  };

  const handleEditBookmark = async (id: number, item: BookmarkItem) => {
    setLoading(true);
    try {
      const bookmarkInfo = {
        data: {
          title: item?.name,
          link: item?.link,
        },
      };

      const bookmarkResponse = await updateBookmark(id, bookmarkInfo);

      if (bookmarkResponse?.status === 200 || bookmarkResponse?.status === 201) {
        success();
        setSelectedChapter((prevChapter: any) => ({
          ...prevChapter,
          bookmarks: {
            ...prevChapter.bookmarks,
            data: prevChapter.bookmarks.data.map((bookmark: any) => {
              if (bookmark.id === id) {
                return {
                  ...bookmark,
                  attributes: {
                    ...bookmark.attributes,
                    title: item.name,
                    link: item.link,
                  },
                };
              }
              return bookmark;
            }),
          },
        }));
        fetchCourse()
          .then((data) => {
            setCourseData(data);
            setLoading(false);
          })
          .catch(() => {
            failed();
            setLoading(false);
          });
      } else {
        failed();
        setLoading(false);
      }
    } catch (error) {
      failed();
      setLoading(false);
    }
  };

  const handleDeleteBookmark = async (id: number) => {
    setLoading(true);
    try {
      const response = await deleteBookmark(id);

      if (response?.status === 200 || response?.status === 201) {
        success();
        setSelectedChapter((prevChapter: any) => ({
          ...prevChapter,
          bookmarks: {
            ...prevChapter.bookmarks,
            data: prevChapter.bookmarks.data.filter((bookmark: any) => bookmark.id !== id),
          },
        }));
        fetchCourse()
          .then((data) => {
            setCourseData(data);
            setLoading(false);
          })
          .catch(() => {
            failed();
            setLoading(false);
          });
      } else {
        failed();
        setLoading(false);
      }
    } catch (error) {
      failed();
      setLoading(false);
    }
  };

  const handleChapterChange =
    (chapterName: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedChapter(isExpanded ? chapterName : '');
    };

  // const selectedCourseData =
  //   courseData?.find((c) => c?.attributes?.Title === expandedCourse) || courseData[0]?.attributes;

    const selectedCourseData =
    courseData?.find((c) => c?.attributes?.Title === expandedCourse);

  return (
    <Box
      sx={{
        display: 'flex',
        px: 2,
        flexDirection: isMobile ? 'column' : 'row',
      }}
    >
      <CourseList
        courses={courseData}
        selectedCourse={expandedCourse}
        onSelectCourse={setExpandedCourse}
        onAddCourse={() => setIsAddCourseOpen(true)}
        onEditCourse={(name, id) => {
          setCourseToEdit(name);
          setCourseId(id);
          setIsEditCourseOpen(true);
        }}
        isMobile={isMobile}
      />

      <ChapterList
        course={selectedCourseData}
        expandedChapter={expandedChapter}
        onChapterChange={handleChapterChange}
        onAddChapter={(id) => {
          setIsAddChapterOpen(true);
          setCourseId(id);
        }}
        onEditChapter={(chapter) => {
          setChapterToEdit({
            title: chapter?.attributes?.title,
            duration: chapter?.attributes?.hours?.toString(),
            topics:
              chapter?.attributes?.topics?.data?.map((content) => ({
                id: content?.id,
                content: content?.attributes?.title,
              })) || [],
          });
          setChapterId(chapter?.id);
          setIsEditChapterOpen(true);
        }}
        onBookmarkChapter={(chapter) => {
          setSelectedChapter(chapter?.attributes);
          setChapterId(chapter?.id);
          setIsBookmarkOpen(true);
        }}
        isMobile={isMobile}
      />

      <AddCourseDialog
        open={isAddCourseOpen}
        onClose={() => setIsAddCourseOpen(false)}
        onAdd={handleAddCourse}
      />

      <EditCourseDialog
        open={isEditCourseOpen}
        onClose={() => setIsEditCourseOpen(false)}
        onUpdate={handleUpdateCourse}
        onDelete={handleDeleteCourse}
        initialCourseName={courseToEdit || ''}
      />

      <AddChapterDialog
        open={isAddChapterOpen}
        onClose={() => setIsAddChapterOpen(false)}
        onSubmit={handleAddChapter}
      />

      <EditChapterDialog
        open={isEditChapterOpen}
        onClose={() => setIsEditChapterOpen(false)}
        onSubmit={handleUpdateChapter}
        initialData={
          chapterToEdit || {
            title: '',
            duration: '',
            topics: [],
          }
        }
        chapterId={chapterId}
      />

      <BookmarkDialog
        open={isBookmarkOpen}
        onClose={() => {
          setIsBookmarkOpen(false);
          setSelectedChapter(null);
        }}
        chapterName={selectedChapter?.title || ''}
        bookmarks={selectedChapter?.bookmarks?.data}
        onAddBookmark={(item) => {
          handleAddBookmark(item);
        }}
        onEditBookmark={(index, item) => {
          handleEditBookmark(index, item);
        }}
        onDeleteBookmark={(index) => {
          handleDeleteBookmark(index);
        }}
      />
    </Box>
  );
};

export default CourseManagement;
