import React, { createContext, useContext, useState } from "react";
import { ICourse, ICourseContext } from "../exports/interfaces";

const CourseContext = createContext<ICourseContext>({
  courseData: [],
  setCourseData: () => null,
});
export const useCourseContext = () => useContext(CourseContext);

export const CourseProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [courseData, setCourseData] = useState<ICourse[]>([]);

  return (
    <CourseContext.Provider value={{ courseData, setCourseData }}>
      {children}
    </CourseContext.Provider>
  );
};
