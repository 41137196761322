import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import ErrorIcon from '@mui/icons-material/Error';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { CurrentUserContext, LoadingContext } from '../App';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { dynamicFailed, failed } from '../hooks/useToast';
import { DataContext } from '../pages/TicketsPage';
import { UserDataContext, UserListContext } from '../routes/Main';
import Pills from './Pills';
import isMobile from '../hooks/isMobile';
import { catchedTrigger } from '../utils/novu';
import isDeveloper from '../hooks/isDeveloper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { recordLog } from '../services/recordLog';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import axios from 'axios';
import { addMessageService } from '../services/ticketService';
// import { useLocation } from 'react-router-dom';
import { githubActions } from '../services/githubActions';
import GitHubIcon from '@mui/icons-material/GitHub';
import { CicdDataType, ITicketData, IUserData } from '../utils/exports/Interface';
import { getCicdData } from '../utils/api/API';
// import io from 'socket.io-client';

const EditStatus = (props: {
  tenant: string;
  fullId: string;
  idToChange: number | string;
  status: string;
  assignees: string[] | null;
  dueDate: string;
  branch: string;
  type: string;
  dragDrop: boolean;
  trigger?: boolean;
  dragDropStatus?: string;
  fetchTicket?: () => void;
  getBoardTickets?: (status: string) => void;
}) => {
  const superAdmin = isSuperAdmin();

  const developer = isDeveloper();

  const { userData } = useContext(UserDataContext);
  const { userList } = useContext(UserListContext);
  const { setLoading } = useContext(LoadingContext);
  const { data, setData } = useContext(DataContext);
  // const { fetchTicketsHomePage } = useContext(TicketListContext);
  const { currentUser } = useContext(CurrentUserContext);

  const [open, setOpen] = useState(false);

  const [commentOpen, setCommentOpen] = useState(false);

  const [status, setStatus] = useState('');

  const handleStatusOpen = () => {
    setOpen(true);
  };

  const handleStatusClose = () => {
    setOpen(false);
    setDate(undefined);
    setDueDate(undefined);
  };

  const handleStatusComment = (pill: { name: string }) => {
    setCommentOpen(true);
    setStatus(pill.name);
  };

  const handleCommentClose = () => {
    setCommentOpen(false);
    setComment('');
  };

  // const socket = io('http://localhost:1337');

  // socket?.on('connect', () => {
  //   console.log('Connected');
  // });
  // useEffect(() => {
  //   console.log('Setting up event listeners for socket:', socket);

  //   // Ensure socket is defined before adding listeners
  //   if (socket) {
  //     // Register 'onMessage' event listener
  //     socket.on('onMessage', () => {
  //       console.log('onMsgListen');
  //       if (location?.pathname === '/overview') {
  //         fetchTicketsHomePage();
  //       } else {
  //         fetchTickets();
  //       }
  //     });
  //   }
  //   return () => {
  //     console.log('unregistering Events...');
  //     socket?.off('connect');
  //     socket?.off('onMessage');
  //   };
  // }, [socket]);

  const statusPills = [
    {
      name: 'BACKLOG',
      hexCode: '#515B52',
    },
    {
      name: 'ON HOLD',
      hexCode: '#747c75',
    },
    {
      name: 'TODO',
      hexCode: '#93A795',
    },
    {
      name: 'REDO',
      hexCode: '#E30000',
    },
    {
      name: 'IN PROGRESS',
      hexCode: '#FF7A00',
    },
    {
      name: 'PR',
      hexCode: '#1468B6',
    },
    {
      name: 'PR DONE',
      hexCode: '#0085FF',
    },
    {
      name: 'CANCELED',
      hexCode: '#000000',
    },
    {
      name: 'DONE',
      hexCode: '#0ED9CD',
    },
    {
      name: 'UAT READY',
      hexCode: '#00E309',
    },
    {
      name: 'UAT',
      hexCode: '#00E309',
    },
    {
      name: 'PROD READY',
      hexCode: '#03C03C',
    },
    {
      name: 'PRODUCTION',
      hexCode: '#03C03C',
    },
    {
      name: 'CLOSED',
      hexCode: '#00800D',
    },
  ];

  const statusDefinitions = [
    {
      name: 'TODO',
      definition:
        'All the HIGHEST -> HIGH priority tasks from the BACKLOG are moved under TODO for the CURRENT SPRINT',
    },
    {
      name: 'IN PROGRESS',
      definition:
        'The developer will pick the HIGHEST priority task assigned from the CURRENT SPRINT to start the development and change the ticket status to WIP',
    },
    {
      name: 'PR',
      definition:
        'After the development of a ticket is completed locally by the developer, the developer will send PR to any team member of the project and change the ticket status to PR',
    },
    {
      name: 'PR DONE',
      definition:
        'After the PR is requested, the PR assigned team member of the project will test locally and approve or reject - preferably senior developer or project manager. Once PR is approved, the same member will then change the ticket status to PR DONE',
    },
    {
      name: 'DONE',
      definition:
        'After the PR is approved, the developer will deploy the task on the SANDBOX and the same developer will then test. Once this test by the developer is PASSED on the SANDBOX, the status is changed to DONE',
    },
  ];

  const [statusToChange, setStatusToChange] = useState<number | string>();

  const handleStatusToChange = (idToChange: number | string) => {
    setStatusToChange(idToChange);
  };

  const [comment, setComment] = useState('');

  const [relatedUsers, setRelatedUsers] = useState<any>();

  const [date, setDate] = useState<any>();
  const [dueDate, setDueDate] = useState<any>();

  const [branch, setBranch] = useState<any>();

  const [files, setFiles] = useState<any>([]);

  // const location = useLocation();

  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

  const [wipAlert, setWipAlert] = useState(false);

  const wipCheck = (pill: { name: string }) => {
    axios
      .get(
        `${process.env.REACT_APP_strapiUrl}/tickets?pagination[pageSize]=99&filters[status][$eq]=in progress`,
        {
          headers: headers,
        },
      )
      .then((res) => {
        if (
          res?.data?.data?.filter((ticket: ITicketData) =>
            ticket?.attributes?.assignees?.includes(userData?.data?.name),
          )?.length === 0
        ) {
          handleStatusComment(pill);
          handleStatusClose();
        } else {
          setWipAlert(true);
          handleStatusClose();
        }
      })
      .catch(() => failed());
  };

  useEffect(() => {
    const users = userList?.data?.users?.users?.filter(
      (user: IUserData) =>
        user?.organizations?.some((obj: { alias: string }) => obj.alias === props.tenant) ||
        user.roles[0].name === 'sa',
    );
    const notifUsers = users?.map((user: IUserData) => {
      return {
        subscriberId: JSON.stringify(user.id),
      };
    });
    setRelatedUsers(notifUsers);
  }, [userList, props.tenant]);

  const [githubRes, setGithubRes] = useState<string>();

  useEffect(() => {
    if (githubRes !== undefined) {
      if (githubRes === 'Validation Failed') {
        dynamicFailed('Branch not found!');
      } else {
        dynamicFailed(githubRes);
      }
    }
  }, [githubRes]);

  const [cicdData, setCicdData] = useState<CicdDataType[]>([]);

  useEffect(() => {
    if (
      status === 'UAT' ||
      status === 'DONE' ||
      status === 'PRODUCTION' ||
      status === 'IN PROGRESS'
    ) {
      getCicdData(props?.tenant, setCicdData);
    }
  }, [status, props?.tenant]);

  useEffect(() => {
    if (status === 'IN PROGRESS') {
      setBranch(props?.branch);
    }
  }, [status, props?.branch]);

  const extractRepoName = (url: string) => {
    if (!url) return '';
    const match = url.match(/^https?:\/\/(www\.)?github.com\/(?<owner>[\w.-]+)\/(?<name>[\w.-]+)/);
    if (!match || !(match.groups?.owner && match.groups?.name)) return '';
    return match.groups.name;
  };

  // const checkBranch = (repoName: string, branchName: string) => {

  //   const config = {
  //     headers: {
  //       Accept: 'application/vnd.github+json',
  //       Authorization: `Bearer ${process.env.REACT_APP_githubToken}`,
  //       'X-GitHub-Api-Version': '2022-11-28'
  //     }
  //   };

  //   axios.get(`https://api.github.com/repos/sciever-inc/${repoName}/branches`, config)
  //     .then(res => console.log(res?.data?.filter((branch: { name: string }) => branch?.name === branchName)))
  // }

  const [branchConfirmationOpen, setBranchConfirmationOpen] = useState(false);

  const branchConfirmation = () => {
    if (
      cicdData !== undefined &&
      status === 'IN PROGRESS' &&
      (developer || superAdmin) &&
      cicdData?.length > 0 &&
      cicdData[0]?.attributes?.enabled &&
      (branch === null || branch == '')
    ) {
      setBranchConfirmationOpen(true);
    } else {
      handleStatusChange();
    }
  };

  const handleStatusChange = async () => {
    setLoading(true);

    if (status === 'DONE' && props?.branch !== null) {
      if (cicdData !== undefined && cicdData?.length > 0 && cicdData[0]?.attributes?.enabled) {
        // checkBranch(extractRepoName(cicdData[0]?.attributes?.repo), props?.branch)
        githubActions(
          setGithubRes,
          extractRepoName(cicdData[0]?.attributes?.repo),
          cicdData[0]?.attributes?.sandbox_branch,
          props?.branch,
        );
      }
    }

    if (status === 'UAT') {
      if (cicdData !== undefined && cicdData?.length > 0 && cicdData[0]?.attributes?.enabled) {
        githubActions(
          setGithubRes,
          extractRepoName(cicdData[0]?.attributes?.repo),
          cicdData[0]?.attributes?.uat_branch,
          props?.branch,
        );
      }
    }

    if (status === 'PRODUCTION') {
      if (cicdData !== undefined && cicdData?.length > 0 && cicdData[0]?.attributes?.enabled) {
        githubActions(
          setGithubRes,
          extractRepoName(cicdData[0]?.attributes?.repo),
          cicdData[0]?.attributes?.production_branch,
          props?.branch,
        );
      }
    }

    const ticketInfo =
      cicdData !== undefined &&
      cicdData?.length > 0 &&
      cicdData[0]?.attributes?.enabled &&
      (props?.branch === null || props?.branch === '')
        ? props?.assignees === null &&
          props?.dueDate === null &&
          status === 'IN PROGRESS' &&
          (developer || superAdmin)
          ? {
              status: status.toLowerCase(),
              assignees: [userData?.data?.name],
              due_date: dueDate,
              branch:
                branch !== null && branch !== ''
                  ? `${props?.type}/${props?.fullId?.toLowerCase()}-${branch}`
                  : null,
            }
          : props?.assignees === null &&
            props?.dueDate !== null &&
            status === 'IN PROGRESS' &&
            (developer || superAdmin)
          ? {
              status: status.toLowerCase(),
              assignees: [userData?.data?.name],
              branch:
                branch !== null && branch !== ''
                  ? `${props?.type}/${props?.fullId?.toLowerCase()}-${branch}`
                  : null,
            }
          : props?.dueDate === null &&
            props?.assignees !== null &&
            status === 'IN PROGRESS' &&
            (developer || superAdmin)
          ? {
              status: status.toLowerCase(),
              due_date: dueDate,
              branch:
                branch !== null && branch !== ''
                  ? `${props?.type}/${props?.fullId?.toLowerCase()}-${branch}`
                  : null,
            }
          : props?.dueDate !== null &&
            props?.assignees !== null &&
            status === 'IN PROGRESS' &&
            (developer || superAdmin)
          ? {
              status: status.toLowerCase(),
              branch:
                branch !== null && branch !== ''
                  ? `${props?.type}/${props?.fullId?.toLowerCase()}-${branch}`
                  : null,
            }
          : {
              status: status.toLowerCase(),
            }
        : props?.assignees === null &&
          props?.dueDate === null &&
          status === 'IN PROGRESS' &&
          (developer || superAdmin)
        ? {
            status: status.toLowerCase(),
            assignees: [userData?.data?.name],
            due_date: dueDate,
          }
        : props?.assignees === null &&
          props?.dueDate !== null &&
          status === 'IN PROGRESS' &&
          (developer || superAdmin)
        ? {
            status: status.toLowerCase(),
            assignees: [userData?.data?.name],
          }
        : props?.dueDate === null &&
          props?.assignees !== null &&
          status === 'IN PROGRESS' &&
          (developer || superAdmin)
        ? {
            status: status.toLowerCase(),
            due_date: dueDate,
          }
        : {
            status: status.toLowerCase(),
          };

    const formData = new FormData();

    Array.from(files).forEach((file: any) => {
      formData.append('files', file);
      formData.append('ref', 'chat');
      formData.append('field', 'attachments');
    });

    await fetch(`${process.env.REACT_APP_strapiUrl}/tickets/${statusToChange}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: ticketInfo }),
    })
      .then(async (response) => {
        recordLog(status, comment, 'ticket', props.fullId, currentUser?.id);
        // if (props.dragDrop) {
        // fetch props.dragdropstatus data and props.status
        if (props.getBoardTickets) {
          props.dragDropStatus && props.getBoardTickets(props.dragDropStatus);
          props.getBoardTickets(props.status);
          props.getBoardTickets(status);
        }
        // }

        if (response.status === 200) {
          await catchedTrigger('status-change', {
            // to: [
            //   { subscriberId: JSON.stringify(userData?.data?.id), email: userData?.data?.email },
            //   { subscriberId: '63d7751916e379fe65f29506', email: 'ankit@scieverinc.com' }
            // ],
            to: relatedUsers,
            payload: {
              ticket: props.fullId,
              status: status === 'IN PROGRESS' ? 'WIP' : status === 'PRODUCTION' ? 'PROD' : status,
              user: userData?.data?.name,
            },
          });
        }
      })
      .catch(() => {
        failed();
      });

    // const messageInfo = {
    //   message: comment,
    //   sender: userData !== undefined && userData.data.name,
    //   ticket: statusToChange,
    //   admin: (developer) && true,
    //   changed_to: status
    // };

    axios
      .post(`${process.env.REACT_APP_strapiUrl}/upload`, formData, {
        headers: headers,
      })
      .then(async (response) => {
        const attachments: any = [];
        response.data.map((imageId: { id: number }) => {
          attachments.push(imageId.id);
        });
        const messageInfo = {
          message: comment,
          sender: userData !== undefined && userData.data.name,
          ticket: statusToChange,
          admin: superAdmin || developer,
          attachments: attachments,
          changed_to: status,
        };
        const response2 = await addMessageService({ data: messageInfo }).catch(setLoading(false));
        if (response2.data.data) {
          // socket?.emit('newMessage');
          if (props.fetchTicket) {
            props.fetchTicket();
          }
          // location?.pathname === '/overview' ? fetchTicketsHomePage() : fetchTickets();
          // fetchTickets();
          const updatedTickets = data.map((ticket: any) => {
            if (ticket.attributes.ticket_id === props.fullId) {
              return {
                ...ticket,
                attributes: {
                  ...ticket.attributes,
                  status: status,
                },
              };
            }
            return ticket;
          });
          setData(updatedTickets);
          setComment('');
          setLoading(false);
          setFiles([]);
        }
      })
      .catch(async () => {
        const messageInfo = {
          message: comment,
          sender: userData !== undefined && userData.data.name,
          ticket: statusToChange,
          admin: superAdmin || developer,
          changed_to: status,
        };
        const response2 = await addMessageService({ data: messageInfo }).catch(setLoading(false));
        if (response2.data.data) {
          // socket?.emit('newMessage');
          if (props.fetchTicket) {
            props.fetchTicket();
          }
          const updatedTickets = data.map((ticket: any) => {
            if (ticket.attributes.ticket_id === props.fullId) {
              return {
                ...ticket,
                attributes: {
                  ...ticket.attributes,
                  status: status,
                },
              };
            }
            return ticket;
          });
          setData(updatedTickets);
          // location?.pathname === '/overview' ? fetchTicketsHomePage() : fetchTickets();
          // fetchTickets();
          setComment('');
          setLoading(false);
          setFiles([]);
        }
      });

    // await fetch(`${strapiUrl}/chats`, {
    //   method: 'POST',
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ data: messageInfo }),
    // })
    //   .then(() => {
    //     fetchTicketsHomePage();
    //     setLoading(false)
    //   })
    //   .catch(() => {
    //     // failed()
    //     setLoading(false);
    //   });

    // const addTicketResponse = await changeStatus.json()

    // const addChatResponse = await addComment.json()

    handleCommentClose();
    setComment('');
    // socket?.emit('newMessage');
    const updatedTickets = data.map((ticket: any) => {
      if (ticket.attributes.ticket_id === props.fullId) {
        return {
          ...ticket,
          attributes: {
            ...ticket.attributes,
            status: status,
          },
        };
      }
      return ticket;
    });
    setData(updatedTickets);
    // fetchTickets();
    // console.log(addTicketResponse);

    // console.log(addChatResponse);
  };

  const mobile = isMobile();

  const handleDate = () => {
    if (date) {
      const dt = new Date(date);

      const year = dt.toLocaleString('default', { year: 'numeric' });
      const month = dt.toLocaleString('default', { month: '2-digit' });
      const day = dt.toLocaleString('default', { day: '2-digit' });

      const formattedDate = year + '-' + month + '-' + day;

      if (formattedDate !== 'Invalid Date-Invalid Date-Invalid Date') {
        setDueDateAnchorEl(null);
        setDueDate(formattedDate);
      }
    }
  };

  const uploadInputRef = useRef<HTMLInputElement>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleGithubClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGithubClose = () => {
    setAnchorEl(null);
  };

  const githubOpen = Boolean(anchorEl);

  useEffect(() => {
    if (props?.dragDrop && props?.dragDropStatus) {
      handleStatusToChange(props.idToChange);
      if (props?.dragDropStatus === 'IN PROGRESS') {
        wipCheck({ name: props?.dragDropStatus });
      } else if (
        // pill.name !== 'PR' &&
        props?.dragDropStatus?.toUpperCase() === 'PR DONE' ||
        props?.dragDropStatus?.toUpperCase() === props.status.toUpperCase() ||
        (props?.dragDropStatus?.toUpperCase() === 'TODO' && !superAdmin)
      ) {
        null;
      } else {
        handleStatusComment({ name: props?.dragDropStatus?.toUpperCase() });
      }
    }
  }, [props?.trigger]);

  const [dueDateAnchorEl, setDueDateAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleDateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDueDateAnchorEl(event.currentTarget);
  };

  const handleDateClose = () => {
    setDueDateAnchorEl(null);
    setDate(undefined);
    setDueDate(undefined);
  };

  const dateOpen = Boolean(dueDateAnchorEl);

  const handleDateChange = (date: Date | null) => {
    setDate(date);
  };

  useEffect(() => {
    handleDate();
  }, [date]);

  return (
    <>
      {!props?.dragDrop && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleStatusOpen();
            handleStatusToChange(props.idToChange);
          }}
          sx={{ p: '0px', b: '0px' }}
          aria-label='handle-status'
        >
          <Pills pill={props.status} />
        </Button>
      )}

      <Dialog open={open} onClose={handleStatusClose} maxWidth='xl'>
        <DialogContent>
          <Box display='flex' flexDirection='column' gap='15px'>
            <Box display='flex' justifyContent='space-between'>
              <Typography fontWeight='bold'>Change status to:</Typography>
              <CloseIcon onClick={handleStatusClose} cursor='pointer' />
            </Box>

            <Typography>Development:</Typography>
            <Stack direction='row' gap='10px'>
              {statusPills.map((pill, index) => {
                if (index <= 7) {
                  return (
                    <Button
                      key={pill.name}
                      onClick={() => {
                        if (pill.name === 'IN PROGRESS') {
                          wipCheck(pill);
                        } else if (
                          // pill.name !== 'PR' &&
                          pill.name === 'PR DONE' ||
                          pill.name === props.status.toUpperCase() ||
                          (pill.name === 'TODO' && !superAdmin)
                        ) {
                          return null;
                        } else {
                          handleStatusComment(pill);
                          handleStatusClose();
                        }
                      }}
                      sx={{
                        p: '0px',
                        b: '0px',
                        cursor:
                          // pill?.name !== 'PR' &&
                          pill?.name === 'PR DONE' ||
                          pill?.name === props?.status?.toUpperCase() ||
                          (pill?.name === 'TODO' && !superAdmin)
                            ? 'not-allowed'
                            : 'pointer',
                      }}
                      aria-label='handle-status-comment'
                    >
                      <Pills pill={pill.name} />
                    </Button>
                  );
                }
              })}
            </Stack>

            <Typography>Deployment:</Typography>
            <Stack direction='row' gap='10px'>
              {statusPills.map((pill, index) => {
                if (index >= 8) {
                  return (
                    <Button
                      key={pill.name}
                      onClick={() => {
                        if (pill.name === 'IN PROGRESS') {
                          wipCheck(pill);
                        } else if (
                          // pill.name !== 'PR' &&
                          pill.name === 'PR DONE' ||
                          pill.name === props.status.toUpperCase() ||
                          (pill.name === 'TODO' && !superAdmin)
                        ) {
                          return null;
                        } else {
                          handleStatusComment(pill);
                          handleStatusClose();
                        }
                      }}
                      sx={{
                        p: '0px',
                        b: '0px',
                        cursor:
                          // pill?.name !== 'PR' &&
                          pill?.name === 'PR DONE' ||
                          pill?.name === props?.status?.toUpperCase() ||
                          (pill?.name === 'TODO' && !superAdmin)
                            ? 'not-allowed'
                            : 'pointer',
                      }}
                      aria-label='handle-status-comment'
                    >
                      <Pills pill={pill.name} />
                    </Button>
                  );
                }
              })}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={commentOpen} onClose={handleCommentClose} fullWidth>
        <DialogContent>
          <Box display='flex' flexDirection='column' gap='15px'>
            <Box display='flex' justifyContent='space-between'>
              <Box display='flex' gap='10px'>
                <Typography fontWeight='bold'>Comment:</Typography>
                <Pills pill={status} />
              </Box>
              <CloseIcon onClick={handleCommentClose} cursor='pointer' />
            </Box>
            <Box fontSize='11px' fontStyle='italic' mt='-15px'>
              {statusDefinitions?.filter((definition) => definition.name === status)[0]?.definition}
            </Box>
            <TextField
              multiline
              fullWidth
              rows={4}
              aria-label='comment'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={{ display: 'flex', gap: '5px', mt: '-68px' }}>
                    {(developer || superAdmin) &&
                      status === 'IN PROGRESS' &&
                      cicdData !== undefined &&
                      cicdData?.length > 0 &&
                      cicdData[0]?.attributes?.enabled &&
                      props?.branch === null && (
                        <GitHubIcon
                          onClick={(e: any) => handleGithubClick(e)}
                          color={branch !== null && branch !== '' ? 'success' : 'secondary'}
                          sx={{ cursor: 'pointer' }}
                        />
                      )}
                    {(developer || superAdmin) &&
                      status === 'IN PROGRESS' &&
                      props?.dueDate === null && (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <IconButton
                            aria-label='handle-data-click'
                            onClick={handleDateClick}
                            sx={{ p: 0 }}
                          >
                            <CalendarMonthIcon
                              color={dueDate && 'success'}
                              sx={{ cursor: 'pointer' }}
                            />
                          </IconButton>

                          <Popover
                            open={dateOpen}
                            anchorEl={dueDateAnchorEl}
                            onClose={handleDateClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <StaticDatePicker
                              value={date}
                              onChange={handleDateChange}
                              disablePast
                              // onAccept={handleDate}
                              componentsProps={{ actionBar: { actions: [] } }}
                            />
                          </Popover>
                        </LocalizationProvider>
                      )}
                    <input
                      ref={uploadInputRef}
                      type='file'
                      style={{ display: 'none' }}
                      aria-label='select-file'
                      onChange={(e) => setFiles(e.target.files)}
                      multiple
                      // accept='image/*'
                    />
                    <Box display='flex'>
                      <AttachFileIcon
                        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                        sx={{ cursor: 'pointer' }}
                      />
                      {files?.length !== undefined && files?.length > 0 && (
                        <Box>{files?.length}</Box>
                      )}
                    </Box>
                    <SendIcon
                      onClick={() => {
                        if (comment === '') {
                          dynamicFailed('Comment Not Entered.');
                          setCommentOpen(false);
                        } else {
                          if (
                            (developer || superAdmin) &&
                            status === 'IN PROGRESS' &&
                            props?.dueDate === null &&
                            dueDate === undefined
                          ) {
                            dynamicFailed('Due Date Not Entered.');
                            setCommentOpen(false);
                          } else {
                            branchConfirmation();
                          }
                        }
                      }}
                      sx={{ cursor: 'pointer' }}
                    />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setComment(e.target.value)}
              onKeyDown={(e) => {
                if (!mobile) {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    if (comment === '') {
                      dynamicFailed('Comment Not Entered.');
                      setCommentOpen(false);
                    } else {
                      if (
                        (developer || superAdmin) &&
                        status === 'IN PROGRESS' &&
                        props?.dueDate === null &&
                        dueDate === undefined
                      ) {
                        dynamicFailed('Due Date Not Entered.');
                        setCommentOpen(false);
                      } else {
                        branchConfirmation();
                        e.preventDefault();
                      }
                    }
                  }
                }
              }}
              value={comment}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={wipAlert} onClose={() => setWipAlert(false)} maxWidth='lg'>
        <DialogContent>
          <Typography display='flex' gap='5px'>
            <ErrorIcon /> There is already a ticket in <Pills pill='in progress' />. Change the
            status before moving another ticket to <Pills pill='in progress' />.
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        open={branchConfirmationOpen}
        onClose={() => setBranchConfirmationOpen(false)}
        maxWidth='lg'
      >
        <DialogContent>
          <Box display='flex' flexDirection='column' alignItems='center' gap='15px'>
            <Typography display='flex' gap='5px'>
              <ErrorIcon /> Are you sure you want to continue without git branch?
            </Typography>
            <Box display='flex' gap='10px'>
              <Button
                size='small'
                variant='contained'
                color='info'
                aria-label='branch-confirmation-open'
                onClick={() => {
                  setBranchConfirmationOpen(false);
                  handleStatusChange();
                }}
              >
                Yes
              </Button>
              <Button
                size='small'
                variant='outlined'
                aria-label='no-branch-confirmation-open'
                onClick={() => setBranchConfirmationOpen(false)}
              >
                No
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Popover
        open={githubOpen}
        anchorEl={anchorEl}
        onClose={handleGithubClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <TextField
          label='Branch'
          aria-label='branch'
          defaultValue={branch}
          size='small'
          sx={{ m: 2 }}
          onChange={(e) => setBranch(e.target.value)}
        />
      </Popover>
    </>
  );
};

export default EditStatus;
