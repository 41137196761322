/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box, CircularProgress, Dialog } from '@mui/material';
import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import CallbackHandler from '../components/CallbackHandler';
import LoginGuard from '../components/Guard/LoginGuard';
import useLoginExpiryEffect from '../hooks/useLoginExpiry';
import CreateTicketPage from '../pages/CreateTicketPage';
import ErrorPage from '../pages/ErrorPage';
import HomePage from '../pages/HomePage';
import NotificationsPage from '../pages/NotificationsPage';
import TicketsPage from '../pages/TicketsPage';
import AttendancePage from '../pages/AttendancePage';
import { CurrentUserContext, TenantContext } from '../App';
import MeetingPage from '../pages/MeetingPage';
import ChatRoom from '../chat-modules/ChatRoom';
import { useDispatch } from 'react-redux';
import { SocketContext } from '../utils/SocketProvider';
import { connectWebSocket } from '../store/websocket/websocketActions';
import { AppDispatch } from '../store/store';
// import { novu } from '../utils/novu';
// import { PushProviderIdEnum } from '@novu/node';
import { failed } from '../hooks/useToast';
import { ILeaveTypesType } from '../utils/exports/Interface';
import Layout from '../course-management-modules/components/Layout';
import CourseManagement from '../course-management-modules/components/CourseManagement';
import AssignCourse from '../course-management-modules/components/AssignCourse';
import Intern from '../course-management-modules/components/Intern';

export const UserDataContext = createContext<any>({});

export const UserListContext = createContext<any>({});

export const AllTenantsContext = createContext<any>({});

export const PageContext = createContext<any>({});

export const PageSizeContext = createContext<any>({});

export const AttendanceUsersContext = createContext<any>({});

export const AllAttendanceUsersContext = createContext<any>({});

export const PolicyInfoContext = createContext<any>({});

export const LeaveTypesContext = createContext<any>({});

export const CombinedReportContext = createContext<any>({});

export const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

// const Main = (props: { deviceToken: string | undefined }) => {
const Main = () => {
  const { setCurrentUser } = useContext(CurrentUserContext);
  const { tenant, setTenant } = useContext(TenantContext);

  const dispatch: AppDispatch = useDispatch();
  const socket = useContext(SocketContext);

  const [userData, setUserData] = useState<any>();
  const superAdmin = userData?.data?.roles[0]?.name === 'sa';
  const [userList, setUserList] = useState<any>();
  const [allTenants, setAllTenants] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [attendanceUsers, setAttendanceUsers] = useState<any>();
  const [allAttendanceUsers, setAllAttendanceUsers] = useState<any>();
  const [policyInfo, setPolicyInfo] = useState<any>();
  const [leaveTypes, setLeaveTypes] = useState<ILeaveTypesType[]>();
  const [combinedReport, setCombinedReport] = useState(false);

  const [userLoading, setUserLoading] = useState(true);

  const hrManagementUser =
    userData?.data?.main_tenant === null ||
    userData?.data?.main_tenant_role[0]?.services?.some(
      (service: { service_name: string }) => service?.service_name === 'HR Management',
    );

  const projectManagementUser =
    userData?.data?.main_tenant === null ||
    userData?.data?.main_tenant_role[0]?.services?.some(
      (service: { service_name: string }) => service?.service_name === 'Project Management',
    );

  const renderHomeRoute = () => {
    if (userLoading)
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
          }}
        >
          <CircularProgress />
        </Box>
      );

    // Check if the page has already been reloaded
    if (!localStorage.getItem('hasReloaded')) {
      setTimeout(() => {
        localStorage.setItem('hasReloaded', 'true'); // Set the flag
        window.location.reload();
      }, 2000); // Reload after 2 seconds
    }

    if (hrManagementUser) return <Navigate to='/attendance' />;
    if (projectManagementUser) return <Navigate to='/tickets' />;
    return <Navigate to='/meeting' />;
  };

  const getPolicyInfoData = () => {
    axios
      .get(`${process.env.REACT_APP_strapiUrl}/infos`, {
        headers: headers,
      })
      .then((res) => {
        setPolicyInfo(res?.data?.data[0]?.attributes);
      })
      .catch(() => failed());
  };

  const getLeaveTypes = () => {
    axios
      .get(`${process.env.REACT_APP_strapiUrl}/leave-types`, {
        headers: headers,
      })
      .then((res) => {
        setLeaveTypes(res?.data?.data);
      })
      .catch(() => failed());
  };

  const getAttendanceUsers = () => {
    axios
      .get(
        `${process.env.REACT_APP_strapiUrl}/attendance-users?pagination[pageSize]=100${
          tenant !== 'all' ? `&filters[tenant][$eq]=${tenant}` : ''
        }&populate[user_files][populate][0]=file`,
        {
          headers: headers,
        },
      )
      .then((res) => {
        setAttendanceUsers(res?.data?.data);
      })
      .catch(() => failed());
  };

  const getAllAttendanceUsers = () => {
    axios
      .get(
        `${process.env.REACT_APP_strapiUrl}/attendance-users?pagination[pageSize]=100&populate[user_files][populate][0]=file`,
        {
          headers: headers,
        },
      )
      .then((res) => {
        setAllAttendanceUsers(res?.data?.data);
      })
      .catch(() => failed());
  };

  const access_token = localStorage.getItem('access_token');

  const [user, setUser] = useState<{ attributes: { tenant: 'string' } }>();

  useLoginExpiryEffect();

  useEffect(() => {
    setLoading(true);

    if (access_token == null || access_token == undefined) {
      return;
    }

    axios
      .get(`${process.env.REACT_APP_sisApi}/api/user`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((user) => {
        setLoading(false);
        setUserData(user);
        setUserLoading(false);
      })
      .catch(() => {
        window.location.href = `${process.env.REACT_APP_sisUrl}&error=Session expired. Please login again.`;
      });
  }, [access_token]);

  useEffect(() => {
    if (access_token == null || access_token == undefined) {
      return;
    }

    axios
      .get(`${process.env.REACT_APP_sisApi}/api/admin/tenants/all`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((user) => {
        setAllTenants(user);
      });
  }, [access_token]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_sisApi}/api/admin/users`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((users) => {
        setUserList(users);
      });
  }, [access_token]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_strapiUrl}/attendance-users?filters[sis_id][$eq]=${userData?.data?.id}&pagination[pageSize]=99&populate[user_files][populate][0]=file`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_token}`,
          },
        },
      )
      .then((res) => {
        setUser(res?.data?.data[0]);
        return null;
      });
  }, [userData]);

  useEffect(() => {
    user?.attributes?.tenant === tenant ? setCurrentUser(user) : setTenant('all');
  }, [user]);

  useEffect(() => {
    if (socket) {
      dispatch(connectWebSocket(socket));
    }
  }, [dispatch, socket]);

  const attendanceUserOnly =
    userData?.data?.roles[0]?.name !== 'sa' &&
    userData?.data?.organizations?.length === 1 &&
    userData?.data?.organizations[0]?.alias === 'sciever'
      ? true
      : false;

  // const createNovuSubscriber = async () => {
  //   await novu.subscribers.identify(JSON.stringify(userData?.data?.id), {
  //     firstName: userData?.data?.name?.split(' ')[0],
  //     lastName: userData?.data?.name?.split(' ')[1],
  //     email: userData?.data?.email
  //   })
  // }

  // const updateDeviceToken = async () => {
  //   await novu.subscribers
  //     .identify(JSON.stringify(userData?.data?.id), {
  //       firstName: userData?.data?.name?.split(' ')[0],
  //       lastName: userData?.data?.name?.split(' ')[1],
  //       email: userData?.data?.email,
  //     })
  //     .then(async () => {
  //       if (props?.deviceToken) {
  //         await novu.subscribers.get(JSON.stringify(userData?.data?.id)).then(async (res) => {
  //           const arr = res?.data?.data?.channels[0]?.credentials?.deviceTokens
  //             ? res?.data?.data?.channels[0]?.credentials?.deviceTokens
  //             : [];
  //           if (!arr?.includes(props?.deviceToken)) {
  //             const newArr = [...arr, props?.deviceToken];
  //             await novu.subscribers.setCredentials(
  //               JSON.stringify(userData?.data?.id),
  //               PushProviderIdEnum.FCM,
  //               {
  //                 deviceTokens: newArr,
  //               },
  //             );
  //           }
  //         });
  //       }
  //     });
  // };

  // useEffect(() => {
  //   if (userData) {
  //     updateDeviceToken();
  //   }
  // }, [props?.deviceToken, userData]);

  return (
    <UserDataContext.Provider value={{ userData, setUserData }}>
      <UserListContext.Provider value={{ userList, setUserList }}>
        <AllTenantsContext.Provider value={{ allTenants, setAllTenants }}>
          <PageContext.Provider value={{ page, setPage }}>
            <PageSizeContext.Provider value={{ pageSize, setPageSize }}>
              <PolicyInfoContext.Provider value={{ policyInfo, setPolicyInfo, getPolicyInfoData }}>
                <LeaveTypesContext.Provider value={{ leaveTypes, setLeaveTypes, getLeaveTypes }}>
                  <AttendanceUsersContext.Provider
                    value={{ attendanceUsers, setAttendanceUsers, getAttendanceUsers }}
                  >
                    <CombinedReportContext.Provider value={{ combinedReport, setCombinedReport }}>
                      <AllAttendanceUsersContext.Provider
                        value={{
                          allAttendanceUsers,
                          setAllAttendanceUsers,
                          getAllAttendanceUsers,
                        }}
                      >
                        <Routes>
                          {attendanceUserOnly ? (
                            <>
                              <Route
                                path='/'
                                element={<LoginGuard>{renderHomeRoute()}</LoginGuard>}
                              />
                              {/* <Route
                      path='/overview'
                      element={
                        <LoginGuard>
                          <HomePage />
                        </LoginGuard>
                      }
                    /> */}
                              <Route
                                path='/tickets'
                                element={
                                  <LoginGuard>
                                    <TicketsPage />
                                  </LoginGuard>
                                }
                              />
                              <Route
                                path='/attendance'
                                element={
                                  <LoginGuard>
                                    <AttendancePage user={user} />
                                  </LoginGuard>
                                }
                              />
                              <Route path='/callback' element={<CallbackHandler />} />
                              <Route path='*' element={<ErrorPage />} />
                            </>
                          ) : (
                            <>
                              <Route
                                path='/'
                                element={<LoginGuard>{renderHomeRoute()}</LoginGuard>}
                              />
                              <Route
                                path='/overview'
                                element={
                                  <LoginGuard>
                                    <HomePage />
                                  </LoginGuard>
                                }
                              />
                              <Route
                                path='/tickets'
                                element={
                                  <LoginGuard>
                                    <TicketsPage />
                                  </LoginGuard>
                                }
                              />
                              <Route
                                path='/chatroom'
                                element={
                                  <LoginGuard>
                                    <ChatRoom />
                                  </LoginGuard>
                                }
                              />
                              <Route
                                path='/create_ticket'
                                element={
                                  <LoginGuard>
                                    <CreateTicketPage />
                                  </LoginGuard>
                                }
                              />
                              <Route
                                path='/notifications'
                                element={
                                  <LoginGuard>
                                    <NotificationsPage />
                                  </LoginGuard>
                                }
                              />
                              <Route
                                path='/attendance'
                                element={
                                  <LoginGuard>
                                    <AttendancePage user={user} />
                                  </LoginGuard>
                                }
                              />
                              <Route
                                path='/meeting'
                                element={
                                  <LoginGuard>
                                    <MeetingPage />
                                  </LoginGuard>
                                }
                              />
                              <Route path='/course' element={<Layout />}>
                                <Route
                                  index
                                  element={
                                    <Navigate
                                      to={superAdmin ? '/course/courses' : '/course/interns'}
                                      replace
                                    />
                                  }
                                />
                                <Route path='courses' element={<CourseManagement />} />
                                <Route path='assign' element={<AssignCourse />} />
                                <Route path='interns' element={<Intern />} />
                              </Route>
                              {/* <Route path='/login' element={access_token == null || access_token == undefined ? <Redirect /> : <HomePage />} /> */}
                              <Route path='/callback' element={<CallbackHandler />} />
                              <Route path='*' element={<ErrorPage />} />
                            </>
                          )}
                        </Routes>

                        <Dialog
                          sx={{ backgroundColor: 'white' }}
                          open={loading}
                          onClose={() => null}
                        >
                          {/* <CircularProgress color='inherit' /> */}
                        </Dialog>
                      </AllAttendanceUsersContext.Provider>
                    </CombinedReportContext.Provider>
                  </AttendanceUsersContext.Provider>
                </LeaveTypesContext.Provider>
              </PolicyInfoContext.Provider>
            </PageSizeContext.Provider>
          </PageContext.Provider>
        </AllTenantsContext.Provider>
      </UserListContext.Provider>
    </UserDataContext.Provider>
  );
};
export default Main;
