import { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  TextField,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
  Tooltip,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import isMobile from '../../hooks/isMobile';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { SocketContext } from '../../utils/SocketProvider';
import { fetchUsers } from '../../store/user/userActions';
import CreateGroupDialog from './CreateGroupDialog';
import {
  createRoom,
  fetchRoomsByUser,
  getFavorites,
  searchRooms,
} from '../../store/room/roomAction';
import { UserDataContext } from '../../routes/Main';
import { RoomData } from '../../store/constants/constants';
import ChatItem from './ChatItem';
import { debounce } from 'lodash';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { setSearchOpen } from '../../store/room/roomSlice';
import { sendChatLogAction } from '../../store/message/messageActions';
import { CustomTabPanel } from '../../utils/exports/Function';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { SquareIconButton } from '../../utils/exports/Styles';

interface ChatListProps {
  onChatItemClick: (chat: RoomData) => void;
}

// Initials for avatar
const getInitials = (name: string) => {
  const parts = name.trim().split(/\s+/);

  if (parts.length === 1) {
    return parts[0].slice(0, 2).toUpperCase();
  }
  return `${parts[0][0]}${parts[parts.length - 1][0]}`.toUpperCase();
};

const ChatList: React.FC<ChatListProps> = (props: ChatListProps) => {
  const mobileView = isMobile();
  const { userData } = useContext(UserDataContext);

  // Group add dialogue
  const [createGroupDialogOpen, setCreateGroupDialogOpen] = useState(false);

  const handleOpenCreateGroupDialog = () => {
    setCreateGroupDialogOpen(true);
  };

  const handleCloseCreateGroupDialog = () => {
    setCreateGroupDialogOpen(false);
  };

  const [filterParam, setFilterParam] = useState<string>('recent');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterRoom = (filterBy: string) => {
    setFilterParam(filterBy);
    if (socket) {
      dispatch(fetchRoomsByUser({ socket, userId: userData.data.id, filterBy }));
    }
    setAnchorEl(null);
  };

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const dispatch: AppDispatch = useDispatch();
  const socket = useContext(SocketContext);

  useEffect(() => {
    if (socket) {
      dispatch(fetchUsers({ socket }));
    }
  }, [dispatch, socket]);

  // Fetch rooms by userId (Assuming a static userId for now)
  const rooms = useSelector((state: RootState) => state.room.rooms);
  const searchedRooms = useSelector((state: RootState) => state.room.searchedRooms);
  const favorites = useSelector((state: RootState) => state.room.favorites);

  // Get Favorites
  const debouncedgetFavorite = debounce(() => {
    if (socket) {
      dispatch(getFavorites({ socket }));
    }
  }, 1000);

  useEffect(() => {
    debouncedgetFavorite();
    // Cleanup
    return () => {
      debouncedgetFavorite.cancel();
    };
  }, [dispatch, socket]);

  const debouncedDispatch = debounce(() => {
    if (socket) {
      dispatch(fetchRoomsByUser({ socket, userId: userData?.data?.id, filterBy: filterParam }));
    }
  }, 1000);

  useEffect(() => {
    debouncedDispatch();

    // Cleanup the debounce on unmount
    return () => {
      debouncedDispatch.cancel();
    };
  }, [dispatch, socket, rooms]);

  const flattenedRooms = rooms.flat();

  const membersRooms = flattenedRooms.filter((room) => room.roomType === 'individual');
  const groupRooms = flattenedRooms.filter((room) => room.roomType === 'group');
  const [isSearching, setIsSearching] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const isSearchOpen = useSelector((state: RootState) => state.room.searchOpen);

  const handleSearch = (userId: number, query: string) => {
    if (query.trim()) {
      setIsSearching(true);
      if (socket) {
        dispatch(searchRooms({ socket, userId, query }));
      }
    } else {
      setIsSearching(false);
    }
  };

  // Dynamic date format
  const formatMessageTime = (timestamp: string | number | Date | undefined | null) => {
    const messageDate = new Date(timestamp || Date.now());
    const now = new Date();

    // Reset hours to start of day for date comparisons
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    // Get start of week (7 days ago)
    const weekAgo = new Date(today);
    weekAgo.setDate(weekAgo.getDate() - 7);

    // Check if message is from today
    if (messageDate >= today) {
      return messageDate.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    }

    // Check if message is from yesterday
    if (messageDate >= yesterday) {
      return 'Yday';
    }

    // Check if message is within the last week
    if (messageDate >= weekAgo) {
      return messageDate.toLocaleString('en-US', {
        weekday: 'short',
      });
    }

    // Message is older than a week
    return messageDate.toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const isFavorite = (room: RoomData): boolean => {
    return favorites.some((fav) => fav.id === room.id);
  };

  const sortRoomsByFavorite = (rooms: RoomData[]) => {
    const sortedRooms = [...rooms].sort((a, b) => {
      const aIsFavorite = isFavorite(a);
      const bIsFavorite = isFavorite(b);

      // Favorites first
      if (aIsFavorite && !bIsFavorite) return -1;
      if (!aIsFavorite && bIsFavorite) return 1;

      // If both or neither are favorites, sort by last message time
      return (b.lastMessage?.createdAt || b.createdAt) - (a.lastMessage?.createdAt || a.createdAt);
    });

    return sortedRooms;
  };

  // console.log(searchedRooms?.[1]?.length);

  const isSearchAvailable =
    searchedRooms?.[0]?.length > 0 ||
    searchedRooms?.[1]?.length > 0 ||
    searchedRooms?.[2]?.length > 0;

  return (
    <>
      <Box
        maxHeight={mobileView ? '87vh' : '89vh'}
        width='100%'
        position='relative'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: '15px',
        }}
      >
        {/* Search Input */}

        <Slide direction='down' in={isSearchOpen} mountOnEnter unmountOnExit>
          <Paper
            elevation={3}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1000,
              backgroundColor: 'white',
              padding: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                placeholder='Search'
                variant='outlined'
                size='small'
                inputRef={searchInputRef}
                autoFocus
                sx={{
                  width: '100%',
                  borderRadius: '15px',
                  padding: mobileView ? '5px 20px' : '0',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        edge='end'
                        onClick={() => {
                          if (searchInputRef.current) {
                            searchInputRef.current.value = '';
                          }
                          setIsSearching(false);
                          dispatch(setSearchOpen(false));
                        }}
                        sx={{ p: '4px' }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleSearch(userData?.data?.id, e.target.value)}
              />

              {/* <IconButton
            size='small'
            sx={{ color: 'text.secondary', padding: '0px' }}
            onClick={handleMenuOpen}
          >
            <MoreVertIcon sx={{ fontSize: '2rem' }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem onClick={() => handleFilterRoom('recent')}>Latest Chats</MenuItem>
            <MenuItem onClick={() => handleFilterRoom('unread')}>Unread Chats</MenuItem>
          </Menu> */}
            </Box>
          </Paper>
        </Slide>

        <Box display='flex' sx={{ width: '100%' }}>
          <Tabs value={value} onChange={handleChange} sx={{ width: '95%' }}>
            <Tab
              label='All'
              sx={{
                flex: 1,
              }}
              disableRipple
            />
            <Tab
              label='Members'
              sx={{
                flex: 1,
              }}
              disableRipple
            />
            <Tab
              label='Groups'
              sx={{
                flex: 1,
              }}
              disableRipple
            />
          </Tabs>
          <Tooltip title='Filter'>
            <SquareIconButton size='small' onClick={handleMenuOpen}>
              <FilterAltIcon sx={{ fontSize: '1.5rem' }} />
              <ArrowDropDownIcon sx={{ fontSize: '1.5rem' }} />
            </SquareIconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem onClick={() => handleFilterRoom('recent')}>Sort by Latest</MenuItem>
            <MenuItem onClick={() => handleFilterRoom('unread')}>Sort by Unread</MenuItem>
          </Menu>
        </Box>
        {/* All chat list */}
        <CustomTabPanel value={value} index={0} customOverflow='scroll' customPadding='0'>
          <List>
            {isSearching ? (
              isSearchAvailable ? (
                // Show search results if searching
                <div>
                  {/* Display New Users */}

                  <h3 style={{ marginBottom: '5px' }}>New Users</h3>
                  {searchedRooms?.[0]?.length > 0 ? (
                    sortRoomsByFavorite(searchedRooms[0] || [])
                      ?.slice(0, 20)
                      .map((room: RoomData) => (
                        <ChatItem
                          key={room.id}
                          isFavorite={isFavorite(room)}
                          roomId={room.id}
                          roomType={room.roomType}
                          name={
                            room.roomType === 'group'
                              ? room.roomName
                              : room.userName || 'Unknown User'
                          }
                          avatar={
                            room.roomType === 'group'
                              ? room.avatar ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                              : room.roomParticipants?.find(
                                  (participant) => participant.user.userId !== userData.data.id,
                                )?.user?.profilePictureURL ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                          }
                          {...(room.lastMessage
                            ? {
                                lastmessage: room.lastMessage?.content
                                  ? room.lastMessage?.content
                                  : room.lastMessage?.forwardMessage?.content
                                  ? room.lastMessage?.forwardMessage?.content
                                  : room.lastMessage?.forwardMessage?.path
                                  ? 'Image'
                                  : room.lastMessage?.path
                                  ? 'Image'
                                  : 'No message',
                                time: formatMessageTime(
                                  room.lastMessage?.createdAt || room.createdAt,
                                ),
                                count: room.unseenMessagesCount,
                                unseen: room.unseenMessagesCount > 0 ? true : false,
                              }
                            : {})}
                          online={
                            room.roomParticipants?.find(
                              (participant) => participant.user.userId !== userData.data.id,
                            )?.user?.isActive
                          }
                          onClick={async () => {
                            if (userData?.data?.id && room?.userId && socket) {
                              const participantIds = [room.userId];

                              const payload = {
                                participantIds,
                                roomType: 'individual' as const,
                              };

                              try {
                                const newRoom = await dispatch(
                                  createRoom({ socket, payload }),
                                ).unwrap();

                                if (newRoom) {
                                  dispatch(
                                    sendChatLogAction({
                                      socket,
                                      logData: {
                                        roomId: newRoom?.id,
                                        content: `${userData?.data?.name} started a conversation.`,
                                      },
                                    }),
                                  );
                                }
                              } catch (error) {
                                console.error('Failed to create the room:', error);
                              }

                              if (searchInputRef.current) {
                                searchInputRef.current.value = '';
                                setIsSearching(false);
                              }
                            }
                          }}
                        />
                      ))
                  ) : (
                    <Typography sx={{ pb: '5px', borderBottom: '2px solid lightgray' }}>
                      No users found
                    </Typography>
                  )}

                  {/* Display Individual Chats */}
                  <h3 style={{ marginBottom: '5px' }}>Individual Chats</h3>

                  {searchedRooms?.[1]?.length > 0 ? (
                    sortRoomsByFavorite(searchedRooms[1] || [])
                      ?.slice(0, 20)
                      .map((room: RoomData) => (
                        <ChatItem
                          key={room.id}
                          isFavorite={isFavorite(room)}
                          roomId={room.id}
                          roomType={room.roomType}
                          name={
                            room.roomType === 'group'
                              ? room.roomName
                              : room.roomParticipants?.find(
                                  (participant) => participant.user.userId !== userData.data.id,
                                )?.user?.userName || 'Unknown User'
                          }
                          avatar={
                            room.roomType === 'group'
                              ? room.avatar ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                              : room.roomParticipants?.find(
                                  (participant) => participant.user.userId !== userData.data.id,
                                )?.user?.profilePictureURL ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                          }
                          lastmessage={
                            room.lastMessage?.content
                              ? room.lastMessage?.content
                              : room.lastMessage?.forwardMessage?.content
                              ? room.lastMessage?.forwardMessage?.content
                              : room.lastMessage?.forwardMessage?.path
                              ? 'Image'
                              : room.lastMessage?.path
                              ? 'Image'
                              : 'No message'
                          }
                          time={formatMessageTime(room.lastMessage?.createdAt || room.createdAt)}
                          count={room.unseenMessagesCount}
                          unseen={room.unseenMessagesCount > 0 ? true : false}
                          online={
                            room.roomParticipants?.find(
                              (participant) => participant.user.userId !== userData.data.id,
                            )?.user?.isActive
                          }
                          onClick={() => props.onChatItemClick(room)}
                        />
                      ))
                  ) : (
                    <Typography sx={{ pb: '5px', borderBottom: '2px solid lightgray' }}>
                      No chats found
                    </Typography>
                  )}

                  {/* Display Group Chats */}
                  <h3 style={{ marginBottom: '5px' }}>Group Chats</h3>
                  {searchedRooms?.[2]?.length > 0 ? (
                    sortRoomsByFavorite(searchedRooms[2] || [])
                      .slice(0, 20)
                      .map((room: RoomData) => (
                        <ChatItem
                          roomType={room.roomType}
                          key={room.id}
                          isFavorite={isFavorite(room)}
                          roomId={room.id}
                          name={
                            room.roomType === 'group'
                              ? room.roomName
                              : room.roomParticipants?.find(
                                  (participant) => participant.user.userId !== userData.data.id,
                                )?.user?.userName || 'Unknown User'
                          }
                          avatar={
                            room.roomType === 'group'
                              ? room.avatar ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                              : room.roomParticipants?.find(
                                  (participant) => participant.user.userId !== userData.data.id,
                                )?.user?.profilePictureURL ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                          }
                          lastmessage={
                            room.lastMessage?.content
                              ? room.lastMessage?.content
                              : room.lastMessage?.forwardMessage?.content
                              ? room.lastMessage?.forwardMessage?.content
                              : room.lastMessage?.forwardMessage?.path
                              ? 'Image'
                              : room.lastMessage?.path
                              ? 'Image'
                              : 'No message'
                          }
                          time={formatMessageTime(room.lastMessage?.createdAt || room.createdAt)}
                          count={room.unseenMessagesCount}
                          unseen={room.unseenMessagesCount > 0 ? true : false}
                          online={
                            room.roomParticipants?.find(
                              (participant) => participant.user.userId !== userData.data.id,
                            )?.user?.isActive
                          }
                          onClick={() => props.onChatItemClick(room)}
                        />
                      ))
                  ) : (
                    <Typography sx={{ pb: '5px', borderBottom: '2px solid lightgray' }}>
                      No chats found
                    </Typography>
                  )}
                </div>
              ) : (
                <Typography
                  sx={{
                    pt: '10px ',
                    textAlign: 'center',
                  }}
                >
                  No search results found
                </Typography>
              )
            ) : (
              // Show default rooms if not searching
              sortRoomsByFavorite(flattenedRooms)
                .slice(0, 20)
                .map((room: RoomData) => (
                  <ChatItem
                    roomType={room.roomType}
                    key={room.id}
                    isFavorite={isFavorite(room)}
                    roomId={room.id}
                    name={
                      room.roomType === 'group'
                        ? room.roomName
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.userName || 'Unknown User'
                    }
                    avatar={
                      room.roomType === 'group'
                        ? room.avatar || getInitials(room.roomName)
                        : room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.profilePictureURL ||
                          getInitials(
                            room.roomParticipants?.find(
                              (participant) => participant.user.userId !== userData.data.id,
                            )?.user?.userName || 'User',
                          )
                    }
                    lastmessage={
                      room.lastMessage?.content
                        ? room.lastMessage?.content
                        : room.lastMessage?.forwardMessage?.content
                        ? room.lastMessage?.forwardMessage?.content
                        : room.lastMessage?.forwardMessage?.path
                        ? 'Image'
                        : room.lastMessage?.path
                        ? 'Image'
                        : 'No message'
                    }
                    time={formatMessageTime(room.lastMessage?.createdAt || room.createdAt)}
                    count={room.unseenMessagesCount}
                    unseen={room.unseenMessagesCount > 0 ? true : false}
                    online={
                      room.roomParticipants?.find(
                        (participant) => participant.user.userId !== userData.data.id,
                      )?.user?.isActive
                    }
                    onClick={() => props.onChatItemClick(room)}
                  />
                ))
            )}
          </List>
        </CustomTabPanel>
        {/* member chat list */}
        <CustomTabPanel value={value} index={1} customOverflow='scroll' customPadding='0'>
          <List>
            {isSearching ? (
              isSearchAvailable ? (
                <div>
                  {/* Display New Users */}
                  <h3 style={{ marginBottom: '5px' }}>New Users</h3>
                  {searchedRooms?.[0]?.length > 0 ? (
                    sortRoomsByFavorite(searchedRooms[0] || [])
                      ?.slice(0, 20)
                      .map((room: RoomData) => (
                        <ChatItem
                          roomType={room.roomType}
                          key={room.id}
                          isFavorite={isFavorite(room)}
                          roomId={room.id}
                          name={
                            room.roomType === 'group'
                              ? room.roomName
                              : room.userName || 'Unknown User'
                          }
                          avatar={
                            room.roomType === 'group'
                              ? room.avatar ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                              : room.roomParticipants?.find(
                                  (participant) => participant.user.userId !== userData.data.id,
                                )?.user?.profilePictureURL ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                          }
                          {...(room.lastMessage
                            ? {
                                lastmessage: room.lastMessage?.content
                                  ? room.lastMessage?.content
                                  : room.lastMessage?.forwardMessage?.content
                                  ? room.lastMessage?.forwardMessage?.content
                                  : room.lastMessage?.forwardMessage?.path
                                  ? 'Image'
                                  : room.lastMessage?.path
                                  ? 'Image'
                                  : 'No message',
                                time: formatMessageTime(
                                  room.lastMessage?.createdAt || room.createdAt,
                                ),
                                count: room.unseenMessagesCount,
                                unseen: room.unseenMessagesCount > 0 ? true : false,
                              }
                            : {})}
                          online={
                            room.roomParticipants?.find(
                              (participant) => participant.user.userId !== userData.data.id,
                            )?.user?.isActive
                          }
                          onClick={async () => {
                            if (userData?.data?.id && room?.userId && socket) {
                              const participantIds = [room.userId];

                              const payload = {
                                participantIds,
                                roomType: 'individual' as const,
                              };

                              try {
                                const newRoom = await dispatch(
                                  createRoom({ socket, payload }),
                                ).unwrap();

                                if (newRoom) {
                                  dispatch(
                                    sendChatLogAction({
                                      socket,
                                      logData: {
                                        roomId: newRoom?.id,
                                        content: `${userData?.data?.name} started a conversation.`,
                                      },
                                    }),
                                  );
                                }
                              } catch (error) {
                                console.error('Failed to create the room:', error);
                              }

                              if (searchInputRef.current) {
                                searchInputRef.current.value = '';
                                setIsSearching(false);
                              }
                            }
                          }}
                        />
                      ))
                  ) : (
                    <Typography sx={{ pb: '5px', borderBottom: '2px solid lightgray' }}>
                      No chats found
                    </Typography>
                  )}

                  {/* Display Individual Chats */}
                  <h3 style={{ marginBottom: '5px' }}>Individual Chats</h3>
                  {searchedRooms?.[1]?.length > 0 ? (
                    sortRoomsByFavorite(searchedRooms[1] || [])
                      ?.slice(0, 20)
                      .map((room: RoomData) => (
                        <ChatItem
                          roomType={room.roomType}
                          key={room.id}
                          isFavorite={isFavorite(room)}
                          roomId={room.id}
                          name={
                            room.roomType === 'group'
                              ? room.roomName
                              : room.roomParticipants?.find(
                                  (participant) => participant.user.userId !== userData.data.id,
                                )?.user?.userName || 'Unknown User'
                          }
                          avatar={
                            room.roomType === 'group'
                              ? room.avatar ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                              : room.roomParticipants?.find(
                                  (participant) => participant.user.userId !== userData.data.id,
                                )?.user?.profilePictureURL ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                          }
                          lastmessage={
                            room.lastMessage?.content
                              ? room.lastMessage?.content
                              : room.lastMessage?.forwardMessage?.content
                              ? room.lastMessage?.forwardMessage?.content
                              : room.lastMessage?.forwardMessage?.path
                              ? 'Image'
                              : room.lastMessage?.path
                              ? 'Image'
                              : 'No message'
                          }
                          time={formatMessageTime(room.lastMessage?.createdAt || room.createdAt)}
                          count={room.unseenMessagesCount}
                          unseen={room.unseenMessagesCount > 0 ? true : false}
                          online={
                            room.roomParticipants?.find(
                              (participant) => participant.user.userId !== userData.data.id,
                            )?.user?.isActive
                          }
                          onClick={() => props.onChatItemClick(room)}
                        />
                      ))
                  ) : (
                    <Typography sx={{ pb: '5px', borderBottom: '2px solid lightgray' }}>
                      No chats found
                    </Typography>
                  )}

                  {/* Display Group Chats */}
                  <h3 style={{ marginBottom: '5px' }}>Group Chats</h3>
                  {searchedRooms?.[2]?.length > 0 ? (
                    sortRoomsByFavorite(searchedRooms[2] || [])
                      .slice(0, 20)
                      .map((room: RoomData) => (
                        <ChatItem
                          roomType={room.roomType}
                          key={room.id}
                          isFavorite={isFavorite(room)}
                          roomId={room.id}
                          name={
                            room.roomType === 'group'
                              ? room.roomName
                              : room.roomParticipants?.find(
                                  (participant) => participant.user.userId !== userData.data.id,
                                )?.user?.userName || 'Unknown User'
                          }
                          avatar={
                            room.roomType === 'group'
                              ? room.avatar ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                              : room.roomParticipants?.find(
                                  (participant) => participant.user.userId !== userData.data.id,
                                )?.user?.profilePictureURL ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                          }
                          lastmessage={
                            room.lastMessage?.content
                              ? room.lastMessage?.content
                              : room.lastMessage?.forwardMessage?.content
                              ? room.lastMessage?.forwardMessage?.content
                              : room.lastMessage?.forwardMessage?.path
                              ? 'Image'
                              : room.lastMessage?.path
                              ? 'Image'
                              : 'No message'
                          }
                          time={formatMessageTime(room.lastMessage?.createdAt || room.createdAt)}
                          count={room.unseenMessagesCount}
                          unseen={room.unseenMessagesCount > 0 ? true : false}
                          online={
                            room.roomParticipants?.find(
                              (participant) => participant.user.userId !== userData.data.id,
                            )?.user?.isActive
                          }
                          onClick={() => props.onChatItemClick(room)}
                        />
                      ))
                  ) : (
                    <Typography sx={{ pb: '5px', borderBottom: '2px solid lightgray' }}>
                      No chats found
                    </Typography>
                  )}
                </div>
              ) : (
                <Typography
                  sx={{
                    pt: '10px ',
                    textAlign: 'center',
                  }}
                >
                  No search results found
                </Typography>
              )
            ) : (
              // Show search results if searching

              sortRoomsByFavorite(membersRooms)
                ?.slice(0, 20)
                .map((room: RoomData) => {
                  return (
                    <ChatItem
                      roomType={room.roomType}
                      key={room.id}
                      isFavorite={isFavorite(room)}
                      roomId={room.id}
                      name={
                        room.roomType === 'group'
                          ? room.roomName
                          : room.roomParticipants?.find(
                              (participant) => participant.user.userId !== userData.data.id,
                            )?.user?.userName || 'Unknown User'
                      }
                      avatar={
                        room.roomParticipants?.find(
                          (participant) => participant.user.userId !== userData.data.id,
                        )?.user?.profilePictureURL ||
                        getInitials(
                          room.roomParticipants?.find(
                            (participant) => participant.user.userId !== userData.data.id,
                          )?.user?.userName || 'User',
                        )
                      }
                      lastmessage={
                        room.lastMessage?.content
                          ? room.lastMessage?.content
                          : room.lastMessage?.forwardMessage?.content
                          ? room.lastMessage?.forwardMessage?.content
                          : room.lastMessage?.forwardMessage?.path
                          ? 'Image'
                          : room.lastMessage?.path
                          ? 'Image'
                          : 'No message'
                      }
                      time={formatMessageTime(room.lastMessage?.createdAt || room.createdAt)}
                      count={room.unseenMessagesCount}
                      unseen={room.unseenMessagesCount > 0 ? true : false}
                      online={
                        room.roomParticipants?.find(
                          (participant) => participant.user.userId !== userData.data.id,
                        )?.user?.isActive
                      }
                      onClick={() => props.onChatItemClick(room)}
                    />
                  );
                })
            )}
          </List>
        </CustomTabPanel>
        {/* groups chat list */}
        <CustomTabPanel value={value} index={2} customOverflow='scroll' customPadding='0'>
          <List>
            <ListItemButton
              onClick={handleOpenCreateGroupDialog}
              sx={{
                borderBottom: '2px solid lightgray',
                '&:hover': {
                  backgroundColor: 'grey.300',
                },
              }}
            >
              <ListItemIcon>
                <Avatar sx={{ width: 45, height: 45 }}>
                  <AddIcon sx={{ width: 35, height: 35 }} />
                </Avatar>
              </ListItemIcon>
              <ListItemText primary='Create Group' />
            </ListItemButton>
            {isSearching ? (
              isSearchAvailable ? ( // Show search results if searching
                <div>
                  {/* Display New Users */}
                  <h3 style={{ marginBottom: '5px' }}>New Users</h3>
                  {searchedRooms?.[0]?.length > 0 ? (
                    sortRoomsByFavorite(searchedRooms[0] || [])
                      ?.slice(0, 20)
                      .map((room: RoomData) => (
                        <ChatItem
                          roomType={room.roomType}
                          key={room.id}
                          isFavorite={isFavorite(room)}
                          roomId={room.id}
                          name={
                            room.roomType === 'group'
                              ? room.roomName
                              : room.userName || 'Unknown User'
                          }
                          avatar={
                            room.roomType === 'group'
                              ? room.avatar ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                              : room.roomParticipants?.find(
                                  (participant) => participant.user.userId !== userData.data.id,
                                )?.user?.profilePictureURL ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                          }
                          {...(room.lastMessage
                            ? {
                                lastmessage: room.lastMessage?.content
                                  ? room.lastMessage?.content
                                  : room.lastMessage?.forwardMessage?.content
                                  ? room.lastMessage?.forwardMessage?.content
                                  : room.lastMessage?.forwardMessage?.path
                                  ? 'Image'
                                  : room.lastMessage?.path
                                  ? 'Image'
                                  : 'No message',
                                time: formatMessageTime(
                                  room.lastMessage?.createdAt || room.createdAt,
                                ),
                                count: room.unseenMessagesCount,
                                unseen: room.unseenMessagesCount > 0 ? true : false,
                              }
                            : {})}
                          online={
                            room.roomParticipants?.find(
                              (participant) => participant.user.userId !== userData.data.id,
                            )?.user?.isActive
                          }
                          onClick={async () => {
                            if (userData?.data?.id && room?.userId && socket) {
                              const participantIds = [room.userId];

                              const payload = {
                                participantIds,
                                roomType: 'individual' as const,
                              };

                              try {
                                const newRoom = await dispatch(
                                  createRoom({ socket, payload }),
                                ).unwrap();

                                if (newRoom) {
                                  dispatch(
                                    sendChatLogAction({
                                      socket,
                                      logData: {
                                        roomId: newRoom?.id,
                                        content: `${userData?.data?.name} started a conversation.`,
                                      },
                                    }),
                                  );
                                }
                              } catch (error) {
                                console.error('Failed to create the room:', error);
                              }

                              if (searchInputRef.current) {
                                searchInputRef.current.value = '';
                                setIsSearching(false);
                              }
                            }
                          }}
                        />
                      ))
                  ) : (
                    <Typography sx={{ pb: '5px', borderBottom: '2px solid lightgray' }}>
                      No chats found
                    </Typography>
                  )}

                  {/* Display Individual Chats */}
                  <h3 style={{ marginBottom: '5px' }}>Individual Chats</h3>
                  {searchedRooms?.[1]?.length > 0 ? (
                    sortRoomsByFavorite(searchedRooms[1] || [])
                      ?.slice(0, 20)
                      .map((room: RoomData) => (
                        <ChatItem
                          roomType={room.roomType}
                          key={room.id}
                          isFavorite={isFavorite(room)}
                          roomId={room.id}
                          name={
                            room.roomType === 'group'
                              ? room.roomName
                              : room.roomParticipants?.find(
                                  (participant) => participant.user.userId !== userData.data.id,
                                )?.user?.userName || 'Unknown User'
                          }
                          avatar={
                            room.roomType === 'group'
                              ? room.avatar ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                              : room.roomParticipants?.find(
                                  (participant) => participant.user.userId !== userData.data.id,
                                )?.user?.profilePictureURL ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                          }
                          lastmessage={
                            room.lastMessage?.content
                              ? room.lastMessage?.content
                              : room.lastMessage?.forwardMessage?.content
                              ? room.lastMessage?.forwardMessage?.content
                              : room.lastMessage?.forwardMessage?.path
                              ? 'Image'
                              : room.lastMessage?.path
                              ? 'Image'
                              : 'No message'
                          }
                          time={formatMessageTime(room.lastMessage?.createdAt || room.createdAt)}
                          count={room.unseenMessagesCount}
                          unseen={room.unseenMessagesCount > 0 ? true : false}
                          online={
                            room.roomParticipants?.find(
                              (participant) => participant.user.userId !== userData.data.id,
                            )?.user?.isActive
                          }
                          onClick={() => props.onChatItemClick(room)}
                        />
                      ))
                  ) : (
                    <Typography sx={{ pb: '5px', borderBottom: '2px solid lightgray' }}>
                      No chats found
                    </Typography>
                  )}

                  {/* Display Group Chats */}
                  <h3 style={{ marginBottom: '5px' }}>Group Chats</h3>
                  {searchedRooms?.[2]?.length > 0 ? (
                    sortRoomsByFavorite(searchedRooms[2] || [])
                      ?.slice(0, 20)
                      .map((room: RoomData) => (
                        <ChatItem
                          roomType={room.roomType}
                          key={room.id}
                          isFavorite={isFavorite(room)}
                          roomId={room.id}
                          name={
                            room.roomType === 'group'
                              ? room.roomName
                              : room.roomParticipants?.find(
                                  (participant) => participant.user.userId !== userData.data.id,
                                )?.user?.userName || 'Unknown User'
                          }
                          avatar={
                            room.roomType === 'group'
                              ? room.avatar ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                              : room.roomParticipants?.find(
                                  (participant) => participant.user.userId !== userData.data.id,
                                )?.user?.profilePictureURL ||
                                `https://randomuser.me/api/portraits/lego/${
                                  (room.id ?? Math.floor(Math.random() * 10)) % 10
                                }.jpg`
                          }
                          lastmessage={
                            room.lastMessage?.content
                              ? room.lastMessage?.content
                              : room.lastMessage?.forwardMessage?.content
                              ? room.lastMessage?.forwardMessage?.content
                              : room.lastMessage?.forwardMessage?.path
                              ? 'Image'
                              : room.lastMessage?.path
                              ? 'Image'
                              : 'No message'
                          }
                          time={formatMessageTime(room.lastMessage?.createdAt || room.createdAt)}
                          count={room.unseenMessagesCount}
                          unseen={room.unseenMessagesCount > 0 ? true : false}
                          online={
                            room.roomParticipants?.find(
                              (participant) => participant.user.userId !== userData.data.id,
                            )?.user?.isActive
                          }
                          onClick={() => props.onChatItemClick(room)}
                        />
                      ))
                  ) : (
                    <Typography sx={{ pb: '5px', borderBottom: '2px solid lightgray' }}>
                      No chats found
                    </Typography>
                  )}
                </div>
              ) : (
                <Typography
                  sx={{
                    pt: '10px ',
                    textAlign: 'center',
                  }}
                >
                  No search results found
                </Typography>
              )
            ) : (
              sortRoomsByFavorite(groupRooms)
                ?.slice(0, 20)
                .map((room: RoomData) => {
                  return (
                    <ChatItem
                      roomType={room.roomType}
                      key={room.id}
                      isFavorite={isFavorite(room)}
                      roomId={room.id}
                      name={room.roomName || 'Group Name'}
                      avatar={room.avatar || getInitials(room.roomName)}
                      lastmessage={
                        room.lastMessage?.content
                          ? room.lastMessage?.content
                          : room.lastMessage?.forwardMessage?.content
                          ? room.lastMessage?.forwardMessage?.content
                          : room.lastMessage?.forwardMessage?.path
                          ? 'Image'
                          : room.lastMessage?.path
                          ? 'Image'
                          : 'No message'
                      }
                      time={formatMessageTime(room.lastMessage?.createdAt || room.createdAt)}
                      count={room.unseenMessagesCount}
                      unseen={room.unseenMessagesCount > 0 ? true : false}
                      onClick={() => props.onChatItemClick(room)}
                    />
                  );
                })
            )}
          </List>
        </CustomTabPanel>
      </Box>
      <CreateGroupDialog open={createGroupDialogOpen} onClose={handleCloseCreateGroupDialog} />
    </>
  );
};

ChatList.propTypes = {
  onChatItemClick: PropTypes.func.isRequired,
};

export default ChatList;
