import { Avatar, Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { AttendanceBlock } from '../../layout/Desktop/DesktopAttendanceLayout';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import EditUser from './EditUser';
// import Info from './Info'
import Users from './Users';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import { CurrentUserContext } from '../../App';
import DragHandleIcon from '@mui/icons-material/DragHandle';
// import image from '../../assets/images/user2.png';
import isMobile from '../../hooks/isMobile';
import { AttendanceUsersContext, CombinedReportContext } from '../../routes/Main';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { attendanceUsersSort, stringToColor } from '../../utils/exports/Function';

const Details = (props: { setCurrentUserId?: Dispatch<SetStateAction<number>> }) => {
  const mobile = isMobile();

  const { currentUser } = useContext(CurrentUserContext);

  const { attendanceUsers } = useContext(AttendanceUsersContext);

  const { combinedReport } = useContext(CombinedReportContext);

  const [open, setOpen] = useState(false);

  // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const files = e.target.files;
  //   if (files && files.length > 0) {
  //     setFiles(files);
  //     setFileNames(files[0].name);
  //   }
  // };

  const sortedUsers = attendanceUsersSort(attendanceUsers);

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
        height: '175px',
        width: '175px',
        fontSize: '75px',
        color: 'white',
        '&.MuiAvatar-root': {
          border: 'none',
        },
      },
      children:
        name?.split(' ')?.length > 1
          ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
          : `${name.split(' ')[0][0]}`,
    };
  };

  const formatUrl = (url: string | undefined) => {
    if (url && !url.startsWith('https://') && !url.startsWith('http://')) {
      return `https://${url}`;
    }
    return url;
  };

  return (
    <>
      {combinedReport ? (
        <AttendanceBlock sx={{ bgcolor: '#F5F2FD', border: '1px solid #e2ddf9' }}>
          {/* <Info section='Details' /> */}
          <Box display='flex' alignItems='center' width='100%' justifyContent='space-between'>
            <Box
              display='flex'
              alignItems='center'
              gap='5px'
              ml='-3px'
              onClick={() => setOpen(true)}
              color='#5e4f9c'
              sx={{ cursor: 'pointer' }}
            >
              <Box p='5px' bgcolor='#5e4f9c' display='flex' alignItems='center' borderRadius='5px'>
                <PersonIcon sx={{ width: '22px', height: '22px', color: 'white' }} />
              </Box>
              <b>Employee Details</b>
            </Box>

            <DragHandleIcon onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }} />
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            mt='10px'
            ml='-15px'
            maxHeight={mobile ? '250px' : 'none'}
            width='calc(100% + 30px)'
            justifyContent='space-between'
            sx={{ overflowY: 'scroll' }}
          >
            {sortedUsers?.map(
              (
                user: { attributes: { name: string; contact: string }; id: number },
                index: number,
              ) => (
                <Box
                  key={user?.id}
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  bgcolor={index % 2 === 0 ? 'white' : 'inherit'}
                  p='5px 15px'
                >
                  <Typography flex={2} fontSize='15px'>
                    {user?.attributes?.name?.split(' ')[0]}
                  </Typography>
                  <Typography flex={2} fontSize='15px'>
                    {user?.attributes?.contact?.split(' ')[1]?.replace('-', '')}
                  </Typography>
                  <Box flex={1} textAlign='end' mb='-4px'>
                    <EditUser table={true} id={user?.id} />
                  </Box>
                </Box>
              ),
            )}
          </Box>
        </AttendanceBlock>
      ) : (
        <AttendanceBlock
          sx={{ bgcolor: '#F5F2FD', border: '1px solid #e2ddf9', overflowY: 'auto' }}
        >
          {/* <Info section='Details' /> */}

          <EditUser id={currentUser?.id} table={false} setCurrentUserId={props.setCurrentUserId} />

          <Box display='flex' width='100%' justifyContent='center' alignItems='center' mt='10px'>
            {/* <img
                            src={image}
                            style={{
                                width: '175px',
                                height: '175px',
                                borderRadius: '50%',
                                objectFit: 'cover'
                            }}
                        /> */}
            {currentUser && <Avatar {...stringAvatar(currentUser?.attributes?.name)} />}
          </Box>

          {/* <Box display='flex' width='100%' justifyContent='space-between' gap='10px'>
                        <Box display='flex' flexDirection='column' gap='10px' alignSelf='flex-start' mt='10px'>
                            <Typography fontSize='15px'>Name</Typography>
                            <Typography fontSize='15px'>E-mail</Typography>
                            <Typography fontSize='15px' sx={{ whiteSpace: 'nowrap' }}>Contact No.</Typography>
                            <Typography fontSize='15px'>Address</Typography>
                        </Box> */}

          <Box
            display='flex'
            flexDirection='column'
            mx='auto'
            gap='0px'
            alignItems='center'
            mt='10px'
          >
            <Typography fontSize='24px' fontWeight='bold'>
              {currentUser?.attributes?.name ? currentUser?.attributes?.name : '-'}
            </Typography>
            <Typography fontSize='15px' mt='5px'>
              {currentUser?.attributes?.official_email
                ? currentUser?.attributes?.official_email
                : '-'}
            </Typography>
            <Typography fontSize='15px'>
              {currentUser?.attributes?.contact ? currentUser?.attributes?.contact : '-'}
            </Typography>
            <Typography
              fontSize='15px'
              width='250px'
              textAlign='center'
              textOverflow='ellipsis'
              overflow='hidden'
            >
              {currentUser?.attributes?.address ? currentUser?.attributes?.address : '-'}
            </Typography>
          </Box>
          <Box display='flex' alignItems='center' justifyContent='center' width='100%' gap='5px'>
            <a
              href={formatUrl(currentUser?.attributes?.linkedin) || '#'}
              target={currentUser?.attributes?.linkedin ? '_blank' : ''}
              rel={currentUser?.attributes?.linkedin ? 'noopener noreferrer' : ''}
              aria-label='linkedin-link'
              style={{
                color: currentUser?.attributes?.linkedin ? '#5e4f9c' : 'rgba(0, 0, 0, 0.4)',
                cursor: currentUser?.attributes?.linkedin ? 'pointer' : 'not-allowed',
              }}
            >
              <LinkedInIcon />
            </a>

            <a
              href={formatUrl(currentUser?.attributes?.facebook) || '#'}
              target={currentUser?.attributes?.facebook ? '_blank' : ''}
              rel={currentUser?.attributes?.facebook ? 'noopener noreferrer' : ''}
              aria-label='facebook-link'
              style={{
                color: currentUser?.attributes?.facebook ? '#5e4f9c' : 'rgba(0, 0, 0, 0.4)',
                cursor: currentUser?.attributes?.facebook ? 'pointer' : 'not-allowed',
              }}
            >
              <FacebookIcon />
            </a>

            <a
              href={formatUrl(currentUser?.attributes?.instagram) || '#'}
              target={currentUser?.attributes?.instagram ? '_blank' : ''}
              rel={currentUser?.attributes?.instagram ? 'noopener noreferrer' : ''}
              aria-label='instagram-link'
              style={{
                color: currentUser?.attributes?.instagram ? '#d8551c' : 'rgba(0, 0, 0, 0.4)',
                cursor: currentUser?.attributes?.instagram ? 'pointer' : 'not-allowed',
              }}
            >
              <InstagramIcon />
            </a>
          </Box>
          {/* </Box> */}
        </AttendanceBlock>
      )}

      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='lg'>
        <DialogTitle
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          sx={{ pb: 0 }}
        >
          <Box display='flex' alignItems='center' gap='10px'>
            <PersonIcon sx={{ width: '25px', height: '25px' }} />
            <b>Employee Details</b>
          </Box>
          <CloseIcon onClick={() => setOpen(false)} cursor='pointer' />
        </DialogTitle>
        <DialogContent sx={{ height: '550px', pt: 0 }}>
          <Users />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Details;
