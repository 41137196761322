import { Box, InputLabel, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BoardContext, TenantContext, TicketsContext } from '../../App';
import isMobile from '../../hooks/isMobile';
import { TicketListContext } from '../../pages/HomePage';
import axios from 'axios';
import { UserDataContext } from '../../routes/Main';
import isDeveloper from '../../hooks/isDeveloper';
import isSuperAdmin from '../../hooks/isSuperAdmin';

const StatusBlock = () => {
  const { tickets } = useContext(TicketsContext);

  const { userData } = useContext(UserDataContext);

  const { tenant } = useContext(TenantContext);

  const { board } = useContext(BoardContext);

  const developer = isDeveloper();

  const superAdmin = isSuperAdmin();

  const navigate = useNavigate();

  const handleClick = (status: string) => {
    navigate(`/tickets?status=${status}`);
  };

  const { ticketList } = useContext(TicketListContext);

  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

  const getCount = (status: string, board: number[] | string[]) => {
    return axios
      .get(
        `${
          process.env.REACT_APP_strapiUrl
        }/tickets?pagination[page]=0&pagination[pageSize]=0&filters[status][$contains]=${status}&filters[main_tenant][$contains]=${
          userData?.data?.organizations[0]?.alias
        }${board?.map((item) =>
          item === 'all'
            ? userId === 'sa'
              ? ''
              : `&filters[$or][0][boards][users][$contains]=${userId}&filters[$or][1][default_board][$eq]=true`
            : item === 0
            ? `&filters[default_board][$eq]=true`
            : `&filters[boards][id][$in]=${item}`,
        )}`,
        {
          headers: headers,
        },
      )
      .then((res) => {
        return res.data.meta.pagination.total;
      });
  };

  const userId = superAdmin ? 'sa' : userData?.data?.id;

  const getTenantSpecificCount = (
    status: string,
    mainTenant: string | [{ alias: string }],
    board: number[] | string[],
  ) => {
    if (mainTenant) {
      if (typeof mainTenant === 'object') {
        // return axios.get(`${process.env.REACT_APP_strapiUrl}/tickets?pagination[page]=0&pagination[pageSize]=0&filters[board][$eq]=${board}&filters[status][$eq]=${status}${mainTenant.map(value => `&filters[main_tenant][$eq]=${value?.alias}`)}`.replace(/,/g, ''), {
        return axios
          .get(
            `${
              process.env.REACT_APP_strapiUrl
            }/tickets?pagination[page]=0&pagination[pageSize]=0${board?.map((item) =>
              item === 'all'
                ? userId === 'sa'
                  ? ''
                  : `&filters[$or][0][boards][users][$contains]=${userId}&filters[$or][1][default_board][$eq]=true`
                : item === 0
                ? `&filters[default_board][$eq]=true`
                : `&filters[boards][id][$in]=${item}`,
            )}&filters[status][$contains]=${status}${mainTenant.map(
              (value) => `&filters[main_tenant][$contains]=${value?.alias}`,
            )}`.replace(/,/g, ''),
            {
              headers: headers,
            },
          )
          .then((res) => {
            return res.data.meta.pagination.total;
          });
      } else {
        // return axios.get(`${process.env.REACT_APP_strapiUrl}/tickets?pagination[page]=0&pagination[pageSize]=0&filters[board][$eq]=${board}&filters[status][$eq]=${status}&filters[main_tenant][$eq]=${mainTenant}`, {
        return axios
          .get(
            `${
              process.env.REACT_APP_strapiUrl
            }/tickets?pagination[page]=0&pagination[pageSize]=0${board?.map((item) =>
              item === 'all'
                ? userId === 'sa'
                  ? ''
                  : `&filters[$or][0][boards][users][$contains]=${userId}&filters[$or][1][default_board][$eq]=true`
                : item === 0
                ? `&filters[default_board][$eq]=true`
                : `&filters[boards][id][$in]=${item}`,
            )}&filters[status][$contains]=${status}&filters[main_tenant][$contains]=${mainTenant}`,
            {
              headers: headers,
            },
          )
          .then((res) => {
            return res.data.meta.pagination.total;
          });
      }
    } else {
      // return axios.get(`${process.env.REACT_APP_strapiUrl}/tickets?pagination[page]=0&pagination[pageSize]=0&filters[status][$eq]=${status}`, {
      return axios
        .get(
          `${process.env.REACT_APP_strapiUrl}/tickets?pagination[page]=0&pagination[pageSize]=0&filters[status][$contains]=${status}`,
          {
            headers: headers,
          },
        )
        .then((res) => {
          return res.data.meta.pagination.total;
        });
    }
  };

  const [backlogCount, setBacklogCount] = useState(0);
  const [onHoldCount, setOnHoldCount] = useState(0);
  const [todoCount, setTodoCount] = useState(0);
  const [redoCount, setRedoCount] = useState(0);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [prCount, setPrCount] = useState(0);
  const [prDoneCount, setPrDoneCount] = useState(0);
  const [doneCount, setDoneCount] = useState(0);
  const [uatReadyCount, setUatReadyCount] = useState(0);
  const [uatCount, setUatCount] = useState(0);
  const [prodReadyCount, setProdReadyCount] = useState(0);
  const [productionCount, setProductionCount] = useState(0);
  const [closedCount, setClosedCount] = useState(0);
  const [canceledCount, setCanceledCount] = useState(0);

  const getFinalCount = (status: string, finalState: Dispatch<SetStateAction<number>>) => {
    if (tenant && userData) {
      developer
        ? getTenantSpecificCount(
            status,
            tenant === 'all' ? userData?.data?.organizations : tenant,
            board,
          ).then((count) => finalState(count))
        : superAdmin
        ? getTenantSpecificCount(status, tenant === 'all' ? null : tenant, board).then((count) =>
            finalState(count),
          )
        : getCount(status, board).then((count) => finalState(count));
    }
  };

  const blockColor = useMemo(() => {
    return [
      {
        name: 'backlog',
        hexCode: '#515B52',
        count: backlogCount,
        dispatch: setBacklogCount,
      },
      {
        name: 'on hold',
        hexCode: '#747c75',
        count: onHoldCount,
        dispatch: setOnHoldCount,
      },
      {
        name: 'todo',
        hexCode: '#93A795',
        count: todoCount,
        dispatch: setTodoCount,
      },
      {
        name: 'redo',
        hexCode: '#E30000',
        count: redoCount,
        dispatch: setRedoCount,
      },
      {
        name: 'in progress',
        hexCode: '#FF7A00',
        count: inProgressCount,
        dispatch: setInProgressCount,
      },
      {
        name: 'pr',
        hexCode: '#1468B6',
        count: prCount,
        dispatch: setPrCount,
      },
      {
        name: 'pr done',
        hexCode: '#0085FF',
        count: prDoneCount,
        dispatch: setPrDoneCount,
      },
      {
        name: 'canceled',
        hexCode: '#000000',
        count: canceledCount,
        dispatch: setCanceledCount,
      },
      {
        name: 'done',
        hexCode: '#0ED9CD',
        count: doneCount,
        dispatch: setDoneCount,
      },
      {
        name: 'uat ready',
        hexCode: '#00E309',
        count: uatReadyCount,
        dispatch: setUatReadyCount,
      },
      {
        name: 'uat',
        hexCode: '#00E309',
        count: uatCount,
        dispatch: setUatCount,
      },
      {
        name: 'prod ready',
        hexCode: '#00E309',
        count: prodReadyCount,
        dispatch: setProdReadyCount,
      },
      {
        name: 'production',
        hexCode: '#03C03C',
        count: productionCount,
        dispatch: setProductionCount,
      },
      {
        name: 'closed',
        hexCode: '#00800D',
        count: closedCount,
        dispatch: setClosedCount,
      },
    ];
  }, [
    ticketList,
    tickets,
    userData,
    tenant,
    board,
    developer,
    superAdmin,
    backlogCount,
    onHoldCount,
    todoCount,
    redoCount,
    inProgressCount,
    prCount,
    prDoneCount,
    doneCount,
    uatCount,
    uatReadyCount,
    prodReadyCount,
    productionCount,
    closedCount,
    canceledCount,
  ]);

  useEffect(() => {
    if (tenant && userData) {
      blockColor?.map((color) => {
        getFinalCount(color?.name, color?.dispatch);
      });
    }
  }, [tenant, board, userData]);

  const mobile = isMobile();

  return (
    <>
      {!mobile ? (
        <Box display='flex' gap='10px'>
          <Box display='flex' flexDirection='column' flex={8} position='relative'>
            <InputLabel
              shrink
              sx={{
                position: 'absolute',
                top: '8px',
                left: '12px',
                fontSize: '14px',
                backgroundColor: 'white',
                padding: '0 5px',
                zIndex: 1,
              }}
            >
              Development
            </InputLabel>

            <Stack
              direction='row'
              flex={1}
              justifyContent='space-between'
              gap='15px'
              mt='15px'
              border='1px solid lightgray'
              p='10px'
              borderRadius='5px'
            >
              {blockColor?.map((blockColor, index) => {
                if (index <= 7) {
                  return (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      alignItems='center'
                      onClick={() =>
                        blockColor.name !== 'pr done' ? handleClick(blockColor.name) : ''
                      }
                      sx={{
                        width: '100%',
                        height: '50px',
                        borderRadius: '5px',
                        bgcolor: blockColor?.hexCode,
                        cursor: blockColor.name !== 'pr done' ? 'pointer' : 'not-allowed',
                      }}
                      key={blockColor.name}
                    >
                      <Typography color='white' fontSize='12px' mb='-5px'>
                        {blockColor.name === 'in progress'
                          ? 'WIP'
                          : blockColor.name === 'production'
                          ? 'PROD'
                          : blockColor.name.toUpperCase()}
                      </Typography>

                      <Typography fontWeight='bold' color='white' fontSize='20px'>
                        {blockColor.count}
                      </Typography>
                    </Box>
                  );
                }
              })}
            </Stack>
          </Box>

          <Box display='flex' flexDirection='column' flex={6} position='relative'>
            <InputLabel
              shrink
              sx={{
                position: 'absolute',
                top: '8px',
                left: '13px',
                fontSize: '14px',
                backgroundColor: 'white',
                padding: '0 5px',
                zIndex: 1,
              }}
            >
              Deployment
            </InputLabel>

            <Stack
              direction='row'
              flex={1}
              justifyContent='space-between'
              gap='15px'
              mt='15px'
              border='1px solid lightgray'
              p='10px'
              borderRadius='5px'
            >
              {blockColor?.map((blockColor, index) => {
                if (index >= 8) {
                  return (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      alignItems='center'
                      onClick={() =>
                        blockColor.name !== 'pr done' ? handleClick(blockColor.name) : ''
                      }
                      sx={{
                        width: '100%',
                        height: '50px',
                        borderRadius: '5px',
                        bgcolor: blockColor?.hexCode,
                        cursor: blockColor.name !== 'pr done' ? 'pointer' : 'not-allowed',
                      }}
                      key={blockColor.name}
                    >
                      <Typography color='white' fontSize='12px' mb='-5px'>
                        {blockColor.name === 'in progress'
                          ? 'WIP'
                          : blockColor.name === 'production'
                          ? 'PROD'
                          : blockColor.name.toUpperCase()}
                      </Typography>

                      <Typography fontWeight='bold' color='white' fontSize='20px'>
                        {blockColor.count}
                      </Typography>
                    </Box>
                  );
                }
              })}
            </Stack>
          </Box>
        </Box>
      ) : (
        <Box
          display='flex'
          overflow='scroll'
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none', // Hide scrollbar for Chrome, Safari, and Opera
            },
          }}
        >
          <Box display='flex' gap='10px' mt={tenant === 'all' ? '75px' : '110px'}>
            {blockColor?.map((blockColor) => {
              return (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'
                  width='70px'
                  height='50px'
                  pb='5px'
                  sx={{
                    borderRadius: '10px',
                    bgcolor: `${blockColor?.hexCode || '#00800D'}`,
                  }}
                  key={blockColor.name}
                  onClick={() =>
                    blockColor.name !== 'pr done' ? handleClick(blockColor.name) : ''
                  }
                >
                  <Typography fontWeight='bold' color='white' fontSize='20px'>
                    {blockColor.count}
                  </Typography>
                  <Typography fontWeight='bold' color='white' fontSize='10px'>
                    {blockColor.name === 'in progress'
                      ? 'WIP'
                      : blockColor.name === 'production'
                      ? 'PROD'
                      : blockColor.name.toUpperCase()}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
};

export default StatusBlock;
