import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import { Tooltip } from '@mui/material';
import isMobile from '../../hooks/isMobile';
import { SquareIconButton } from '../../utils/exports/Styles';

const ScreenShare = () => {
  const mobileView = isMobile();
  return (
    <Tooltip title='Screen Share'>
      <SquareIconButton
        sx={{
          color: 'black',
          width: mobileView ? '20px' : '30px',
          height: mobileView ? '20px' : '30px',
        }}
      >
        <ScreenShareIcon />
      </SquareIconButton>
    </Tooltip>
  );
};

export default ScreenShare;
