import EditIcon from '@mui/icons-material/Edit';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import { useState, useContext, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
// import isMobile from '../hooks/isMobile';
import { CurrentUserContext, LoadingContext } from '../App';
import { failed } from '../hooks/useToast';
import { DataContext } from '../pages/TicketsPage';
import { UserDataContext } from '../routes/Main';
import isSuperAdmin from '../hooks/isSuperAdmin';
// import { TicketListContext } from '../pages/HomePage';
import isDeveloper from '../hooks/isDeveloper';
import { recordLog } from '../services/recordLog';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromHTML, ContentState, convertToRaw, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertRichText } from '../utils/exports/Function';
import { ITicketData } from '../utils/exports/Interface';

const EditDescription = (props: {
  ticket_id: string;
  id: string | number;
  current: string;
  fetchTicket?: () => void;
  ticketsData: ITicketData[] | undefined;
  setTicketsData: Dispatch<SetStateAction<ITicketData[]>> | undefined;
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setDescription(EditorState.createWithContent(convertRichText(props.current)));
  };

  //   const [description, setDescription] = useState(props.current);
  const [description, setDescription] = useState<EditorState | undefined>(
    EditorState.createEmpty(),
  );

  // const mobile = isMobile();

  const { setLoading } = useContext(LoadingContext);

  const { data, setData } = useContext(DataContext);

  const { userData } = useContext(UserDataContext);

  const { currentUser } = useContext(CurrentUserContext);

  // const { fetchTicketsHomePage } = useContext(TicketListContext);

  const superAdmin = isSuperAdmin();
  const developer = isDeveloper();

  useEffect(() => {
    if (props.current) {
      setDescription(EditorState.createWithContent(convertRichText(props.current)));
    }
  }, [props.current]);

  const handleEdit = async () => {
    if (description) {
      const rawContentState = convertToRaw(description.getCurrentContent());
      const descriptionHtml = draftToHtml(rawContentState);
      setLoading(true);

      const ticketInfo = {
        description: descriptionHtml,
      };

      await fetch(`${process.env.REACT_APP_strapiUrl}/tickets/${props.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: ticketInfo }),
      })
        .then(() => {
          setOpen(false);
          setLoading(false);
          recordLog(`Description`, description, 'ticket', props.ticket_id, currentUser?.id);
        })
        .catch(() => {
          failed();
          setLoading(false);
        });

      const messageInfo = {
        message: descriptionHtml,
        sender: userData !== undefined && userData.data.name,
        ticket: props.id,
        admin: (superAdmin || developer) && true,
        changed_to: 'Description',
      };

      await fetch(`${process.env.REACT_APP_strapiUrl}/chats`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: messageInfo }),
      })
        .then(async (response) => {
          const responseData = await response.json();
          const updatedTickets = data.map((ticket: any) => {
            if (ticket.attributes.ticket_id === props.ticket_id) {
              return {
                ...ticket,
                attributes: {
                  ...ticket.attributes,
                  description: responseData.data.attributes.message,
                },
              };
            }
            return ticket;
          });
          const updatedBoardTickets = props.ticketsData
            ? props.ticketsData.map((ticket: any) => {
                if (ticket.attributes.ticket_id === props.ticket_id) {
                  return {
                    ...ticket,
                    attributes: {
                      ...ticket.attributes,
                      description: responseData.data.attributes.message,
                    },
                  };
                }
                return ticket;
              })
            : [];
          setData(updatedTickets);
          props.setTicketsData && props.setTicketsData(updatedBoardTickets);
          if (props.fetchTicket) {
            props.fetchTicket();
          }
        })
        .catch(() => {
          // failed();
          setLoading(false);
        });

      // fetchTickets();
    }
  };

  const handlePastedText = useCallback(
    (html: string | null) => {
      if (html && description) {
        const cleanedHtml = html.replace(/style="[^"]*"/g, '');
        const blocksFromHTML = convertFromHTML(cleanedHtml);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );

        const currentContent = description.getCurrentContent();
        const selection = description.getSelection();

        const newContentState = Modifier.replaceWithFragment(
          currentContent,
          selection,
          contentState.getBlockMap(),
        );

        const newEditorState = EditorState.push(description, newContentState, 'insert-fragment');

        setDescription(
          EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter()),
        );
        return true;
      }
      return false;
    },
    [description, setDescription],
  );

  return (
    <>
      <EditIcon
        onClick={() => setOpen(true)}
        sx={{ height: '16px', color: 'gray', mb: '-2px', cursor: 'pointer' }}
      />

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogContent>
          <Box display='flex' flexDirection='column' gap='15px'>
            <Box display='flex' justifyContent='space-between'>
              <Typography fontWeight='bold'>Description:</Typography>
              <CloseIcon onClick={handleClose} cursor='pointer' />
            </Box>
            {/* <TextField
              multiline
              fullWidth
              rows={4}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={{ mt: '-68px' }}>
                    <SendIcon sx={{ cursor: 'pointer' }} onClick={handleEdit} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setDescription(e.target.value)}
              onKeyDown={(e) => {
                if (!mobile) {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    handleEdit();
                    e.preventDefault();
                  }
                }
              }}
              value={description}
            /> */}
            <Box className='editor-container'>
              <Editor
                editorState={description}
                wrapperClassName='editDescription-wrapper'
                editorClassName='editDescription-editor'
                onEditorStateChange={setDescription}
                handlePastedText={handlePastedText}
                toolbar={{
                  options: ['inline', 'link', 'history'],
                  inline: {
                    inDropdown: false,
                    options: ['bold', 'italic', 'underline'],
                  },
                  link: {
                    inDropdown: false,
                    options: ['link'],
                  },
                  history: {
                    inDropdown: false,
                    options: ['undo', 'redo'],
                  },
                }}
              />
              <SendIcon className='send-icon' onClick={handleEdit} />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditDescription;
