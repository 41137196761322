import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Modal,
  Divider,
  Avatar,
  TextField,
  InputAdornment,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import PushPinIcon from '@mui/icons-material/PushPin';
import EditIcon from '@mui/icons-material/Edit';
import isMobile from '../../hooks/isMobile';
import { SocketContext } from '../../utils/SocketProvider';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';

import {
  deleteMessageAction,
  getMessageAction,
  markMessageAsSeenAction,
  pinMessageAction,
  removePinMessageAction,
  searchMessages,
  forwardMessageAction,
  getPinnedMessageAction,
  bookmarkMessageAction,
  removeBookmarkAction,
  getSearchedMessageAction,
} from '../../store/message/messageActions';
import { UserDataContext } from '../../routes/Main';
import { MenuOption, MessageData, RoomData } from '../../store/constants/constants';
import {
  clearSearchResults,
  setAction,
  setDebouncedSearchQuery,
  setCurrentMatchIndex,
  setIsSearching,
  setSelectedMessage,
  setIsSearchContextActive,
} from '../../store/message/messageSlice';
import OptionsMenu from './ContextMenu/OptionsMenu';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ImageCarousel from './ImageCarousel';
import ForwardDialog from './ForwardDialog';
import PinnedMessage from './PinnedMessage';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ConfirmationDialog from './ConfirmationDialog';
import SeenIndicator from './SeenIndicator';
import HtmltoText from './HtmltoText';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { stringToColor } from '../../utils/exports/Function';
import Setting from './Setting';
import { dynamicFailed } from '../../hooks/useToast';
import { SquareIconButton } from '../../utils/exports/Styles';

interface ChatAreaProps {
  chat: RoomData | null;
  // showSearch: boolean; // Add a prop to control search visibility
  onFileDrop: (files: FileList) => void; // Add a function to close the search
  showPinnedContext: boolean;
  setShowPinnedContext: (show: boolean) => void;
}

// showSearch,
const ChatArea: React.FC<ChatAreaProps> = ({
  chat,
  onFileDrop,
  showPinnedContext,
  setShowPinnedContext,
}) => {
  const mobileView = isMobile();
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState<string | null>(null);
  const pinnedMessageRef = useRef<HTMLDivElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const selectedChat = useSelector((state: RootState) => state.room.selectedChat);
  const searchedMsg = useSelector((state: RootState) => state.message.SearchResults);
  const showSearch = useSelector((state: RootState) => state.message.searchStatus);
  const messages = useSelector((state: RootState) => state.message.messages);
  const pinnedMessage = useSelector((state: RootState) => state.message.pinMessage);
  const bookmarks = useSelector((state: RootState) => state.message.bookmarks);
  const [limit, setLimit] = useState(15);
  const [hasMore, setHasMore] = useState(true);
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [forwardDialogOpen, setForwardDialogOpen] = useState(false);
  const [selectedForwardMessage, setSelectedForwardMessage] = useState<MessageData | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [messageToRemove, setMessageToRemove] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [initialPosition, setInitialPosition] = useState<
    | {
        top: number;
        left: number;
        width: number;
        height: number;
      }
    | undefined
  >(undefined);
  const currentMatchRef = useRef<HTMLDivElement | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const socket = useContext(SocketContext);
  const debouncedSearchQuery = useSelector(
    (state: RootState) => state.message.debouncedSearchQuery,
  );
  const currentMatchIndex = useSelector((state: RootState) => state.message.currentMatchIndex);
  const isSearching = useSelector((state: RootState) => state.message.isSearching);
  const currentRoom = useSelector((state: RootState) => state.room.currentRoom);

  const { userData } = useContext(UserDataContext);
  const rooms = useSelector((state: RootState) => state.room.rooms);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      onFileDrop(files);
    }
  };

  const isGroup = chat?.roomType === 'group';

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const messageParams = {
    roomId: chat?.id,
    userId: userData?.data?.id,
    page: 1,
    limit: limit,
  };

  // Debounced function to dispatch the socket request
  const debouncedDispatch = debounce(() => {
    if (socket) {
      setIsLoading(true);
      dispatch(getMessageAction({ socket, message1: messageParams }))
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    }
  }, 1000);

  useEffect(() => {
    debouncedDispatch();

    // Cleanup the debounce on unmount
    return () => {
      debouncedDispatch.cancel();
    };
  }, [dispatch, socket, messages, limit]);

  const debouncedMarkAsSeen = debounce(() => {
    if (socket && messages && messages.length > 0 && chat) {
      // Extract message IDs from the messages array and filter out undefined values
      const messageIds = messages
        ?.filter((message) =>
          message.seenBy?.some((seen) => seen?.user?.userId === userData?.data?.id && !seen.isSeen),
        )
        .map((message) => message.id)
        ?.filter((id): id is number => id !== undefined);

      if (messageIds.length > 0) {
        dispatch(
          markMessageAsSeenAction({
            socket,
            messageId: messageIds,
            roomId: chat?.id,
          }),
        );
      }
    }
  }, 1000); // Adjust the debounce delay as needed

  useEffect(() => {
    // Call the debounced function
    debouncedMarkAsSeen();

    // Cleanup the debounce on unmount
    return () => {
      debouncedMarkAsSeen.cancel();
    };
  }, [dispatch, socket, messages]);

  // infinite scroll
  const fetchMoreMessages = () => {
    setLimit((limit) => limit + 10);

    // Check if all messages are loaded
    if (messages.length < limit) {
      setHasMore(false); // No more messages to load
    }
  };
  // Function to get all images from messages
  const getImagesFromMessages = () => {
    return messages
      ?.filter(
        (msg) => msg.type === 'attachments' && msg.path && /\.(jpg|jpeg|png|gif)$/i.test(msg.path),
      )
      .map((msg) => ({
        id: msg.id || 0,
        path: msg.path || '',
        updatedAt: msg.updatedAt || '',
        sender: {
          userName: msg.sender?.userName || 'Unknown',
        },
      }));
  };

  // Function to find image index in the filtered images array
  const findImageIndex = (currentPath: string) => {
    const images = getImagesFromMessages();
    return images.findIndex((img) => img.path === currentPath);
  };

  const handleClose = () => {
    setSelectedFile(null);
    setOpen(false);
  };

  const isFirstMessage = (index: number) => {
    if (index === 0) return true;
    const currentMessage = messages[index];
    const previousMessage = messages[index - 1];

    // Check if both messages exist before accessing their properties
    if (!currentMessage || !previousMessage) {
      return true;
    }

    return (
      currentMessage.sender !== previousMessage.sender ||
      !isSameDay(currentMessage?.updatedAt, previousMessage?.updatedAt)
    );
  };

  const isSameDay = (date1?: string, date2?: string) => {
    if (!date1 || !date2) return false;
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const getDateDisplay = (date?: string) => {
    if (!date) return '';

    const messageDate = new Date(date);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (isSameDay(messageDate.toISOString(), today.toISOString())) {
      return 'Today';
    } else if (isSameDay(messageDate.toISOString(), yesterday.toISOString())) {
      return 'Yesterday';
    } else {
      return messageDate.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
  };

  const shouldShowDateHeader = (index: number) => {
    const currentMessage = messages[index];
    const previousMessage = messages[index + 1];

    return (
      index === messages.length - 1 ||
      !isSameDay(currentMessage?.updatedAt, previousMessage?.updatedAt)
    );
  };

  const handleForward = async (roomId: number, messageId: number) => {
    if (socket) {
      await dispatch(
        forwardMessageAction({
          socket,
          forwardData: {
            roomId,
            messageId,
          },
        }),
      );
    }
  };

  const handleForwardMessage = (message: MessageData) => {
    setSelectedForwardMessage(message);
    setForwardDialogOpen(true);
  };

  const handleMessageClick = (messageId: number | undefined) => {
    if (messageId !== undefined) {
      setSelectedMessageId((prevId) =>
        prevId === messageId.toString() ? null : messageId.toString(),
      );
    }
  };

  const handleRemoveMessage = (messageId: number) => {
    setMessageToRemove(messageId);
    setDeleteDialogOpen(true);
  };

  const confirmDeleteMessage = () => {
    if (socket && chat && chat.id && messageToRemove) {
      dispatch(
        deleteMessageAction({ socket, deleteData: { roomId: chat.id, id: messageToRemove } }),
      );
      setDeleteDialogOpen(false);
      setMessageToRemove(null);
    } else {
      console.error('Unable to delete message: socket is undefined');
    }
  };

  const cancelDeleteMessage = () => {
    setDeleteDialogOpen(false);
    setMessageToRemove(null);
  };

  const handlePinMessage = (messageId: number) => {
    if (chat && chat.id && socket) {
      dispatch(pinMessageAction({ socket, pinData: { roomId: chat.id, messageId } }));
    } else {
      console.error('Unable to pin message: chat, chat.id, or socket is undefined');
    }
  };

  const handleRemovePinMessage = (pinnedMessageId: number) => {
    if (socket) {
      dispatch(removePinMessageAction({ socket, pinnedMessageId }));
      setShowPinnedContext(false);
    } else {
      console.error('Unable to remove pinned message: socket is undefined');
    }
  };

  const handleBookmark = (messageId: number) => {
    if (socket) {
      dispatch(bookmarkMessageAction({ socket, messageId }));
    }
  };

  const handleRemoveBookmark = (bookmarkId: number) => {
    if (socket) {
      dispatch(removeBookmarkAction({ socket, bookmarkId }));
    }
  };

  const isBookmarked = (message: MessageData): boolean => {
    return bookmarks.some((bookmark) => bookmark.message.id === message.id);
  };

  const handleOptionSelect = (option: MenuOption, message: MessageData) => {
    const bookmarkToRemove = bookmarks.find((bookmark) => bookmark.message.id === message.id);
    if (option.action === 'reply' || option.action === 'edit') {
      dispatch(setSelectedMessage(message));
      dispatch(setAction(option.action));
    } else if (option.action === 'pin' && message.id !== undefined) {
      handlePinMessage(message.id);
      dispatch(setAction(option.action));
    } else if (option.action === 'unpin' && pinnedMessage?.id !== undefined) {
      handleRemovePinMessage(pinnedMessage?.id);
      dispatch(setAction(option.action));
    } else if (option.action === 'bookmark' && message.id !== undefined) {
      handleBookmark(message.id);
      dispatch(setAction(option.action));
    } else if (option.action === 'removeBookmark' && bookmarkToRemove?.id) {
      handleRemoveBookmark(bookmarkToRemove?.id);
      dispatch(setAction(option.action));
    } else if (option.action === 'delete' && message.id !== undefined) {
      handleRemoveMessage(message.id);
      dispatch(setAction(option.action));
    } else if (option.action === 'forward') {
      handleForwardMessage(message);
      dispatch(setAction(option.action));
    } else if (option.action === 'copy') {
      dispatch(setAction(option.action));
      if (message.content) {
        // Create a container and render HtmlToText into it
        const container = document.createElement('div');
        const htmlContent = message.content;
        container.innerHTML = htmlContent;

        // Get text content and normalize whitespace
        let plainText = container.textContent || container.innerText || '';

        // Replace multiple consecutive line breaks with a single one
        plainText = plainText.replace(/\n\s*\n/g, '\n');

        // Trim any leading or trailing whitespace
        plainText = plainText.trim();

        navigator.clipboard
          .writeText(plainText)
          .then(() => {
            console.log('Text copied to clipboard');
          })
          .catch((err) => {
            console.error('Failed to copy text: ', err);
          });
      }
    }
    setSelectedMessageId(null);
  };

  // Rendering message content for the message (attachment or textmessage or replay message or forward message)
  const renderMessageContent = (message: MessageData) => {
    const isPinned = pinnedMessage && pinnedMessage.message.id === message.id;
    const isCurrentTextMatch = message.id === searchedMsg?.textMessage?.[currentMatchIndex]?.id;
    const isCurrentReplyMatch = message.id === searchedMsg?.replyMessage?.[currentMatchIndex]?.id;

    // Add forwarded message rendering
    if (message.type === 'forwardMessage') {
      return (
        <Box>
          <Box
            sx={{
              borderLeft: '2px solid #dbe5e5',
              pl: 1,
            }}
          >
            {message?.forwardMessage?.type === 'attachments' && message.forwardMessage?.path ? (
              <Box>
                {/\.(jpg|jpeg|png|gif)$/i.test(message?.forwardMessage.path) ? (
                  // Image files
                  <Box
                    component='img'
                    src={`https://chatroom.enitiation.com/${message.forwardMessage.path}`}
                    sx={{
                      maxWidth: '200px',
                      maxHeight: '200px',
                      objectFit: 'contain',
                    }}
                    alt='Forwarded attachment'
                  />
                ) : /\.(mp4|mov|avi|mkv)$/i.test(message.forwardMessage.path) ? (
                  // Video files
                  <video
                    controls
                    style={{
                      maxWidth: '200px',
                      maxHeight: '200px',
                    }}
                  >
                    <source
                      src={`https://chatroom.enitiation.com/${message.forwardMessage.path}`}
                      type='video/mp4'
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : /\.(pdf)$/i.test(message.forwardMessage.path) ? (
                  // PDF files
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img
                      src='https://cdn-icons-png.flaticon.com/512/4208/4208479.png'
                      alt='PDF Icon'
                      style={{
                        width: '30px',
                        height: '30px',
                      }}
                    />
                    <a
                      href={`https://chatroom.enitiation.com/${message.forwardMessage.path}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{
                        color: '#007bff',
                        textDecoration: 'underline',
                      }}
                    >
                      {message?.forwardMessage?.path.split('/').pop()}
                    </a>
                  </Box>
                ) : /\.(docx|doc)$/i.test(message.forwardMessage.path) ? (
                  // Word documents
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img
                      src='https://w7.pngwing.com/pngs/89/291/png-transparent-microsoft-word-logo-microsoft-word-office-open-xml-document-computer-icons-computer-file-word-file-icon-blue-angle-text-thumbnail.png'
                      alt='Word Icon'
                      style={{
                        width: '30px',
                        height: '30px',
                      }}
                    />
                    <a
                      href={`https://chatroom.enitiation.com/${message.forwardMessage.path}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{
                        color: '#007bff',
                        textDecoration: 'underline',
                      }}
                    >
                      {message?.forwardMessage?.path.split('/').pop()}
                    </a>
                  </Box>
                ) : /\.(txt)$/i.test(message.forwardMessage.path) ? (
                  // Text files
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img
                      src='https://w7.pngwing.com/pngs/89/291/png-transparent-microsoft-word-logo-microsoft-word-office-open-xml-document-computer-icons-computer-file-word-file-icon-blue-angle-text-thumbnail.png'
                      alt='Text Icon'
                      style={{
                        width: '30px',
                        height: '30px',
                      }}
                    />
                    <a
                      href={`https://chatroom.enitiation.com/${message.forwardMessage.path}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{
                        color: '#007bff',
                        textDecoration: 'underline',
                      }}
                    >
                      {message?.forwardMessage?.path.split('/').pop()}
                    </a>
                  </Box>
                ) : (
                  // Other file types
                  <a
                    href={`https://chatroom.enitiation.com/${message.forwardMessage.path}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{
                      color: '#007bff',
                      textDecoration: 'underline',
                    }}
                  >
                    Download File
                  </a>
                )}
              </Box>
            ) : (
              // Render text content
              <Typography sx={{ fontWeight: isPinned ? 'bold' : 'normal' }}>
                {isSearching &&
                message?.forwardMessage?.id &&
                isMessageInSearchResults(message?.forwardMessage?.id) ? (
                  highlightSearchText(
                    message.content,
                    getSearchQuery(),
                    isCurrentTextMatch,
                    currentMatchRef,
                  )
                ) : (
                  <HtmltoText HtmlContent={message.forwardMessage?.content} />
                )}
              </Typography>
            )}
          </Box>
        </Box>
      );
    }

    // Text messages
    if (message.content && message.type === 'text_messages') {
      return (
        <Typography
          sx={{
            fontWeight: isPinned ? 'bold' : 'normal',
          }}
        >
          {isSearching && message.id && isMessageInSearchResults(message.id) ? (
            highlightSearchText(
              message.content,
              getSearchQuery(),
              isCurrentTextMatch,
              currentMatchRef,
            )
          ) : (
            <HtmltoText HtmlContent={message.content} />
          )}
        </Typography>
      );
    }

    // Reply messages
    if (message.content && message.type === 'replyMessage') {
      return (
        <>
          <Typography
            variant='body2'
            color='text.secondary'
            sx={{
              mb: 1,
              p: 1,
              borderLeft: '4px solid #dbe5e5',
              bgcolor: '#f5f5f5',
              borderRadius: '4px',
            }}
          >
            {message.repliedTo?.type === 'forwardMessage' ? (
              <HtmltoText HtmlContent={message.repliedTo?.forwardMessage?.content || ''} />
            ) : (
              <HtmltoText HtmlContent={message.repliedTo?.content || ''} />
            )}
          </Typography>
          <Typography>
            {isSearching && message.id && isMessageInSearchResults(message.id) ? (
              highlightSearchText(
                message.content,
                getSearchQuery(),
                isCurrentReplyMatch,
                currentMatchRef,
              )
            ) : (
              <HtmltoText HtmlContent={message.content} />
            )}
          </Typography>
        </>
      );
    }

    // Attachments
    if (message.type === 'attachments') {
      return (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {message.path && /\.(jpg|jpeg|png|gif)$/i.test(message.path) ? ( // Image extensions
              <Box
                sx={{
                  position: 'relative',
                  '&:hover .fullscreen-icon': {
                    opacity: 1,
                  },
                }}
              >
                <img
                  src={`https://chatroom.enitiation.com/${message.path}`} // Image path
                  alt='Attachment'
                  style={{
                    maxWidth: '100%',
                    maxHeight: '300px',
                    objectFit: 'contain',
                    marginTop: '8px',
                  }}
                />
                <Tooltip title='FullScreen'>
                  <SquareIconButton
                    className='fullscreen-icon'
                    onClick={(e) => {
                      e.stopPropagation();
                      const target = e.currentTarget.previousSibling;
                      if (target instanceof HTMLImageElement) {
                        // Type check to ensure target is an image
                        const rect = target.getBoundingClientRect();
                        setInitialPosition({
                          top: rect.top,
                          left: rect.left,
                          width: rect.width,
                          height: rect.height,
                        });
                      }
                      setSelectedImageIndex(findImageIndex(message.path || ''));
                      setCarouselOpen(true);
                    }}
                    sx={{
                      position: 'absolute',
                      top: '10%',
                      left: '95%',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                      opacity: 0,
                      transition: 'opacity 0.2s',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                      },
                    }}
                  >
                    <FullscreenIcon />
                  </SquareIconButton>
                </Tooltip>
              </Box>
            ) : message.path && /\.(mp4|mov|avi|mkv)$/i.test(message.path) ? ( // Video extensions
              <video
                controls
                style={{
                  maxWidth: '100%',
                  maxHeight: '300px',
                  marginTop: '8px',
                }}
              >
                <source src={`https://chatroom.enitiation.com/${message.path}`} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            ) : message.path && /\.(pdf)$/i.test(message.path) ? ( // PDF files
              <>
                <img
                  src='https://cdn-icons-png.flaticon.com/512/4208/4208479.png'
                  alt='PDF Icon'
                  style={{
                    width: '30px',
                    height: '30px',
                    marginRight: '8px',
                  }}
                />
                <a
                  href={`https://chatroom.enitiation.com/${message.path}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    color: '#007bff',
                    textDecoration: 'underline',
                    marginTop: '8px',
                  }}
                >
                  {message.path.split('/').pop()}
                </a>
              </>
            ) : message.path && /\.(docx|doc)$/i.test(message.path) ? ( // Word documents
              <>
                <img
                  src='https://w7.pngwing.com/pngs/89/291/png-transparent-microsoft-word-logo-microsoft-word-office-open-xml-document-computer-icons-computer-file-word-file-icon-blue-angle-text-thumbnail.png' // Replace with the actual Word icon URL
                  alt='Word Icon'
                  style={{
                    width: '30px',
                    height: '30px',
                    marginRight: '8px',
                  }}
                />
                <a
                  href={`https://chatroom.enitiation.com/${message.path}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    color: '#007bff',
                    textDecoration: 'underline',
                    marginTop: '8px',
                  }}
                >
                  {message.path.split('/').pop()}
                </a>
              </>
            ) : message.path && /\.(txt)$/i.test(message.path) ? ( // Text files
              <>
                <img
                  src='https://w7.pngwing.com/pngs/89/291/png-transparent-microsoft-word-logo-microsoft-word-office-open-xml-document-computer-icons-computer-file-word-file-icon-blue-angle-text-thumbnail.png' // Replace with the actual Word icon URL
                  alt='Word Icon'
                  style={{
                    width: '30px',
                    height: '30px',
                    marginRight: '8px',
                  }}
                />
                <a
                  href={`https://chatroom.enitiation.com/${message.path}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    color: '#007bff',
                    textDecoration: 'underline',
                    marginTop: '8px',
                  }}
                >
                  {message.path.split('/').pop()}
                </a>
              </>
            ) : (
              <a
                href={`https://chatroom.enitiation.com/${message.path}`} // Fallback for other file types
                target='_blank'
                rel='noopener noreferrer'
                style={{
                  color: '#007bff',
                  textDecoration: 'underline',
                  marginTop: '8px',
                }}
              >
                Download File
              </a>
            )}
          </Box>
        </Box>
      );
    }

    return null;
  };

  const pinnedMessages = useSelector((state: RootState) => state.message.pinnedMessages);
  // const pinMessage = useSelector((state: RootState) => state.message.pinMessage);
  const [hasUserScrolled, setHasUserScrolled] = useState(false);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const previousPinIdRef = useRef<number | null>(null);
  const isAutoScrollingRef = useRef(false);
  const searchedMessages = useSelector((state: RootState) => state.message.searchedMessages);
  const previousSearchIdRef = useRef<number | null>(null);
  const isSearchContextActive = useSelector(
    (state: RootState) => state.message.isSearchContextActive,
  );

  // Effect to handle room changes
  useEffect(() => {
    setShowPinnedContext(false);
    dispatch(setIsSearchContextActive(false));
    setHasUserScrolled(false);
    previousPinIdRef.current = null;

    setIsLoading(true);
    if (socket) {
      dispatch(
        getMessageAction({
          socket,
          message1: messageParams,
        }),
      );
    }
  }, [chat?.id]);

  // Handle scrolling to pinned message
  const scrollToPinnedMessage = useCallback(() => {
    if (pinnedMessage?.message.id && !hasUserScrolled) {
      const pinnedMessageElement = document.getElementById(`message-${pinnedMessage.message.id}`);
      if (pinnedMessageElement && scrollableContainerRef.current) {
        isAutoScrollingRef.current = true;
        setTimeout(() => {
          pinnedMessageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          // Reset auto-scrolling flag after animation completes
          setTimeout(() => {
            isAutoScrollingRef.current = false;
          }, 100);
        }, 100);
      }
    }
  }, [pinnedMessage?.message.id, hasUserScrolled]);

  // Effect to detect manual scrolling
  useEffect(() => {
    const scrollContainer = scrollableContainerRef.current;
    if (!scrollContainer) return;

    let scrollTimeout: NodeJS.Timeout;

    const handleScroll = () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }

      if (!isAutoScrollingRef.current) {
        scrollTimeout = setTimeout(() => {
          setHasUserScrolled(true);
        }, 150);
      }
    };

    scrollContainer.addEventListener('scroll', handleScroll);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  }, []);

  // Effect to handle pinned message updates and trigger scroll
  useEffect(() => {
    if (showPinnedContext && pinnedMessages.length > 0) {
      const currentPinId = pinnedMessage?.message.id;

      // Only scroll if this is a new pin or initial load
      if (currentPinId && currentPinId !== previousPinIdRef.current) {
        previousPinIdRef.current = currentPinId;
        setHasUserScrolled(false);
        scrollToPinnedMessage();
      }
    }
  }, [showPinnedContext, pinnedMessages, pinnedMessage?.message.id, scrollToPinnedMessage]);

  // Effect to handle pinned message updates from socket
  useEffect(() => {
    if (socket && chat?.id) {
      socket.on('pinMessage', async () => {
        if (showPinnedContext) {
          await dispatch(getPinnedMessageAction({ socket, roomId: chat.id }));
        }
      });

      return () => {
        socket.off('pinMessage');
      };
    }
  }, [socket, chat?.id, showPinnedContext, dispatch]);

  // Function to handle clicking on pinned message
  const handlePinnedMessageClick = useCallback(async () => {
    if (chat?.id && socket) {
      setShowPinnedContext(true);
      setHasUserScrolled(false);
      previousPinIdRef.current = null;
      await dispatch(getPinnedMessageAction({ socket, roomId: chat.id }));
    }
  }, [chat?.id, socket, dispatch]);

  // Rendering pinned mesaage
  const renderPinnedMessageBanner = useCallback(() => {
    if (!pinnedMessage) return null;

    return (
      <PinnedMessage
        pinnedMessage={pinnedMessage}
        onUnpin={(id) => {
          handleRemovePinMessage(id);
          if (showPinnedContext) {
            setShowPinnedContext(false);
          }
        }}
        onScroll={handlePinnedMessageClick}
      />
    );
  }, [pinnedMessage, handleRemovePinMessage, showPinnedContext, chat, socket, dispatch]);

  const handleReturnToChat = useCallback(() => {
    setShowPinnedContext(false);
    // Wait for state update and re-render
    setTimeout(() => {
      if (pinnedMessage?.message?.id) {
        // First find and scroll to the pinned message
        const pinnedElement = document.getElementById(`message-${pinnedMessage.message.id}`);
        if (pinnedElement) {
          pinnedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        // Then scroll to bottom after a brief delay to ensure smooth transition
        setTimeout(() => {
          if (scrollableContainerRef.current) {
            scrollableContainerRef.current.scrollTo({
              top: scrollableContainerRef.current.scrollHeight,
              behavior: 'smooth',
            });
          }
        }, 100);
      } else {
        // If no pinned message, just scroll to bottom
        if (scrollableContainerRef.current) {
          scrollableContainerRef.current.scrollTo({
            top: scrollableContainerRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      }
    }, 0);
  }, [pinnedMessage]);

  // Search Message

  // Add a new state for search debounce

  // Create a debounce function for search
  const debouncedSearch = useCallback(
    debounce((query: string, roomId: number) => {
      if (query.trim()) {
        dispatch(setIsSearching(true));
        dispatch(setDebouncedSearchQuery(query));
        if (socket) {
          // Split search into terms and search server for each
          const searchTerms = query.trim().split(' ');
          searchTerms.forEach((term) => {
            dispatch(searchMessages({ socket, roomId, query: term }));
          });
        }
      } else {
        dispatch(setIsSearching(false));
        dispatch(setDebouncedSearchQuery(query));
      }
    }, 1000),
    [socket, dispatch],
  );

  // Function to highlight searched text in message content
  const highlightSearchText = (
    content: string,
    searchQuery: string,
    isCurrentMatch: boolean,
    currentMatchRef?: React.RefObject<HTMLSpanElement>,
  ) => {
    if (!searchQuery || !content) return content;

    // Convert HTML to plain text first
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    const plainTextContent = tempDiv.textContent || tempDiv.innerText || '';

    // Convert the search query into an array of individual terms
    const searchTerms = searchQuery.trim().toLowerCase().split(' ').filter(Boolean);

    // Escape special characters for each term for use in a regex
    const escapedSearchTerms = searchTerms.map((term) =>
      term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
    );

    // Combine terms into a regex pattern that matches any of them
    const searchPattern = new RegExp(`(${escapedSearchTerms.join('|')})`, 'gi');

    // First, split the content into lines
    const lines = plainTextContent.split('\n');

    return (
      <>
        {lines.map((line, lineIndex) => {
          // Split line based on the combined search pattern and highlight matches
          const parts = line.split(searchPattern);

          return (
            <React.Fragment key={lineIndex}>
              {parts.map((part, partIndex) =>
                searchTerms.some((term) => part.toLowerCase().includes(term)) ? (
                  <Box
                    ref={isCurrentMatch ? currentMatchRef : undefined}
                    key={`${lineIndex}-${partIndex}`}
                    component='span'
                    sx={{
                      backgroundColor: isCurrentMatch ? '#E6BF00' : '#FFD700',
                      padding: '0 2px',
                      borderRadius: '2px',
                      fontWeight: isCurrentMatch ? 'bold' : 'normal',
                    }}
                  >
                    {part}
                  </Box>
                ) : (
                  part
                ),
              )}
              {lineIndex < lines.length - 1 && <br />}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  // scroll to the currentmatch for searched message
  useEffect(() => {
    if (currentMatchRef.current) {
      currentMatchRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [currentMatchIndex]);

  // Function to check if a message is in search results
  const isMessageInSearchResults = (messageId: number) => {
    if (!searchedMsg || !debouncedSearchQuery) return false;

    // Check in all message types
    return (
      searchedMsg.textMessage?.some((msg) => msg.id === messageId) ||
      searchedMsg.replyMessage?.some((msg) => msg.id === messageId) ||
      false
    );
  };
  // Get the current search query from the search input
  const getSearchQuery = () => {
    return searchInputRef.current?.value || '';
  };

  const getTotalSearchResults = () => {
    if (!searchedMsg) return 0;
    return (
      (searchedMsg.textMessage?.length || 0) +
      // (searchedMsg.attachment?.length || 0) +
      (searchedMsg.replyMessage?.length || 0)
    );
  };

  // Handle search message click and fetch
  // const handleSearchMessageClick = useCallback(
  //   async (messageId: number) => {
  //     if (chat?.id && socket && messageId) {
  //       try {
  //         dispatch(setIsSearchContextActive(true));
  //         previousSearchIdRef.current = messageId;

  //         const searchData = {
  //           // Pass the specific message ID directly
  //           messageId: messageId,
  //           roomId: chat.id,
  //         };

  //         await dispatch(getSearchedMessageAction({ socket, searchData })).unwrap();
  //       } catch (error) {
  //         console.error('Failed to get search context:', error);
  //       }
  //     }
  //   },
  //   [chat?.id, socket, dispatch],
  // );
  // Modified scrollToMatch function with better handling of unrendered messages
  const scrollToMatch = useCallback(
    async (index: number) => {
      const allSearchedMessages = [
        ...(searchedMsg?.textMessage || []),
        ...(searchedMsg?.replyMessage || []),
      ];

      if (allSearchedMessages.length > 0 && index >= 0 && index < allSearchedMessages.length) {
        const matchedMessage = allSearchedMessages[index];
        if (matchedMessage.id) {
          // First, ensure the message is loaded
          if (!messages.some((msg) => msg.id === matchedMessage.id)) {
            // If message isn't loaded, fetch more messages first
            await fetchMoreMessages();
          }

          // Wait for next render cycle
          setTimeout(() => {
            const messageElement = document.getElementById(`message-${matchedMessage.id}`);
            if (messageElement && scrollableContainerRef.current) {
              // Calculate the scroll position to keep the search bar visible
              const searchBarHeight =
                document.querySelector('.MuiTextField-root')?.clientHeight || 0;
              const containerTop = scrollableContainerRef.current.getBoundingClientRect().top;
              const messageTop = messageElement.getBoundingClientRect().top;

              // Adjust scroll position to keep both the message and search bar visible
              scrollableContainerRef.current.scrollTop +=
                messageTop - containerTop - searchBarHeight - 20;

              // // Highlight the message temporarily
              // messageElement.style.transition = 'background-color 0.3s';
              // messageElement.style.backgroundColor = 'rgba(25, 118, 210, 0.1)';
              setTimeout(() => {
                messageElement.style.backgroundColor = '';
              }, 1500);
            }
          }, 100);
        }
      }
    },
    [messages, searchedMsg, fetchMoreMessages],
  );

  const handleSearchNavigation = (direction: 'up' | 'down') => {
    const totalMatches = getTotalSearchResults();
    if (totalMatches > 0) {
      setIsSearchNumberingActive(true);
      const newIndex =
        direction === 'up'
          ? (currentMatchIndex - 1 + totalMatches) % totalMatches
          : (currentMatchIndex + 1) % totalMatches;

      dispatch(setCurrentMatchIndex(newIndex));
      scrollToMatch(newIndex);
    }
  };

  // Modified effect for handling search message updates from socket
  useEffect(() => {
    if (socket && chat?.id) {
      socket.on('getSearchMessage', async () => {
        if (isSearchContextActive && previousSearchIdRef.current) {
          const searchData = {
            // Use the previously stored message ID
            messageId: previousSearchIdRef.current,
            roomId: chat.id,
          };

          await dispatch(getSearchedMessageAction({ socket, searchData }));
        }
      });

      return () => {
        socket.off('getSearchMessage');
      };
    }
  }, [socket, chat?.id, isSearchContextActive, dispatch]);

  const [selectUserId, setSelectUserId] = useState<number | null>(null);

  // Add handler to return to normal chat view
  const handleReturnFromSearch = useCallback(() => {
    dispatch(setIsSearchContextActive(false));
    previousSearchIdRef.current = null;
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTo({
        top: scrollableContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, []);
  const [isSearchNumberingActive, setIsSearchNumberingActive] = useState(false);

  // Modified getMessagesToRender to handle loading state
  const getMessagesToRender = useCallback(() => {
    if (showPinnedContext && pinnedMessages.length > 0) {
      return pinnedMessages;
    }
    if (isSearchContextActive) {
      return searchedMessages.length > 0 ? searchedMessages : messages;
    }
    return messages;
  }, [showPinnedContext, pinnedMessages, messages, isSearchContextActive, searchedMessages]);

  const handleCloseSearch = () => {
    dispatch(clearSearchResults());
    dispatch(setDebouncedSearchQuery(''));
    dispatch(setCurrentMatchIndex(0));
    dispatch(setIsSearchContextActive(false));

    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  };

  return (
    <Box
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      sx={{
        height: mobileView ? 'calc(100% - 110.67px) ' : '100% ',
        overflowY: 'hidden',
        mt: mobileView ? '55px' : '0px',
        pb: '5px',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '100%',
          // pt: pinnedMessage || showSearch ? 0 : 4,
        }}
      >
        <Box>
          {showSearch && (
            <Box
              display='flex'
              alignItems='center'
              p='5px'
              pt={mobileView ? '0px' : '5px'}
              // borderTop={mobileView ? '2px solid lightgray' : '0px'}
              borderBottom='2px solid lightgray'
              width='100%'
              sx={{
                position: 'sticky',
                opacity: 1,
                backgroundColor: 'white',
              }}
            >
              <TextField
                variant='outlined'
                fullWidth
                placeholder='Search'
                inputRef={searchInputRef}
                InputProps={{
                  sx: {
                    height: '40px',
                    fontSize: '14px',
                    paddingRight: 0,
                  },
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SquareIconButton onClick={handleCloseSearch} size='small'>
                        <CloseIcon />
                      </SquareIconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  if (selectedChat?.id) {
                    setIsSearchNumberingActive(false);
                    setCurrentMatchIndex(0);
                    debouncedSearch(e.target.value, selectedChat.id);
                  }
                }}
              />
              <Box
                display='flex'
                alignItems='center'
                ml='5px'
                height={40}
                sx={{
                  border: '1px solid rgba(0, 0, 0, 0.23)', // Similar to outlined border
                  borderRadius: '4px',
                  '&:hover': {
                    borderColor: 'rgba(0, 0, 0, 0.87)', // Darker border on hover
                  },
                }}
              >
                <Box display='flex' flexDirection='column' alignItems='center'>
                  <SquareIconButton
                    size='small'
                    sx={{ padding: '0px', marginBottom: '-5px' }}
                    onClick={() => {
                      handleSearchNavigation('up');
                    }}
                  >
                    <ArrowDropUpIcon />
                  </SquareIconButton>
                  <SquareIconButton
                    size='small'
                    sx={{ padding: '0px', marginTop: '-5px' }}
                    onClick={() => {
                      handleSearchNavigation('down');
                    }}
                  >
                    <ArrowDropDownIcon />
                  </SquareIconButton>
                </Box>

                <Typography variant='body2' mr={1}>
                  {debouncedSearchQuery && isSearchNumberingActive
                    ? `${getTotalSearchResults() - currentMatchIndex}`
                    : '?'}
                  /{isSearching ? `${getTotalSearchResults()}` : '0'}
                </Typography>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            {renderPinnedMessageBanner()}
          </Box>
        </Box>

        {isSearchContextActive && (
          <Tooltip title='Recent Chat'>
            <ExpandMoreIcon
              onClick={handleReturnFromSearch}
              fontSize='inherit'
              sx={{
                backgroundColor: '#b0b0b0',
                position: 'absolute',
                fontSize: '3rem',
                p: 1,
                bottom: 20,
                right: 30,
                zIndex: 1,
                borderRadius: '50%',
                cursor: 'pointer',
                boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
                transition: '0.3s',
                '&:hover': {
                  backgroundColor: '#a0a0a0',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                },
              }}
            />
          </Tooltip>
        )}
        {showPinnedContext && (
          <Tooltip title='Recent Chat'>
            <ExpandMoreIcon
              onClick={handleReturnToChat}
              fontSize='inherit'
              sx={{
                backgroundColor: '#b0b0b0',
                position: 'absolute',
                fontSize: '3rem',
                p: 1,
                bottom: 20,
                right: 30,
                zIndex: 1,
                borderRadius: '50%',
                cursor: 'pointer',
                boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
                transition: '0.3s',
                '&:hover': {
                  backgroundColor: '#a0a0a0',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                },
              }}
            />
          </Tooltip>
        )}

        <div
          id='scrollableDiv'
          // ref={scrollableDivRef}
          ref={scrollableContainerRef}
          style={{
            width: '100%',
            maxHeight: mobileView
              ? (showSearch && pinnedMessage?.message.type === 'forwardMessage') ||
                (showSearch && pinnedMessage?.message.type === 'replyMessage')
                ? 'calc(100vh - 213.8px)'
                : (showSearch && pinnedMessage?.message.type === 'text_messages') ||
                  (showSearch && pinnedMessage?.message.type === 'attachments')
                ? 'calc(100vh - 203.87px)'
                : showSearch
                ? 'calc(100vh - 163.87px)'
                : pinnedMessage?.message.type === 'forwardMessage' ||
                  pinnedMessage?.message.type === 'replyMessage'
                ? 'calc(100vh - 160.6px)'
                : pinnedMessage?.message.type === 'text_messages' ||
                  pinnedMessage?.message.type === 'attachments'
                ? 'calc(100vh - 150.67px)'
                : 'calc(100vh - 110.67px)'
              : (showSearch && pinnedMessage?.message.type === 'forwardMessage') ||
                (showSearch && pinnedMessage?.message.type === 'replyMessage')
              ? 'calc(100vh - 281.11px)'
              : (showSearch && pinnedMessage?.message.type === 'text_messages') ||
                (showSearch && pinnedMessage?.message.type === 'attachments')
              ? 'calc(100vh - 271.18px)'
              : showSearch
              ? 'calc(100vh - 231.18px)'
              : pinnedMessage?.message.type === 'forwardMessage' ||
                pinnedMessage?.message.type === 'replyMessage'
              ? 'calc(100vh - 234.51px)'
              : pinnedMessage?.message.type === 'text_messages' ||
                pinnedMessage?.message.type === 'attachments'
              ? 'calc(100vh - 224.58px)'
              : 'calc(100vh - 184.58px)',
            overflow: 'auto',
            display: 'flex',
            // padding: '5px 0px',
            flexDirection: 'column-reverse',
          }}
        >
          {isLoading ? (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              padding={2}
              width='100%'
              height='100%'
            >
              <CircularProgress />
            </Box>
          ) : (
            messages.length > 0 && (
              <InfiniteScroll
                dataLength={getMessagesToRender()?.length || 0}
                next={fetchMoreMessages} // Load more messages when the user scrolls up
                hasMore={!showPinnedContext && hasMore}
                style={{ display: 'flex', flexDirection: 'column-reverse' }}
                loader={
                  <Box display='flex' justifyContent='center' alignItems='center' padding={2}>
                    <CircularProgress />
                  </Box>
                }
                inverse={true} // Inverse for bottom-to-top loading
                scrollableTarget='scrollableDiv'
              >
                {getMessagesToRender().map((message, index) => {
                  const isSender = message?.sender?.userId
                    ? message.sender.userId === userData.data.id
                    : true;
                  const showAvatar = message?.sender?.userId
                    ? !isSender && isFirstMessage(index)
                    : false;
                  const isSelected =
                    message.id !== undefined && selectedMessageId === message.id.toString();
                  const isPinned = pinnedMessage && pinnedMessage.message.id === message.id;
                  const isForwardMessage = message.type === 'forwardMessage';
                  const isEdited =
                    new Date(message.createdAt ?? '').setMilliseconds(0) !==
                    new Date(message.updatedAt ?? '').setMilliseconds(0);

                  // const showTimestamp = isFirstMessage(index);

                  // Determine if this message needs to show date header
                  const showDateHeader = shouldShowDateHeader(index);

                  const getInitials = (name: string) => {
                    const parts = name.trim().split(' ');

                    // If there's only one part, return the first two letters of that part
                    if (parts.length === 1) {
                      return parts[0].slice(0, 2).toUpperCase();
                    }

                    // If there are multiple parts, return the first letter of the first and last names
                    return `${parts[0][0]}${parts[parts.length - 1][0]}`.toUpperCase();
                  };

                  // const isPinnedContextMessage =
                  //   showPinnedContext && message.id === pinnedMessage?.message.id;

                  return (
                    <Box
                      key={message.id}
                      id={`message-${message.id}`}

                      // sx={{
                      //   bgcolor: isPinnedContextMessage ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
                      // }}
                    >
                      {/* Date header */}

                      {showDateHeader && (
                        <Box sx={{ textAlign: 'center' }}>
                          <Divider variant='middle'>
                            <Typography
                              variant='body2'
                              sx={{
                                color: 'gray',
                                display: 'inline-block',
                                bgcolor: '#fff',
                                px: 1,
                                borderRadius: 1,
                              }}
                            >
                              {getDateDisplay(message?.updatedAt)}
                            </Typography>
                          </Divider>
                        </Box>
                      )}

                      {/* Message content */}

                      <Box
                        ref={isPinned ? pinnedMessageRef : null}
                        display='flex'
                        flexDirection={isSender ? 'row-reverse' : 'row'}
                        alignItems='center'
                        my={0.5}
                      >
                        {showAvatar && message.type !== 'chat_logs' && (
                          <Box
                            onClick={() => {
                              const userInRoom = currentRoom?.roomParticipants.find(
                                (participant) => participant.user.userId === message.sender?.userId,
                              );

                              if (userInRoom) {
                                setSelectUserId(message.sender?.userId || null);
                                isGroup && setIsSettingsOpen(true);
                              } else {
                                dynamicFailed('No user found');
                              }
                            }}
                          >
                            <Avatar
                              alt='No Image'
                              src={`https://chatroom.enitiation.com/${message.sender?.profilePictureURL}`}
                              sx={{
                                width: 45,
                                height: 45,
                                ml: '5px',
                                bgcolor: stringToColor(message.sender?.userName || 'Anonymous'),
                                cursor: isGroup ? 'pointer' : '',
                                fontSize: '20px',
                                color: 'white',
                                '&.MuiAvatar-root': {
                                  border: 'none',
                                },
                              }}
                            >
                              {getInitials(message.sender?.userName || 'Anonymous')}
                            </Avatar>
                          </Box>
                        )}

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            wordBreak: 'break-word',
                          }}
                        >
                          {/* pinned icon on top of the message bubble  */}

                          {/* option popups when clicked in the chat bubble */}
                          <Box
                            display='flex'
                            alignItems='center'
                            flexDirection={isSender ? 'row' : 'row-reverse'}
                            justifyContent={isSender ? 'end' : 'start'}
                          >
                            {isSelected && (
                              <Box
                                display='flex'
                                alignItems='center'
                                flexDirection={isSender ? 'row' : 'row-reverse'}
                              >
                                <OptionsMenu
                                  options={
                                    isSender
                                      ? isForwardMessage
                                        ? [
                                            { label: 'Copy', action: 'copy' },
                                            { label: 'Delete', action: 'delete' },

                                            { label: 'Forward', action: 'forward' },
                                            isPinned
                                              ? { label: 'Unpin', action: 'unpin' }
                                              : { label: 'Pin', action: 'pin' },
                                            { label: 'Reply', action: 'reply' },
                                            isBookmarked(message)
                                              ? {
                                                  label: 'Remove Bookmark',
                                                  action: 'removeBookmark',
                                                }
                                              : { label: 'Add Bookmark', action: 'bookmark' },
                                          ]
                                        : [
                                            { label: 'Copy', action: 'copy' },
                                            { label: 'Delete', action: 'delete' },
                                            { label: 'Edit', action: 'edit' },
                                            { label: 'Forward', action: 'forward' },
                                            isPinned
                                              ? { label: 'Unpin', action: 'unpin' }
                                              : { label: 'Pin', action: 'pin' },
                                            { label: 'Reply', action: 'reply' },
                                            isBookmarked(message)
                                              ? {
                                                  label: 'Remove Bookmark',
                                                  action: 'removeBookmark',
                                                }
                                              : { label: 'Add Bookmark', action: 'bookmark' },
                                          ]
                                      : [
                                          { label: 'Copy', action: 'copy' },
                                          { label: 'Forward', action: 'forward' },
                                          isPinned
                                            ? { label: 'Unpin', action: 'unpin' }
                                            : { label: 'Pin', action: 'pin' },
                                          { label: 'Reply', action: 'reply' },
                                          isBookmarked(message)
                                            ? { label: 'Remove Bookmark', action: 'removeBookmark' }
                                            : { label: 'Add Bookmark', action: 'bookmark' },
                                        ]
                                  }
                                  onOptionSelect={(option) => handleOptionSelect(option, message)}
                                />

                                <Typography
                                  variant='body2'
                                  sx={{
                                    fontWeight: '300',
                                    color: 'text.secondary',
                                    fontSize: '12px',
                                    ml: isSender ? 0.25 : 0,
                                    mr: isSender ? 0 : 0.25,
                                  }}
                                >
                                  {message.updatedAt
                                    ? new Date(message.updatedAt).toLocaleTimeString('en-GB', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                      })
                                    : ''}
                                </Typography>
                              </Box>
                            )}

                            {message.type !== 'chat_logs' && (
                              <Box
                                data-message-id={message.id}
                                ref={
                                  isMessageInSearchResults(message.id || 0) ? currentMatchRef : null
                                }
                                onClick={() => handleMessageClick(message.id)}
                                sx={{
                                  maxWidth: mobileView ? '50dvw' : '40dvw',
                                  bgcolor: isSender ? '#EAF0FF' : '#D3D3D3',
                                  p: '6px 10px',
                                  m: '0px 5px',
                                  borderRadius: '6px',
                                  position: 'relative',
                                  cursor: 'pointer',
                                  '&:hover': {
                                    bgcolor: isSender ? '#DAEAFF' : '#C3C3C3',
                                  },
                                }}
                              >
                                {(isEdited ||
                                  isForwardMessage ||
                                  isBookmarked(message) ||
                                  isPinned) && (
                                  <Box
                                    display='flex'
                                    gap='.5rem'
                                    justifyContent={isSender ? 'end' : 'start'}
                                    mb={0.5}
                                  >
                                    {isEdited && (
                                      <Tooltip title='Edit'>
                                        <EditIcon
                                          fontSize='medium'
                                          sx={{
                                            fontSize: '1rem',
                                            bgcolor: 'white',
                                            borderRadius: '100px',
                                            p: 0.3,
                                            color: 'rgba(0, 0, 0, 0.54)',
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                    {isForwardMessage && (
                                      <Tooltip title='Forward'>
                                        <ArrowForwardIcon
                                          fontSize='medium'
                                          sx={{
                                            fontSize: '1rem',
                                            bgcolor: 'white',
                                            borderRadius: '100px',
                                            p: 0.3,
                                            color: 'rgba(0, 0, 0, 0.54)',
                                          }}
                                        />
                                      </Tooltip>
                                    )}

                                    {isBookmarked(message) && (
                                      <Tooltip title='Bookmark'>
                                        <BookmarkIcon
                                          fontSize='medium'
                                          sx={{
                                            fontSize: '1rem',
                                            bgcolor: 'white',
                                            borderRadius: '100px',
                                            p: 0.3,
                                            color: 'rgba(0, 0, 0, 0.54)',
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                    {isPinned && (
                                      <Tooltip title='Pin'>
                                        <PushPinIcon
                                          fontSize='medium'
                                          sx={{
                                            fontSize: '1rem',
                                            bgcolor: 'white',
                                            borderRadius: '100px',
                                            p: 0.3,
                                            color: 'rgba(0, 0, 0, 0.54)',
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </Box>
                                )}

                                {renderMessageContent(message)}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>

                      {(message.seenBy?.length ?? 0) > 0 && (
                        <SeenIndicator message={message} messages={messages} />
                      )}

                      {message.type === 'chat_logs' && (
                        <Box
                          sx={{
                            bgcolor: '#F5F5F5',
                            p: '8px 12px',
                            borderRadius: '5px',
                            mt: '6px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography variant='subtitle2' component='span' sx={{ fontWeight: 700 }}>
                            {message.content}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </InfiniteScroll>
            )
          )}
          <div ref={chatEndRef} />
        </div>

        {/* Modal for displaying selected file */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='preview'
          aria-describedby='preview'
          closeAfterTransition
          BackdropProps={{ timeout: 100 }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              position: 'relative',
            }}
          >
            {selectedFile && selectedFile.startsWith('data:image') ? (
              <img src={selectedFile} alt='Preview' style={{ maxHeight: '90%', maxWidth: '90%' }} />
            ) : (
              <Box
                sx={{
                  bgcolor: 'white',
                  p: 1,
                  borderRadius: 1,
                  boxShadow: 24,
                  maxHeight: '80vh',
                  overflowY: 'auto',
                }}
              >
                <Typography>{selectedFile}</Typography>
              </Box>
            )}
            <SquareIconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                bgcolor: 'rgba(255, 255, 255, 0.8)',
                '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' },
              }}
            >
              <CloseIcon />
            </SquareIconButton>
          </Box>
        </Modal>
        <ImageCarousel
          open={carouselOpen}
          onClose={() => setCarouselOpen(false)}
          images={getImagesFromMessages()}
          initialIndex={selectedImageIndex}
          initialPosition={initialPosition}
        />
      </Box>

      <ForwardDialog
        open={forwardDialogOpen}
        onClose={() => setForwardDialogOpen(false)}
        message={selectedForwardMessage}
        rooms={rooms}
        onForward={handleForward}
      />

      <ConfirmationDialog
        open={deleteDialogOpen}
        title='Confirm Message Removal'
        message={`Are you sure you want to remove this message from the chat?`}
        onConfirm={confirmDeleteMessage}
        onCancel={cancelDeleteMessage}
      />
      <Setting
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        selectedUserId={selectUserId}
        chats={chat}
        setShowPinnedContext={setShowPinnedContext}
      />
    </Box>
  );
};

export default ChatArea;
