import { createAsyncThunk } from '@reduxjs/toolkit';
import { Socket } from 'socket.io-client';
import {
  setRooms,
  setLoading,
  setError,
  addRoom,
  addRoomParticipants,
  removeRoomParticipants,
  setRoomDetails,
  setSearchedRooms,
  updateRoomDetails,
  removeRoom,
  setSelectedChat,
  addFavorite,
  removeFavorite,
  setFavorites,
} from './roomSlice';
import {
  CreateRoomPayload,
  RoomData,
  AddParticipantPayload,
  UserData,
  RoomParticipant,
  RemoveParticipantPayload,
  RoomDetailsPayload,
  RoomDetailsResponse,
  UpdateRoomPayload,
  FavoritesData,
  GetFavoritesResponse,
} from '../constants/constants';
import { RootState } from '../store';
import { fetchUser } from '../user/userActions';

interface FetchRoomsByUserArgs {
  socket: Socket;
  userId: number;
  filterBy: string;
}

interface CreateRoomArgs {
  socket: Socket;
  payload: CreateRoomPayload;
}

interface FetchRoomDetailsArgs {
  socket: Socket;
  payload: RoomDetailsPayload;
}

interface AddParticipantsArgs {
  socket: Socket;
  payload: AddParticipantPayload;
}

interface RemoveParticipantsArgs {
  socket: Socket;
  payload: RemoveParticipantPayload;
}

interface SearchRoomsArgs {
  socket: Socket;
  userId: number;
  query: string;
}

interface UpdateRoomArgs {
  socket: Socket;
  payload: UpdateRoomPayload;
}

interface RemoveRoomArgs {
  socket: Socket;
  payload: RoomDetailsPayload;
}

interface AddFavoriteArgs {
  socket: Socket;
  roomId: number;
}

interface RemoveFavoriteArgs {
  socket: Socket;
  roomId: number;
}

interface GetFavoritesArgs {
  socket: Socket;
}

export const fetchRoomsByUser = createAsyncThunk(
  'room/fetchRoomsByUser',
  async ({ socket, userId, filterBy }: FetchRoomsByUserArgs, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      if (socket) {
        socket.emit('getRoomByUser', { userId: userId, page: 1, limit: 100, filterBy });

        // Consider using `on` if you expect multiple responses or switch back to `once` if it's one-time
        socket.on('getRoomByUsers', (response: any) => {
          if (response.error) {
            dispatch(setError(response.error));
          } else {
            const { data, total, page, limit } = response;
            dispatch(setRooms({ rooms: data, total, page, limit }));
          }
          dispatch(setLoading(false));
        });
      }
    } catch (error) {
      console.error('Error in fetching rooms by userId:', error);
      dispatch(setError('Failed to fetch rooms'));
      dispatch(setLoading(false));
      throw error;
    }
  },
);

export const fetchRoomDetails = createAsyncThunk(
  'room/fetchRoomDetails',
  async ({ socket, payload }: FetchRoomDetailsArgs, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      if (socket) {
        socket.emit('getRoomDetails', payload);

        socket.once('getRoom', (response: RoomDetailsResponse) => {
          if (response.data && response.data.length > 0) {
            dispatch(setRoomDetails(response.data[0]));
          } else {
            dispatch(setError('Room not found'));
          }
          dispatch(setLoading(false));
        });
      }
    } catch (error) {
      console.error('Error in fetching room details:', error);
      dispatch(setError('Failed to fetch room details'));
      dispatch(setLoading(false));
      throw error;
    }
  },
);

export const createRoom = createAsyncThunk<RoomData, CreateRoomArgs>(
  'room/createRoom',
  async ({ socket, payload }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));

    return new Promise((resolve, reject) => {
      try {
        socket.emit('joinRoom', payload);

        socket.once('roomJoined', (response: RoomData) => {
          if (response) {
            dispatch(addRoom(response));
            dispatch(setSelectedChat(response));
            resolve(response); // Return the room data to fulfill the promise
          } else {
            dispatch(setError('Failed to create room'));
            reject(new Error('Failed to create room'));
          }
        });
      } catch (error) {
        console.error('Error in creating room:', error);
        dispatch(setError('Failed to create room'));
        dispatch(setLoading(false));
        rejectWithValue(error);
      } finally {
        dispatch(setLoading(false));
      }
    });
  },
);

export const updateRoom = createAsyncThunk(
  'room/updateRoom',
  async ({ socket, payload }: UpdateRoomArgs, { dispatch }) => {
    try {
      dispatch(setLoading(true));

      socket.emit('updateRoomDetails', payload);

      socket.once('updateRoom', (response: RoomData) => {
        if (response) {
          dispatch(updateRoomDetails(response));
          dispatch(setSelectedChat(response));
        } else {
          dispatch(setError('Failed to update room details'));
        }
        dispatch(setLoading(false));
      });
    } catch (error) {
      console.error('Error in updating room details:', error);
      dispatch(setError('Failed to update room details'));
      dispatch(setLoading(false));
      throw error;
    }
  },
);

export const addParticipants = createAsyncThunk<void, AddParticipantsArgs, { state: RootState }>(
  'room/addParticipants',
  async ({ socket, payload }, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));
      socket.emit('addRoomParticipants', payload);

      socket.once('addRoomParticipant', async (response: string) => {
        const match = response.match(/Users with id: (\d+) added to Room with id: (\d+)/);
        if (match) {
          const [, userIds, roomId] = match;
          const addedUserIds = userIds.split(',').map(Number);
          const addedParticipants: RoomParticipant[] = [];
          for (const userId of addedUserIds) {
            await dispatch(fetchUser({ socket, id: userId }));
            const state = getState();
            const userData = state.user.users.find((user: UserData) => user.userId === userId);

            if (userData) {
              const existingParticipant = state.room.rooms
                .find((room: RoomData) => room.id === Number(roomId))
                ?.roomParticipants.find(
                  (participant: RoomParticipant) => participant.user.userId === userId,
                );

              addedParticipants.push({
                id: existingParticipant?.id || userId,
                createdAt: existingParticipant?.createdAt || new Date().toISOString(),
                deletedAt: existingParticipant?.deletedAt || null,
                userRole: existingParticipant?.userRole || 'member',
                user: userData,
              });
            }
          }

          dispatch(
            addRoomParticipants({
              roomId: Number(roomId),
              participants: addedParticipants,
            }),
          );
        } else {
          dispatch(setError('Failed to parse response when adding participants to room'));
        }
        dispatch(setLoading(false));
      });
    } catch (error) {
      console.error('Error in adding participants to room:', error);
      dispatch(setError('Failed to add participants to room'));
      dispatch(setLoading(false));
      throw error;
    }
  },
);

export const removeParticipants = createAsyncThunk<
  void,
  RemoveParticipantsArgs,
  { state: RootState }
>('room/removeParticipants', async ({ socket, payload }, { dispatch }) => {
  try {
    dispatch(setLoading(true));

    socket.emit('removeParticipats', payload);

    socket.once('removeParticipant', (response: { removedParticipant: RoomParticipant[] }) => {
      if (response.removedParticipant) {
        dispatch(
          removeRoomParticipants({
            roomId: payload.roomId,
            removedParticipants: response.removedParticipant,
          }),
        );
      } else {
        dispatch(setError('Failed to remove participants from room'));
      }
      dispatch(setLoading(false));
    });
  } catch (error) {
    console.error('Error in removing participants from room:', error);
    dispatch(setError('Failed to remove participants from room'));
    dispatch(setLoading(false));
    throw error;
  }
});

export const searchRooms = createAsyncThunk(
  'room/searchRooms',
  async ({ socket, userId, query }: SearchRoomsArgs, { dispatch }) => {
    try {
      dispatch(setLoading(true));

      if (socket) {
        // Emit the searchRoom event
        socket.emit('searchRoom', { userId, query });

        // Listen for the searchRooms event
        socket.on('searchRooms', (response: any) => {
          if (response.error) {
            dispatch(setError(response.error));
          } else {
            // Handle the response
            const { newUsers, individual, groups } = response;

            // Process rooms and dispatch them to the Redux store
            dispatch(
              setSearchedRooms({
                rooms: [newUsers, individual, groups],
              }),
            );
          }
          dispatch(setLoading(false));
        });
      }
    } catch (error) {
      console.error('Error in searching rooms:', error);
      dispatch(setError('Failed to search rooms'));
      dispatch(setLoading(false));
      throw error;
    }
  },
);

export const removeRoomAction = createAsyncThunk(
  'room/removeRoom',
  async ({ socket, payload }: RemoveRoomArgs, { dispatch }) => {
    try {
      dispatch(setLoading(true));

      socket.emit('removeRoom', payload);

      socket.once('removeRoom', (response: string) => {
        const match = response.match(/Room with id: (\d+) removed/);

        if (match) {
          const [, roomId] = match;
          dispatch(removeRoom(Number(roomId)));
        } else {
          dispatch(setError('Failed to parse response when removing room'));
        }

        dispatch(setLoading(false));
      });
    } catch (error) {
      console.error('Error in removing room:', error);
      dispatch(setError('Failed to remove room'));
      dispatch(setLoading(false));
      throw error;
    }
  },
);

export const FavoriteRoom = createAsyncThunk(
  'room/FavoriteRoom',
  async ({ socket, roomId }: AddFavoriteArgs, { dispatch }) => {
    try {
      socket.emit('favorites', { roomId });

      socket.once('favorites', (response: FavoritesData) => {
        dispatch(addFavorite(response));
      });
    } catch (error) {
      console.error('Error in adding favorite:', error);
      throw error;
    }
  },
);

export const removeFavoriteAction = createAsyncThunk(
  'room/removeFavorite',
  async ({ socket, roomId }: RemoveFavoriteArgs, { dispatch }) => {
    try {
      socket.emit('removeFavorites', { roomId });

      socket.once('removeFavorites', (response: number) => {
        dispatch(removeFavorite(response));
      });
    } catch (error) {
      console.error('Error in removing Favorite:', error);
      dispatch(setError('Failed to remove Favorite'));
      dispatch(setLoading(false));
      throw error;
    }
  },
);

export const getFavorites = createAsyncThunk(
  'room/getFavorites',
  async ({ socket }: GetFavoritesArgs, { dispatch }) => {
    try {
      socket.emit('getFavorites');

      socket.once('getFavorites', (response: GetFavoritesResponse) => {
        dispatch(setFavorites(response.favorites));
      });
    } catch (error) {
      console.error('Error in getting Favorites:', error);
      dispatch(setError('Failed to get Favorites'));
      dispatch(setLoading(false));
      throw error;
    }
  },
);
