/* eslint-disable react/prop-types */
import { Avatar, Box, Divider, IconButton, Typography } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import Badge from '@mui/material/Badge';
import isMobile from '../../hooks/isMobile';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React, { useContext, useEffect, useRef, useState } from 'react';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CloseIcon from '@mui/icons-material/Close';
import { BookmarkData, MenuOption, MessageData } from '../../store/constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { SocketContext } from '../../utils/SocketProvider';
import { getBookmarksAction, removeBookmarkAction } from '../../store/message/messageActions';
import { debounce } from 'lodash';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ImageCarousel from './ImageCarousel';
import OptionsMenu from './ContextMenu/OptionsMenu';
import { setAction } from '../../store/message/messageSlice';
import HtmltoText from './HtmltoText';
import { stringToColor } from '../../utils/exports/Function';
import { SquareIconButton } from '../../utils/exports/Styles';

interface ChatBoxHeadingProps {
  onBackClick: () => void;
}

const Bookmarks: React.FC<ChatBoxHeadingProps> = ({ onBackClick }) => {
  const mobileView = isMobile();
  const dispatch: AppDispatch = useDispatch();
  const socket = useContext(SocketContext);
  const bookmarks = useSelector((state: RootState) => state.message.bookmarks);
  const bookmarksContainerRef = useRef<HTMLDivElement>(null);
  const [initialPosition, setInitialPosition] = useState<
    | {
        top: number;
        left: number;
        width: number;
        height: number;
      }
    | undefined
  >(undefined);
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedBookmarkId, setSelectedBookmarkId] = useState<string | null>(null);

  // Scroll to bottom
  useEffect(() => {
    if (bookmarksContainerRef.current) {
      bookmarksContainerRef.current.scrollTop = bookmarksContainerRef.current.scrollHeight;
    }
  }, [bookmarks]);

  // Function to get all images from messages
  const getImagesFromMessages = () => {
    return bookmarks
      .filter(
        (bookmark) =>
          bookmark.message.type === 'attachments' &&
          bookmark.message.path &&
          /\.(jpg|jpeg|png|gif)$/i.test(bookmark.message.path),
      )
      .map((bookmark) => ({
        id: bookmark.message.id || 0,
        path: bookmark.message.path || '',
        updatedAt: bookmark.message.updatedAt || '',
        sender: {
          userName: bookmark.message.sender?.userName || 'Unknown',
        },
      }));
  };

  // Function to find image index in the filtered images array
  const findImageIndex = (currentPath: string) => {
    const images = getImagesFromMessages();
    return images.findIndex((img) => img.path === currentPath);
  };

  // Get Bookmarks
  const debouncedDispatch = debounce(() => {
    if (socket) {
      dispatch(getBookmarksAction({ socket }));
    }
  }, 1000);

  useEffect(() => {
    debouncedDispatch();

    // Cleanup the debounce on unmount
    return () => {
      debouncedDispatch.cancel();
    };
  }, [dispatch, socket]);

  // Render bookmarked contents
  const renderMessageContent = (message: MessageData) => {
    // Forward Message
    if (message.type === 'forwardMessage') {
      return (
        <Box>
          <Typography
            variant='body2'
            color='text.secondary'
            sx={{
              mb: 1,
              fontSize: '0.8rem',
            }}
          >
            Forwarded message
          </Typography>
          <Box
            sx={{
              borderLeft: '2px solid #dbe5e5',
              pl: 1,
            }}
          >
            {message.forwardMessage?.type === 'attachments' && message.forwardMessage?.path ? (
              <Box>
                {/\.(jpg|jpeg|png|gif)$/i.test(message.forwardMessage.path) ? (
                  // Image files
                  <Box
                    component='img'
                    src={`https://chatroom.enitiation.com/${message.forwardMessage.path}`}
                    sx={{
                      maxWidth: '200px',
                      maxHeight: '200px',
                      objectFit: 'contain',
                    }}
                    alt='Forwarded attachment'
                  />
                ) : /\.(mp4|mov|avi|mkv)$/i.test(message.forwardMessage.path) ? (
                  // Video files
                  <video
                    controls
                    style={{
                      maxWidth: '200px',
                      maxHeight: '200px',
                    }}
                  >
                    <source
                      src={`https://chatroom.enitiation.com/${message.forwardMessage.path}`}
                      type='video/mp4'
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : /\.(pdf)$/i.test(message.forwardMessage.path) ? (
                  // PDF files
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img
                      src='https://cdn-icons-png.flaticon.com/512/4208/4208479.png'
                      alt='PDF Icon'
                      style={{
                        width: '30px',
                        height: '30px',
                      }}
                    />
                    <a
                      href={`https://chatroom.enitiation.com/${message.forwardMessage.path}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{
                        color: '#007bff',
                        textDecoration: 'underline',
                      }}
                    >
                      {message.forwardMessage.path.split('/').pop()}
                    </a>
                  </Box>
                ) : /\.(docx|doc)$/i.test(message.forwardMessage.path) ? (
                  // Word documents
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img
                      src='https://w7.pngwing.com/pngs/89/291/png-transparent-microsoft-word-logo-microsoft-word-office-open-xml-document-computer-icons-computer-file-word-file-icon-blue-angle-text-thumbnail.png'
                      alt='Word Icon'
                      style={{
                        width: '30px',
                        height: '30px',
                      }}
                    />
                    <a
                      href={`https://chatroom.enitiation.com/${message.forwardMessage.path}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{
                        color: '#007bff',
                        textDecoration: 'underline',
                      }}
                    >
                      {message.forwardMessage.path.split('/').pop()}
                    </a>
                  </Box>
                ) : /\.(txt)$/i.test(message.forwardMessage.path) ? (
                  // Text files
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img
                      src='https://w7.pngwing.com/pngs/89/291/png-transparent-microsoft-word-logo-microsoft-word-office-open-xml-document-computer-icons-computer-file-word-file-icon-blue-angle-text-thumbnail.png'
                      alt='Text Icon'
                      style={{
                        width: '30px',
                        height: '30px',
                      }}
                    />
                    <a
                      href={`https://chatroom.enitiation.com/${message.forwardMessage.path}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{
                        color: '#007bff',
                        textDecoration: 'underline',
                      }}
                    >
                      {message.forwardMessage.path.split('/').pop()}
                    </a>
                  </Box>
                ) : (
                  // Other file types
                  <a
                    href={`https://chatroom.enitiation.com/${message.forwardMessage.path}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{
                      color: '#007bff',
                      textDecoration: 'underline',
                    }}
                  >
                    Download File
                  </a>
                )}
              </Box>
            ) : (
              // Render text content
              // <Typography sx={{ fontWeight: 'normal' }}>
              message?.forwardMessage?.id &&
              message?.forwardMessage?.content && (
                <HtmltoText HtmlContent={message.forwardMessage?.content} />
              )
              // </Typography>
            )}
          </Box>
        </Box>
      );
    }

    // Text messages
    if (message.content && message.type === 'text_messages') {
      return (
        // <Typography sx={{ fontWeight: 'normal' }}>
        <HtmltoText HtmlContent={message.content} />
        // </Typography>
      );
    }

    // Reply messages
    if (message.content && message.type === 'replyMessage') {
      return (
        <>
          <Typography
            variant='body2'
            color='text.secondary'
            sx={{
              mb: 1,
              p: 1,
              borderLeft: '4px solid #dbe5e5',
              bgcolor: '#f5f5f5',
              borderRadius: '4px',
            }}
          >
            {message.repliedTo?.type === 'forwardMessage' ? (
              <HtmltoText HtmlContent={message.repliedTo?.forwardMessage?.content || ''} />
            ) : (
              <HtmltoText HtmlContent={message.repliedTo?.content || ''} />
            )}
          </Typography>
          {/* <Typography> */}
          <HtmltoText HtmlContent={message.content} />
          {/* </Typography> */}
        </>
      );
    }

    // Attachments
    if (message.type === 'attachments') {
      return (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {message.path && /\.(jpg|jpeg|png|gif)$/i.test(message.path) ? ( // Image extensions
              <Box
                sx={{
                  position: 'relative',
                  '&:hover .fullscreen-icon': {
                    opacity: 1,
                  },
                }}
              >
                <img
                  src={`https://chatroom.enitiation.com/${message.path}`} // Image path
                  alt='Attachment'
                  style={{
                    maxWidth: '100%',
                    maxHeight: '300px',
                    objectFit: 'contain',
                    marginTop: '8px',
                  }}
                />
                <IconButton
                  className='fullscreen-icon'
                  onClick={(e) => {
                    e.stopPropagation();
                    const target = e.currentTarget.previousSibling;
                    if (target instanceof HTMLImageElement) {
                      // Type check to ensure target is an image
                      const rect = target.getBoundingClientRect();
                      setInitialPosition({
                        top: rect.top,
                        left: rect.left,
                        width: rect.width,
                        height: rect.height,
                      });
                    }
                    setSelectedImageIndex(findImageIndex(message.path || ''));
                    setCarouselOpen(true);
                  }}
                  sx={{
                    position: 'absolute',
                    top: '10%',
                    left: '95%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    opacity: 0,
                    transition: 'opacity 0.2s',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    },
                  }}
                >
                  <FullscreenIcon />
                </IconButton>
              </Box>
            ) : message.path && /\.(mp4|mov|avi|mkv)$/i.test(message.path) ? ( // Video extensions
              <video
                controls
                style={{
                  maxWidth: '100%',
                  maxHeight: '300px',
                  marginTop: '8px',
                }}
              >
                <source src={`https://chatroom.enitiation.com/${message.path}`} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            ) : message.path && /\.(pdf)$/i.test(message.path) ? ( // PDF files
              <>
                <img
                  src='https://cdn-icons-png.flaticon.com/512/4208/4208479.png'
                  alt='PDF Icon'
                  style={{
                    width: '30px',
                    height: '30px',
                    marginRight: '8px',
                  }}
                />
                <a
                  href={`https://chatroom.enitiation.com/${message.path}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    color: '#007bff',
                    textDecoration: 'underline',
                    marginTop: '8px',
                  }}
                >
                  {message.path.split('/').pop()}
                </a>
              </>
            ) : message.path && /\.(docx|doc)$/i.test(message.path) ? ( // Word documents
              <>
                <img
                  src='https://w7.pngwing.com/pngs/89/291/png-transparent-microsoft-word-logo-microsoft-word-office-open-xml-document-computer-icons-computer-file-word-file-icon-blue-angle-text-thumbnail.png' // Replace with the actual Word icon URL
                  alt='Word Icon'
                  style={{
                    width: '30px',
                    height: '30px',
                    marginRight: '8px',
                  }}
                />
                <a
                  href={`https://chatroom.enitiation.com/${message.path}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    color: '#007bff',
                    textDecoration: 'underline',
                    marginTop: '8px',
                  }}
                >
                  {message.path.split('/').pop()}
                </a>
              </>
            ) : message.path && /\.(txt)$/i.test(message.path) ? ( // Text files
              <>
                <img
                  src='https://w7.pngwing.com/pngs/89/291/png-transparent-microsoft-word-logo-microsoft-word-office-open-xml-document-computer-icons-computer-file-word-file-icon-blue-angle-text-thumbnail.png' // Replace with the actual Word icon URL
                  alt='Word Icon'
                  style={{
                    width: '30px',
                    height: '30px',
                    marginRight: '8px',
                  }}
                />
                <a
                  href={`https://chatroom.enitiation.com/${message.path}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    color: '#007bff',
                    textDecoration: 'underline',
                    marginTop: '8px',
                  }}
                >
                  {message.path.split('/').pop()}
                </a>
              </>
            ) : (
              <a
                href={`https://chatroom.enitiation.com/${message.path}`} // Fallback for other file types
                target='_blank'
                rel='noopener noreferrer'
                style={{
                  color: '#007bff',
                  textDecoration: 'underline',
                  marginTop: '8px',
                }}
              >
                Download File
              </a>
            )}
          </Box>
        </Box>
      );
    }
  };

  // Get Initials for avatar
  const getInitials = (name: string) => {
    const parts = name.trim().split(/\s+/);
    if (parts.length === 1) {
      return parts[0].slice(0, 2).toUpperCase();
    }
    return `${parts[0][0]}${parts[parts.length - 1][0]}`.toUpperCase();
  };

  // Date format
  // Dynamic date format
  const formatMessageTime = (timestamp: string | number | Date | undefined | null) => {
    const messageDate = new Date(timestamp || Date.now());
    const now = new Date();

    // Reset hours to start of day for date comparisons
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    // Get start of week (7 days ago)
    const weekAgo = new Date(today);
    weekAgo.setDate(weekAgo.getDate() - 7);

    // Check if message is from today
    if (messageDate >= today) {
      return messageDate.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    }

    // Check if message is from yesterday
    if (messageDate >= yesterday) {
      return 'Yday';
    }

    // Check if message is within the last week
    if (messageDate >= weekAgo) {
      return messageDate.toLocaleString('en-US', {
        weekday: 'short',
      });
    }

    // Message is older than a week
    return messageDate.toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const handleBookmarkClick = (bookmarkId: number | undefined) => {
    if (bookmarkId !== undefined) {
      setSelectedBookmarkId((prevId) =>
        prevId === bookmarkId.toString() ? null : bookmarkId.toString(),
      );
    }
  };

  const handleRemoveBookmark = (bookmarkId: number) => {
    if (socket) {
      dispatch(removeBookmarkAction({ socket, bookmarkId }));
    }
  };

  const handleOptionSelect = (option: MenuOption, message: MessageData) => {
    const bookmarkToRemove = bookmarks.find((bookmark) => bookmark.message.id === message.id);
    if (option.action === 'removeBookmark' && bookmarkToRemove?.id) {
      handleRemoveBookmark(bookmarkToRemove?.id);
      dispatch(setAction(option.action));
    } else if (option.action === 'copy') {
      dispatch(setAction(option.action));
      if (message.content) {
        navigator.clipboard
          .writeText(message.content)
          .then(() => {
            console.log('Text copied to clipboard');
          })
          .catch((err) => {
            console.error('Failed to copy text: ', err);
          });
      }
    }
    setSelectedBookmarkId(null);
  };

  return (
    <Box
      width='100%'
      // borderRadius={mobileView ? '0' : '15px'}
      height='calc(100vh - 73px)'
      display='flex'
      flexDirection='column'
      alignItems='center'
      bgcolor='white'
      sx={{ border: '1px solid lightgray', borderRadius: '5px' }}
    >
      <Box
        display='flex'
        alignItems='center'
        justifyContent={mobileView ? 'flex-start' : 'space-between'}
        width='100%'
        height={mobileView ? '50px' : '48px'}
        sx={{ px: 2 }}
        borderBottom='1px solid lightgray'
      >
        {mobileView && <ArrowBackIosIcon onClick={onBackClick} />}
        <Box display='flex' alignItems='center' sx={{ cursor: 'pointer' }} gap={1}>
          <BookmarkIcon sx={{ height: '22px', width: '20px' }} />
          <Typography variant='h6' sx={{ fontSize: '16px', fontWeight: '600' }}>
            Bookmarks ({bookmarks.length})
          </Typography>
          {/* <Typography variant='subtitle1'>({bookmarks.length})</Typography> */}
        </Box>

        {!mobileView && (
          <SquareIconButton>
            <CloseIcon
              sx={{
                cursor: 'pointer',
                color: 'black',
                width: mobileView ? '20px' : '30px',
                height: mobileView ? '20px' : '30px',
              }}
              onClick={onBackClick}
            />
          </SquareIconButton>
        )}
      </Box>
      <Box
        ref={bookmarksContainerRef}
        sx={{
          flex: 1,
          width: '100%',
          overflowY: 'auto',
          my: '5px',
          maxHeight: 'calc(100vh - 81px)',
        }}
      >
        {[...bookmarks]
          .sort((a, b) => {
            const dateA = new Date(a.message.createdAt || 0).getTime();
            const dateB = new Date(b.message.createdAt || 0).getTime();
            return dateA - dateB;
          })
          .map((bookmark: BookmarkData, index: number) => (
            <React.Fragment key={bookmark.id}>
              <Box display='flex' flexDirection={'row'} alignItems='center' mt={1}>
                <Avatar
                  alt='No Image'
                  src={`https://chatroom.enitiation.com/${bookmark.message.sender?.profilePictureURL}`}
                  sx={{
                    width: 45,
                    height: 45,
                    ml: '5px',
                    bgcolor: stringToColor(bookmark.message.sender?.userName || 'Anonymous'),
                  }}
                >
                  {getInitials(bookmark.message.sender?.userName || 'Anonymous')}
                </Avatar>
                <Box
                  sx={{
                    // maxWidth: mobileView ? '95%' : '70%',
                    display: 'flex',
                    flexDirection: 'column',
                    wordBreak: 'break-word',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                      <Typography sx={{ fontWeight: 'normal', fontSize: '14px' }}>
                        {bookmark.message.room?.roomType === 'group'
                          ? `${bookmark.message.sender?.userName || 'Anonymous'} > ${
                              bookmark.message.room?.roomName
                            }`
                          : `${bookmark.message.sender?.userName || 'Anonymous'}`}
                      </Typography>
                      <Typography sx={{ color: 'gray', fontSize: '12px' }}>
                        {formatMessageTime(bookmark.message.createdAt)}
                      </Typography>
                    </Box>
                    <Box
                      display='flex'
                      alignItems='center'
                      flexDirection={'row-reverse'}
                      justifyContent={'start'}
                    >
                      {bookmark.id !== undefined &&
                        selectedBookmarkId === bookmark.id.toString() && (
                          // <Box display='flex' alignItems='center' flexDirection={'row-reverse'}>
                          <OptionsMenu
                            options={[
                              { label: 'Copy', action: 'copy' },
                              { label: 'Remove Bookmark', action: 'removeBookmark' },
                            ]}
                            onOptionSelect={(option) =>
                              handleOptionSelect(option, bookmark.message)
                            }
                          />
                          // </Box>
                        )}

                      <Box
                        data-message-id={bookmark.message.id}
                        onClick={() => handleBookmarkClick(bookmark.id)}
                        sx={{
                          bgcolor: '#D3D3D3',
                          p: '6px 10px',
                          m: '0px 5px',
                          borderRadius: '8px',
                          position: 'relative',
                          maxWidth: mobileView ? '70dvw' : '50dvw',
                          width: 'fit-content',
                          cursor: 'pointer',
                          '&:hover': {
                            bgcolor: '#C3C3C3',
                          },
                        }}
                      >
                        {renderMessageContent(bookmark.message)}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {index < bookmarks.length - 1 && (
                <Divider
                  sx={{
                    my: 1,
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                  }}
                />
              )}
            </React.Fragment>
          ))}
      </Box>
      <ImageCarousel
        open={carouselOpen}
        onClose={() => setCarouselOpen(false)}
        images={getImagesFromMessages()}
        initialIndex={selectedImageIndex}
        initialPosition={initialPosition}
      />
    </Box>
  );
};

export default Bookmarks;
