import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';

export const SquareIconButton = styled(IconButton)({
  borderRadius: 0,
  '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
    borderRadius: 0,
    backgroundColor: 'grey.300',
  },
});
