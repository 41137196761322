/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Dialog,
  Typography,
} from '@mui/material';
import ThemeProvider from '@mui/system/ThemeProvider';
import { createContext, useState } from 'react';
import Main from './routes/Main';
import { theme } from './theme/theme';
import { useToast } from './hooks/useToast';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
// import { messaging } from './firebase'
// import { getToken } from 'firebase/messaging';
import NotificationsIcon from '@mui/icons-material/Notifications';
import isMobile from './hooks/isMobile';
import './App.css';
import { useSearchParams } from 'react-router-dom';
import { ApolloClient, ApolloProvider, gql, InMemoryCache } from '@apollo/client';
import { CourseProvider } from './course-management-modules/context/CourseContext';

export const RefreshContext = createContext<any>({});

export const FilterRefreshContext = createContext<any>({});

export const TicketsContext = createContext<any>({});

export const TenantContext = createContext<any>({});

export const BoardContext = createContext<any>([]);

export const LoadingContext = createContext<any>({});

export const FilterStatusContext = createContext<any>({});

export const CurrentUserContext = createContext<any>({});

export default function App() {
  const [searchParams] = useSearchParams();
  const [refresh, setRefresh] = useState(true);
  const [filterRefresh, setFilterRefresh] = useState(true);
  const [tickets, setTickets] = useState('');
  const [tenant, setTenant] = useState(searchParams.get('tenant') ?? 'all');
  const [board, setBoard] = useState<number[] | string[]>(
    searchParams.get('board') === 'all'
      ? searchParams
          .get('board')
          ?.split(',')
          .map((item) => item) ?? [0]
      : searchParams
          .get('board')
          ?.split(',')
          .map((item) => parseInt(item)) ?? [0],
  );
  const [loading, setLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState('');
  const [currentUser, setCurrentUser] = useState();
  // const [deviceToken, setDeviceToken] = useState<string>();
  // const [request, setRequest] = useState(false);
  const [openRequest, setOpenRequest] = useState(false);

  const mobile = isMobile();

  // const checkPermission = () => {
  //   const notifsPermission = Notification.permission;
  //   if (notifsPermission === "default") {
  //     setOpenRequest(true);
  //   }
  // }

  // const requestPermission = async () => {
  //   const permission = await Notification.requestPermission();
  //   if (permission === "granted") {
  //     const token = await getToken(messaging, { vapidKey: "BMWEire5tOtFBsFa8Z7NI4FmNNaLs8h4xwGLT5iaC4C_TI7IuSyDn2eGgCs9Ru13V0IUkMD1CrfL3Us7d0ri4UQ" })
  //     // console.log(token)
  //     setDeviceToken(token)
  //     setRequest(false)
  //   }
  // }

  // useEffect(() => {
  //   if (request === true) {
  //     requestPermission()
  //   }
  // }, [request])

  // useEffect(() => {
  //   checkPermission()
  // }, [])

  const client = new ApolloClient({
    uri: 'https://sws.scieverinc.com.np/graphql',
    cache: new InMemoryCache(),
  });

  // const GET_ROLE = gql`
  //   query {
  //     getRole {
  //       id
  //       display_name
  //       name
  //       description
  //       tenant
  //       status
  //       features {
  //         id
  //         name
  //         description
  //         status
  //         operations {
  //           id
  //           name
  //           method
  //         }
  //       }
  //       createdAt
  //       updatedAt
  //     }
  //   }
  // `;

  // const accessToken = localStorage.getItem('access_token');

  // const { data, error } = useQuery(GET_ROLE, {
  //   context: {
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   },
  // });

  // if (loading) return "Loading...";
  // if (error) return <pre>{error.message}</pre>;

  client
    .query({
      query: gql`
        query {
          getRole {
            id
            display_name
            name
            description
            tenant
            status
            features {
              id
              name
              description
              status
              operations {
                id
                name
                method
              }
            }
            createdAt
            updatedAt
          }
        }
      `,
    })
    .then((data) => console.log(data))
    .catch((err) => console.log(err));

  return (
    <>
      <ApolloProvider client={client}>
        <DndProvider backend={HTML5Backend}>
          <ThemeProvider theme={theme}>
            <CourseProvider>
              <RefreshContext.Provider value={{ refresh, setRefresh }}>
                <FilterRefreshContext.Provider value={{ filterRefresh, setFilterRefresh }}>
                  <TicketsContext.Provider value={{ tickets, setTickets }}>
                    <TenantContext.Provider value={{ tenant, setTenant }}>
                      <BoardContext.Provider value={{ board, setBoard }}>
                        <LoadingContext.Provider value={{ loading, setLoading }}>
                          <FilterStatusContext.Provider value={{ filterStatus, setFilterStatus }}>
                            <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
                              <CssBaseline />
                              {/* <Main deviceToken={deviceToken} /> */}
                              <Main />
                            </CurrentUserContext.Provider>
                          </FilterStatusContext.Provider>
                        </LoadingContext.Provider>
                      </BoardContext.Provider>
                    </TenantContext.Provider>
                  </TicketsContext.Provider>
                </FilterRefreshContext.Provider>
              </RefreshContext.Provider>
            </CourseProvider>
          </ThemeProvider>
        </DndProvider>
      </ApolloProvider>

      {window.location.hostname !== 'sos.scieverinc.com' && (
        <Typography
          fontSize={mobile ? '10px' : '20px'}
          position='absolute'
          top='5px'
          left='50%'
          fontWeight='bold'
          sx={{ transform: 'translateX(-50%)' }}
          zIndex='10'
        >
          For Testing Purpose Only!
        </Typography>
      )}

      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
        }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>

      <Dialog
        open={openRequest}
        onClose={() => setOpenRequest(false)}
        PaperProps={
          !mobile
            ? {
                sx: {
                  position: 'fixed',
                  bottom: '20px',
                  right: '20px',
                  margin: 0,
                  transform: 'none',
                },
              }
            : undefined
        }
      >
        <Box display='flex' flexDirection='column' gap='10px' p='15px' alignItems='center'>
          <Box display='flex' gap='5px' alignItems='center'>
            <NotificationsIcon />
            <Box>
              {mobile ? 'Allow push notifications?' : 'Would you like to allow push notifications?'}
            </Box>
          </Box>
          <Box display='flex' gap='10px'>
            <Button
              size='small'
              variant='contained'
              color='info'
              aria-label='notification'
              onClick={() => {
                // setRequest(true);
                setOpenRequest(false);
              }}
            >
              Yes
            </Button>
            <Button
              size='small'
              variant='outlined'
              aria-label='notification'
              onClick={() => setOpenRequest(false)}
            >
              No
            </Button>
          </Box>
        </Box>
      </Dialog>

      {useToast()}
    </>
  );
}
