import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useState, useContext, Dispatch, SetStateAction, useEffect } from 'react';
import {
  // Dialog,
  // Box,
  // Typography,
  // DialogContent,
  // InputAdornment,
  // TextField,
  IconButton,
  Popover,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import CloseIcon from '@mui/icons-material/Close';
// import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import { CurrentUserContext, LoadingContext } from '../../App';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import { UserDataContext } from '../../routes/Main';
import { DataContext } from '../../pages/TicketsPage';
// import { TicketListContext } from '../../pages/HomePage';
import isDeveloper from '../../hooks/isDeveloper';
import { recordLog } from '../../services/recordLog';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { failed } from '../../hooks/useToast';
import { ITicketData } from '../../utils/exports/Interface';

const StartDatePicker = (props: {
  selectedRowId: string;
  fetchTicket?: () => void;
  ticketsData?: ITicketData[] | undefined;
  setTicketsData?: Dispatch<SetStateAction<ITicketData[]>> | undefined;
}) => {
  const superAdmin = isSuperAdmin();

  const developer = isDeveloper();

  const { userData } = useContext(UserDataContext);

  const { setLoading } = useContext(LoadingContext);

  const { data, setData } = useContext(DataContext);

  // const { fetchTicketsHomePage } = useContext(TicketListContext);

  const { currentUser } = useContext(CurrentUserContext);

  const id = props.selectedRowId != null ? props.selectedRowId.slice(4) : 'undefined';
  const selectedId = parseInt(id);

  // const [commentOpen, setCommentOpen] = useState(false);

  // const [comment, setComment] = useState('');

  // const handleCommentClose = () => {
  //   setCommentOpen(false);
  // };

  const [date, setDate] = useState<any>();

  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

  const handleDate = () => {
    if (date) {
      setLoading(true);

      const dt = new Date(date);

      const year = dt.toLocaleString('default', { year: 'numeric' });
      const month = dt.toLocaleString('default', { month: '2-digit' });
      const day = dt.toLocaleString('default', { day: '2-digit' });

      const formattedDate = year + '-' + month + '-' + day;

      const startDate = {
        data: {
          start_date: formattedDate,
        },
      };

      const messageInfo = {
        data: {
          message: `${formattedDate}`,
          sender: userData !== undefined && userData.data.name,
          ticket: selectedId,
          admin: (superAdmin || developer) && true,
          changed_to: 'Start Date',
        },
      };

      axios
        .put(`${process.env.REACT_APP_strapiUrl}/tickets/${selectedId}`, startDate, {
          headers: headers,
        })
        .then(() => {
          recordLog(`Start Date`, formattedDate, 'ticket', props.selectedRowId, currentUser?.id);
          axios
            .post(`${process.env.REACT_APP_strapiUrl}/chats`, messageInfo, {
              headers: headers,
            })
            .then(() => {
              // handleCommentClose();
              handleDateClose();
              // setComment('');
              if (props.fetchTicket) {
                props.fetchTicket();
              }
              const updatedTickets = data.map((ticket: any) => {
                if (ticket.id === selectedId) {
                  return {
                    ...ticket,
                    attributes: {
                      ...ticket.attributes,
                      start_date: formattedDate,
                    },
                  };
                }
                return ticket;
              });
              const updatedBoardTickets = props.ticketsData
                ? props.ticketsData.map((ticket: any) => {
                    if (ticket.id === selectedId) {
                      return {
                        ...ticket,
                        attributes: {
                          ...ticket.attributes,
                          start_date: formattedDate,
                        },
                      };
                    }
                    return ticket;
                  })
                : [];
              setData(updatedTickets);
              props.setTicketsData && props.setTicketsData(updatedBoardTickets);
              // window.location.pathname === '/overview' ? fetchTicketsHomePage() : fetchTickets();
              // fetchTickets();
              setLoading(false);
            })
            .catch(() => failed());
        })
        .catch(() => {
          failed();
          setLoading(false);
        });
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleDateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateClose = () => {
    setAnchorEl(null);
  };

  const dateOpen = Boolean(anchorEl);

  const handleDateChange = (date: Date | null) => {
    setDate(date);
    // setCommentOpen(true);
  };

  useEffect(() => {
    handleDate();
  }, [date]);
  // console.log(date)

  return (
    <>
      {/* {(superAdmin || developer) */}
      {/* ? */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <IconButton aria-label='handle-data-click' onClick={handleDateClick} sx={{ p: 0 }}>
          <CalendarMonthIcon sx={{ width: '18px', height: '18px', color: 'black' }} />
          {/* <CalendarMonthIcon onClick={handleClickOpen} cursor='pointer' sx={{ width: '20px', height: '20px' }} /> */}
        </IconButton>

        <Popover
          open={dateOpen}
          anchorEl={anchorEl}
          onClose={handleDateClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: -190,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <StaticDatePicker
            value={date}
            onChange={handleDateChange}
            disablePast
            // onAccept={() => setCommentOpen(true)}
            componentsProps={{ actionBar: { actions: [] } }}
          />
        </Popover>
      </LocalizationProvider>
      {/* : '-' */}
      {/* } */}

      {/* <Dialog open={commentOpen} onClose={handleCommentClose} fullWidth>
        <DialogContent>
          <Box display='flex' flexDirection='column' gap='15px'>
            <Box display='flex' justifyContent='space-between'>
              <Typography fontWeight='bold'>Comment:</Typography>
              <CloseIcon onClick={handleCommentClose} cursor='pointer' />
            </Box>
            <TextField
              multiline
              fullWidth
              rows={4}
              aria-label='comment'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={{ mt: '-68px' }}>
                    <SendIcon onClick={handleDate} sx={{ cursor: 'pointer' }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setComment(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleDate();
                  e.preventDefault();
                }
              }}
              value={comment}
            />
          </Box>
        </DialogContent>
      </Dialog> */}
    </>
  );
};

export default StartDatePicker;
