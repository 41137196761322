import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import AssignDeveloper from '../AssignDeveloper';
import { BoardContext, TenantContext } from '../../App';
import axios from 'axios';
import { failed, success } from '../../hooks/useToast';
import { gapi } from 'gapi-script';
import isMobile from '../../hooks/isMobile';
import { AllTenantsContext, UserDataContext } from '../../routes/Main';
import AgendasBoard from './AgendasBoard';
import DecisionsBoard from './DecisionsBoard';
import RollingItemsBoard from './RollingItemsBoard';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ITicketData } from '../../utils/exports/Interface';
import DoneButton from '../UI/DoneButton';
import DiscussionBoard from './DiscussionBoard';
import isSuperAdmin from '../../hooks/isSuperAdmin';

export interface IItemsProps {
  actionItems: number[];
  setActionItems: Dispatch<SetStateAction<number[]>>;
  discussions: number[];
  setDiscussions: Dispatch<SetStateAction<number[]>>;
  decisions: number[];
  setDecisions: Dispatch<SetStateAction<number[]>>;
  rollingItems: number[];
  setRollingItems: Dispatch<SetStateAction<number[]>>;
  actionItemsArray: ITicketData[];
  discussionsArray: ITicketData[];
  decisionsArray: ITicketData[];
  rollingItemsArray: ITicketData[];
}

const NewMeeting = (props: { englishDate: string; nepaliDate: string }) => {
  const mobile = isMobile();

  const { allTenants } = useContext(AllTenantsContext);
  const { tenant } = useContext(TenantContext);
  const superAdmin = isSuperAdmin();
  const { board } = useContext(BoardContext);
  const { userData } = useContext(UserDataContext);
  const userId = superAdmin ? 'sa' : userData?.data?.id;

  const [title, setTitle] = useState<string>();
  const [attendees, setAttendees] = useState<string[] | null>(null);
  const [actionItems, setActionItems] = useState<number[]>([]);
  const [discussions, setDiscussions] = useState<number[]>([]);
  const [decisions, setDecisions] = useState<number[]>([]);
  const [rollingItems, setRollingItems] = useState<number[]>([]);
  const [meetingApproach, setMeetingApproach] = useState<string | null>(null);
  const [meetingType, setMeetingType] = useState<string | null>(null);
  const [meetingCategory, setMeetingCategory] = useState<string | null>(null);

  const [actionItemsArray, setActionItemsArray] = useState<ITicketData[]>([]);
  const [discussionsArray, setDiscussionsArray] = useState<ITicketData[]>([]);
  const [decisionsArray, setDecisionsArray] = useState<ITicketData[]>([]);
  const [rollingItemsArray, setRollingItemsArray] = useState<ITicketData[]>([]);

  const itemsProps = {
    actionItems: actionItems,
    setActionItems: setActionItems,
    discussions: discussions,
    setDiscussions: setDiscussions,
    decisions: decisions,
    setDecisions: setDecisions,
    rollingItems: rollingItems,
    setRollingItems: setRollingItems,
    actionItemsArray: actionItemsArray,
    discussionsArray: discussionsArray,
    decisionsArray: decisionsArray,
    rollingItemsArray: rollingItemsArray,
  };

  const meetingApproachData = [
    {
      id: 1,
      name: 'Online',
    },
    {
      id: 2,
      name: 'In-Person',
    },
  ];

  const meetingTypeData = [
    {
      id: 1,
      name: 'Internal',
    },
    {
      id: 2,
      name: 'External',
    },
  ];

  const meetingCategoryData = [
    {
      id: 1,
      name: 'Ideation',
    },
    {
      id: 2,
      name: 'Sprint',
    },
    {
      id: 3,
      name: 'Stand-Up',
    },
    {
      id: 4,
      name: 'Hands-On',
    },
    {
      id: 5,
      name: 'Review',
    },
    {
      id: 6,
      name: 'Demo',
    },
  ];

  const [calendarId, setCalendarId] = useState<string>();

  const getCalendarId = () => {
    setCalendarId(
      allTenants?.data?.tenants
        ?.filter(
          (org: { organization: { alias: string } }) => org?.organization?.alias === tenant,
        )[0]
        ?.organization?.google_calendar_id?.split(', ')[1],
    );
  };

  useEffect(() => {
    getCalendarId();
  }, [tenant, allTenants]);

  const [idToEdit, setIdToEdit] = useState<number>(0);

  // const CLIENT_ID = process.env.REACT_APP_calendarClientId
  const CLIENT_ID = '1028944999419-hvdpe0haq6u5uuh4d8a8kes02voltq1l.apps.googleusercontent.com';
  // const API_KEY = process.env.REACT_APP_eventApiKey
  const API_KEY = 'AIzaSyD6YhqpL9Kw-7S73gyYaul_IURq0cNZVos';
  const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
  const SCOPES = 'https://www.googleapis.com/auth/calendar.events';

  const addCalendarEvent = (startTime: string, title: string | undefined) => {
    gapi.load('client:auth2', () => {
      gapi.auth2.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      });

      gapi.client.load('calendar', 'v3');
      const timeZone = 'Asia/Kathmandu';
      const duration = '24:00:00';

      const startDate = new Date(startTime);
      const msDuration =
        (Number(duration.split(':')[0]) * 60 * 60 +
          Number(duration.split(':')[1]) * 60 +
          Number(duration.split(':')[2])) *
        1000;
      const endDate = new Date(startDate.getTime() + msDuration);
      const isoStartDate = new Date(
        startDate.getTime() - new Date().getTimezoneOffset() * 60 * 1000 + 20700000,
      )
        .toISOString()
        .split('.')[0];
      const isoEndDate = new Date(endDate.getTime() - new Date().getTimezoneOffset() * 60 * 1000)
        .toISOString()
        .split('.')[0];

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          const event = {
            summary: title,
            start: {
              dateTime: isoStartDate,
              timeZone: timeZone,
            },
            end: {
              dateTime: isoEndDate,
              timeZone: timeZone,
            },
            recurrence: ['RRULE:FREQ=DAILY;COUNT=1'],
          };

          const request = gapi.client.calendar.events.insert({
            calendarId: calendarId,
            resource: event,
          });

          request.execute((event: any) => {
            // console.log(event)
            window.open(event.htmlLink);
          });
        });
    });
  };

  const [suggestions, setSuggestions] = useState<ITicketData[]>([]);

  const fetchSuggestions = async () => {
    const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

    axios
      .get(
        `${process.env.REACT_APP_strapiUrl}/tickets?pagination[pageSize]=999&filters[main_tenant][$eq]=${tenant}` +
          board
            ?.map((item: any) => {
              if (item === 'all') {
                return userId === 'sa'
                  ? ''
                  : `&filters[$or][0][boards][users][$contains]=${userId}&filters[$or][1][default_board][$eq]=true`;
              } else if (item === 0) {
                return `&filters[default_board][$eq]=true`;
              } else {
                return `&filters[boards][id][$in]=${item}`;
              }
            })
            .filter(Boolean)
            .join(''),
        {
          headers: headers,
        },
      )
      .then((res) => setSuggestions(res.data.data))
      .catch(() => failed());
  };

  useEffect(() => {
    fetchSuggestions();
  }, [superAdmin, tenant, board]);

  useEffect(() => {
    setActionItemsArray(
      suggestions?.filter((ticket) => actionItems?.includes(ticket?.id as number)),
    );
    setDiscussionsArray(
      suggestions?.filter((ticket) => discussions?.includes(ticket?.id as number)),
    );
    setDecisionsArray(suggestions?.filter((ticket) => decisions?.includes(ticket?.id as number)));
    setRollingItemsArray(
      suggestions?.filter((ticket) => rollingItems?.includes(ticket?.id as number)),
    );
  }, [suggestions, actionItems, decisions, rollingItems]);

  const [allMeetings, setAllMeetings] = useState<{ id: number; attributes: { title: string } }[]>(
    [],
  );

  const headers = { Authorization: `Bearer ${process.env.REACT_APP_token}` };

  const getAllMeetings = () => {
    axios
      .get(
        `${process.env.REACT_APP_strapiUrl}/meetings?filters[date][$eq]=${props.englishDate}&filters[tenant][$eq]=${tenant}&filters[board][$eq]=${board[0]}&populate=*`,
        {
          headers: headers,
        },
      )
      .then((res) => setAllMeetings(res.data.data))
      .catch(() => failed());
  };

  const getMeeting = () => {
    if (idToEdit !== 0) {
      axios
        .get(`${process.env.REACT_APP_strapiUrl}/meetings/${idToEdit}?populate=*`, {
          headers: headers,
        })
        .then((res) => {
          setTitle(res.data.data.attributes.title);
          setAttendees(res.data.data.attributes.attendees);
          setMeetingApproach(res.data.data.attributes.meeting_approach);
          setMeetingType(res.data.data.attributes.meeting_type);
          setMeetingCategory(res.data.data.attributes.meeting_category);
          setActionItems(res.data.data.attributes.action_items);
          setDiscussions(res.data.data.attributes.discussions);
          setDecisions(res.data.data.attributes.decisions);
          setRollingItems(res.data.data.attributes.rolling_items);
        })
        .catch(() => failed());
    } else {
      setTitle(undefined);
      setAttendees(null);
      setMeetingApproach(null);
      setMeetingType(null);
      setMeetingCategory(null);
      setActionItems([]);
      setDiscussions([]);
      setDecisions([]);
      setRollingItems([]);
    }
  };

  useEffect(() => {
    if (tenant !== 'all' && board[0] !== 'all') {
      getAllMeetings();
    }
  }, [props.englishDate, props.nepaliDate, tenant, board]);

  useEffect(() => {
    getMeeting();
  }, [idToEdit]);

  useEffect(() => {
    setIdToEdit(0);
    setTitle(undefined);
    setAttendees(null);
    setMeetingApproach(null);
    setMeetingType(null);
    setMeetingCategory(null);
    setActionItems([]);
    setDiscussions([]);
    setDecisions([]);
    setRollingItems([]);
  }, [props?.nepaliDate, props?.nepaliDate, tenant]);

  const createNewMeeting = () => {
    const data = {
      data: {
        tenant: tenant,
        board: board[0],
        date: props.englishDate,
        title: title,
        attendees: attendees,
        meeting_approach: meetingApproach,
        meeting_type: meetingType,
        meeting_category: meetingCategory,
        discussions: discussions,
        decisions: decisions,
        action_items: actionItems,
        rolling_items: rollingItems,
      },
    };
    axios
      .post(`${process.env.REACT_APP_strapiUrl}/meetings`, data, {
        headers: headers,
      })
      .then(() => {
        success();
        setIdToEdit(0);
        setTitle(undefined);
        setAttendees(null);
        setMeetingApproach(null);
        setMeetingType(null);
        setMeetingCategory(null);
        setActionItems([]);
        setDiscussions([]);
        setDecisions([]);
        setRollingItems([]);
        addCalendarEvent(new Date(props.englishDate).toISOString(), title);
        getAllMeetings();
      })
      .catch(() => {
        failed();
      });
  };

  const editMeeting = () => {
    const data = {
      data: {
        tenant: tenant,
        board: board[0],
        date: props.englishDate,
        title: title,
        attendees: attendees,
        meeting_approach: meetingApproach,
        meeting_type: meetingType,
        meeting_category: meetingCategory,
        discussions: discussions,
        decisions: decisions,
        action_items: actionItems,
        rolling_items: rollingItems,
      },
    };
    axios
      .put(`${process.env.REACT_APP_strapiUrl}/meetings/${idToEdit}`, data, {
        headers: headers,
      })
      .then(() => {
        success();
        getAllMeetings();
      })
      .catch(() => {
        failed();
      });
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    if (containerRef.current) {
      const { scrollLeft } = containerRef.current;
      const buffer = 70; // Pixels from the edge to start scrolling
      const movementSpeed = 30; // Pixels to scroll per event

      if (e.clientX < containerRef.current.getBoundingClientRect().left + buffer) {
        // Scroll left
        containerRef.current.scrollLeft = scrollLeft - movementSpeed;
      } else if (e.clientX > containerRef.current.getBoundingClientRect().right - buffer) {
        // Scroll right
        containerRef.current.scrollLeft = scrollLeft + movementSpeed;
      }
    }
  };

  const handleNextClick = () => {
    const currentIndex = allMeetings.findIndex((item) => item.id === idToEdit);

    const nextIndex = currentIndex + 1;

    if (nextIndex < allMeetings.length) {
      setIdToEdit(allMeetings[nextIndex].id);
    }
  };

  const handlePrevClick = () => {
    const currentIndex = allMeetings.findIndex((item) => item.id === idToEdit);

    const prevIndex = currentIndex - 1;

    if (prevIndex >= 0) {
      setIdToEdit(allMeetings[prevIndex].id);
    } else {
      setIdToEdit(0);
    }
  };

  const currentIndex = allMeetings.findIndex((item) => item.id === idToEdit);
  const isAtStart = idToEdit === 0;
  const isAtEnd = currentIndex === allMeetings.length - 1;

  return (
    <Box
      height='100%'
      border={mobile ? 'none' : '1px dashed gray'}
      borderRadius='10px'
      borderColor='gray'
      display='flex'
      flexDirection='column'
      padding='15px'
      gap='5px'
    >
      {tenant !== 'all' && board[0] !== 'all' ? (
        <>
          <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
            <Box display='flex' alignItems='center' gap='5px'>
              <KeyboardArrowLeftIcon
                onClick={() => handlePrevClick()}
                sx={{
                  height: '30px',
                  width: '30px',
                  ml: '-10px',
                  color: isAtStart ? 'gray' : 'black',
                  cursor: isAtStart ? 'not-allowed' : 'pointer',
                }}
              />
              <Box fontWeight='bold' fontSize='14px'>
                {props.englishDate} / {props.nepaliDate}
              </Box>
            </Box>

            <Box display='flex' alignItems='center' gap='5px'>
              <DoneButton
                text='Save'
                onClick={() => (idToEdit === 0 ? createNewMeeting() : editMeeting())}
                width='50px'
                height='25px'
              />

              <KeyboardArrowRightIcon
                onClick={() => handleNextClick()}
                sx={{
                  height: '30px',
                  width: '30px',
                  mr: '-10px',
                  color: isAtEnd ? 'gray' : 'black',
                  cursor: isAtEnd ? 'not-allowed' : 'pointer',
                }}
              />
            </Box>
          </Box>

          <Box display='flex' gap='10px' mt='5px' alignItems='center' width='100%'>
            <Typography fontSize='14px'>Title:</Typography>
            <TextField
              size='small'
              value={title || ''}
              aria-label='title'
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              variant='standard'
            />
            <Box>
              {allMeetings?.length > 0 && (
                <FormControl size='small'>
                  <Select
                    value={idToEdit}
                    aria-label='meeting-select'
                    onChange={(e) => setIdToEdit(e.target.value as number)}
                    sx={{
                      mb: '5px',
                      '.MuiSelect-select': {
                        width: 0,
                        padding: 0,
                        opacity: 0,
                      },
                      '.MuiSelect-icon': {
                        height: '25px',
                        width: '25px',
                      },
                      '.MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                  >
                    <MenuItem aria-label='new' value={0}  sx={{
                        fontSize: '14px',
                      }}>
                      New
                    </MenuItem>
                    {allMeetings?.map((meeting: { attributes: { title: string }; id: number }) => {
                      return (
                        <MenuItem
                          aria-label='attributes-title'
                          key={meeting.attributes.title}
                          value={meeting.id}
                          sx={{
                            fontSize: '14px',
                          }}
                        >
                          {meeting.attributes.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Box>
          </Box>
          <Box display='flex' gap='30px' alignItems='baseline'>
            <Box display='flex' gap='10px'>
              <Typography fontSize='14px'>Attendees:</Typography>
              <AssignDeveloper
                idToChange=''
                assignees={attendees}
                setAssignees={setAttendees}
                main_tenant={tenant}
                newTicket={true}
                attendees={true}
              />
            </Box>
            <Box display='flex' gap='10px' alignItems='center' width='100%' justifyContent='end'>
              <FormControl
                variant='standard'
                size='small'
                sx={{
                  flex: '1 1 calc(40% - 10px)',
                  // minWidth: '120px',
                  maxWidth: '150px',
                }}
              >
                <InputLabel sx={{ fontSize: '14px' }}>Approach</InputLabel>
                <Select
                  value={meetingApproach || ''}
                  onChange={(e) => setMeetingApproach(e.target.value)}
                  label='Meeting_Approach'
                  aria-label='meeting-approach-select'
                  sx={{
                    fontSize: '14px',
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        fontSize: '14px',
                      },
                    },
                  }}
                >
                  {meetingApproachData.map((data) => (
                    <MenuItem
                      aria-label={data.name}
                      key={data.id}
                      value={data.name}
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant='standard'
                size='small'
                sx={{
                  flex: '1 1 calc(40% - 10px)',
                  // minWidth: '120px',
                  maxWidth: '150px',
                }}
              >
                <InputLabel sx={{ fontSize: '14px' }}>Type</InputLabel>
                <Select
                  value={meetingType || ''}
                  onChange={(e) => setMeetingType(e.target.value)}
                  label='Meeting_Type'
                  aria-label='meeting-type-select'
                  sx={{
                    fontSize: '14px',
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        fontSize: '14px',
                      },
                    },
                  }}
                >
                  {meetingTypeData.map((data) => (
                    <MenuItem
                      aria-label={data.name}
                      key={data.id}
                      value={data.name}
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant='standard'
                size='small'
                sx={{
                  flex: '1 1 calc(40% - 10px)',
                  // minWidth: '120px',
                  maxWidth: '150px',
                }}
              >
                <InputLabel sx={{ fontSize: '14px' }}>Category</InputLabel>
                <Select
                  value={meetingCategory || ''}
                  onChange={(e) => setMeetingCategory(e.target.value)}
                  label='Meeting_Category'
                  aria-label='meeting-category-select'
                  sx={{
                    fontSize: '14px',
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        fontSize: '14px',
                      },
                    },
                  }}
                >
                  {meetingCategoryData.map((data) => (
                    <MenuItem
                      aria-label={data.name}
                      key={data.id}
                      value={data.name}
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          {!mobile ? (
            <Box
              display='flex'
              sx={{
                overflowX: 'scroll',
                '&::-webkit-scrollbar': {
                  height: '5px',
                },
              }}
              ref={containerRef}
              onDragOver={handleDragOver}
              justifyContent='space-between'
            >
              <AgendasBoard
                suggestions={suggestions}
                items={itemsProps}
                fetchSuggestions={fetchSuggestions}
              />

              <DiscussionBoard items={itemsProps} />

              <DecisionsBoard items={itemsProps} />

              <RollingItemsBoard items={itemsProps} />
            </Box>
          ) : (
            <Box display='flex' flexDirection='column' gap='5px'>
              <Box display='flex' flexDirection='column' gap='5px' p='5px'>
                <Box fontWeight='bold'>AGENDA</Box>
                <Box>
                  <TextField
                    multiline
                    fullWidth
                    value={actionItems || ''}
                    rows={4}
                    aria-label='agendas'
                    // onChange={e => setActionItems(e.target.value)}
                    sx={{
                      border: 'dashed',
                      borderWidth: '1px',
                      borderRadius: '10px',
                      borderColor: 'gray',
                      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 0,
                      },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 0,
                      },
                      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 0,
                      },
                      '& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline':
                        {
                          borderWidth: 0,
                        },
                      '& .MuiInputBase-root': {
                        padding: '5px 10px',
                      },
                    }}
                  />
                </Box>
              </Box>

              <Box display='flex' flexDirection='column' gap='5px' p='5px'>
                <Box fontWeight='bold'>DISCUSSION</Box>
                <Box>
                  <TextField
                    multiline
                    fullWidth
                    rows={4}
                    value={discussions || ''}
                    aria-label='discussions'
                    // onChange={e => setDiscussions(e.target.value)}
                    sx={{
                      border: 'dashed',
                      borderWidth: '1px',
                      borderRadius: '10px',
                      borderColor: 'gray',
                      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 0,
                      },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 0,
                      },
                      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 0,
                      },
                      '& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline':
                        {
                          borderWidth: 0,
                        },
                      '& .MuiInputBase-root': {
                        padding: '5px 10px',
                      },
                    }}
                  />
                </Box>
              </Box>

              <Box display='flex' flexDirection='column' gap='5px' p='5px'>
                <Box fontWeight='bold'>DECISION</Box>
                <Box>
                  <TextField
                    multiline
                    fullWidth
                    rows={4}
                    value={decisions || ''}
                    aria-label='decisions'
                    // onChange={e => setDecisions(e.target.value)}
                    sx={{
                      border: 'dashed',
                      borderWidth: '1px',
                      borderRadius: '10px',
                      borderColor: 'gray',
                      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 0,
                      },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 0,
                      },
                      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 0,
                      },
                      '& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline':
                        {
                          borderWidth: 0,
                        },
                      '& .MuiInputBase-root': {
                        padding: '5px 10px',
                      },
                    }}
                  />
                </Box>
              </Box>

              <Box display='flex' flexDirection='column' gap='5px' p='5px'>
                <Box fontWeight='bold'>ROLLING</Box>
                <Box>
                  <TextField
                    multiline
                    fullWidth
                    rows={4}
                    value={rollingItems || ''}
                    aria-label='rolling-items'
                    // onChange={e => setRollingItems(e.target.value)}
                    sx={{
                      border: 'dashed',
                      borderWidth: '1px',
                      borderRadius: '10px',
                      borderColor: 'gray',
                      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 0,
                      },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 0,
                      },
                      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 0,
                      },
                      '& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline':
                        {
                          borderWidth: 0,
                        },
                      '& .MuiInputBase-root': {
                        padding: '5px 10px',
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </>
      ) : (
        <Box height='100%' display='flex' alignItems='center' justifyContent='center'>
          <Typography variant='h6' fontWeight='bold'>
            Select Tenant and Board
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default NewMeeting;
