import React from 'react';

interface HtmltoTextProps {
  HtmlContent: string | undefined;
}

const HtmltoText: React.FC<HtmltoTextProps> = ({ HtmlContent }) => {
  const convertHTMLToText = (htmlContent: string | undefined): React.ReactNode => {
    // Check if htmlContent is undefined or an empty string
    if (!htmlContent) {
      return null;
    }

    // Create a temporary div to parse HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;

    // Recursively process child nodes
    const processNode = (node: Node): React.ReactNode => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent;
      }

      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        const children = Array.from(element.childNodes).map(processNode);

        switch (element.tagName.toLowerCase()) {
          case 'b':
          case 'strong':
            return <strong key={Math.random()}>{children}</strong>;
          case 'i':
          case 'em':
            return <em key={Math.random()}>{children}</em>;
          case 'ins':
            return <ins key={Math.random()}>{children}</ins>;
          default:
            return children;
        }
      }

      return null;
    };

    return processNode(tempDiv);
  };
  return <span>{convertHTMLToText(HtmlContent)}</span>;
};

export default HtmltoText;
