import { FormControl, MenuItem, Select } from '@mui/material';
import { useContext } from 'react';
import { BoardContext, TenantContext } from '../App';
import { AllTenantsContext, CombinedReportContext, UserDataContext } from '../routes/Main';
import isDeveloper from '../hooks/isDeveloper';
import isSuperAdmin from '../hooks/isSuperAdmin';

const SelectTenant = () => {
  const { tenant, setTenant } = useContext(TenantContext);
  const { allTenants } = useContext(AllTenantsContext);
  const { userData } = useContext(UserDataContext);
  const { setBoard } = useContext(BoardContext);
  const { setCombinedReport } = useContext(CombinedReportContext);
  const developer = isDeveloper();
  const superAdmin = isSuperAdmin();

  return (
    <>
      {(superAdmin || developer) && (
        <FormControl size='small'>
          <Select
            value={tenant}
            aria-label='tenant-select'
            onChange={(event) => {
              setTenant(event.target.value);
              setBoard(['all']);
              setCombinedReport(true);
            }}
            sx={{ height: '28px', pb: '1px' }}
          >
            <MenuItem aria-label='all' value='all'>
              All
            </MenuItem>
            {developer
              ? userData?.data?.organizations
                  ?.filter((tenant: any) => tenant?.sts_link === 1)
                  ?.map((tenant: any) => {
                    return (
                      <MenuItem aria-label='tenant-alias' value={tenant?.alias} key={tenant?.alias}>
                        {tenant?.ticket_id?.toUpperCase()}
                      </MenuItem>
                    );
                  })
              : allTenants?.data?.tenants
                  ?.filter((tenant: any) => tenant.organization.sts_link === 1)
                  .map(
                    (tenant: {
                      organization: { ticket_id: string; alias: string };
                      id: string;
                    }) => {
                      return (
                        <MenuItem
                          aria-label='tenant-organization-alias'
                          value={tenant?.organization?.alias}
                          key={tenant?.id}
                        >
                          {tenant?.organization?.ticket_id?.toUpperCase()}
                        </MenuItem>
                      );
                    },
                  )}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default SelectTenant;
