import LockIcon from '@mui/icons-material/Lock';
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { headers, UserListContext } from '../routes/Main';
import isSuperAdmin from '../hooks/isSuperAdmin';
import { BoardContext, TenantContext } from '../App';
import axios from 'axios';
import { dynamicFailed, success } from '../hooks/useToast';
import isAdmin from '../hooks/isAdmin';

const BoardAccess = () => {
  const { userList } = useContext(UserListContext);
  const { tenant } = useContext(TenantContext);
  const { board } = useContext(BoardContext);
  const superAdmin = isSuperAdmin();
  const admin = isAdmin();
  const [open, setOpen] = useState(false);
  const [authorizedUsers, setAuthorizedUsers] = useState<number[]>([]);
  const [boardName, setBoardName] = useState<string>();

  const getAuthorizedUsers = () => {
    axios
      .get(`${process.env.REACT_APP_strapiUrl}/boards/${board[0]}`, {
        headers: headers,
      })
      .then((res) => {
        setBoardName(res.data.data.attributes.name);
        setAuthorizedUsers(res.data.data.attributes.users);
      })
      .catch(() => dynamicFailed('Server Error!'));
  };

  useEffect(() => {
    board[0] !== '0' && board[0] !== 0 && board[0] !== 'all' && getAuthorizedUsers();
  }, [board]);

  const handleChange = (e: any) => {
    authorizedUsers
      ? authorizedUsers?.includes(parseInt(e.target.value))
        ? setAuthorizedUsers(
            authorizedUsers?.filter((item: number) => item !== parseInt(e.target.value)),
          )
        : setAuthorizedUsers([...authorizedUsers, parseInt(e.target.value)])
      : setAuthorizedUsers([parseInt(e.target.value)]);
  };

  const editAuthorizedUsers = () => {
    const data = {
      data: {
        users: authorizedUsers,
      },
    };

    axios
      .put(`${process.env.REACT_APP_strapiUrl}/boards/${board[0]}`, data, {
        headers: headers,
      })
      .then(() => {
        success();
        setOpen(false);
      })
      .catch(() => dynamicFailed('Server Error!'));
  };

  return (
    <>
      {(superAdmin || admin) && !board?.includes('all') && !board?.includes(0) && (
        <LockIcon sx={{ cursor: 'pointer' }} onClick={() => setOpen(true)} />
      )}

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth='xl'
      >
        <DialogContent sx={{ padding: '0px 20px' }}>
          <Box display='flex' flexDirection='column'>
            <Box
              display='flex'
              position='sticky'
              top='0px'
              justifyContent='space-between'
              gap='10px'
              bgcolor='white'
              p='20px 10px'
              m='-10px -10px -15px -10px'
              zIndex='1'
            >
              <Typography fontWeight='bold' fontSize='14px'>
                {boardName && `${boardName} Users:`}
              </Typography>
              <Box display='flex' gap='10px'>
                <DoneIcon sx={{ cursor: 'pointer' }} onClick={() => editAuthorizedUsers()} />
                <CloseIcon
                  onClick={() => {
                    setOpen(false);
                  }}
                  cursor='pointer'
                />
              </Box>
            </Box>

            <FormControl component='fieldset' variant='standard' sx={{ mt: '20px', mb: '20px' }}>
              <FormGroup>
                {userList?.data?.users?.users
                  ?.filter(
                    (user: any) =>
                      user?.status === '1' &&
                      user?.roles[0]?.name !== 'sa' &&
                      user?.organizations?.filter(
                        (data: { alias: string }) => data?.alias === tenant,
                      ).length > 0,
                  )
                  .map((user: any) => {
                    return (
                      <FormControlLabel
                        key={user?.name}
                        control={
                          <Checkbox
                            aria-label='user-name'
                            checked={authorizedUsers?.includes(user?.id)}
                            onChange={handleChange}
                            value={user?.id}
                          />
                        }
                        label={user?.name}
                      />
                    );
                  })}
              </FormGroup>
            </FormControl>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BoardAccess;
