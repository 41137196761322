import React, { useContext, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Avatar,
  Divider,
  List,
  ListItem,
  Checkbox,
  Button,
  CircularProgress,
  TextField,
  InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { RoomData, MessageData } from '../../store/constants/constants';
import { UserDataContext } from '../../routes/Main';
import HtmltoText from './HtmltoText';
import { stringToColor } from '../../utils/exports/Function';
import { SquareIconButton } from '../../utils/exports/Styles';

interface ForwardDialogProps {
  open: boolean;
  onClose: () => void;
  message: MessageData | null;
  rooms: RoomData[];
  onForward: (roomId: number, messageId: number) => void;
}

const ForwardDialog: React.FC<ForwardDialogProps> = ({
  open,
  onClose,
  message,
  rooms,
  onForward,
}) => {
  const [selectedRooms, setSelectedRooms] = useState<number[]>([]);
  const [isForwarding, setIsForwarding] = useState(false);
  const [progress, setProgress] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const { userData } = useContext(UserDataContext);

  const getInitials = (name: string) => {
    const parts = name.split(' ');
    return parts.length > 1
      ? (parts[0][0] + parts[parts.length - 1][0]).toUpperCase()
      : parts[0].slice(0, 2).toUpperCase();
  };

  const getRoomDisplayName = (room: RoomData) => {
    return room.roomType === 'group'
      ? room.roomName
      : room.roomParticipants?.find((participant) => participant.user.userId !== userData.data.id)
          ?.user?.userName || 'Unknown User';
  };

  const filterRooms = (rooms: RoomData[]) => {
    const searchTerm = searchQuery.toLowerCase();
    return rooms.filter((room) => {
      const displayName = getRoomDisplayName(room).toLowerCase();
      return displayName.includes(searchTerm);
    });
  };

  const groupChats = filterRooms(rooms.filter((room) => room.roomType === 'group'));
  const individualChats = filterRooms(rooms.filter((room) => room.roomType === 'individual'));

  const handleToggleRoom = (roomId: number) => {
    setSelectedRooms((prev) =>
      prev.includes(roomId) ? prev.filter((id) => id !== roomId) : [...prev, roomId],
    );
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleForwardMessages = async () => {
    if (!message) return;

    const messageIdToForward =
      message.type === 'forwardMessage' ? message.forwardMessage?.id : message.id;

    if (messageIdToForward !== undefined && selectedRooms.length > 0) {
      setIsForwarding(true);
      try {
        for (let i = 0; i < selectedRooms.length; i++) {
          await onForward(selectedRooms[i], messageIdToForward);
          setProgress(((i + 1) / selectedRooms.length) * 100);
        }
        setSelectedRooms([]);
        onClose();
      } catch (error) {
        console.error('Error forwarding messages:', error);
      } finally {
        setIsForwarding(false);
        setProgress(0);
      }
    }
  };

  const renderMessagePreview = (message: MessageData | null) => {
    if (!message) return null;

    switch (message.type) {
      case 'text_messages':
        return (
          <Typography
            sx={{
              bgcolor: '#f5f5f5',
              p: 1,
              borderRadius: 1,
              mb: 2,
              maxHeight: '100px',
              overflow: 'auto',
            }}
          >
            <HtmltoText HtmlContent={message.content} />
          </Typography>
        );

      case 'attachments':
        if (message.path && /\.(jpg|jpeg|png|gif)$/i.test(message.path)) {
          return (
            <Box sx={{ mb: 2 }}>
              <img
                src={`https://chatroom.enitiation.com/${message.path}`}
                alt='Attachment preview'
                style={{
                  maxWidth: '100%',
                  maxHeight: '150px',
                  objectFit: 'contain',
                  borderRadius: '4px',
                }}
              />
            </Box>
          );
        } else if (message.path && /\.(pdf)$/i.test(message.path)) {
          return (
            <Box
              sx={{
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                bgcolor: '#f5f5f5',
                borderRadius: 1,
                p: 1,
              }}
            >
              <img
                src='https://cdn-icons-png.flaticon.com/512/4208/4208479.png'
                alt='PDF Icon'
                style={{ width: '50px', height: '50px' }}
              />
              <Typography variant='body2' color='text.primary' sx={{ ml: 1 }}>
                {message.path.split('/').pop()}
              </Typography>
            </Box>
          );
        }
        break;

      case 'replyMessage':
        return (
          <Box
            sx={{
              bgcolor: '#f5f5f5',
              borderRadius: 1,
              p: 1,
              mb: 2,
              maxHeight: '100px',
              overflow: 'auto',
            }}
          >
            <Typography variant='body2' color='text.secondary' gutterBottom>
              Reply to: <HtmltoText HtmlContent={message.repliedTo?.content} />
            </Typography>
            <Typography sx={{ bgcolor: '#f5f5f5', p: 1, borderRadius: 1 }}>
              <HtmltoText HtmlContent={message.content} />
            </Typography>
          </Box>
        );

      case 'forwardMessage':
        return null;

      default:
        return (
          <Typography sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
            <HtmltoText HtmlContent={message.content} />
          </Typography>
        );
    }
  };

  const renderSelectedRooms = () => (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 0.5,
        mb: 0,
        mt: 1,
      }}
    >
      {selectedRooms.map((roomId) => {
        const room = rooms.find((r) => r.id === roomId);
        if (!room) return null;

        const displayName = getRoomDisplayName(room);
        const avatarColor =
          room.roomType === 'group' ? stringToColor(room.roomName) : stringToColor(displayName);

        return (
          <Box
            key={roomId}
            sx={{
              position: 'relative',
              display: 'inline-flex',
            }}
          >
            <Avatar
              src={`https://chatroom.enitiation.com/${room.avatar}`}
              sx={{
                width: 35,
                height: 35,
                fontSize: '0.875rem',
                bgcolor: avatarColor,
                margin: '4px',
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: 'rgba(0, 0, 0, 0.3)',
                },
              }}
              onClick={() => handleToggleRoom(roomId)}
            >
              {getInitials(displayName)}
            </Avatar>
            <SquareIconButton
              size='small'
              sx={{
                position: 'absolute',
                top: -4,
                right: -4,
                width: 20,
                height: 20,
                bgcolor: 'background.paper',
                border: '1px solid',
                borderColor: 'divider',
                '&:hover': {
                  bgcolor: 'action.hover',
                },
              }}
              onClick={() => handleToggleRoom(roomId)}
            >
              <CloseIcon sx={{ fontSize: 14 }} />
            </SquareIconButton>
          </Box>
        );
      })}
    </Box>
  );

  const renderRoomList = (rooms: RoomData[], title: string) => (
    <Box sx={{ mt: 1 }}>
      <Typography
        variant='body1'
        sx={{
          fontWeight: 500,
          color: 'text.secondary',
          pl: 2,
        }}
      >
        {title}
      </Typography>

      <List>
        {rooms.map((room) => {
          const displayName = getRoomDisplayName(room);
          const avatarColor =
            room.roomType === 'group' ? stringToColor(room.roomName) : stringToColor(displayName);

          return (
            <ListItem
              key={room.id}
              onClick={() => handleToggleRoom(room.id)}
              sx={{
                p: 1.5,
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  cursor: 'pointer',
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <Avatar
                  src={`https://chatroom.enitiation.com/${room.avatar}`}
                  sx={{
                    width: 45,
                    height: 45,
                    mr: 2,
                    bgcolor: avatarColor,
                  }}
                >
                  {getInitials(displayName)}
                </Avatar>
                <Box>
                  <Typography>{displayName}</Typography>
                  {room.roomType === 'group' && (
                    <Typography variant='caption' color='text.secondary'>
                      {room.memberCount} members
                    </Typography>
                  )}
                </Box>
              </Box>
              <Checkbox
                checked={selectedRooms.includes(room.id)}
                onChange={() => handleToggleRoom(room.id)}
                onClick={(e) => e.stopPropagation()}
                disabled={isForwarding}
              />
            </ListItem>
          );
        })}
        {rooms.length === 0 && (
          <Typography variant='body2' color='text.secondary' sx={{ p: 2, textAlign: 'center' }}>
            No {title.toLowerCase()} found
          </Typography>
        )}
      </List>
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={!isForwarding ? onClose : undefined}
      aria-labelledby='forward-dialog-title'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          width: 400,
          maxHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Header */}
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ mb: 2 }} variant='h6' component='h2' fontWeight={'bold'}>
            Forward Message
          </Typography>
          <Box>{renderMessagePreview(message)}</Box>

          {/* Search Input */}
          <Box sx={{ position: 'relative', width: '100%' }}>
            <TextField
              fullWidth
              size='medium'
              placeholder='Search'
              variant='outlined'
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon
                      sx={{
                        color: 'action.active',
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: '100%',
                borderRadius: '15px',
              }}
            />
          </Box>

          {/* Selected Rooms now shown as Avatars */}
          {renderSelectedRooms()}
        </Box>
        <SquareIconButton
          onClick={onClose}
          disabled={isForwarding}
          size='small'
          sx={{ color: 'text.secondary', position: 'absolute', right: '0', top: '0', p: 1 }}
        >
          <CloseIcon />
        </SquareIconButton>

        <Divider />

        {/* Scrollable Content */}
        <Box sx={{ p: 1, overflowY: 'auto', maxHeight: 'calc(80vh - 140px)' }}>
          {individualChats.length > 0 && renderRoomList(individualChats, 'People')}
          {groupChats.length > 0 && renderRoomList(groupChats, 'Groups')}

          {individualChats.length === 0 && groupChats.length === 0 && searchQuery && (
            <Typography variant='body2' color='text.secondary' sx={{ textAlign: 'center', mt: 2 }}>
              No results found
            </Typography>
          )}
        </Box>

        {/* Sticky Footer */}
        <Box
          sx={{
            p: 2,
            borderTop: 1,
            borderColor: 'divider',
            bgcolor: 'background.paper',
            display: 'flex',
            alignSelf: 'center',
          }}
        >
          {isForwarding && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={20} />
              <Typography variant='body2'>Forwarding... {Math.round(progress)}%</Typography>
            </Box>
          )}
          <Button
            onClick={handleForwardMessages}
            variant='contained'
            disabled={selectedRooms.length === 0 || isForwarding}
            sx={{ ml: 'auto' }}
          >
            Forward Message ({selectedRooms.length})
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ForwardDialog;
