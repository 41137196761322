import { Box, Stack } from '@mui/material';
import OverviewHeader from '../../components/Home Page/OverviewHeader';
import StatusBlock from '../../components/Home Page/StatusBlock';
import { useContext, useEffect, useState } from 'react';
import { BoardContext, LoadingContext, TenantContext } from '../../App';
// import { DataContext } from '../../pages/TicketsPage';
import { PageContext, UserDataContext } from '../../routes/Main';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import isDeveloper from '../../hooks/isDeveloper';
import { getTicketsService, getTicketsTenantSpecificService } from '../../services/ticketService';
import { ITicketData } from '../../utils/exports/Interface';
import isAttendanceUser from '../../hooks/isAttendanceUser';

const DesktopHomePageLayout = () => {
  const attendanceUser = isAttendanceUser();

  const [backlogData, setBacklogData] = useState<ITicketData[]>([]);
  const [onholdData, setOnholdData] = useState<ITicketData[]>([]);
  const [todoData, setTodoData] = useState<ITicketData[]>([]);
  const [redoData, setRedoData] = useState<ITicketData[]>([]);
  const [inprogressData, setInprogressData] = useState<ITicketData[]>([]);
  const [prData, setPrData] = useState<ITicketData[]>([]);
  const [prdoneData, setPrdoneData] = useState<ITicketData[]>([]);
  const [doneData, setDoneData] = useState<ITicketData[]>([]);
  const [uatReadyData, setUatReadyData] = useState<ITicketData[]>([]);
  const [uatData, setUatData] = useState<ITicketData[]>([]);
  const [prodReadyData, setProdReadyData] = useState<ITicketData[]>([]);
  const [productionData, setProductionData] = useState<ITicketData[]>([]);
  const [closedData, setClosedData] = useState<ITicketData[]>([]);
  const [canceledData, setCanceledData] = useState<ITicketData[]>([]);

  const [backlogDatacount, setBacklogDatacount] = useState<number>();
  const [onholdDatacount, setOnholdDatacount] = useState<number>();
  const [todoDatacount, setTodoDatacount] = useState<number>();
  const [redoDatacount, setRedoDatacount] = useState<number>();
  const [inprogressDatacount, setInprogressDatacount] = useState<number>();
  const [prDatcounta, setPrDatacount] = useState<number>();
  const [prdoneDatacount, setPrdoneDatacount] = useState<number>();
  const [doneDatacount, setDoneDatacount] = useState<number>();
  const [uatReadyDatacount, setUatReadyDatacount] = useState<number>();
  const [uatDatacount, setUatDatacount] = useState<number>();
  const [prodReadyDatacount, setProdReadyDatacount] = useState<number>();
  const [productionDatacount, setProductionDatacount] = useState<number>();
  const [closedDatacount, setClosedDatacount] = useState<number>();
  const [canceledDatacount, setCanceledDatacount] = useState<number>();

  const statuses = [
    {
      name: 'Backlog',
      state: backlogData,
      setState: setBacklogData,
      countState: backlogDatacount,
      setCountState: setBacklogDatacount,
    },
    {
      name: 'On Hold',
      state: onholdData,
      setState: setOnholdData,
      countState: onholdDatacount,
      setCountState: setOnholdDatacount,
    },
    {
      name: 'Todo',
      state: todoData,
      setState: setTodoData,
      countState: todoDatacount,
      setCountState: setTodoDatacount,
    },
    {
      name: 'Redo',
      state: redoData,
      setState: setRedoData,
      countState: redoDatacount,
      setCountState: setRedoDatacount,
    },
    {
      name: 'In Progress',
      state: inprogressData,
      setState: setInprogressData,
      countState: inprogressDatacount,
      setCountState: setInprogressDatacount,
    },
    {
      name: 'PR',
      state: prData,
      setState: setPrData,
      countState: prDatcounta,
      setCountState: setPrDatacount,
    },
    {
      name: 'PR Done',
      state: prdoneData,
      setState: setPrdoneData,
      countState: prdoneDatacount,
      setCountState: setPrdoneDatacount,
    },
    {
      name: 'Done',
      state: doneData,
      setState: setDoneData,
      countState: doneDatacount,
      setCountState: setDoneDatacount,
    },
    {
      name: 'UAT Ready',
      state: uatReadyData,
      setState: setUatReadyData,
      countState: uatReadyDatacount,
      setCountState: setUatReadyDatacount,
    },
    {
      name: 'UAT',
      state: uatData,
      setState: setUatData,
      countState: uatDatacount,
      setCountState: setUatDatacount,
    },
    {
      name: 'Prod Ready',
      state: prodReadyData,
      setState: setProdReadyData,
      countState: prodReadyDatacount,
      setCountState: setProdReadyDatacount,
    },
    {
      name: 'Production',
      state: productionData,
      setState: setProductionData,
      countState: productionDatacount,
      setCountState: setProductionDatacount,
    },
    {
      name: 'Closed',
      state: closedData,
      setState: setClosedData,
      countState: closedDatacount,
      setCountState: setClosedDatacount,
    },
    {
      name: 'Canceled',
      state: canceledData,
      setState: setCanceledData,
      countState: canceledDatacount,
      setCountState: setCanceledDatacount,
    },
  ];

  // const { ticketList } = useContext(TicketListContext);
  // const { data } = useContext(DataContext);
  const { tenant } = useContext(TenantContext);
  const { board } = useContext(BoardContext);
  const { page } = useContext(PageContext);
  const { userData } = useContext(UserDataContext);
  const { setLoading } = useContext(LoadingContext);

  const superAdmin = isSuperAdmin();
  const developer = isDeveloper();

  // const tickets: ITicketData[] = useMemo(() => {
  //   return data?.filter((el: ITicketData) => el?.attributes?.status === type?.toLowerCase());
  // }, [data, type]);

  // const [ticketsData, setTicketsData] = useState(tickets);
  // const [ticketsCount, setTicketsCount] = useState(0);

  // const headers = { 'Authorization': `Bearer ${process.env.REACT_APP_token}` };

  // useEffect(() => {
  //   setTicketsData(tickets);
  // }, [tickets]);

  // useEffect(() => async {
  //   const res = await getTicketsTenantSpecificService(tenant, board, page, 10, type?.toLowerCase());
  //   if (res.data) {
  //     setData(res.data.data);
  //   }
  // }, [page, tenant, board]);
  const getTenantTickets = async (type: string) => {
    if (!userData) {
      return;
    }
    const res = developer
      ? await getTicketsTenantSpecificService(
          superAdmin ? 'sa' : userData?.data?.id,
          tenant === 'all' ? userData?.data?.organizations : tenant,
          board,
          page,
          10,
          false,
          type?.toLowerCase(),
        )
      : await getTicketsTenantSpecificService(
          superAdmin ? 'sa' : userData?.data?.id,
          tenant === 'all' ? null : tenant,
          board,
          page,
          10,
          false,
          type?.toLowerCase(),
        );
    if (res?.data) {
      return res?.data;
    }
  };

  const getTickets = async (status: string) => {
    if (!userData) {
      return;
    }
    setLoading(true);
    const res = await getTicketsService(
      superAdmin ? 'sa' : userData?.data?.id,
      userData.data.organizations[0]?.alias ?? false,
      board,
      page,
      10,
      false,
      status,
    );
    if (res?.data) {
      return res.data.data;
    }
  };
  const getBoardTickets = async (statusName: string) => {
    const setState = statuses.filter((status) => status.name === statusName)[0].setState;
    const setStateCount = statuses.filter((status) => status.name === statusName)[0].setState;

    if (!superAdmin && !developer) {
      const res = await getTickets(statusName);
      if (res?.data) {
        setState(res.data.data);
        setStateCount(res.data.meta.pagination.total);
      }
      return;
    }

    const res = await getTenantTickets(statusName);
    if (res?.data) {
      setState(res.data.data);
      setStateCount(res.data.meta.pagination.total);
    }
  };
  useEffect(() => {
    statuses.map((status) => {
      getBoardTickets(status.name);
    });
  }, [superAdmin, developer, statuses, page, tenant, board]);

  return (
    <>
      <Box
        bgcolor='white'
        position='fixed'
        left={!attendanceUser ? '0px' : '65px'}
        right='0px'
        top='0px'
        bottom='-25px'
        p='15px'
        overflow='hidden'
        // sx={{ borderTopLeftRadius: '10px' }}
      >
        <OverviewHeader />
        <StatusBlock />
        <Stack
          direction='row'
          mt='12px'
          justifyContent='space-between'
          gap='10px'
          pb='10px'
          sx={{ overflowX: 'scroll', overflowY: 'hidden' }}
        >
          {/* {
            statuses?.map((status, index)=>(
              <TicketsContainer type={status.name} ticketsData={status.state} key={index} getBoardTickets={getBoardTickets}/>
            ))
          } */}
        </Stack>
      </Box>

      {/* <RecentActivities /> */}
    </>
  );
};

export default DesktopHomePageLayout;
