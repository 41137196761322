import {
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import Fuse from 'fuse.js';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ITicketData } from '../../utils/exports/Interface';
import CreateTicket from '../CreateTicket';
import striptags from 'striptags';

const FuseSuggestions = (props: {
  suggestions: ITicketData[];
  items: number[];
  setItems: Dispatch<SetStateAction<number[]>>;
  fetchSuggestions?: () => void;
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');

  const [query, setQuery] = useState('');
  const [results, setResults] = useState<any>([]);
  const [timer, setTimer] = useState<any>(null);

  useEffect(() => {
    const fuse = new Fuse(props.suggestions, {
      keys: ['attributes.description', 'attributes.ticket_id'],
      shouldSort: true,
      includeScore: true,
    });

    if (query.trim() !== '') {
      const newTimer = setTimeout(() => {
        const searchResults = fuse.search(query);
        const sortedResults = searchResults.sort((a, b) => (a.score ?? 0) - (b.score ?? 0));
        setResults(sortedResults);
      }, 1000);
      setTimer(newTimer);

      // Cleanup previous timer
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    } else {
      setResults([]);
    }
  }, [query, props.suggestions]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    const parts = value.split('@');
    if (parts.length > 1 && parts[parts.length - 1].length > 0) {
      setQuery(parts[parts.length - 1].toLowerCase());
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (id: number) => {
    const selectedSuggestion = props.suggestions.find((s: any) => s.id === id);
    if (selectedSuggestion) {
      const parts = inputValue.split('@');
      parts.pop();
      const newValue = parts.join('@') + `@${selectedSuggestion.attributes.ticket_id} `;
      setInputValue('');
      props.setItems([...props.items, parseInt(newValue?.split('-')[1])]);
      setShowSuggestions(false);
    }
  };

  const highlightMatch = (text: string, query: string) => {
    const queryWords = query.split(' ').filter(Boolean);
    const parts: JSX.Element[] = [];
    let startIndex = 0;

    queryWords.forEach((word, index) => {
      const wordIndex = text.toLowerCase().indexOf(word.toLowerCase(), startIndex);
      if (wordIndex !== -1) {
        if (startIndex < wordIndex) {
          parts.push(<span key={`text-${index}`}>{text.substring(startIndex, wordIndex)}</span>);
        }
        parts.push(
          <span key={`highlight-${index}`} style={{ backgroundColor: 'yellow' }}>
            {text.substring(wordIndex, wordIndex + word.length)}
          </span>,
        );
        startIndex = wordIndex + word.length;
      }
    });

    if (startIndex < text.length) {
      parts.push(<span key='end'>{text.substring(startIndex)}</span>);
    }

    return parts;
  };

  return (
    <div style={{ position: 'relative' }}>
      <TextField
        variant='outlined'
        size='small'
        aria-label='suggestions'
        fullWidth
        value={inputValue}
        onChange={(e: any) => handleInputChange(e)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <CreateTicket
                items={props?.items}
                setItems={props?.setItems}
                fetchSuggestions={props?.fetchSuggestions}
              />
            </InputAdornment>
          ),
        }}
      />

      {showSuggestions && (
        <Paper
          sx={{
            position: 'absolute',
            width: '120%',
            top: '50px',
            maxWidth: 250,
            maxHeight: 300,
            overflowY: 'auto',
            mt: -1,
            ml: 1,
            zIndex: 1000,
          }}
        >
          <List>
            {results.map((suggestion: any, index: any) => {
              return (
                <ListItem
                  button
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion?.item?.id)}
                >
                  <ListItemText
                    primary={
                      <Typography>
                        {highlightMatch(
                          suggestion?.item?.attributes?.ticket_id,
                          inputValue.split('@').pop() as string,
                        )}
                      </Typography>
                    }
                    secondary={
                      <Typography>
                        {highlightMatch(
                          striptags(suggestion?.item?.attributes?.description || ''),
                          inputValue.split('@').pop() as string,
                        )}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      )}
    </div>
  );
};

export default FuseSuggestions;
