import { Box, ImageList, ImageListItem, Tooltip, Typography } from '@mui/material';
import logo from '../../assets/images/sciever_logo_png.webp';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import SearchIcon from '@mui/icons-material/Search';
import isMobile from '../../hooks/isMobile';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { setBookmarkStatus } from '../../store/message/messageSlice';
import { setSearchOpen } from '../../store/room/roomSlice';
import { useContext, useEffect } from 'react';
import { getBookmarksAction } from '../../store/message/messageActions';
import { debounce } from 'lodash';
import { SocketContext } from '../../utils/SocketProvider';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { SquareIconButton } from '../../utils/exports/Styles';

const ChatHead = () => {
  const dispatch: AppDispatch = useDispatch();
  const socket = useContext(SocketContext);
  const isSearchOpen = useSelector((state: RootState) => state.room.searchOpen);
  const bookmarks = useSelector((state: RootState) => state.message.bookmarks);

  // Get Bookmarks
  const debouncedDispatch = debounce(() => {
    if (socket) {
      dispatch(getBookmarksAction({ socket }));
    }
  }, 1000);

  useEffect(() => {
    debouncedDispatch();

    // Cleanup the debounce on unmount
    return () => {
      debouncedDispatch.cancel();
    };
  }, [dispatch, socket]);

  const handleSearchToggle = () => {
    dispatch(setSearchOpen(!isSearchOpen));
  };

  const mobileView = isMobile();
  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        gap='10px'
        width='100%'
        padding={mobileView ? '10px ' : '0'}
      >
        <ImageList
          sx={{
            // width: '35.5px',
            height: '35px',
            overflow: 'hidden',
            m: '0px',
          }}
          cols={1}
        >
          <ImageListItem>
            <img src={logo} alt='Sciever Inc. Logo' style={{ height: '35px' }} />
          </ImageListItem>
        </ImageList>

        <Typography variant='h6' fontWeight='bold'>
          Chat Room
        </Typography>
        <Box display='flex' alignItems='center' gap={1} ml='auto'>
          <SquareIconButton>
            <SearchIcon sx={{ cursor: 'pointer' }} onClick={handleSearchToggle} />
          </SquareIconButton>

          <Tooltip title='Bookmark'>
            <SquareIconButton>
              {bookmarks.length > 0 ? (
                <BookmarkIcon
                  sx={{ cursor: 'pointer' }}
                  onClick={() => dispatch(setBookmarkStatus(true))}
                />
              ) : (
                <BookmarkBorderIcon
                  sx={{ cursor: 'pointer' }}
                  onClick={() => dispatch(setBookmarkStatus(true))}
                />
              )}
            </SquareIconButton>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};

export default ChatHead;
