import { createAsyncThunk } from '@reduxjs/toolkit';
import { setUser, setUsers } from '../user/userSlice';
import { Socket } from 'socket.io-client';

interface MapUserArgs {
  socket: Socket;
  chatUser: any;
}

interface FetchUsersArgs {
  socket: Socket;
  id?: number;
}

export const mapUser = createAsyncThunk(
  'userMapping/mapUser',
  async ({ socket, chatUser }: MapUserArgs, { dispatch }) => {
    try {
      if (socket.id) {
        const chatUser1 = {
          userId: chatUser.userId,
          userName: chatUser.userName,
          role: chatUser.role,
          organization: chatUser.organization,
          socketId: socket.id,
          isActive: true,
        };

        if (socket) {
          socket.emit('userMap', chatUser1);
          dispatch(setUser(chatUser1));
        }
      }
    } catch (error) {
      console.error('Error in mapping user:', error);
      throw error;
    }
  },
);

export const fetchUsers = createAsyncThunk(
  'userMapping/fetchUsers',
  async ({ socket }: FetchUsersArgs, { dispatch }) => {
    try {
      if (socket) {
        socket.emit('userProfile', {});

        socket.on('users', (data: any) => {
          dispatch(setUsers(data));
        });
      }
    } catch (error) {
      console.error('Error in fetching users:', error);
      throw error;
    }
  },
);

export const fetchUser = createAsyncThunk(
  'userMapping/fetchUser',
  async ({ socket, id }: FetchUsersArgs, { dispatch }) => {
    try {
      if (socket) {
        socket.emit('userProfile', { id });

        socket.on('users', (data: any) => {
          dispatch(setUsers(data));
        });
      }
    } catch (error) {
      console.error('Error in fetching users:', error);
      throw error;
    }
  },
);
