import { Button } from '@mui/material';

const CancelButton = (props: {
  text: string;
  onClick: () => void;
  width?: string;
  disabled?: boolean;
  mx?: string;
  mt?: string;
  mb?: string;
}) => {
  return (
    <Button
      onClick={props.onClick}
      disabled={props.disabled || false}
      aria-label='cancel'
      variant='contained'
      color='error'
      size='small'
      sx={{
        width: props.width || '90px',
        mx: props.mx || '0px',
        mt: props.mt || '0px',
        mb: props.mb || '0px',
        textTransform: 'none',
      }}
    >
      {props.text}
    </Button>
  );
};

export default CancelButton;
