import { useContext, useEffect, useState } from "react";
import { Box, Drawer, ImageList, ImageListItem, Typography, useTheme } from "@mui/material";
import ScieverCalendar from "../../utils/ScieverCalendar";
import NewMeeting from "../../components/Meeting/NewMeeting";
import BottomNavbar from "../../components/Mobile/BottomNavbar";
import logo from '../../assets/images/sciever_logo_png.webp';
import { AllTenantsContext } from "../../routes/Main";
import { TenantContext } from "../../App";

const MobileMeetingLayout = () => {

    const theme = useTheme();
    const [englishDate, setEnglishDate] = useState<string>();
    const [nepaliDate, setNepaliDate] = useState<string>();
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [calendarId, setCalendarId] = useState<string>();

    const { allTenants } = useContext(AllTenantsContext);
    const { tenant } = useContext(TenantContext);

    const getCalendarId = () => {
        setCalendarId(allTenants?.data?.tenants?.filter((org: { organization: { alias: string } }) => org?.organization?.alias === tenant)[0]?.organization?.google_calendar_id?.split(', ')[1])
    }

    useEffect(() => {
        getCalendarId()
    }, [tenant, allTenants])

    useEffect(() => {
        (englishDate && nepaliDate) ?
            setDrawerOpen(true)
            :
            setDrawerOpen(false)
    }, [englishDate, nepaliDate])

    return (
        <>
            <Box
                display='flex'
                px='15px'
                justifyContent='space-between'
                alignItems='center'
                position='fixed'
                left='0px'
                right='0px'
                bgcolor={theme.palette.background.default}
                zIndex='1'
                height='60px'
            >
                <Box display='flex' alignItems='center' gap='10px'>
                    <ImageList
                        sx={{
                            width: '35px',
                            height: '35px',
                            overflow: 'hidden',
                            m: '0px'
                        }}
                        cols={1}
                    >
                        <ImageListItem>
                            <img src={logo} alt='Sciever Inc. Logo' style={{ width: '35px', height: '35px' }} />
                        </ImageListItem>
                    </ImageList>

                    <Typography variant='h6' fontSize='18px' fontWeight='bold' noWrap>
                        Meeting Minute
                    </Typography>
                </Box>
            </Box>

            <Box pt='61px' position='absolute' top='0px' bottom='65px' left='0px' right='0px'>
                <Box height='100%' bgcolor='white' borderRadius='5px' m='0px 15px 65px 15px' p='5px'>
                    <ScieverCalendar height='100%' setEnglishDate={setEnglishDate} setNepaliDate={setNepaliDate} calendarId={calendarId !== undefined ? calendarId : ''} />
                </Box>
            </Box>

            <BottomNavbar />

            <Drawer
                anchor='bottom'
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                {(englishDate && nepaliDate) &&
                    <Box height='100%'>
                        <NewMeeting englishDate={englishDate} nepaliDate={nepaliDate} />
                    </Box>
                }
            </Drawer>
        </>
    )
}

export default MobileMeetingLayout
