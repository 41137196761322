import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import TicketsTable from '../../components/Desktop/TicketsTable';
import OverviewHeader from '../../components/Home Page/OverviewHeader';
import TicketsContainer from '../../components/Home Page/tickets/TicketsContainer';
import StatusBlock from '../../components/Home Page/StatusBlock';
// import PieChartReport from '../../components/PieChartReport';
// import BarChartReport from '../../components/BarChartReport';
// import LineChartReport from '../../components/LineChartReport';
import RoadmapChart from '../../components/RoadmapChart';
import { getWeekDates } from '../../components/Attendance/Hours';
import { AttendanceBlock } from './DesktopAttendanceLayout';
import Pills from '../../components/Pills';
import TicketDetails from '../../components/TicketDetails';
import striptags from 'striptags';
import { useContext, useEffect, useState } from 'react';
import { BoardContext, LoadingContext, TenantContext } from '../../App';
// import { DataContext } from '../../pages/TicketsPage';
import { PageContext, UserDataContext } from '../../routes/Main';
import isSuperAdmin from '../../hooks/isSuperAdmin';
import isDeveloper from '../../hooks/isDeveloper';
import { getTicketsService, getTicketsTenantSpecificService } from '../../services/ticketService';
import { useSearchParams } from 'react-router-dom';
import { a11yProps, CustomTabPanel, formattedDate } from '../../utils/exports/Function';
import { ITicketData } from '../../utils/exports/Interface';
import isAttendanceUser from '../../hooks/isAttendanceUser';
import MeetingPage from '../../pages/MeetingPage';

const DesktopTicketsPageLayout = () => {
  const attendanceUser = isAttendanceUser();

  const [backlogData, setBacklogData] = useState<ITicketData[]>([]);
  const [onholdData, setOnholdData] = useState<ITicketData[]>([]);
  const [todoData, setTodoData] = useState<ITicketData[]>([]);
  const [redoData, setRedoData] = useState<ITicketData[]>([]);
  const [inprogressData, setInprogressData] = useState<ITicketData[]>([]);
  const [prData, setPrData] = useState<ITicketData[]>([]);
  const [prdoneData, setPrdoneData] = useState<ITicketData[]>([]);
  const [doneData, setDoneData] = useState<ITicketData[]>([]);
  const [uatReadyData, setUatReadyData] = useState<ITicketData[]>([]);
  const [uatData, setUatData] = useState<ITicketData[]>([]);
  const [prodReadyData, setProdReadyData] = useState<ITicketData[]>([]);
  const [productionData, setProductionData] = useState<ITicketData[]>([]);
  const [closedData, setClosedData] = useState<ITicketData[]>([]);
  const [canceledData, setCanceledData] = useState<ITicketData[]>([]);

  const [backlogDatacount, setBacklogDatacount] = useState<number>(0);
  const [onholdDatacount, setOnholdDatacount] = useState<number>(0);
  const [todoDatacount, setTodoDatacount] = useState<number>(0);
  const [redoDatacount, setRedoDatacount] = useState<number>(0);
  const [inprogressDatacount, setInprogressDatacount] = useState<number>(0);
  const [prDatcounta, setPrDatacount] = useState<number>(0);
  const [prdoneDatacount, setPrdoneDatacount] = useState<number>(0);
  const [doneDatacount, setDoneDatacount] = useState<number>(0);
  const [uatReadyDatacount, setUatReadyDatacount] = useState<number>(0);
  const [uatDatacount, setUatDatacount] = useState<number>(0);
  const [prodReadyDatacount, setProdReadyDatacount] = useState<number>(0);
  const [productionDatacount, setProductionDatacount] = useState<number>(0);
  const [closedDatacount, setClosedDatacount] = useState<number>(0);
  const [canceledDatacount, setCanceledDatacount] = useState<number>(0);

  const statuses = [
    {
      name: 'Backlog',
      state: backlogData,
      setState: setBacklogData,
      countState: backlogDatacount,
      setCountState: setBacklogDatacount,
    },
    {
      name: 'On Hold',
      state: onholdData,
      setState: setOnholdData,
      countState: onholdDatacount,
      setCountState: setOnholdDatacount,
    },
    {
      name: 'Todo',
      state: todoData,
      setState: setTodoData,
      countState: todoDatacount,
      setCountState: setTodoDatacount,
    },
    {
      name: 'Redo',
      state: redoData,
      setState: setRedoData,
      countState: redoDatacount,
      setCountState: setRedoDatacount,
    },
    {
      name: 'In Progress',
      state: inprogressData,
      setState: setInprogressData,
      countState: inprogressDatacount,
      setCountState: setInprogressDatacount,
    },
    {
      name: 'PR',
      state: prData,
      setState: setPrData,
      countState: prDatcounta,
      setCountState: setPrDatacount,
    },
    {
      name: 'PR Done',
      state: prdoneData,
      setState: setPrdoneData,
      countState: prdoneDatacount,
      setCountState: setPrdoneDatacount,
    },
    {
      name: 'Done',
      state: doneData,
      setState: setDoneData,
      countState: doneDatacount,
      setCountState: setDoneDatacount,
    },
    {
      name: 'UAT Ready',
      state: uatReadyData,
      setState: setUatReadyData,
      countState: uatReadyDatacount,
      setCountState: setUatReadyDatacount,
    },
    {
      name: 'UAT',
      state: uatData,
      setState: setUatData,
      countState: uatDatacount,
      setCountState: setUatDatacount,
    },
    {
      name: 'Prod Ready',
      state: prodReadyData,
      setState: setProdReadyData,
      countState: prodReadyDatacount,
      setCountState: setProdReadyDatacount,
    },
    {
      name: 'Production',
      state: productionData,
      setState: setProductionData,
      countState: productionDatacount,
      setCountState: setProductionDatacount,
    },
    {
      name: 'Closed',
      state: closedData,
      setState: setClosedData,
      countState: closedDatacount,
      setCountState: setClosedDatacount,
    },
    {
      name: 'Canceled',
      state: canceledData,
      setState: setCanceledData,
      countState: canceledDatacount,
      setCountState: setCanceledDatacount,
    },
  ];

  const { tenant } = useContext(TenantContext);
  const { board } = useContext(BoardContext);
  const { page } = useContext(PageContext);
  const { userData } = useContext(UserDataContext);
  const { setLoading } = useContext(LoadingContext);

  const superAdmin = isSuperAdmin();
  const developer = isDeveloper();

  const getTenantTickets = async (type: string) => {
    if (!userData) {
      return;
    }
    const res = developer
      ? await getTicketsTenantSpecificService(
          superAdmin ? 'sa' : userData?.data?.id,
          tenant === 'all' ? userData?.data?.organizations : tenant,
          board,
          page,
          10,
          false,
          type?.toLowerCase(),
        )
      : await getTicketsTenantSpecificService(
          superAdmin ? 'sa' : userData?.data?.id,
          tenant === 'all' ? null : tenant,
          board,
          page,
          10,
          false,
          type?.toLowerCase(),
        );
    if (res?.data) {
      return res?.data;
    }
  };

  const getTickets = async (status: string) => {
    if (!userData) {
      return;
    }
    setLoading(true);
    const res = await getTicketsService(
      superAdmin ? 'sa' : userData?.data?.id,
      userData.data.organizations[0]?.alias ?? false,
      board,
      page,
      10,
      false,
      status?.toLowerCase(),
    );
    if (res?.data) {
      return res?.data;
    }
  };

  const getBoardTickets = async (statusName: string) => {
    if (!superAdmin && !developer) {
      const res = await getTickets(statusName);
      if (res?.data) {
        statuses
          .filter((status) => status.name?.toLowerCase() === statusName?.toLowerCase())[0]
          .setState(res.data);
        statuses
          .filter((status) => status.name?.toLowerCase() === statusName?.toLowerCase())[0]
          .setCountState(res.meta.pagination.total);
        // setStateCount(res.data.meta.pagination.total ? res.data.meta.pagination.total : 0)
      }
      return;
    }

    const res = await getTenantTickets(statusName);
    if (res?.data) {
      statuses
        .filter((status) => status.name?.toLowerCase() === statusName?.toLowerCase())[0]
        .setState(res.data);
      statuses
        .filter((status) => status.name?.toLowerCase() === statusName?.toLowerCase())[0]
        .setCountState(res.meta.pagination.total);
      // setStateCount(res.data.meta.pagination.total ? res.data.meta.pagination.total : 0)
    }
  };
  useEffect(() => {
    statuses.map((status) => {
      getBoardTickets(status.name);
    });
  }, [superAdmin, developer, page, tenant, board]);

  const [searchParams, setQueryParams] = useSearchParams();

  const [tabValue, setTabValue] = useState(
    searchParams.get('tab') ? parseInt(searchParams.get('tab') as string) : 0,
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    const queryParams = Object.fromEntries(searchParams);
    queryParams.tab = JSON.stringify(newValue);
    setQueryParams(queryParams);
  };

  // const { data } = useContext(DataContext);
  const [tickets, setTickets] = useState([]);

  const getRoadmapTickets = async () => {
    if (!userData) {
      return;
    }
    setLoading(true);
    const res = await getTicketsService(
      superAdmin ? 'sa' : userData?.data?.id,
      userData.data.organizations[0]?.alias ?? false,
      board,
      0,
      25,
      true,
    );
    if (res?.data) {
      setTickets(res?.data?.data);
      setLoading(false);
    }
  };

  const getTenantRoadmapTickets = async () => {
    if (!userData) {
      return;
    }
    setLoading(true);
    const res = await getTicketsTenantSpecificService(
      superAdmin ? 'sa' : userData?.data?.id,
      tenant === 'all' ? null : tenant,
      board,
      0,
      25,
      true,
    );
    if (res?.data) {
      setTickets(res?.data?.data);
      setLoading(false);
    }
  };

  const fetchRoadmapTickets = () => {
    if (!superAdmin && !developer) {
      getRoadmapTickets();
      return;
    }
    getTenantRoadmapTickets();
  };

  useEffect(() => {
    fetchRoadmapTickets();
  }, [userData, superAdmin, tenant, board]);

  const weekDates = getWeekDates();

  const [open, setOpen] = useState(false);

  const roadmapTickets: ITicketData[] = tickets?.filter(
    (ticket: ITicketData) =>
      ticket?.attributes?.due_date && weekDates?.includes(ticket?.attributes?.due_date),
  );

  const [ticketToOpen, setTicketToOpen] = useState<string>('');

  const handleClickOpen = (ticketId: string) => {
    setOpen(true);
    setTicketToOpen(ticketId);
  };

  return (
    <>
      <Box
        position='fixed'
        left={!attendanceUser ? '0px' : '65px'}
        right='0px'
        top='0px'
        bottom='-25px'
        bgcolor='white'
        p='15px'
      >
        <OverviewHeader getBoardTickets={getBoardTickets} />
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label='Dashboard' {...a11yProps(0)} />
            <Tab label='Board' {...a11yProps(1)} />
            <Tab label='List' {...a11yProps(2)} />
            <Tab label='Roadmap' {...a11yProps(3)} />
            <Tab label='Meeting' {...a11yProps(4)} />
          </Tabs>
        </Box>

        <CustomTabPanel customPadding='0px' value={tabValue} index={0}>
          <Box display='flex' flexDirection='column' gap='15px'>
            <StatusBlock />
            {/* <Box display='flex' alignItems='center'>
                            <Box flex={3} display='flex' justifyContent='center'>
                                <PieChartReport />
                            </Box>
                            <Box flex={4} mr='-25px' display='flex' justifyContent='center'>
                                <BarChartReport />
                            </Box>
                            <Box flex={4} display='flex' justifyContent='center'>
                                <LineChartReport />
                            </Box>
                        </Box> */}

            {/* <Box display='flex' flexDirection='column' gap='5px'>
                            {roadmapTickets?.map(ticket => {
                                return (
                                    <Typography key={ticket?.id}>{ticket?.attributes?.ticket_id} {ticket?.attributes?.description}</Typography>
                                )
                            })}
                        </Box> */}

            <AttendanceBlock
              sx={{
                minHeight: 'calc(100dvh - 205px)',
                maxWidth: '500px',
                bgcolor: '#F5F2FD',
                border: '1px solid #e2ddf9',
              }}
            >
              <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
                <b>Current Sprint ({roadmapTickets?.length})</b>
                <b>
                  {formattedDate(weekDates[0])} - {formattedDate(weekDates[5])}
                </b>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                mt='10px'
                width='calc(100% + 30px)'
                ml='-15px'
                sx={{ overflowY: 'scroll', overflowX: 'hidden' }}
              >
                {roadmapTickets?.map((ticket, index) => {
                  const isEven = index % 2 === 0;
                  return (
                    <>
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        p='5px 15px'
                        gap='15px'
                        bgcolor={isEven ? 'white' : 'inherit'}
                        onClick={() => handleClickOpen(ticket?.attributes?.ticket_id)}
                        sx={{
                          cursor: 'pointer',
                        }}
                        key={ticket?.attributes?.ticket_id}
                      >
                        <Typography fontSize='15px' noWrap minWidth='60px'>
                          {ticket?.attributes?.ticket_id}
                        </Typography>
                        <Typography flex={1} fontSize='15px' noWrap overflow='hidden'>
                          {striptags(ticket?.attributes?.description)}
                        </Typography>
                        <Pills pill={ticket?.attributes?.status} />
                      </Box>
                    </>
                  );
                })}
              </Box>
            </AttendanceBlock>
          </Box>
        </CustomTabPanel>

        <CustomTabPanel customPadding='0px' value={tabValue} index={1}>
          <Stack
            direction='row'
            mt='12px'
            justifyContent='space-between'
            gap='10px'
            pb='10px'
            sx={{ overflowX: 'scroll', overflowY: 'hidden' }}
            height='calc(100dvh - 100px)'
          >
            {statuses?.map((status, index) => {
              return (
                <TicketsContainer
                  type={status.name}
                  ticketsData={status.state}
                  setTicketsData={status.setState}
                  ticketsCount={status.countState}
                  key={index}
                  getBoardTickets={getBoardTickets}
                />
              );
            })}
          </Stack>
        </CustomTabPanel>

        <CustomTabPanel customPadding='0px' value={tabValue} index={2}>
          <TicketsTable />
        </CustomTabPanel>

        <CustomTabPanel customPadding='0px' value={tabValue} index={3}>
          <RoadmapChart />
        </CustomTabPanel>

        <CustomTabPanel customPadding='0px' value={tabValue} index={4}>
          <MeetingPage fromTickets={true}/>
        </CustomTabPanel>
      </Box>
      <TicketDetails open={open} setOpen={setOpen} ticketId={ticketToOpen} />
    </>
  );
};

export default DesktopTicketsPageLayout;
