import React from 'react';
import Sidebar from '../../components/Desktop/Sidebar';
import { Box } from '@mui/material';
import isAttendanceUser from '../../hooks/isAttendanceUser';

const DesktopMainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const attendanceUser = isAttendanceUser();

  return (
    <>
      {/* <DesktopHeader /> */}
      <Box display='flex' flexDirection='row' role='list'>
        {attendanceUser && <Sidebar />}
        {children}
      </Box>
    </>
  );
};

export default DesktopMainLayout;
